import React from 'react';

interface IDoubleGrid {
    children: React.ReactNodeArray | React.ReactChild;
}

export const DoubleGrid: React.FunctionComponent<IDoubleGrid> = (props) => (
    <div className="grid-wrapper">
        <div className="col-50">
            {props.children[0] ? props.children[0]: props.children}
        </div>
        <div className="col-50">
            {props.children[1]}
        </div>
    </div>
);
