import React from 'react';
import { ColumnFactory } from 'components/data-table/ColumnFactory';
import { IPlanningHBL } from 'reducers/planning/models';
import { StatusRow } from 'components/status-row';
import { DataTable } from 'components/data-table/DamcoTable';
import { cloneDataWithDisplayNames } from 'utilities/tables';

interface IHBLFCRDetailsTable {
    data: IPlanningHBL[];
}

export const HBLFCRDetailsTable = (props: IHBLFCRDetailsTable) => {
    const data = cloneDataWithDisplayNames(props.data ? props.data : []);

    return(
        <StatusRow title="HBL/FCR Details">
            <DataTable
                data={data}
                columns={[
                    ColumnFactory.standardColumn('numberDisplayName', 'HBL/FCR Number'),
                    ColumnFactory.standardColumn('consigneeNameDisplayName', 'Consignee name'),
                    ColumnFactory.standardColumn('consigneeBECodeDisplayName', 'BE Code'),
                    ColumnFactory.standardColumn('packagesDisplayName', 'Packages'),
                    ColumnFactory.standardColumn('weightDisplayName', 'Weight'),
                    ColumnFactory.standardColumn('volumeDisplayName', 'Volume'),
                    ColumnFactory.standardColumn('deconsolidationLocationDisplayName', 'Deconsolidation location'),
                    ColumnFactory.standardColumn('referenceDisplayName', 'Reference'),
                ]}
                reference={null as any}
                tableName="HBLFCRDetailsTable"
                showPagination={true}
                withCheckboxes={false}
            />
        </StatusRow>
    );
}
