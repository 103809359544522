import React, { Component } from 'react';
import { ModalScreen } from 'components/modal-screen';
import { ModalType } from 'components/modal-screen/ModalType';
import { OneStatus } from 'components/status-row/OneStatus';
import { connect } from 'react-redux';
import { addVesselToBl } from 'actions/vessels';
import { Action, compose } from 'redux';
import { ThunkDispatch } from 'redux-thunk';
import { DoubleGrid } from 'components/grid/double-grid';
import { FormInput } from 'components/input/FormInput';
import { FormDateInput } from 'components/date-input/FormDateInput';
import { InjectedFormProps, reduxForm } from 'redux-form';
import { IStore } from 'reducers/index';
import { Dialog } from 'components/dialog';
import { IBLVessel } from 'reducers/vessels/models';
import { EditCBLDetailsFormAirFirstLine } from 'modules/tracking/containers/components/EditCBLDetailsFormAirFirstLine';
import { EditCBLDetailsFormOceanFirstLine } from 'modules/tracking/containers/components/EditCBLDetailsFormOceanFirstLine';

interface IMapStateToProps {
    selectedVessel: any;
}

interface IDispatch {
    addVesselToBl: (body: any, blId: any, isAir: boolean, onSuccess: (vessel: IBLVessel) => void, onRejected: (error: any) => void) => void;
}

interface IAddNewVesselPopupState {
    error: any;
    isErrorDialogVisible: boolean;
}

interface IAddNewVesselPopupOwnProps {
    isAir?: boolean
    blId: number;
    visible: boolean;
    closeModal: () => void;
    onAddSuccess: () => Promise<void>;
}

interface IAddNewVesselPopup extends IAddNewVesselPopupOwnProps, IMapStateToProps, IDispatch {
}

const FORM_NAME = 'ADD_NEW_VESSEL';

export class _AddNewVesselPopup extends Component<InjectedFormProps<{}, IAddNewVesselPopup> & IAddNewVesselPopup, IAddNewVesselPopupState> {
    public state: IAddNewVesselPopupState = {
        isErrorDialogVisible: false,
        error: null,
    };

    public render() {
        return (
            <form onSubmit={this.props.handleSubmit(this.submit)}>
                <ModalScreen
                    title="Add new vessel"
                    modalType={ModalType.m()}
                    primaryButtonTitle="Save"
                    primaryButtonType="submit"
                    secondaryButtonTitle="Cancel"
                    secondaryButtonFunc={this.props.closeModal}
                    visible={this.props.visible}
                    closeModal={this.props.closeModal}
                >
                    { this.state.error && <Dialog
                        closeDialog={() => this.setState({ isErrorDialogVisible: false, error: null })}
                        isVisible={this.state.isErrorDialogVisible}
                        message="Vessel with this details not exists"
                        dialogType={this.state.error.status}
                    /> }
                    <section className="page-container">
                        {this.props.isAir ? <EditCBLDetailsFormAirFirstLine /> : <EditCBLDetailsFormOceanFirstLine /> }
                        <DoubleGrid>
                            <OneStatus label="ETD">
                                <FormDateInput
                                    name="ETD"
                                    clearInput={() => null}
                                />
                            </OneStatus>
                            <OneStatus label="ETA">
                                <FormDateInput
                                    name="ETA"
                                    clearInput={() => null}
                                />
                            </OneStatus>
                        </DoubleGrid>
                        <DoubleGrid>
                            <FormInput
                                label="POL"
                                name="POLCode"
                                disabled={false}
                            />
                            <FormInput
                                label="POD"
                                name="PODCode"
                                disabled={false}
                            />
                        </DoubleGrid>
                    </section>
                </ModalScreen>
            </form>
        );
    };

    private submit = async (fields: any) => {
        let data: object = {
            PODCode: fields.PODCode ? fields.PODCode.trim() : '',
            POLCode: fields.POLCode ? fields.POLCode.trim() : '',
            MasterBillOfLadingId: this.props.blId,
            VoyageId: '',
        };
        if(this.props.isAir) {
            data = {
                ...data,
                VoyageReference: fields.FlightNumber ? fields.FlightNumber.trim() : '',
            };
        } else {
            data = {
                ...data,
                VesselCode: fields.VesselName ? fields.VesselName.trim() : '',
                VoyageNumber: fields.VoyageReference ? fields.VoyageReference.trim() : '',
            }
        }
        await this.props.addVesselToBl(fields, data, !!this.props.isAir, () => {
                this.props.onAddSuccess();
                this.props.closeModal();
            },
            (e) => {
                this.setState({
                    isErrorDialogVisible: true,
                    error: e.response,
                })
            }
        );
    };
}

const mapStateToProps = (state: IStore): IMapStateToProps => ({
    selectedVessel: state.vessels.blDetails
});

const mapDispatchToProps = (dispatch: ThunkDispatch<any, any, Action>): IDispatch => ({
    addVesselToBl: (body: any, blId: any, isAir: boolean, onSuccess: (vessel: IBLVessel) => void, onRejected: (error: any) => void) => dispatch(addVesselToBl(body, blId, isAir, onSuccess, onRejected)),
});

export const AddNewVesselPopup = compose(
    connect<IMapStateToProps, IDispatch, IAddNewVesselPopupOwnProps, IStore>(mapStateToProps, mapDispatchToProps),
    reduxForm<{}, IAddNewVesselPopupOwnProps>({
        form: FORM_NAME,
    }),
)(_AddNewVesselPopup);
