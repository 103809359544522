import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { RouteComponentProps } from 'react-router-dom';
import { ThunkDispatch } from 'redux-thunk';
import { Action } from 'redux';
import { fetchCblDetails } from 'actions/fact';
import { ErrorDialog } from 'components/error-dialog';
import API from 'constants/api';
import { PageHeader } from 'components/page-header';
import { IStore } from 'reducers/index';
import { ICblDetails } from 'reducers/fact/models';
import { CblDetailsTopBar } from './components/CblDetailsTopBar';
import { Page } from 'components/page-container';
import { CarrierRow } from './components/CarrierRow';
import { ConsigneeRow } from './components/ConsigneeRow';
import { ShipperRow } from './components/ShipperRow';
import { PlacesRow } from './components/PlacesRow';
import { BookingsTable } from './components/BookingsTable';
import { DocumentsTable } from './components/DocumentsTable';
import { ETSTable } from './components/ETSTable';
import { EquipmentsTable } from './components/EquipmentsTable';
import { VoyagesTable } from './components/VoyagesTable';
import { ShipmentsTable } from './components/ShipmentsTable';
import { LoadingAnimation } from 'components/loading';

interface IParams {
    cblId: string;
}

interface IMapDispatchToProps {
    fetchCblDetails: (cblId: number) => Promise<void>;
}

interface IMapStateToProps {
    cblDetails: ICblDetails;
    isCblDetailsLoading: boolean;
}

interface ICblDetailsProps extends RouteComponentProps<IParams>, IMapStateToProps, IMapDispatchToProps {}

const _CblDetails: React.StatelessComponent<ICblDetailsProps> = (props) => {
    const cblId = parseInt(props.match.params.cblId, 10);
    useEffect(() => {
        if (!Number.isNaN(cblId)) {
            props.fetchCblDetails(cblId);
        }
    }, []);

    return(
        <React.Fragment>
            {props.isCblDetailsLoading && <LoadingAnimation />}
            <ErrorDialog endpoint={API.Fact.GetEquipmentDetails} />
            <PageHeader backButton={true}>CBL: {props.cblDetails.number}</PageHeader>
            <CblDetailsTopBar 
                number={props.cblDetails.number}
                transportContractNumber={props.cblDetails.transportContractNumber}
                internalNumber={props.cblDetails.internalNumber}
                createDate={props.cblDetails.createDate}
                updateDate={props.cblDetails.updateDate}
            />
            <Page>
                <CarrierRow 
                    carrierBookingReference={props.cblDetails.carrierBookingReference}
                    carrierBusinessEntityCode={props.cblDetails.carrierBusinessEntityCode}
                    carrierCode={props.cblDetails.carrierCode}
                    carrierConutryCode={props.cblDetails.carrierConutryCode}
                    carrierFACTCode={props.cblDetails.carrierFACTCode}
                    carrierName={props.cblDetails.carrierName}
                />
                <ConsigneeRow 
                    consigneeName={props.cblDetails.consigneeName}
                    consigneeCountryCode={props.cblDetails.consigneeCountryCode}
                    consigneeFACTCode={props.cblDetails.consigneeFACTCode}
                    consigneeSCVCode={props.cblDetails.consigneeSCVCode}
                />
                <ShipperRow 
                    shipperBusinessEntityCode={props.cblDetails.shipperBusinessEntityCode}
                    shipperContryCode={props.cblDetails.shipperContryCode}
                    shipperFACTCode={props.cblDetails.shipperFACTCode}
                    shipperName={props.cblDetails.shipperName}
                    shipperSCVCode={props.cblDetails.shipperSCVCode}
                />
                <PlacesRow 
                    originalPortOfLoadingCode={props.cblDetails.originalPortOfLoadingCode}
                    originalPortOfLoadingName={props.cblDetails.originalPortOfLoadingName}
                    placeOfDeliveryCode={props.cblDetails.placeOfDeliveryCode}
                    placeOfDeliveryName={props.cblDetails.placeOfDeliveryName}
                />
                <BookingsTable bookings={props.cblDetails.bookings} />
                <DocumentsTable documents={props.cblDetails.documents} />
                <ETSTable ets={props.cblDetails.equipmentTransportShipments} />
                <EquipmentsTable equipments={props.cblDetails.equipments} />
                <ShipmentsTable shipments={props.cblDetails.shipments} />
                <VoyagesTable voyages={props.cblDetails.voyages} />
            </Page>
        </React.Fragment>
    );
};

const mapStateToProps = (state: IStore): IMapStateToProps => ({
    cblDetails: state.fact.cblDetails,
    isCblDetailsLoading: state.fact.isCblDetailsLoading
});

const mapDispatchToProps = (dispatch: ThunkDispatch<any, any, Action>): IMapDispatchToProps => ({
    fetchCblDetails: (cblId: number) => dispatch(fetchCblDetails(cblId)),
});

export const CblDetails = connect<IMapStateToProps, IMapDispatchToProps, {}, IStore>(mapStateToProps, mapDispatchToProps)(_CblDetails);