import React from 'react';
import { FormDateInput } from 'components/date-input/FormDateInput';
import { FormInput } from 'components/input/FormInput';
import { FormSelect } from 'components/select/FormSelect';
import { TripleGrid } from 'components/grid/triple-grid';
import { IEquipmentDeliveryDetails } from 'reducers/planning/models';
import { IOption } from 'components/select/Select';

interface IEditFinalDeliveryDetailsModalRow {
    equipmentDeliveryDetails: IEquipmentDeliveryDetails;
    selectedConfigurationId: number;
}

export const FINAL_DELIVERY_DETAILS_SHIFT_OPTIONS: IOption[] = [{
    value: '',
    optionText: 'Select value...'
}, {
    value: 'Morning',
    optionText: 'Morning',
}, {
    value: 'Afternoon',
    optionText: 'Afternoon',
}, {
    value: 'Night',
    optionText: 'Night',
}];

export const EditFinalDeliveryDetailsModalRow = (props: IEditFinalDeliveryDetailsModalRow) => {
    return (
        <React.Fragment>
            <TripleGrid>
                <FormInput
                    label="Customer BE Code"
                    name={`beCode_${props.equipmentDeliveryDetails.equipmentId}`}
                    disabled={true}
                />
                <FormInput
                    label="Customer name"
                    name={`name_${props.equipmentDeliveryDetails.equipmentId}`}
                    disabled={true}
                />
            </TripleGrid>
            <TripleGrid>
                <FormDateInput
                    label="Delivery date"
                    id={`Delivery date_${props.equipmentDeliveryDetails.equipmentId}`}
                    name={`clientDelivery_${props.equipmentDeliveryDetails.equipmentId}`}
                    withTime={true}
                />
                <FormSelect
                    label="Shift"
                    name={`shift_${props.equipmentDeliveryDetails.equipmentId}`}
                    options={FINAL_DELIVERY_DETAILS_SHIFT_OPTIONS}
                />
            </TripleGrid>
            <TripleGrid>
                <FormInput
                    label="Delivery location"
                    name={`deliveryLocation_${props.equipmentDeliveryDetails.equipmentId}`}
                />
                <FormInput
                    label="City"
                    name={`city_${props.equipmentDeliveryDetails.equipmentId}`}
                    disabled={true}
                />
            </TripleGrid>
            <TripleGrid>
                <FormInput
                    label="Country"
                    name={`country_${props.equipmentDeliveryDetails.equipmentId}`}
                    disabled={true}
                />
                <FormInput
                    label="Address"
                    name={`address_${props.equipmentDeliveryDetails.equipmentId}`}
                    disabled={true}
                />
                <FormInput
                    label="Zip code"
                    name={`zipCode_${props.equipmentDeliveryDetails.equipmentId}`}
                    disabled={true}
                />
            </TripleGrid>
        </React.Fragment>
    );
};
