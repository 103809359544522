import React, { FunctionComponent } from 'react';
import { ModalScreen } from 'components/modal-screen';
import { ModalType } from 'components/modal-screen/ModalType';
import { formatDate, formatValueIfNullOrUndefined } from 'utilities/util';
import { OneStatus } from 'components/status-row/OneStatus';
import { StatusRow } from 'components/status-row';
import styled from 'styled-components';
import { DoubleGrid } from 'components/grid/double-grid';
import { IEventLog } from 'reducers/event-logs/models';

interface IEventLogDetailsPopupProps {
    eventLog?: IEventLog;
    visible: boolean;
    closeModal(): void;
}

const SPre = styled.pre`
    word-break: break-all;
`;

export const EventLogDetailsPopup: FunctionComponent<IEventLogDetailsPopupProps> = (props) => {
    return (
        <ModalScreen
            title={`Event log - ${props.eventLog ? props.eventLog.runId : ''}`}
            visible={props.visible}
            modalType={ModalType.m()}
            closeModal={props.closeModal}
            hideSecondaryButton={true}
            primaryButtonTitle="Close"
            primaryButtonFunc={props.closeModal}
        >
            {props.eventLog && (
                <>
                    <StatusRow title="Input body">
                        <SPre>{props.eventLog.inputBody}</SPre>
                    </StatusRow>
                    <StatusRow title="Output body">
                        <SPre>{props.eventLog.body}</SPre>
                    </StatusRow>

                    <StatusRow title="Details">
                        <DoubleGrid>
                            <OneStatus label="Date">
                                {formatDate(props.eventLog.eventLogDate, 'DD-MM-YYYY HH:mm:ss')}
                            </OneStatus>
                            <OneStatus label="Run ID">
                                {formatValueIfNullOrUndefined(props.eventLog.runId)}
                            </OneStatus>
                        </DoubleGrid>
                        <DoubleGrid>
                            <OneStatus label="Status">
                                {formatValueIfNullOrUndefined(props.eventLog.status)}
                            </OneStatus>
                            <OneStatus label="System">
                                {formatValueIfNullOrUndefined(props.eventLog.system)}
                            </OneStatus>
                        </DoubleGrid>
                        <DoubleGrid>
                            <OneStatus label="Message">
                                {formatValueIfNullOrUndefined(props.eventLog.message)}
                            </OneStatus>
                            <OneStatus label="Type">
                                {formatValueIfNullOrUndefined(props.eventLog.type)}
                            </OneStatus>
                        </DoubleGrid>
                        <DoubleGrid>
                            <OneStatus label="Capability">
                                {formatValueIfNullOrUndefined(props.eventLog.capability)}
                            </OneStatus>
                            <OneStatus label="CBL">
                                {formatValueIfNullOrUndefined(props.eventLog.cblNumber)}
                            </OneStatus>
                        </DoubleGrid>
                        <DoubleGrid>
                            <OneStatus label="Vessel">
                                {formatValueIfNullOrUndefined(props.eventLog.vesselNumber)}
                            </OneStatus>
                            <OneStatus label="Voyage">
                                {formatValueIfNullOrUndefined(props.eventLog.voyageNumber)}
                            </OneStatus>
                        </DoubleGrid>
                        <DoubleGrid>
                            <OneStatus label="Equipment">
                                {formatValueIfNullOrUndefined(props.eventLog.equipmentNumber)}
                            </OneStatus>
                            <OneStatus label="PO Number">
                                {formatValueIfNullOrUndefined(props.eventLog.poNumber)}
                            </OneStatus>
                        </DoubleGrid>
                        <DoubleGrid>
                            <OneStatus label="Correlation ID">
                                {formatValueIfNullOrUndefined(props.eventLog.correlationId)}
                            </OneStatus>
                        </DoubleGrid>
                    </StatusRow>
                </>
            )}
        </ModalScreen>
    );
}
