import React from 'react';
import { ModalScreen } from 'components/modal-screen';
import { ModalType } from 'components/modal-screen/ModalType';
import { FormInput } from 'components/input/FormInput';
import { InjectedFormProps, reduxForm, reset } from 'redux-form';
import { Dispatch } from 'redux';
import { DoubleGrid } from 'components/grid/double-grid';
import { FormSelect } from 'components/select/FormSelect';
import { IOption } from 'components/select/Select';
import { IGlobalSettings } from 'reducers/settings/models';

interface IEditedItem {
    key: string;
    fieldName: string;
    value: string;
    fieldType: string;
    availableValues: string[];
}

interface IEditGlobalSettingsModal {
    title: string;
    visible: boolean;
    editedItem: IEditedItem | null;
    closeModal: () => void;
    saveFunction: (configuration: IGlobalSettings) => void;
}

const mapToSelectOption = (inputArray: string[]): IOption[] => {
    const outputArray = inputArray.map((value: string) => {
        return {
            value,
            optionText: value
        };
    });

    return outputArray;
}

const FORM_NAME = 'EDIT_GLOBAL_SETTINGS_MODAL';

const _EditGlobalSettingsModal: React.SFC<InjectedFormProps<{}, IEditGlobalSettingsModal> & IEditGlobalSettingsModal> = (props) => {
    const inputType = () => {
        if (props.editedItem && props.editedItem.fieldType === 'text') {
            return(
                <FormInput
                    label="Value"
                    name="Value"
                />
            );
        }
        if (props.editedItem && props.editedItem.fieldType === 'dropdown') {
            return(
                <FormSelect
                    name="Value"
                    label="Value"
                    options={mapToSelectOption(props.editedItem.availableValues)}
                />
            );
        }
        return(
            <FormInput
                label="Value"
                name="Value"
            />
        );
    }

    return (
        <form onSubmit={props.handleSubmit(props.saveFunction)}>
            <ModalScreen
                title={props.title}
                visible={props.visible}
                modalType={ModalType.s()}
                closeModal={props.closeModal}
                primaryButtonTitle="Done"
                primaryButtonFunc={() => null}
                primaryButtonType="submit"
                secondaryButtonTitle="Cancel"
                secondaryButtonFunc={props.closeModal}
            >
                <DoubleGrid>
                    <FormInput
                        label="Key"
                        name="OptionName"
                        disabled={true}
                    />
                    {inputType()}
                </DoubleGrid>
            </ModalScreen>
        </form>
    );
};

const afterSubmit = (_: any, dispatch: Dispatch) => dispatch(reset(FORM_NAME));

export const EditGlobalSettingsModal = reduxForm<{}, IEditGlobalSettingsModal>({
    form: FORM_NAME,
    onSubmitSuccess: afterSubmit,
    enableReinitialize: true
})(_EditGlobalSettingsModal);
