import React from 'react';
import { DateInput } from 'components/date-input'

interface IFiltersDateInput {
    onChange?: () => void;
    value?: string;
    name: string;
    label: string;
    type: string;
    withTime?: boolean;
    isEndOfDay?: boolean;
    relatedField?: string;
    readOnly?: boolean;
    placeholder?: string;
}

export const FiltersDateInput: React.FunctionComponent<IFiltersDateInput> = (props) => (
    <div className="col-25">
        <DateInput
            id="filter-date-input"
            name={props.name}
            label={props.label}
            onChange={props.onChange as (value: string) => void}
            value={props.value as string}
            relatedField={props.relatedField}
            readOnly={props.readOnly}
            placeholder={props.placeholder}
            {...props}
        />
    </div>
);