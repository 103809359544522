import React from 'react';
import { Page } from 'components/page-container';
import { PageHeader } from 'components/page-header';
import { ConsigneeTable } from './components/ConsigneeTable';
import { PageWithTimeline } from 'components/page-with-timeline';
import { LoadingAnimation } from 'components/loading';
import { ThunkDispatch} from 'redux-thunk';
import { fetchHawbDetails } from 'actions/customs';
import { Action } from 'redux';
import { RouteComponentProps } from 'react-router-dom';
import { connect } from 'react-redux';
import { IStore } from 'reducers/index';
import { HandleError } from 'components/handle-error';
import { HawbTopBar } from './components/HawbTopBar';
import { HawbTable } from './components/HawbTable';
import { FlightTable } from './components/FlightTable';
import { CustomsTypes } from 'constants/comment';
import { ProgressIndicator } from 'components/progress-indicator';
import { IBubble } from 'reducers/vessels/models';
import { UserDetails } from 'components/user-details';
import { IHawbDetails } from 'reducers/customs/models';
import { HoldsAirRow } from 'modules/details-pages/components/HoldsAirRow';
import { Capability } from 'components/timeline';
import { ClearanceTable } from 'views/planning/deliver-planning-container-details/components/ClearanceTable';

interface IHawbDetailsProps extends IDispatch, RouteComponentProps<any>, IMapState {}

interface IDispatch {
    fetchHawbDetails: (id: string, voyageId: string) => Promise<void>;
}

interface IMapState {
    hawbDetails: IHawbDetails;
    isFetchHawbDetailsLoading: boolean;
    isFetchHawbDetailsError: boolean;
    bubbles: IBubble[];
}

class _HawbDetails extends React.Component<IHawbDetailsProps> {
    public componentDidMount() {
        const { id, voyageId } = this.props.match.params;
        this.props.fetchHawbDetails(id, voyageId);
    }

    public render() {
        const { isFetchHawbDetailsError } = this.props;

        return (
            <>
                { this.props.isFetchHawbDetailsLoading && <LoadingAnimation /> }
                <PageHeader backButton={true}>HAWB: {this.props.hawbDetails.hblNumber}</PageHeader>
                <HandleError
                    isUpdateHBLDetailsError={() => null}
                    isFetchHBLFCRDetailsError={isFetchHawbDetailsError}
                />
                <ProgressIndicator data={this.props.bubbles} />
                <HawbTopBar
                    {...this.props.hawbDetails}
                    hblId={this.props.match.params.id}
                    voyageId={this.props.match.params.voyageId}
                    data={this.props.hawbDetails}
                />
                <Page>
                    <PageWithTimeline
                        objectId={this.props.match.params.id}
                        objectType={CustomsTypes.Hbl}
                        isAir={true}
                        capability={Capability.Customs}
                    >
                        <FlightTable
                            flight={this.props.hawbDetails.FlightNumber}
                            pol={this.props.hawbDetails.pol}
                            pod={this.props.hawbDetails.pod}
                        />
                        <ClearanceTable {...this.props.hawbDetails} />
                        <HoldsAirRow data={this.props.hawbDetails.holds} />
                        <ConsigneeTable {...this.props.hawbDetails} />
                        <HawbTable {...this.props.hawbDetails} />
                        <UserDetails
                            user={this.props.hawbDetails.lastUserUpdated}
                            date={this.props.hawbDetails.updateDate}
                        />
                    </PageWithTimeline>
                </Page>
            </>
        );
    }
}

const mapStateToProps = (state: IStore): IMapState => ({
    isFetchHawbDetailsLoading: state.customs.isFetchHawbDetailsLoading,
    hawbDetails: state.customs.hawbDetails,
    isFetchHawbDetailsError: !!state.customs.fetchHawbDetailsError,
    bubbles: state.customs.hawbDetails.bubbleData
});

const mapDispatchToProps = (dispatch: ThunkDispatch<any, any, Action>): IDispatch => ({
    fetchHawbDetails: (id: string,  voyageId: string) => dispatch(fetchHawbDetails(id, voyageId)),
});

export const HawbDetails = connect(mapStateToProps, mapDispatchToProps)(_HawbDetails);
