export enum ClearanceStatus {
    Cleared = 1,
    OnHold = 2,
    InProgress = 3,
}

export enum ClearanceTypes {
    SFD = 'SFD',
    SAD = 'SAD',
    FULL_IMPORT_ENTRY = 'Full import entry',
    DOM_PROC = 'Dom.proc',
}

export const clearanceStatusOptions = [
    { value: '', optionText: 'Select value...' },
    { value: ClearanceStatus.Cleared, optionText: 'Cleared' },
    { value: ClearanceStatus.OnHold, optionText: 'On hold' },
    { value: ClearanceStatus.InProgress, optionText: 'In progress' },
];

export const clearanceTypesOptionsFirst = [
    { value: '', optionText: 'Select value...' },
    { value: ClearanceTypes.SFD, optionText: ClearanceTypes.SFD },
    { value: ClearanceTypes.DOM_PROC, optionText: ClearanceTypes.DOM_PROC },
];

export const clearanceTypesOptionsSecond = [
    { value: '', optionText: 'Select value...' },
    { value: ClearanceTypes.SFD, optionText: ClearanceTypes.SFD },
    { value: ClearanceTypes.SAD, optionText: ClearanceTypes.SAD },
    { value: ClearanceTypes.FULL_IMPORT_ENTRY, optionText: ClearanceTypes.FULL_IMPORT_ENTRY },
    { value: ClearanceTypes.DOM_PROC, optionText: ClearanceTypes.DOM_PROC },
];

export enum HoldTypesEmum {
    XRAY = 1,
    PHYSICAL_INSPECTION = 2,
    DOCUMENT_INSPECTION = 3,
}

export const HoldTypes = {
    [HoldTypesEmum.XRAY]: 'X-Ray',
    [HoldTypesEmum.PHYSICAL_INSPECTION]: 'Physical Inspection',
    [HoldTypesEmum.DOCUMENT_INSPECTION]: 'Document Inspection',
}

export const holdTypesOptions = [
    { value: '', optionText: 'Select value...' },
    { value: HoldTypesEmum.XRAY, optionText: 'X-Ray' },
    { value: HoldTypesEmum.PHYSICAL_INSPECTION, optionText: 'Physical Inspection' },
    { value: HoldTypesEmum.DOCUMENT_INSPECTION, optionText: 'Document Inspection' },
];