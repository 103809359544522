import React, { Component } from 'react';
import { connect } from 'react-redux';
import { IStore } from 'reducers/index';
import { IApiResetErrorAction } from 'reducers/api';
import { Dispatch } from 'redux';
import { resetError } from 'actions/api';
import styled from 'styled-components';

interface IMapStateToProps {
    errors?: string[];
}

interface IMapDispatchToProps {
    resetError(): void;
}

interface IErrorDialogOwnProps {
    /**
     * Endpoint to display display errors for
     */
    endpoint: string;
    /**
     *  When `true` is passed, component will not clear errors when mounted (default: `false`)
     */
    preserveError?: boolean;
}

interface IErrorDialogProps extends IErrorDialogOwnProps, IMapStateToProps, IMapDispatchToProps {}

const SContainer = styled.div`
    display: flex;
    margin-bottom: 16px;
`;

const SErrors = styled.div`
    flex-grow: 1;
    margin: 0 10px;
`;

class _ErrorDialog extends Component<IErrorDialogProps> {
    public static defaultProps = {
        preserveError: false,
    };

    public componentDidMount() {
        if (!this.props.preserveError) {
            this.props.resetError();
        }
    }

    public render() {
        if (!this.props.errors || this.props.errors.length === 0) {
            return null;
        }

        return (
            <SContainer className="message failed">
                <i aria-hidden="true" className="fa fa-times-circle fa-3x" />

                <SErrors>
                    {this.props.errors.map(error => (
                        <div key={error}>{error}</div>
                    ))}
                </SErrors>

                <i id="close_failed" className="fas fa-times" onClick={this.props.resetError} />
            </SContainer>
        );
    }
}

const mapStateToProps = (store: IStore, props: IErrorDialogOwnProps): IMapStateToProps => ({
    errors: store.api.errors[props.endpoint],
});

const mapDispatch = (dispatch: Dispatch<IApiResetErrorAction>, props: IErrorDialogOwnProps) => ({
    resetError: () => dispatch(resetError(props.endpoint)),
});

export const ErrorDialog = connect<IMapStateToProps, IMapDispatchToProps, IErrorDialogOwnProps, IStore>(mapStateToProps, mapDispatch)(_ErrorDialog);
