import React, { FunctionComponent, useRef, useState } from 'react';
import { UploadSection } from 'damco-components';
import { ModalType } from 'components/modal-screen/ModalType';
import { ModalScreen } from 'components/modal-screen';
import { IImportErrors } from 'reducers/import/models';
import { connect } from 'react-redux';
import { ThunkDispatch } from 'redux-thunk';
import { Action } from 'redux';
import { resetImportErrors, resetImportTransportDetailsErrors } from 'actions/import';
import { Accordion } from 'components/accordion';
import { IStore } from 'reducers/index';
import { Dialog } from 'components/dialog';
import { DialogWithDownload } from 'components/dialog/DialogWithFileDownload'
import { downloadFile } from 'utilities/download';

interface IModalImportOwnProps {
    uploadFinalDeliveryDetails: (ref: any) => void;
    uploadTransportDetails: (ref: any) => void;
    cancelMethod: () => void;
    downloadFinalDeliveryDetails: () => void;
    downloadTransportDetails: () => void;
    closeModal: () => void;
    fileFormats: string;
    downloadTitle: string;
    visible: boolean;
    errors: IImportErrors[];
    isAir?: boolean;
}

interface IMapDispatchToProps {
    resetImportErrors: () => void;
    resetImportTransportDetailsErrors: () => void;
}

interface IMapStateToProps {
    transportDetailsErrors: any;
    file: any;
    fileFinalDetails: any;
    successWhileImport: boolean;
    successWhileImportFinalDetails: boolean;
}

interface IModalImport extends IModalImportOwnProps, IMapStateToProps, IMapDispatchToProps {}

const _ModalImport: FunctionComponent<IModalImport> = (props) => {
    const [finalDeliveryClosed, setFinalDelivery] = useState<boolean>(false);
    const [transportDetailsClosed, setTransportDetails] = useState<boolean>(false);

    const [errorWithFileIsVisible, setErrorWithFileVisible] = useState<boolean>(false)

    const ref = useRef<any>();
    const refTransportDetails = useRef<any>();

    const onClose = () => {
        props.closeModal();
        props.resetImportErrors();
        props.resetImportTransportDetailsErrors();
    };

    const renderTransportDetailsErrors = () => {
        if(props.transportDetailsErrors.errors !== undefined) {
            return props.transportDetailsErrors.errors.map((err: any, i: number) => {
                return(
                    <Dialog
                        key={i}
                        isVisible={true}
                        dialogType={400}
                        message={err.value}
                    />
                )
            });
        } else if(props.file && props.file instanceof Blob) {
            return (
                <DialogWithDownload 
                    isVisible={!errorWithFileIsVisible}
                    dialogType={400}
                    closeDialog={() => setErrorWithFileVisible(!errorWithFileIsVisible)}
                    action={() => downloadFile(props.file)}
                />
            )
        }
    };

    const renderFinalDetailsErrors = () => {
        if(props.errors !== undefined && props.errors.length) {
            return props.errors.map((err: any, i: number) => (
                <Dialog
                    key={i}
                    isVisible={true}
                    dialogType={400}
                    message={err.value}
                />
            ));
        } else if(props.fileFinalDetails && props.fileFinalDetails instanceof Blob) {
            return (
                <DialogWithDownload 
                    isVisible={!errorWithFileIsVisible}
                    dialogType={400}
                    closeDialog={() => setErrorWithFileVisible(!errorWithFileIsVisible)}
                    action={() => downloadFile(props.fileFinalDetails)}
                />
            )
        }
        return;
    };

    return(
        <ModalScreen
            title="Import from excel / download template"
            visible={props.visible}
            modalType={ModalType.m()}
            closeModal={onClose}
            primaryButtonTitle="Done"
            primaryButtonType="submit"
            primaryButtonFunc={onClose}
            secondaryButtonTitle="Cancel"
            secondaryButtonFunc={onClose}
        >
            <Accordion
                text="Update final delivery details"
                collapse={finalDeliveryClosed}
                onClick={() => setFinalDelivery(!finalDeliveryClosed)}
            >
                {props.successWhileImportFinalDetails && <Dialog 
                    isVisible={props.successWhileImportFinalDetails}
                    dialogType={200}
                    message={`Excel file has been uploaded successfully.`}
                />}
                {renderFinalDetailsErrors()}
                <UploadSection
                    name="upload-control"
                    ref={ref}
                    multiple={false}
                    uploadMethod={() => props.uploadFinalDeliveryDetails(ref)}
                    cancelMethod={props.cancelMethod}
                    groupUploads={false}
                    fileFormats={props.fileFormats}
                    restrictionText={''}
                    fileLimit={''}
                    downloadTitle={props.downloadTitle}
                    downloadText={''}
                    downloadMethod={props.downloadFinalDeliveryDetails}
                    showDownload={true}
                />
            </Accordion>
            <Accordion
                text="Update transport details"
                collapse={transportDetailsClosed}
                onClick={() => setTransportDetails(!transportDetailsClosed)}
            >
                {props.successWhileImport && <Dialog 
                    isVisible={props.successWhileImport}
                    dialogType={200}
                    message={`Excel file has been uploaded successfully.`}
                />}
                {renderTransportDetailsErrors()}
                <UploadSection
                    name="upload-control2"
                    ref={refTransportDetails}
                    multiple={false}
                    uploadMethod={() => props.uploadTransportDetails(refTransportDetails)}
                    cancelMethod={props.cancelMethod}
                    groupUploads={false}
                    fileFormats={props.fileFormats}
                    restrictionText={''}
                    fileLimit={''}
                    downloadTitle={props.downloadTitle}
                    downloadText={''}
                    downloadMethod={props.downloadTransportDetails}
                    showDownload={true}
                />
            </Accordion>
        </ModalScreen>
    );
}

const mapStateToProps = (state: IStore): IMapStateToProps => ({
    transportDetailsErrors: state.importReducer.importTransportDetailsErrors.errors,
    file: state.importReducer.file,
    fileFinalDetails: state.importReducer.fileFinalDetails,
    successWhileImport: state.importReducer.successWhileImport,
    successWhileImportFinalDetails: state.importReducer.successWhileImportFinalDetails
});

const mapDispatchToProps = (dispatch: ThunkDispatch<any, any, Action>): IMapDispatchToProps => ({
    resetImportErrors: () => dispatch(resetImportErrors()),
    resetImportTransportDetailsErrors: () => dispatch(resetImportTransportDetailsErrors())
});

export const ModalImport = connect<IMapStateToProps, IMapDispatchToProps, IModalImportOwnProps>(mapStateToProps, mapDispatchToProps)(_ModalImport);