import React from 'react';
import { connect } from 'react-redux';
import { Action } from 'redux';
import { ThunkDispatch } from 'redux-thunk';
import {
    fetchContainerTrackingGlobalsConfiguration, updateContainerTrackingGlobalSettings
} from 'actions/settings';
import { GlobalsTable } from 'modules/settings/container-tracking/containers/GlobalsTable';
import { IStore } from 'reducers/index';
import { IGlobalSettings } from 'reducers/settings/models';

interface IDispatch {
    fetchContainerTrackingGlobalsConfiguration: () => {};
    updateContainerTrackingGlobalSettings: (configuration: IGlobalSettings) => void;
}

interface IMapStateToProps {
    globalSettings: IGlobalSettings[];
    isContainerTrackingGlobalSettingsPending: boolean;
}

interface IGlobalsProps extends IMapStateToProps, IDispatch {}

class _Globals extends React.Component<IGlobalsProps> {
    public componentDidMount() {
        this.props.fetchContainerTrackingGlobalsConfiguration()
    }

    public render() {
        return(
            <div>
                <GlobalsTable 
                    isLoading={this.props.isContainerTrackingGlobalSettingsPending}
                    data={this.props.globalSettings}
                    updateSettings={this.props.updateContainerTrackingGlobalSettings}
                    refreshSettings={this.props.fetchContainerTrackingGlobalsConfiguration}
                />
            </div>
        )
    }
}

const mapStateToProps = (state: IStore): IMapStateToProps => ({
    globalSettings: state.settings.containerTrackingGlobalSettings,
    isContainerTrackingGlobalSettingsPending: state.settings.fetchContainerTrackingGlobalsSettingsPending
});

const mapDispatchToProps = (dispatch: ThunkDispatch<any, any, Action>): IDispatch => ({
    fetchContainerTrackingGlobalsConfiguration: () => dispatch(fetchContainerTrackingGlobalsConfiguration()),
    updateContainerTrackingGlobalSettings: (configuration: IGlobalSettings) => dispatch(updateContainerTrackingGlobalSettings(configuration))
});

export const Globals = connect(mapStateToProps, mapDispatchToProps)(_Globals);