import { Column } from 'react-table';
import React from 'react';

export const expandColumn = (): Column => {
    return {
        expander: true,
        style: {
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
        },
        Expander: ({ isExpanded, ...rest }: any) => {
            const arrow = isExpanded ? 'fa fa-angle-up' : 'fa fa-angle-down';
            return (
                <span>
                    <i className={`expander_id ${arrow}`}/>
                </span>
            );
        },
    };
};