import React from 'react';
import { OneStatus } from 'components/status-row/OneStatus';
import { StatusRow } from 'components/status-row';
import { QuarterGrid } from 'components/grid/quarter-grid';
import { formatDate, formatValueIfNullOrUndefined } from 'utilities/util';

interface IVesselRow {
    ata?: string;
    destinationPort?: string;
    eta?: string;
    vesselName?: string;
    voyageReference?: string;
}

export const VesselRow: React.SFC<IVesselRow> = (props: IVesselRow) => (
    <StatusRow title="Vessel">
        <QuarterGrid>
            <OneStatus label="Vessel name">
                {formatValueIfNullOrUndefined(props.vesselName)}
            </OneStatus>
            <OneStatus label="Voyage">
                {formatValueIfNullOrUndefined(props.voyageReference)}
            </OneStatus>
            <OneStatus label="POD">
                {formatValueIfNullOrUndefined(props.destinationPort)}
            </OneStatus>
            <OneStatus label="Current ETA">
                {formatDate(props.eta as string)}
            </OneStatus>
        </QuarterGrid>
        <QuarterGrid>
            <OneStatus label="ATA">
                {formatDate(props.ata as string)}
            </OneStatus>
        </QuarterGrid>
    </StatusRow>
);
