import React from 'react';
import { ColumnFactory } from 'components/data-table/ColumnFactory';
import { DataTable } from 'components/data-table/DamcoTable';
import { ICblDetailsETS } from 'reducers/fact/models';
import { StatusRow } from 'components/status-row';
import { cloneDataWithDisplayNames } from 'utilities/tables';

interface IETSTableProps {
    ets: ICblDetailsETS[];
}

export const ETSTable: React.FunctionComponent<IETSTableProps> = (props) => {
    const data = cloneDataWithDisplayNames(props.ets, 'DD-MM-YYYY, h:mm').map(ets => ({
        ...ets,
        anyIssoSentDisplayName: ets.anyIssoSent ? 'Y' : 'N',
        isCancelledDisplayName: ets.isCancelled ? 'Y' : 'N',
    }));

    return(
        <StatusRow title="Equipment transport shipments">
            <DataTable
                data={data}
                columns={[
                    ColumnFactory.standardColumn('equipmentTransportShipmentDocumentNumberDisplayName', 'Equipment transport shipment document number'),
                    ColumnFactory.standardColumn('equipmentNumberDisplayName', 'Equipment number'),
                    ColumnFactory.standardColumn('shipmentNumberDisplayName', 'Shipment number'),
                    ColumnFactory.standardColumn('sealNumberDisplayName', 'Seal number'),
                    ColumnFactory.standardColumn('anyIssoSentDisplayName', 'Any ISSO sent'),
                    ColumnFactory.standardColumn('isCancelledDisplayName', 'Is cancelled'),
                    ColumnFactory.standardColumn('cblEtsOnETVolumeFactorDisplayName', 'CBL ETS on ET volume factor'),
                    ColumnFactory.standardColumn('etsOnCblVolumeFactorDisplayName', 'ETS on CBL volume factor'),
                    ColumnFactory.standardColumn('etsOnETVolumeFactorDisplayName', 'ETS on ET volume factor'),
                    ColumnFactory.standardColumn('volumeDisplayName', 'Volume'),
                    ColumnFactory.standardColumn('createDateDisplayName', 'Create date'),
                    ColumnFactory.standardColumn('lastUpdateDateDisplayName', 'Last update date'),
                ]}
                withCheckboxes={false}
                reference={null as any}
                tableName="CblDetailsETSTable"
                showPagination={true}
                expandable={false}
            />
        </StatusRow>
    );
};

