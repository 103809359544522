import React from 'react';
import { ColumnFactory } from 'components/data-table/ColumnFactory';
import { DataTable } from 'components/data-table/DamcoTable';
import { downloadFile } from 'utilities/download';
import { connect } from 'react-redux';
import { ThunkDispatch } from 'redux-thunk';
import { Action } from 'redux';
import { exportEquipmentDeliveryAndReturnEquipmentToExcel } from 'actions/export';
import { EditEmptyReturnDetailsPopup } from 'modules/delivery-and-return/ocean/components/EditEmptyReturnDetailsPopup';
import { EditTransportationDetailsModal } from 'views/delivery-and-return/equipment-details/components/EditTransportationDetailsModal';
import { IStore } from 'reducers/index';
import { IEquipmentDetails } from 'reducers/planning/models';
import { getAirDeliveryDetails, getAirDelivery } from 'actions/planning';
import { LoadingAnimation } from 'components/loading';
import { mapStatusToStringForDelivery } from 'constants/statuses';
import { Transport_Mode, Transport_Type } from 'constants/transport';
import { hauliersToEnum, getArrayOfButtons } from 'utilities/util';
import { ISettingsModel } from 'reducers/settings/models';
import { fetchDeliveryPlanningHaulierSettings } from 'actions/settings';
// tslint:disable-next-line: no-submodule-imports
import { withPermissions, IWithPermissions } from 'src/HOC/withPermissions';
import { BUSINNESS_FUNCTIONS } from 'constants/roles';

interface IMapStateToProps {
    airDeliveryDetails: IEquipmentDetails;
    isGetAirDeliveryDetailsPending: boolean;
    isAirDeliveryLoading: boolean;
    deliveryPlanningHaulierSettings: ISettingsModel[];
    fetchDeliveryPlanningHaulierSettingsPending: boolean;
}

interface IMapDispatchToProps {
    exportEquipmentDeliveryAndReturnEquipmentToExcel: () => Promise<void>;
    getAirDeliveryDetails: (id: number) => Promise<void>;
    fetchDeliveryPlanningHaulierSettings: () => void;
    getAirDelivery: () => void;
}

interface IAirDeliveryTableOwnProps {
    data: any[];
    pageCount: number;
    fetchEquipments: any;
}

interface IAirDeliveryTableProps extends IWithPermissions, IAirDeliveryTableOwnProps, IMapStateToProps, IMapDispatchToProps {}

interface IAirDeliveryTableState {
    isUpdateFinalDetailsModalOpened: boolean;
    isUpdateTransportationModalOpened: boolean;
}

class _AirDeliveryListTable extends React.Component<IAirDeliveryTableProps, IAirDeliveryTableState> {
    public dataTable = React.createRef<any>();

    public state: IAirDeliveryTableState = {
        isUpdateFinalDetailsModalOpened: false,
        isUpdateTransportationModalOpened: false
    };

    public componentDidMount() {
        this.props.fetchDeliveryPlanningHaulierSettings();
    }

    public render() {
        const hauliers = hauliersToEnum(this.props.deliveryPlanningHaulierSettings);
        const data = this.props.data.map((obj: any) => ({
            ...obj,
            HAWBNumberDisplayName: [obj.HAWBNumber, `/delivery-and-return/air/${obj.id}`],
            deliveryStatusDisplayName: mapStatusToStringForDelivery(obj.deliveryStatus),
            children: obj.deliveries.map((child: any) => ({
                ...child,
                transportModeDisplayName: Transport_Mode[child.transportMode],
                transportTypeDisplayName: Transport_Type[child.transportType],
                haulierDisplayName: hauliers[child.hauliers],
                parentNode: 0
            }))
        }));

        return(
            <React.Fragment>
                {(this.props.isGetAirDeliveryDetailsPending || this.props.isAirDeliveryLoading || this.props.fetchDeliveryPlanningHaulierSettingsPending) && <LoadingAnimation />}
                <DataTable
                    data={data}
                    columns={[
                        ColumnFactory.linkedColumn('HAWBNumberDisplayName', 'HAWB Number'),
                        ColumnFactory.standardColumn('isDangerousGoodsDisplayName', 'DG'),
                        ColumnFactory.standardColumn('deliveryPlannedDateDisplayName', 'Planned delivery'),
                        ColumnFactory.standardColumn('deliveryActualDateDisplayName', 'Actual Delivery'),
                        ColumnFactory.standardColumn('lastLocationDisplayName', 'Last location'),
                        ColumnFactory.standardColumn('deliveryStatusDisplayName', 'Status'),
                    ]}
                    subColumns={[
                        ColumnFactory.standardColumn('transportModeDisplayName', 'Transport mode'),
                        ColumnFactory.standardColumn('transportTypeDisplayName', 'Transport type'),
                        ColumnFactory.standardColumn('haulierDisplayName', 'Haulier'),
                        ColumnFactory.standardColumn('isTransportOrderSentDisplayName', 'TO sent'),
                        ColumnFactory.standardColumn('pickupLocationDisplayName', 'Pickup location'),
                        ColumnFactory.standardColumn('estimatedPickupDateDisplayName', 'Estimated date'),
                        ColumnFactory.standardColumn('pickupDateDisplayName', 'Pickup date'),
                        ColumnFactory.standardColumn('dropOffLocationDisplayName', 'Drop-off location'),
                        ColumnFactory.standardColumn('estimatedDropOffDateDisplayName', 'Estimated date'),
                        ColumnFactory.standardColumn('dropOffDateDisplayName', 'Drop-off date'),
                        ColumnFactory.standardColumn('deliveryReferenceDisplayName', 'Delivery reference'),
                    ]}
                    withCheckboxes={true}
                    selectVariant="single"
                    expandable={true}
                    reference={this.dataTable}
                    tableName="DnRAirDeliveryList"
                    actions={{
                        primaryActionIsMoreBtn: false,
                        primaryActionLabel: 'Export to excel',
                        primaryAction: this.exportToExcel,
                        parentContextualActions: getArrayOfButtons(
                            this.props.permissions,
                            [
                                { isMoreBtn: false, label: 'Update final delivery details', action: this.toggleEditReturnDetailsModal },
                                { isMoreBtn: false, label: 'Update transportation details', action: this.toggleEditTransportationDetailsModal }
                            ])
                    }}
                    manualPagination={true}
                    fetchData={this.props.fetchEquipments}
                    pageCount={this.props.pageCount}
                    showColumnOptions={true}
                />
                {this.state.isUpdateFinalDetailsModalOpened && <EditEmptyReturnDetailsPopup
                    visible={this.state.isUpdateFinalDetailsModalOpened}
                    closeModal={this.toggleEditReturnDetailsModal}
                    initialValues={this.props.airDeliveryDetails}
                    refetchFunction={this.props.getAirDelivery}
                    isAir={true}
                    status={this.props.airDeliveryDetails.deliveryStatusEnum}
                />}
                {this.state.isUpdateTransportationModalOpened && <EditTransportationDetailsModal
                    visible={this.state.isUpdateTransportationModalOpened}
                    closeModal={this.toggleEditTransportationDetailsModal}
                    isAir={false}
                    id={this.getFirstId()}
                    refetchFunction={this.props.getAirDelivery}
                />}
            </React.Fragment>
        );
    };

    private exportToExcel = async () => {
        const res = await this.props.exportEquipmentDeliveryAndReturnEquipmentToExcel();
        await downloadFile(res);
    };

    private toggleEditReturnDetailsModal = async () => {
        if(!this.state.isUpdateFinalDetailsModalOpened) {
            await this.props.getAirDeliveryDetails(this.getFirstId());
        }
        this.setState((state) => ({
            isUpdateFinalDetailsModalOpened: !state.isUpdateFinalDetailsModalOpened,
        }));
    };

    private toggleEditTransportationDetailsModal = () => this.setState((state) => ({
        isUpdateTransportationModalOpened: !state.isUpdateTransportationModalOpened
    }));

    private getFirstId = () => {
        const selectedItems = this.dataTable.current ? this.dataTable.current.getSelectedItems() : [];

        return selectedItems[0].id;
    };
}

const ROLES: BUSINNESS_FUNCTIONS[] = [
    BUSINNESS_FUNCTIONS.AIR_DELIVERY_EQUIPMENT_UPDATE_DELIVERY_DETAILS,
    BUSINNESS_FUNCTIONS.AIR_DELIVERY_EDIT_TRANSPORTATION_DETAILS
];

const mapStateToProps = (state: IStore): IMapStateToProps => ({
    airDeliveryDetails: state.planning.airDeliveryDetails,
    isGetAirDeliveryDetailsPending: state.planning.isGetAirDeliveryDetailsPending,
    isAirDeliveryLoading: state.planning.isGetAirReturnPending,
    deliveryPlanningHaulierSettings: state.settings.deliveryPlanningHaulierSettings,
    fetchDeliveryPlanningHaulierSettingsPending: state.settings.fetchDeliveryPlanningHaulierSettingsPending
});

const mapDispatchToProps = (dispatch: ThunkDispatch<any, any, Action>): IMapDispatchToProps => ({
    exportEquipmentDeliveryAndReturnEquipmentToExcel: () => dispatch(exportEquipmentDeliveryAndReturnEquipmentToExcel(true)),
    getAirDeliveryDetails: (id: number) => dispatch(getAirDeliveryDetails(id)),
    fetchDeliveryPlanningHaulierSettings: () => dispatch(fetchDeliveryPlanningHaulierSettings(true)),
    getAirDelivery: () => dispatch(getAirDelivery(1, 10)),
});

const __AirDeliveryListTable = connect<IMapStateToProps, IMapDispatchToProps, IAirDeliveryTableOwnProps, IStore>(mapStateToProps, mapDispatchToProps)(_AirDeliveryListTable);
export const AirDeliveryListTable = withPermissions(__AirDeliveryListTable, ROLES) as any;
