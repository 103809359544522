import { callApiGet, callApiPost, callApiPut } from 'utilities/apiCaller';
import { TYPES } from 'action-types/vessels';
import { TYPES as FILTER_TYPES } from 'action-types/filters';
import { ThunkDispatch } from 'redux-thunk';
import { Action } from 'redux';
import { IBLVessel } from 'reducers/vessels/models';
import {IStore} from 'reducers/index';
import { applyFilters, generateError, generateErrors } from 'utilities/util';
import API from 'constants/api';
import { Dispatch } from 'redux';

export interface IQueryWithPagination {
    PageNumber: number;
    PageSize: number;
}

export interface IBodyCommentVessel {
    ObjectId: number;
    ObjectType: string;
    user?: string;
    content: string;
}

export interface IMawbParams {
    VoyageId: number,
    ETA: string | Date;
    ETD: string | Date;
}

export const fetchVessels = (pageNumber: number, pageSize: number) => {
    return async (dispatch: ThunkDispatch<any, any, Action>, getState: () => IStore) => {
        dispatch({ type: TYPES.FETCH_VESSELS.PENDING });
        try {
            const data = applyFilters(getState, {}, {
                PageNumber: pageNumber,
                Page: pageNumber,
                PageSize: pageSize
            });
            data.Filters.TransportMode = 'Ocean';
            const res = await callApiPost(API.ContainerTracking.GetVessels, data, {headers: {TransportMode: 'Ocean'}});
            dispatch({ type: TYPES.FETCH_VESSELS.FULFILLED, ...res.data });
        } catch (e) {
            const errors = generateErrors(e);
            dispatch({ type: TYPES.FETCH_VESSELS.REJECTED, errors });
        }
    }
};

export const fetchVesselDetails = (voyageId: number, isAir: boolean) => {
    return async (dispatch: ThunkDispatch<any, any, Action>) => {
        dispatch({ type: TYPES.FETCH_VESSEL_DETAILS.PENDING });
        try {
            const res = await callApiGet(API.ContainerTracking.GetVesselDetails, { voyageId }, {headers: {TransportMode: isAir ? 'Air' : 'Ocean'}});
            dispatch({ type: TYPES.FETCH_VESSEL_DETAILS.FULFILLED, vesselDetails: res.data });
        } catch (e) {
            const errors = generateErrors(e);
            dispatch({ type: TYPES.FETCH_VESSEL_DETAILS.REJECTED, errors });
        }
    };
};

export const fetchBLList = (pageNumber?: number, pageSize?: number) => {
    return async (dispatch: ThunkDispatch<any, any , Action>, getState: () => IStore) => {
        dispatch({ type: TYPES.FETCH_BL_LIST.PENDING });
        try {
            const res = await callApiPost(API.ContainerTracking.GetBLList, applyFilters(getState, { isAirInFilters: false } , {
                PageNumber: pageNumber,
                Page: pageNumber,
                PageSize: pageSize
            }), {headers: {TransportMode: 'Ocean'}});
            const blList = res.data;
            dispatch({ type: TYPES.FETCH_BL_LIST.FULFILLED, ...blList });
            dispatch({ type: FILTER_TYPES.SET_TOTAL_COUNT, totalCount: res.data.totalCount });
        } catch (e) {
            const errors = generateErrors(e);
            dispatch({ type: TYPES.FETCH_BL_LIST.REJECTED, errors });
        }
    }
};

export const fetchBLDetails = (blId: number): any => {
    return async (dispatch: ThunkDispatch<any, any , Action>) => {
        dispatch({ type: TYPES.FETCH_BL_DETAILS.PENDING });
        try {
            const res = await callApiGet(API.ContainerTracking.GetBlDetails, { blId }, {headers: {TransportMode: 'Ocean'}});
            dispatch({ type: TYPES.FETCH_BL_DETAILS.FULFILLED, blDetails: res.data });
        } catch (e) {
            const errors = generateErrors(e);
            dispatch({ type: TYPES.FETCH_BL_DETAILS.REJECTED, errors });
        }
    }
};

export const fetchMAWBDetails = (blId: number) => {
    return async (dispatch: ThunkDispatch<any, any, Action>) => {
        dispatch({ type: TYPES.FETCH_MAWB_DETAILS.PENDING });
        try {
            const res = await callApiGet(API.ContainerTracking.GetBlDetails, { blId }, {headers: {TransportMode: 'Air'}});
            dispatch({ type: TYPES.FETCH_MAWB_DETAILS.FULFILLED, mawbDetails: res.data })
        } catch (e) {
            const errors = generateErrors(e);
            dispatch({ type: TYPES.FETCH_MAWB_DETAILS.REJECTED, errors });
        }
    };
};

const getObjectValues = (object: object, values: string[]) => values.reduce((prev: object, value: string) => ({
    ...prev,
    [value]: object[value],
}), {});

export const editVesselDetails = (fields: any, isAir: boolean, onSuccess?: () => void, onRejected?: (error: any) => void) => {
    return async (dispatch: ThunkDispatch<any, any, Action>) => {
        dispatch({ type: TYPES.EDIT_VESSEL_PENDING.PENDING });
        const data = getObjectValues(fields, [
            'VesselCode',
            'VoyageId',
            'VoyageReference',
            'VesselCode',
            'VesselName',
            'ATA',
            'ATD',
            'ETA',
            'ETD',
            'POLCode',
            'PODCode',
            'ReasonCode',
            'Status',
        ]);
        try {
            await callApiPost(API.ContainerTracking.EditVessel, data, {headers: {TransportMode: isAir ? 'Air' : 'Ocean'}});
            dispatch({ type: TYPES.EDIT_VESSEL_PENDING.FULFILLED });
            if(onSuccess) {
                onSuccess();
            }
        } catch (e) {
            const errors = generateErrors(e);
            dispatch({ type: TYPES.EDIT_VESSEL_PENDING.REJECTED, errors });
            if(onRejected) {
                onRejected(e);
            }
            throw e;
        }

    }
};

export const resetEditVesselDetailsError = () => (dispatch: Dispatch) => {
    dispatch({type: TYPES.EDIT_VESSEL_PENDING.RESET_ERROR });
}

export const editVesselDetailsOnCBLLevel = (fields: any, blId: number, onSuccess?: () => void, onRejected?: (error: any) => void) => {
    return async (dispatch: ThunkDispatch<any, any, Action>, getState: () => IStore) => {
        dispatch({ type: TYPES.EDIT_VESSEL_ON_CBL_LEVEL.PENDING });
        const data = getObjectValues(fields, [
            'VesselCode',
            'VoyageId',
            'VoyageReference',
            'VesselCode',
            'VesselName',
            'ATA',
            'ATD',
            'ETA',
            'ETD',
            'POL',
            'POD',
            'ReasonCode',
            'Status',
        ]);
        try {
            await callApiPost(API.ContainerTracking.EditVesselDetailsOnCBLLevel, data, {headers: {TransportMode: 'Air'}});
            dispatch({
                type: TYPES.EDIT_VESSEL_ON_CBL_LEVEL.FULFILLED,
                blId,
                vessel: {
                    voyageId: fields.VoyageId,
                    etd: fields.ETD,
                    currentETA: fields.ETA,
                }
            });
            if(fields.comment) {
                const user = getState().user.user;
                dispatch(sendCommentToVessel({
                    content: fields.comment,
                    user: user.name,
                    ObjectId: fields.VoyageId,
                    ObjectType: 'Vessel',
                }))
            }
            if(onSuccess) {
                onSuccess();
            }
        } catch (e) {
            const errors = generateErrors(e);
            dispatch({ type: TYPES.EDIT_VESSEL_ON_CBL_LEVEL.REJECTED, errors });
            if(onRejected) {
                onRejected(e);
            }
        }
    }
};
export const sendCommentToVessel = (data: IBodyCommentVessel, onSuccess?: () => void, onRejected?: (error: any) => void) => {
    return async (dispatch: ThunkDispatch<any, any, Action>) => {
        dispatch({ type: TYPES.SEND_COMMENT_TO_VESSEL.PENDING });
        try {
            await callApiPut(API.Comments.Add, data, {headers: {TransportMode: 'Air'}});
            dispatch({ type: TYPES.SEND_COMMENT_TO_VESSEL.FULFILLED });
            if(onSuccess) {
                onSuccess();
            }
        } catch (e) {
            const errors = generateErrors(e);
            dispatch({ type: TYPES.SEND_COMMENT_TO_VESSEL.REJECTED, errors });
            if(onRejected) {
                onRejected(e);
            }
        }
    }
};

export const addVesselToBl = (body: any, blId: any, isAir: boolean, onSuccess: (vessel: IBLVessel) => void, onRejected: (error: any) => void) => {
    return async (dispatch: ThunkDispatch<any, any, Action>) => {
        dispatch({ type: TYPES.ADD_VESSEL_TO_BL.PENDING });
        let data = getObjectValues(body, [
            'VoyageId',
            'ETD',
            'ETA',
            'POL',
            'POD',
            'VoyageNumber',
            'VesselCode',
        ]);
        data = {...data, ...blId};
        try {
            const res = await callApiPost(API.ContainerTracking.AddVesselToBl, data, {headers: {TransportMode: isAir ? 'Air' : 'Ocean'}});
            dispatch({ type: TYPES.ADD_VESSEL_TO_BL.FULFILLED });
            if(onSuccess) {
                onSuccess(res.data);
            }
        } catch (e) {
            const errors = generateErrors(e);
            dispatch({ type: TYPES.ADD_VESSEL_TO_BL.REJECTED, errors });
            if(onRejected) {
                onRejected(e);
            }
        }
    }
};

export const fetchMawbList = (pageNumber?: number, pageSize?: number) => {
    return async (dispatch: ThunkDispatch<any, any , Action>, getState: () => IStore) => {
        dispatch({ type: TYPES.FETCH_MAWB_LIST.PENDING });
        try {
            const res = await callApiPost(API.ContainerTracking.AirGetMawbList, applyFilters(getState, { isAirInFilters: true }, {
                Page: pageNumber,
                PageNumber: pageNumber,
                PageSize: pageSize
            }), {headers: {TransportMode: 'Air'}});
            const blList = res.data;
            dispatch({ type: TYPES.FETCH_MAWB_LIST.FULFILLED, ...blList });
            dispatch({ type: FILTER_TYPES.SET_TOTAL_COUNT, totalCount: res.data.totalCount });
        } catch (e) {
            const errors = generateErrors(e);
            dispatch({ type: TYPES.FETCH_MAWB_LIST.REJECTED, errors });
        }
    }
};

export const saveMawbDetails = (params: IMawbParams) => {
    return async (dispatch: ThunkDispatch<any, any, Action>) => {
        dispatch({ type: TYPES.EDIT_MAWB_DETAILS.PENDING });
        try {
            await callApiPost(API.ContainerTracking.EditVesselDetails, params, { headers: { TransportMode: 'Air' } });
            dispatch({
                type:   TYPES.EDIT_MAWB_DETAILS.FULFILLED
            });
        } catch(e) {
            const error = generateError(e);
            dispatch({ type: TYPES.EDIT_MAWB_DETAILS.REJECTED, error });
            throw e;
        }
    }
};

export const resetSaveMawbDetailsError = () => (dispatch: Dispatch) => {
    dispatch({type: TYPES.EDIT_MAWB_DETAILS.RESET_ERROR });
}

export const openCBLDetailsModal = (blId: number) => ({
    type: TYPES.OPEN_CBL_DETAILS_MODAL,
    blId,
});

export const closeCBLDetailsModal = () => ({
    type: TYPES.CLOSE_CBL_DETAILS_MODAL,
});

export const fetchMilestones = (pageNumber: number, pageSize: number) => {
    return async (dispatch: ThunkDispatch<any, any, Action>, getState: () => IStore) => {
        dispatch({ type: TYPES.FETCH_MILESTONES_LIST.PENDING });
        try {
            const data = applyFilters(getState, {}, {
                PageNumber: pageNumber,
                Page: pageNumber,
                PageSize: pageSize
            });
            data.Filters.TransportMode = 'Ocean';
            const res = await callApiPost(API.ContainerTracking.GetMilestones, data, {headers: {TransportMode: 'Ocean'}});
            dispatch({ type: TYPES.FETCH_MILESTONES_LIST.FULFILLED, ...res.data });
        } catch (e) {
            const errors = generateErrors(e);
            dispatch({ type: TYPES.FETCH_MILESTONES_LIST.REJECTED, errors });
        }
    }
};

export const updateMilestone = (fields: any) => {
    return async (dispatch: ThunkDispatch<any, any, Action>) => {
        dispatch({ type: TYPES.UPDATE_MILESTONES.PENDING });
        try {
            await callApiPost(API.ContainerTracking.UpdateMilestones, fields, {headers: {TransportMode: 'Air'}});
            dispatch({type: TYPES.UPDATE_MILESTONES.FULFILLED,});
        } catch (e) {
            const errors = generateErrors(e);
            dispatch({ type: TYPES.UPDATE_MILESTONES.REJECTED, errors });
            throw e;
        }
    }
};

export const fetchMRNDetails = (blId: number[], isAir?: boolean) => {
    return async (dispatch: ThunkDispatch<any, any, Action>) => {
        dispatch({ type: TYPES.FETCH_MRN_DETAILS.PENDING });
        try {
            const res = await callApiGet(API.ContainerTracking.GetMRNData(blId), undefined, {headers: {TransportMode: isAir ? 'Air' : 'Ocean'}});
            dispatch({ type: TYPES.FETCH_MRN_DETAILS.FULFILLED, result: res.data });
        } catch (e) {
            const errors = generateErrors(e);
            dispatch({ type: TYPES.FETCH_MRN_DETAILS.REJECTED, errors });
        }
    }
}

export const saveMRNDetails = (data: any, isAir?: boolean) => {
    return async (dispatch: ThunkDispatch<any, any, Action>) => {
        dispatch({ type: TYPES.SAVE_MRN_DETAILS.PENDING });
        try {
            const res = await callApiPost(API.ContainerTracking.SaveMRNData, data, {headers: {TransportMode: isAir ? 'Air' : 'Ocean'}});
            dispatch({ type: TYPES.SAVE_MRN_DETAILS.FULFILLED, result: res.data });
        } catch (e) {
            const errors = generateErrors(e);
            dispatch({ type: TYPES.SAVE_MRN_DETAILS.REJECTED, errors });
        }
    }
}
