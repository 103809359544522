import React, { FunctionComponent, useEffect } from 'react';
import { InjectedFormProps, reduxForm } from 'redux-form';
import { FormInput } from 'components/input/FormInput';
import { FormDateInput } from 'components/date-input/FormDateInput';
import { QuarterGrid } from 'components/grid/quarter-grid';
import { FormSelect } from 'components/select/FormSelect';
import { clearanceStatusOptions, clearanceTypesOptionsSecond } from 'constants/customs';
import { IStore } from 'reducers/index';
import { ThunkDispatch } from 'redux-thunk';
import { Action, compose } from 'redux';
import { connect } from 'react-redux';
import { ISettingsModel } from 'reducers/settings/models';
import { IOption } from 'components/select/Select';
import { getHaulierText } from 'utilities/util';
import { fetchCustomsBrokerSettings } from 'actions/settings';

export const FORM_NAME = 'EDIT_HBL_CLEARANCE_DETAILS';

interface IMapStateToProps {
    brokers: ISettingsModel[];
}

interface IMapDispatchToProps {
    getBrokers: () => void;
}

interface IUpdateHBLCustomsClearanceFormOwnProps {
    isAir?: boolean;
}

type IUpdateHBLCustomsClearanceFormProps = IUpdateHBLCustomsClearanceFormOwnProps
    & InjectedFormProps<{}, IUpdateHBLCustomsClearanceFormOwnProps>
    & IMapStateToProps
    & IMapDispatchToProps;

const _UpdateHBLCustomsClearanceForm: FunctionComponent<IUpdateHBLCustomsClearanceFormProps> = (props) => {
    useEffect(() => {
        props.getBrokers();
    }, []);

    const brokerOptions: IOption[] = [
        { value: '', optionText: 'Select value...' },
        ...props.brokers.map(broker => ({
            optionText: getHaulierText(broker),
            value: broker.id
        }))
    ];

    return (
        <form>
            <section className="page-container">
                <QuarterGrid>
                    <FormSelect
                        label="Clearance type"
                        name="CL1_Type"
                        options={clearanceTypesOptionsSecond}
                    />
                </QuarterGrid>
                <QuarterGrid>
                    <FormSelect
                        label="Broker"
                        name="CL1_Broker"
                        options={brokerOptions}
                    />
                    <FormDateInput
                        label="Customs cleared date"
                        name="CL1_StatusDate"
                        withTime={props.isAir ? true : false}
                    />
                    <FormInput
                        label="Comments"
                        name="CL1_Comments"
                    />
                    <FormSelect
                        label="Status"
                        name="CL1_Status"
                        options={clearanceStatusOptions}
                    />
                </QuarterGrid>
                <QuarterGrid>
                    <FormInput
                        label="EIDR"
                        name="EIDR"
                    />
                    <FormInput
                        label="SFD"
                        name="SFD"
                    />
                    <FormInput
                        label="UCR"
                        name="UCR"
                    />

                </QuarterGrid>
            </section>
        </form>
    );
}

const mapStateToProps = (state: IStore): IMapStateToProps => ({
    brokers: state.settings.customsBrokerSettings,
});

const mapDispatchToProps = (dispatch: ThunkDispatch<{}, {}, Action>): IMapDispatchToProps => ({
    getBrokers: () => dispatch(fetchCustomsBrokerSettings(true))
});

export const UpdateHBLCustomsClearanceForm = compose(
    reduxForm<{}, IUpdateHBLCustomsClearanceFormOwnProps>({
        form: FORM_NAME,
        enableReinitialize: true
    }),
    connect<IMapStateToProps, IMapDispatchToProps, IUpdateHBLCustomsClearanceFormOwnProps, IStore>(mapStateToProps, mapDispatchToProps),
)(_UpdateHBLCustomsClearanceForm);