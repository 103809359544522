import React from 'react';
import { OneStatus } from 'components/status-row/OneStatus';
import { StatusRow } from 'components/status-row';
import { QuarterGrid } from 'components/grid/quarter-grid';
import { formatValueIfNullOrUndefined } from 'utilities/util';

interface IEquipmentRow {
    equipmentService?: string;
    equipmentSizeType?: string;
    grossWeight?: number;
    packageType?: string;
    sealNumber?: string;
    shipmentType?: string;
    totalPackages?: number;
    volume?: number;
    isAir?: boolean;
}

export const EquipmentRow: React.SFC<IEquipmentRow> = (props: IEquipmentRow) => (
    <StatusRow title="Equipment">
        <QuarterGrid>
            <OneStatus label="Service">
                {formatValueIfNullOrUndefined(props.equipmentService)}
            </OneStatus>
            <OneStatus label="Shipment Type">
                {formatValueIfNullOrUndefined(props.shipmentType)}
            </OneStatus>
            <OneStatus label="Size/Type">
                {formatValueIfNullOrUndefined(props.equipmentSizeType)}
            </OneStatus>
            {!props.isAir && <OneStatus label="Seal number">
                {formatValueIfNullOrUndefined(props.sealNumber)}
            </OneStatus>}
        </QuarterGrid>
        <QuarterGrid>
            <OneStatus label="Total packages">
                {formatValueIfNullOrUndefined(props.totalPackages)}
            </OneStatus>
            <OneStatus label="Package type">
                {formatValueIfNullOrUndefined(props.packageType)}
            </OneStatus>
            <OneStatus label="Weight">
                {formatValueIfNullOrUndefined(props.grossWeight)}
            </OneStatus>
            <OneStatus label="Volume">
                {formatValueIfNullOrUndefined(props.volume)}
            </OneStatus>
        </QuarterGrid>
    </StatusRow>
);
