export enum FeeType {
    'Demurrage' = 1,
    'Detention' = 2
}

export enum ContractFromAndTo {
    'ATA' = 1,
    'Gate Out' = 2,
    'ATD' = 3,
    'Empty Return' = 4,
}