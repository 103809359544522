import React, { Component } from 'react';
import { LoadingAnimation } from 'components/loading';
import { ColumnFactory } from 'components/data-table/ColumnFactory';
import { downloadFile } from 'utilities/download';
import { ThunkDispatch } from 'redux-thunk';
import { exportCustomsToExcel } from 'actions/export';
import { Action } from 'redux';
import { connect } from 'react-redux';
import { DataTable } from 'components/data-table/DamcoTable';
import { NotifyCustomerModal } from 'modules/modals/components/NotifyCustomerModalForCustoms';
import { UpdateHawbModal } from './UpdateHAWBModal';
import { CTReasonCodes } from 'constants/reasonCodes';
import { uniqWith, isEqual, xorWith } from 'lodash';
import { fetchHawbDetailsOnList } from 'actions/customs';
import {
  mapStatusToStringForCT,
  mapStatusToStringForCustoms,
} from 'constants/statuses';
// tslint:disable-next-line: no-submodule-imports
import { withPermissions, IWithPermissions } from 'src/HOC/withPermissions';
import { BUSINNESS_FUNCTIONS } from 'constants/roles';
import { getArrayOfButtons } from 'utilities/util';
import { IStore } from 'reducers/index';
import { Dialog } from 'components/dialog';

interface IMapDispatchToProps {
  exportCustomsToExcel: (isAir: boolean) => Promise<any>;
  fetchHawbDetails: (id: string, voyageId: string) => Promise<void>;
}

interface IAirClearanceTableOwnProps {
  data: any;
  isLoading: boolean;
  pageCount: number;
  fetchBls: any;
}
interface IAirClearanceTableState {
  isEditMultipleContainersModal: boolean;
  isNotifyCustomerModalOpened: boolean;
  details: any[];
  isErrorDialogOpened: boolean;
  errorMessage: string;
}

interface IAirClearanceTableProps
  extends IWithPermissions,
    IAirClearanceTableOwnProps,
    IMapDispatchToProps {}

class _AirClearance extends Component<
  IAirClearanceTableProps,
  IAirClearanceTableState
> {
  public dataTable = React.createRef<any>();

  public state: IAirClearanceTableState = {
    isEditMultipleContainersModal: false,
    isNotifyCustomerModalOpened: false,
    details: [],
    isErrorDialogOpened: false,
    errorMessage: 'Some error occurred',
  };

  public componentDidUpdate(prevProps: IAirClearanceTableProps) {
    const isArrayEqual = (x: any[], y: any[]) =>
      xorWith(x, y, isEqual).length === 0;

    if (!isArrayEqual(this.props.data, prevProps.data)) {
      this.setState({ isErrorDialogOpened: false });
    }
  }

  public render() {
    const data = this.props.data.map((obj: any, index: number) => ({
      ...obj,
      statusDisplayName: mapStatusToStringForCT(obj.status),
      children: obj.hblEquipmentData.map((child: any, localIndex: number) => ({
        ...child,
        id: index.toFixed(1) + localIndex / 10,
        hblNumberDisplayName: [
          child.hblNumber,
          `/customs/air-clearance/${child.hblId}/${obj.voyageId}`,
        ],
        customsStatusDisplayName: mapStatusToStringForCustoms(
          child.customsStatus
        ),
        parentNode: 0,
        voyageId: obj.voyageId,
      })),
    }));

    return (
      <>
        {this.props.isLoading && <LoadingAnimation />}
        {this.state.isErrorDialogOpened && (
          <Dialog
            isVisible={this.state.isErrorDialogOpened}
            message={this.state.errorMessage}
            closeDialog={this.toggleErrorDialog}
          />
        )}
        <DataTable
          data={data}
          columns={[
            ColumnFactory.standardColumn(
              'vesselNameDisplayName',
              'Flight number'
            ),
            ColumnFactory.standardColumn('polDisplayName', 'POL'),
            ColumnFactory.standardColumn('podDisplayName', 'POD'),
            ColumnFactory.standardColumn('newETADisplayName', 'New ETA'),
            ColumnFactory.standardColumn('ataDisplayName', 'ATA'),
            ColumnFactory.standardColumn(
              'daysTillETADisplayName',
              'Days till ETA'
            ),
            ColumnFactory.standardColumn('statusDisplayName', 'Status'),
          ]}
          subColumns={[
            ColumnFactory.linkedColumn('hblNumberDisplayName', 'HAWB number'),
            ColumnFactory.standardColumn('serviceDisplayName', 'Service'),
            ColumnFactory.standardColumn(
              'referenceNumberDisplayName',
              'Reference number'
            ),
            ColumnFactory.standardColumn(
              'totalPackagesDisplayName',
              'Total packages'
            ),
            ColumnFactory.standardColumn(
              'packagesTypeDisplayName',
              'Piece type'
            ),
            ColumnFactory.standardColumn('weightDisplayName', 'Weight'),
            ColumnFactory.standardColumn('volumeDisplayName', 'Volume'),
            ColumnFactory.standardColumn('brokerDisplayName.name', 'Broker'),
            ColumnFactory.standardColumn(
              'customsStatusDisplayName',
              'Customs status'
            ),
          ]}
          expandable={true}
          withCheckboxes={true}
          selectVariant="multiple"
          actions={{
            primaryActionIsMoreBtn: false,
            primaryActionLabel: 'Export to excel',
            primaryAction: this.exportToExcel,
            parentContextualActions: [],
            childContextualActions: getArrayOfButtons(this.props.permissions, [
              {
                isMoreBtn: false,
                label: 'Update clearance details',
                action: this.checkIfCanUpdateClearanceDetails,
              },
              {
                isMoreBtn: false,
                label: 'Notify customer',
                action: this.toggleNotifyCustomerModal,
              },
            ]),
          }}
          reference={this.dataTable}
          tableName="AirClearanceList"
          manualPagination={true}
          fetchData={this.props.fetchBls}
          pageCount={this.props.pageCount}
          showColumnOptions={true}
        />
        {this.state.isEditMultipleContainersModal && (
          <UpdateHawbModal
            visible={this.state.isEditMultipleContainersModal}
            closeModal={this.toggleEditMultipleContainersModal}
            hblId={this.getIds().hblId}
            voyageId={this.getIds().voyageId}
            data={this.state.details}
          />
        )}
        {this.state.isNotifyCustomerModalOpened && (
          <NotifyCustomerModal
            visible={this.state.isNotifyCustomerModalOpened}
            closeModal={this.toggleNotifyCustomerModal}
            blId={this.getIds().hblId}
            group={CTReasonCodes.Air_LateShipment}
            isAir={true}
          />
        )}
      </>
    );
  }

  private exportToExcel = async () => {
    const res = await this.props.exportCustomsToExcel(true);
    await downloadFile(res, 'application/zip', 'Data.zip');
  };

  private toggleNotifyCustomerModal = () =>
    this.setState((state) => ({
      isNotifyCustomerModalOpened: !state.isNotifyCustomerModalOpened,
    }));

  private getIds = () => {
    const selectedItems = this.dataTable.current
      ? this.dataTable.current.getSelectedItems()
      : [];
    const returnValue = (property: string) =>
      selectedItems.map((item: any) => {
        return item[property];
      });
    const hblNumber = returnValue('hblNumber');
    const hblId = returnValue('hblId');
    const voyageId = returnValue('voyageId');
    const blId = returnValue('blId');
    return {
      hblId,
      hblNumber,
      voyageId,
      blId,
    };
  };

  private toggleEditMultipleContainersModal = async () => {
    const selectedItems = this.dataTable.current
      ? this.dataTable.current.getSelectedItems()
      : [];

    if (!this.state.isEditMultipleContainersModal) {
      const checkSelectedItems = uniqWith(
        selectedItems.map((hbl: any) => ({
          cL2_Broker: hbl.cL2_Broker,
          cL2_Comments: hbl.cL2_Comments,
          cL2_CustomsClearedDate: hbl.cL2_CustomsClearedDate,
          cL2_Status: hbl.cL2_Status,
          cL2_Type: hbl.cL2_Type,
        })),
        isEqual
      );

      if (checkSelectedItems.length === 1) {
        const fetchDetails = await Promise.all(
          this.getIds().hblId.map((_: any, index: number) => {
            return this.props.fetchHawbDetails(
              this.getIds().hblId[index],
              this.getIds().voyageId[index]
            );
          })
        );

        this.setState({
          isEditMultipleContainersModal: true,
          details: fetchDetails,
        });
      }
    } else {
      this.setState({
        isEditMultipleContainersModal: false,
      });
    }
  };

  private selectedItemsAsUniq = () => {
    const selectedItems = this.dataTable.current
      ? this.dataTable.current.getSelectedItems()
      : [];
    return uniqWith(
      selectedItems.map((hbl: any) => {
        return {
          cL2_Broker: hbl.cL2_Broker,
          cL2_Comments: hbl.cL2_Comments,
          cL2_CustomsClearedDate: hbl.cL2_CustomsClearedDate,
          cL2_Status: hbl.cL2_Status,
          cL2_Type: hbl.cL2_Type,
        };
      }),
      isEqual
    );
  };

  private toggleErrorDialog = () =>
    this.setState((state) => ({
      isErrorDialogOpened: !state.isErrorDialogOpened,
    }));

  private checkIfCanUpdateClearanceDetails = () => {
    if (this.selectedItemsAsUniq().length === 1) {
      this.setState({ isErrorDialogOpened: false });
      this.toggleEditMultipleContainersModal();
    } else {
      this.setState({
        isErrorDialogOpened: true,
        errorMessage: 'Selected HBLs are not equal',
      });
    }
  };
}

const ROLES: BUSINNESS_FUNCTIONS[] = [
  BUSINNESS_FUNCTIONS.AIR_CUSTOMS_UPDATE_HBL_CUSTOMS_DETAILS,
  BUSINNESS_FUNCTIONS.AIR_CUSTOMS_NOTIFY_CUSTOMER,
];

const mapDispatchToProps = (
  dispatch: ThunkDispatch<any, any, Action>
): IMapDispatchToProps => ({
  exportCustomsToExcel: (isAir: boolean) =>
    dispatch(exportCustomsToExcel(isAir)),
  fetchHawbDetails: (id: string, voyageId: string) =>
    dispatch(fetchHawbDetailsOnList(id, voyageId)),
});

const __AirClearanceTable = connect<
  undefined,
  IMapDispatchToProps,
  IAirClearanceTableOwnProps,
  IStore
>(
  undefined,
  mapDispatchToProps
)(_AirClearance);
export const AirClearanceTable = withPermissions(
  __AirClearanceTable,
  ROLES
) as any;
