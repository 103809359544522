import React from 'react';
import { BackButton } from './components/BackButton';

interface IPageHeaderProps {
    backButton?: boolean;
    children: React.ReactNode | React.ReactNodeArray;
    id?: string;
}

export const PageHeader: React.StatelessComponent<IPageHeaderProps> = (props: IPageHeaderProps) => {
    return (
        <div className="header-group profile-template grid-wrapper">
            <main className="full-width">
                <ul className="page-title-group">
                    {props.backButton ? <BackButton /> : <li style={{margin: 0}}/>}
                    <li>
                        <h1 id={props.id}>{props.children}</h1>
                    </li>
                </ul>
            </main>
        </div>
    );
}