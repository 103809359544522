import React from 'react';
import { NavLink } from 'react-router-dom';
import styled from 'styled-components';

interface INavButton {
    to: string;
    children: React.ReactNode | React.ReactNodeArray;
}

const SNavButton: any = styled(NavLink)`
    position: relative;
    white-space: nowrap;
`;

export const NavButton = (props: INavButton) => (
    <SNavButton
        to={props.to}
        className="nav-item nav-link link"
        activeClassName="active"
    >
        {props.children}
    </SNavButton>
)
