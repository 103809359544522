import React from 'react';

interface IQuarterGrid {
    children: React.ReactNodeArray | React.ReactChild;
}

export const QuarterGrid: React.FunctionComponent<IQuarterGrid> = (props) => (
    <div className="grid-wrapper">
        <div className="col-25">
            {props.children[0] ? props.children[0] : props.children}
        </div>
        <div className="col-25">
            {props.children[1]}
        </div>
        <div className="col-25">
            {props.children[2]}
        </div>
        <div className="col-25">
            {props.children[3]}
        </div>
    </div>
);

