import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { Page } from 'components/page-container';
import { PageHeader } from 'components/page-header';
import { ThunkDispatch } from 'redux-thunk';
import { Action } from 'redux';
import { RouteComponentProps } from 'react-router';
import { IStore } from 'reducers/index';
import { ErrorDialog } from 'components/error-dialog';
import API from 'constants/api';
import { GenericError } from 'components/error-dialog/GenericError';
import { FactDetailsTopBar } from './components/FactDetailsTopBar';
import { fetchDocumentDetails } from 'actions/fact';
import { EquipmentsTable } from './components/EquipmentsTable';
import { EssosTable } from './components/EssosTable';
import { EquipmentTransportShipmentTable } from './components/EquipmentTransportShipmentsTable';
import { CblsTable } from './components/CblsTable';
import { BookingsTable } from './components/BookingsTable';
import { VoyagesTable } from './components/VoyagesTable';
import { PurchaseOrderNumbersTable } from './components/PurchaseOrderNumbersTable';
import { IDocumentDetails } from 'reducers/fact/models';
import { IssuerRow } from './components/IssuerRow';
import { ConsigneeRow } from './components/ConsigneeRow';
import { InvoiceeRow } from './components/InvoiceeRow';
import { ShipperRow } from './components/ShipperRow';
import { ServicesRow } from './components/ServicesRow';
import { PlacesRow } from './components/PlacesRow';
import { AsnsTable } from './components/AsnsTable';

interface IParams {
    documentId: string;
}

interface IDispatch {
    fetchDocumentDetails: (documentId: number) => Promise<void>;
}

interface IMapStateToProps {
    documentDetails: IDocumentDetails;
}

interface IDocumentsDetails extends RouteComponentProps<IParams>, IDispatch, IMapStateToProps {}

const _FactDetails: React.FunctionComponent<IDocumentsDetails> = (props) => {
    const documentId = parseInt(props.match.params.documentId, 10);

    useEffect(() => {
        if (!Number.isNaN(documentId)) {
            props.fetchDocumentDetails(documentId);
        }
    }, []);

    if (Number.isNaN(documentId)) {
        return <GenericError />;
    }

    return (
        <React.Fragment>
            <ErrorDialog endpoint={API.Fact.GetDocumentDetails} />
            <PageHeader backButton={true}>Document: {props.documentDetails.originalDocumentNumber}</PageHeader>
            <FactDetailsTopBar 
                documentId={props.match.params.documentId}
                documentNumber={props.documentDetails.documentNumber}
                type={props.documentDetails.type}
                isCancelled={props.documentDetails.isCancelled}
                createDate={props.documentDetails.createDate}
                lastUpdateDate={props.documentDetails.lastUpdateDate}
                approverUserCompanyWideCode={props.documentDetails.approverUserCompanyWideCode}
                changeUserCompanyWideCode={props.documentDetails.changeUserCompanyWideCode}
                prepaidCollectIndicator={props.documentDetails.prepaidCollectIndicator}
                goodsReceiptDate={props.documentDetails.goodsReceiptDate}
                estimatedDeliveryTime={props.documentDetails.estimatedDeliveryTime}
                id={props.documentDetails.documentId}
                asns={props.documentDetails.asns}
            />
            <Page>
                <IssuerRow 
                    issuerCountryCode={props.documentDetails.issuerCountryCode}
                    issuerBECode={props.documentDetails.issuerBECode}
                    issuerName={props.documentDetails.issuerName}
                    issuerFactCode={props.documentDetails.issuerFactCode}
                    issuerSCVCode={props.documentDetails.issuerSCVCode}
                    issuerFunctionCode={props.documentDetails.issuerFunctionCode}
                />
                <ConsigneeRow 
                    consigneeCountryCode={props.documentDetails.consigneeCountryCode}
                    consigneeBusinessEntityCode={props.documentDetails.consigneeBusinessEntityCode}
                    consigneeName={props.documentDetails.consigneeName}
                    consigneeFactCode={props.documentDetails.consigneeFactCode}
                    consigneeSCVCode={props.documentDetails.consigneeSCVCode}
                />
                <InvoiceeRow 
                    invoiceeCountryCode={props.documentDetails.invoiceeCountryCode}
                    invoiceeBusinessEntityCode={props.documentDetails.invoiceeBusinessEntityCode}
                    invoiceeName={props.documentDetails.invoiceeName}
                    invoiceeFactCode={props.documentDetails.invoiceeFactCode}
                    invoiceeSCVCode={props.documentDetails.invoiceeSCVCode}
                />
                <ShipperRow 
                    shipperCountryCode={props.documentDetails.shipperCountryCode}
                    shipperBusinessEntityCode={props.documentDetails.shipperBusinessEntityCode}
                    shipperName={props.documentDetails.shipperName}
                    shipperFactCode={props.documentDetails.shipperFACTCode}
                    shipperSCVCode={props.documentDetails.shipperSCVCode}
                />
                <ServicesRow 
                    serviceAtOrigin={props.documentDetails.serviceAtOrigin}
                    serivceAtDestination={props.documentDetails.serviceAtDestination}
                />
                <PlacesRow 
                    placeOfReceiptCode={props.documentDetails.placeOfReceiptCode}
                    placeOfReceiptName={props.documentDetails.placeOfReceiptName}
                    placeOfDeliveryCode={props.documentDetails.placeOfDeliveryCode}
                    placeOfDeliveryName={props.documentDetails.placeOfDeliveryName}
                    portOfLoadingCode={props.documentDetails.portOfLoadingCode}
                    portOfLoadingName={props.documentDetails.portOfLoadingName}
                    portOfDischargeCode={props.documentDetails.portOfDischargeCode}
                    portOfDischargeName={props.documentDetails.portOfDischargeName}
                />
                <AsnsTable data={props.documentDetails.asns} />
                <EquipmentsTable equipments={props.documentDetails.equipments} />
                <EssosTable data={props.documentDetails.essos} />
                <EquipmentTransportShipmentTable data={props.documentDetails.equipmentTransportShipments} />
                <CblsTable data={props.documentDetails.cbls} />
                <BookingsTable data={props.documentDetails.bookings} />
                <VoyagesTable data={props.documentDetails.voyages} />
                <PurchaseOrderNumbersTable data={props.documentDetails.purchaseOrderNumbers} />
            </Page>
        </React.Fragment>
    );
}

const mapStateToProps = (state: IStore): IMapStateToProps => ({
    documentDetails: state.fact.documentDetails,
});

const mapDispatchToProps = (dispatch: ThunkDispatch<any, any, Action>): IDispatch => ({
    fetchDocumentDetails: (documentId: number) => dispatch(fetchDocumentDetails(documentId)),
});

export const FactDetails = connect<IMapStateToProps,IDispatch, {}, IStore>(mapStateToProps, mapDispatchToProps)(_FactDetails);
