import { Column } from 'react-table';
import React from 'react';

export const conditionalExpandColumn = (field: string): Column => {
    return {
        expander: true,
        style: {
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
        },
        Expander: ({ isExpanded, row }: any) => {
            if(row[field] || row[`_original`][field]) {
                const arrow = isExpanded ? 'fa fa-angle-up' : 'fa fa-angle-down';
                return (
                    <span>
                        <i className={`expander_id ${arrow}`}/>
                    </span>
                );
            }
            return null;
        },
    };
};