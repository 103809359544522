import React, { Component } from 'react';
import { connect } from 'react-redux';
import { FiltersInput } from 'modules/filters/components/FiltersInput';
import { Page } from 'components/page-container';
import { Filters } from 'modules/filters/containers/Filters';
import { PageHeader } from 'components/page-header';
import { ThunkDispatch } from 'redux-thunk';
import { Action } from 'redux';
import { fetchIssoList } from 'actions/fact';
import { IStore } from 'reducers/index';
import { FiltersSelect } from 'modules/filters/components/FiltersSelect';
import { ILink } from 'components/sub-navigation/models/Link';
import { SubNavigation } from 'components/sub-navigation';
import { types } from '../../documents/list/fact';
import { IIssoList } from 'reducers/fact/models';
import { IssoTable } from 'modules/fact/components/IssoTable';
import { setSort, resetSort } from 'actions/filters';
import { setSortForTable } from 'utilities/tables';
import { ISortModel } from 'reducers/filters/models';

const links: ILink[] = [
    {
        to: '/exception-management/fact/documents',
        linkText: 'Documents',
    },
    {
        to: '/exception-management/fact/equipments',
        linkText: 'Equipments',
    }, 
    {
        to: '/exception-management/fact/cbls',
        linkText: 'Cbls',
    }, 
    {
        to: '/exception-management/fact/isso',
        linkText: 'ISSO',
    }, 
    {
        to: '/exception-management/fact/esso',
        linkText: 'ESSO',
    }
];

interface IMapDispatchToProps {
    fetchIssoList: (pageNumber?: number, pageSize?: number) => Promise<void>;
    setSort: (sort: ISortModel) => void;
    resetSort: () => void;
}

interface IMapStateToProps {
    isIssoListLoading: boolean;
    issos: IIssoList;
}

interface IEssoListProps extends IMapStateToProps, IMapDispatchToProps {}

interface IEssoListState {
    page: number;
    pageSize: number;
}

class _IssoList extends Component<IEssoListProps, IEssoListState> {
    public state = {
        page: 1,
        pageSize: 10,
    };

    public render() {
        const filters = [
            <FiltersInput
                key="originalDocumentNumber"
                name="originalDocumentNumber"
                label="Original document number"
            />,
            <FiltersInput
                key="documentNumber"
                name="documentNumber"
                label="Document number"
            />,
            <FiltersSelect
                key="type"
                name="type"
                label="Type"
                options={types}
            />
        ];
    
        return(
            <React.Fragment>
                <PageHeader>Isso</PageHeader>
                <Page>
                    <Filters
                        tableNameKey="isso"
                        fetchFunction={() => this.props.fetchIssoList(this.state.page, this.state.pageSize)}
                        filters={filters}
                    />
                    <SubNavigation links={links} />
                    <IssoTable
                        data={this.props.issos.result}
                        isLoading={this.props.isIssoListLoading}
                        pageCount={Math.ceil(this.props.issos.totalCount / this.props.issos.pageSize)}
                        fetchIssos={this.fetchIssos}
                    />
                </Page>
            </React.Fragment>
        );
    }

    private fetchIssos = (tableData: any) => {
        this.setState({
            page: tableData.page + 1,
            pageSize: tableData.pageSize
        }, () => {
            setSortForTable(tableData.sorted, this.props.setSort);
            this.props.fetchIssoList(this.state.page, this.state.pageSize);
        });
    };
}

const mapStateToProps = (state: IStore): IMapStateToProps => ({
    isIssoListLoading: state.fact.isEssoListLoading,
    issos: state.fact.issoList
});

const mapDispatchToProps = (dispatch: ThunkDispatch<any, any, Action>): IMapDispatchToProps => ({
    fetchIssoList: (pageNumber?: number, pageSize?: number) => dispatch(fetchIssoList(pageNumber, pageSize)),
    setSort: (sort: ISortModel) => dispatch(setSort(sort)),
    resetSort: () => dispatch(resetSort())
});

export const IssoList = connect<IMapStateToProps, IMapDispatchToProps, {}, IStore>(mapStateToProps, mapDispatchToProps)(_IssoList);
