import { Column } from 'react-table';
import React from 'react';
import { ColumnFactory } from 'components/data-table/ColumnFactory';

export const booleanColumn = (accessor: string, name: string, trueValue: string, falseValue: string): Column => {
    const cell = (rowInfo: { value: string }) => (
        <div>
            {rowInfo.value ? trueValue : falseValue}
        </div>
    );
    return ColumnFactory.getColumnOptionsOld(accessor, name, cell);
};