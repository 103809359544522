import React, { Component } from 'react';
import { ModalScreen } from 'components/modal-screen';
import { ModalType } from 'components/modal-screen/ModalType';
import { reduxForm, InjectedFormProps, reset } from 'redux-form';
import { FormInput } from 'components/input/FormInput';
import { Dispatch } from 'redux';
import { TripleGrid } from 'components/grid/triple-grid';
import { BooleanFormSelect } from 'components/select/BooleanFormSelect';
import { FormDateInput } from 'components/date-input/FormDateInput';
import { FormSelect } from 'components/select/FormSelect';
import { IUpdateDDContractBody } from 'reducers/demurrage-and-detention/models';

interface IAddContractModalProps {
    title: string;
    visible: boolean;
    onSubmit: (fields: IUpdateDDContractBody) => void;
    closeModal: () => void;
}

class _AddContractModal extends Component<InjectedFormProps<IUpdateDDContractBody, IAddContractModalProps> & IAddContractModalProps> {
    public render() {
        return (
            <form onSubmit={this.props.handleSubmit(this.props.onSubmit)}>
                <ModalScreen
                    title={this.props.title}
                    modalType={ModalType.m()}
                    primaryButtonTitle="Save"
                    primaryButtonType="submit"
                    secondaryButtonTitle="Cancel"
                    secondaryButtonFunc={this.props.closeModal}
                    visible={this.props.visible}
                    closeModal={this.props.closeModal}
                >
                    <TripleGrid>
                        <FormInput
                            label="BE code"
                            name="beCode"
                        />
                        <FormInput
                            label="Customer"
                            name="customer"
                        />
                        <FormSelect
                            label="Fee type"
                            name="feeType"
                            options={[
                                { optionText: 'Select value...', value: '' },
                                { optionText: 'Demurrage', value: 1 },
                                { optionText: 'Detention', value: 2 },
                            ]}
                        />
                    </TripleGrid>
                    <TripleGrid>
                        <FormSelect
                            label="From"
                            name="from"
                            options={[
                                { optionText: 'Select value...', value: '' },
                                { optionText: 'ATA', value: 1 },
                                { optionText: 'Gate out', value: 2 },
                                { optionText: 'ATD', value: 3 },
                                { optionText: 'Empty return', value: 4 },
                            ]}
                        />
                        <FormSelect
                            label="To"
                            name="to"
                            options={[
                                { optionText: 'Select value...', value: '' },
                                { optionText: 'ATA', value: 1 },
                                { optionText: 'Gate out', value: 2 },
                                { optionText: 'ATD', value: 3 },
                                { optionText: 'Empty return', value: 4 },
                            ]}
                        />
                        <FormInput
                            label="SCAC"
                            name="scac"
                        />
                    </TripleGrid>
                    <TripleGrid>
                        <FormInput
                            label="Container type"
                            name="containerType"
                        />
                        <FormInput
                            label="Container size"
                            name="containerSize"
                        />
                        <FormInput
                            label="Free days"
                            name="freeDays"
                            type="number"
                        />
                    </TripleGrid>
                    <TripleGrid>
                        <BooleanFormSelect
                            label="iMODG"
                            name="imodg"
                        />
                        <FormDateInput
                            label="Valid from"
                            name="validFrom"
                        />
                        <FormDateInput
                            label="Valid to"
                            name="validTo"
                        />
                    </TripleGrid>
                    <TripleGrid>
                        <FormInput
                            label="Port level"
                            name="portLevel"
                        />
                        <FormInput
                            label="Ocean port"
                            name="oceanPort"
                        />
                        <FormInput
                            label="Inland port"
                            name="inlandPort"
                        />
                    </TripleGrid>
                    <TripleGrid>
                        <BooleanFormSelect
                            label="Damco NVOCC"
                            name="damcoNVOCC"
                        />
                        <FormInput
                            label="Free time calc type"
                            name="freetimeCalcType"
                        />
                        <FormInput
                            label="Charge"
                            name="charge"
                            type="number"
                        />
                    </TripleGrid>
                    <TripleGrid>
                        <FormInput
                            label="Currency"
                            name="currency"
                        />
                    </TripleGrid>
                </ModalScreen>
            </form>
        );
    }
};

const FORM_NAME = 'ADD_NEW_CONTRACT';
const onSubmitSuccess = (_: any, dispatch: Dispatch) => dispatch(reset(FORM_NAME));

export const AddContractModal = reduxForm<IUpdateDDContractBody, IAddContractModalProps>({
    form: FORM_NAME,
    onSubmitSuccess,
})(_AddContractModal);
