import React from 'react';
import { LoadingAnimation } from 'components/loading';
import { ColumnFactory } from 'components/data-table/ColumnFactory';
import { DataTable } from 'components/data-table/DamcoTable';
import { downloadFile } from 'utilities/download';
import { connect } from 'react-redux';
import { ThunkDispatch } from 'redux-thunk';
import { Action } from 'redux';
import { exportHblReleaseToExcel } from 'actions/export';
import { editHblDetails, fetchHawbList } from 'actions/bill-release';
import { EditHblPopup } from 'modules/bill-release/shared/components/EditBlModal.tsx/EditHblPopup';
import { surrenderType } from 'modules/bill-release/shared/components/EditBlModal.tsx/surrenderType';
// tslint:disable-next-line: no-submodule-imports
import { IWithPermissions, withPermissions } from 'src/HOC/withPermissions';
import { getArrayOfButtons } from 'utilities/util';
import { BUSINNESS_FUNCTIONS } from 'constants/roles';
import { IStore } from 'reducers/index';
import { cloneDataWithDisplayNames } from 'utilities/tables';

interface IMapDispatchToProps {
    exportHblReleaseToExcel: (isAir: boolean) => Promise<void>;
    editHblDetails: (body: any) => Promise<void>;
    fetchHawbList: (pageNumber: number, pageSize: number) => Promise<void>;
}
interface IHAWBTableOwnProps extends IWithPermissions, IMapDispatchToProps {
    data: any;
    isLoading: boolean;
    pageCount: number;
    fetchHawbs: any;
}

interface IHAWBTableProps extends IWithPermissions, IHAWBTableOwnProps, IMapDispatchToProps {}

interface IHAWBTableState {
    isEditHawbModalOpened: boolean;
}

class _HAWBTable extends React.Component<IHAWBTableProps, IHAWBTableState> {
    public dataTable = React.createRef<any>();

    public state: IHAWBTableState = {
        isEditHawbModalOpened: false
    };

    public render() {
        const data = cloneDataWithDisplayNames(this.props.data).map((obj: any) => ({
            ...obj,
            hblNumberDisplayName: [obj.hblNumber, `/bill-release/hawb/${obj.hblId}`]
        }));

        return(
            <React.Fragment>
                {this.props.isLoading && <LoadingAnimation />}
                <DataTable
                    data={data}
                    columns={[
                        ColumnFactory.linkedColumn('hblNumberDisplayName', 'HAWB number'),
                        ColumnFactory.standardColumn('vesselName', 'Flight number'),
                        ColumnFactory.standardColumn('destinationPort', 'POD'),
                        ColumnFactory.standardColumn('newETADisplayName', 'new ETA'),
                        ColumnFactory.standardColumn('daysTillETA', 'Days till ETA'),
                        ColumnFactory.standardColumn('ataDisplayName', 'ATA'),
                        ColumnFactory.standardColumn('billType', 'Bill type'),
                        ColumnFactory.standardColumn('surrenderType', 'Surrender Type'),
                        ColumnFactory.standardColumn('originalHBLSurrenderedDisplayName', 'Original HB/L surrendered'),
                        ColumnFactory.standardColumn('originalHBLSurrenderDateDisplayName', 'Surrender date')
                    ]}
                    withCheckboxes={true}
                    selectVariant="single"
                    reference={this.dataTable}
                    tableName="HAWBReleaseList"
                    actions={{
                        primaryActionIsMoreBtn: false,
                        primaryActionLabel: 'Export to excel',
                        primaryAction: this.exportToExcel,
                        parentContextualActions: getArrayOfButtons(
                            this.props.permissions,
                            [
                                { isMoreBtn: false, label: 'Update HAWB details', action: this.toggleEditHawbModal }
                            ])
                    }}
                    manualPagination={true}
                    fetchData={this.props.fetchHawbs}
                    pageCount={this.props.pageCount}
                    showColumnOptions={true}
                />
                {this.state.isEditHawbModalOpened && <EditHblPopup
                    visible={this.state.isEditHawbModalOpened}
                    closeModal={this.toggleEditHawbModal}
                    blId={this.dataTable.current ? this.dataTable.current.getSelectedItems()[0].hblId : 0}
                    initialValues={this.getInitialValuesForEditHblDetails()}
                    isSurrendered={false}
                    submitFunc={this.editHawbDetails}
                    isHbl={true}
                    isAir={true}
                    modalName="HAWB"
                />}
            </React.Fragment>
        );
    }

    private exportToExcel = async () => {
        const res = await this.props.exportHblReleaseToExcel(true);
        await downloadFile(res);
    };

    private toggleEditHawbModal = () => this.setState((state) => ({
        isEditHawbModalOpened: !state.isEditHawbModalOpened
    }));

    private editHawbDetails = async (data: any) => {
        await this.props.editHblDetails(data);
        await this.props.fetchHawbList(1, 10);
    };

    private getInitialValuesForEditHblDetails = () => {
        const selectedItems = this.dataTable.current ? this.dataTable.current.getSelectedItems() : [];
        
        if (selectedItems[0]) {
            const type = surrenderType.find(({ optionText }) => optionText === selectedItems[0].surrenderType)
            return {
                SurrenderType: type ? type.value : '',
                SurrenderedDate: selectedItems[0].originalHBLSurrenderDate,
            }
        }
        return {};
    };
}

const ROLES: BUSINNESS_FUNCTIONS[] = [BUSINNESS_FUNCTIONS.AIR_HAWB_RELEASE_EDIT_HBL];

const mapDispatchToProps = (dispatch: ThunkDispatch<any, any, Action>): IMapDispatchToProps => ({
    exportHblReleaseToExcel: (isAir: boolean) => dispatch(exportHblReleaseToExcel(isAir)),
    editHblDetails: (body: any) => dispatch(editHblDetails(body)),
    fetchHawbList: (pageNumber: number, pageSize: number) => dispatch(fetchHawbList(pageNumber, pageSize)),
});

const __HAWBTable = connect<undefined, IMapDispatchToProps, IHAWBTableOwnProps, IStore>(undefined, mapDispatchToProps)(_HAWBTable);
 export const HAWBTable = withPermissions(__HAWBTable, ROLES) as any;
