import React from 'react';
import { FormInput } from 'components/input/FormInput';
import { DoubleGrid } from 'components/grid/double-grid';

interface IEditCBLDetailsFormOceanFirstLine {
    isDisabled?: boolean;
}

export const EditCBLDetailsFormOceanFirstLine: React.SFC<IEditCBLDetailsFormOceanFirstLine> = (props: IEditCBLDetailsFormOceanFirstLine) => (
    <DoubleGrid>
        <FormInput
            label="Vessel name"
            name="VesselName"
            disabled={props.isDisabled}
        />
        <FormInput
            label="Voyage"
            name="VoyageReference"
            disabled={props.isDisabled}
        />
    </DoubleGrid>
);
