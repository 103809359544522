import React from 'react';
import { Link } from 'react-router-dom';
import { NavItem } from './../NavItem/NavItem';
import UserArea from './UserArea';
import {
    NavbarContainer,
    NavbarMenu
} from './NavBarStyles';
import { NavButton } from 'modules/navbar/components/NavItem/NavButton';
import { NavLink } from 'modules/navbar/components/NavItem/NavLink';
import { IRoute, IRouteLink } from 'modules/navbar/routes';

interface INavBar {
    routes: IRoute[];
}
export class NavBar extends React.Component<INavBar> {
    public render() {
        const routes = this.props.routes.map((route: IRoute, index: number) => {
            if(route.links) {
                const links = this.renderLinks(route.links);
                return (
                    <NavItem
                        title={route.name}
                        key={index}
                    >
                        {links}
                    </NavItem>
                );
            }
            return (
                <NavButton
                    to={route.to}
                    key={index}
                >
                    {route.name}
                </NavButton>
            );
        });
        return (
            <nav className="navbar large-nav-menu">
                <Link to="/" className="navbar-brand">
                    <img src="https://cdn.damco.com/cortex/v1/images/logos/DamcoPortal.svg" alt="Logo -Damco" height="32" width="32" />
                </Link>
                <NavbarContainer className="collapse navbar-collapse" id="navbarCollapse">
                    <NavbarMenu className="navbar-nav">
                        {routes}
                    </NavbarMenu>
                    <UserArea />
                </NavbarContainer>
            </nav>
        );
    }

    private renderLinks = (links: IRouteLink[]) => {
        return links.map((link: IRouteLink, index: number) => (
            <NavLink
                to={link.to}
                text={link.text}
                key={index}
            />
        ));
    }
}
