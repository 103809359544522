export enum ContainerTrackingTypes {
    Voyage = 'voyage',
    Cbl = 'cbl'
}

export enum CustomsTypes {
    Hbl = 'hbl',
    Equipment = 'equipment'
}

export enum DeliveryPlanningTypes {
    Equipment = 'equipment'
}