import React from 'react';
import { Page } from 'components/page-container';
import { SubNavigation } from 'components/sub-navigation';
import { ILink } from 'components/sub-navigation/models/Link';
import { PageHeader } from 'components/page-header';
import { DetentionTable } from './components/DetentionTable';
import { connect } from 'react-redux';
import { ThunkDispatch } from 'redux-thunk';
import { Action } from 'redux';
import { getDetentionEquipments } from 'actions/demurrageAndDetention';
import { IStore } from 'reducers/index';
import { IDetentionListData } from 'reducers/demurrage-and-detention/models';
import { LoadingAnimation } from 'components/loading';
import { HandleError } from 'components/handle-error';
import { setSortForTable } from 'utilities/tables';
import { setSort, resetSort } from 'actions/filters';
import { ISortModel } from 'reducers/filters/models';
import { Filters } from 'modules/filters/containers/Filters';
import { FiltersInput } from 'modules/filters/components/FiltersInput';
import { FiltersDateInput } from 'modules/filters/components/FiltersDateInput';

interface IMapStateToProps {
    detention: IDetentionListData;
    isLoading: boolean;
    isExportLoading: boolean;
    exportDetentionError: string;
    atdEmpty: boolean;
    gateOutPortDateEmpty: boolean;
    emptyReturnDateEmpty: boolean;
    firstChargeableDayDetentionEmpty: boolean;
}

interface IDispatch {
    getDetentionEquipments: (pageNumber: number, pageSize: number) => Promise<void>;
    setSort: (sort: ISortModel) => void;
    resetSort: () => void;
}

interface IDetention extends IMapStateToProps, IDispatch {}

interface IDetentionState {
    page: number;
    pageSize: number;
}

const links: ILink[] = [{
    to: '/demurrage-and-detention/demurrage',
    linkText: 'Demurrage',
}, {
    to: '/demurrage-and-detention/detention',
    linkText: 'Detention',
}];

class _Detention extends React.Component<IDetention, IDetentionState> {
    public state = {
        page: 1,
        pageSize: 10
    };

    public render() {
        const filters = [
            <FiltersInput
                key="EquipmentNumber"
                name="EquipmentNumber"
                label="Equipment Number"
                id="equipmentNumber"
            />,
            <FiltersDateInput
                type="date"
                key="atd_from"
                name="atd_from"
                label="Actual delivery date (from)"
                relatedField="atd"
                readOnly={this.props.atdEmpty}
            />,
            <FiltersDateInput
                type="date"
                key="atd_to"
                name="atd_to"
                label="Actual delivery date (to)"
                isEndOfDay={true}
                relatedField="atd"
                readOnly={this.props.atdEmpty}
            />,
            <FiltersDateInput
                type="date"
                key="GateOutPortDate_from"
                name="GateOutPortDate_from"
                label="Container gate out (from)"
                relatedField="gateOutPortDate"
                readOnly={this.props.gateOutPortDateEmpty}
            />,
            <FiltersDateInput
                type="date"
                key="GateOutPortDate_to"
                name="GateOutPortDate_to"
                label="Container gate out (to)"
                isEndOfDay={true}
                relatedField="gateOutPortDate"
                readOnly={this.props.gateOutPortDateEmpty}
            />,
            <FiltersDateInput
                type="date"
                key="EmptyReturnDate_from"
                name="EmptyReturnDate_from"
                label="Empty return date (from)"
                relatedField="emptyReturnDate"
                readOnly={this.props.emptyReturnDateEmpty}
            />,
            <FiltersDateInput
                type="date"
                key="EmptyReturnDate_to"
                name="EmptyReturnDate_to"
                label="Empty return date (to)"
                isEndOfDay={true}
                relatedField="emptyReturnDate"
                readOnly={this.props.emptyReturnDateEmpty}
            />,
            <FiltersInput
                key="CarrierName"
                name="CarrierName"
                label="Carrier"
            />,
            <FiltersInput
                key="DetentionContractId"
                name="DetentionContractId"
                label="Contact #"
            />,
            <FiltersDateInput
                type="date"
                key="FirstChargeableDayDetention_from"
                name="FirstChargeableDayDetention_from"
                label="First chargeable day (from)"
                relatedField="firstChargeableDayDetention"
                readOnly={this.props.firstChargeableDayDetentionEmpty}
            />,
            <FiltersDateInput
                type="date"
                key="FirstChargeableDayDetention_to"
                name="FirstChargeableDayDetention_to"
                label="First chargeable day (to)"
                isEndOfDay={true}
                relatedField="firstChargeableDayDetention"
                readOnly={this.props.firstChargeableDayDetentionEmpty}
            />,
            <FiltersInput
                key="PONumber"
                name="PONumber"
                label="PO Number"
            />,
            <FiltersInput
                key="lastUserUpdated"
                name="lastUserUpdated"
                label="Last updated by"
            />,
        ];

        return (
            <Page>
                <PageHeader>Demurrage & Detention</PageHeader>
                {this.props.isLoading ||this.props.isExportLoading && <LoadingAnimation />}
                <HandleError
                    exportDetentionError={this.props.exportDetentionError}
                />
                <Filters
                    tableNameKey="delivery-detention"
                    filters={filters}
                    fetchFunction={() => this.props.getDetentionEquipments(this.state.page, this.state.pageSize)}
                    withoutPagination={true}
                />
                <SubNavigation links={links} />
                <DetentionTable
                    data={this.props.detention.result}
                    refetchFunction={() => this.props.getDetentionEquipments(1, this.state.pageSize)}
                    pageCount={Math.ceil(this.props.detention.totalCount / this.props.detention.pageSize)}
                    fetchEquipments={this.fetchEquipments}
                />
            </Page>
        );
    }

    public componentWillUnmount() {
        this.props.resetSort();
    }

    private fetchEquipments = (tableData: any) => {
        this.setState({
            page: tableData.page + 1,
            pageSize: tableData.pageSize
        }, () => {
            setSortForTable(tableData.sorted, this.props.setSort);
            this.props.getDetentionEquipments(this.state.page, this.state.pageSize);
        });
    };
}

const mapStateToProps = (state: IStore): IMapStateToProps => ({
    detention: state.demurrageAndDetention.detention,
    isLoading: state.demurrageAndDetention.isGetDetentionPending,
    exportDetentionError: state.exportReducer.exportDetentionError,
    isExportLoading: state.exportReducer.isExportDetentionLoading,
    atdEmpty: state.filters.filters.atd_empty,
    gateOutPortDateEmpty: state.filters.filters.gateoutportdate_empty,
    emptyReturnDateEmpty: state.filters.filters.emptyreturndate_empty,
    firstChargeableDayDetentionEmpty: state.filters.filters.firstchargeabledaydetention_empty
});

const mapDispatchToProps = (dispatch: ThunkDispatch<any, any, Action>): IDispatch => ({
    getDetentionEquipments: (pageNumber: number, pageSize: number) => dispatch(getDetentionEquipments(pageNumber, pageSize)),
    setSort: (sort: ISortModel) => dispatch(setSort(sort)),
    resetSort: () => dispatch(resetSort()),
});

export const Detention = connect(mapStateToProps, mapDispatchToProps)(_Detention);
