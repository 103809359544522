import { TYPES } from 'action-types/vessels';
import { IBLList, IVessel, IBLDetails, IBLVessel, IMAWBDetails, IMilestones } from 'reducers/vessels/models';
import { cloneDataWithDisplayNames } from 'utilities/tables';

interface IAction {
    readonly type: string;
    readonly vessels?: any;
    readonly vesselDetails?: IVessel;
    readonly result: any[],
    readonly pageSize?: number;
    readonly totalCount?: number;
    readonly pageNumber?: number;
    readonly blDetails?: IBLDetails;
    readonly mawbDetails?: IMAWBDetails;
    readonly PageSize?: number;
    readonly TotalCount?: number;
    readonly PageNumber?: number;
    readonly Result?: any[],
    readonly vessel: IVessel;
    readonly blId: number;
    readonly error?: string;
}

export interface IPagingResult {
    pageNumber: number;
    pageSize: number;
    totalCount: number;
}

export interface IVesselsState {
    readonly isLoading: boolean;
    readonly isEditVesselLoading: boolean;
    readonly isError: boolean;
    readonly editVesselPendingError: string;
    readonly editMawbDetailsError: string;
    readonly vessels: any;
    readonly vesselDetails: IVessel;
    readonly blDetails: IBLDetails;
    readonly mawbDetails: IMAWBDetails;
    readonly blList: IBLList;
    readonly mawbList: IBLList;
    readonly isOpenCBLDetailsModalOpened: boolean;
    readonly cblDetailsModalBLId: number;
    readonly milestones: IMilestones;
    readonly mrnData: any[];
}

const defaultState: IVesselsState = {
    isLoading: false,
    isError: false,
    isEditVesselLoading: false,
    editVesselPendingError: '',
    editMawbDetailsError: '',
    vessels: {
        totalCount: 0,
        pageNumber: 1,
        pageSize: 0,
        data: []
    },
    vesselDetails: {
        ata: '',
        atd: '',
        eta: '',
        etd: '',
        bLs: [],
        bubbleData: [],
        carrierName: '',
        comments: [],
        currentETA: '',
        destinationPort: '',
        loadingPort: '',
        originalETA: '',
        vesselCode: '',
        vesselName: '',
        voyageDelayReasonCode: '',
        voyageId: 0,
        voyageReference: '',
        voyageStatus: 0,
        lastUserUpdated: null,
        updateDate: null,
    },
    blList: {
        data: [],
        pageNumber: 1,
        pageSize: 0,
        totalCount: 0,
    },
    mawbList: {
        data: [],
        pageNumber: 1,
        pageSize: 0,
        totalCount: 0,
    },
    blDetails: {
        blNumber: '',
        vessels: [],
        equipments: [],
        bubbleData: [],
        lastUserUpdated: null,
        updateDate: null,
    },
    mawbDetails: {
        '3PL': '',
        fourPLCarrierCode: '',
        fourPLCarrierName: '',
        fourPLReference: '',
        MAWB: '',
        vessels: [],
        equipments: [],
        bubbleData: [],
        lastUserUpdated: null,
        updateDate: null,
    },
    milestones: {
        result: [],
        pageNumber: 1,
        pageSize: 10,
        totalCount: 0
    },
    isOpenCBLDetailsModalOpened: false,
    cblDetailsModalBLId: 0,
    mrnData: []
};

export const reducer = (state: IVesselsState = defaultState, action: IAction): IVesselsState => {
    switch (action.type) {
        case TYPES.FETCH_VESSELS.PENDING:
            return {
                ...state,
                isLoading: true,
                isError: false
            };
        case TYPES.FETCH_VESSELS.FULFILLED:
            return {
                ...state,
                vessels: {
                    data: cloneDataWithDisplayNames(action.result) || state.vessels.result,
                    totalCount: action.totalCount || state.vessels.totalCount,
                    pageNumber: action.pageNumber || state.vessels.pageNumber,
                    pageSize: action.pageSize || state.vessels.pageSize,
                },
                isLoading: false,
                isError: false
            };
        case TYPES.FETCH_VESSELS.REJECTED:
            return {
                ...state,
                isLoading: false,
                isError: true
            };
        case TYPES.FETCH_VESSEL_DETAILS.PENDING:
            return {
                ...state,
                vesselDetails: action.vesselDetails || state.vesselDetails,
                isLoading: true
            };
        case TYPES.FETCH_VESSEL_DETAILS.FULFILLED:
            return {
                ...state,
                vesselDetails: action.vesselDetails || state.vesselDetails,
                isLoading: false
            };
        case TYPES.FETCH_VESSEL_DETAILS.REJECTED:
            return {
                ...state,
                vesselDetails: action.vesselDetails || state.vesselDetails,
                isLoading: false
            };
        case TYPES.FETCH_BL_LIST.PENDING:
            return {
                ...state,
                isLoading: true,
                isError: false
            };
        case TYPES.FETCH_BL_LIST.FULFILLED:
            return {
                ...state,
                isLoading: false,
                isError: false,
                blList: {
                    data: cloneDataWithDisplayNames(action.result) || state.blList.data,
                    totalCount: action.totalCount || state.blList.totalCount,
                    pageNumber: action.pageNumber || state.blList.pageNumber,
                    pageSize: action.pageSize || state.blList.pageSize,
                },
            };
        case TYPES.FETCH_BL_LIST.REJECTED:
            return {
                ...state,
                isLoading: false,
                isError: true
            };
        case TYPES.FETCH_BL_DETAILS.PENDING:
            return {
                ...state,
                isLoading: true,
                isError: false,
            };
        case TYPES.FETCH_BL_DETAILS.FULFILLED:
            return {
                ...state,
                isLoading: false,
                isError: false,
                blDetails: action.blDetails || state.blDetails
            };
        case TYPES.FETCH_BL_DETAILS.REJECTED:
            return {
                ...state,
                isLoading: false,
                isError: true
            };
        case TYPES.EDIT_VESSEL_PENDING.PENDING:
            return {
                ...state,
                isEditVesselLoading: true,
                editVesselPendingError: '',
            };
        case TYPES.EDIT_VESSEL_PENDING.FULFILLED:
            return {
                ...state,
                isEditVesselLoading: false,
                editVesselPendingError: '',
            }
        case TYPES.EDIT_VESSEL_PENDING.REJECTED:
            return {
                ...state,
                isEditVesselLoading: false,
                editVesselPendingError: action.error || '',
            };
        case TYPES.EDIT_VESSEL_PENDING.RESET_ERROR:
            return {
                ...state,
                isEditVesselLoading: false,
                editVesselPendingError: '',
            };
        case TYPES.EDIT_VESSEL_ON_CBL_LEVEL.PENDING:
            return {
                ...state,
                isLoading: true,
                isError: false,
            }
        case TYPES.EDIT_VESSEL_ON_CBL_LEVEL.FULFILLED:
            return {
                ...state,
                blList: {
                    ...state.blList,
                    data: state.blList.data.map((bl: any) => {
                        if(bl.blId === action.blId) {
                            return {
                                ...bl,
                                vessels: bl.vessels.map((vesselInList: IBLVessel) => {
                                    if(vesselInList.voyageId === action.vessel.voyageId) {
                                        return {
                                            ...vesselInList,
                                            ...action.vessel,
                                        }
                                    }
                                    return vesselInList;
                                }),
                            }
                        }
                        return bl;
                    }),
                },
                isLoading: false,
                isError: false,
            };
        case TYPES.EDIT_VESSEL_ON_CBL_LEVEL.REJECTED:
            return {
                ...state,
                isLoading: false,
                isError: true
            };
        case TYPES.ADD_VESSEL_TO_BL.PENDING:
            return {
                ...state,
                isLoading: true,
                isError: false,
            };
        case TYPES.ADD_VESSEL_TO_BL.FULFILLED:
            return {
                ...state,
                isLoading: false,
                isError: false,
            };
        case TYPES.ADD_VESSEL_TO_BL.REJECTED:
            return {
                ...state,
                isLoading: false,
                isError: true
            };

        case TYPES.SEND_COMMENT_TO_VESSEL.PENDING:
            return {
                ...state,
                isLoading: true,
                isError: false,
            };
        case TYPES.SEND_COMMENT_TO_VESSEL.FULFILLED:
            return {
                ...state,
                isLoading: false,
                isError: false,
            };
        case TYPES.SEND_COMMENT_TO_VESSEL.REJECTED:
            return {
                ...state,
                isLoading: false,
                isError: true,
            };
        case TYPES.FETCH_MAWB_LIST.PENDING:
            return {
                ...state,
                isLoading: true,
                isError: false
            };
        case TYPES.FETCH_MAWB_LIST.FULFILLED:
            return {
                ...state,
                isLoading: false,
                isError: false,
                mawbList: {
                    data: action.result || state.blList.data,
                    totalCount: action.totalCount || state.blList.totalCount,
                    pageNumber: action.pageNumber || state.blList.pageNumber,
                    pageSize: action.pageSize || state.blList.pageSize,
                },
            };
        case TYPES.FETCH_MAWB_LIST.REJECTED:
            return {
                ...state,
                isLoading: false,
                isError: true
            };
        case TYPES.FETCH_MAWB_DETAILS.PENDING:
            return {
                ...state,
                isLoading: true,
                isError: false,
            };
        case TYPES.FETCH_MAWB_DETAILS.FULFILLED:
            return {
                ...state,
                isLoading: false,
                isError: false,
                mawbDetails: action.mawbDetails as IMAWBDetails,
            };
        case TYPES.FETCH_MAWB_DETAILS.REJECTED:
            return {
                ...state,
                isLoading: false,
                isError: true
            };
        case TYPES.EDIT_MAWB_DETAILS.PENDING:
            return {
                ...state,
                editMawbDetailsError: '',
            };
        case TYPES.EDIT_MAWB_DETAILS.FULFILLED:
            return {
                ...state,
                editMawbDetailsError: '',
            };
        case TYPES.EDIT_MAWB_DETAILS.REJECTED:
            return {
                ...state,
                editMawbDetailsError: action.error || '',
            };
        case TYPES.EDIT_MAWB_DETAILS.RESET_ERROR:
            return {
                ...state,
                editMawbDetailsError: action.error || '',
            };
        case TYPES.OPEN_CBL_DETAILS_MODAL:
            return {
                ...state,
                isOpenCBLDetailsModalOpened: true,
                cblDetailsModalBLId: action.blId,
            };
        case TYPES.CLOSE_CBL_DETAILS_MODAL:
            return {
                ...state,
                isOpenCBLDetailsModalOpened: false,
                cblDetailsModalBLId: 0,
            };
        case TYPES.FETCH_MILESTONES_LIST.PENDING:
            return {
                ...state,
                milestones: {
                    result: action.result || state.milestones.result,
                    totalCount: action.totalCount || state.milestones.totalCount,
                    pageNumber: action.pageNumber || state.milestones.pageNumber,
                    pageSize: action.pageSize || state.milestones.pageSize,
                }
            }
        case TYPES.FETCH_MILESTONES_LIST.FULFILLED:
            return {
                ...state,
                milestones: {
                    result: action.result || state.milestones.result,
                    totalCount: action.totalCount || state.milestones.totalCount,
                    pageNumber: action.pageNumber || state.milestones.pageNumber,
                    pageSize: action.pageSize || state.milestones.pageSize,
                }
            }
        case TYPES.FETCH_MRN_DETAILS.FULFILLED:
            return {
                ...state,
                mrnData: action.result || state.mrnData
            }
        default:
            return state;
    }
};
