import React from 'react';
import { Accordion } from 'components/accordion';
import { QuarterGrid } from 'components/grid/quarter-grid';
import { OneStatus } from 'components/status-row/OneStatus';
import { formatValueIfNullOrUndefined, formatDate } from 'utilities/util';

interface IUserDetailsProps {
    user?: string | null;
    date?: string | null;
}

export const UserDetails: React.StatelessComponent<IUserDetailsProps> = (props: IUserDetailsProps) => {
    return(
        <Accordion text="User details">
            <QuarterGrid>
                <OneStatus label="Last user updated">
                    {props.user ? formatValueIfNullOrUndefined(props.user) : '-'}
                </OneStatus>
                <OneStatus label="Last updated date">
                    {props.date ? formatDate(props.date) : '-'}
                </OneStatus>
            </QuarterGrid>
        </Accordion>
    );
};