import React from 'react';
import { ColumnFactory } from 'components/data-table/ColumnFactory';
import { DataTable } from 'components/data-table/DamcoTable';
import { ICblDetailsDocument } from 'reducers/fact/models';
import { StatusRow } from 'components/status-row';
import { cloneDataWithDisplayNames } from 'utilities/tables';

interface IDocumentsTableProps {
    documents: ICblDetailsDocument[];
}

export const DocumentsTable: React.FunctionComponent<IDocumentsTableProps> = (props) => {
    const data = cloneDataWithDisplayNames(props.documents, 'DD-MM-YYYY, h:mm');

    return(
        <StatusRow title="Documents">
            <DataTable
                data={data}
                columns={[
                    ColumnFactory.standardColumn('documentNumberDisplayName', 'Document number'),
                    ColumnFactory.standardColumn('originalDocumentNumberDisplayName', 'Original document number'),
                    ColumnFactory.standardColumn('typeDisplayName', 'Type'),
                    ColumnFactory.standardColumn('createDateDisplayName', 'Create date'),
                    ColumnFactory.standardColumn('updateDateDisplayName', 'Update date'),
                ]}
                withCheckboxes={false}
                reference={null as any}
                tableName="CblDetailsDocumentsTable"
                showPagination={true}
                expandable={false}
            />
        </StatusRow>
    );
};

