import { CONFIG as DEV_CONFIG_LOCAL } from './dev-local';
import { CONFIG as DEV_CONFIG } from './dev';
import { CONFIG as PREPROD_CONFIG } from './preprod';
import { CONFIG as UAT_CONFIG } from './uat';
import { CONFIG as PROD_CONFIG } from './prod';
import { IConfig } from 'configs/config.interface';

const environment = process.env.NODE_ENV;

const getEnvironment = (): IConfig => {
    if(environment === 'development') {
        return DEV_CONFIG;
    }
    if(environment === 'uat') {
        return UAT_CONFIG;
    }
    if(environment === 'preprod') {
        return PREPROD_CONFIG;
    }
    if(environment === 'production') {
        return PROD_CONFIG;
    }
    return DEV_CONFIG_LOCAL;
};

export const CONFIG: IConfig = getEnvironment();
