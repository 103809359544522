import { createAsyncActionConstants } from 'utilities/util';

export const TYPES = {
    FETCH_FACT_DATA: createAsyncActionConstants('FETCH_FACT_DATA'),
    FETCH_ASN_MESSAGES: createAsyncActionConstants('FETCH_ASN_MESSAGES'),
    FETCH_DOCUMENT_DETAILS: createAsyncActionConstants('FETCH_DOCUMENT_DETAILS'),
    FETCH_EQUIPMENT_DETAILS: createAsyncActionConstants('FETCH_EQUIPMENT_DETAILS'),
    FETCH_CBL_DETAILS: createAsyncActionConstants('FETCH_CBL_DETAILS'),
    FETCH_EQUIPMENT_TRANSPORT_SHIPPMENT_DETAILS: createAsyncActionConstants('FETCH_EQUIPMENT_TRANSPORT_SHIPPMENT_DETAILS'),
    FETCH_EQUIPMENTS_LIST: createAsyncActionConstants('FETCH_EQUIPMENTS_LIST'),
    GET_FILE: createAsyncActionConstants('GET_FILE'),
    FETCH_ESSO_LIST: createAsyncActionConstants('FETCH_ESSO_LIST'),
    FETCH_ISSO_LIST: createAsyncActionConstants('FETCH_ISSO_LIST'),
    FETCH_CBLS_LIST: createAsyncActionConstants('FETCH_CBLS_LIST'),
    FETCH_SHIPMENT_DETAILS: createAsyncActionConstants('FETCH_SHIPMENT_DETAILS'),
    CREATE_ESSO: createAsyncActionConstants('CREATE_ESSO'),
    CREATE_ISSO: createAsyncActionConstants('CREATE_ISSO'),
    GENERATE_DQ_ISSUES_REPORT: createAsyncActionConstants('GENERATE_DQ_ISSUES_REPORT')
}