import React from 'react';
import { IBL, IBLVessel } from 'reducers/vessels/models';
import { IStore } from 'reducers/index';
import { ThunkDispatch } from 'redux-thunk';
import { Action } from 'redux';
import { fetchMAWBDetails, fetchMawbList } from 'actions/vessels';
import { EditCBLMAWBDetailsPopup } from 'modules/tracking/containers/components/EditCBLMAWBDetailsPopup';
import { connect } from 'react-redux';

interface IMapStateToProps {
    cblDetailsModalBLId: number;
    vessels: IBLVessel[];
}
interface IDispatch {
    fetchMAWBList: () => Promise<void>;
    fetchMAWBDetails: (blId: number) => Promise<void>;
}

interface IEditMAWBDetailsPopupOwnProps {
    isDetails?: boolean;
}

interface IEditMAWBDetailsPopup extends IEditMAWBDetailsPopupOwnProps, IDispatch, IMapStateToProps {
}

const _EditMAWBDetailsPopup = (props: IEditMAWBDetailsPopup) => {
    const refetchFunction = props.isDetails ? () => props.fetchMAWBDetails(props.cblDetailsModalBLId) : props.fetchMAWBList;
    const vessels = props.vessels.map((vessel: IBLVessel) => ({
        ...vessel,
        cblDetailsModalBLId: props.cblDetailsModalBLId
    }));

    return (
        <EditCBLMAWBDetailsPopup
            {...props}
            refetchFunction={refetchFunction}
            vessels={vessels}
            isAir={true}
        />
    );
};

const getVessels = (state: IStore, props: IEditMAWBDetailsPopupOwnProps): IBLVessel[] => {
    const { cblDetailsModalBLId } = state.vessels;
    if(!cblDetailsModalBLId) {
        return [];
    }
    if(props.isDetails) {
        return state.vessels.mawbDetails.vessels as any[];
    }
    return (state.vessels.mawbList.data.find((vessel: IBL) => vessel.blId === cblDetailsModalBLId) as IBL).vessels;
};

const mapStateToProps = (state: IStore, props: IEditMAWBDetailsPopupOwnProps): IMapStateToProps => {
    const { cblDetailsModalBLId } = state.vessels;
    return {
        cblDetailsModalBLId,
        vessels: getVessels(state, props),
    }
};

const mapDispatchToProps = (dispatch: ThunkDispatch<any, any, Action>): IDispatch => ({
    fetchMAWBList: () => dispatch(fetchMawbList(1, 10)),
    fetchMAWBDetails: (blId: number) => dispatch(fetchMAWBDetails(blId)),
});

export const EditMAWBDetailsPopup = connect(mapStateToProps, mapDispatchToProps)(_EditMAWBDetailsPopup);
