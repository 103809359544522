import React, {FunctionComponent, useRef, useEffect} from 'react';
import { connect } from 'react-redux';
import { getUAMBusinessFunctions, setUser } from 'actions/user';
import { detectAuthStatus } from 'actions/auth';
import { authentication, acquireToken } from './authentication';
import { LoadingAnimation } from 'components/loading';
import { CONFIG } from 'configs/index';
import { IStore } from 'reducers/index';
import { IRoles } from 'utilities/roles';
import { ThunkDispatch } from 'redux-thunk';
import { Action } from 'redux';
import { User } from 'msal';

interface IMapStateToProps {
    signedIn: boolean;
    roles: IRoles[];
}

interface IDispatch {
    user: (user: User) => void;
    setAuthenticateStatus: () => void;
    getUAMBusinessFunctions: () => void;
}

interface IMsal extends IDispatch, IMapStateToProps {};

const Msal: FunctionComponent<IMsal> = (props) => {
    const isInitialMount = useRef<boolean>(true);

    useEffect(() => {
        if (isInitialMount.current) {
            isInitialMount.current = false;

            authentication.initialize(CONFIG.MSAL);
            acquireToken(props.setAuthenticateStatus);
            authentication.setAuthorizationInterceptors();
        } else {
            if(props.roles.length > 0 && props.signedIn) {
                props.user(authentication.getUser());
            } else if(props.signedIn) {
                props.user(authentication.getUser());
                props.getUAMBusinessFunctions();
            } else {
                authentication.signOut();
            }
        }
    }, [props.signedIn]);

    if(props.signedIn) {
        return(
            <React.Fragment>
                {props.children}
            </React.Fragment>
        )
    }
    return <LoadingAnimation />;
}

const mapStateToProps = (state: IStore): IMapStateToProps => ({
    signedIn: state.auth.signedIn,
    roles: state.user.roles
});

const mapDispatchToProps = (dispatch: ThunkDispatch<any, any, Action>): IDispatch => ({
    user: (user: User) => dispatch(setUser(user)),
    setAuthenticateStatus: () => dispatch(detectAuthStatus()),
    getUAMBusinessFunctions: () => dispatch(getUAMBusinessFunctions()),
});

export default connect<IMapStateToProps, IDispatch, {}, IStore>(mapStateToProps, mapDispatchToProps)(Msal);
