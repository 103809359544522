import React, { useEffect } from 'react';
import { Page } from 'components/page-container';
import { PageHeader } from 'components/page-header';
import { PageWithTimeline } from 'components/page-with-timeline';
import { CustomsTrackingContainerDetailsTopBar } from './components/CustomsTrackingContainerDetailsTopBar';
import { EquipmentDetailsTable } from './components/EquipmentDetailsTable';
import { HouseBLTable } from './components/HouseBLTable';
import { fetchContainerDetails } from 'actions/customs';
import { connect } from 'react-redux';
import { ThunkDispatch } from 'redux-thunk';
import { RouteComponentProps } from 'react-router-dom';
import { Action } from 'redux';
import { IStore } from 'reducers/index';
import { IContainer } from 'reducers/customs/models';
import { HandleError } from 'components/handle-error';
import {LoadingAnimation} from 'components/loading';
import { LastUser } from 'views/customs/customs-tracking-container-details/components/LastUser';
import { PoTable } from 'views/customs/customs-tracking-container-details/components/PoTable';
import { CustomsTypes } from 'constants/comment';
import { Capability } from 'components/timeline';
import { GenericError } from 'components/error-dialog/GenericError';
import { ProgressIndicator } from 'components/progress-indicator';
import { IBubble } from 'reducers/vessels/models';

interface IDispatch {
    fetchContainerDetails: (equipmentId: number) => Promise<void>;
}

interface IMapToState {
    container: IContainer
    isFetchContainerDetails: boolean;
    isError: boolean;
    isFetchContainerError: boolean;
    bubbles: IBubble[];
}

interface ICustomTrackingContainerDetails extends RouteComponentProps<{ containerId: string }>, IMapToState, IDispatch {}

const _CustomsTrackingContainerDetails: React.FunctionComponent<ICustomTrackingContainerDetails> = (props) => {
        const id = parseInt(props.match.params.containerId, 10);

        useEffect(() => {
            if (!Number.isNaN(id)) {
                props.fetchContainerDetails(id);
            }
        }, []);

        if (Number.isNaN(id)) {
            return <GenericError />;
        }

        return (
            <>
                { props.isFetchContainerDetails && <LoadingAnimation /> }
                <PageHeader backButton={true}>Equipment: {props.container.equipment.equipmentNumber}</PageHeader>
                <HandleError
                    isFetchContainerError={props.isFetchContainerError}
                    isError={props.isError}
                />
                <ProgressIndicator data={props.bubbles} />
                <CustomsTrackingContainerDetailsTopBar 
                    {...props.container} 
                    equipId={[id]} 
                    refreshFunction={props.fetchContainerDetails}
                    voyageId={props.container.voyageId}
                    data={props.container.equipment}
                />
                <Page>
                    <PageWithTimeline
                        objectId={id}
                        objectType={CustomsTypes.Equipment}
                        isAir={false}
                        capability={Capability.Customs}
                    >
                        <PoTable data={props.container.po}/>
                        <EquipmentDetailsTable {...props.container.equipment} />
                        <HouseBLTable data={props.container.hbl}/>
                        <LastUser {...props.container.lastUser} />
                    </PageWithTimeline>
                </Page>
            </>
        );
}

const mapDispatchToProps = (dispatch: ThunkDispatch<any, any, Action>): IDispatch => ({
    fetchContainerDetails: (equipmentId: number) => dispatch(fetchContainerDetails(equipmentId)),
});

const mapStateToProps = (state: IStore): IMapToState => ({
    container: state.customs.container,
    isFetchContainerDetails: state.customs.isFetchContainerDetails,
    isFetchContainerError: !!state.customs.fetchContainerError,
    isError: state.customs.isError,
    bubbles: state.customs.container.bubbleData
});


export const CustomsTrackingContainerDetails = connect(mapStateToProps, mapDispatchToProps)(_CustomsTrackingContainerDetails);
