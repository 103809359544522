import React from 'react';
import { StatusRow } from 'components/status-row';
import { QuarterGrid } from 'components/grid/quarter-grid';
import { OneStatus } from 'components/status-row/OneStatus';
import { formatValueIfNullOrUndefined, formatDate } from 'utilities/util';

interface IFlightDetailsRow {
    FlightNumber: string;
    destinationPort: string;
    eta: string;
    ata: string;
}

export const FlightDetailsRow = (props: IFlightDetailsRow) => (
    <StatusRow title="Flight Details">
        <QuarterGrid>
            <OneStatus label="Flight">{formatValueIfNullOrUndefined(props.FlightNumber)}</OneStatus>
            <OneStatus label="POD">{formatValueIfNullOrUndefined(props.destinationPort)}</OneStatus>
            <OneStatus label="CurrentETA">{formatDate(props.eta)}</OneStatus>
            <OneStatus label="ATA">{formatDate(props.ata)}</OneStatus>
        </QuarterGrid>
    </StatusRow>
);
