import React from 'react';
import { StatusRow } from 'components/status-row';
import { OneStatus } from 'components/status-row/OneStatus';
import { QuarterGrid } from 'components/grid/quarter-grid';
import { formatValueIfNullOrUndefined } from 'utilities/util';

interface IPlaceRowProps {
    placeOfConsolidationCode: string;
    placeOfConsolidationName: string;
    placeOfDeconsolidationCode: string;
    placeOfDeconsolidationName: string;
    placeOfDeliveryCode: string;
    placeOfDeliveryName: string;
}

export const PlaceRow: React.FunctionComponent<IPlaceRowProps> = (props) => {
    return (
        <StatusRow title="Place">
            <QuarterGrid>
                <OneStatus label="Place of consolidation code">
                    {formatValueIfNullOrUndefined(props.placeOfConsolidationCode)}
                </OneStatus>
                <OneStatus label="Place of consolidation name">
                    {formatValueIfNullOrUndefined(props.placeOfConsolidationName)}
                </OneStatus>
                <OneStatus label="Place of deconsolidation code">
                    {formatValueIfNullOrUndefined(props.placeOfDeconsolidationCode)}
                </OneStatus>
                <OneStatus label="Place of deconsolidation name">
                    {formatValueIfNullOrUndefined(props.placeOfDeconsolidationName)}
                </OneStatus>
            </QuarterGrid>
            <QuarterGrid>
                <OneStatus label="Place of delivery code">
                    {formatValueIfNullOrUndefined(props.placeOfDeliveryCode)}
                </OneStatus>
                <OneStatus label="Place of delivery name">
                    {formatValueIfNullOrUndefined(props.placeOfDeliveryName)}
                </OneStatus>
            </QuarterGrid>
        </StatusRow>
    );
};
