import React from 'react';
import styled from 'styled-components';

const SLink: any = styled.a`
    background: ${(props: IVerticalTab) => props.active ? '#ebf4fa' : 'transparent'} !important;
    border-right: ${(props: IVerticalTab) => props.active ? '3px solid #398FD1' : '0'} !important;
    color: ${(props: IVerticalTab) => props.active ? '#398FD1' : '#868F98'} !important;
    cursor: pointer;
`;

interface IVerticalTab {
    link: {
        linkText: string;
    };
    index: number;
    active: boolean;
    onClick: () => void;
}

export const VerticalTab: React.SFC<IVerticalTab> = (props: IVerticalTab) => (
    <li onClick={props.onClick}>
        <SLink active={props.active}>{props.link.linkText}</SLink>
    </li>
)
