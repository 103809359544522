import React from 'react';

interface IOneStatus {
    label: string;
    children: any;
    id?: string;
}

export const OneStatus: React.SFC<IOneStatus> = (props: IOneStatus) => (
    <div className="form-group">
        <label className="regular-inactive-text">
            {props.label}
        </label>
        <div id={props.id}>
            {props.children}
        </div>
    </div>
)