import React, { FunctionComponent, useRef } from 'react';
import { DataTable } from 'components/data-table/DamcoTable';
import { ColumnFactory } from 'components/data-table/ColumnFactory';
import { BUSINNESS_FUNCTIONS } from 'constants/roles';
// tslint:disable-next-line: no-submodule-imports
import { withPermissions, IWithPermissions } from 'src/HOC/withPermissions';
import { cloneDataWithDisplayNames } from 'utilities/tables';
import { ICbl, ICblDocument } from 'reducers/fact/models';

interface ICblsTableOwnProps {
    data: ICbl[];
    pageCount: number;
    fetchCbls: any;
}

interface ICblsTableProps extends IWithPermissions, ICblsTableOwnProps {}

const _CblsTable: FunctionComponent<ICblsTableProps> = (props) => {
    const dataTable = useRef<any>();
    const data = cloneDataWithDisplayNames(props.data).map(cbl => ({
        ...cbl,
        numberDisplayName: [cbl.number, `/exception-management/fact/cbls/${cbl.id}`],
        children: cbl.documents.map((document: ICblDocument) => ({
            ...document,
            documentNumberDisplayName: [document.documentNumber, `/exception-management/fact/documents/${document.documentId}`]
        }))
    }));

    return (
        <React.Fragment>
            <DataTable
                data={data}
                showColumnOptions={true}
                columns={[
                    ColumnFactory.linkedColumn('numberDisplayName', 'CBL number'),
                    ColumnFactory.standardColumn('carrierBookingReferenceDisplayName', 'Carrier booking reference'),
                    ColumnFactory.standardColumn('carrierCodeDisplayName', 'Carrier code'),
                    ColumnFactory.standardColumn('finalPortOfDischargeNameDisplayName', 'Final port of discharge - name'),
                    ColumnFactory.standardColumn('finalPortOfDischargeCodeDisplayName', 'Final port of discharge - code'),
                    ColumnFactory.standardColumn('originalPortOfLoadingNameDisplayName', 'Original port of loading - name'),
                    ColumnFactory.standardColumn('originalPortOfLoadingCodeDisplayName', 'Original port of loading - code'),
                    ColumnFactory.standardColumn('placeOfDeliveryNameDisplayName', 'Place of delivery - name'),
                    ColumnFactory.standardColumn('placeOfDeliveryCodeDisplayName', 'Place of delivery - code'),
                    ColumnFactory.standardColumn('transportContractNumberDisplayName', 'Transport contract number'),
                    ColumnFactory.standardColumn('typeDisplayName', 'Type')
                ]}
                subColumns={[
                    ColumnFactory.linkedColumn('documentNumberDisplayName', 'Document number'),
                    ColumnFactory.standardColumn('originalDocumentNumberDisplayName', 'Original document number'),
                    ColumnFactory.standardColumn('typeDisplayName', 'Type'),
                    ColumnFactory.standardColumn('createDateDisplayName', 'Create date'),
                    ColumnFactory.standardColumn('updateDateDisplayName', 'Update date'),
                ]}
                expandable={true}
                withCheckboxes={false}
                tableName="FactCblsList"
                reference={dataTable}
                pageCount={props.pageCount}
                manualPagination={true}
                fetchData={props.fetchCbls}
            />
        </React.Fragment>
    );
}

const ROLES: BUSINNESS_FUNCTIONS[] = [];

export const CblsTable = withPermissions(_CblsTable, ROLES) as any;
