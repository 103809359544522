import React from 'react';
import { ColumnFactory } from 'components/data-table/ColumnFactory';
import { formatValueIfNullOrUndefined } from 'utilities/util';

export const StandardColumnOld = (accessor: string, name: string) => {
    const cell = (rowInfo: any) => (
        <div key={`${rowInfo.index}-${rowInfo.viewIndex}`}>
            {formatValueIfNullOrUndefined(rowInfo.value)}
        </div>
    );
    return ColumnFactory.getColumnOptionsOld(accessor, name, cell);
};