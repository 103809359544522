import React from 'react';
import { ModalScreen } from 'components/modal-screen';
import { ModalType } from 'components/modal-screen/ModalType';
import { Button, ButtonType } from 'components/button';
import { connect } from 'react-redux';
import { DeliveryAddTransportationRowModal } from './DeliveryAddTransportationRowModal';
import { LoadingAnimation } from 'components/loading';
import { ColumnFactory } from 'components/data-table/ColumnFactory';
import { ISettingsModel } from 'reducers/settings/models';
import { withTableCheckboxes, IWithTableCheckboxesProps } from 'containers/data-table/withCheckboxes';
import styled from 'styled-components';
import { hauliersToEnum } from 'utilities/util';
import { Transport_Mode, TRANSPORT_TYPE_OPTIONS } from 'constants/transport';
import { DataTable } from 'components/data-table/DataTable';
import { AreYouSureModal } from 'modules/modals/components/AreYouSureModal';
import { getEquipmentDeliveries, deleteEquipmentDeliveries } from 'actions/planning';
import { fetchDeliveryPlanningHaulierSettings } from 'actions/settings';
import { ThunkDispatch } from 'redux-thunk';
import { Action } from 'redux';
import { IStore } from 'reducers/index';
import { cloneDataWithDisplayNames } from 'utilities/tables';

interface IEditTransportationDetailsModalOwnProps {
    visible: boolean;
    closeModal: () => void;
    id: number;
    isAir: boolean;
    refetchFunction(): void;
}

interface IMapStateToProps {
    isLoading: boolean;
    data: any[];
    deliveryPlanningHaulierSettings: ISettingsModel[];
    fetchDeliveryPlanningHaulierSettingsPending: boolean;
}

interface IDispatch {
    deleteEquipmentDeliveries: (containerId: number, ids: { deliveriesIds: number[]}) => Promise<void>;
    getEquipmentDeliveries: (id: number) => Promise<void>;
    fetchDeliveryPlanningHaulierSettings: () => void,
}

interface IEditEquipmentDeliveriesTableState {
    isAreYouSureModalOpened: boolean;
    isInsertRowModalOpened: boolean;
    isEdit: boolean;
}

interface IEditTransportationDetailsModal extends IEditTransportationDetailsModalOwnProps, IWithTableCheckboxesProps, IMapStateToProps, IDispatch {}

const SButton = styled(Button)`
    margin-right: 10px;
    margin-bottom: 10px;
`;

class _EditTransportationDetailsModal extends React.Component<IEditTransportationDetailsModal, IEditEquipmentDeliveriesTableState> {
    public state: IEditEquipmentDeliveriesTableState = {
        isAreYouSureModalOpened: false,
        isInsertRowModalOpened: false,
        isEdit: false,
    };

    public componentDidMount() {
        this.props.fetchDeliveryPlanningHaulierSettings();
        this.props.getEquipmentDeliveries(this.props.id);
    }

    public componentDidUpdate(prevProps: IEditTransportationDetailsModal) {
        if (this.props.id !== prevProps.id) {
            this.props.getEquipmentDeliveries(this.props.id);
        }
    }

    public render() {
        const hauliers = hauliersToEnum(this.props.deliveryPlanningHaulierSettings);
        const data = cloneDataWithDisplayNames(this.props.data).map((obj: any) => ({
            ...obj,
            transportModeDisplayName: Transport_Mode[obj.transportMode],
            transportTypeDisplayName: TRANSPORT_TYPE_OPTIONS[obj.transportModeDisplayName].find((item: any) => item.value === obj.transportType).optionText,
            haulierDisplayName: hauliers[obj.haulier]
        }));

        return (
            <ModalScreen
                title="Edit transportation details"
                visible={this.props.visible}
                modalType={ModalType.xl()}
                closeModal={this.closeAndRefetch}
                primaryButtonTitle="Done"
                primaryButtonFunc={this.closeAndRefetch}
                secondaryButtonTitle="Cancel"
                secondaryButtonFunc={this.closeAndRefetch}
            >
                {this.props.isLoading || this.props.fetchDeliveryPlanningHaulierSettingsPending && <LoadingAnimation/>}
                {this.state.isInsertRowModalOpened && <DeliveryAddTransportationRowModal
                    visible={this.state.isInsertRowModalOpened}
                    closeModal={this.toggleAddTransportationRowModal}
                    ids={[this.props.id]}
                    initialValues={this.state.isEdit ? this.props.checked[0] : {}}
                    isAir={this.props.isAir}
                    fromPlanning={false}
                />}
                <AreYouSureModal
                    visible={this.state.isAreYouSureModalOpened}
                    onClose={this.toggleAreYouSureModal}
                    onAccept={this.deleteDeliveries}
                />
                <SButton
                    buttonType={ButtonType.Blue}
                    onClick={this.toggleAddTransportationRowModal}
                    id="insert-row-button"
                >
                    Insert row
                </SButton>
                <SButton
                    buttonType={ButtonType.Transparent}
                    onClick={this.toggleEditTransportationRowModal}
                    isDisabled={!this.props.checked.length}
                    id="edit-row-button"
                >
                    Edit row
                </SButton>
                <SButton
                    buttonType={ButtonType.Transparent}
                    onClick={this.toggleAreYouSureModal}
                    isDisabled={!this.props.checked.length}
                    id="delete-row-button"
                >
                    Remove row
                </SButton>
                <DataTable
                    data={data}
                    columns={[
                        ColumnFactory.checkBoxColumn(this.props.toggleCheckbox, () => false, false),
                        ColumnFactory.standardColumnOld('transportModeDisplayName', 'Transport mode'),
                        ColumnFactory.standardColumnOld('transportTypeDisplayName', 'Transport type'),
                        ColumnFactory.standardColumnOld('haulierDisplayName', 'Haulier'),
                        ColumnFactory.standardColumnOld('isTransportOrderSentDisplayName', 'TO sent'),
                        ColumnFactory.standardColumnOld('pickupLocationDisplayName', 'Pickup location'),
                        ColumnFactory.dateColumnOld('estimatedPickupDate', 'Pickup date'),
                        ColumnFactory.standardColumnOld('dropOffLocationDisplayName', 'Drop-off location'),
                        ColumnFactory.dateColumnOld('estimatedDropOffDate', 'Drop-off date'),
                        ColumnFactory.standardColumnOld('deliveryReferenceDisplayName', 'Delivery reference'),
                    ]}
                />
            </ModalScreen>
        );
    }

    private toggleAreYouSureModal = () => this.setState((state) => ({
        isAreYouSureModalOpened: !state.isAreYouSureModalOpened,
    }));

    private toggleAddTransportationRowModal = () => this.setState((state) => ({
        isInsertRowModalOpened: !state.isInsertRowModalOpened,
        isEdit: false,
    }));

    private toggleEditTransportationRowModal = () => this.setState((state) => ({
        isInsertRowModalOpened: !state.isInsertRowModalOpened,
        isEdit: true,
    }));

    private deleteDeliveries = async () => {
        const deliveriesIds = this.props.checked.map(({ id }: any) => id);
        await this.props.deleteEquipmentDeliveries(this.props.id, { deliveriesIds });
        this.props.getEquipmentDeliveries(this.props.id);
    }

    private closeAndRefetch = () => {
        this.props.refetchFunction();
        this.props.closeModal();
    }
}

const mapStateToProps = (state: IStore): IMapStateToProps => {
    const {
        fetchDeliveryPlanningHaulierSettingsPending,
        deliveryPlanningHaulierSettings,
    } = state.settings;
    return {
        deliveryPlanningHaulierSettings,
        isLoading: state.planning.isDeleteEquipmentDeliveriesPending || state.planning.isGetEquipmentsPending,
        fetchDeliveryPlanningHaulierSettingsPending,
        data: state.planning.equipmentDeliveries,
    }
};

const mapDispatchToProps = (dispatch: ThunkDispatch<any, any, Action>) => ({
    deleteEquipmentDeliveries: (containerId: number, ids: { deliveriesIds: number[]}) => dispatch(deleteEquipmentDeliveries(containerId, ids, false)),
    getEquipmentDeliveries: (id: number) => dispatch(getEquipmentDeliveries(id, false)),
    fetchDeliveryPlanningHaulierSettings: () => dispatch(fetchDeliveryPlanningHaulierSettings(true)),
});

export const EditTransportationDetailsModal = connect<IMapStateToProps, IDispatch, IEditTransportationDetailsModalOwnProps, IStore>(mapStateToProps, mapDispatchToProps)(
    withTableCheckboxes<IEditTransportationDetailsModalOwnProps>(_EditTransportationDetailsModal, 'id')
);
