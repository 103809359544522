import React from 'react';
import { ColumnFactory } from 'components/data-table/ColumnFactory';
import { DataTable } from 'components/data-table/DamcoTable';
import { IETSIsso } from 'reducers/fact/models';
import { StatusRow } from 'components/status-row';
import { cloneDataWithDisplayNames } from 'utilities/tables';

interface IVoyagesTableProps {
    issos: IETSIsso[];
}

export const IssosTable: React.FunctionComponent<IVoyagesTableProps> = (props) => {
    const data = cloneDataWithDisplayNames(props.issos, 'DD-MM-YYYY, h:mm').map(isso => ({
        ...isso,
        isSentDisplayName: isso.isSent ? 'Y' : 'N',
        financialChangeIndicatorDisplayName: isso.financialChangeIndicator ? 'Y' : 'N',
        reasonDisplayName: isso.reasonDisplayName ? isso.reasonDisplayName : '-'
    }));

    return(
        <StatusRow title="ISSOs">
            <DataTable
                data={data}
                columns={[
                    ColumnFactory.standardColumn('issoNumberDisplayName', 'ISSO number'),
                    ColumnFactory.standardColumn('messageTypeDisplayName', 'Message type'),
                    ColumnFactory.standardColumn('isSentDisplayName', 'Is sent'),
                    ColumnFactory.standardColumn('generationDateDisplayName', 'Generation date'),
                    ColumnFactory.standardColumn('changedFinancialFieldsDisplayName', 'Changed financial fields'),
                    ColumnFactory.standardColumn('financialChangeIndicatorDisplayName', 'Financial change indicator'),
                    ColumnFactory.standardColumn('brainResponseStatusCodeDisplayName', 'Brain response status code'),
                    ColumnFactory.standardColumn('reasonDisplayName', 'Reason')
                ]}
                subColumns={[
                    ColumnFactory.standardColumn('ErrorDisplayName', 'Error'),
                ]}
                withCheckboxes={false}
                reference={null as any}
                tableName="ETSDetailsIssosTable"
                showPagination={true}
                expandable={true}
            />
        </StatusRow>
    );
};

