import { formatDate } from './util';
import { SORT_ORDER, ISortModel } from 'reducers/filters/models';

export const createDisplayNameProperty = (property: string, value: any, formatFunc?: () => string | number) => {
    if(formatFunc) {
        return {
            [property]: value,
            [`${property}DisplayName`]: formatFunc()
        }
    }

    return {
        [property]: value,
        [`${property}DisplayName`]: value
    }
};

export const cloneDataWithDisplayNames = (data: any[], dateFormat: string = 'DD-MM-YYYY') => {
    const regEx = /\b[0-9]{4}-[0-9]{2}-[0-9]{2}T[0-9]{2}:[0-9]{2}:[0-9]{2}Z?\b/;
    
    if(data.length > 0) {
        data.map((obj: any) => {
            // tslint:disable-next-line: forin
            for(const property in obj) {
                if(Array.isArray(obj[property])) {
                    if(typeof obj[property][0] === 'string') {
                        Object.assign(obj, {
                            ...createDisplayNameProperty(property, obj[property])
                        });
                    } else {
                        Object.assign(obj, {
                            children: cloneDataWithDisplayNames(obj[property], dateFormat)
                        });
                    }
                } else if(obj[property] === null) {
                    Object.assign(obj, {
                        ...createDisplayNameProperty(property, obj[property], () => '-')
                    });
                } else if(regEx.test(obj[property])) {
                    Object.assign(obj, {
                        ...createDisplayNameProperty(property, obj[property], () => formatDate(obj[property], dateFormat)),
                    });
                } else if(obj[property] === true || obj[property] === false) {
                    Object.assign(obj, {
                        ...createDisplayNameProperty(property, obj[property], () => obj[property] === true ? 'Y' : 'N')
                    });
                } else {
                    Object.assign(obj, {
                        ...createDisplayNameProperty(property, obj[property])
                    });
                }
            }
        });
    }

    return data;
};

export const prettifyString = (value: string = ''): string => {
    let output: string = '';
    const len: number = value !== null ? value.length : 0;
    let char: string;

    for (let i = 0; i < len; i++) {
        char = value.charAt(i);

        if (i === 0) {
            output += char.toUpperCase();
        }
        else if (char !== char.toLowerCase() && char === char.toUpperCase()) {
            output += ' ' + char;
        }
        else if (char === '-' || char === '_') {
            output += ' ';
        }
        else {
            output += char;
        }
    }

    return output;
};

export const setSortForTable = (data: Array<{ desc: boolean, id: string}>, sortFunction: (sort: ISortModel) => void) => {
    if(data && data.length > 0) {
        const order = data[0].desc ? SORT_ORDER.DESCENDING : SORT_ORDER.ASCENDING;
        const sort = {
            column: data[0].id.replace('DisplayName', ''),
            order,
            desc: data[0].desc,
        };
        sortFunction(sort);
    }
}