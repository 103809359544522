import React, { Component } from 'react';
import { VerticalTab } from 'components/sub-navigation-vertical/components/VerticalTab';
import { withRouter, RouteComponentProps } from 'react-router';
import styled from 'styled-components';
import { ILink } from 'components/sub-navigation/models/Link';

const SSubNavigationVertical = styled.ul`
    width: 100%;
`;

interface ISubNavigationProps {
    links: ILink[];
}

interface ISubNavigation extends RouteComponentProps<{}>, ISubNavigationProps {}

interface ISubNavigationState {
    activeLink: string;
}

class _SubNavigationVertical extends Component<ISubNavigation, ISubNavigationState> {
    public state: ISubNavigationState = {
        activeLink: '',
    };

    public render() {
        const activePath = this.getActiveTabFromUrl();
        const navigationLinks = this.props.links.map((link, index) => {
            const linkText = link.linkText.toLowerCase();
            const onClick = () => {
                this.props.history.push(link.to);
                this.setState({ activeLink: linkText });
            };

            return (
                <VerticalTab
                    link={link}
                    index={index}
                    onClick={onClick}
                    key={index}
                    active={linkText === activePath || linkText === this.state.activeLink}
                />
            );
        });

        return(
            <SSubNavigationVertical className="dc-tabs-vertical">
                {navigationLinks}
            </SSubNavigationVertical>
        );
    }

    private getActiveTabFromUrl = () => {
        const url = this.props.location.pathname;
        const splitUrl = url.split('/').map(item => item.replace(/-/g, ' '));

        switch(splitUrl[splitUrl.length - 2]) {
            case 'container tracking':
                return splitUrl[splitUrl.length - 1];
            case 'customs':
                return splitUrl[splitUrl.length - 1];
            case 'delivery planning':
                return splitUrl[splitUrl.length - 1];
            case 'demurrage and detention':
                return splitUrl[splitUrl.length - 1];
            case 'fact':
                return splitUrl[splitUrl.length - 1];
            default:
                return;
        }
    }
};

export const SubNavigationVertical = withRouter(_SubNavigationVertical);
