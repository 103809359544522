import React from 'react';
import { ColumnFactory } from 'components/data-table/ColumnFactory';
import { DataTable } from 'components/data-table/DamcoTable';
import { IEquipmentCbl, IEquipmentCblDocumentOnEquipment } from 'reducers/fact/models';
import { StatusRow } from 'components/status-row';
import { cloneDataWithDisplayNames } from 'utilities/tables';

interface ICblsTableProps {
    cbls: IEquipmentCbl[];
}

export const CblsTable: React.StatelessComponent<ICblsTableProps> = (props) => {
    const data = cloneDataWithDisplayNames(props.cbls, 'DD-MM-YYYY, h:mm').map(cbl => ({
        ...cbl,
        cblNumberDisplayName: [cbl.cblNumber, `/exception-management/fact/cbls/${cbl.cblId}`],
        children: cbl.cblDocumentsOnEquipment.map((doc: IEquipmentCblDocumentOnEquipment) => ({
            ...doc,
            documentNumberDisplayName: [doc.documentNumber, `/exception-management/fact/documents/${doc.documentId}`]
        }))
    }));

    return(
        <StatusRow title="CBLs">
            <DataTable
                data={data}
                columns={[
                    ColumnFactory.linkedColumn('cblNumberDisplayName', 'CBL number'),
                    ColumnFactory.standardColumn('carrierCodeDisplayName', 'Carrier code'),
                    ColumnFactory.standardColumn('carrierBookingReferenceDisplayName', 'Carrier booking reference'),
                    ColumnFactory.standardColumn('transportContractNumberDisplayName', 'Transport contract number'),
                    ColumnFactory.standardColumn('insertDateDisplayName', 'Insert date'),
                    ColumnFactory.standardColumn('updateDateDisplayName', 'Update date'),
                ]}
                subColumns={[
                    ColumnFactory.linkedColumn('documentNumberDisplayName', 'Document number'),
                    ColumnFactory.standardColumn('originalDocumentNumberDisplayName', 'Original document number'),
                    ColumnFactory.standardColumn('typeDisplayName', 'Type'),
                    ColumnFactory.standardColumn('volumeDisplayName', 'Volume'),
                    ColumnFactory.standardColumn('essoCblOnEtVolumeFactorDisplayName', 'ESSO CBL on ET volume factor'),
                    ColumnFactory.standardColumn('essoEtOnCblVolumeFactorDisplayName', 'ESSO ET on CBL volume factor'),
                ]}
                withCheckboxes={false}
                reference={null as any}
                tableName="EquipmentDetailsCblsTable"
                showPagination={true}
                expandable={true}
            />
        </StatusRow>
    );
};

