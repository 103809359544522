export const types = [
    {
        optionText: 'FCR',
        value: 'FCR',
    },
    {
        optionText: 'HBL',
        value: 'HBL',
    },
    {
        optionText: 'HSWB',
        value: 'HSWB',
    },
    {
        optionText: 'HAWB',
        value: 'HAWB',
    }
];