import React, { Component } from 'react';
import { MESSAGE_TYPES } from 'constants/message-type';
import { IOption } from 'components/select/Select';
import Select from 'react-select';
import { COMMENT_EVENT_TYPES } from 'constants/event-types';
import { Button, ButtonType } from 'components/button';
import { DoubleGrid } from 'components/grid/double-grid';
import { Arrow } from 'components/arrow';
import styled from 'styled-components';

interface IProps {
    onChange(filters: ICommentFilters): void;
}

export interface ICommentFilters {
    messageTypes: string[];
    contents: string[];
}

interface IState {
    filters: ICommentFilters;
    expanded: boolean;
}

const initialState: IState = {
    filters: {
        contents: [],
        messageTypes: [],
    },
    expanded: false,
};

const FiltersHeaderWrapper = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
`;

export class Filters extends Component<IProps, IState> {
    public state: IState = {
        ...initialState,
    };

    public componentDidMount() {
        this.props.onChange({ ...initialState.filters });
    }

    public render() {
        const commentTypes: IOption[] = Object.keys(MESSAGE_TYPES).map(key => ({
            optionText: MESSAGE_TYPES[key],
            value: MESSAGE_TYPES[key],
        }));

        return (
            <form className="filter-search-criteria" onSubmit={this.handleSubmit} style={{ marginBottom: '.5rem' }}>
                <fieldset>
                    <FiltersHeaderWrapper>
                        <div>Filters</div>
                        <Button buttonType={ButtonType.Transparent} onClick={this.toggleFilters}>
                            <Arrow isUp={this.state.expanded} style={{ marginRight: '5px' }} />
                            Expand
                        </Button>
                    </FiltersHeaderWrapper>

                    {this.state.expanded && (
                        <div className="filter-body" style={{ marginTop: '.5rem' }}>
                            <DoubleGrid>
                                <div>
                                    <label className="filter-label">Comment type</label>
                                    <Select<IOption>
                                        options={commentTypes}
                                        className="react-select-wrapper"
                                        isMulti={true}
                                        getOptionLabel={option => option.optionText}
                                        getOptionValue={option => option.value}
                                        value={commentTypes.filter(
                                            type => this.state.filters.messageTypes.indexOf(type.value) > -1,
                                        )}
                                        onChange={value =>
                                            this.setState({
                                                filters: {
                                                    ...this.state.filters,
                                                    messageTypes: value
                                                        ? Array.isArray(value)
                                                            ? value.map(v => v.value)
                                                            : [value.value]
                                                        : [],
                                                },
                                            })
                                        }
                                    />
                                </div>
                                <div>
                                    <label className="filter-label">Event type</label>
                                    <Select<string>
                                        options={COMMENT_EVENT_TYPES}
                                        className="react-select-wrapper"
                                        isMulti={true}
                                        getOptionLabel={option => option}
                                        getOptionValue={option => option}
                                        value={this.state.filters.contents}
                                        onChange={value =>
                                            this.setState({
                                                filters: {
                                                    ...this.state.filters,
                                                    contents: value ? (Array.isArray(value) ? value : [value]) : [],
                                                },
                                            })
                                        }
                                    />
                                </div>
                            </DoubleGrid>
                        </div>
                    )}
                </fieldset>

                {this.state.expanded && (
                    <fieldset>
                        <div className="filter-button-area">
                            <Button type="submit" buttonType={ButtonType.Blue}>
                                Apply
                            </Button>
                            <Button onClick={this.clearFilters} buttonType={ButtonType.Transparent}>
                                Clear
                            </Button>
                        </div>
                    </fieldset>
                )}
            </form>
        );
    }

    private toggleFilters = () => {
        this.setState(state => ({ expanded: !state.expanded }));
    };

    private clearFilters = () => {
        this.props.onChange({ ...initialState.filters });
        this.setState({ ...initialState });
    };

    private handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault();
        this.props.onChange(this.state.filters);
        return false;
    };
}
