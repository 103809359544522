import React from 'react';
import { ColumnFactory } from 'components/data-table/ColumnFactory';
import { DataTable } from 'components/data-table/DamcoTable';
import { IFactBooking } from 'reducers/fact/models';
import { StatusRow } from 'components/status-row';
import { cloneDataWithDisplayNames } from 'utilities/tables';

interface IBookingsTableProps {
    data: IFactBooking[];
}
export const BookingsTable: React.StatelessComponent<IBookingsTableProps> = (props) => {
    const data = cloneDataWithDisplayNames(props.data);

    return(
        <StatusRow title="Bookings">
            <DataTable
                data={data}
                columns={[
                    ColumnFactory.standardColumn('numberDisplayName', 'Number'),
                    ColumnFactory.standardColumn('cargoTypeDisplayName', 'Cargo type'),
                    ColumnFactory.standardColumn('consigneeBusinessEntityCodeDisplayName', 'Consignee business entity code'),
                    ColumnFactory.standardColumn('consigneeNameDisplayName', 'Consignee name'),
                    ColumnFactory.standardColumn('consigneeFactCodeDisplayName', 'Consignee fact code'),
                    ColumnFactory.standardColumn('consigneeSCVCodeDisplayName', 'Consignee SCV code'),
                    ColumnFactory.standardColumn('destinationSiteNameDisplayName', 'Destination site'),
                    ColumnFactory.standardColumn('originSiteNameDisplayName', 'Origin site'),
                    ColumnFactory.standardColumn('ultimatePlaceOfDeliveryNameDisplayName', 'Ultimate place of delivery'),
                    ColumnFactory.standardColumn('internalShipmentNumberDisplayName', 'Internal shipment number'),
                ]}
                withCheckboxes={false}
                reference={null as any}
                tableName="FactDetailsBookingsTable"
                showPagination={true}
            />
        </StatusRow>
    );
};

