import React from 'react';
import { ProgressIndicatorStep } from './components/step';
import { IBubble } from 'reducers/vessels/models';
import './sass/index.scss';

interface IProgressIndicator {
    data: IBubble[];
}

export const ProgressIndicator = (props: IProgressIndicator) => {
    const getColumnSize = () => {
        if(props.data.length === 1) {
            return 'col-100';
        } else if(props.data.length === 2) {
            return 'col-50';
        } else if(props.data.length === 3) {
            return 'col-30';
        } else if(props.data.length === 4) {
            return 'col-25';
        } else if(props.data.length > 4) {
            return 'col-20';
        } else if(props.data.length > 6) {
            return 'col-10';
        } else {
            return 'col-10';
        }
    };

    const steps = props.data ? props.data.map((step: any, i: number) => {
        return(
            <ProgressIndicatorStep
                key={i}
                isActive={step.isActive}
                isDone={step.done}
                isError={step.isError}
                status={step.status}
                colSize={getColumnSize()}
                line1={step.date}
                line2={step.location}
                isLink={step.isLink}
                linkAddress={step.linkAddress}
            />
        );
    }) : null;

    return(
        <div className="step-indicator grid-wrapper">
            <ul className="step-indicator__progressbar">
                {steps}
            </ul>

        </div>
    );
}