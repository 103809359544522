import { createAsyncActionConstants } from 'utilities/util';

export const TYPES = {
    FETCH_VESSELS: createAsyncActionConstants('FETCH_VESSELS'),
    FETCH_VESSEL_DETAILS: createAsyncActionConstants('FETCH_VESSEL_DETAILS'),
    FETCH_MAWB_DETAILS: createAsyncActionConstants('FETCH_MAWB_DETAILS'),
    FETCH_BL_LIST: createAsyncActionConstants('GET_BL_LIST'),
    FETCH_BL_DETAILS: createAsyncActionConstants('FETCH_BL_DETAILS'),
    EDIT_VESSEL_PENDING: createAsyncActionConstants('EDIT_VESSEL_PENDING'),
    EDIT_VESSEL_ON_CBL_LEVEL: createAsyncActionConstants('EDIT_VESSEL_ON_CBL_LEVEL'),
    SEND_COMMENT_TO_VESSEL: createAsyncActionConstants('SEND_COMMENT_TO_VESSEL'),
    ADD_VESSEL_TO_BL: createAsyncActionConstants('ADD_VESSEL_TO_BL'),
    FETCH_MAWB_LIST: createAsyncActionConstants('FETCH_MAWB_LIST'),
    EDIT_MAWB_DETAILS: createAsyncActionConstants('EDIT_MAWB_DETAILS'),
    FETCH_AIR_REASON_CODES: createAsyncActionConstants('FETCH_AIR_REASON_CODES'),
    OPEN_CBL_DETAILS_MODAL: 'OPEN_CBL_DETAILS_MODAL',
    CLOSE_CBL_DETAILS_MODAL: 'CLOSE_CBL_DETAILS_MODAL',
    FETCH_MILESTONES_LIST: createAsyncActionConstants('FETCH_MILESTONES_LIST'),
    UPDATE_MILESTONES: createAsyncActionConstants('UPDATE_MILESTONES'),

    FETCH_MRN_DETAILS: createAsyncActionConstants('FETCH_MRN_DETAILS'),
    SAVE_MRN_DETAILS: createAsyncActionConstants('SAVE_MRN_DETAILS')
};
