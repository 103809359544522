import React from 'react';
import { formatDate, formatValueIfNullOrUndefined } from 'utilities/util';
import { PropertiesRibbonWrapper } from 'components/properties-ribbon';

interface IEquipmentDetailsTopBarOwnProps {
    equipmentNumber: string;
    transportIdentification: string;
    type: string;
    size: string | number;
    createDate: string;
    lastUpdateDate: string;
    weight: number;
    volume: number;
    quantity: number;
    totalPackages: number;
}

export const EquipmentDetailsTopBar: React.StatelessComponent<IEquipmentDetailsTopBarOwnProps> = (props) => {
    const propertiesRibbonData = {
        labels: [
            {label: 'Equipment number', value: formatValueIfNullOrUndefined(props.equipmentNumber)},
            {label: 'Transport identification', value: formatValueIfNullOrUndefined(props.transportIdentification)},
            {label: 'Type', value: formatValueIfNullOrUndefined(props.type)},
            {label: 'Size', value: formatValueIfNullOrUndefined(props.size)},
            {label: 'Create date', value: formatDate(props.createDate, 'DD-MM-YYYY, h:mm')},
            {label: 'Last update date', value: formatDate(props.lastUpdateDate, 'DD-MM-YYYY, h:mm')},
            {label: 'Weight', value: formatValueIfNullOrUndefined(props.weight)},
            {label: 'Volume', value: formatValueIfNullOrUndefined(props.volume)},
            {label: 'Quantity', value: formatValueIfNullOrUndefined(props.quantity)},
            {label: 'Total packages', value: formatValueIfNullOrUndefined(props.totalPackages)}
        ],
        actions: []
    }

    return <PropertiesRibbonWrapper content={propertiesRibbonData} />;
};
