import React from 'react';
import { ColumnFactory } from 'components/data-table/ColumnFactory';
import { mapStatusToStringForCT } from 'constants/statuses';
import { Accordion } from 'components/accordion';
import { DataTable } from 'components/data-table/DamcoTable';
import { cloneDataWithDisplayNames } from 'utilities/tables';

interface ICarrierBlVesselsTableProps {
    data: any[];
}

export const CarrierBlVesselsTable: React.StatelessComponent<ICarrierBlVesselsTableProps> = (props) => {
    const data = cloneDataWithDisplayNames(props.data).map((obj: any) => ({
        ...obj,
        voyageStatusDisplayName: mapStatusToStringForCT(obj.voyageStatus)
    }));
    
    return(
        <Accordion text="Transshipment">
            <DataTable 
                data={data}
                withCheckboxes={false}
                expandable={false}
                columns={[
                    ColumnFactory.standardColumn('vesselNameDisplayName', 'Vessel name'),
                    ColumnFactory.standardColumn('voyageReferenceDisplayName', 'Voyage'),
                    ColumnFactory.standardColumn('loadingPortDisplayName', 'POL'),
                    ColumnFactory.standardColumn('destinationPortDisplayName', 'POD'),
                    ColumnFactory.standardColumn('etdDisplayName', 'ETD'),
                    ColumnFactory.standardColumn('atdDisplayName', 'ATD'),
                    ColumnFactory.standardColumn('originalETADisplayName', 'Original ETA'),
                    ColumnFactory.standardColumn('currentETADisplayName', 'Current ETA'),
                    ColumnFactory.standardColumn('ataDisplayName', 'ATA'),
                    ColumnFactory.standardColumn('voyageStatusDisplayName', 'Status'),
                ]}
                tableName="MBLTransshipmentTable"
                reference={null as any}
            />
        </Accordion>
    )
};