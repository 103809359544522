import React, { FunctionComponent, useState, useRef } from 'react';
import { ColumnFactory } from 'components/data-table/ColumnFactory';
import { IOfficePrefix } from 'reducers/fact/models';
import { connect } from 'react-redux';
import { DataTable } from 'components/data-table/DamcoTable';
import { ThunkDispatch } from 'redux-thunk';
import { Action } from 'redux';
import { addGOHTranslation, getGOHTranslations, deleteGOHTranslation, editGOHTranslation } from 'actions/settings';
import { IGOHTranslation } from 'reducers/settings/models';
import { GOHModal } from '../components/GOHModal';

interface IGOHTableOwnProps {
    data: IGOHTranslation[];
    pageCount: number;
    fetchPrefixes: any;
}

interface IMapDispatchToProps {
    addGOHTranslation: (body: IOfficePrefix) => Promise<void>;
    getGOHTranslations: () => Promise<void>;
    editGOHTranslation: (body: any) => Promise<void>;
    deleteGOHTranslation: (body: any) => Promise<void>;
}

interface IGOHTableProps extends IGOHTableOwnProps, IMapDispatchToProps {}

const _GOHTable: FunctionComponent<IGOHTableProps> = (props) => {
    const [isAddPrefixModalOpened, setAddPrefixModalOpened] = useState<boolean>(false);
    const [isEditPrefixModalOpened, setEditPrefixModalOpened] = useState<boolean>(false);

    const dataTable = useRef<any>();

    const toggleAddPrefixModal = (): void => setAddPrefixModalOpened(!isAddPrefixModalOpened);

    const toggleEditPrefixModal = (): void => setEditPrefixModalOpened(!isEditPrefixModalOpened);

    const addTranslation = async (data: any): Promise<void> => {
        await props.addGOHTranslation(data);
        toggleAddPrefixModal();
    };

    const updatePrefix = async (fields: IOfficePrefix): Promise<void> => {
        await props.editGOHTranslation(fields);
        toggleEditPrefixModal();
        await props.getGOHTranslations();
    };

    const deletePrefix = async (): Promise<void> => {
        await props.deleteGOHTranslation(getId());
        await dataTable.current.hideContextRibbon();
        await props.getGOHTranslations();
    };

    const getSelectedItem = () => {
        const selectedItems = dataTable.current ? dataTable.current.getSelectedItems() : [];
        if(selectedItems[0]) {
            return selectedItems[0];
        }
        return;
    };

    const getId = (): number | undefined => {
        const selectedItems = dataTable.current ? dataTable.current.getSelectedItems() : [];
        if(selectedItems[0]) {
            return selectedItems[0].id;
        }
        return;
    }

    return (
        <React.Fragment>
            <DataTable
                data={props.data}
                withCheckboxes={true}
                expandable={false}
                selectVariant="single"
                columns={[
                    ColumnFactory.standardColumn('gohFlag', 'GOH flag'),
                    ColumnFactory.standardColumn('resultContainerType', 'Result container type'),
                    ColumnFactory.standardColumn('subtype', 'Subtype'),
                    ColumnFactory.standardColumn('type', 'Type')
                ]}
                actions={{
                    primaryActionIsMoreBtn: false,
                    primaryActionLabel: 'Add translation',
                    primaryAction: toggleAddPrefixModal,
                    parentContextualActions: [
                        { isMoreBtn: false, label: 'Edit translation', action: toggleEditPrefixModal },
                        { isMoreBtn: false, label: 'Delete translation', action: deletePrefix },
                    ]
                }}
                reference={dataTable}
                tableName="SettingsFACTGOHTable"
                manualPagination={true}
                fetchData={props.fetchPrefixes}
                pageCount={props.pageCount}
                showPagination={true}
            />
            {isAddPrefixModalOpened && <GOHModal
                title="Add translation"
                closeModal={toggleAddPrefixModal}
                saveFunction={addTranslation}
            />}
            {isEditPrefixModalOpened && <GOHModal
                title="Edit translation"
                closeModal={toggleEditPrefixModal}
                saveFunction={updatePrefix}
                initialValues={getSelectedItem() || {}}
            />}
        </React.Fragment>
    );
}

const mapDispatchToProps = (dispatch: ThunkDispatch<any,any, Action>): IMapDispatchToProps => ({
    addGOHTranslation: (body: IOfficePrefix) => dispatch(addGOHTranslation(body)),
    getGOHTranslations: () => dispatch(getGOHTranslations(1, 10)),
    editGOHTranslation: (data: any) => dispatch(editGOHTranslation(data)),
    deleteGOHTranslation: (id: number) => dispatch(deleteGOHTranslation(id)),
})

export const GOHTable = connect<undefined, IMapDispatchToProps, IGOHTableOwnProps>(undefined, mapDispatchToProps)(_GOHTable);
