import React from 'react';
import { ModalScreen } from 'components/modal-screen';
import { ModalType } from 'components/modal-screen/ModalType';

interface IVesselDetailsPopup {
    visible: boolean;
    description: string;
    closeModal: () => void | void;
}

export const DescriptionModal: React.StatelessComponent<IVesselDetailsPopup> = (props) => {
    return (
        <ModalScreen
            title="Update vessel details"
            modalType={ModalType.s()}
            primaryButtonTitle="Close"
            primaryButtonFunc={props.closeModal}
            hideSecondaryButton={true}
            visible={props.visible}
            closeModal={props.closeModal}
        >
            <section className="page-container">
                <p>{props.description}</p>
            </section>
        </ModalScreen>
    );
}
