import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Page } from 'components/page-container';
import { ContractsTable } from './ContractsTable';
import { ThunkDispatch } from 'redux-thunk';
import { getDDContracts } from 'actions/demurrageAndDetention';
import { Action } from 'redux';
import { IStore } from 'reducers/index';
import { IDemurrageAndDetentionContracts } from 'reducers/demurrage-and-detention';

interface IMapStateToProps {
    isLoading: boolean;
    contracts: IDemurrageAndDetentionContracts;
}
interface IMapDispatchToProps {
    getDDContracts: (pageNumber: number, pageSize: number) => Promise<void>;
}

interface IContractsProps extends IMapStateToProps, IMapDispatchToProps {}

interface IContractsState {
    page: number;
    pageSize: number;
}

class _Contracts extends Component<IContractsProps, IContractsState> {
    public state: IContractsState = {
        page: 1,
        pageSize: 10
    };

    public render() {
        return(
            <Page>
                <ContractsTable
                    data={this.props.contracts.result}
                    isLoading={this.props.isLoading}
                    pageCount={Math.ceil(this.props.contracts.totalCount / this.props.contracts.pageSize)}
                    fetchContracts={this.fetchContracts}
                />
                
            </Page>
        );
    }

    private fetchContracts = (tableData: any) => {
        this.setState({
            page: tableData.page + 1,
            pageSize: tableData.pageSize
        }, () => {
            this.props.getDDContracts(this.state.page, this.state.pageSize);
        });
    };
}

const mapStateToProps = (state: IStore): IMapStateToProps => ({
    isLoading: state.demurrageAndDetention.isGetContractsPending,
    contracts: state.demurrageAndDetention.contracts,
});

const mapDispatchToProps = (dispatch: ThunkDispatch<any, any, Action>): IMapDispatchToProps => ({
    getDDContracts: (pageNumber: number, pageSize: number) => dispatch(getDDContracts(pageNumber, pageSize)),
});

export const Contracts = connect<IMapStateToProps, IMapDispatchToProps, {}, IStore>(mapStateToProps, mapDispatchToProps)(_Contracts);