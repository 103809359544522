import React, { Component } from 'react';
import { LoadingAnimation } from 'components/loading';

interface ITimeLineInputProps {
    isLoading: boolean;
    onSubmit: (body: string) => Promise<void>;
}

interface ITimeLineInputState {
    value: string;
}

export class TimeLineInput extends Component<ITimeLineInputProps, ITimeLineInputState> {
    public state: ITimeLineInputState = {
        value: ''
    };

    public render() {
        return(
            <>
                {this.props.isLoading && <LoadingAnimation />}
                <div className="time-line-widget">
                    <ul className="timeline-list">
                        <li className="timeline-input">
                            <div className="image-block inline">
                                <img alt="" src="https://cdn.damco.com/images/icons/DefaultProfileImg32.png" />
                            </div>
                            <div className="input-block inline">
                                <div className="input-btn-wrapper">
                                    <input
                                        style={{zIndex: 0}}
                                        type="text"
                                        className="form-control"
                                        placeholder="Add new comment"
                                        onChange={(e: any) => this.setInputValue(e)}
                                        value={this.state.value}
                                    />
                                    <button
                                        type="button"
                                        className="select-btn"
                                        onClick={this.submit}
                                        style={{zIndex: 0}}
                                    >
                                        <span className="fa fa-paper-plane" />
                                    </button>
                                </div>
                            </div>
                        </li>
                    </ul>
                </div>
            </>
        );
    };

    private submit = async () => {
        if (this.state.value.length > 0) {
            await this.props.onSubmit(this.state.value);
            this.setState({ value: '' });
        }
    };

    private setInputValue = (e: any) => this.setState({ value: e.target.value });
};
