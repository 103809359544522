import { TYPES } from 'action-types/export';

interface IAction {
    readonly type: string;
    readonly res: any;
    readonly error: any;
}

export interface IExportState {
    readonly isExportEquipmentPlanningLoading: boolean;
    readonly exportPlanningError: string;
    readonly isExportDelivAndReturnEquipLoading: boolean;
    readonly exportDeliveryAndReturnEquipmentError: string;
    readonly isExportDelivAndReturnLocLoading: boolean;
    readonly exportDeliveryAndReturnLocationError: string;
    readonly isExportDemurrageLoading: boolean;
    readonly exportDemurrageError: string;
    readonly isExportDetentionLoading: boolean;
    readonly exportDetentionError: string;
    readonly isExportContainerTrackingLoading: boolean;
    readonly exportContainerTrackingError: string;
    readonly isExportHblReleaseLoading: boolean;
    readonly exportHblError: string;
    readonly isExportCblReleaseLoading: boolean;
    readonly exportCblError: string;
    readonly isExportCustomsLoading: boolean;
    readonly exportCustomsError: string;
}

const defaultState: IExportState = {
    isExportEquipmentPlanningLoading: false,
    exportPlanningError: '',
    isExportDelivAndReturnEquipLoading: false,
    exportDeliveryAndReturnEquipmentError: '',
    isExportDelivAndReturnLocLoading: false,
    exportDeliveryAndReturnLocationError: '',
    isExportDemurrageLoading: false,
    exportDemurrageError: '',
    isExportDetentionLoading: false,
    exportDetentionError: '',
    isExportContainerTrackingLoading: false,
    exportContainerTrackingError: '',
    isExportHblReleaseLoading: false,
    exportHblError: '',
    isExportCblReleaseLoading: false,
    exportCblError: '',
    isExportCustomsLoading: false,
    exportCustomsError: '',
};

export const reducer = (state: IExportState = defaultState, action: IAction): IExportState => {
    switch (action.type) {
        case TYPES.EXPORT_EQUIPMENT_PLANNING_TO_EXCEL.PENDING:
            return {
                ...state,
                isExportEquipmentPlanningLoading: true,
                exportPlanningError: '',
            };
        case TYPES.EXPORT_EQUIPMENT_PLANNING_TO_EXCEL.FULFILLED:
            return {
                ...state,
                isExportEquipmentPlanningLoading: false,
            };
        case TYPES.EXPORT_EQUIPMENT_PLANNING_TO_EXCEL.REJECTED:
            return {
                ...state,
                isExportEquipmentPlanningLoading: false,
                exportPlanningError: action.error,
            };
        case TYPES.EXPORT_EQUIPMENT_PLANNING_TO_EXCEL.RESET_ERROR:
            return {
                ...state,
                exportPlanningError: '',
            };
        case TYPES.EXPORT_EQUIP_DELIV_AND_RETURN_TO_EXCEL.PENDING:
            return {
                ...state,
                isExportDelivAndReturnEquipLoading: true,
                exportDeliveryAndReturnEquipmentError: '',
            };
        case TYPES.EXPORT_EQUIP_DELIV_AND_RETURN_TO_EXCEL.FULFILLED:
            return {
                ...state,
                isExportDelivAndReturnEquipLoading: false,
            };
        case TYPES.EXPORT_EQUIP_DELIV_AND_RETURN_TO_EXCEL.REJECTED:
            return {
                ...state,
                isExportDelivAndReturnEquipLoading: false,
                exportDeliveryAndReturnEquipmentError: action.error,
            };
        case TYPES.EXPORT_EQUIP_DELIV_AND_RETURN_TO_EXCEL.RESET_ERROR:
            return {
                ...state,
                exportDeliveryAndReturnEquipmentError: '',
            };
        case TYPES.EXPORT_EQUIP_DELIV_AND_RETURN_LOC_TO_EXCEL.PENDING:
            return {
                ...state,
                isExportDelivAndReturnLocLoading: true,
                exportDeliveryAndReturnLocationError: '',
            };
        case TYPES.EXPORT_EQUIP_DELIV_AND_RETURN_LOC_TO_EXCEL.FULFILLED:
            return {
                ...state,
                isExportDelivAndReturnLocLoading: false,
            };
        case TYPES.EXPORT_EQUIP_DELIV_AND_RETURN_LOC_TO_EXCEL.REJECTED:
            return {
                ...state,
                isExportDelivAndReturnLocLoading: false,
                exportDeliveryAndReturnLocationError: action.error,
            };
        case TYPES.EXPORT_EQUIP_DELIV_AND_RETURN_LOC_TO_EXCEL.REJECTED:
            return {
                ...state,
                exportDeliveryAndReturnLocationError: '',
            };
        case TYPES.EXPORT_DEMURRAGE_TO_EXCEL.PENDING:
            return {
                ...state,
                isExportDemurrageLoading: true,
                exportDemurrageError: '',
            };
        case TYPES.EXPORT_DEMURRAGE_TO_EXCEL.FULFILLED:
            return {
                ...state,
                isExportDemurrageLoading: false,
            };
        case TYPES.EXPORT_DEMURRAGE_TO_EXCEL.REJECTED:
            return {
                ...state,
                isExportDemurrageLoading: false,
                exportDemurrageError: action.error,
            };
        case TYPES.EXPORT_DEMURRAGE_TO_EXCEL.RESET_ERROR:
            return {
                ...state,
                exportDemurrageError: '',
            };
        case TYPES.EXPORT_DETENTION_TO_EXCEL.PENDING:
            return {
                ...state,
                isExportDetentionLoading: true,
                exportDetentionError: '',
            };
        case TYPES.EXPORT_DETENTION_TO_EXCEL.FULFILLED:
            return {
                ...state,
                isExportDetentionLoading: false,
            };
        case TYPES.EXPORT_DETENTION_TO_EXCEL.REJECTED:
            return {
                ...state,
                isExportDetentionLoading: false,
                exportDetentionError: action.error,
            };
        case TYPES.EXPORT_DETENTION_TO_EXCEL.RESET_ERROR:
            return {
                ...state,
                exportDetentionError: '',
            };
        case TYPES.EXPORT_CONTAINER_TRACKING.PENDING:
            return {
                ...state,
                isExportContainerTrackingLoading: true,
                exportContainerTrackingError: ''
            };
        case TYPES.EXPORT_CONTAINER_TRACKING.FULFILLED:
            return {
                ...state,
                isExportContainerTrackingLoading: false,
            };
        case TYPES.EXPORT_CONTAINER_TRACKING.REJECTED:
            return {
                ...state,
                isExportContainerTrackingLoading: false,
                exportContainerTrackingError: action.error,
            };
        case TYPES.EXPORT_CONTAINER_TRACKING.RESET_ERROR:
            return {
                ...state,
                exportContainerTrackingError: '',
            };
        case TYPES.EXPORT_HBL_RELEASE.PENDING:
            return {
                ...state,
                isExportHblReleaseLoading: true,
                exportHblError: '',
            };
        case TYPES.EXPORT_HBL_RELEASE.FULFILLED:
            return {
                ...state,
                isExportHblReleaseLoading: false,
            };
        case TYPES.EXPORT_HBL_RELEASE.REJECTED:
            return {
                ...state,
                isExportHblReleaseLoading: false,
                exportHblError: action.error,
            };
        case TYPES.EXPORT_HBL_RELEASE.RESET_ERROR:
            return {
                ...state,
                exportHblError: '',
            };
        case TYPES.EXPORT_CBL_RELEASE.PENDING:
            return {
                ...state,
                isExportCblReleaseLoading: true,
                exportCblError: '',
            };
        case TYPES.EXPORT_CBL_RELEASE.FULFILLED:
            return {
                ...state,
                isExportCblReleaseLoading: false,
            };
        case TYPES.EXPORT_CBL_RELEASE.REJECTED:
            return {
                ...state,
                isExportCblReleaseLoading: false,
                exportCblError: action.error,
            };
        case TYPES.EXPORT_CBL_RELEASE.RESET_ERROR:
            return {
                ...state,
                exportCblError: '',
            };
        case TYPES.EXPORT_CUSTOMS.PENDING:
            return {
                ...state,
                isExportCustomsLoading: true,
                exportCustomsError: '',
            };
        case TYPES.EXPORT_CUSTOMS.FULFILLED:
            return {
                ...state,
                isExportCustomsLoading: false,
            };
        case TYPES.EXPORT_CUSTOMS.REJECTED:
            return {
                ...state,
                isExportCustomsLoading: false,
                exportCustomsError: action.error,
            };
        case TYPES.EXPORT_CUSTOMS.RESET_ERROR:
            return {
                ...state,
                exportCustomsError: '',
            }
        default:
            return state;
    }
};
