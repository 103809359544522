import React from 'react';
import styled from 'styled-components';

const SDiv = styled.div`
    margin-bottom: 15px;
`;

const dialogType = (type?: number) => {
    switch (type) {
        case 200:
            return {
                type: 'success',
                icon: 'fa fa-check-circle fa-3x'
            };
        case 404:
        case 400:
        case 500:
            return {
                type: 'failed',
                icon: 'fa fa-times-circle fa-3x'
            };
        default:
            return {
                type: 'info',
                icon: 'fa fa-info-circle fa-3x'
            }
    }
}


interface IDialogProps {
    isVisible: boolean;
    dialogType?: number ;
    closeDialog(): void;
    action(): void;
}

export class DialogWithDownload extends React.Component<IDialogProps> {

    public render() {
        const dialog = dialogType(this.props.dialogType);
        const renderDialog = () => {
            if(this.props.isVisible) {
                return(
                    <SDiv className={`message ${dialog.type}`}>
                        <i aria-hidden="true" className={dialog.icon} />
                            <span id="message_success">Excel file has not been imported. Click <a onClick={this.onClick}>HERE</a> for more details.</span>
                        <i id="close_failed" className="fas fa-times" onClick={this.props.closeDialog} />
                    </SDiv>
                )
            }
            return <div />
        };
        return renderDialog();
    }

    private onClick = (e: any) => {
        e.preventDefault();
        this.props.action();
    }
};

