import { ToggleArrow } from 'components/toggle-arrow';
import React from 'react';
import styled from 'styled-components';
import { isFunction, isUndefined } from 'lodash';

const SChildren = styled.div`
    display: ${(props: { isVisible: boolean; }) => props.isVisible ? 'block' : 'none'};
`;

interface IAccordionProps {
    text: string;
    collapse?: boolean;
    children: React.ReactNode | React.ReactNodeArray;
    onClick?: (e: React.MouseEvent<HTMLDivElement>) => void;
}

interface IAccordionState {
    isExpanded: boolean;
}


export class Accordion extends React.Component<IAccordionProps, IAccordionState> {
    public state = {
        isExpanded: true
    }

    public componentDidMount() {
        if (!isUndefined(this.props.collapse)) {
            if(!this.props.collapse) {
                if(this.props.collapse) {
                    this.setState({ isExpanded: true });
                }
            } else {
                this.setState({ isExpanded: false });
            }
        }
    }

    public componentDidUpdate(prevProps: IAccordionProps) {
        if(prevProps.collapse !== this.props.collapse) {
            this.setState((state: IAccordionState) => ({ isExpanded: !state.isExpanded }));
        }
    }

    public render() {
        return (
            <div className="grid-wrapper">
                <div className="accordion-title" onClick={this.handleClick}>
                    <h3 className="title-blue-underline" onClick={this.onToggle}>{this.props.text}</h3>
                    <ToggleArrow
                        expandedText=""
                        nonExpandedText=""
                        isExpanded={this.state.isExpanded ? true : false}
                        onToggle={this.onToggle}
                    />
                </div>
                <SChildren isVisible={this.state.isExpanded}>
                    {this.props.children}
                </SChildren>
            </div>
        )
    }

    // backwards compatibility with uncontrolled accordions
    // TODO: refactor this, two click handlers are not needed I guess
    private handleClick = (e: React.MouseEvent<HTMLDivElement>) => {
        if (isFunction(this.props.onClick)) {
            this.props.onClick(e);
        }
    }

    private onToggle = () => {
        if (!isFunction(this.props.onClick)) {
            this.setState((state: IAccordionState) => ({ isExpanded: !state.isExpanded }));
        }
    }
}
