import React from 'react';
import { BaseFieldProps, Field } from 'redux-form'
import { IInput, Input } from 'components/input/index';

class CustomPropsField extends Field<any> {}

interface IFormInput extends IInput, BaseFieldProps {
    validate?: any;
    id?: string;
    input?: React.InputHTMLAttributes<HTMLInputElement>;
}

export const FormInput: React.FunctionComponent<IFormInput> = (props) => (
    <CustomPropsField
        component={Input}
        validate={props.validate}
        {...props}
    />
);
