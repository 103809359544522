import React, { FunctionComponent, useEffect } from 'react';
import { connect } from 'react-redux';
import { ThunkDispatch } from 'redux-thunk';
import { Action } from 'redux';
import { fetchDeliveryPlanningDerigSettings } from 'actions/settings';
import { IStore } from 'reducers/index';
import { ISettingsModel } from 'reducers/settings/models';
import { DerigTable } from 'modules/settings/delivery-planning/containers/DerigTable';
import API from 'constants/api';
import { ErrorDialog } from 'components/error-dialog';


interface IMapStateToProps {
    deliveryPlanningDerigSettings: ISettingsModel[];
    fetchDeliveryPlanningDeRigSettingsPending: boolean;
}

interface IDispatch {
    fetchDeliveryPlanningDerigSettings: () => void;
}

interface IDerigConfiguration extends IDispatch, IMapStateToProps {
}

const _DerigConfiguration: FunctionComponent<IDerigConfiguration> = (props) => {
    useEffect(() => {
        props.fetchDeliveryPlanningDerigSettings();
    }, []);

    return (
        <React.Fragment>
            <ErrorDialog endpoint={API.Settings.FetchDeliveryStatusDeRigConfigurations} />
            <ErrorDialog endpoint={API.Settings.CreateDeliveryPlanningDeRigConfiguration} />
            <ErrorDialog endpoint={API.Settings.UpdateDeliveryPlanningDeRigConfiguration} />
            <ErrorDialog endpoint={API.Settings.DeleteDeliveryPlanningDeRigConfiguration} />
            <DerigTable
                data={props.deliveryPlanningDerigSettings}
                isLoading={props.fetchDeliveryPlanningDeRigSettingsPending}
            />
        </React.Fragment>
    )
}

const mapStateToProps = (state: IStore): IMapStateToProps => ({
    deliveryPlanningDerigSettings: state.settings.deliveryPlanningDerigSettings,
    fetchDeliveryPlanningDeRigSettingsPending: state.settings.fetchDeliveryPlanningDeRigSettingsPending,
});

const mapDispatchToProps = (dispatch: ThunkDispatch<any, any, Action>): IDispatch => ({
    fetchDeliveryPlanningDerigSettings: () => dispatch(fetchDeliveryPlanningDerigSettings()),
});

export const DerigConfiguration = connect<IMapStateToProps, IDispatch, IStore>(mapStateToProps, mapDispatchToProps)(_DerigConfiguration);