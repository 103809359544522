import React from 'react';
import { FormSelect } from 'components/select/FormSelect';

interface IBooleanFormSelect {
    label: string;
    name: string;
}

export const BooleanFormSelect: React.SFC<IBooleanFormSelect> = (props: IBooleanFormSelect) => (
    <FormSelect
        options={[
            {
                value: '',
                optionText: 'Select value...'
            },
            {
                value: true,
                optionText: 'Yes'
            },
            {
                value: false,
                optionText: 'No'
            }
        ]}
        {...props}
    />
);
