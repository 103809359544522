import React from 'react';
import { StatusRow } from 'components/status-row';
import { OneStatus } from 'components/status-row/OneStatus';
import { QuarterGrid } from 'components/grid/quarter-grid';
import { formatValueIfNullOrUndefined } from 'utilities/util';

interface IServicesRowProps {
    serviceAtOrigin: string;
    serivceAtDestination: string;
}

export const ServicesRow: React.StatelessComponent<IServicesRowProps> = (props) => {
    return (
        <StatusRow title="Services">
            <QuarterGrid>
                <OneStatus label="Service at origin">
                    {formatValueIfNullOrUndefined(props.serviceAtOrigin)}
                </OneStatus>
                <OneStatus label="Serivce at destination">
                    {formatValueIfNullOrUndefined(props.serivceAtDestination)}
                </OneStatus>
            </QuarterGrid>
        </StatusRow>
    );
};
