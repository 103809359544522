import React from 'react';
import { StatusRow } from 'components/status-row';
import { OneStatus } from 'components/status-row/OneStatus';
import { QuarterGrid } from 'components/grid/quarter-grid';

interface IDocumentsRow {
    documentsCMRRequired: boolean;
    documentsPARNRequired: boolean;
}

export const DocumentsRow: React.SFC<IDocumentsRow> = (props: IDocumentsRow) => {
    return (
        <StatusRow title="Documents">
            <QuarterGrid>
                <OneStatus label="PARN Received">
                    {props.documentsPARNRequired ? 'Y' : 'N'}
                </OneStatus>
                <OneStatus label="CMR required">
                    {props.documentsCMRRequired ? 'Y' : 'N'}
                </OneStatus>
            </QuarterGrid>
        </StatusRow>
    );
};