import React, { Component } from 'react';
import { connect } from 'react-redux';
import { ModalScreen } from 'components/modal-screen';
import { ModalType } from 'components/modal-screen/ModalType';
import { ThunkDispatch } from 'redux-thunk';
import { Action, compose } from 'redux';
import { INotifyCustomerAction, notifyCustomer, getReasonCodes, resetNotifyCustomerError } from 'actions/modals';
import { IStore } from 'reducers/index';
import { LoadingAnimation } from 'components/loading';
import { CTReasonCodes, ReasonCommentMode } from 'constants/reasonCodes';
import { InjectedFormProps, reduxForm, reset, formValueSelector } from 'redux-form';
import { FormSelect } from 'components/select/FormSelect';
import { IReasonCode } from 'reducers/settings/models';
import { Dialog } from 'components/dialog';
import { SingleGrid } from 'components/grid/single-grid';
import { FormTextArea } from 'components/textarea/FormTextArea';
import { Capability } from 'components/timeline';

interface IMapStateToProps {
    isLoading: boolean;
    reasonCodes: IReasonCode[];
    isGetReasonCodesLoading: boolean;
    notifyCustomerError: string,
    commentMode: ReasonCommentMode;
    comment: string;
    reasonCode: string | undefined;
}

interface IDispatch {
    notifyCustomer: (data: INotifyCustomerAction, isAir: boolean) => Promise<void>;
    getReasonCodes: (group?: CTReasonCodes) => Promise<void>;
    resetNotifyCustomerError: () => void;
    resetForm: (formName: string) => void;
}

interface INotifyCustomerModalOwnProps {
    visible: boolean;
    blId: number[];
    group?: CTReasonCodes;
    closeModal: () => void;
    refreshFunction?: (id: number[]) => Promise<void>;
    isAir: boolean;
}

interface INotifyCustomerModal extends INotifyCustomerModalOwnProps, IDispatch, IMapStateToProps, InjectedFormProps<{}, INotifyCustomerModalOwnProps> {}

class _NotifyCustomerModal extends Component<INotifyCustomerModal> {
    public static defaultProps = {
        group: CTReasonCodes.Ocean_LateShipment,
    };

    public componentDidMount() {
        this.props.resetForm(FORM_NAME);
        this.props.resetNotifyCustomerError();
        this.props.getReasonCodes(this.props.group);
    }

    public componentDidUpdate(prevProps: INotifyCustomerModal) {
        if (this.props.visible && !prevProps.visible) {
            this.props.resetForm(FORM_NAME);
            this.props.resetNotifyCustomerError();
        }
    }

    public render() {
        if (this.props.isLoading || this.props.isGetReasonCodesLoading) {
            return <LoadingAnimation />;
        }

        const mapReasonCodes = this.props.reasonCodes.map((row: IReasonCode) => ({
            optionText: row.reasonCode,
            value: row.reasonCode
        }));
        const withEmptyOption = [{ optionText: 'Select value...', value: ''}, ...mapReasonCodes];
        const isSaveDisabled = !this.props.reasonCode || this.props.commentMode === ReasonCommentMode.Required
            ? !this.props.comment || this.props.comment.length === 0
            : false;

        const numberOfEditedItems = this.props.blId.length;
        const title = `Notify customer ${numberOfEditedItems ? `- ${numberOfEditedItems} ${numberOfEditedItems > 1 ? 'CB/Ls' : 'CB/L'}` : ''}`;

        return (
            <form onSubmit={this.props.handleSubmit(this.onSubmit)}>
                <ModalScreen
                    title={title}
                    visible={this.props.visible}
                    modalType={ModalType.s()}
                    closeModal={this.props.closeModal}
                    primaryButtonIsDisabled={isSaveDisabled}
                    primaryButtonTitle="Send"
                    primaryButtonType="submit"
                    id="notify-customer-modal"
                    secondaryButtonTitle="Cancel"
                    secondaryButtonFunc={this.props.closeModal}
                >
                    <Dialog
                        message={this.props.notifyCustomerError}
                        isVisible={!!this.props.notifyCustomerError}
                        dialogType={500}
                        closeDialog={this.props.resetNotifyCustomerError}
                    />

                    <div>Select a reason code to send to customer.</div>
                    <SingleGrid>
                        <FormSelect
                            name="ReasonCode"
                            label="ReasonCode"
                            options={withEmptyOption}
                        />
                    </SingleGrid>
                    {this.props.commentMode !== ReasonCommentMode.Disabled && (
                        <SingleGrid>
                            <FormTextArea
                                label="Comment"
                                name="Comment"
                            />
                        </SingleGrid>
                    )}
                </ModalScreen>
            </form>
        );
    }

    private onSubmit = async (config: any) => {
        if (this.props.blId === null || this.props.blId === undefined) {
            return this.props.closeModal();
        }

        if (config.ReasonCode === null || config.ReasonCode === undefined) {
            return this.props.closeModal();
        }

        const getBlId = () => {
            if (typeof this.props.blId === 'object') {
                return [...this.props.blId];
            } else {
                return [this.props.blId];
            }
        };
        const data = {
            ReasonCode: config.ReasonCode,
            BlIds: getBlId(),
            ReasonCodeComment: undefined as undefined | string
        };

        if (this.props.commentMode !== ReasonCommentMode.Disabled && this.props.comment) {
            data.ReasonCodeComment = this.props.comment;
        }

        await this.props.notifyCustomer(data as any, this.props.isAir);
        this.props.resetForm(FORM_NAME);
        this.props.closeModal();

        if (this.props.refreshFunction) {
            await this.props.refreshFunction(this.props.blId);
        }
    }
}

const mapStateToProps = (state: IStore): IMapStateToProps => {
    const selectedReasonCode = formValueSelector(FORM_NAME)(state, 'ReasonCode');
    const comment = formValueSelector(FORM_NAME)(state, 'Comment');
    const foundReasonCode = state.modals.codes.find(code => code.reasonCode === selectedReasonCode);
    const commentEnabled = (selectedReasonCode && foundReasonCode && foundReasonCode.commentMode !== null) ? foundReasonCode.commentMode : ReasonCommentMode.Disabled;

    return {
        commentMode: commentEnabled,
        comment,
        reasonCode: selectedReasonCode,
        isLoading: state.modals.isNotifyCustomerPending,
        reasonCodes: state.modals.codes,
        isGetReasonCodesLoading: state.modals.isGetReasonCodesLoading,
        notifyCustomerError: state.modals.notifyCustomerError,
    }
};

const mapDispatchToProps = (dispatch: ThunkDispatch<any, any, Action>): IDispatch => ({
    notifyCustomer: (data: INotifyCustomerAction, isAir: boolean) => dispatch(notifyCustomer(data, isAir)),
    getReasonCodes: (group?: CTReasonCodes) => dispatch(getReasonCodes(Capability.ContainerTracking, true, group)),
    resetNotifyCustomerError: () => dispatch(resetNotifyCustomerError()),
    resetForm: (formName: string) => dispatch(reset(formName)),
});

const FORM_NAME = 'NOTIFY_CUSTOMER_MODAL';

export const NotifyCustomerModal = compose(
    connect<IMapStateToProps, IDispatch, INotifyCustomerModalOwnProps, IStore>(mapStateToProps, mapDispatchToProps),
    reduxForm<{}, INotifyCustomerModalOwnProps>({
        form: FORM_NAME,
        enableReinitialize: true
    })
)(_NotifyCustomerModal);
