import React, { FunctionComponent, useRef, useState } from 'react';
import { DataTable } from 'components/data-table/DamcoTable';
import { ColumnFactory } from 'components/data-table/ColumnFactory';
import { BUSINNESS_FUNCTIONS } from 'constants/roles';
// tslint:disable-next-line: no-submodule-imports
import { withPermissions, IWithPermissions } from 'src/HOC/withPermissions';
import { cloneDataWithDisplayNames } from 'utilities/tables';
import { IEsso } from 'reducers/fact/models';
import { downloadFile } from 'utilities/download';
import { DQReportModal } from './DQModal';
import { connect } from 'react-redux';
import { generateDqIssuesReport } from 'actions/fact';
import { ThunkDispatch } from 'redux-thunk';
import { Action } from 'redux';
import { IStore } from 'reducers/index';

interface IEquipmentsTableOwnProps {
    data: IEsso[];
    pageCount: number;
    fetchEssos: any;
}


interface IMapStateToStore {
    errors: any[];
}

interface IMapDispatchToProps {
    generateDqIssuesReport: (data: any) => Promise<void>;
}

interface IEssoTableProps extends IMapStateToStore, IMapDispatchToProps, IWithPermissions, IEquipmentsTableOwnProps {}

const _EssoTable: FunctionComponent<IEssoTableProps> = (props) => {
    const [dqReportModalIsOpen, setDqReportModal] = useState<boolean>(false);
    const dataTable = useRef<any>();
    
    const data = cloneDataWithDisplayNames(props.data).map(esso => ({
        ...esso,
        id: esso.essoId,
        isSentDisplayName: esso.isSent ? 'Y' : 'N',
        originalDocumentNumberDisplayName: [esso.document.originalDocumentNumber, `/exception-management/fact/documents/${esso.document.documentId}`],
        documentNumberDisplayName: esso.document.documentNumber,
        documentTypeDisplayName: esso.document.documentType,
        isCanceledDisplayName: esso.document.isCanceled ? 'Y' : 'N',
        createDateDisplayName: esso.document.createDate,
        updateDateDisplayName: esso.document.updateDate,
        financialChangeIndicatorDisplayName: esso.financialChangeIndicator ? 'Y' : 'N',
        reasonDisplayName: esso.reasonDisplayName ? esso.reasonDisplayName : '-'
    }));

    const toggleDqReportModal = () => setDqReportModal(!dqReportModalIsOpen);

    const generateDqReport = async (fields: any): Promise<void> => {
        const res = await props.generateDqIssuesReport(fields);
        downloadFile(res);
        toggleDqReportModal();
    }

    return (
        <React.Fragment>
            {dqReportModalIsOpen && <DQReportModal
                errors={props.errors}
                visible={dqReportModalIsOpen}
                closeModal={toggleDqReportModal}
                generateReport={generateDqReport}
            />}
            <DataTable
                data={data}
                showColumnOptions={true}
                actions={{
                    primaryActionIsMoreBtn: false,
                    primaryActionLabel: 'Generate DQ issues report',
                    primaryAction: toggleDqReportModal,
                }}
                columns={[
                    ColumnFactory.standardColumn('numberDisplayName', 'ESSO number'),
                    ColumnFactory.standardColumn('documentNumberDisplayName', 'Document number'),
                    ColumnFactory.linkedColumn('originalDocumentNumberDisplayName', 'Original document number'),
                    ColumnFactory.standardColumn('messageTypeDisplayName', 'Message type'),
                    ColumnFactory.standardColumn('documentTypeDisplayName', 'Message type'),
                    ColumnFactory.dateColumn('generationDateDisplayName', 'Generation date'),
                    ColumnFactory.dateColumn('createDateDisplayName', 'Document create date'),
                    ColumnFactory.dateColumn('updateDateDisplayName', 'Document update date'),
                    ColumnFactory.standardColumn('isSentDisplayName', 'Document is sent'),
                    ColumnFactory.standardColumn('isCanceledDisplayName', 'Document is canceled'),
                    ColumnFactory.standardColumn('changedFinancialFieldsDisplayName', 'Changed financial fields'),
                    ColumnFactory.standardColumn('financialChangeIndicatorDisplayName', 'Financial change indicator'),
                    ColumnFactory.standardColumn('brainResponseStatusCodeDisplayName', 'Status'),
                    ColumnFactory.standardColumn('reasonDisplayName', 'Reason')
                ]}
                expandable={false}
                withCheckboxes={false}
                tableName="FactEssoList"
                reference={dataTable}
                pageCount={props.pageCount}
                manualPagination={true}
                fetchData={props.fetchEssos}
            />
        </React.Fragment>
    );
}

const mapStateToProps = (state: IStore): IMapStateToStore => ({
    errors: state.fact.dqIssuesReportErrors
});

const mapDispatchToProps = (dispatch: ThunkDispatch<any, any, Action>): IMapDispatchToProps => ({
    generateDqIssuesReport: (data: any) => dispatch(generateDqIssuesReport(data))
});

const __EssoTable = connect(mapStateToProps, mapDispatchToProps)(_EssoTable)

const ROLES: BUSINNESS_FUNCTIONS[] = [];

export const EssoTable = withPermissions(__EssoTable, ROLES) as any;
