import { TYPES } from 'action-types/vessel-schedule';
import { IVesselSchedule } from './models';
import { cloneDataWithDisplayNames } from 'utilities/tables';

interface IAction {
    readonly type: string;
    readonly schedule: IVesselSchedule;
    readonly error: string;
}

export interface IVesselsScheduleState {
    readonly fetchVesselScheduleListError: string;
    readonly fetchListIsLoading: boolean;
    readonly schedule: IVesselSchedule;
}

const defaultState: IVesselsScheduleState = {
    fetchVesselScheduleListError: '',
    fetchListIsLoading: false,
    schedule: {
        pageNumber: 1,
        pageSize: 0,
        result: [],
        totalCount: 0
    }
};

export const reducer = (state: IVesselsScheduleState = defaultState, action: IAction): IVesselsScheduleState => {
    switch (action.type) {
        case TYPES.FETCH_VESSEL_SCHEDULE_LIST.PENDING:
            return {
                ...state,
                fetchListIsLoading: true,
                fetchVesselScheduleListError: ''
            };
        case TYPES.FETCH_VESSEL_SCHEDULE_LIST.FULFILLED:
            return {
                ...state,
                schedule: {
                    result: cloneDataWithDisplayNames(action.schedule.result) || defaultState.schedule.result,
                    totalCount: action.schedule.totalCount || defaultState.schedule.totalCount,
                    pageNumber: action.schedule.pageNumber || defaultState.schedule.pageNumber,
                    pageSize: action.schedule.pageSize || defaultState.schedule.pageSize
                },
                fetchListIsLoading: false,
                fetchVesselScheduleListError: ''
            };
        case TYPES.FETCH_VESSEL_SCHEDULE_LIST.REJECTED:
            return {
                ...state,
                fetchListIsLoading: false,
                fetchVesselScheduleListError: action.error
            };
        default:
            return state;
    }
};
