import { ThunkDispatch } from 'redux-thunk';
import { Action } from 'redux';
import { EVENT_LOGS } from 'action-types/event-logs';
import { TYPES } from 'action-types/filters';
import { applyFilters } from 'utilities/util';
import { IStore } from 'reducers/index';
import { callApiPost } from 'utilities/apiCaller';
import API from 'constants/api';

export const fetchEventLogs = (pageNumber?: number, pageSize?: number) => async (dispatch: ThunkDispatch<any, any, Action>, getState: () => IStore) => {
    const res = await callApiPost(API.Presentation.GetLogEventsFromDatabase, applyFilters(getState, { isAirInBody: false, isAirInFilters: false }, {
        Page: pageNumber || 1,
        PageNumber: pageNumber || 1,
        PageSize: pageSize || 10
    }), {
        headers: { TransportMode: 'Ocean' },
    });
    dispatch({ type: EVENT_LOGS.DATA_FETCHED, data: res.data });
    dispatch({
        type: TYPES.SET_TOTAL_COUNT,
        totalCount: res.data.totalCount,
    });
};
