import React from 'react';
import { ColumnFactory } from 'components/data-table/ColumnFactory';
import { DataTable } from 'components/data-table/DamcoTable';
import { IEquipmentDetailsDocument } from 'reducers/fact/models';
import { StatusRow } from 'components/status-row';
import { cloneDataWithDisplayNames } from 'utilities/tables';

interface IDocumentsTableProps {
    documents: IEquipmentDetailsDocument[];
}

export const DocumentsTable: React.StatelessComponent<IDocumentsTableProps> = (props) => {
    const data = cloneDataWithDisplayNames(props.documents, 'DD-MM-YYYY, h:mm').map(document => ({
        ...document,
        documentNumberDisplayName: [document.documentNumber, `/exception-management/fact/documents/${document.documentId}`]
    }));

    return(
        <StatusRow title="Documents">
            <DataTable
                data={data}
                columns={[
                    ColumnFactory.linkedColumn('documentNumberDisplayName', 'Document number'),
                    ColumnFactory.standardColumn('documentOnEquipmentVolumeDisplayName', 'Document on equipment volume'),
                    ColumnFactory.standardColumn('essoOnEtVolumeFactorDisplayName', 'ESSO on ET volume factor'),
                    ColumnFactory.standardColumn('etOnEssoVolumeFactorDisplayName', 'ET on ESSO volume factor'),
                    ColumnFactory.standardColumn('originalDocumentNumberDisplayName', 'Original document number'),
                    ColumnFactory.standardColumn('typeDisplayName', 'Type'),
                    ColumnFactory.standardColumn('updateDateDisplayName', 'Update date'),
                    ColumnFactory.standardColumn('createDateDisplayName', 'Create date'),
                ]}
                withCheckboxes={false}
                reference={null as any}
                tableName="EquipmentDetailsDocumentsTable"
                showPagination={true}
                expandable={false}
            />
        </StatusRow>
    );
};

