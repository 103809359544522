import React, { FunctionComponent } from 'react';
import { ColumnFactory } from 'components/data-table/ColumnFactory';
import { mapStatusToStringForCT } from 'constants/statuses';
import { Accordion } from 'components/accordion';
import { DataTable } from 'components/data-table/DamcoTable';
import { cloneDataWithDisplayNames } from 'utilities/tables';

interface IMawbVesselsTableProps {
    data: any[];
    threePl: string;
}

export const MawbVesselsTable: FunctionComponent<IMawbVesselsTableProps> = (props) => {
    const data = cloneDataWithDisplayNames(props.data).map((obj: any) => ({
        ...obj,
        voyageStatusDisplayName: mapStatusToStringForCT(obj.voyageStatus),
        threePlDisplayName: props.threePl
    }));

    return(
        <Accordion text="Transshipment">
        <DataTable 
            data={data}
            withCheckboxes={false}
            expandable={false}
            columns={[
                ColumnFactory.standardColumn('vesselNameDisplayName', 'Flight number'),
                ColumnFactory.standardColumn('threePlDisplayName', '3PL'),
                ColumnFactory.standardColumn('loadingPortDisplayName', 'POL'),
                ColumnFactory.standardColumn('destinationPortDisplayName', 'POD'),
                ColumnFactory.standardColumn('etdDisplayName', 'ETD'),
                ColumnFactory.standardColumn('atdDisplayName', 'ATD'),
                ColumnFactory.standardColumn('originalETADisplayName', 'Original ETA'),
                ColumnFactory.standardColumn('currentETADisplayName', 'Current ETA'),
                ColumnFactory.standardColumn('ataDisplayName', 'ATA'),
                ColumnFactory.standardColumn('voyageStatusDisplayName', 'Status'),
            ]}
            tableName="MAWBTrackingVesselTable"
            reference={null as any}
        />
        </Accordion>
    )
};