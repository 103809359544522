import React from 'react';
import { ColumnFactory } from 'components/data-table/ColumnFactory';
import { IBLEquipments } from 'reducers/vessels/models';
import { Accordion } from 'components/accordion';
import { DataTable } from 'components/data-table/DamcoTable';
import { cloneDataWithDisplayNames } from 'utilities/tables';

interface IMawbDetailsTableProps {
    data: IBLEquipments[];
}

export const MawbDetailsTable: React.StatelessComponent<IMawbDetailsTableProps> = (props: IMawbDetailsTableProps) => {
    const data: any = cloneDataWithDisplayNames(props.data).map((obj: any) => ({
        ...obj,
        children: obj.pOs.map((pos: any) => ({
            ...pos,
            ...pos.sku[0]
        }))
    }));

    return (
        <Accordion text="Equipment">
            <DataTable
                withCheckboxes={false}
                expandable={true}
                data={data}
                columns={[
                    ColumnFactory.standardColumn('HAWBDisplayName', 'HAWB'),
                    ColumnFactory.standardColumn('serviceDisplayName', 'Service'),
                    ColumnFactory.standardColumn('shipmentTypeDisplayName', 'Shipment type'),
                    ColumnFactory.standardColumn('totalPackagesDisplayName', 'Total packages'),
                    ColumnFactory.standardColumn('packageTypeDisplayName', 'Package type'),
                    ColumnFactory.standardColumn('grossWeightDisplayName', 'Weight'),
                    ColumnFactory.standardColumn('volumeDisplayName', 'Volume'),
                    ColumnFactory.standardColumn('chargeableWeightDisplayName', 'Chargeable weight'),
                ]}
                subColumns={[
                    ColumnFactory.standardColumn('poNumberDisplayName', 'PO Number'),
                    ColumnFactory.standardColumn('skuNumberDisplayName', 'SKU'),
                    ColumnFactory.standardColumn('goodsDescriptionDisplayName', 'Description'),
                    ColumnFactory.standardColumn('packagesDisplayName', 'Packages'),
                    ColumnFactory.standardColumn('pieceTypeDisplayName', 'Piece type'),
                    ColumnFactory.standardColumn('termsOfPrepaidDisplayName', 'Terms of prepaid / collect'),
                ]}
                tableName="MawbDetailsEquipmentTable"
                reference={null as any}
                showPagination={true}
            />
        </Accordion>
    );
}
