import { TYPES } from 'action-types/auth';

interface IAction {
    readonly type: string;
    readonly payload: any;
}

export interface IAuthState {
    readonly loginPending: boolean;
    readonly signedIn: boolean;
}

const defaultState: IAuthState = {
    loginPending: false,
    signedIn: false
};

export const reducer = (state: IAuthState = defaultState, action: IAction): IAuthState => {
    switch (action.type) {
        case TYPES.LOGIN.PENDING:
            return {
                ...state,
                loginPending: true,
                signedIn: false
            };
        case TYPES.LOGIN.FULFILLED:
            return {
                ...state,
                loginPending: false,
                signedIn: true
            };
        case TYPES.LOGIN.REJECTED:
            return {
                ...state,
                loginPending: false,
                signedIn: false
            };
        default:
            return state;
    }
};
