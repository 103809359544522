/// <reference types="webpack-env" />

import 'babel-polyfill';
import React from 'react';
import ReactDOM from 'react-dom';
import registerServiceWorker from './registerServiceWorker';
import { BrowserRouter } from 'react-router-dom';
import { Provider } from 'react-redux';
import store from './store';
import PageWrapper from 'containers/pageWrapper/pageWrapper';
import Msal from 'containers/authorization/Msal.tsx';
import { RoutingConfiguration } from './views';
import './styles/excluded.css';
import 'styles/sticky-header.css';
import moment from 'moment';

moment.locale('en-gb');

const render = (Component: React.ComponentClass) =>
    ReactDOM.render(
        <Provider store={store}>
            <Msal>
                <BrowserRouter>
                    <PageWrapper>
                        <Component />
                    </PageWrapper>
                </BrowserRouter>
            </Msal>
        </Provider>,
        document.getElementById('root') as HTMLElement,
    );

render(RoutingConfiguration);

registerServiceWorker();

if (module.hot) {
    module.hot.accept('./views', () => {
        const NextRoutingConfiguration = require('./views').RoutingConfiguration;
        render(NextRoutingConfiguration);
    });
}
