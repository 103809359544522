import React from 'react';
import { ModalType } from 'components/modal-screen/ModalType';
import { ModalScreen } from 'components/modal-screen';

interface IErrorModal {
    visible: boolean;
    primaryButtonFunc: () => void;
    closeModal: () => void;
}

export const ErrorModal: React.StatelessComponent<IErrorModal> = (props: IErrorModal) => (
    <ModalScreen
        title="Some error occured"
        visible={props.visible}
        modalType={ModalType.s()}
        closeModal={props.closeModal}
        primaryButtonTitle="Close"
        primaryButtonFunc={props.primaryButtonFunc}
    >
        <p>Notify customer is not able for chosen item right now.</p>
    </ModalScreen>
);
