import { ThunkDispatch } from 'redux-thunk';
import API from 'constants/api';
import {callApiGet, callApiPost} from 'utilities/apiCaller';
import { TYPES } from 'action-types/customs';
import { Action } from 'redux';
import { IStore } from 'reducers/index';
import { applyFilters, generateError } from 'utilities/util';
import { Dispatch } from 'redux';

export const fetchBlList = (pageNumber?: number, pageSize?: number) => {
    return async (dispatch: ThunkDispatch<any, any, Action>, getState: () => IStore) => {
        dispatch({ type: TYPES.FETCH_BLS.PENDING });
        try {
            const res = await callApiPost(API.Customs.GetBls, applyFilters(getState, { isAirInBody: false, isAirInFilters: false }, {
                Page: pageNumber,
                PageNumber: pageNumber,
                PageSize: pageSize
            }), {headers: { TransportMode: 'Ocean' } });
            dispatch({ type: TYPES.FETCH_BLS.FULFILLED, bls: res.data });
        } catch (e) {
            const error = generateError(e);
            dispatch({ type: TYPES.FETCH_BLS.REJECTED, error });
        }
    }
};

export const resetFetchBlListError = () => (dispatch: Dispatch) => {
    dispatch({ type: TYPES.FETCH_BLS.RESET_ERROR });
}

export const fetchHBLFCRDetails = (hblid: string | string[], voyageId: string | string[]) => {
    return async (dispatch: ThunkDispatch<any, any, Action>) => {
        dispatch({ type: TYPES.FETCH_HBLFCR_DETAILS.PENDING });
        try {
            const res = await callApiGet(API.Customs.GetHBLFCRDetails, {
                hblid: Array.isArray(hblid) ? hblid.join() : hblid,
                voyageId: Array.isArray(voyageId) ? voyageId.join() : voyageId,
            }, {headers: {TransportMode: 'Ocean'}});
            dispatch({ type: TYPES.FETCH_HBLFCR_DETAILS.FULFILLED, hblFcrDetails: res.data });
            return res;
        } catch (e) {
            const error = generateError(e);
            dispatch({ type: TYPES.FETCH_HBLFCR_DETAILS.REJECTED, error });
            return e;
        }
    }
}

export const resetFetchHBLFCRDetailsError = () => (dispatch: Dispatch) => {
    dispatch({ type: TYPES.FETCH_HBLFCR_DETAILS.RESET_ERROR });
}

export const fetchHBLFCRDetailsOnList = (hblid: string, voyageId: string) => {
    return async (dispatch: ThunkDispatch<any, any, Action>) => {
        dispatch({ type: TYPES.FETCH_HBLFCR_DETAILS_ON_LIST.PENDING });
        try {
            const res = await callApiGet(API.Customs.GetHBLFCRDetails, {
                hblid: Array.isArray(hblid) ? hblid.join() : hblid,
                voyageId: Array.isArray(voyageId) ? voyageId.join() : voyageId,
            }, {headers: {TransportMode: 'Ocean'}});
            dispatch({ type: TYPES.FETCH_HBLFCR_DETAILS_ON_LIST.FULFILLED, hblFcrDetails: res.data });
            return res.data;
        } catch (e) {
            dispatch({ type: TYPES.FETCH_HBLFCR_DETAILS_ON_LIST.REJECTED });
            return e;
        }
    }
}

export const resetFetchHBLFCRDetailsOnListError = () => (dispatch: Dispatch) => {
    dispatch({ type: TYPES.FETCH_HBLFCR_DETAILS_ON_LIST.RESET_ERROR });
}

export const updateHBLDetails = (body: any) => {
    return async (dispatch: ThunkDispatch<any, any, Action>) => {
        dispatch({ type: TYPES.UPDATE_HBL_DETAILS.PENDING });
        try {
            const res = await callApiPost(API.Customs.UpdateHBLDetails, body, {headers: {TransportMode: 'Ocean'}});
            dispatch({ type: TYPES.UPDATE_HBL_DETAILS.FULFILLED, hblDetails: res });
        } catch (e) {
            const error = generateError(e);
            dispatch({ type: TYPES.UPDATE_HBL_DETAILS.REJECTED, error });
            throw e;
        }
    }
};

export const resetUpdateHBLDetailsError = () => (dispatch: Dispatch) => {
    dispatch({ type: TYPES.UPDATE_HBL_DETAILS.RESET_ERROR });
}

export const fetchContainerDetails = (equipmentId: number) => {
    return async (dispatch: ThunkDispatch<any, any, Action>) => {
        dispatch({ type: TYPES.FETCH_CONTAINER_DETAILS.PENDING });
        try {
            const res = await callApiGet(API.Customs.GetContainerDetails, { equipmentId }, {headers: {TransportMode: 'Ocean'}});
            dispatch({ type: TYPES.FETCH_CONTAINER_DETAILS.FULFILLED, container: res.data });
        } catch (e) {
            const error = generateError(e);
            dispatch({ type: TYPES.FETCH_CONTAINER_DETAILS.REJECTED, error });
            throw e;
        }
    }
}

export const resetFetchContainerDetailsError = () => (dispatch: Dispatch) => {
    dispatch({ type: TYPES.FETCH_CONTAINER_DETAILS.RESET_ERROR });
}

export const fetchAirClearanceList = (pageNumber?: number, pageSize?: number) => {
    return async (dispatch: ThunkDispatch<any, any, Action>, getState: () => IStore) => {
        dispatch({ type: TYPES.FETCH_AIR_CLEARANCE.PENDING });
        try {
            const res = await callApiPost(API.Customs.AirGetAirClearance, applyFilters(getState, { isAirInBody: true, isAirInFilters: true }, {
                Page: pageNumber,
                PageNumber: pageNumber,
                PageSize: pageSize
            }), {headers: {
                TransportMode: 'Air'
            }});
            dispatch({ type: TYPES.FETCH_AIR_CLEARANCE.FULFILLED, bls: res.data });
        } catch (e) {
            const error = generateError(e);
            dispatch({ type: TYPES.FETCH_AIR_CLEARANCE.REJECTED, error });
        }
    }
};

export const resetFetchAirClearanceListError = () => (dispatch: Dispatch) => {
    dispatch({ type: TYPES.FETCH_AIR_CLEARANCE.RESET_ERROR });
}

export const fetchHawbDetails = (hblid: string, voyageId: string) => {
    return async (dispatch: ThunkDispatch<any, any, Action>) => {
        dispatch({ type: TYPES.FETCH_CUSTOMS_HAWB_DETAILS.PENDING });
        try {
            const res = await callApiGet(API.Customs.AirGetHawbDetails, { hblid, voyageId }, {headers: {TransportMode: 'Air'}});
            dispatch({ type: TYPES.FETCH_CUSTOMS_HAWB_DETAILS.FULFILLED, hawbDetails: res.data });
        } catch (e) {
            const error = generateError(e);
            dispatch({ type: TYPES.FETCH_CUSTOMS_HAWB_DETAILS.REJECTED, error });
            return e;
        }
    }
}

export const resetFetchHawbDetailsError = () => (dispatch: Dispatch) => {
    dispatch({ type: TYPES.FETCH_CUSTOMS_HAWB_DETAILS.RESET_ERROR });
}

export const fetchHawbDetailsOnList = (hblid: string, voyageId: string) => {
    return async (dispatch: ThunkDispatch<any, any, Action>) => {
        dispatch({ type: TYPES.FETCH_HAWB_DETAILS_ON_LIST.PENDING });
        try {
            const res = await callApiGet(API.Customs.AirGetHawbDetails, { hblid, voyageId }, {headers: {TransportMode: 'Air'}});
            dispatch({ type: TYPES.FETCH_HAWB_DETAILS_ON_LIST.FULFILLED, hawbDetails: res.data });
            return res.data;
        } catch (e) {
            const error = generateError(e);
            dispatch({ type: TYPES.FETCH_HAWB_DETAILS_ON_LIST.REJECTED, error });
            return e;
        }
    }
}

export const resetFetchHawbDetailsOnListError = () => (dispatch: Dispatch) => {
    dispatch({ type: TYPES.FETCH_HAWB_DETAILS_ON_LIST.RESET_ERROR });
}


export const fetchEquipmentsList = (pageNumber?: number, pageSize?: number, isAir: boolean = false) => {
    return async (dispatch: ThunkDispatch<any, any, Action>, getState: () => IStore) => {
        dispatch({ type: TYPES.FETCH_EQUIPMENTS.PENDING });
        try {
            const res = await callApiPost(API.Customs.GetEquipments, applyFilters(getState, { isAirInBody: isAir, isAirInFilters: isAir }, {
                Page: pageNumber,
                PageNumber: pageNumber,
                PageSize: pageSize
            }), {
                headers: { TransportMode: isAir ? 'Air' : 'Ocean' },
            });
            dispatch({ type: TYPES.FETCH_EQUIPMENTS.FULFILLED, equipments: res.data });
        } catch (e) {
            const error = generateError(e);
            dispatch({ type: TYPES.FETCH_EQUIPMENTS.REJECTED, error });
        }
    }
};

export const updateEquipments = (body: any, isAir: boolean = false) => {
    return async (dispatch: ThunkDispatch<any, any, Action>) => {
        dispatch({ type: TYPES.UPDATE_EQUIPMENTS.PENDING });
        try {
            const res = await callApiPost(API.Customs.UpdateEquipments, body, {headers: {TransportMode: isAir ? 'Air' : 'Ocean'}});
            dispatch({ type: TYPES.UPDATE_EQUIPMENTS.FULFILLED, equipmentsDetails: res });
        } catch (e) {
            const error = generateError(e);
            dispatch({ type: TYPES.UPDATE_EQUIPMENTS.REJECTED, error });
            throw e;
        }
    }
};