import React from 'react';
import {QuarterGrid} from 'components/grid/quarter-grid';
import {OneStatus} from 'components/status-row/OneStatus';
import { ILastUser } from 'reducers/customs/models';
import { formatDate, formatValueIfNullOrUndefined } from 'utilities/util';
import { Accordion } from 'components/accordion';

export const LastUser: React.SFC<ILastUser> = (props: ILastUser) => {
    return(
        <Accordion text="User details">
            <QuarterGrid>
                <OneStatus label="Last user updated">
                    {formatValueIfNullOrUndefined(props.user)}
                </OneStatus>
                <OneStatus label="Last updated date">
                    {formatDate(props.updateDate)}
                </OneStatus>
            </QuarterGrid>
        </Accordion>
        
    )
}