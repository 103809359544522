import React, { FunctionComponent, useEffect } from 'react';
import { PageHeader } from 'components/page-header';
import { Page } from 'components/page-container';
import { PageWithTimeline } from 'components/page-with-timeline';
import { DangerousGoodsRow } from 'modules/details-pages/components/DangerousGoodsRow';
import { VesselRow } from 'modules/details-pages/components/VesselRow';
import { ReleaseRow } from 'modules/details-pages/components/ReleaseRow';
import { EquipmentRow } from 'modules/details-pages/components/EquipmentRow';
import { DeliveryBottomTable } from 'modules/details-pages/components/DeliveryBottomTable';
import { DeliveryAndReturnContainerDetailsTopBar } from './containers/DeliveryAndReturnContainerDetailsTopBar';
import { NonConformanceRow } from 'modules/details-pages/components/NonConformance';
import { DemurrageAndDetentionRow } from 'modules/details-pages/components/DemurrageAndDetention';
import { DeliveryFailureRow } from 'modules/details-pages/components/DeliveryFailureRow';
import { RouteComponentProps } from 'react-router';
import { DeliveryTable } from './components/DeliveryTable';
import { EmptyReturnDocumentsRow } from 'modules/details-pages/components/EmptyReturnDocumentsRow';
import { IEquipmentDetails } from 'reducers/planning/models';
import { IStore } from 'reducers/index';
import { ThunkDispatch } from 'redux-thunk';
import { Action } from 'redux';
import { getEquipmentDetails } from 'actions/planning';
import { connect } from 'react-redux';
import { LoadingAnimation } from 'components/loading';
import {HandleError} from 'components/handle-error';
import { DeliveryPlanningTypes } from 'constants/comment';
import { ProgressIndicator } from 'components/progress-indicator';
import { IBubble } from 'reducers/vessels/models';
import { UserDetails } from 'components/user-details';
import { Capability } from 'components/timeline';
import { DeliveryRow } from './components/DeliveryRow';
import { HBLFCRDetailsTable } from 'modules/details-pages/components/HBLFCRDetailsTable';
import { GenericError } from 'components/error-dialog/GenericError';
import { ClearanceTable } from 'views/planning/deliver-planning-container-details/components/ClearanceTable';

interface IMapStateToProps {
    equipment: IEquipmentDetails;
    isLoading: boolean;
    isGetEquipmentDeliveriesError: boolean;
    isUpdateEmptyReturnActualsError: boolean;
    isAddEquipmentDeliveriesError: boolean
    isUpdateEmptyReturnDetailsError: boolean
    bubbles: IBubble[];
}

interface IDispatch {
    getEquipmentDetails: (id: number) => Promise<void>;
}

type IDeliveryAndReturnEquipmentDetails = IMapStateToProps & IDispatch & RouteComponentProps<{ id: string }>;

const _DeliveryAndReturnEquipmentDetails: FunctionComponent<IDeliveryAndReturnEquipmentDetails> = (props) => {
    useEffect(() => {
        fetchEquipment()
    }, []);
    
    const id = parseInt(props.match.params.id, 10);
    const fetchEquipment = () => {
        const equipId = parseInt(id.toString(), 10);
        if (!Number.isNaN(equipId)) {
            props.getEquipmentDetails(equipId)
        }
    };

    const {
        isGetEquipmentDeliveriesError,
        isUpdateEmptyReturnActualsError,
        isAddEquipmentDeliveriesError,
        isUpdateEmptyReturnDetailsError,
    } = props;

    if (Number.isNaN(id)) {
        return <GenericError />;
    }

    return (
        <>
            {props.isLoading && <LoadingAnimation />}
            <PageHeader
                backButton={true}
                id="equipment-return-header"
            >
                Equipment : {props.equipment.equipmentNumber}
            </PageHeader>
            <ProgressIndicator data={props.bubbles} />
            <HandleError
                isUpdateEmptyReturnDetailsError={isUpdateEmptyReturnDetailsError}
                isGetEquipmentDeliveriesError={isGetEquipmentDeliveriesError}
                isUpdateEmptyReturnActualsError={isUpdateEmptyReturnActualsError}
                isAddEquipmentDeliveriesError={isAddEquipmentDeliveriesError}
            />
            <DeliveryAndReturnContainerDetailsTopBar
                equipment={props.equipment}
                refecthFunction={fetchEquipment}
            />
            <Page>
                <PageWithTimeline
                    objectId={id}
                    objectType={DeliveryPlanningTypes.Equipment}
                    isAir={false}
                    capability={Capability.DeliveryPlanning}
                >
                    <DeliveryTable data={props.equipment.deliveries} />
                    <DeliveryRow {...props.equipment} />
                    <EmptyReturnDocumentsRow {...props.equipment} />
                    <HBLFCRDetailsTable data={props.equipment.hbLs} />
                    <DeliveryFailureRow {...props.equipment} />
                    <NonConformanceRow {...props.equipment} />
                    <DemurrageAndDetentionRow {...props.equipment} />
                    <DangerousGoodsRow {...props.equipment} />
                    <VesselRow {...props.equipment} />
                    <ClearanceTable {...props.equipment} />
                    <ReleaseRow {...props.equipment} />
                    <EquipmentRow {...props.equipment} />
                    <DeliveryBottomTable data={props.equipment.pOs} />
                    <UserDetails
                        user={props.equipment.lastUserUpdated}
                        date={props.equipment.updateDate}
                    />
                </PageWithTimeline>
            </Page>
        </>
    );
}

const mapStateToProps = (state: IStore): IMapStateToProps => ({
    isUpdateEmptyReturnDetailsError: !!state.planning.updateEmptyReturnDetailsError,
    isGetEquipmentDeliveriesError: !!state.planning.getEquipmentDeliveriesError,
    isUpdateEmptyReturnActualsError: !!state.planning.updateEmptyReturnActualsError,
    isAddEquipmentDeliveriesError: !!state.planning.addEquipmentDeliveriesError,
    equipment: state.planning.equipmentDetails,
    isLoading: state.planning.isGetEquipmentDetailsPending,
    bubbles: state.planning.equipmentDetails.bubbleDataDeliveryAndReturn
});

const mapDispatchToProps = (dispatch: ThunkDispatch<any, any, Action>): IDispatch => ({
    getEquipmentDetails: (id: number) => dispatch(getEquipmentDetails(id)),
});

export const DeliveryAndReturnEquipmentDetails = connect<IMapStateToProps, IDispatch, {}, IStore>(mapStateToProps, mapDispatchToProps)(_DeliveryAndReturnEquipmentDetails);
