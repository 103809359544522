import { TYPES } from 'action-types/filters';
import { SORT_ORDER } from 'reducers/filters/models';
import { ReactNodeArray } from 'react';

interface IAction {
    readonly type: string;
    readonly column: string;
    readonly order: SORT_ORDER;
    readonly totalCount?: number;
    readonly filters?: ReactNodeArray;
    readonly name: string
    readonly value: any,
}

export interface IFiltersState {
    column: string,
    order: SORT_ORDER,
    pageSize: number;
    pageNumber: number;
    totalCount: number;
    emptyFilters: object;
    filters: any;
    appliedFilters: object;
}

const defaultState: IFiltersState = {
    column: '',
    order: SORT_ORDER.ASCENDING,
    pageSize: 10,
    pageNumber: 1,
    totalCount: 0,
    emptyFilters: {},
    filters: {},
    appliedFilters: {}
};

export const reducer = (state: IFiltersState = defaultState, action: IAction): IFiltersState => {
    switch (action.type) {
        case TYPES.SET_SORT:
            return {
                ...state,
                column: action.column,
                order: action.order,
            };
        case TYPES.RESET_SORT:
            return {
                ...state,
                column: '',
                order: SORT_ORDER.ASCENDING,
            };
        case TYPES.SET_FIRST_PAGE:
            return {
                ...state,
                pageNumber: 1,
            };
        case TYPES.SET_NEXT_PAGE:
            return {
                ...state,
                pageNumber: state.pageNumber + 1
            };
        case TYPES.SET_PREVIOUS_PAGE:
            return {
                ...state,
                pageNumber: state.pageNumber - 1
            };
        case TYPES.SET_LAST_PAGE:
            return {
                ...state,
                pageNumber: Math.ceil(state.totalCount / state.pageSize)
            };
        case TYPES.SET_TOTAL_COUNT:
            return {
                ...state,
                totalCount: action.totalCount as number,
            };
        case TYPES.SET_EMPTY_FILTERS:
            return {
                ...state,
                emptyFilters: createEmptyFilters(action.filters as ReactNodeArray),
            };
        case TYPES.SET_FILTER:
            return {
                ...state,
                filters: {
                    ...state.filters,
                    [action.name]: action.value,
                }
            };
        case TYPES.APPLY_FILTERS:
            return {
                ...state,
                appliedFilters: state.filters,
                pageNumber: 1,
            };
        case TYPES.CLEAR_FILTERS:
            return {
                ...state,
                appliedFilters: state.emptyFilters,
                filters: state.emptyFilters,
                pageNumber: 1,
            };
        case TYPES.SET_EMPTY_DATE:
            return {
                ...state,
                filters: {
                    ...state.filters,
                    [action.name]: action.value
                }
            };
        default:
            return state;
    }
};

const createEmptyFilters = (filters: ReactNodeArray) => filters.reduce((prev: object, filter: any) => {
    const { name, type } = filter.props;
    if(type === 'array') {
        return {
            ...prev,
            [name]: [],
        }
    }
    return {
        ...prev,
        [name]: ''
    };
}, {}) as object;
