import React from 'react';
import { ColumnFactory } from 'components/data-table/ColumnFactory';
import { Accordion } from 'components/accordion';
import { DataTable } from 'components/data-table/DamcoTable';
import { cloneDataWithDisplayNames } from 'utilities/tables';



export const HouseBLTable: React.StatelessComponent<{ data: any[] }> = (props) => {
    const data = cloneDataWithDisplayNames(props.data).map((bl: any) => ({
        ...bl,
        children: bl.hold
    }));

    return (
        <Accordion text="House B/L">
            <DataTable
                data={data}
                withCheckboxes={false}
                expandable={true}
                columns={[
                    ColumnFactory.standardColumn('hblNumberDisplayName', 'HB/L'),
                    ColumnFactory.standardColumn('hblTypeDisplayName', 'HBL type'),
                    ColumnFactory.standardColumn('orignalHblSurrenderedDisplayName', 'Original HBL surrendered'),
                    ColumnFactory.standardColumn('surrenderedDateDisplayName', 'Surrendered date'),
                ]}
                subColumns={[
                    ColumnFactory.standardColumn('holdTypeDisplayName', 'Hold Type'),
                    ColumnFactory.standardColumn('startDateDisplayName', 'Start Date'),
                    ColumnFactory.standardColumn('endDateDisplayName', 'End Date'),
                ]}
                tableName="CustomsEquipmentDetailsHBLTable"
                reference={null as any}
                showPagination={true}
            />
        </Accordion>
    );
}
