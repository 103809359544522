import React, { Component } from 'react';
import { Redirect, Switch } from 'react-router';
import { ILink } from 'components/sub-navigation/models/Link';
import { SubNavigationVertical } from 'components/sub-navigation-vertical';
import { RouteWithPermissions } from 'containers/route-with-permissions';
import { BUSINNESS_FUNCTIONS } from 'constants/roles';
import { OfficePrefixes } from 'modules/settings/fact/containers/OfficePrefixes';
import { GOHManagementTable } from 'modules/settings/fact/containers/GOHManagementTable';

export class Fact extends Component {
    private subLinks: ILink[] = [
        {
            to: 'office-prefixes',
            linkText: 'Office prefixes'
        },
        {
            to: 'goh-management',
            linkText: 'GOH management'
        }
    ];

    public render() {
        return (
            <div className="grid-wrapper">
                <div className="col-10">
                    <SubNavigationVertical
                        links={this.subLinks}
                    />
                </div>
                <div className="col-90">
                    <Switch>
                        <RouteWithPermissions
                            path="/exception-management/settings/fact/office-prefixes"
                            component={OfficePrefixes}
                            functions={[ BUSINNESS_FUNCTIONS.CONFIGURATION_FACT_WRAPPER ]}
                        />
                        <RouteWithPermissions
                            path="/exception-management/settings/fact/goh-management"
                            component={GOHManagementTable}
                            functions={[ BUSINNESS_FUNCTIONS.CONFIGURATION_FACT_WRAPPER ]}
                        />
                        <Redirect to="/exception-management/settings/fact/office-prefixes" />
                    </Switch>
                </div>
            </div>
        );
    }
}