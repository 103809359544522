import React from 'react';
import { formatDate, formatValueIfNullOrUndefined, getArrayOfButtons } from 'utilities/util';
import { EditEmptyReturnDetailsPopup } from 'modules/delivery-and-return/ocean/components/EditEmptyReturnDetailsPopup';
import { IEquipmentDetails } from 'reducers/planning/models';
import { BUSINNESS_FUNCTIONS } from 'constants/roles';
// tslint:disable-next-line: no-submodule-imports
import { IWithPermissions, withPermissions } from 'src/HOC/withPermissions';
import { PropertiesRibbonWrapper } from 'components/properties-ribbon';
import { EditTransportationDetailsModal } from 'views/delivery-and-return/equipment-details/components/EditTransportationDetailsModal';

interface IAirDestinationSummaryTopBar extends IWithPermissions {
    deliveryPlannedDate: string;
    deliveryActualDate: string;
    currentLocation: string;
    deliveryStatus: string;
    getAirReturn: (id: number) => Promise<void>;
    equipment: IEquipmentDetails;
}

interface IAirDestinationSummaryTopBarState {
    isEditDetailsModalOpened: boolean;
    isEditTransportationDetailsModalOpened: boolean;
}

class _AirDestinationSummaryTopBar extends React.Component<IAirDestinationSummaryTopBar, IAirDestinationSummaryTopBarState> {
    public state: IAirDestinationSummaryTopBarState = {
        isEditDetailsModalOpened: false,
        isEditTransportationDetailsModalOpened: false,
    };

    public render() {
        const actions = [
            {label: 'Update final delivery details', action: this.toggleUpdateDeliveryDetailsModal},
            {label: 'Update transport details', action: this.toggleEditTransportationDetailsModal},
        ];
        const propertiesRibbonData = {
            labels: [
                {label: 'Planned date / time', value: formatDate(this.props.deliveryPlannedDate)},
                {label: 'Delivery actual', value: formatDate(this.props.deliveryActualDate)},
                {label: 'Current location', value: formatValueIfNullOrUndefined(this.props.currentLocation)},
                {label: 'Delivery status', value: formatValueIfNullOrUndefined(this.props.deliveryStatus)},
            ],
            actions: getArrayOfButtons(this.props.permissions, actions)
        };

        return (
            <React.Fragment>
                <PropertiesRibbonWrapper content={propertiesRibbonData} />
                {this.state.isEditDetailsModalOpened && <EditEmptyReturnDetailsPopup
                    visible={this.state.isEditDetailsModalOpened}
                    closeModal={this.toggleUpdateDeliveryDetailsModal}
                    initialValues={this.props.equipment}
                    refetchFunction={() => this.props.getAirReturn(this.props.equipment.id)}
                    isAir={true}
                    status={this.props.equipment.deliveryStatusEnum}
                />}
                {this.state.isEditTransportationDetailsModalOpened && <EditTransportationDetailsModal
                    visible={this.state.isEditTransportationDetailsModalOpened}
                    closeModal={this.toggleEditTransportationDetailsModal}
                    isAir={false}
                    id={this.props.equipment.id}
                    refetchFunction={() => this.props.getAirReturn(this.props.equipment.id)}
                />}
            </React.Fragment>
        );
    }

    private toggleUpdateDeliveryDetailsModal = () => this.setState((state) => ({
        isEditDetailsModalOpened: !state.isEditDetailsModalOpened,
    }));

    private toggleEditTransportationDetailsModal = () => this.setState((state) => ({
        isEditTransportationDetailsModalOpened: !state.isEditTransportationDetailsModalOpened,
    }));
}

const ROLES: BUSINNESS_FUNCTIONS[] = [BUSINNESS_FUNCTIONS.AIR_DELIVERY_EQUIPMENT_UPDATE_DELIVERY_DETAILS];

export const AirDestinationSummaryTopBar = withPermissions<IAirDestinationSummaryTopBar>(_AirDestinationSummaryTopBar, ROLES) as any;