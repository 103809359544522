import React, { Component } from 'react';
import { connect } from 'react-redux';
import { IStore } from 'reducers/index';
import { ColumnFactory } from 'components/data-table/ColumnFactory';
import {
    fetchContarinerTrackingCarriers,
    addContarinerTrackingCarrier,
    updateContarinerTrackingCarrier,
    deleteContarinerTrackingCarrier,
    resetContarinerTrackingCarrierError,
} from 'actions/settings';
import { ThunkDispatch } from 'redux-thunk';
import { Action } from 'redux';
import { EditCarrierModal } from 'modules/settings/container-tracking/containers/EditCarrierModal';
import { ConfirmModal } from 'components/confirm-modal';
import { DataTable } from 'components/data-table/DamcoTable';

interface IMapStateToProps {
    data: ICarrier[];
    error: string;
    pageSize: number;
    totalCount: number;
    pageNumber: number;
}

interface IDispatch {
    fetchCarriers(): Promise<void>;
    addNewCarrier(carrier: ICarrier): Promise<void>;
    updateCarrier(carrier: ICarrier): Promise<void>;
    deleteCarrier(carrier: ICarrier): Promise<void>;
    resetError(): void;
}

export interface ICarrier {
    carrierEmailConfigurationId?: number;
    carrierCode: string;
    carrierName: string;
    email: string;
    subjectTemplate: string;
    bodyTemplate: string;
    destinationPortName: string;
    destinationPortCode: string;
}

interface ICarriersProps extends IMapStateToProps, IDispatch {}

interface ICarriersState {
    editCarrierVisible: boolean;
    confirmVisible: boolean;
}

class _Carriers extends Component<ICarriersProps, ICarriersState> {
    public dataTable = React.createRef<any>();

    public state: ICarriersState = {
        editCarrierVisible: false,
        confirmVisible: false,
    };

    public componentDidMount() {
        this.props.fetchCarriers();
    }

    public render() {
        return (
            <React.Fragment>
                <DataTable
                    data={this.props.data}
                    withCheckboxes={true}
                    expandable={false}
                    selectVariant="single"
                    columns={[
                        ColumnFactory.standardColumn('email', 'Email'),
                        ColumnFactory.standardColumn('subjectTemplate', 'Subject template'),
                        ColumnFactory.standardColumn('bodyTemplate', 'Body template'),
                        ColumnFactory.standardColumn('carrierCode', 'Carrier code'),
                        ColumnFactory.standardColumn('carrierName', 'Carrier name'),
                        ColumnFactory.standardColumn('destinationPortCode', 'Destination port code'),
                        ColumnFactory.standardColumn('destinationPortName', 'Destination port name'),
                    ]}
                    actions={{
                        primaryActionIsMoreBtn: false,
                        primaryActionLabel: 'Add new',
                        primaryAction: this.openAddNew,
                        parentContextualActions: [
                            { isMoreBtn: false, label: 'Edit', action: () => this.setState({ editCarrierVisible: true }) },
                            { isMoreBtn: false, label: 'Delete', action: () => this.setState({ confirmVisible: true }) },
                        ]
                    }}
                    reference={this.dataTable}
                    tableName="SettingsCarrierCTTable"
                    showPagination={true}
                />
                {this.state.editCarrierVisible && <EditCarrierModal
                    visible={this.state.editCarrierVisible}
                    closeModal={() => this.setState({ editCarrierVisible: false })}
                    initialValues={this.dataTable.current ? this.dataTable.current.getSelectedItems()[0] : {}}
                    saveFunction={this.saveCarrier}
                    error={this.props.error}
                    resetError={this.props.resetError}
                />}
                {this.state.confirmVisible && <ConfirmModal
                    title="Are you sure?"
                    visible={this.state.confirmVisible}
                    onConfirm={this.deleteSelectedCarrier}
                    closeModal={() => this.setState({ confirmVisible: false })}
                >
                    Are you sure you want to delete this carrier?
                </ConfirmModal>}
            </React.Fragment>
        );
    }

    private saveCarrier = async (carrier: ICarrier) => {
        try {
            if (carrier.carrierEmailConfigurationId) {
                await this.props.updateCarrier(carrier);
            } else {
                await this.props.addNewCarrier(carrier);
            }

            this.setState({ editCarrierVisible: false });
            await this.props.fetchCarriers();
        } catch (error) {
            console.error(error);
        }
    };

    private deleteSelectedCarrier = async () => {
        const selectedItem = this.dataTable.current ? this.dataTable.current.getSelectedItems() : [];
        const carrier = selectedItem[0] as ICarrier;
        
        if (carrier) {
            await this.props.deleteCarrier(carrier);
            await this.props.fetchCarriers();
        }
        this.setState({ confirmVisible: false });
        await this.dataTable.current.hideContextRibbon();
    };

    private openAddNew = () => this.setState({ editCarrierVisible: true });
}

const mapStateToProps = (state: IStore): IMapStateToProps => ({
    data: state.settings.containerTrackingCarriers,
    error: state.settings.containerTrackingCarriersError,
    pageSize: state.filters.pageSize,
    totalCount: state.filters.totalCount,
    pageNumber: state.filters.pageNumber,
});

const mapDispatch = (dispatch: ThunkDispatch<any, any, Action>): IDispatch => ({
    fetchCarriers: () => dispatch(fetchContarinerTrackingCarriers()),
    addNewCarrier: (carrier: ICarrier) => dispatch(addContarinerTrackingCarrier(carrier)),
    updateCarrier: (carrier: ICarrier) => dispatch(updateContarinerTrackingCarrier(carrier)),
    deleteCarrier: (carrier: ICarrier) => dispatch(deleteContarinerTrackingCarrier(carrier)),
    resetError: () => dispatch(resetContarinerTrackingCarrierError()),
});

export const Carriers = connect<IMapStateToProps, IDispatch, IStore>(mapStateToProps, mapDispatch)(_Carriers);
