import React from 'react';
import { ModalScreen } from 'components/modal-screen';
import { ModalType } from 'components/modal-screen/ModalType';
import { EditEquipmentDeliveriesTable } from 'views/planning/deliver-planning-container-details/components/EditEquipmentDeliveriesTable';
import { TripleGrid } from 'components/grid/triple-grid';
import { ThunkDispatch } from 'redux-thunk';
import { Action, compose } from 'redux';
import { getEquipmentDeliveries, updateEquipmentDetails, resetUpdateEquipmentDetailsError, getEquipmentsList, getHAWBList, getHAWBDetails, getEquipmentDetails } from 'actions/planning';
import { connect } from 'react-redux';
import { IStore } from 'reducers/index';
import { IEquipmentDelivery, IPlanningHBL } from 'reducers/planning/models';
import { IAddComment, addNewComment, fetchComments } from 'actions/timeline';
import { HandleError } from 'components/handle-error';
import { DeliveryPlanningTypes } from 'constants/comment';
import { LoadingAnimation } from 'components/loading';
import { Accordion } from 'components/accordion';
import { FormSelect } from 'components/select/FormSelect';
import { FormInput } from 'components/input/FormInput';
import { Form, InjectedFormProps, reduxForm, submit, FieldArray, WrappedFieldArrayProps } from 'redux-form';
import styled from 'styled-components';
import { Button, ButtonType } from 'components/button';
import { FormTextArea } from 'components/textarea/FormTextArea';
import { Countable } from 'components/countable';
import { count } from 'utilities/util';
import { updateButtonOnAccordionsChange } from 'utilities/accordion';
import { Capability } from 'components/timeline';
import { HBLFieldArray } from './HBLFieldArray';

const SButton = styled(Button)`
    margin: 10px 0;
`;

const SButtonWrapper: any = styled.div`
    width: 100%;
    display: ${(props: any) => props.show ? 'flex' : 'none'};
    justify-content: flex-end;
`;

const boolOptions = [
    { value: '', optionText: 'Select value...' },
    { value: true, optionText: 'Yes'},
    { value: false, optionText: 'No'}
];

interface IEditDeliveryDetailsModalOwnProps {
    visible: boolean;
    closeModal: () => void;
    ids: any[];
    isAir?: boolean;
    isDetails?: boolean;
}

// interface IEditDeliveryDetailsModalState {
//     isInsertRowModalOpened: boolean;
//     isEdit: boolean;
//     collapseSections: boolean;
// }

export interface IEditDeliveryDetailsForm {
    documentsPARNRequired: boolean;
    documentsCRMRequired: boolean;
    isDangerousGoods: boolean;
    dangerousGoodsUNNO: string;
    dangerousGoodsClass: string;
    dangerousGoodsDescription: string;
    isDangerousGoodsADR: string;
    comments: string;
    hbLs: IPlanningHBL[];
}

interface IMapStateToProps {
    isGetEquipmentDeliveriesError: boolean;
    equipmentDeliveries: IEquipmentDelivery[];
    isLoading: boolean;
}

interface IDispatch {
    getEquipmentDeliveries: (id: number) => Promise<void>;
    addNewComment: (body: IAddComment, isAir: boolean) => Promise<void>;
    updateEquipmentDetails: (fields: object, isAir: boolean) => Promise<void>;
    fetchComments: (objectType: string, id: number, isAir: boolean) => Promise<any>;
    submit: () => void;
    resetUpdateEquipmentDetailsError: () => void;
    getEquipments: () => Promise<void>;
    getHAWBList: () => Promise<void>;
    getHawbDetails: (id: number) => Promise<void>;
    getEquipmentDetails: (id: number) => Promise<void>;
}

interface IEditDeliveryDetailsModal extends
    IEditDeliveryDetailsModalOwnProps,
    InjectedFormProps<IEditDeliveryDetailsForm, IEditDeliveryDetailsModalOwnProps>,
    IMapStateToProps,
    IDispatch {}


class _EditTransportationDetailsModal extends React.Component<IEditDeliveryDetailsModal, any> {
    public static FORM_NAME = 'EDIT_TRANSPORT_DETAILS';

    public state: any = {
        isInsertRowModalOpened: false,
        isEdit: false,
    };

    public componentDidMount() {
        this.props.getEquipmentDeliveries(this.props.ids[0]);

        this.setState({
            accordion_1: true,
            accordion_2: true,
            accordion_3: true,
            hbLs: this.props.initialValues.hbLs || [],
        });
    };

    public render() {
        const title = this.props.ids.length > 1 ? `- ${this.props.ids.length} equipments` : '';

        return (
            <React.Fragment>
                {this.props.isLoading && <LoadingAnimation/>}
                <ModalScreen
                    title={`Update transport details ${title}`}
                    visible={this.props.visible}
                    modalType={ModalType.xl()}
                    closeModal={this.props.closeModal}
                    primaryButtonTitle="Done"
                    primaryButtonFunc={this.props.ids.length === 1 ? this.props.submit : this.props.closeModal}
                    primaryButtonId="submit-button"
                    secondaryButtonTitle="Cancel"
                    secondaryButtonFunc={this.props.closeModal}
                    id="edit-transport-details-modal"
                >
                    <HandleError
                        isGetEquipmentDeliveriesError={this.props.isGetEquipmentDeliveriesError}
                    />
                    <EditEquipmentDeliveriesTable
                        ids={this.props.ids}
                        isAir={this.props.isAir}
                    />
                    <SButtonWrapper show={this.props.ids.length === 1}>
                        <SButton buttonType={ButtonType.Transparent} onClick={() => this.toggleSections()} id="expand-sections">
                            {updateButtonOnAccordionsChange(this.state)}
                        </SButton>
                    </SButtonWrapper>
                    <Form onSubmit={this.props.handleSubmit(this.onSubmit)}>
                        <Countable getNumberOfChildrens={this.getNumberOfSections}>
                            {this.props.ids.length === 1 && <>
                                <Accordion
                                    text="Documents"
                                    collapse={this.state.accordion_1}
                                    onClick={() => this.setCollapseStatus(1)}
                                >
                                    <TripleGrid>
                                        <FormSelect
                                            options={boolOptions}
                                            name="documentsPARNRequired"
                                            label="PARN Received"
                                        />
                                        <FormSelect
                                            options={boolOptions}
                                            name="documentsCRMRequired"
                                            label="CMR Required"
                                        />
                                    </TripleGrid>
                                </Accordion>

                                {Array.isArray(this.state.hbLs) &&
                                    <Accordion
                                        text="HBL/FCR details"
                                        collapse={this.state.accordion_2}
                                        onClick={() => this.setCollapseStatus(2)}
                                    >
                                        <FieldArray<WrappedFieldArrayProps<IPlanningHBL>> name="hbLs" component={HBLFieldArray}/>
                                    </Accordion>
                                }

                                <Accordion
                                    text="Dangerous goods"
                                    collapse={this.state.accordion_3}
                                    onClick={() => this.setCollapseStatus(3)}
                                >
                                    <TripleGrid>
                                        <FormSelect
                                            options={boolOptions}
                                            name="isDangerousGoods"
                                            label="Dangerous goods"
                                        />
                                        <FormInput
                                            label="UNNO"
                                            name="dangerousGoodsUNNO"
                                        />
                                        <FormSelect
                                            options={[
                                                { value: '', optionText: 'Select value...' },
                                                { value: '1', optionText: 'Class 1'},
                                                { value: '2', optionText: 'Class 2'}
                                            ]}
                                            name="dangerousGoodsClass"
                                            label="IMO class"
                                        />
                                    </TripleGrid>
                                    <TripleGrid>
                                        <FormInput
                                            label="Description"
                                            name="dangerousGoodsDescription"
                                        />
                                        <FormSelect
                                            options={boolOptions}
                                            name="isDangerousGoodsADR"
                                            label="ADR Required"
                                        />

                                    </TripleGrid>
                                </Accordion>
                            </>}
                        </Countable>
                        <TripleGrid>
                            <FormTextArea
                                name="comments"
                                label="Comments"
                                id="comment"
                            />
                        </TripleGrid>
                    </Form>
                </ModalScreen>
            </React.Fragment>
        );
    }

    private onSubmit = async (fields: IEditDeliveryDetailsForm) => {
        if(this.props.ids.length === 1) {
            await this.props.updateEquipmentDetails({
                ...fields,
                equipmentId: this.props.ids[0],
            }, !!this.props.isAir);

            if(fields.comments) {
                await this.props.addNewComment({
                    objectId: this.props.ids[0],
                    content: fields.comments,
                    objectType: DeliveryPlanningTypes.Equipment,
                }, !!this.props.isAir);
            }
            await this.props.fetchComments(DeliveryPlanningTypes.Equipment, this.props.ids[0], !!this.props.isAir);
            await this.props.closeModal();

            if(this.props.isAir) {
                if(this.props.isDetails) {
                    await this.props.getHawbDetails(this.props.ids[0]);
                } else {
                    await this.props.getHAWBList();
                }
            } else {
                if(this.props.isDetails) {
                    await this.props.getEquipmentDetails(this.props.ids[0]);
                } else {
                    await this.props.getEquipments();
                }
            }
        } else {
            await this.props.closeModal();
        }
    };

    private toggleSections = () => {
        const collapseStatus: boolean[] = [];

        for (const property in this.state) {
            if(property.includes('accordion_')) {
                collapseStatus.push(this.state[property]);
            }
        }

        const countTrue = count(collapseStatus, true);
        const countFalse = count(collapseStatus, false);

        if(countTrue > countFalse && countTrue === collapseStatus.length) {
            this.setState({
                accordion_1: false,
                accordion_2: false,
                accordion_3: false,
                collapseSections: false
            });
        } else if(countTrue < countFalse && countFalse === collapseStatus.length) {
            this.setState({
                accordion_1: true,
                accordion_2: true,
                accordion_3: true,
                collapseSections: true
            });
        } else if(countTrue > countFalse && countTrue !== collapseStatus.length) {
            this.setState({
                accordion_1: true,
                accordion_2: true,
                accordion_3: true,
                collapseSections: true
            });
        } else if(countTrue < countFalse && countFalse !== collapseStatus.length) {
            this.setState({
                accordion_1: false,
                accordion_2: false,
                accordion_3: false,
                collapseSections: false
            });
        } else if(countTrue === countFalse ) {
            this.setState({
                accordion_1: true,
                accordion_2: true,
                accordion_3: true,
                collapseSections: true
            });
        }
    };

    private getNumberOfSections = (data: boolean[]) => {
        data.forEach((val: boolean, i: number) => {
            this.setState({
                [`accordion_${i+1}`]: val
            });
        });
    };

    private setCollapseStatus = (sectionNumber: number) => this.setState((state: any) => ({
        [`accordion_${sectionNumber}`]: !state[`accordion_${sectionNumber}`]
    }));
}

const mapStateToProps = (state: IStore, ownProps: IEditDeliveryDetailsModalOwnProps): IMapStateToProps => {
    const {
        equipmentDeliveries,
        getEquipmentDeliveriesError,
        isGetEquipmentDeliveriesPending,
        isUpdateEquipmentDetailsPending,
    } = state.planning;
    const { addCommentLoading } = state.timeline;
    return {
        equipmentDeliveries,
        isGetEquipmentDeliveriesError: !!getEquipmentDeliveriesError,
        isLoading: addCommentLoading || isGetEquipmentDeliveriesPending || isUpdateEquipmentDetailsPending,
    }
};

const mapDispatchToProps = (dispatch: ThunkDispatch<any, any, Action>): IDispatch => ({
    getEquipmentDeliveries: (id: number) => dispatch(getEquipmentDeliveries(id, false)),
    updateEquipmentDetails: (fields: object, isAir: boolean) => dispatch(updateEquipmentDetails(fields, isAir)),
    addNewComment: (body: any, isAir: boolean) => dispatch(addNewComment(body, isAir, Capability.DeliveryPlanning)),
    fetchComments: (objectType: string, id: number, isAir: boolean) => dispatch(fetchComments({objectType, id, isAir, capability: Capability.DeliveryPlanning})),
    submit: () => dispatch(submit(_EditTransportationDetailsModal.FORM_NAME)),
    resetUpdateEquipmentDetailsError: () => dispatch(resetUpdateEquipmentDetailsError()),
    getEquipments: () => dispatch(getEquipmentsList()),
    getHAWBList: () => dispatch(getHAWBList()),
    getHawbDetails: (id: number) => dispatch(getHAWBDetails(id)),
    getEquipmentDetails: (id: number) => dispatch(getEquipmentDetails(id)),
});

export const EditTransportationDetailsModal = compose(
    reduxForm<IEditDeliveryDetailsForm, IEditDeliveryDetailsModalOwnProps>({
        form: _EditTransportationDetailsModal.FORM_NAME,
    }),
    connect<IMapStateToProps, IDispatch, IEditDeliveryDetailsModalOwnProps, IStore>(mapStateToProps, mapDispatchToProps),
)(_EditTransportationDetailsModal);
