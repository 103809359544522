import React from 'react';
import { DataTable } from 'components/data-table/DataTable';
import { ColumnFactory } from 'components/data-table/ColumnFactory';
import { AddNewVesselPopup } from 'modules/tracking/containers/components/AddNewVesselPopup';
import { EditCblDetailsForm } from 'modules/tracking/containers/components/EditCblDetailsForm';
import { IWithChildrenCheckboxesProps, withChildrenCheckboxes } from '../../../../HOC/withChildrenCheckboxes';
import { NoDateModal } from 'modules/modals/components/NoDateModal';

interface IEditCBLDetailsPopupTableOwnProps {
    data: any[];
    blId: number;
    editVessel: (fields: any) => Promise<void>;
    refetchFunction: () => Promise<void>;
    isAir: boolean;
    isAnySelected: (list: any[]) => void;
}

interface IEditCBLDetailsPopupTable extends IEditCBLDetailsPopupTableOwnProps, IWithChildrenCheckboxesProps {}

interface IEditCBLDetailsPopupTableState {
    isAddVesselModalOpened: boolean;
    isConfirmationPopupOpen: boolean;
    fieldsToSend: any;
}

class _EditCBLDetailsPopupTable extends React.Component<IEditCBLDetailsPopupTable, IEditCBLDetailsPopupTableState> {
    public state: IEditCBLDetailsPopupTableState = {
        isAddVesselModalOpened: false,
        isConfirmationPopupOpen: false,
        fieldsToSend: null,
    };

    public render() {
        const titleColumn = this.props.isAir ? ColumnFactory.standardColumnOld('FlightNumber', 'Flight number') : ColumnFactory.standardColumnOld('vesselName', 'Vessel name');
        this.props.isAnySelected(this.props.checkedChildren);

        return (
            <React.Fragment>
                <section className="page-container">
                    <DataTable
                        data={this.props.data}
                        columns={[
                            ColumnFactory.checkBoxColumn(this.props.onToggleChildrenCheckbox, () => null, false),
                            titleColumn,
                            ColumnFactory.standardColumnOld('loadingPort', 'POL'),
                            ColumnFactory.standardColumnOld('destinationPort', 'POD'),
                            ColumnFactory.dateColumnOld('etd', 'ETD'),
                            ColumnFactory.dateColumnOld('currentETA', 'ETA')
                        ]}
                    />
                </section>
                <EditCblDetailsForm
                    onSubmit={this.handleSubmit}
                    initialValues={this.getInitialValues()}
                    isAir={this.props.isAir}
                />
                <AddNewVesselPopup
                    onAddSuccess={this.onAddSuccess}
                    closeModal={this.toggleAddVesselButton}
                    visible={this.state.isAddVesselModalOpened}
                    blId={this.props.blId}
                    isAir={this.props.isAir}
                />

                <NoDateModal
                    visible={this.state.isConfirmationPopupOpen}
                    onClose={() => this.setState({isConfirmationPopupOpen: false})}
                    onAccept={() => this.props.editVessel(this.state.fieldsToSend)}
                />
            </React.Fragment>
        );
    }

    private handleSubmit = (fields: any) => {
        const previousCblDetails = this.getInitialValues();
        this.setState({ fieldsToSend: fields });

        if (
            (!fields.ATA && previousCblDetails.ATA) ||
            (!fields.ATD && previousCblDetails.ATD) ||
            (!fields.ETA && previousCblDetails.ETA) ||
            (!fields.ETD && previousCblDetails.ETD)
        ) {
            this.setState({ isConfirmationPopupOpen: true });
        } else {
            this.props.editVessel({
                ATA: fields.ATA,
                ATD: fields.ATD,
                ETA: fields.ETA,
                ETD: fields.ETD,
                FlightNumber: fields.FlightNumber,
                PODCode: fields.POD,
                POLCode: fields.POl,
                VesselName: fields.VesselName,
                VoyageId: fields.VoyageId,
                VoyageReference: fields.VoyageReference,
                MblId: fields.MblId,
                comment: fields.comment
            });
        }
    }

    private getInitialValues = () => {
        const selectedVessel = this.props.checkedChildren[0];

        if(selectedVessel) {
            return {
                ETD: selectedVessel.etd,
                ETA: selectedVessel.currentETA,
                ATD: selectedVessel.atd,
                ATA: selectedVessel.ata,
                POD: selectedVessel.destinationPort,
                POL: selectedVessel.loadingPort,
                VoyageId: selectedVessel.voyageId,
                MblId: selectedVessel.cblDetailsModalBLId,
                VesselName: selectedVessel.vesselName,
                VoyageReference: selectedVessel.voyageReference,
                comment: '',
                FlightNumber: selectedVessel.FlightNumber,
            }
        }
        return {};
    };

    private onAddSuccess = () => this.props.refetchFunction();

    private toggleAddVesselButton = () => this.setState((state) => ({
        isAddVesselModalOpened: !state.isAddVesselModalOpened,
    }));
}

export const EditCBLDetailsPopupTable = withChildrenCheckboxes<IEditCBLDetailsPopupTableOwnProps>(_EditCBLDetailsPopupTable, 'voyageId', true);
