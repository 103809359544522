import React from 'react';
import { Redirect, Switch } from 'react-router';
import { ILink } from 'components/sub-navigation/models/Link';
import { SubNavigationVertical } from 'components/sub-navigation-vertical';
import { Globals } from 'views/exception-management/settings/container-tracking/containers/Globals';
import { ReasonCodes } from 'views/exception-management/settings/container-tracking/containers/ReasonCodes';
import { RouteWithPermissions } from 'containers/route-with-permissions';
import { BUSINNESS_FUNCTIONS } from 'constants/roles';
import { Carriers } from './containers/Carriers';

export class ContainerTracking extends React.Component {
    private subLinks: ILink[] = [
        {
            to: 'globals',
            linkText: 'Globals'
        },
        {
            to: 'reason-codes',
            linkText: 'Reason codes'
        },
        {
            to: 'carriers',
            linkText: 'Carriers'
        },
    ];

    public render() {
        return (
            <div className="grid-wrapper">
                <div className="col-10">
                    <SubNavigationVertical
                        links={this.subLinks}
                    />
                </div>
                <div className="col-90">
                    <Switch>
                        <RouteWithPermissions
                            path="/exception-management/settings/container-tracking/globals"
                            component={Globals}
                            functions={[ BUSINNESS_FUNCTIONS.CONFIGURATION_CONTAINER_TRACKING ]}
                        />
                        <RouteWithPermissions
                            path="/exception-management/settings/container-tracking/reason-codes"
                            component={ReasonCodes}
                            functions={[ BUSINNESS_FUNCTIONS.CONFIGURATION_CONTAINER_TRACKING ]}
                        />
                        <RouteWithPermissions
                            path="/exception-management/settings/container-tracking/carriers"
                            component={Carriers}
                            functions={[ BUSINNESS_FUNCTIONS.CONFIGURATION_CONTAINER_TRACKING ]}
                        />
                        <Redirect to="/exception-management/settings/container-tracking/reason-codes" />
                    </Switch>
                </div>
            </div>
        );
    }
}
