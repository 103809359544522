import { Column } from 'react-table';
import React from 'react';
import { ColumnFactory } from 'components/data-table/ColumnFactory';
import { formatDate } from 'utilities/util';

export const dateColumn = (accessor: string, name: string, config: string = 'DD-MM-YYYY'): Column => {
    const cell = (rowInfo: { value: string }) => (
        <div>
            {formatDate(rowInfo.value, config)}
        </div>
    );
    return ColumnFactory.getColumnOptions(accessor, name, cell);
};