import React from 'react';
import { Redirect, Switch } from 'react-router';
import { ILink } from 'components/sub-navigation/models/Link';
import { SubNavigationVertical } from 'components/sub-navigation-vertical';
import { DerigConfiguration } from 'views/exception-management/settings/delivery-planning/containers/DerigConfiguration';
import { HaulierConfiguration } from 'views/exception-management/settings/delivery-planning/containers/HaulierConfiguration';
import { Globals } from 'views/exception-management/settings/delivery-planning/containers/Globals';
import { ReasonCodes } from 'views/exception-management/settings/delivery-planning/containers/ReasonCodes';
import { RouteWithPermissions } from 'containers/route-with-permissions';
import { BUSINNESS_FUNCTIONS } from 'constants/roles';

export class DeliveryPlanning extends React.Component {
    private subLinks: ILink[] = [
        {
            to: 'globals',
            linkText: 'Globals'
        },
        {
            to: 'reason-codes',
            linkText: 'Reason codes'
        },
        {
            to: 'derig',
            linkText: 'Derig'
        },
        {
            to: 'haulier',
            linkText: 'Haulier'
        },
        // {
        //     to: 'customers',
        //     linkText: 'Customers'
        // }
    ];

    public render() {
        return (
            <div className="grid-wrapper">
                <div className="col-10">
                    <SubNavigationVertical
                        links={this.subLinks}
                    />
                </div>
                <div className="col-90">
                    <Switch>
                        <RouteWithPermissions
                            path="/exception-management/settings/delivery-planning/globals"
                            component={Globals}
                            functions={[ BUSINNESS_FUNCTIONS.CONFIGURATION_DELIVERY_PLANNING ]}
                        />
                        <RouteWithPermissions
                            path="/exception-management/settings/delivery-planning/reason-codes"
                            component={ReasonCodes}
                            functions={[ BUSINNESS_FUNCTIONS.CONFIGURATION_DELIVERY_PLANNING ]}
                        />
                        <RouteWithPermissions
                            path="/exception-management/settings/delivery-planning/derig"
                            component={DerigConfiguration}
                            functions={[ BUSINNESS_FUNCTIONS.CONFIGURATION_DELIVERY_PLANNING ]}
                        />
                        <RouteWithPermissions
                            path="/exception-management/settings/delivery-planning/haulier"
                            component={HaulierConfiguration}
                            functions={[ BUSINNESS_FUNCTIONS.CONFIGURATION_DELIVERY_PLANNING ]}
                        />
                        {/* <RouteWithPermissions
                            path="/exception-management/settings/delivery-planning/customers"
                            component={Customers}
                            functions={[ BUSINNESS_FUNCTIONS.CONFIGURATION_DELIVERY_PLANNING ]}
                        /> */}
                        <Redirect to="/exception-management/settings/delivery-planning/reason-codes" />
                    </Switch>
                </div>
            </div>
        );
    }
}
