import React from 'react';
import { connect } from 'react-redux';
import { Action } from 'redux';
import { ThunkDispatch } from 'redux-thunk';
import {
    fetchCustomsGlobalsConfiguration,
    updateCustomsGlobalSettings
} from 'actions/settings';
import { GlobalsTable } from 'modules/settings/customs/containers/GlobalsTable';
import { IStore } from 'reducers/index';
import { IGlobalSettings } from 'reducers/settings/models';

interface IDispatch {
    fetchCustomsGlobalsConfiguration: () => {};
    updateCustomsGlobalSettings: (configuration: IGlobalSettings) => void;
}

interface IMapStateToProps {
    globalSettings: any[];
    isCustomsGlobalSettingsPending: boolean;
}

interface IGlobalsProps extends IMapStateToProps, IDispatch {}

class _Globals extends React.Component<IGlobalsProps> {
    public componentDidMount() {
        this.props.fetchCustomsGlobalsConfiguration()
    }

    public render() {
        return(
            <GlobalsTable 
                isLoading={this.props.isCustomsGlobalSettingsPending}
                data={this.props.globalSettings}
                updateSettings={this.props.updateCustomsGlobalSettings}
                refreshSettings={this.props.fetchCustomsGlobalsConfiguration}
            />
        )
    }
}

const mapStateToProps = (state: IStore): IMapStateToProps => ({
    globalSettings: state.settings.customsGlobalSettings,
    isCustomsGlobalSettingsPending: state.settings.fetchCustomsGlobalsSettingsPending
});

const mapDispatchToProps = (dispatch: ThunkDispatch<any, any, Action>): IDispatch => ({
    fetchCustomsGlobalsConfiguration: () => dispatch(fetchCustomsGlobalsConfiguration()),
    updateCustomsGlobalSettings: (configuration: IGlobalSettings) => dispatch(updateCustomsGlobalSettings(configuration))
});

export const Globals = connect(mapStateToProps, mapDispatchToProps)(_Globals);