import { Action } from 'redux';
import { TYPES } from 'action-types/settings';
import { TYPES as FILTER_TYPES } from 'action-types/filters';
import { ThunkDispatch } from 'redux-thunk';
import {callApiDelete, callApiGet, callApiPost, callApiPut} from 'utilities/apiCaller';
import API from 'constants/api';
import {
    ISettingsModel,
    ICreateSettingsModel,
    ICreateBroker,
    IUpdateBroker,
    IReasonCode
} from 'reducers/settings/models'
import { applyFilters, generateError, applyPageDetailsWithoutFilters } from 'utilities/util';
import { IStore } from 'reducers/index';
import { Dispatch } from 'redux';
import { ICarrier } from 'views/exception-management/settings/container-tracking/containers/Carriers';
import { ISettingsAction } from 'reducers/settings';
import { IOfficePrefix } from 'reducers/fact/models';

export const fetchDeliveryPlanningDerigSettings = (): ThunkDispatch<any, any, Action> => {
    return async (dispatch: ThunkDispatch<any, any, Action>): Promise<void> => {
        dispatch({
            type: TYPES.FETCH_DELIVERY_PLANNING_DERIG_SETTINGS.PENDING,
        });
        try {
            const res = await callApiPost(API.Settings.FetchDeliveryStatusDeRigConfigurations,{
                getAllDetails: true,
                page: 1,
                pageSize: 100,
            });
            dispatch({
                type: TYPES.FETCH_DELIVERY_PLANNING_DERIG_SETTINGS.FULFILLED,
                settings: res.data.result,
            });
        } catch (e) {
            dispatch({
                type: TYPES.FETCH_DELIVERY_PLANNING_DERIG_SETTINGS.REJECTED,
            });
        }
    }
};

export const fetchDeliveryPlanningHaulierSettings = (getAllDetails = false): ThunkDispatch<any, any, Action> => {
    return async (dispatch: ThunkDispatch<any, any, Action>): Promise<void> => {
        dispatch({
            type: TYPES.FETCH_DELIVERY_PLANNING_HAULIER_SETTINGS.PENDING,
        });
        try {
            const res = await callApiPost(API.Settings.FetchDeliveryPlanningHaulierSettings, {
                getAllDetails,
                page: 1,
                pageSize: 100
            });
            dispatch({
                type: TYPES.FETCH_DELIVERY_PLANNING_HAULIER_SETTINGS.FULFILLED,
                settings: res.data.result,
            });
        } catch (e) {
            dispatch({
                type: TYPES.FETCH_DELIVERY_PLANNING_HAULIER_SETTINGS.REJECTED,
            });
        }
    }
};

export const createDeRigConfiguration = (configuration: ICreateSettingsModel) => {
    return async (dispatch: ThunkDispatch<any, any, Action>): Promise<void> => {
        dispatch({
            type: TYPES.CREATE_DELIVERY_PLANNING_DERIG_SETTINGS.PENDING,
        });
        try {
            await callApiPost(API.Settings.CreateDeliveryPlanningDeRigConfiguration, configuration);
            dispatch({
                type: TYPES.CREATE_DELIVERY_PLANNING_DERIG_SETTINGS.FULFILLED,
            });
        } catch (e) {
            dispatch({
                type: TYPES.CREATE_DELIVERY_PLANNING_DERIG_SETTINGS.REJECTED,
            });
        }
    }
};

export const createHauliersConfiguration = (configuration: ICreateSettingsModel) => {
    return async (dispatch: ThunkDispatch<any, any, Action>): Promise<void> => {
        dispatch({
            type: TYPES.CREATE_DELIVERY_PLANNING_DERIG_SETTINGS.PENDING,
        });
        try {
            await callApiPost(API.Settings.CreateDeliveryPlanningHauliersConfiguration, configuration);
            dispatch({
                type: TYPES.CREATE_DELIVERY_PLANNING_DERIG_SETTINGS.FULFILLED,
            });
        } catch (e) {
            dispatch({
                type: TYPES.CREATE_DELIVERY_PLANNING_DERIG_SETTINGS.REJECTED,
            });
        }
    }
};

export const deleteDeliveryPlanningDerigConfiguration = (deRigConfigurationId: string) => {
    return async (dispatch: ThunkDispatch<any, any, Action>): Promise<void> => {
        dispatch({
            type: TYPES.DELETE_DELIVERY_PLANNING_DERIG_SETTINGS.PENDING,
        });
        try {
            await callApiDelete(API.Settings.DeleteDeliveryPlanningDeRigConfiguration, { data: { deRigConfigurationId }});
            dispatch({
                type: TYPES.DELETE_DELIVERY_PLANNING_DERIG_SETTINGS.FULFILLED,
            });
        } catch (e) {
            dispatch({
                type: TYPES.DELETE_DELIVERY_PLANNING_DERIG_SETTINGS.REJECTED,
            });
        }
    }
};

export const deleteDeliveryPlanningHauliersConfiguration = (haulierConfigurationId: string) => {
    return async (dispatch: ThunkDispatch<any, any, Action>): Promise<void> => {
        dispatch({
            type: TYPES.DELETE_DELIVERY_PLANNING_HAULIERS_SETTINGS.PENDING,
        });
        try {
            await callApiDelete(API.Settings.DeleteDeliveryPlanningHaulierConfiguration, { data: { haulierConfigurationId }});
            dispatch({
                type: TYPES.DELETE_DELIVERY_PLANNING_HAULIERS_SETTINGS.FULFILLED,
            });
        } catch (e) {
            dispatch({
                type: TYPES.DELETE_DELIVERY_PLANNING_HAULIERS_SETTINGS.REJECTED,
            });
        }
    }
};

export const updateDeliveryPlanningDerigConfiguration = (configuration: ISettingsModel) => {
    return async (dispatch: ThunkDispatch<any, any, Action>): Promise<void> => {
        dispatch({
            type: TYPES.UPDATE_DELIVERY_PLANNING_DERIG_SETTINGS.PENDING,
        });
        try {
            await callApiPut(API.Settings.UpdateDeliveryPlanningDeRigConfiguration, configuration);
            dispatch({
                type: TYPES.UPDATE_DELIVERY_PLANNING_DERIG_SETTINGS.FULFILLED,
            });
        } catch (e) {
            dispatch({
                type: TYPES.UPDATE_DELIVERY_PLANNING_DERIG_SETTINGS.REJECTED,
            });
        }
    }
};

export const updateDeliveryPlanningHaulierConfiguration = (configuration: ISettingsModel) => {
    return async (dispatch: ThunkDispatch<any, any, Action>): Promise<void> => {
        dispatch({
            type: TYPES.UPDATE_DELIVERY_PLANNING_HAULIER_SETTINGS.PENDING,
        });
        try {
            await callApiPut(API.Settings.UpdateDeliveryPlanningHaulierConfiguration, configuration);
            dispatch({
                type: TYPES.UPDATE_DELIVERY_PLANNING_HAULIER_SETTINGS.FULFILLED,
            });
        } catch (e) {
            dispatch({
                type: TYPES.UPDATE_DELIVERY_PLANNING_HAULIER_SETTINGS.REJECTED,
            });
        }
    }
};

export const fetchContainerTrackingGlobalsConfiguration = () => {
    return async (dispatch: ThunkDispatch<any, any, Action>): Promise<void> => {
        dispatch({
            type: TYPES.FETCH_CONTAINER_TRACKING_GLOBALS_SETTINGS.PENDING,
        });
        try {
            const res = await callApiGet(API.Settings.FetchContainerTrackingGlobalSettings);
            dispatch({
                type: TYPES.FETCH_CONTAINER_TRACKING_GLOBALS_SETTINGS.FULFILLED,
                globalSettings: res.data
            });
        } catch (e) {
            dispatch({
                type: TYPES.FETCH_CONTAINER_TRACKING_GLOBALS_SETTINGS.REJECTED,
            });
        }
    }
};

export const fetchDeliveryPlanningGlobalsConfiguration = () => {
    return async (dispatch: ThunkDispatch<any, any, Action>): Promise<void> => {
        dispatch({
            type: TYPES.FETCH_DELIVERY_PLANNING_GLOBALS_SETTINGS.PENDING,
        });
        try {
            const res = await callApiGet(API.Settings.FetchDeliveryPlanningGlobalSettings);
            dispatch({
                type: TYPES.FETCH_DELIVERY_PLANNING_GLOBALS_SETTINGS.FULFILLED,
                globalSettings: res.data
            });
        } catch (e) {
            dispatch({
                type: TYPES.FETCH_DELIVERY_PLANNING_GLOBALS_SETTINGS.REJECTED,
            });
        }
    }
};

export const fetchCustomsGlobalsConfiguration = () => {
    return async (dispatch: ThunkDispatch<any, any, Action>): Promise<void> => {
        dispatch({
            type: TYPES.FETCH_CUSTOMS_GLOBALS_SETTINGS.PENDING,
        });
        try {
            const res = await callApiGet(API.Settings.FetchCustomsGlobalSettings);
            dispatch({
                type: TYPES.FETCH_CUSTOMS_GLOBALS_SETTINGS.FULFILLED,
                globalSettings: res.data
            });
        } catch (e) {
            dispatch({
                type: TYPES.FETCH_CUSTOMS_GLOBALS_SETTINGS.REJECTED,
            });
        }
    }
};

export const updateCustomsGlobalSettings = (body: any) => {
    return async (dispatch: ThunkDispatch<any, any, Action>): Promise<void> => {
        dispatch({
            type: TYPES.UPDATE_CUSTOMS_GLOBAL_SETTINGS.PENDING
        });
        try {
            const formatBody = [...body];
            await callApiPost(API.Settings.UpdateCustomsGlobalSettings, formatBody);
            dispatch({
                type: TYPES.UPDATE_CUSTOMS_GLOBAL_SETTINGS.FULFILLED,
            });
        } catch (e) {
            dispatch({
                type: TYPES.UPDATE_CUSTOMS_GLOBAL_SETTINGS.REJECTED
            });
        }
    }
}

export const updateContainerTrackingGlobalSettings = (body: any) => {
    return async (dispatch: ThunkDispatch<any, any, Action>): Promise<void> => {
        dispatch({
            type: TYPES.UPDATE_CONTAINER_TRACKING_GLOBAL_SETTINGS.PENDING
        });
        try {
            const formatBody = [...body];
            await callApiPost(API.Settings.UpdateContainerTrackingGlobalSettings, formatBody);
            dispatch({
                type: TYPES.UPDATE_CONTAINER_TRACKING_GLOBAL_SETTINGS.FULFILLED,
            });
        } catch (e) {
            dispatch({
                type: TYPES.UPDATE_CONTAINER_TRACKING_GLOBAL_SETTINGS.REJECTED
            });
        }
    }
};

export const fetchDeliveryPlanningReasonCodes = (group?: string) => {
    return async (dispatch: ThunkDispatch<any, any, Action>): Promise<void> => {
        dispatch({
            type: TYPES.FETCH_DELIVERY_PLANNING_REASON_CODES.PENDING
        });
        try {
            const param = group ? group : '';
            const res = await callApiGet(API.Settings.FetchDeliveryPlanningReasonCodes(param))
            dispatch({
                type: TYPES.FETCH_DELIVERY_PLANNING_REASON_CODES.FULFILLED,
                reasonCodes: res.data
            });
        } catch (e) {
            dispatch({
                type: TYPES.FETCH_DELIVERY_PLANNING_REASON_CODES.REJECTED,
                error: e
            });
            throw e;
        }
    }
}

export const updateDeliveryPlanningReasonCode = (id: number, body: any) => {
    return async (dispatch: ThunkDispatch<any, any, Action>): Promise<void> => {
        dispatch({
            type: TYPES.UPDATE_DELIVERY_PLANNING_REASON_CODES.PENDING
        });
        try {
            const res = await callApiPut(API.Settings.UpdateDeliveryPlanningReasonCode(id), body)
            dispatch({
                type: TYPES.UPDATE_DELIVERY_PLANNING_REASON_CODES.FULFILLED,
                reasonCode: res.data
            });
        } catch (e) {
            dispatch({
                type: TYPES.UPDATE_DELIVERY_PLANNING_REASON_CODES.REJECTED,
                error: e
            });
            throw e;
        }
    }
}

export const resetUpdateDeliveryPlanningReasonCodeError = () => (dispatch: Dispatch) => {
    dispatch({type: TYPES.UPDATE_DELIVERY_PLANNING_REASON_CODES.RESET_ERROR});
}

export const deleteDeliveryPlanningReasonCode = (id: number) => {
    return async (dispatch: ThunkDispatch<any, any, Action>): Promise<void> => {
        dispatch({type: TYPES.DELETE_DELIVERY_PLANNING_REASON_CODES.PENDING});
        try {
            const res = await callApiDelete(API.Settings.DeleteDeliveryPlanningReasonCode(id))
            dispatch({type: TYPES.DELETE_DELIVERY_PLANNING_REASON_CODES.FULFILLED, reasonCode: res.data});
        } catch (e) {
            dispatch({type: TYPES.DELETE_DELIVERY_PLANNING_REASON_CODES.REJECTED, error: e});
            throw e;
        }
        
    }
}

export const createDeliveryPlanningReasonCode = (body: any) => {
    return async (dispatch: ThunkDispatch<any, any, Action>): Promise<void> => {
        dispatch({type: TYPES.CREATE_DELIVERY_PLANNING_REASON_CODES.PENDING});
        try {
            const res = await callApiPost(API.Settings.CreateDeliveryPlanningReasonCode, body)
            dispatch({type: TYPES.CREATE_DELIVERY_PLANNING_REASON_CODES.FULFILLED, reasonCode: res.data});
        } catch (e) {
            const error = generateError(e);
            dispatch({type: TYPES.CREATE_DELIVERY_PLANNING_REASON_CODES.REJECTED, error});
            throw e;
        }
    }
}

export const resetCreateDeliveryPlanningReasonCodeError = () => (dispatch: Dispatch) => {
    dispatch({ type: TYPES.CREATE_DELIVERY_PLANNING_REASON_CODES.RESET_ERROR });
};

export const fetchContainerTrackingReasonCodes = (group?: string) => {
    return async (dispatch: ThunkDispatch<any, any, Action>): Promise<void> => {
        dispatch({type: TYPES.FETCH_CONTAINER_TRACKING_REASON_CODES.PENDING});
        try {
            const param = group ? group : '';
            const res = await callApiGet(API.Settings.FetchContainerTrackingReasonCodes(param))
            dispatch({type: TYPES.FETCH_CONTAINER_TRACKING_REASON_CODES.FULFILLED, reasonCodes: res.data});
        } catch (e) {
            dispatch({type: TYPES.FETCH_CONTAINER_TRACKING_REASON_CODES.REJECTED, error: e});
        }
    }
}

export const updateContainerTrackingReasonCode = (body: any) => {
    return async (dispatch: ThunkDispatch<any, any, Action>): Promise<void> => {
        dispatch({type: TYPES.UPDATE_CONTAINER_TRACKING_REASON_CODES.PENDING});
        try {
            const res = await callApiPut(API.Settings.UpdateContainerTrackingReasonCode, body)
            dispatch({type: TYPES.UPDATE_CONTAINER_TRACKING_REASON_CODES.FULFILLED, reasonCode: res.data});
        } catch (e) {
            dispatch({type: TYPES.UPDATE_CONTAINER_TRACKING_REASON_CODES.REJECTED, error: e});
        }
    }
}

export const resetUpdateContainerTrackingReasonCodeError = () => (dispatch: Dispatch) => {
    dispatch({ type: TYPES.UPDATE_CONTAINER_TRACKING_REASON_CODES.RESET_ERROR });
}

export const deleteContainerTrackingReasonCode = (id: number) => {
    return async (dispatch: ThunkDispatch<any, any, Action>): Promise<void> => {
        dispatch({type: TYPES.DELETE_CONTAINER_TRACKING_REASON_CODES.PENDING});
        try {
            const res = await callApiDelete(API.Settings.DeleteContainerTrackingReasonCode(id))
            dispatch({type: TYPES.DELETE_CONTAINER_TRACKING_REASON_CODES.FULFILLED, reasonCode: res.data});
        } catch (e) {
            dispatch({type: TYPES.DELETE_CONTAINER_TRACKING_REASON_CODES.REJECTED, error: e});
        }
    }
}

export const createContainerTrackingReasonCode = (body: any) => {
    return async (dispatch: ThunkDispatch<any, any, Action>): Promise<void> => {
        dispatch({type: TYPES.CREATE_CONTAINER_TRACKING_REASON_CODES.PENDING});
        try {
            const res = await callApiPost(API.Settings.CreateContainerTrackingReasonCode, body);
            dispatch({type: TYPES.CREATE_CONTAINER_TRACKING_REASON_CODES.FULFILLED, reasonCode: res.data});
        } catch (e) {
            const error = generateError(e);
            dispatch({type: TYPES.CREATE_CONTAINER_TRACKING_REASON_CODES.REJECTED, error});
            throw e;
        }
    }
};

export const resetCreateContainerTrackingReasonCodeError = () => (dispatch: Dispatch) => {
    dispatch({ type: TYPES.CREATE_CONTAINER_TRACKING_REASON_CODES.RESET_ERROR });
};

export const fetchCustomsReasonCodes = (group?: string) => {
    return async (dispatch: ThunkDispatch<any, any, Action>): Promise<void> => {
        dispatch({type: TYPES.FETCH_CUSTOMS_REASON_CODES.PENDING});
        try {
            const param = group ? group : '';
            const res = await callApiGet(API.Settings.FetchCustomsReasonCodes(param))
            dispatch({type: TYPES.FETCH_CUSTOMS_REASON_CODES.FULFILLED, reasonCodes: res.data});
        } catch (e) {
            dispatch({type: TYPES.FETCH_CUSTOMS_REASON_CODES.REJECTED, error: e});
        }
    }
}

export const updateCustomsReasonCode = (body: any) => {
    return async (dispatch: ThunkDispatch<any, any, Action>): Promise<void> => {
        dispatch({type: TYPES.UPDATE_CUSTOMS_REASON_CODES.PENDING});
        try {
            const res = await callApiPut(API.Settings.UpdateCustomsReasonCode, body)
            dispatch({type: TYPES.UPDATE_CUSTOMS_REASON_CODES.FULFILLED, reasonCode: res.data});
        } catch (e) {
            dispatch({type: TYPES.UPDATE_CUSTOMS_REASON_CODES.REJECTED, error: e});
        }
    }
}

export const resetUpdateReasonCodeError = () => {
    return (dispatch: Dispatch): void => {
        dispatch({ type: TYPES.UPDATE_CUSTOMS_REASON_CODES.RESET_ERROR });
    }
}

export const deleteCustomsReasonCode = (id: number) => {
    return async (dispatch: ThunkDispatch<any, any, Action>): Promise<void> => {
        dispatch({type: TYPES.DELETE_CUSTOMS_REASON_CODES.PENDING});
        try {
            const res = await callApiDelete(API.Settings.DeleteCustomsReasonCode(id))
            dispatch({type: TYPES.DELETE_CUSTOMS_REASON_CODES.FULFILLED, reasonCode: res.data});
        } catch (e) {
            dispatch({type: TYPES.DELETE_CUSTOMS_REASON_CODES.REJECTED, error: e});
        }
    }
}

export const createCustomsReasonCode = (body: any) => {
    return async (dispatch: ThunkDispatch<any, any, Action>): Promise<void> => {
        dispatch({type: TYPES.CREATE_CUSTOMS_REASON_CODES.PENDING});
        try {
            const res = await callApiPost(API.Settings.CreateCustomsReasonCode, body);
            dispatch({type: TYPES.CREATE_CUSTOMS_REASON_CODES.FULFILLED, reasonCode: res.data});
        } catch (e) {
            const error = generateError(e);
            dispatch({type: TYPES.CREATE_CUSTOMS_REASON_CODES.REJECTED, error});
            throw e;
        }
    }
};

export const resetCreateCustomsReasonCodeError = () => (dispatch: Dispatch) => {
    dispatch({ type: TYPES.CREATE_CUSTOMS_REASON_CODES.RESET_ERROR });
};

export const addCustomerSettings = (customer: object) => {
    return async (dispatch: ThunkDispatch<any, any, Action>): Promise<void> => {
        dispatch({type: TYPES.CREATE_DELIVERY_PLANNING_CUSTOMER.PENDING});
        try {
            await callApiPost(API.Settings.CreateDeliveryPlanningCustomer, {
                inputModel: customer
            });
            dispatch({type: TYPES.CREATE_DELIVERY_PLANNING_CUSTOMER.FULFILLED})
        } catch (e) {
            dispatch({type: TYPES.CREATE_DELIVERY_PLANNING_CUSTOMER.REJECTED});
        }
    }
};

export const getCustomerSettings = (pageNumber?: number, pageSize?: number) => {
    return async (dispatch: ThunkDispatch<any, any, Action>, getState: () => IStore): Promise<void> => {
        dispatch({type: TYPES.GET_DELIVERY_PLANNING_CUSTOMERS.PENDING});
        const res = await callApiPost(API.Settings.GetDeliveryPlanningCustomer, applyFilters(getState, {}, {
            PageNumber: pageNumber,
            Page: pageNumber,
            PageSize: pageSize
        }));
        try {
            const customerConfigurations = res.data;
            dispatch({type: TYPES.GET_DELIVERY_PLANNING_CUSTOMERS.FULFILLED, customerConfigurations,});
            dispatch({type: FILTER_TYPES.SET_TOTAL_COUNT, totalCount: res.data.totalCount,});
        } catch (e) {
            dispatch({type: TYPES.GET_DELIVERY_PLANNING_CUSTOMERS.REJECTED});
        }
    }
};

export const editCustomerSettings = (customer: object) => {
    return async (dispatch: ThunkDispatch<any, any, Action>): Promise<void> => {
        dispatch({type: TYPES.EDIT_DELIVERY_PLANNING_CUSTOMER.PENDING});
        try {
            await callApiPut(API.Settings.PutDeliveryPlanningCustomer, {
                InputModel: customer
            });
            dispatch({type: TYPES.EDIT_DELIVERY_PLANNING_CUSTOMER.FULFILLED})
        } catch (e) {
            dispatch({type: TYPES.EDIT_DELIVERY_PLANNING_CUSTOMER.REJECTED});
        }
    }
};

export const deleteCustomerSetting = (CustomerConfigurationId: number) => {
    return async (dispatch: ThunkDispatch<any, any, Action>): Promise<void> => {
        dispatch({type: TYPES.DELETE_DELIVERY_PLANNING_CUSTOMER.PENDING});
        try {
            await callApiDelete(API.Settings.DeleteCustomerConfiguration, {
                data: { CustomerConfigurationId },
            });
            dispatch({type: TYPES.DELETE_DELIVERY_PLANNING_CUSTOMER.FULFILLED})
        } catch (e) {
            dispatch({type: TYPES.DELETE_DELIVERY_PLANNING_CUSTOMER.REJECTED});
        }
    }
};


export const fetchCustomsBrokerSettings = (getAllDetails = false): ThunkDispatch<any, any, Action> => {
    return async (dispatch: ThunkDispatch<any, any, Action>): Promise<void> => {
        dispatch({type: TYPES.FETCH_CUSTOMS_BROKER_SETTINGS.PENDING});
        try {
            const res = await callApiPost(API.Settings.FetchCustomsBrokerSettings, {
                getAllDetails,
                page: 1,
                pageSize: 100
            });
            dispatch({
                type: TYPES.FETCH_CUSTOMS_BROKER_SETTINGS.FULFILLED,
                settings: res.data.result.map((broker: any) => ({ ...broker, id: broker.brokerId })),
            });
        } catch (e) {
            dispatch({type: TYPES.FETCH_CUSTOMS_BROKER_SETTINGS.REJECTED});
        }
    }
};


export const createCustomsBrokerConfiguration = (configuration: ICreateBroker) => {
    return async (dispatch: ThunkDispatch<any, any, Action>): Promise<void> => {
        dispatch({type: TYPES.CREATE_DELIVERY_PLANNING_DERIG_SETTINGS.PENDING});
        try {
            await callApiPost(API.Settings.CreateCustomsBrokersConfiguration, configuration);
            dispatch({type: TYPES.CREATE_DELIVERY_PLANNING_DERIG_SETTINGS.FULFILLED});
        } catch (e) {
            dispatch({type: TYPES.CREATE_DELIVERY_PLANNING_DERIG_SETTINGS.REJECTED});
        }
    }
};

export const deleteCustomsBrokersConfiguration = (brokerId: string) => {
    return async (dispatch: ThunkDispatch<any, any, Action>): Promise<void> => {
        dispatch({type: TYPES.DELETE_CUSTOMS_BROKERS_SETTINGS.PENDING});
        try {
            await callApiDelete(API.Settings.DeleteCustomsBrokerConfiguration, { data: { IDs: [brokerId] }});
            dispatch({type: TYPES.DELETE_CUSTOMS_BROKERS_SETTINGS.FULFILLED});
        } catch (e) {
            dispatch({type: TYPES.DELETE_CUSTOMS_BROKERS_SETTINGS.REJECTED});
        }
    }
};

export const updateCustomsBrokerConfiguration = (configuration: IUpdateBroker) => {
    return async (dispatch: ThunkDispatch<any, any, Action>): Promise<void> => {
        dispatch({type: TYPES.UPDATE_CUSTOMS_BROKER_SETTINGS.PENDING});
        try {
            await callApiPut(API.Settings.UpdateCustomsBrokerConfiguration, configuration);
            dispatch({type: TYPES.UPDATE_CUSTOMS_BROKER_SETTINGS.FULFILLED});
        } catch (e) {
            dispatch({type: TYPES.UPDATE_CUSTOMS_BROKER_SETTINGS.REJECTED});
        }
    }
};

export const fetchContarinerTrackingCarriers = () => async (
    dispatch: ThunkDispatch<any, any, ISettingsAction>,
    getState: () => IStore,
) => {
    const res = await callApiPost(
        API.Settings.FetchContainerTrackingCarrierSettings,
        applyPageDetailsWithoutFilters(getState),
    );
    dispatch({ type: TYPES.FETCH_CONTAINER_TRACKING_CARRIER_SETTINGS.FULFILLED, carriers: res.data.result });
    dispatch({ type: FILTER_TYPES.SET_TOTAL_COUNT, totalCount: res.data.totalCount });
};

export const addContarinerTrackingCarrier = (carrier: ICarrier) => async (dispatch: ThunkDispatch<any, any, ISettingsAction>) => {
    dispatch({ type: TYPES.ADD_CONTAINER_TRACKING_CARRIER_SETTINGS.PENDING });
    try {
        await callApiPost(API.Settings.AddContainerTrackingCarrierSettings, carrier);
        dispatch({ type: TYPES.ADD_CONTAINER_TRACKING_CARRIER_SETTINGS.FULFILLED })
    } catch (e) {
        dispatch({ type: TYPES.ADD_CONTAINER_TRACKING_CARRIER_SETTINGS.REJECTED, error: generateError(e) });
    }
}

export const updateContarinerTrackingCarrier = (carrier: ICarrier) => async (dispatch: ThunkDispatch<any, any, ISettingsAction>) => {
    dispatch({ type: TYPES.UPDATE_CONTAINER_TRACKING_CARRIER_SETTINGS.PENDING });
    try {
        await callApiPut(API.Settings.UpdateContainerTrackingCarrierSettings, carrier);
        dispatch({ type: TYPES.UPDATE_CONTAINER_TRACKING_CARRIER_SETTINGS.FULFILLED })
    } catch (e) {
        dispatch({ type: TYPES.UPDATE_CONTAINER_TRACKING_CARRIER_SETTINGS.REJECTED, error: generateError(e) });
    }
}

export const deleteContarinerTrackingCarrier = (carrier: ICarrier) => async (dispatch: ThunkDispatch<any, any, ISettingsAction>) => {
    dispatch({ type: TYPES.DELETE_CONTAINER_TRACKING_CARRIER_SETTINGS.PENDING });
    try {
        await callApiDelete(API.Settings.DeleteContainerTrackingCarrierSettings, { data: carrier });
        dispatch({ type: TYPES.DELETE_CONTAINER_TRACKING_CARRIER_SETTINGS.FULFILLED })
    } catch (e) {
        dispatch({ type: TYPES.DELETE_CONTAINER_TRACKING_CARRIER_SETTINGS.REJECTED, error: generateError(e) });
    }
}

export const resetContarinerTrackingCarrierError = () => async (dispatch: ThunkDispatch<any, any, ISettingsAction>) => {
    dispatch({ type: TYPES.UPDATE_CONTAINER_TRACKING_CARRIER_SETTINGS.RESET_ERROR });
}

export const getFactOfficePrefixes = (pageNumber?: number, pageSize?: number) => async (dispatch: ThunkDispatch<any, any, ISettingsAction>, getState: () => IStore) => {
    const res = await callApiPost(API.Settings.FetchFactOfficePrefixes, applyPageDetailsWithoutFilters(getState, { isAirInBody: false, isAirInFilters: false }, {
        Page: pageNumber,
        PageNumber: pageNumber,
        PageSize: pageSize
    }), { headers: { 'TransportMode': 'Ocean' } });

    dispatch({ type: TYPES.FETCH_FACT_OFFICE_PREFIXES, officePrefixes: res.data });
    dispatch({ type: FILTER_TYPES.SET_TOTAL_COUNT, totalCount: res.data.totalCount });
}

export const addFactOfficePrefix = (data: IOfficePrefix) => async () => {
    await callApiPost(API.Settings.AddFactOfficePrefix, data, { headers: { 'TransportMode': 'Ocean' } });
}

export const updateFactOfficePrefix = (data: IOfficePrefix) => async () => {
    await callApiPost(API.Settings.UpdateFactOfficePrefixes, data, { headers: { 'TransportMode': 'Ocean' } });
}

export const deleteFactOfficePrefix = (data: IOfficePrefix) => async () => {
    await callApiPost(API.Settings.DeleteFactOfficePrefix, data, { headers: { 'TransportMode': 'Ocean' } });
}

export const fetchDemurrageAndDetentionReasonCodes = () => {
    return async (dispatch: ThunkDispatch<any, any, Action>): Promise<void> => {
        dispatch({type: TYPES.FETCH_DEMURRAGE_AND_DETENTION_REASON_CODES.PENDING});
        try {
            const res = await callApiGet(API.Settings.FetchDemurrageAndDetentionReasonCodes)
            dispatch({type: TYPES.FETCH_DEMURRAGE_AND_DETENTION_REASON_CODES.FULFILLED, reasonCodes: res.data});
        } catch (e) {
            dispatch({type: TYPES.FETCH_DEMURRAGE_AND_DETENTION_REASON_CODES.REJECTED, error: e});
        }
    }
}

export const updateDemurrageAndDetentionReasonCodes = (body: any) => {
    return async (dispatch: ThunkDispatch<any, any, Action>): Promise<void> => {
        dispatch({type: TYPES.UPDATE_DEMURRAGE_AND_DETENTION_REASON_CODES.PENDING});
        try {
            await callApiPut(API.Settings.UpdateDemurrageAndDetentionReasonCode(body.id), body);
            dispatch({type: TYPES.UPDATE_DEMURRAGE_AND_DETENTION_REASON_CODES.FULFILLED});
        } catch (e) {
            dispatch({type: TYPES.UPDATE_DEMURRAGE_AND_DETENTION_REASON_CODES.REJECTED});
        }
    }
};

export const deleteDemurrageAndDetentionReasonCodes = (id: number) => {
    return async (dispatch: ThunkDispatch<any, any, ISettingsAction>): Promise<void> => {
        dispatch({ type: TYPES.DELETE_DEMURRAGE_AND_DETENTION_REASON_CODES.PENDING });
        try {
            await callApiDelete(API.Settings.DeleteDemurrageAndDetentionReasonCode(id));
            dispatch({ type: TYPES.DELETE_DEMURRAGE_AND_DETENTION_REASON_CODES.FULFILLED })
        } catch (e) {
            dispatch({ type: TYPES.DELETE_DEMURRAGE_AND_DETENTION_REASON_CODES.REJECTED, error: generateError(e) });
        }
    }
};

export const createDemurrageAndDetentionReasonCodes = (body: IReasonCode) => {
    return async (dispatch: ThunkDispatch<any, any, ISettingsAction>): Promise<void> => {
        dispatch({ type: TYPES.CREATE_DEMURRAGE_AND_DETENTION_REASON_CODES.PENDING });
        try {
            await callApiPost(API.Settings.CreateDemurrageAndDetentionReasonCode, body);
            dispatch({ type: TYPES.CREATE_DEMURRAGE_AND_DETENTION_REASON_CODES.FULFILLED })
        } catch (e) {
            dispatch({ type: TYPES.CREATE_DEMURRAGE_AND_DETENTION_REASON_CODES.REJECTED, error: generateError(e) });
        }
    }
};

export const getGOHTranslations = (pageNumber?: number, pageSize?: number) => {
    return async (dispatch: ThunkDispatch<any, any, ISettingsAction>, getState: () => IStore): Promise<void> => {
        dispatch({ type: TYPES.FETCH_GOH_TRANSLATIONS.PENDING });
        try {
            const data = applyFilters(getState, {}, {
                PageNumber: pageNumber ,
                Page: pageNumber,
                PageSize: pageSize
            });
            const res = await callApiPost(API.Settings.GetGOHTranslations, data, {headers: {TransportMode: 'Ocean'}});
            dispatch({ type: TYPES.FETCH_GOH_TRANSLATIONS.FULFILLED, GOHTranslations: res.data })
        } catch (e) {
            dispatch({ type: TYPES.FETCH_GOH_TRANSLATIONS.REJECTED, error: generateError(e) });
        }
    }
};

export const addGOHTranslation = (data: any) => {
    return async (dispatch: ThunkDispatch<any, any, ISettingsAction>): Promise<void> => {
        dispatch({ type: TYPES.ADD_GOH_TRANSLATION.PENDING });
        try {
            await callApiPost(API.Settings.AddGOHTranslation, data);
            dispatch({ type: TYPES.ADD_GOH_TRANSLATION.FULFILLED})
        } catch (e) {
            dispatch({ type: TYPES.ADD_GOH_TRANSLATION.REJECTED, error: generateError(e) });
        }
    }
};

export const editGOHTranslation = (data: any) => {
    return async (dispatch: ThunkDispatch<any, any, ISettingsAction>): Promise<void> => {
        dispatch({ type: TYPES.UPDATE_GOH_TRANSLATIONS.PENDING });
        try {
            await callApiPost(API.Settings.UpdateGOHTranslation, data);
            dispatch({ type: TYPES.UPDATE_GOH_TRANSLATIONS.FULFILLED})
        } catch (e) {
            dispatch({ type: TYPES.UPDATE_GOH_TRANSLATIONS.REJECTED, error: generateError(e) });
        }
    }
};

export const deleteGOHTranslation = (id: number) => {
    return async (dispatch: ThunkDispatch<any, any, ISettingsAction>): Promise<void> => {
        dispatch({ type: TYPES.DELETE_GOH_TRANSLATION.PENDING });
        try {
            await callApiPost(API.Settings.DeleteGOHTranslation, {id});
            dispatch({ type: TYPES.DELETE_GOH_TRANSLATION.FULFILLED})
        } catch (e) {
            dispatch({ type: TYPES.DELETE_GOH_TRANSLATION.REJECTED, error: generateError(e) });
        }
    }
};