import { TYPES } from 'action-types/timeline';
import { IComment, IComments } from './models';
import { ICommentFilters } from 'components/timeline/components/Filters';

export interface ITimelineState {
    readonly fetchCommentsLoading: boolean;
    readonly addCommentLoading: boolean;
    readonly fetchCommentsForCustomsLoading: boolean;
    readonly addCommentForCustomsLoading: boolean;
    readonly fetchCommentsForDeliveryPlanningLoading: boolean;
    readonly addCommentForDeliveryPlanningLoading: boolean;
    readonly error: string;
    readonly comments: IComments;
    readonly additionalComments: IComments;
    readonly filters: ICommentFilters;
}

export interface ITimelineAction {
    readonly type: string;
    readonly comments: IComments;
    readonly newComment?: IComment;
    readonly error?: string;
    readonly filters?: ICommentFilters;
    readonly additionalComments: IComments;
}

const defaultState: ITimelineState = {
    fetchCommentsLoading: false,
    addCommentLoading: false,
    fetchCommentsForCustomsLoading: false,
    addCommentForCustomsLoading: false,
    fetchCommentsForDeliveryPlanningLoading: false,
    addCommentForDeliveryPlanningLoading: false,
    error: '',
    comments: {
        page: 1,
        pageSize: 0,
        totalCount: 0,
        result: [],
    },
    additionalComments: {
        page: 1,
        pageSize: 0,
        totalCount: 0,
        result: [],
    },
    filters: {
        contents: [],
        messageTypes: [],
    },
};

export const reducer = (state: ITimelineState = defaultState, action: ITimelineAction): ITimelineState => {
    switch (action.type) {
        case TYPES.FETCH_COMMENTS.PENDING:
            return {
                ...state,
                fetchCommentsLoading: true,
                error: '',
            };
        case TYPES.FETCH_COMMENTS.FULFILLED:
            return {
                ...state,
                comments: {
                    ...action.comments,
                    page: action.comments.page || (action.comments as any).pageNumber,
                },
                fetchCommentsLoading: false,
                error: '',
            };
        case TYPES.FETCH_COMMENTS.REJECTED:
            return {
                ...state,
                comments: defaultState.comments,
                fetchCommentsLoading: false,
                error: action.error || '',
            };
        case TYPES.ADD_COMMENT.PENDING:
            return {
                ...state,
                addCommentLoading: true,
                error: '',
            };
        case TYPES.ADD_COMMENT.FULFILLED:
            return {
                ...state,
                addCommentLoading: false,
                error: '',
            };
        case TYPES.ADD_COMMENT.REJECTED:
            return {
                ...state,
                addCommentLoading: false,
                error: action.error || '',
            };
        case TYPES.LOAD_MORE_COMMENTS.PENDING:
            return {
                ...state,
                fetchCommentsLoading: true,
                error: '',
            };
        case TYPES.LOAD_MORE_COMMENTS.FULFILLED:
            return {
                ...state,
                fetchCommentsLoading: false,
                comments: {
                    ...action.comments,
                    result: [...state.comments.result, ...action.comments.result],
                },
            };
        case TYPES.LOAD_MORE_COMMENTS.REJECTED:
            return {
                ...state,
                fetchCommentsLoading: false,
                error: action.error || '',
            };
        case TYPES.SET_FILTERS:
            return {
                ...state,
                filters: action.filters || defaultState.filters,
            };
        case TYPES.SET_FILTERS:
            return {
                ...state,
                filters: action.filters || defaultState.filters,
            };

        case TYPES.FETCH_ADDITIONAL_COMMENTS.PENDING:
            return {
                ...state,
                fetchCommentsLoading: true,
                error: '',
            };
        case TYPES.FETCH_ADDITIONAL_COMMENTS.FULFILLED:
            return {
                ...state,
                additionalComments: action.additionalComments || defaultState.additionalComments,
                fetchCommentsLoading: false,
                error: '',
            };
        case TYPES.FETCH_ADDITIONAL_COMMENTS.REJECTED:
            return {
                ...state,
                comments: defaultState.comments,
                fetchCommentsLoading: false,
                error: action.error || '',
            };
        default:
            return state;
    }
};
