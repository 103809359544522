import React, { Component } from 'react';
import { ModalType } from 'components/modal-screen/ModalType';
import { ModalScreen } from 'components/modal-screen';
import { TripleGrid } from 'components/grid/triple-grid';
import { DataTable } from 'components/data-table/DataTable';
import { ColumnFactory } from 'components/data-table/ColumnFactory';
import { InjectedFormProps, reduxForm } from 'redux-form';
import { compose, Action } from 'redux';
import { connect } from 'react-redux';
import { FormTextArea } from 'components/textarea/FormTextArea';
import { SingleGrid } from 'components/grid/single-grid';
import { ThunkDispatch } from 'redux-thunk';
import { requestCarrierRelease, IRequestCarrierReleaseData, resetRequestCarrierReleaseError, INotifyCustomerWithRelease, notifyCustomerWithCarrierContainerRelease } from 'actions/bill-release';
import { IStore } from 'reducers/index';
import { Dialog } from 'components/dialog';
import { Input } from 'components/input';

interface IMapDispatchToProps {
    requestCarrierRelease: (data: IRequestCarrierReleaseData) => Promise<void>;
    resetRequestCarrierReleaseError: () => void;
    notifyCustomerWithCarrierContainerRelease: (data: INotifyCustomerWithRelease) => Promise<void>;
}

interface IMapStateToProps {
    requestReleaseError: string;
}

interface IRequestReleaseModalOwnProps {
    tableData?: any;
    visible: boolean;
    equipments: any[];
    ids: {
        voyageIds: number[];
        blIds: number[];
    };
    carrierName: string;
    closeModal: () => void;
    editingItemsCount: number;
}

type IRequestReleaseModal = IRequestReleaseModalOwnProps & IMapDispatchToProps & IMapStateToProps & InjectedFormProps<{}, IRequestReleaseModalOwnProps>;

class _RequestReleaseModal extends Component<IRequestReleaseModal> {
    public componentDidMount() {
        this.props.resetRequestCarrierReleaseError();
    }

    public componentDidUpdate(prevProps: IRequestReleaseModal) {
        if (this.props.visible && !prevProps.visible) {
            this.props.resetRequestCarrierReleaseError();
        }
    }

    public render() {
        return (
            <form onSubmit={this.props.handleSubmit(this.submit)}>
                <ModalScreen
                    title={this.getTitle()}
                    modalType={ModalType.l()}
                    primaryButtonTitle="Save"
                    primaryButtonType="submit"
                    secondaryButtonTitle="Cancel"
                    secondaryButtonFunc={this.props.closeModal}
                    visible={this.props.visible}
                    closeModal={this.props.closeModal}
                >
                    <Dialog
                        message={this.props.requestReleaseError}
                        dialogType={500}
                        isVisible={!!this.props.requestReleaseError}
                        closeDialog={this.props.resetRequestCarrierReleaseError}
                    />

                    <div className="large-body-text">Request release for following equipment</div>
                    <TripleGrid>
                        <Input
                            label="Carrier"
                            disabled={true}
                            input={{value: this.props.carrierName, readOnly: true}}
                        />
                    </TripleGrid>
                    <DataTable
                        data={this.props.equipments}
                        columns={[
                            ColumnFactory.standardColumnOld('equipmentNumber', 'Equipment Number'),
                            ColumnFactory.standardColumnOld('vesselName', 'Vessel Name'),
                        ]}
                    />
                    <SingleGrid>
                        <FormTextArea
                            label="Comment"
                            name="Comment"
                        />
                    </SingleGrid>
                </ModalScreen>
            </form>
        );
    }

    private submit = async () => {
        try {
            await this.props.requestCarrierRelease({ blIds: this.props.ids.blIds });
            await this.props.notifyCustomerWithCarrierContainerRelease({
                voyageId: this.props.ids.voyageIds[0],
                blIds: this.props.ids.blIds
            });
            this.props.closeModal();
        } catch (error) {
            console.error(error);
        }
    };

    private getTitle() {
        const { editingItemsCount: count } = this.props;
        const base = 'Request release';

        if (count < 1) {
            return base;
        }

        return `${base} - updating for ${count} CB/L${count > 1 ? 's' : ''}`;
    }
}

const mapStateToProps = ({ bill_release }: IStore): IMapStateToProps => ({
    requestReleaseError: bill_release.requestReleaseError,
})

const mapDispatchToProps = (dispatch: ThunkDispatch<any, any, Action>): IMapDispatchToProps => ({
    requestCarrierRelease: (config: IRequestCarrierReleaseData) => dispatch(requestCarrierRelease(config)),
    resetRequestCarrierReleaseError: () => dispatch(resetRequestCarrierReleaseError()),
    notifyCustomerWithCarrierContainerRelease: (data: INotifyCustomerWithRelease) => dispatch(notifyCustomerWithCarrierContainerRelease(data)),
});

const FORM_NAME = 'REQUEST_CARRIER_RELEASE';

export const RequestReleaseModal = compose(
    connect<IMapStateToProps, IMapDispatchToProps, IRequestReleaseModalOwnProps, IStore>(mapStateToProps, mapDispatchToProps),
    reduxForm<{}, IRequestReleaseModalOwnProps>({
        form: FORM_NAME,
        enableReinitialize: true
    })
)(_RequestReleaseModal);
