import React from 'react';
import { ColumnFactory } from 'components/data-table/ColumnFactory';
import { DataTable } from 'components/data-table/DamcoTable';
import { IShipmentBooking } from 'reducers/fact/models';
import { StatusRow } from 'components/status-row';
import { cloneDataWithDisplayNames } from 'utilities/tables';

interface IDocumentsTableProps {
    data: IShipmentBooking[];
}

export const BookingsTable: React.FunctionComponent<IDocumentsTableProps> = (props) => {
    const data = cloneDataWithDisplayNames(props.data);

    return(
        <StatusRow title="Bookings">
            <DataTable
                data={data}
                columns={[
                    ColumnFactory.standardColumn('numberDisplayName', 'Number'),
                    ColumnFactory.standardColumn('cargoTypeDisplayName', 'Cargo type'),
                    ColumnFactory.standardColumn('consigneeBusinessEntityCodeDisplayName', 'Consignee business entity code'),
                    ColumnFactory.standardColumn('consigneeCountryCodeDisplayName', 'Consignee country code'),
                    ColumnFactory.standardColumn('consigneeFactCodeDisplayName', 'Consignee fact code'),
                    ColumnFactory.standardColumn('consigneeNameDisplayName', 'Consignee name'),
                    ColumnFactory.standardColumn('consigneeSCVCodeDisplayName', 'Consignee SCV code'),
                    ColumnFactory.standardColumn('destinationSiteCodeDisplayName', 'Destination site code'),
                    ColumnFactory.standardColumn('destinationSiteNameDisplayName', 'Destination site name'),
                    ColumnFactory.standardColumn('internalShipmentNumberDisplayName', 'Internal shipment number'),
                    ColumnFactory.standardColumn('originSiteCodeDisplayName', 'Origin site code'),
                    ColumnFactory.standardColumn('originSiteNameDisplayName', 'Origin site name'),
                    ColumnFactory.standardColumn('paymentTypeDisplayName', 'Payment type'),
                    ColumnFactory.standardColumn('placeOfDeliveryCodeDisplayName', 'Place of delivery code'),
                    ColumnFactory.standardColumn('placeOfDeliveryNameDisplayName', 'Place of delivery name'),
                    ColumnFactory.standardColumn('ultimatePlaceOfDeliveryCodeDisplayName', 'Ultimate place of delivery code '),
                    ColumnFactory.standardColumn('ultimatePlaceOfDeliveryNameDisplayName', 'Ultimate place of delivery name '),
                ]}
                withCheckboxes={false}
                reference={null as any}
                tableName="ShipmentDetailsBookingsTable"
                showPagination={true}
                expandable={false}
            />
        </StatusRow>
    );
};

