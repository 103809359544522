import React, { useEffect, useState, FunctionComponent } from 'react';
import { Route, Redirect, Switch, withRouter, RouteComponentProps } from 'react-router-dom';
import { TrackingRouting } from 'views/tracking/TrackingRouting';
import { ALL_ROLES, defaultRedirect } from 'constants/roles';
import { UnauthorizedPage } from 'views/unauthorized';
import { connect } from 'react-redux';
import { IStore } from 'reducers/index';
import { OTHER_ROUTES } from 'constants/routes';
import { IRoles } from 'utilities/roles';
import { LoadingAnimation } from 'components/loading';
import { ThunkDispatch } from 'redux-thunk';
import { Action } from 'redux';
import { checkIfRolesAreLoaded } from 'actions/user';
import { BillReleaseRouting } from './bill-release';
import { CustomsRouting } from './customs/CustomsRouting';
import { PlanningRouting } from './planning';
import { DeliveryAndReturnRouting } from './delivery-and-return/DeliveryAndReturnRouting';
import { DemurrageAndDetentionRouting } from './demurrage-and-detention/DemurrageAndDetentionRouting';
import { ChangeLog } from './change-log/ChangeLog';
import { ExceptionManagementRouting } from './exception-management/ExceptionManagementRouting';

interface IMapStateToProps {
    roles: IRoles[];
    rolesAreLoading: boolean;
};

interface IMapDispatchToProps {
    checkIfRolesAreLoaded: () => Promise<boolean>;
}

interface IProps extends IMapStateToProps, IMapDispatchToProps, RouteComponentProps<{}> {};

const _RoutingConfiguration: FunctionComponent<IProps> = (props) => {
    const [redirectPath, setRedirectPath] = useState<string>('');

    useEffect(() => {
        if(props.checkIfRolesAreLoaded() && Array.isArray(props.roles) && props.roles.length > 0) {
            for(const role of props.roles) {
                if(ALL_ROLES.some(bf => bf === role.name || bf === role.Name)) {
                    const businessFunction = role.name || role.Name as string;
                    setRedirectPath(defaultRedirect[businessFunction]);
                    break;
                } else {
                    setRedirectPath(OTHER_ROUTES.UNAUTHORIZED);
                }
            }
        } else {
            setRedirectPath(OTHER_ROUTES.UNAUTHORIZED);
        }
    }, [props.roles]);

    if(props.rolesAreLoading) {
        return <LoadingAnimation />;
    }

    return(
        <React.Fragment>
            {props.roles && <Switch>
                <Route path="/tracking" component={TrackingRouting} />
                <Route path="/bill-release" component={BillReleaseRouting} />
                <Route path="/customs" component={CustomsRouting} />
                <Route path="/planning" component={PlanningRouting} />
                <Route path="/delivery-and-return" component={DeliveryAndReturnRouting} />
                <Route path="/demurrage-and-detention" component={DemurrageAndDetentionRouting} />
                <Route path="/changelog" component={ChangeLog} />
                <Route path="/exception-management" component={ExceptionManagementRouting} />
                <Route path="/unauthorized" component={UnauthorizedPage} />
                <Redirect to={redirectPath} />
            </Switch>}
        </React.Fragment>
    )
}

const mapStateToProps = (state: IStore): IMapStateToProps => ({
    roles: state.user.roles,
    rolesAreLoading: state.user.rolesAreLoading
});

const mapDispatchToProps = (dispatch: ThunkDispatch<any, any, Action>): IMapDispatchToProps => ({
    checkIfRolesAreLoaded: () => dispatch(checkIfRolesAreLoaded())
})

export const RoutingConfiguration = withRouter(connect<IMapStateToProps, {}, {}, IStore>(mapStateToProps, mapDispatchToProps)(_RoutingConfiguration));
