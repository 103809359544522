import React from 'react';
import { MESSAGE_TYPES } from 'constants/message-type';

interface IUserCommentProps {
    title: string;
    time: Date | string;
    message: string;
    type: string;
}

export const UserComment: React.StatelessComponent<IUserCommentProps> = (props) => {
    const messageType = (type: string) => {
        switch(type) {
            case MESSAGE_TYPES.MESSAGE:
                return(
                    <div className="status-msg">
                        <i className="fa fa-pencil requested" aria-hidden="true" />
                        <span>Comment</span>
                    </div>
                );
            case MESSAGE_TYPES.SYSTEM:
                return(
                    <div className="status-msg">
                        <i className="fa fa-rocket success requested" aria-hidden="true" />
                        <span>System</span>
                    </div>
                );
            case MESSAGE_TYPES.SYSTEM_INBOUND:
                return(
                    <div className="status-msg">
                        <i className="fa fa-rocket success requested" aria-hidden="true" />
                        <span>Inbound</span>
                    </div>
                );
            case MESSAGE_TYPES.SYSTEM_OUTBOUND:
                return(
                    <div className="status-msg">
                        <i className="fa fa-rocket success requested" aria-hidden="true" />
                        <span>Outbound</span>
                    </div>
                );
            case MESSAGE_TYPES.INFORMATION:
                return(
                    <div className="status-msg">
                        <i className="fa fa-check-circle success requested" aria-hidden="true" />
                        <span>Update</span>
                    </div>
                );
            default:
                return(
                    <div className="status-msg">
                        <i className="fa fa-pencil requested" aria-hidden="true" />
                        <span>Comment</span>
                    </div>
                );
        }
    }

    const setIcon = () => {
        if (props.type === MESSAGE_TYPES.SYSTEM_OUTBOUND || props.type === MESSAGE_TYPES.SYSTEM_INBOUND) {
            return <img src="https://cdn.damco.com/cortex/v1/images/logos/DamcoPortal.svg" alt="Logo Damco" width="32" height="32" />;
        } else {
            return <img src="https://cdn.damco.com/images/icons/DefaultProfileImg32.png" alt="User default image"/>;
        }
    }

    return(
        <li className="timeline-history">
            <div className="image-block inline">
                {setIcon()}
            </div>
            <div className="info-block inline">
                <div className="info-head">
                    <span className="title">{props.title}</span>
                    <span className="date-time">{props.time}</span>
                </div>
                <div className="info-body">
                    {messageType(props.type)}
                    <div className="message-container">
                        <p>{props.message}</p>
                    </div>
                </div>
            </div>
        </li>
    );
}
