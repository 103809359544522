import React, {Component} from 'react';
import Dropdown from './Dropdown';
import { connect } from 'react-redux'
import { UserAreaContainer } from './UserAreaStyles';
import { IStore } from 'reducers/index';

interface IUserAreaProps {
    user: string;
    company: string;
    email: string;
}

 class UserArea extends Component<IUserAreaProps> {
    constructor(props: any) {
        super(props);
    }

    public render() {
        return (
            <React.Fragment>
                <UserAreaContainer>
                    <Dropdown 
                        user={this.props.user}
                        email={this.props.email}
                        company={this.props.company}
                    />
                </UserAreaContainer>
            </React.Fragment>
        );
    }
};

const mapStateToProps = (state: IStore): IUserAreaProps => {
    return {
        user: state.user.user.name,
        company: state.user.user.idToken.company,
        email: state.user.user.idToken.email
    }
}

export default connect(mapStateToProps)(UserArea);