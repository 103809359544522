import React, { Component } from 'react';
import { PageHeader } from 'components/page-header';
import { Page } from 'components/page-container';
import { Filters } from 'modules/filters/containers/Filters';
import { FiltersInput } from 'modules/filters/components/FiltersInput';
import { FiltersDateInput } from 'modules/filters/components/FiltersDateInput';
import { FiltersSelect } from 'modules/filters/components/FiltersSelect';
import { connect } from 'react-redux';
import { fetchVessels } from 'actions/vessels';
import { ThunkDispatch } from 'redux-thunk';
import { Action } from 'redux';
import { IStore } from 'reducers/index';
import { statusesValues } from 'utilities/util';
import { setSortForTable } from 'utilities/tables';
import { CT_STATUS } from 'constants/statuses';
import { VesselsTable } from 'modules/tracking/vessels/components/VesselsTable';
import { ErrorDialog } from 'components/error-dialog';
import API from 'constants/api';
import { ISortModel } from 'reducers/filters/models';
import { setSort, resetSort } from 'actions/filters';
import { IVesselsList } from 'reducers/vessels/models';

interface IMapToStateProps {
    vessels: IVesselsList;
    etdEmpty: boolean;
    atdEmpty: boolean;
    originalEtaEmpty: boolean;
    newEtaEmpty: boolean;
    ataEmpty: boolean;
}

interface IDispatchProps {
    fetchVessels: (pageNumber: number, pageSize: number) => Promise<void>;
    setSort: (sort: ISortModel) => void;
    resetSort: () => void;
}

interface IVessels extends IMapToStateProps, IDispatchProps {}

interface IVesselsState {
    page: number;
    pageSize: number;
}

export class _Vessels extends Component<IVessels, IVesselsState> {
    public state = {
        page: 1,
        pageSize: 10,
    };

    public render() {
        const filters = [
            <FiltersInput
                key="vesselName"
                name="VesselNames"
                label="Vessel Name"
                id="vesselName"
                type="arrayOne"
            />,
            <FiltersInput
                key="voyageNumber"
                name="voyageNumber"
                label="Voyage"
            />,
            <FiltersInput
                key="pol"
                name="polName"
                label="POL"
            />,
            <FiltersInput
                key="pod"
                name="podName"
                label="POD"
            />,
            <FiltersDateInput
                key="etd"
                name="etd_from"
                label="ETD (from)"
                type="date"
                relatedField="ETD"
                readOnly={this.props.etdEmpty}
            />,
            <FiltersDateInput
                key="etd"
                name="etd_to"
                label="ETD (to)"
                type="date"
                isEndOfDay={true}
                relatedField="ETD"
                readOnly={this.props.etdEmpty}
            />,
            <FiltersDateInput
                key="atd"
                name="atd_from"
                label="ATD (from)"
                type="date"
                relatedField="ATD"
                readOnly={this.props.atdEmpty}
            />,
            <FiltersDateInput
                key="atd"
                name="atd_to"
                label="ATD (to)"
                type="date"
                isEndOfDay={true}
                relatedField="ATD"
                readOnly={this.props.atdEmpty}
            />,
            <FiltersDateInput
                key="eta"
                name="neweta_from"
                label="New ETA (from)"
                type="date"
                relatedField="newEta"
                readOnly={this.props.newEtaEmpty}
            />,
            <FiltersDateInput
                key="eta"
                name="neweta_to"
                label="New ETA (to)"
                type="date"
                isEndOfDay={true}
                relatedField="newEta"
                readOnly={this.props.newEtaEmpty}
            />,
            <FiltersDateInput
                key="ata"
                name="ata_from"
                label="ATA (from)"
                type="date"
                relatedField="ata"
                readOnly={this.props.ataEmpty}
            />,
            <FiltersDateInput
                key="ata"
                name="ata_to"
                label="ATA (to)"
                type="date"
                isEndOfDay={true}
                relatedField="ata"
                readOnly={this.props.ataEmpty}
            />,
            <FiltersSelect
                key="status"
                name="status"
                label="Status"
                type="number"
                options={statusesValues(CT_STATUS)}
            />,
            <FiltersInput
                key="lastUserUpdated"
                name="lastUserUpdated"
                label="Last updated by"
            />,
        ];

        return (
            <React.Fragment>
                <PageHeader>Vessels tracking</PageHeader>
                <Page>
                    <ErrorDialog endpoint={API.ContainerTracking.GetVessels} />
                    <ErrorDialog endpoint={API.Export.ExportVesselTrackingToExcel} />
                    <Filters
                        tableNameKey="tracking-vessels"
                        filters={filters}
                        fetchFunction={() => this.props.fetchVessels(this.state.page, this.state.pageSize)}
                        withoutPagination={true}
                    />
                    <VesselsTable
                        data={this.props.vessels.data}
                        pageCount={Math.ceil(this.props.vessels.totalCount / this.props.vessels.pageSize)}
                        fetchVessels={this.fetchVessels}
                    />
                </Page>
            </React.Fragment>
        );
    }

    public componentWillUnmount() {
        this.props.resetSort();
    }

    private fetchVessels = (tableData: any) => {
        this.setState({
            page: tableData.page + 1,
            pageSize: tableData.pageSize
        }, () => {
            setSortForTable(tableData.sorted, this.props.setSort);
            this.props.fetchVessels(this.state.page, this.state.pageSize);
        });
    };
}

const mapStateToProps = (state: IStore): IMapToStateProps => ({
    vessels: state.vessels.vessels,
    etdEmpty: state.filters.filters.etd_empty,
    atdEmpty: state.filters.filters.atd_empty,
    originalEtaEmpty: state.filters.filters.originaleta_empty,
    newEtaEmpty: state.filters.filters.neweta_empty,
    ataEmpty: state.filters.filters.ata_empty
});

const mapDispatchToProps = (dispatch: ThunkDispatch<any, any, Action>): IDispatchProps => ({
    fetchVessels: (pageNumber: number, pageSize: number) => dispatch(fetchVessels(pageNumber, pageSize)),
    setSort: (sort: ISortModel) => dispatch(setSort(sort)),
    resetSort: () => dispatch(resetSort()),
});

export const Vessels = connect<IMapToStateProps, IDispatchProps, undefined, IStore>(mapStateToProps, mapDispatchToProps)(_Vessels);
