import React from 'react';
import { ColumnFactory } from 'components/data-table/ColumnFactory';
import { DataTable } from 'components/data-table/DamcoTable';
import { ICblVoyage } from 'reducers/fact/models';
import { StatusRow } from 'components/status-row';
import { cloneDataWithDisplayNames } from 'utilities/tables';

interface IVoyagesTableProps {
    voyages: ICblVoyage[];
}

export const VoyagesTable: React.FunctionComponent<IVoyagesTableProps> = (props) => {
    const data = cloneDataWithDisplayNames(props.voyages, 'DD-MM-YYYY, h:mm');

    return(
        <StatusRow title="Voyages">
            <DataTable
                data={data}
                columns={[
                    ColumnFactory.standardColumn('voyageNumberDisplayName', 'Voyage number'),
                    ColumnFactory.standardColumn('vesselNameDisplayName', 'Vessel name'),
                    ColumnFactory.standardColumn('vesselCodeDisplayName', 'Vessel code'),
                    ColumnFactory.standardColumn('loadingPortNameDisplayName', 'Loading port name'),
                    ColumnFactory.standardColumn('loadingPortCodeDisplayName', 'Loading port code'),
                    ColumnFactory.standardColumn('destinationPortNameDisplayName', 'Destination port name'),
                    ColumnFactory.standardColumn('destinationPortCodeDisplayName', 'Destination port code'),
                    ColumnFactory.standardColumn('ataDisplayName', 'ATA'),
                    ColumnFactory.standardColumn('atdDisplayName', 'ATD'),
                    ColumnFactory.standardColumn('etaDisplayName', 'ETA'),
                    ColumnFactory.standardColumn('etdDisplayName', 'ETD'),
                ]}
                withCheckboxes={false}
                reference={null as any}
                tableName="CblDetailsVoyagesTable"
                showPagination={true}
                expandable={false}
            />
        </StatusRow>
    );
};

