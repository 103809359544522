import React from 'react';
import { StatusRow } from 'components/status-row';
import { OneStatus } from 'components/status-row/OneStatus';
import { QuarterGrid } from 'components/grid/quarter-grid';
import { formatValueIfNullOrUndefined, formatDate } from 'utilities/util';
import { Link } from 'react-router-dom';

interface IEquipmentTransportRowProps {
    createDate: string;
    updateDate: string;
    equipmentNumber: string;
    etsOnEtVolumeFactor: number;
    packages: number;
    quantity: number;
    sealNumber: string;
    size: number | string;
    substitutionSize: string;
    substitutionType: string;
    volume: number;
    weight: number;
    etId: number
}

export const EquipmentTransportRow: React.StatelessComponent<IEquipmentTransportRowProps> = (props) => {
    return (
        <StatusRow title="Equipment transport">
            <QuarterGrid>
                <OneStatus label="Create date">
                    {formatDate(props.createDate, 'DD-MM-YYYY, h:mm')}
                </OneStatus>
                <OneStatus label="Update date">
                    {formatDate(props.updateDate, 'DD-MM-YYYY, h:mm')}
                </OneStatus>
                <OneStatus label="Equipment number">
                    <Link to={`/exception-management/fact/equipments/${props.etId}`}>{formatValueIfNullOrUndefined(props.equipmentNumber)}</Link>
                </OneStatus>
                <OneStatus label="ETS on ET volume factor">
                    {formatValueIfNullOrUndefined(props.etsOnEtVolumeFactor)}
                </OneStatus>
            </QuarterGrid>
            <QuarterGrid>
                <OneStatus label="Packages">
                    {formatValueIfNullOrUndefined(props.packages)}
                </OneStatus>
                <OneStatus label="Quantity">
                    {formatValueIfNullOrUndefined(props.quantity)}
                </OneStatus>
                <OneStatus label="Seal number">
                    {formatValueIfNullOrUndefined(props.sealNumber)}
                </OneStatus>
                <OneStatus label="Size">
                    {formatValueIfNullOrUndefined(props.size)}
                </OneStatus>
            </QuarterGrid>
            <QuarterGrid>
                <OneStatus label="Substitution size">
                    {formatValueIfNullOrUndefined(props.substitutionSize)}
                </OneStatus>
                <OneStatus label="Substitution type">
                    {formatValueIfNullOrUndefined(props.substitutionType)}
                </OneStatus>
                <OneStatus label="Volume">
                    {formatValueIfNullOrUndefined(props.volume)}
                </OneStatus>
                <OneStatus label="Weight">
                    {formatValueIfNullOrUndefined(props.weight)}
                </OneStatus>
            </QuarterGrid>
        </StatusRow>
    );
};
