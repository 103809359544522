import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { RouteComponentProps } from 'react-router-dom';
import { ThunkDispatch } from 'redux-thunk';
import { Action } from 'redux';
import { fetchShipmentDetails } from 'actions/fact';
import { ErrorDialog } from 'components/error-dialog';
import API from 'constants/api';
import { PageHeader } from 'components/page-header';
import { IStore } from 'reducers/index';
import { Page } from 'components/page-container';
import { ShipmentDetailsTopBar } from './components/ShipmentDetailsTopBar';
import { ConsigneeRow } from './components/ConsigneeRow';
import { IShipmentDetails } from 'reducers/fact/models';
import { PlaceRow } from './components/PlaceRow';
import { BookingsTable } from './components/BookingsTable';
import { CblsTable } from './components/CblsTable';
import { DocumentsTable } from './components/DocumentsTable';
import { ETSTable } from './components/ETSTable';

interface IParams {
    shipmentId: string;
    equipmentId: string;
    etsId: string;
}

interface IMapDispatchToProps {
    fetchShipmentDetails: (shipmentId: number) => Promise<void>;
}

interface IMapStateToProps {
    shipmentDetails: IShipmentDetails;
}

interface IShipmentDetailsProps extends RouteComponentProps<IParams>, IMapStateToProps, IMapDispatchToProps {}

const _ShipmentDetails: React.StatelessComponent<IShipmentDetailsProps> = (props) => {
    const shipmentId = parseInt(props.match.params.shipmentId, 10);
    useEffect(() => {
        if (!Number.isNaN(shipmentId)) {
            props.fetchShipmentDetails(shipmentId);
        }
    }, []);

    return(
        <React.Fragment>
            <ErrorDialog endpoint={API.Fact.FetchShipmentDetails} />
            <PageHeader backButton={true}>Shipment: {props.shipmentDetails.documentNumber}</PageHeader>
            <ShipmentDetailsTopBar
                documentNumber={props.shipmentDetails.documentNumber}
                cargoType={props.shipmentDetails.cargoType}
                prepaidCollectIndicator={props.shipmentDetails.prepaidCollectIndicator}
                reconsolidationPoint={props.shipmentDetails.reconsolidationPoint}
            />
            <Page>
                <BookingsTable data={props.shipmentDetails.bookings} />
                <CblsTable data={props.shipmentDetails.cbls} />
                <DocumentsTable data={props.shipmentDetails.documents} />
                <ETSTable data={props.shipmentDetails.equipmentTransportShipments} equipmentId={props.match.params.equipmentId as any} />
                <ConsigneeRow 
                    consigneeBusinessEntityCode={props.shipmentDetails.consigneeBusinessEntityCode}
                    consigneeCountryCode={props.shipmentDetails.consigneeCountryCode}
                    consigneeFACTCode={props.shipmentDetails.consigneeFACTCode}
                    consigneeName={props.shipmentDetails.consigneeName}
                />
                <PlaceRow 
                    placeOfConsolidationCode={props.shipmentDetails.placeOfConsolidationCode}
                    placeOfConsolidationName={props.shipmentDetails.placeOfConsolidationName}
                    placeOfDeconsolidationCode={props.shipmentDetails.placeOfDeconsolidationCode}
                    placeOfDeconsolidationName={props.shipmentDetails.placeOfDeconsolidationName}
                    placeOfDeliveryCode={props.shipmentDetails.placeOfDeliveryCode}
                    placeOfDeliveryName={props.shipmentDetails.placeOfDeliveryName}
                />
            </Page>
        </React.Fragment>
    );
};

const mapStateToProps = (state: IStore): IMapStateToProps => ({
    shipmentDetails: state.fact.shipmentDetails
});

const mapDispatchToProps = (dispatch: ThunkDispatch<any, any, Action>): IMapDispatchToProps => ({
    fetchShipmentDetails: (shipmentId: number) => dispatch(fetchShipmentDetails(shipmentId)),
});

export const ShipmentDetails = connect<IMapStateToProps, IMapDispatchToProps, {}, IStore>(mapStateToProps, mapDispatchToProps)(_ShipmentDetails);