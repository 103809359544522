import React from 'react';
import { connect } from 'react-redux';
import { ThunkDispatch } from 'redux-thunk';
import { Action } from 'redux';
import {
    createCustomsBrokerConfiguration,
    deleteCustomsBrokersConfiguration,
    fetchCustomsBrokerSettings,
    updateCustomsBrokerConfiguration
} from 'actions/settings';
import { IStore } from 'reducers/index';
import { ConfigurationTable } from 'modules/settings/delivery-planning/containers/ConfigurationTable';
import { ISettingsModel, ICreateBroker, IUpdateBroker } from 'reducers/settings/models';

interface IDispatch {
    fetchCustomsBrokerSettings: () => void;
    createCustomsBrokerConfiguration: (configuration: ICreateBroker) => Promise<void>;
    updateCustomsBrokerConfiguration: (configuration: IUpdateBroker) => Promise<void>;
    deleteCustomsBrokersConfiguration: (id: string) => Promise<void>;
}

interface IMapStateToProps {
    customsBrokerSettings: ISettingsModel[];
    fetchCustomsBrokerSettingsPending: boolean;
    updateCustomsBrokerSettingsPending: boolean;
    deleteCustomsBrokerSettingsPending: boolean;
    createCustomsBrokerSettingsPending: boolean;
}

interface IBrokersConfiguration extends IDispatch, IMapStateToProps{}

class _BrokersConfiguration extends React.Component<IBrokersConfiguration> {
    public componentDidMount() {
        this.props.fetchCustomsBrokerSettings();
    }

    public render() {
        return (
            <ConfigurationTable
                data={this.props.customsBrokerSettings}
                deleteConfiguration={this.deleteCustomsBrokersConfiguration}
                updateConfiguration={this.updateBrokersConfiguration}
                isLoading={this.props.fetchCustomsBrokerSettingsPending || this.props.deleteCustomsBrokerSettingsPending}
                updateConfigurationPending={this.props.updateCustomsBrokerSettingsPending}
            />
        );
    }

    private deleteCustomsBrokersConfiguration = async (id: string) => {
        await this.props.deleteCustomsBrokersConfiguration(id);
        await this.props.fetchCustomsBrokerSettings();
    };

    private updateBrokersConfiguration = async (configuration: ISettingsModel & {Id: string}) => {
        await this.props.updateCustomsBrokerConfiguration({BrokerId: configuration.Id, Broker: configuration});
        await this.props.fetchCustomsBrokerSettings();
    }
}

const mapStateToProps = (state: IStore): IMapStateToProps => ({
    customsBrokerSettings: state.settings.customsBrokerSettings,
    fetchCustomsBrokerSettingsPending: state.settings.fetchCustomsBrokerSettingsPending,
    updateCustomsBrokerSettingsPending: state.settings.updateCustomsBrokerSettingsPending,
    deleteCustomsBrokerSettingsPending: state.settings.deleteCustomsBrokerSettingsPending,
    createCustomsBrokerSettingsPending: state.settings.createCustomsBrokerSettingsPending,
});

const mapDispatchToProps = (dispatch: ThunkDispatch<any, any, Action>): IDispatch => ({
    fetchCustomsBrokerSettings: () => dispatch(fetchCustomsBrokerSettings(true)),
    createCustomsBrokerConfiguration: (configuration: ICreateBroker) => dispatch(createCustomsBrokerConfiguration(configuration)),
    deleteCustomsBrokersConfiguration: (id: string) => dispatch(deleteCustomsBrokersConfiguration(id)),
    updateCustomsBrokerConfiguration: (configuration: IUpdateBroker) => dispatch(updateCustomsBrokerConfiguration(configuration)),
});


export const BrokersConfiguration = connect(mapStateToProps, mapDispatchToProps)(_BrokersConfiguration);