import React from 'react';
import { IRoute, IRouteLink } from '../../routes';
import { MobileNavigationDropdownLink } from 'modules/navbar/components/Mobile/MobileNavigationDropdownLink';

interface IMobileNavigationDropDownLink extends IRoute {
    index: number
    isExpanded: boolean,
    toggleSubNavigation(index: number): void;
}

export const MobileNavigationDropDownItem = (props: IMobileNavigationDropDownLink) => {
    const links = (props.links as IRouteLink[]).map((link: IRouteLink, index: number) => (
        <MobileNavigationDropdownLink
            to={link.to}
            text={link.text}
            key={index}
        />
    ));
    return (
        <div className="nav-mobile-column" key={`item-${props.index}`}>
            <div
                className="nav-mobile-title"
                onClick={(event) => {
                    event.stopPropagation();
                    props.toggleSubNavigation(props.index)
                }}
            >
                <span>{props.name}</span>
                <i className={`${props.isExpanded ? 'fa fa-chevron-up' : 'fa fa-chevron-down'}`} aria-hidden="true"/>
            </div>
            <ul className={`sub-nav-items ${props.isExpanded ? '' : 'hidden'}`}>
                {links}
            </ul>
        </div>
    );
};
