import React from 'react';
import { Column, CellInfo } from 'react-table';
import { ColumnFactory } from 'components/data-table/ColumnFactory';
import styled from 'styled-components';

const SP = styled.p`
    cursor: pointer;
    color: #398FD1;
    margin: 0;

    &:hover {
        color: #3C5BA3;
        text-decoration: underline;
    }
`;

export const onClickColumn = (accessor: string, name: string, onClick: (rowInfo: CellInfo) => void): Column => {
    const cell = (rowInfo: CellInfo) => (
        <div>
            <SP onClick={() => onClick(rowInfo)}>
                {rowInfo.value}
            </SP>
        </div>
    );
    return ColumnFactory.getColumnOptionsOld(accessor, name, cell);
};