import { TYPES } from 'action-types/import';
import { IImportErrors } from './models';

interface IAction {
    readonly type: string;
    readonly errors: IImportErrors[];
    readonly res: any;
}

export interface IImportState {
    readonly isIxportContainerPlanningLoading: boolean;
    readonly isImportTransportDetailsLoading: boolean;
    readonly importPlanningOceanErrors: IImportErrors[];
    readonly importTransportDetailsErrors: any;
    readonly file: any;
    readonly fileFinalDetails: any;
    readonly successWhileImportFinalDetails: boolean;
    readonly successWhileImport: boolean;
}

const defaultState: IImportState = {
    isIxportContainerPlanningLoading: false,
    isImportTransportDetailsLoading: false,
    importPlanningOceanErrors: [],
    importTransportDetailsErrors: {
        errors: []
    },
    file: null,
    fileFinalDetails: null,
    successWhileImport: false,
    successWhileImportFinalDetails: false
};

export const reducer = (state: IImportState = defaultState, action: IAction): IImportState => {
    switch (action.type) {
        case TYPES.IMPORT_FINAL_DELIVERY_DETAILS.PENDING:
            return {
                ...state,
                isIxportContainerPlanningLoading: true,
                importPlanningOceanErrors: [],
                successWhileImportFinalDetails: false,
                fileFinalDetails: null,
            };
        case TYPES.IMPORT_FINAL_DELIVERY_DETAILS.FULFILLED:
            return {
                ...state,
                isIxportContainerPlanningLoading: false,
                fileFinalDetails: action.res || defaultState.fileFinalDetails,
                successWhileImportFinalDetails: action.res ? false : true
            };
        case TYPES.IMPORT_FINAL_DELIVERY_DETAILS.REJECTED:
            return {
                ...state,
                isIxportContainerPlanningLoading: false,
                importPlanningOceanErrors: action.errors,
                successWhileImportFinalDetails: false,
                fileFinalDetails: null,
            };
        case TYPES.IMPORT_FINAL_DELIVERY_DETAILS.RESET_ERROR:
            return {
                ...state,
                importPlanningOceanErrors: [],
                fileFinalDetails: null
            };
        case TYPES.IMPORT_TRANSPORT_DETAILS.PENDING:
            return {
                ...state,
                isImportTransportDetailsLoading: true,
                importTransportDetailsErrors: {
                    errors: []
                },
                file: null,
                successWhileImport: false
            };
        case TYPES.IMPORT_TRANSPORT_DETAILS.FULFILLED:
            return {
                ...state,
                isImportTransportDetailsLoading: false,
                file: action.res || defaultState.file,
                successWhileImport: action.res ? false : true
            };
        case TYPES.IMPORT_TRANSPORT_DETAILS.REJECTED:
            return {
                ...state,
                isImportTransportDetailsLoading: false,
                importTransportDetailsErrors: {
                    errors: action.errors
                },
                file: null
            };
        case TYPES.IMPORT_TRANSPORT_DETAILS.RESET_ERROR:
            return {
                ...state,
                importTransportDetailsErrors: {
                    errors: []
                },
                file: [],
                successWhileImport: false
            };
        default:
            return state;
    }
};
