import React from 'react';
import { StatusRow } from 'components/status-row';
import { OneStatus } from 'components/status-row/OneStatus';
import { QuarterGrid } from 'components/grid/quarter-grid';
import { formatDate, formatValueIfNullOrUndefined } from 'utilities/util';

interface IDetailsRow {
    deliveryBookedDate?: string;
    deliveryPlannedDate: string;
    referencesShift: string;
}

export const DetailsRow: React.SFC<IDetailsRow> = (props: IDetailsRow) => {
    return (
        <StatusRow title="Details">
            <QuarterGrid>
                <OneStatus label="Original planned date">
                    {formatDate(props.deliveryBookedDate)}
                </OneStatus>
                <OneStatus label="Planned date">
                    {formatDate(props.deliveryPlannedDate)}
                </OneStatus>
                <OneStatus label="Shift">
                    {formatValueIfNullOrUndefined(props.referencesShift)}
                </OneStatus>
            </QuarterGrid>
        </StatusRow>
    );
};
