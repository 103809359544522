import React, { FunctionComponent, useState } from 'react';
import { Button, ButtonType } from 'components/button';
import styled from 'styled-components';
import { LoadingAnimation } from 'components/loading';
import { Dialog } from 'components/dialog';

const SDiv = styled.div`
  display: flex;
  align-items: center;
`;

const SButton = styled(Button)`
  margin-right: 10px;
`;

const SInput = styled.input`
  padding: 5px;
`;
interface AddNewCustomerProps {
  isLoading: boolean;
  requestSucceeded: boolean;
  addCustomerFunction: (name: string) => Promise<void>;
  verifyCustomerFunction: (name: string) => Promise<void>;
}

export const AddNewCustomer: FunctionComponent<AddNewCustomerProps> = ({
  isLoading,
  requestSucceeded,
  addCustomerFunction,
  verifyCustomerFunction,
}) => {
  const [customerName, setCustomerName] = useState<string>('');

  const handleCustomerNameChange = (e: any): void => {
    setCustomerName(e.target.value);
  };

  const sendNewCustomerName = (): void => {
    addCustomerFunction(customerName);
    setCustomerName('');
  };
  const verifyCustomerName = (): void => {
    verifyCustomerFunction(customerName);
  };

  if (isLoading) {
    return <LoadingAnimation />;
  }

  return (
    <React.Fragment>
      {requestSucceeded && (
        <Dialog
          isVisible={requestSucceeded}
          message="Customer has been verified/added successfully"
        />
      )}
      <SDiv>
        <SButton
          buttonType={ButtonType.Blue}
          onClick={sendNewCustomerName}
          type="button"
          isDisabled={customerName.length === 0}
        >
          Add
        </SButton>
        <SButton
          buttonType={ButtonType.Blue}
          onClick={verifyCustomerName}
          type="button"
          isDisabled={customerName.length === 0}
        >
          Verify
        </SButton>
        <SInput
          type="text"
          value={customerName}
          onChange={handleCustomerNameChange}
        />
      </SDiv>
    </React.Fragment>
  );
};
