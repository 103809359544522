import React from 'react';
import { FiltersInput } from 'modules/filters/components/FiltersInput';
import { FiltersDateInput } from 'modules/filters/components/FiltersDateInput';
import { FiltersSelect } from 'modules/filters/components/FiltersSelect';
import { statusesValues } from 'utilities/util';
import { setSortForTable } from 'utilities/tables';
import { PageHeader } from 'components/page-header';
import { Page } from 'components/page-container';
import { Filters } from 'modules/filters/containers/Filters';
import { AirDeliveryListTable } from '../../../modules/delivery-and-return/air/components/AirDeliveryListTable';
import { connect } from 'react-redux';
import { ThunkDispatch } from 'redux-thunk';
import { Action } from 'redux';
import { getAirDelivery } from 'actions/planning';
import { IStore } from 'reducers/index';
import { IAirDeliveryData } from 'reducers/planning/models';
import { LoadingAnimation } from 'components/loading';
import { DELIVERY_STATUS } from 'constants/statuses';
import { exportEquipmentDeliveryAndReturnEquipmentToExcel } from 'actions/export';
import { HandleError } from 'components/handle-error';
import { setSort, resetSort } from 'actions/filters';
import { ISortModel } from 'reducers/filters/models';

interface IMapStateToProps {
    airDelivery: IAirDeliveryData;
    isGetAirReturnPending: boolean;
    isExportLoading: boolean;
    exportEquipmentError: string;
    deliveryPlannedDateEmpty: boolean;
    deliveryActualDateEmpty: boolean;
}

interface IDispatch {
    getAirDelivery: (pageNumber: number, pageSize: number) => Promise<void>;
    exportEquipmentDeliveryAndReturnEquipmentToExcel: () => Promise<any>;
    setSort: (sort: ISortModel) => void;
    resetSort: () => void;
}

interface IAirDelivery extends IMapStateToProps, IDispatch {}

interface IAirDeliveryState {
    page: number;
    pageSize: number;
}

class _AirDelivery extends React.Component<IAirDelivery, IAirDeliveryState> {
    public state = {
        page: 1,
        pageSize: 10
    };

    public render() {
        const filters = [
            <FiltersInput
                key="HAWBNumber"
                name="HAWBNumber"
                label="HAWB number"
            />,
            <FiltersDateInput
                key="DeliveryPlannedDate_from"
                name="DeliveryPlannedDate_from"
                label="Planned delivery (from)"
                type="date"
                withTime={true}
                relatedField="deliveryPlannedDate"
                readOnly={this.props.deliveryPlannedDateEmpty}
            />,
            <FiltersDateInput
                key="DeliveryPlannedDate_to"
                name="DeliveryPlannedDate_to"
                label="Planned delivery (to)"
                type="date"
                isEndOfDay={true}
                withTime={true}
                relatedField="deliveryPlannedDate"
                readOnly={this.props.deliveryPlannedDateEmpty}
            />,
            <FiltersSelect
                key="DeliveryStatus"
                name="DeliveryStatus"
                label="Status"
                type="number"
                options={statusesValues(DELIVERY_STATUS)}
            />,
            <FiltersDateInput
                key="DeliveryActualDate_from"
                name="DeliveryActualDate_from"
                label="Actual delivery (from)"
                type="date"
                withTime={true}
                relatedField="deliveryActualDate"
                readOnly={this.props.deliveryActualDateEmpty}
            />,
            <FiltersDateInput
                key="DeliveryActualDate_to"
                name="DeliveryActualDate_to"
                label="Actual delivery (to)"
                type="date"
                isEndOfDay={true}
                withTime={true}
                relatedField="deliveryActualDate"
                readOnly={this.props.deliveryActualDateEmpty}
            />,
            <FiltersInput
                key="lastLocation"
                name="lastLocation"
                label="Last location"
            />,
            <FiltersInput
                key="POD"
                name="podName"
                label="POD"
            />,
            <FiltersInput
                key="PONumber"
                name="PONumber"
                label="PO Number"
            />,
            <FiltersInput
                key="lastUserUpdated"
                name="lastUserUpdated"
                label="Last updated by"
            />,
        ];

        return (
            <>
                <PageHeader>Air delivery</PageHeader>
                <Page>
                    {this.props.isGetAirReturnPending ||this.props.isExportLoading && <LoadingAnimation />}
                    <HandleError
                        exportEquipmentError={this.props.exportEquipmentError}
                    />
                    <Filters
                        tableNameKey="delivery-air"
                        filters={filters}
                        fetchFunction={() => this.props.getAirDelivery(this.state.page, this.state.pageSize)}
                        withoutPagination={true}
                    />
                    <AirDeliveryListTable 
                        data={this.props.airDelivery.data}
                        pageCount={Math.ceil(this.props.airDelivery.totalCount / this.props.airDelivery.pageSize)}
                        fetchEquipments={this.fetchEquipments}
                    />
                </Page>
            </>
        );
    };

    public componentWillUnmount() {
        this.props.resetSort();
    }

    private fetchEquipments = (tableData: any) => {
        this.setState({
            page: tableData.page + 1,
            pageSize: tableData.pageSize
        }, () => {
            setSortForTable(tableData.sorted, this.props.setSort);
            this.props.getAirDelivery(this.state.page, this.state.pageSize);
        });
    };
}

const mapStateToProps = (state: IStore): IMapStateToProps => ({
    airDelivery: state.planning.airDelivery,
    isGetAirReturnPending: state.planning.isGetAirReturnPending,
    exportEquipmentError: state.exportReducer.exportDeliveryAndReturnEquipmentError,
    isExportLoading: state.exportReducer.isExportDelivAndReturnEquipLoading,
    deliveryPlannedDateEmpty: state.filters.filters.deliveryplanneddate_empty,
    deliveryActualDateEmpty: state.filters.filters.deliveryactualdate_empty
});

const mapDispatchToProps = (dispatch: ThunkDispatch<any, any, Action>): IDispatch => ({
    getAirDelivery: (pageNumber: number, pageSize: number) => dispatch(getAirDelivery(pageNumber, pageSize)),
    exportEquipmentDeliveryAndReturnEquipmentToExcel: () => dispatch(exportEquipmentDeliveryAndReturnEquipmentToExcel(true)),
    setSort: (sort: ISortModel) => dispatch(setSort(sort)),
    resetSort: () => dispatch(resetSort()),
});

export const AirDelivery = connect<IMapStateToProps, IDispatch, {}, IStore>(mapStateToProps, mapDispatchToProps)(_AirDelivery);
