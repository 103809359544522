import React from 'react';
import { Field } from 'redux-form'
import { DateInput } from 'components/date-input';

class CustomPropsField extends Field<any> {}

export const FormDateInput: React.FunctionComponent<any> = (props) => (
    <CustomPropsField
        component={DateInput}
        {...props}
    />
);

