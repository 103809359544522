import { createAsyncActionConstants } from 'utilities/util';

export const TYPES = {
    EXPORT_EQUIPMENT_PLANNING_TO_EXCEL: createAsyncActionConstants('EXPORT_EQUIPMENT_PLANNING_TO_EXCEL'),
    EXPORT_EQUIP_DELIV_AND_RETURN_TO_EXCEL: createAsyncActionConstants('EXPORT_EQUIP_DELIV_AND_RETURN_TO_EXCEL'),
    EXPORT_EQUIP_DELIV_AND_RETURN_LOC_TO_EXCEL: createAsyncActionConstants('EXPORT_EQUIP_DELIV_AND_RETURN_LOC_TO_EXCEL'),
    EXPORT_DEMURRAGE_TO_EXCEL: createAsyncActionConstants('EXPORT_DEMURRAGE_TO_EXCEL'),
    EXPORT_DETENTION_TO_EXCEL: createAsyncActionConstants('EXPORT_DETENTION_TO_EXCEL'),

    EXPORT_VESSEL_TRACKING: createAsyncActionConstants('EXPORT_VESSEL_TRACKING'),
    EXPORT_CONTAINER_TRACKING: createAsyncActionConstants('EXPORT_CONTAINER_TRACKING'),

    EXPORT_HBL_RELEASE: createAsyncActionConstants('EXPORT_HBL_RELEASE'),
    EXPORT_CBL_RELEASE: createAsyncActionConstants('EXPORT_CBL_RELEASE'),

    EXPORT_CUSTOMS: createAsyncActionConstants('EXPORT_CUSTOMS'),

    EXPORT_TRANSPORT_DETAILS: createAsyncActionConstants('EXPORT_TRANSPORT_DETAILS')
}