import React, { FunctionComponent } from 'react';

interface IFiltersInput {
    onChange?: () => void; // injected
    value?: string; // injected
    name: string;
    label: string;
    type?: string;
    id?: string;
}

export const FiltersInput: FunctionComponent<IFiltersInput> = (props) => {
    if(props.type === 'number') {
        return (
            <div className="col-25">
                <div>
                    <label className="filter-label">{props.label}</label>
                    <input
                        id={props.id}
                        className="form-control"
                        onChange={props.onChange}
                        value={props.value}
                        type="number"
                        min="0"
                    />
                </div>
            </div>
        );
    }
    return (
        <div className="col-25">
            <div>
                <label className="filter-label">{props.label}</label>
                <input
                    type={props.type}
                    id={props.id}
                    className="form-control"
                    onChange={props.onChange}
                    value={props.value}
                />
            </div>
        </div>
    );
}