import React, { Component } from 'react';
import { InjectedFormProps, reduxForm, reset } from 'redux-form';
import { SingleGrid } from 'components/grid/single-grid';
import { DoubleGrid } from 'components/grid/double-grid';
import { FormInput } from 'components/input/FormInput';
import { FormDateInput } from 'components/date-input/FormDateInput';
import { FormTextArea } from 'components/textarea/FormTextArea';
import { EditCBLDetailsFormOceanFirstLine } from 'modules/tracking/containers/components/EditCBLDetailsFormOceanFirstLine';
import { EditCBLDetailsFormAirFirstLine } from 'modules/tracking/containers/components/EditCBLDetailsFormAirFirstLine';
import { connect } from 'react-redux';
import { compose, Dispatch } from 'redux';
import { IStore } from 'reducers/index';

export const FORM_NAME = 'EDIT_CBL_DETAILS';

interface IEditCblDetailsFormOwnProps {
    onSubmit: (fields: any) => void;
    // initialValues: object;
    isAir?: boolean;
}

interface IEditCblDetailsForm extends IEditCblDetailsFormOwnProps, InjectedFormProps<{}, IEditCblDetailsFormOwnProps>, IDispatch {}

export class _EditCblDetailsForm extends Component<IEditCblDetailsForm> {
    public componentWillUnmount(): void {
        this.props.resetForm();
    }

    public render() {
        const withTime = this.props.isAir ? true : false;

        return (
            <form>
                <section className="page-container">
                    {this.props.isAir ? <EditCBLDetailsFormAirFirstLine isDisabled={true} /> : <EditCBLDetailsFormOceanFirstLine isDisabled={true} /> }
                    <DoubleGrid>
                        <FormDateInput
                            name="ETD"
                            label="ETD"
                            readOnly={false}
                            clearInput={() => null}
                            withTime={withTime}
                        />
                        <FormDateInput
                            name="ETA"
                            label="ETA"
                            readOnly={false}
                            clearInput={() => null}
                            withTime={withTime}
                        />
                    </DoubleGrid>
                    <DoubleGrid>
                        <FormDateInput
                            name="ATD"
                            label="ATD"
                            readOnly={false}
                            clearInput={() => null}
                            withTime={withTime}
                        />
                        <FormDateInput
                            name="ATA"
                            label="ATA"
                            readOnly={false}
                            clearInput={() => null}
                            withTime={withTime}
                        />
                    </DoubleGrid>
                    <DoubleGrid>
                        <FormInput
                            label="POL"
                            name="POL"
                            disabled={true}
                        />
                        <FormInput
                            label="POD"
                            name="POD"
                            disabled={true}
                        />
                    </DoubleGrid>
                    <SingleGrid>
                        <FormTextArea
                            label="Comment"
                            name="comment"
                        />
                    </SingleGrid>
                </section>
            </form>
        );
    };
}

interface IDispatch {
    resetForm: () => void;
}

const mapDispatchToProps = (dispatch: Dispatch): IDispatch => ({
    resetForm: () => dispatch(reset(FORM_NAME)),
});


export const EditCblDetailsForm = compose(
    reduxForm<{}, IEditCblDetailsFormOwnProps>({
        form: FORM_NAME,
        enableReinitialize: true,
    }),
    connect<{}, IDispatch, IEditCblDetailsFormOwnProps, IStore>(undefined, mapDispatchToProps),
)(_EditCblDetailsForm);
