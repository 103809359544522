import React, { Component } from 'react';
import { PageHeader } from 'components/page-header';
import { Page } from 'components/page-container';
import { Filters } from 'modules/filters/containers/Filters';
import { FiltersInput } from 'modules/filters/components/FiltersInput';
import { FiltersDateInput } from 'modules/filters/components/FiltersDateInput';
import { FiltersSelect } from 'modules/filters/components/FiltersSelect';
import { connect } from 'react-redux';
import { ThunkDispatch } from 'redux-thunk';
import { Action } from 'redux';
import { fetchCblList } from 'actions/bill-release';
import { IStore } from 'reducers/index';
import { CBLTable } from 'modules/bill-release/cbl/components/CblTable';
import { LoadingAnimation } from 'components/loading';
import { HandleError } from 'components/handle-error';
import { surrenderType } from 'modules/bill-release/shared/components/EditBlModal.tsx/surrenderType';
import { billReleaseFilterStatuses } from 'constants/statuses';
import { ISortModel } from 'reducers/filters/models';
import { setSort, resetSort } from 'actions/filters';
import { setSortForTable } from 'utilities/tables';

interface IMapStateToProps {
    isLoading: boolean;
    cblList: any;
    isExportLoading: boolean;
    cblError: string;
    newEtaEmpty: boolean;
    ataEmpty: boolean;
}
interface IMapDispatchToProps {
    fetchCblList: (pageNumber: number, pageSize: number) => Promise<void>;
    setSort: (sort: ISortModel) => void;
    resetSort: () => void;
}

interface ICblProps extends IMapStateToProps, IMapDispatchToProps {}

interface ICblState {
    page: number;
    pageSize: number
}

class _Cbl extends Component<ICblProps, ICblState> {
    public state: ICblState = {
        page: 1,
        pageSize: 10
    };

    public render() {
        const filters = [
            <FiltersInput
                key="cblNumber"
                name="cblNumber"
                label="CBL Number"
            />,
            <FiltersInput
                key="vesselName"
                name="vesselName"
                label="Vessel name"
            />,
            <FiltersInput
                key="carrier"
                name="carrierName"
                label="Carrier"
            />,
            <FiltersInput
                key="pod"
                name="podName"
                label="POD"
            />,
            <FiltersDateInput
                type="date"
                key="newEta_from"
                name="newEta_from"
                label="New ETA (from)"
                relatedField="newEta"
                readOnly={this.props.newEtaEmpty}
            />,
            <FiltersDateInput
                type="date"
                key="newEta_to"
                name="newEta_to"
                label="New ETA (to)"
                relatedField="newEta"
                readOnly={this.props.newEtaEmpty}
            />,
            <FiltersInput
                type="number"
                key="daysTillETA_from"
                name="daysTillETA_from"
                label="Days till ETA (from)"
            />,
            <FiltersInput
                type="number"
                key="daysTillETA_to"
                name="daysTillETA_to"
                label="Days till ETA (to)"
            />,
            <FiltersDateInput
                type="date"
                key="ata_from"
                name="ata_from"
                label="ATA (from)"
                relatedField="ata"
                readOnly={this.props.ataEmpty}
            />,
            <FiltersDateInput
                type="date"
                key="ata_to"
                name="ata_to"
                label="ATA (to)"
                relatedField="ata"
                readOnly={this.props.ataEmpty}
            />,
            <FiltersSelect
                key="surrenderType"
                name="surrenderType"
                label="Surrender type"
                type="number"
                options={surrenderType}
            />,
            <FiltersSelect
                key="carrierReleaseStatus"
                name="carrierReleaseStatus"
                label="Carrier release status"
                type="number"
                options={billReleaseFilterStatuses}
            />,
            <FiltersInput
                key="lastUserUpdated"
                name="lastUserUpdated"
                label="Last updated by"
            />,
        ];
        return (
            <>
                <PageHeader>Carrier B/Ls</PageHeader>
                <Page>
                    {this.props.isExportLoading && <LoadingAnimation />}
                    <HandleError
                        cblError={this.props.cblError}
                    />
                    <Filters
                        tableNameKey="tracking-cbl"
                        filters={filters}
                        fetchFunction={() => this.props.fetchCblList(this.state.page, this.state.pageSize)}
                    />
                    <CBLTable
                        data={this.props.cblList.data}
                        isLoading={this.props.isLoading}
                        pageCount={Math.ceil(this.props.cblList.totalCount / this.props.cblList.pageSize)}
                        fetchCbls={this.fetchCbls}
                    />
                </Page>
            </>
        );
    }

    public componentWillUnmount() {
        this.props.resetSort();
    }

    private fetchCbls = (tableData: any) => {
        this.setState({
            page: tableData.page + 1,
            pageSize: tableData.pageSize
        }, () => {
            setSortForTable(tableData.sorted, this.props.setSort);
            this.props.fetchCblList(this.state.page, this.state.pageSize);
        });
        
    };
}

const mapStateToProps = (state: IStore): IMapStateToProps => ({
    isLoading: state.bill_release.isFetchCblListLoading,
    cblList: state.bill_release.cbl,
    isExportLoading: state.exportReducer.isExportCblReleaseLoading,
    cblError: state.exportReducer.exportCblError,
    newEtaEmpty: state.filters.filters.neweta_empty,
    ataEmpty: state.filters.filters.ata_empty,
});

const mapDispatchToProps = (dispatch: ThunkDispatch<any, any, Action>): IMapDispatchToProps => ({
    fetchCblList: (pageNumber: number, pageSize: number) => dispatch(fetchCblList(pageNumber, pageSize)),
    setSort: (sort: ISortModel) => dispatch(setSort(sort)),
    resetSort: () => dispatch(resetSort()),
});

export const Cbl = connect(mapStateToProps, mapDispatchToProps)(_Cbl);
