import React, { Component } from 'react';
import { withRouter, RouteComponentProps } from 'react-router-dom';
import styled from 'styled-components';

interface INavItemProps extends RouteComponentProps<{}> {
    title: string;
}

interface INavItemState {
    pathname: string;
    isDropdownOpen: boolean;
}

const Dropdown: any = styled.ul`
    border-radius: 0;
    border-top: 0;
    min-width: 250px;
    box-shadow: inherit;
    margin-left: 0;
    margin-top: -2px !important;
    padding: 8px 0;
`;

const SLink: any = styled.div`
    cursor: pointer;
    position: relative;
    color: #007bff;
    background: transparent;
    border: 0;

    &:focus {
        outline: none;
    }

    p {
        white-space: nowrap;
    }
`;

const SText = styled.p`
    color: #1E638E;

    &:hover {
        color: #003E5E;
    }
`;

class _NavItem extends Component<INavItemProps, INavItemState> {
    public static getDerivedStateFromProps(props: INavItemProps, state: INavItemState) {
        if(props.location.pathname !== state.pathname) {
            return {
                pathname: props.location.pathname,
                isDropdownOpen: false,
            }
        }
        return state
    }

    public state = {
        pathname: this.props.location.pathname,
        isDropdownOpen: false,
        mouseOnDropDownMenu: false,
    };

    public dropDownReference: any;

    public componentDidUpdate() {
        if(!this.state.isDropdownOpen) {
            document.removeEventListener('mousedown', this.handleClickOutside);
        }
    }

    public render() {
        return (
            <SLink
                className={`nav-item nav-link link ${this.state.isDropdownOpen ? 'active' : ''}`}
                onClick={this.handleShowDropDown}
            >
                <div
                    className="dropdown-wrapper"
                    ref={(reference: any) => this.dropDownReference = reference}
                >
                    <SText className="dropdown-toggle">
                        {this.props.title}
                        <i className="fa fa-angle-down" />
                    </SText>
                    <Dropdown
                        className={`dropdown-menu ${this.state.isDropdownOpen ? 'show' : '' }`}
                    >
                        {this.props.children}
                    </Dropdown>
                </div>
            </SLink>
        );
    }

    private handleShowDropDown = () => {
        this.setState({ isDropdownOpen: true });
        document.addEventListener('mousedown', this.handleClickOutside);
    };

    private handleClickOutside = (event: any) => {
        if (this.dropDownReference && this.dropDownReference.contains(event.target)) {
            return;
        } else {
            this.setState({
                isDropdownOpen: false
            })
        }
    };
}

export const NavItem = withRouter(_NavItem);
