import React from 'react';
import { StatusRow } from 'components/status-row';
import { ColumnFactory } from 'components/data-table/ColumnFactory';
import { HoldTypes } from 'constants/customs';
import { DataTable } from 'components/data-table/DamcoTable';
import { cloneDataWithDisplayNames } from 'utilities/tables';

interface IHoldsRowProps {
    data: any[];
}

export const HoldsRow = (props: IHoldsRowProps) => {
    const data = cloneDataWithDisplayNames(props.data).map((obj: any) => ({
        ...obj,
        holdTypeDisplayName: HoldTypes[obj.holdType]
    }));
    
    return(
        <StatusRow title="Holds">
            <DataTable
                data={data}
                withCheckboxes={false}
                expandable={false}
                columns={[
                    ColumnFactory.standardColumn('holdTypeDisplayName', 'Hold type'),
                    ColumnFactory.standardColumn('equipmentNumberDisplayName', 'Equipment number'),
                    ColumnFactory.standardColumn('startDateDisplayName', 'State date'),
                    ColumnFactory.standardColumn('endDateDisplayName', 'End date'),
                    ColumnFactory.standardColumn('commentDisplayName', 'Comments'),
                ]}
                tableName="FCRDetailsHoldsTable"
                reference={null as any}
                showPagination={true}
            />
        </StatusRow>
    );
};
