import { createAsyncActionConstants } from 'utilities/util';

export const TYPES = {
    UPDATE_RELEASE_DETAILS: createAsyncActionConstants('UPDATE_RELEASE_DETAILS'),
    REQUEST_RELEASE: createAsyncActionConstants('REQUEST_RELEASE'),
    FETCH_CBL_LIST: createAsyncActionConstants('FETCH_CBL_LIST'),
    FETCH_HBL_LIST: createAsyncActionConstants('FETCH_HBL_LIST'),
    FETCH_CBL_DETAILS: createAsyncActionConstants('FETCH_CBL_DETAILS'),
    FETCH_HBL_DETAILS: createAsyncActionConstants('FETCH_HBL_DETAILS'),
    EDIT_HBL_DETAILS: createAsyncActionConstants('EDIT_HBL_DETAILS'),
    FETCH_HAWB_LIST: createAsyncActionConstants('FETCH_HAWB_LIST'),
    FETCH_HAWB_DETAILS: createAsyncActionConstants('FETCH_HAWB_DETAILS'),
    EDIT_HAWB_DETAILS: createAsyncActionConstants('EDIT_HAWB_DETAILS'),
}