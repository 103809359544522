import { createAsyncActionConstants } from 'utilities/util';

export const TYPES = {
    GET_DETENTION_EQUIPMENTS: createAsyncActionConstants('GET_DETENTION_EQUIPMENTS'),
    GET_DEMURRAGE_EQUIPMENTS: createAsyncActionConstants('GET_DEMURRAGE_EQUIPMENTS'),
    GET_CONTRACTS: createAsyncActionConstants('GET_CONTRACTS'),
    EDIT_CONTRACT: createAsyncActionConstants('EDIT_CONTRACT'),
    ADD_CONTRACT: createAsyncActionConstants('ADD_CONTRACT'),
    DELETE_CONTRACT: createAsyncActionConstants('DELETE_CONTRACT'),
    EDIT_DEMURRAGE_AND_DETENTION_DETAILS: createAsyncActionConstants('EDIT_DEMURRAGE_AND_DETENTION_DETAILS'),
};