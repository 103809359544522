import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { RouteComponentProps } from 'react-router-dom';
import { ThunkDispatch } from 'redux-thunk';
import { Action } from 'redux';
import { fetchEquipmentTransportShippmentDetails } from 'actions/fact';
import { ErrorDialog } from 'components/error-dialog';
import API from 'constants/api';
import { PageHeader } from 'components/page-header';
import { IStore } from 'reducers/index';
import { IETSDetails } from 'reducers/fact/models';
import { Page } from 'components/page-container';
import { ETSDetailsTopBar } from './components/ETSDetailsTopBar';
import { EquipmentTransportRow } from './components/EquipmentTransportRow';
import { ShipmentRow } from './components/ShipmentRow';
import { VoyagesTable } from './components/VoyagesTable';
import { DocumentsTable } from './components/DocumentsTable';
import { CblTable } from './components/CblTable';
import { IssosTable } from './components/IssosTable';

interface IParams {
    etsId: string;
    equipmentId: string;
}

interface IMapDispatchToProps {
    fetchEquipmentTransportShippmentDetails: (etsId: number) => Promise<void>;
}

interface IMapStateToProps {
    etsDetails: IETSDetails;
}

interface IETSDetailsProps extends RouteComponentProps<IParams>, IMapStateToProps, IMapDispatchToProps {}

const _ETSDetails: React.StatelessComponent<IETSDetailsProps> = (props) => {
    const etsId = parseInt(props.match.params.etsId, 10);
    useEffect(() => {
        props.fetchEquipmentTransportShippmentDetails(etsId);
    }, []);

    return(
        <React.Fragment>
            <ErrorDialog endpoint={API.Fact.GetEquipmentDeliveryShippmentDetails} />
            <PageHeader backButton={true}>Equipment Transport Shipment: {props.etsDetails.documentNumber}</PageHeader>
            <ETSDetailsTopBar 
                documentNumber={props.etsDetails.documentNumber}
                createDate={props.etsDetails.createDate}
                updateDate={props.etsDetails.updateDate}
                eta={props.etsDetails.eta}
                etd={props.etsDetails.etd}
                isDeleted={props.etsDetails.isDeleted}
                packages={props.etsDetails.packages}
                volume={props.etsDetails.volume}
                quantity={props.etsDetails.quantity}
                weight={props.etsDetails.weight}
                id={etsId}
            />
            <Page>
                <EquipmentTransportRow 
                    createDate={props.etsDetails.equipmentTransport.createDate}
                    updateDate={props.etsDetails.equipmentTransport.updateDate}
                    equipmentNumber={props.etsDetails.equipmentTransport.equipmentNumber}
                    etsOnEtVolumeFactor={props.etsDetails.equipmentTransport.etsOnEtVolumeFactor}
                    packages={props.etsDetails.equipmentTransport.packages}
                    quantity={props.etsDetails.equipmentTransport.quantity}
                    sealNumber={props.etsDetails.equipmentTransport.sealNumber}
                    size={props.etsDetails.equipmentTransport.size}
                    substitutionSize={props.etsDetails.equipmentTransport.substitutionSize}
                    substitutionType={props.etsDetails.equipmentTransport.substitutionType}
                    volume={props.etsDetails.equipmentTransport.volume}
                    weight={props.etsDetails.equipmentTransport.weight}
                    etId={props.etsDetails.equipmentTransport.equipmentTransportId}
                />
                <ShipmentRow 
                    bookingAgent={props.etsDetails.shipment.bookingAgent}
                    bookings={props.etsDetails.shipment.bookings}
                    cargoType={props.etsDetails.shipment.cargoType}
                    consigneeBusinessEntityCode={props.etsDetails.shipment.consigneeBusinessEntityCode}
                    consigneeCountryCode={props.etsDetails.shipment.consigneeCountryCode}
                    consigneeName={props.etsDetails.shipment.consigneeName}
                    cosigneeFACTCode={props.etsDetails.shipment.cosigneeFACTCode}
                    documentNumber={props.etsDetails.shipment.documentNumber}
                    placeOfConsolidationCode={props.etsDetails.shipment.placeOfConsolidationCode}
                    placeOfConsolidationName={props.etsDetails.shipment.placeOfConsolidationName}
                    placeOfDeconsolidationCode={props.etsDetails.shipment.placeOfDeconsolidationCode}
                    placeOfDeconsolidationName={props.etsDetails.shipment.placeOfDeconsolidationName}
                    placeOfDeliveryCode={props.etsDetails.shipment.placeOfDeliveryCode}
                    placeOfDeliveryName={props.etsDetails.shipment.placeOfDeliveryName}
                    prepaidCollectIndicator={props.etsDetails.shipment.prepaidCollectIndicator}
                    reconsolidationPoint={props.etsDetails.shipment.reconsolidationPoint}
                    ids={[props.match.params.etsId, props.match.params.equipmentId, props.etsDetails.shipment.shipmentId.toString()]}
                />
                <CblTable cbls={props.etsDetails.cbls} />
                <DocumentsTable documents={props.etsDetails.documents} />
                <VoyagesTable voyages={props.etsDetails.voyages} />
                <IssosTable issos={props.etsDetails.issos} />
            </Page>
        </React.Fragment>
    );
};

const mapStateToProps = (state: IStore): IMapStateToProps => ({
    etsDetails: state.fact.etsDetails
});

const mapDispatchToProps = (dispatch: ThunkDispatch<any, any, Action>): IMapDispatchToProps => ({
    fetchEquipmentTransportShippmentDetails: (etsId: number) => dispatch(fetchEquipmentTransportShippmentDetails(etsId))
});

export const ETSDetails = connect<IMapStateToProps, IMapDispatchToProps, {}, IStore>(mapStateToProps, mapDispatchToProps)(_ETSDetails);