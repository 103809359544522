import React from 'react';
import { ColumnFactory } from 'components/data-table/ColumnFactory';
import { DataTable } from 'components/data-table/DamcoTable';
import { IShipmentCbl } from 'reducers/fact/models';
import { StatusRow } from 'components/status-row';
import { cloneDataWithDisplayNames } from 'utilities/tables';

interface ICblsTableProps {
    data: IShipmentCbl[];
}

export const CblsTable: React.FunctionComponent<ICblsTableProps> = (props) => {
    const data = cloneDataWithDisplayNames(props.data).map(cbl => ({
        ...cbl,
        numberDisplayName: [cbl.number, `/exception-management/fact/cbls/${cbl.id}`]
    }));

    return(
        <StatusRow title="CBLs">
            <DataTable
                data={data}
                columns={[
                    ColumnFactory.linkedColumn('numberDisplayName', 'Number'),
                    ColumnFactory.standardColumn('carrierBookingReferenceDisplayName', 'Carrier booking reference'),
                    ColumnFactory.standardColumn('carrierCodeDisplayName', 'Carrier code'),
                    ColumnFactory.standardColumn('carrierNameDisplayName', 'Carrier name'),
                    ColumnFactory.standardColumn('finalPortOfDischargeCodeDisplayName', 'Final port of discharge code'),
                    ColumnFactory.standardColumn('finalPortOfDischargeNameDisplayName', 'Final port of discharge name'),
                    ColumnFactory.standardColumn('internalNumberDisplayName', 'Internal number'),
                    ColumnFactory.standardColumn('originalPortOfLoadingCodeDisplayName', 'Original port of loading code'),
                    ColumnFactory.standardColumn('originalPortOfLoadingNameDisplayName', 'Original port of loading name'),
                    ColumnFactory.standardColumn('placeOfDeliveryCodeDisplayName', 'Place of delivery code'),
                    ColumnFactory.standardColumn('placeOfDeliveryNameDisplayName', 'Place of delivery name'),
                    ColumnFactory.standardColumn('shipperNameDisplayName', 'Shipper name'),
                    ColumnFactory.standardColumn('transportContractNumberDisplayName', 'Transport contract number')
                ]}
                withCheckboxes={false}
                reference={null as any}
                tableName="ShipmentDetailsBookingsTable"
                showPagination={true}
                expandable={false}
            />
        </StatusRow>
    );
};

