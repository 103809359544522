import React from 'react';
import { StatusRow } from 'components/status-row';
import { OneStatus } from 'components/status-row/OneStatus';
import { QuarterGrid } from 'components/grid/quarter-grid';
import { formatValueIfNullOrUndefined } from 'utilities/util';

interface IPlacesRowProps {
    placeOfReceiptCode: string;
    placeOfReceiptName: string;
    placeOfDeliveryCode: string;
    placeOfDeliveryName: string;
    portOfLoadingCode: string;
    portOfLoadingName: string;
    portOfDischargeCode: string;
    portOfDischargeName: string;
}

export const PlacesRow: React.StatelessComponent<IPlacesRowProps> = (props) => {
    return (
        <StatusRow title="Places">
            <QuarterGrid>
                <OneStatus label="Place of receipt code">
                    {formatValueIfNullOrUndefined(props.placeOfReceiptCode)}
                </OneStatus>
                <OneStatus label="Place of receipt name">
                    {formatValueIfNullOrUndefined(props.placeOfReceiptName)}
                </OneStatus>
                <OneStatus label="Place of delivery code">
                    {formatValueIfNullOrUndefined(props.placeOfDeliveryCode)}
                </OneStatus>
                <OneStatus label="Place of delivery name">
                    {formatValueIfNullOrUndefined(props.placeOfDeliveryName)}
                </OneStatus>
            </QuarterGrid>
            <QuarterGrid>
                <OneStatus label="Port of loading code">
                    {formatValueIfNullOrUndefined(props.portOfLoadingCode)}
                </OneStatus>
                <OneStatus label="Port of loading name">
                    {formatValueIfNullOrUndefined(props.portOfLoadingName)}
                </OneStatus>
                <OneStatus label="Port of discharge code">
                    {formatValueIfNullOrUndefined(props.portOfDischargeCode)}
                </OneStatus>
                <OneStatus label="Port of discharge name">
                    {formatValueIfNullOrUndefined(props.portOfDischargeName)}
                </OneStatus>
            </QuarterGrid>
        </StatusRow>
    );
};
