import React from 'react';
import ui from 'react-redux-ui-tools'

export interface IWithChildrenCheckboxesProps {
    checkedChildren: any[];
    onToggleChildrenCheckbox(row: any): void;
    clearChildrenCheckboxes(): void;
    toggleAllCheckboxes(): void;
    isAllCheckboxesSelected(): boolean;
}

interface IWithChildrenCheckboxesDataProps {
    data: any[];
}

interface IUIProps {
    ui: {
        checked: any[],
    },
    updateUI: (field: object) => void;
}

export const withChildrenCheckboxes = <P extends IWithChildrenCheckboxesDataProps>(WrappedComponent: React.ComponentType<P & IWithChildrenCheckboxesProps>, idField: string, isChildren = false, multiselect: boolean = false): React.ComponentType<P> => {
    class _WithChildrenCheckboxes extends React.Component<P & IWithChildrenCheckboxesProps & IUIProps> {
        
        public toggleCheckbox = (row: any): void => {
            if(!multiselect) {
                this.toggleCheckboxWithSingleSelection(row);
            } else {
                this.toggleCheckboxWithMultipleSelection(row);
            }
        };

        public onToggleChildrenCheckbox = (row: any): void => {
            if(this.isRowChecked(row)) {
                this.props.updateUI({ checked: [] })
            } else {
                this.props.updateUI({ checked: [row] })
            }
        };

        public toggleAllCheckboxes = (): void => {
            if(this.isAllCheckboxesSelected()) {
                this.clearAllCheckboxes();
            } else {
                this.selectAllCheckboxes();
            }
        };

        public isAllCheckboxesSelected = (): boolean => this.props.ui.checked.length === this.props.data.length;

        public render() {
            const data = isChildren ? this.getDataWithSelectedProp((this.props.data || []) as any[]) : this.props.data;
            return (
                <WrappedComponent
                    {...this.props}
                    onToggleChildrenCheckbox={multiselect ? this.toggleCheckbox : this.onToggleChildrenCheckbox}
                    checkedChildren={this.props.ui.checked}
                    data={data}
                    clearChildrenCheckboxes={this.clearAllCheckboxes}
                    toggleAllCheckboxes={this.toggleAllCheckboxes}
                    isAllCheckboxesSelected={this.isAllCheckboxesSelected}
                />
            );
        }

        private getDataWithSelectedProp = (data: any[]) => {
            return data.map((row: any) => {
                row.selected = this.isRowChecked(row);
                return {
                    ...row,
                    selected: this.isRowChecked(row),
                };
            });
        };

        private toggleCheckboxWithMultipleSelection = (row: any): void => {
            if(this.isRowChecked(row)) {
                this.removeRowFromState(row);
            } else {
                this.addRowToState(row)
            }
        };

        private toggleCheckboxWithSingleSelection = (row: any): void => {
            if(this.isRowChecked(row)) {
                this.props.updateUI({ checked: [] });
            } else {
                this.props.updateUI({ checked: [row] });
            }
        };

        private isRowChecked = (row: any): boolean => !!this.props.ui.checked.find((checkedRow: any) => {
            return checkedRow[idField] === row[idField];
        });

        private clearAllCheckboxes = (): void => this.props.updateUI({ checked: [] });

        private selectAllCheckboxes = (): void => this.props.updateUI({ checked: this.props.data });

        private removeRowFromState = (row: any): void => this.props.updateUI({
            checked: this.props.ui.checked.filter((rowToCheck: any) => rowToCheck[idField] !== row[idField])
        });

        private addRowToState = (row: any): void => this.props.updateUI({
            checked: this.props.ui.checked.concat(row)
        });
    }

    const config = {
        key: 'WITH_CHILDREN_CHECKBOXES',
        state: {
            checked: [],
        }
    };

    return ui(config)(_WithChildrenCheckboxes);
};
