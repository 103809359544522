import React from 'react';
import { OneStatus } from 'components/status-row/OneStatus';
import { StatusRow } from 'components/status-row';
import { QuarterGrid } from 'components/grid/quarter-grid';
import { formatValueIfNullOrUndefined } from 'utilities/util';

interface INonConformanceRow {
    nonConformance?: boolean;
    nonConformanceCategory?: string;
    nonConformanceComment?: string;
    nonConformanceType?: string;
    nonConformanceReference?: string;
    nonConformanceQuantity?: string;
    nonConformancePoc?: string;
}

export const NonConformanceRow: React.SFC<INonConformanceRow> = (props: INonConformanceRow) => (
    <StatusRow title="Non conformance">
        <QuarterGrid>
            <OneStatus label="Non conformance">
                {props.nonConformance ? 'Y' : 'N'}
            </OneStatus>
            <OneStatus label="Non conformance type">
                {formatValueIfNullOrUndefined(props.nonConformanceType)}
            </OneStatus>
            <OneStatus label="Reference">
                {formatValueIfNullOrUndefined(props.nonConformanceReference)}
            </OneStatus>
            <OneStatus label="Quantity">
                {formatValueIfNullOrUndefined(props.nonConformanceQuantity)}
            </OneStatus>
        </QuarterGrid>
        <QuarterGrid>
            <OneStatus label="POC">
                {formatValueIfNullOrUndefined(props.nonConformancePoc)}
            </OneStatus>
            <OneStatus label="Non conformance category">
                {formatValueIfNullOrUndefined(props.nonConformanceCategory)}
            </OneStatus>
            <OneStatus label="Comment">
                {formatValueIfNullOrUndefined(props.nonConformanceComment)}
            </OneStatus>
        </QuarterGrid>
    </StatusRow>
);
