import React from 'react';
import { LoadingAnimation } from 'components/loading';
import { ColumnFactory } from 'components/data-table/ColumnFactory';
import { DataTable } from 'components/data-table/DamcoTable';
import { downloadFile } from 'utilities/download';
import { connect } from 'react-redux';
import { ThunkDispatch } from 'redux-thunk';
import { Action } from 'redux';
import { exportHblReleaseToExcel } from 'actions/export';
import { EditHblPopup } from 'modules/bill-release/shared/components/EditBlModal.tsx/EditHblPopup';
import { surrenderType } from 'modules/bill-release/shared/components/EditBlModal.tsx/surrenderType';
import { editHblDetails, fetchHblList } from 'actions/bill-release';
// tslint:disable-next-line: no-submodule-imports
import { withPermissions, IWithPermissions } from 'src/HOC/withPermissions';
import { BUSINNESS_FUNCTIONS } from 'constants/roles';
import { getArrayOfButtons } from 'utilities/util';
import { IStore } from 'reducers/index';

export interface IHBLTable {
    selected: boolean;
}

interface IMapDispatchToProps {
    fetchHblList: (pageNumber: number, pageSize: number) => Promise<void>;
    editHblDetails: (body: any) => Promise<void>;
}

interface IMapDispatchToProps {
    exportHblReleaseToExcel: (isAir: boolean) => Promise<void>;
}

interface IHBLTableOwnProps {
    data: any;
    isLoading: boolean;
    pageCount: number;
    fetchHbls: any;
}

interface IHBLTableProps extends IWithPermissions, IHBLTableOwnProps, IMapDispatchToProps {}

interface IHBLTableState {
    isEditHblModalOpened: boolean;
}

class _HBLTable extends React.Component<IHBLTableProps, IHBLTableState> {
    public dataTable = React.createRef<any>();

    public state: IHBLTableState = {
        isEditHblModalOpened: false
    };

    public render() {
        const data = this.props.data.map((obj: any, index: number) => ({
            ...obj,
            id: index,
            hblNumber: [obj.hblNumber, `/bill-release/hbl/${obj.hblId}`]
        }));

        return(
            <React.Fragment>
                {this.props.isLoading && <LoadingAnimation />}
                <DataTable
                    data={data}
                    columns={[
                        ColumnFactory.linkedColumn('hblNumber', 'HBL number'),
                        ColumnFactory.standardColumn('vesselName', 'Vessel name'),
                        ColumnFactory.standardColumn('voyageNumber', 'Voyage number'),
                        ColumnFactory.standardColumn('destinationPort', 'POD'),
                        ColumnFactory.standardColumn('newETADisplayName', 'new ETA'),
                        ColumnFactory.standardColumn('daysTillETADisplayName', 'Days till ETA'),
                        ColumnFactory.standardColumn('ataDisplayName', 'ATA'),
                        ColumnFactory.standardColumn('billType', 'Bill type'),
                        ColumnFactory.standardColumn('surrenderType', 'Surrender Type'),
                        ColumnFactory.standardColumn('originalHBLSurrenderedDisplayName', 'Original HB/L surrendered'),
                        ColumnFactory.standardColumn('originalHBLSurrenderDateDisplayName', 'Surrender date')
                    ]}
                    withCheckboxes={true}
                    selectVariant="single"
                    reference={ this.dataTable}
                    tableName="HBLReleaseList"
                    actions={{
                        primaryActionIsMoreBtn: false,
                        primaryActionLabel: 'Export to excel',
                        primaryAction: this.exportToExcel,
                        parentContextualActions: getArrayOfButtons(
                            this.props.permissions,
                            [
                                { isMoreBtn: false, label: 'Update HBL details', action: this.toggleEditHblModal }
                            ])
                    }}
                    manualPagination={true}
                    fetchData={this.props.fetchHbls}
                    pageCount={this.props.pageCount}
                    showColumnOptions={true}
                />
                {this.state.isEditHblModalOpened && <EditHblPopup
                    visible={this.state.isEditHblModalOpened}
                    closeModal={this.toggleEditHblModal}
                    blId={this.dataTable.current ? this.dataTable.current.getSelectedItems()[0].hblId : 0}
                    initialValues={this.getInitialValuesForEditHblDetails()}
                    isSurrendered={false}
                    submitFunc={this.editHblDetails}
                    isHbl={true}
                    modalName="HBL"
                />}
            </React.Fragment>
        );
    };

    private exportToExcel = async () => {
        const res = await this.props.exportHblReleaseToExcel(false);
        await downloadFile(res);
    };

    private toggleEditHblModal = () => this.setState((state) => ({
        isEditHblModalOpened: !state.isEditHblModalOpened
    }));

    private editHblDetails = async (data: any) => {
        await this.props.editHblDetails(data);
        await this.props.fetchHblList(1, 10);
    };

    private getInitialValuesForEditHblDetails = () => {
        const selectedItems = this.dataTable.current ? this.dataTable.current.getSelectedItems() : [];
        
        if (selectedItems[0]) {
            const type = surrenderType.find(({ optionText }) => optionText === selectedItems[0].surrenderType)
            return {
                SurrenderType: type ? type.value : '',
                SurrenderedDate: selectedItems[0].originalHBLSurrenderDate,
            }
        }
        return {};
    };
}

const ROLES: BUSINNESS_FUNCTIONS[] = [BUSINNESS_FUNCTIONS.OCEAN_HBL_RELEASE_EDIT_HBL];

const mapDispatchToProps = (dispatch: ThunkDispatch<any, any, Action>): IMapDispatchToProps => ({
    exportHblReleaseToExcel: (isAir: boolean) => dispatch(exportHblReleaseToExcel(isAir)),
    editHblDetails: (body: any) => dispatch(editHblDetails(body)),
    fetchHblList: (pageNumber: number, pageSize: number) => dispatch(fetchHblList(pageNumber, pageSize)),
})

const __HBLTable = connect<undefined, IMapDispatchToProps, IHBLTableOwnProps, IStore>(undefined, mapDispatchToProps)(_HBLTable);
export const HBLTable = withPermissions(__HBLTable, ROLES) as any;
