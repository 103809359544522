import React from 'react';
import { StatusRow } from 'components/status-row';
import { OneStatus } from 'components/status-row/OneStatus';
import { QuarterGrid } from 'components/grid/quarter-grid';
import { formatValueIfNullOrUndefined } from 'utilities/util';

interface IIssuerRowProps {
    shipperCountryCode: string;
    shipperBusinessEntityCode: string;
    shipperName: string;
    shipperFactCode: string;
    shipperSCVCode: string;
}

export const ShipperRow: React.StatelessComponent<IIssuerRowProps> = (props) => {
    return (
        <StatusRow title="Shipper">
            <QuarterGrid>
                <OneStatus label="Shipper country code">
                    {formatValueIfNullOrUndefined(props.shipperCountryCode)}
                </OneStatus>
                <OneStatus label="Shipper business entity code">
                    {formatValueIfNullOrUndefined(props.shipperBusinessEntityCode)}
                </OneStatus>
                <OneStatus label="Shipper name">
                    {formatValueIfNullOrUndefined(props.shipperName)}
                </OneStatus>
                <OneStatus label="Shipper fact code">
                    {formatValueIfNullOrUndefined(props.shipperFactCode)}
                </OneStatus>
            </QuarterGrid>
            <QuarterGrid>
                <OneStatus label="Shipper SCV code">
                    {formatValueIfNullOrUndefined(props.shipperSCVCode)}
                </OneStatus>
            </QuarterGrid>
        </StatusRow>
    );
};
