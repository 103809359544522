// TODO: this component should replace all LoadingIndicatiors around
import React, { SFC } from 'react';
import { connect } from 'react-redux';
import { IStore } from 'reducers/index';
import { LoadingAnimation } from 'components/loading';

interface IMapStateToProps {
    shouldDisplay: boolean;
}

const _GlobalLoading: SFC<IMapStateToProps> = props => {
  return props.shouldDisplay ? <LoadingAnimation /> : null;
};

const mapStateToProps = (store: IStore): IMapStateToProps => ({
    shouldDisplay: store.api.currentRequests > 0
});

export const GlobalLoading = connect<IMapStateToProps, null, {}, IStore>(
    mapStateToProps,
)(_GlobalLoading);

