enum surrenderTypes {
    Original = 1,
    Surrendered = 2,
    Telex = 3,
    NotApplicable = 4,
};

export const surrenderType = [
    { optionText: 'Original', value: surrenderTypes.Original },
    { optionText: 'Surrendered', value: surrenderTypes.Surrendered },
    { optionText: 'Telex', value: surrenderTypes.Telex },
    { optionText: 'Not applicable', value: surrenderTypes.NotApplicable },
];