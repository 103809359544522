import React from 'react';
import { Column } from 'react-table';
import { ColumnFactory } from 'components/data-table/ColumnFactory';
import { formatValueIfNullOrUndefined } from 'utilities/util';

export const StandardColumnWithDisabledSort = (accessor: string, name: string, sortable: boolean = false): Column => {
    const cell = (rowInfo: { value: string }) => (
        <div>
            {formatValueIfNullOrUndefined(rowInfo.value)}
        </div>
    );
    return ColumnFactory.getColumnOptions(accessor, name, cell, '', sortable);
};