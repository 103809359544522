import { IStore } from 'reducers/index';
import { callApiPost } from 'utilities/apiCaller';
import { ThunkDispatch } from 'redux-thunk';
import { Action } from 'redux';
import { TYPES } from 'action-types/export';
import API from 'constants/api';
import { applyFiltersWithoutPageDetails, generateError } from 'utilities/util';

export const exportEquipmentPlanningToExcel = (isAir: boolean, downloadForReImport: boolean = false, ids?: number[]) => {
    return async (dispatch: ThunkDispatch<any, any, Action>, getState: () => IStore) => {
        dispatch({ type: TYPES.EXPORT_EQUIPMENT_PLANNING_TO_EXCEL.PENDING });
        try {
            const Filters = applyFiltersWithoutPageDetails(getState, { 
                isAirInFilters: isAir ? true : false,
                isAirInBody: isAir ? true : false 
            }, { 
                Filters: {
                    ...getState().filters.appliedFilters,
                    TransportMode: isAir ? 'Air' : 'Ocean',
                    equipmentId: ids
                },
                downloadForReImport 
            });
            const res = await callApiPost(API.Export.ExportEquipmentPlanningToExcel, Filters, { 
                responseType: 'blob', 
                headers: {
                    TransportMode: isAir ? 'Air' : 'Ocean'
                } 
            });
            dispatch({ type: TYPES.EXPORT_EQUIPMENT_PLANNING_TO_EXCEL.FULFILLED, res });
            return res.data;
        } catch (e) {
            const error = generateError(e);
            dispatch({ type: TYPES.EXPORT_EQUIPMENT_PLANNING_TO_EXCEL.REJECTED, error });
            throw e;
        }
    }
};

export const exportEquipmentDeliveryAndReturnEquipmentToExcel = (isAir: boolean) => {
    return async (dispatch: ThunkDispatch<any, any, Action>, getState: () => IStore) => {
        dispatch({ type: TYPES.EXPORT_EQUIP_DELIV_AND_RETURN_TO_EXCEL.PENDING });
        try {
            const Filters = applyFiltersWithoutPageDetails(getState, { 
                isAirInFilters: isAir ? true : false, 
                isAirInBody: isAir ? true : false 
            });
            const res = await callApiPost(API.Export.ExportEquipmentDeliveryAndReturnEquipmentToExcel, Filters, {
                responseType: 'blob',
                headers: {TransportMode: isAir ? 'Air' : 'Ocean'}
            });
            dispatch({ type: TYPES.EXPORT_EQUIP_DELIV_AND_RETURN_TO_EXCEL.FULFILLED, res });
            return res.data;
        } catch (e) {
            const error = generateError(e);
            dispatch({ type: TYPES.EXPORT_EQUIP_DELIV_AND_RETURN_TO_EXCEL.REJECTED, error });
            throw e;
        }
    }
};

export const exportEquipmentDeliveryAndReturnLocationToExcel = (isAir: boolean) => {
    return async (dispatch: ThunkDispatch<any, any, Action>, getState: () => IStore) => {
        dispatch({ type: TYPES.EXPORT_EQUIP_DELIV_AND_RETURN_LOC_TO_EXCEL.PENDING });
        try {
            const Filters = applyFiltersWithoutPageDetails(getState, { 
                isAirInFilters: isAir ? true : false,
                isAirInBody: isAir ? true : false
            });
            if (Filters.Filters.TransportMode === 'air' || Filters.Filters.TransportMode === 'ocean') {
                Filters.Filters.TransportMode = undefined as any;
            }
            const res = await callApiPost(API.Export.ExportEquipmentDeliveryAndReturnLocationToExcel, {
                ...Filters,
                TransportMode: isAir ? 'Air' : 'Ocean'
            }, {
                responseType: 'blob',
                headers: {TransportMode: isAir ? 'Air' : 'Ocean'}
            });
            dispatch({ type: TYPES.EXPORT_EQUIP_DELIV_AND_RETURN_LOC_TO_EXCEL.FULFILLED, res });
            return res.data;
        } catch (e) {
            const error = generateError(e);
            dispatch({ type: TYPES.EXPORT_EQUIP_DELIV_AND_RETURN_LOC_TO_EXCEL.REJECTED, error });
            throw e;
        }
    }
};

export const exportDemurrageToExcel = (isAir: boolean) => {
    return async (dispatch: ThunkDispatch<any, any, Action>, getState: () => IStore) => {
        dispatch({ type: TYPES.EXPORT_DEMURRAGE_TO_EXCEL.PENDING });
        try {
            const Filters = applyFiltersWithoutPageDetails(getState, { 
                isAirInFilters: isAir ? true : false, 
                isAirInBody: isAir ? true : false
            });
            const res = await callApiPost(API.Export.ExportDemurrageToExcel, {
                ...Filters,
                TransportMode: isAir ? 'Air' : 'Ocean'
            }, {
                responseType: 'blob',
                headers: {TransportMode: isAir ? 'Air' : 'Ocean'}
            });
            dispatch({ type: TYPES.EXPORT_DEMURRAGE_TO_EXCEL.FULFILLED, res });
            return res.data;
        } catch (e) {
            const error = generateError(e);
            dispatch({ type: TYPES.EXPORT_DEMURRAGE_TO_EXCEL.REJECTED, error });
            throw e;
        }
    }
};

export const exportDetentionToExcel = (isAir: boolean) => {
    return async (dispatch: ThunkDispatch<any, any, Action>, getState: () => IStore) => {
        dispatch({ type: TYPES.EXPORT_DETENTION_TO_EXCEL.PENDING });
        try {
            const Filters = applyFiltersWithoutPageDetails(getState, { 
                isAirInFilters: isAir ? true : false,
                isAirInBody: isAir ? true : false
            });
            const res = await callApiPost(API.Export.ExportDetentionToExcel, {
                ...Filters,
                TransportMode: isAir ? 'Air' : 'Ocean'
            }, {
                responseType: 'blob',
                headers: {TransportMode: isAir ? 'Air' : 'Ocean'}
            });
            dispatch({ type: TYPES.EXPORT_DETENTION_TO_EXCEL.FULFILLED, res });
            return res.data;
        } catch (e) {
            const error = generateError(e);
            dispatch({ type: TYPES.EXPORT_DETENTION_TO_EXCEL.REJECTED, error });
            throw e;
        }
    }
};

export const exportVesselTrackingToExcel = () => {
    return async (dispatch: ThunkDispatch<any, any, Action>, getState: () => IStore) => {
        const Filters = applyFiltersWithoutPageDetails(getState, { isAirInFilters: false });
        const res = await callApiPost(API.Export.ExportVesselTrackingToExcel, Filters, {
            responseType: 'blob',
            headers: {TransportMode: 'Ocean'}
        });

        return res.data;
    }
};

export const exportContainerTrackingToExcel = (isAir: boolean) => {
    return async (dispatch: ThunkDispatch<any, any, Action>, getState: () => IStore) => {
        dispatch({ type: TYPES.EXPORT_CONTAINER_TRACKING.PENDING });
        try {
            const Filters = applyFiltersWithoutPageDetails(getState, { 
                isAirInFilters: isAir ? true : false,
                isAirInBody: isAir ? true : false
            });
            const res = await callApiPost(API.Export.ExportContainerTrackingToExcel, Filters, {
                responseType: 'blob',
                headers: {TransportMode: isAir ? 'Air' : 'Ocean'}
            });
            dispatch({ type: TYPES.EXPORT_CONTAINER_TRACKING.FULFILLED, res });
            return res.data;
        } catch (e) {
            const error = generateError(e);
            dispatch({ type: TYPES.EXPORT_CONTAINER_TRACKING.REJECTED, error });
            throw e;
        }
    }
};

export const exportHblReleaseToExcel = (isAir: boolean) => {
    return async (dispatch: ThunkDispatch<any, any, Action>, getState: () => IStore) => {
        dispatch({ type: TYPES.EXPORT_HBL_RELEASE.PENDING });
        try {
            const Filters = applyFiltersWithoutPageDetails(getState, { 
                isAirInFilters: isAir ? true : false ,
                isAirInBody: isAir ? true : false
            });
            const res = await callApiPost(API.Export.ExportHblReleaseToExcel, Filters, {
                responseType: 'blob',
                headers: {TransportMode: isAir ? 'Air' : 'Ocean'}
            });
            dispatch({ type: TYPES.EXPORT_HBL_RELEASE.FULFILLED, res });
            return res.data;
        } catch (e) {
            const error = generateError(e);
            dispatch({ type: TYPES.EXPORT_HBL_RELEASE.REJECTED, error });
            throw e;
        }
    }
};

export const exportCblReleaseToExcel = () => {
    return async (dispatch: ThunkDispatch<any, any, Action>, getState: () => IStore) => {
        dispatch({ type: TYPES.EXPORT_CBL_RELEASE.PENDING });
        try {
            const Filters = applyFiltersWithoutPageDetails(getState, { isAirInFilters: false });
            const res = await callApiPost(API.Export.ExportCblReleaseToExcel, Filters, {
                responseType: 'blob',
                headers: {TransportMode: 'Ocean'}
            });
            dispatch({ type: TYPES.EXPORT_CBL_RELEASE.FULFILLED, res });
            return res.data;
        } catch (e) {
            const error = generateError(e);
            dispatch({ type: TYPES.EXPORT_CBL_RELEASE.REJECTED, error });
            throw e;
        }
    }
};

export const exportCustomsToExcel = (isAir: boolean) => {
    return async (dispatch: ThunkDispatch<any, any, Action>, getState: () => IStore) => {
        dispatch({ type: TYPES.EXPORT_CUSTOMS.PENDING });
        try {
            const Filters = applyFiltersWithoutPageDetails(getState, { 
                isAirInFilters: isAir ? true : false,
                isAirInBody: isAir ? true : false
            });
            const res = await callApiPost(API.Export.ExportCustomsToExcel, Filters, {
                responseType: 'blob',
                headers: {TransportMode: isAir ? 'Air' : 'Ocean'}
            });
            dispatch({ type: TYPES.EXPORT_CUSTOMS.FULFILLED, res });
            return res.data;
        } catch (e) {
            const error = generateError(e);
            dispatch({ type: TYPES.EXPORT_CUSTOMS.REJECTED, error });
            throw e;
        }
    }
};

export const exportCustomsReleaseToExcel = (isAir?: boolean) => {
    return async (dispatch: ThunkDispatch<any, any, Action>, getState: () => IStore) => {
        const Filters = applyFiltersWithoutPageDetails(getState, { isAirInFilters: isAir ? true : false, isAirInBody: isAir ? true : false });
        const res = await callApiPost(API.Export.ExportCustomsReleaseToExcel, Filters, {
            responseType: 'blob',
            headers: {TransportMode: isAir ? 'Air' : 'Ocean'}
        });

        return res.data;
    }
};

export const exportTransportDetailsToExcel = (isAir: boolean, onlyTemplate: boolean, ids?: number[]) => {
    return async (dispatch: ThunkDispatch<any, any, Action>) => {
        dispatch({ type: TYPES.EXPORT_TRANSPORT_DETAILS.PENDING });
        try {
            const res = await callApiPost(API.Export.ExportTransportDetailsToExcel, {
                equipmentIds: ids,
                TransportType: isAir ? 'Air' : 'Ocean',
                onlyTemplate: onlyTemplate ? true : false
            }, { 
                responseType: 'blob', 
                headers: {
                    TransportType: isAir ? 'Air' : 'Ocean'
                } 
            });
            dispatch({ type: TYPES.EXPORT_TRANSPORT_DETAILS.FULFILLED, res });
            return res.data;
        } catch (e) {
            const error = generateError(e);
            dispatch({ type: TYPES.EXPORT_TRANSPORT_DETAILS.REJECTED, error });
            throw e;
        }
    }
};