import React, { FunctionComponent, useRef, useState } from 'react';
import { ColumnFactory } from 'components/data-table/ColumnFactory';
import { LoadingAnimation } from 'components/loading';
import { ISettingsModel, ICreateSettingsModel } from 'reducers/settings/models';
import { ConfigurationModal } from './ConfigurationModal';
import { DataTable } from 'components/data-table/DamcoTable';
import { connect } from 'react-redux';
import { ThunkDispatch } from 'redux-thunk';
import { Action } from 'redux';
import { IStore } from 'reducers/index';
import { fetchDeliveryPlanningHaulierSettings, createHauliersConfiguration, deleteDeliveryPlanningHauliersConfiguration, updateDeliveryPlanningHaulierConfiguration } from 'actions/settings';
import { ErrorModal } from 'modules/modals/components/ErrorModal';

interface IDispatch {
    fetchDeliveryPlanningHaulierSettings: () => any;
    createHauliersConfiguration: (configuration: ICreateSettingsModel) => Promise<void>;
    deleteDeliveryPlanningHauliersConfiguration: (id: string) => Promise<void>;
    updateDeliveryPlanningHaulierConfiguration: (configuration: ISettingsModel) => Promise<void>;
}

interface IMapStateToProps {
    updateHaulierSettingsPending: boolean;
    createHaulierSettingsPending: boolean;
}

interface IHaulierTableOwnProps {
    isLoading: boolean;
    data: ISettingsModel[];
}

interface IHaulierTable extends IHaulierTableOwnProps, IMapStateToProps, IDispatch {}

const _HaulierTable: FunctionComponent<IHaulierTable> = (props) => {
    const [isEditConfigurationModalOpened, setEditConfigurationModalOpened] = useState<boolean>(false);
    const [isCreateHauliersConfigurationModalOpened, setCreateHauliersConfigurationModalOpened] = useState<boolean>(false);
    const [isErrorModalOpen, setErrorModalOpen] = useState<boolean>(false);

    const dataTable = useRef<any>();

    const toggleCreateHauliersConfigurationModal = (): void => setCreateHauliersConfigurationModalOpened(!isCreateHauliersConfigurationModalOpened);

    const toggleEditModal = (id?: string): void => setEditConfigurationModalOpened(!isEditConfigurationModalOpened);

    const toggleErrorModal = (): void => setErrorModalOpen(!isErrorModalOpen);

    const getRowToEdit = (): object => {
        const selectedItems = dataTable.current ? dataTable.current.getSelectedItems() : [];

        return {
            Name: selectedItems[0].name,
            Email: selectedItems[0].email,
            Subject: selectedItems[0].subject,
            Country: selectedItems[0].country,
            BeCode: selectedItems[0].beCode,
            Body: selectedItems[0].body,
            Id: selectedItems[0].id,
        }
    };

    const getId = (): string => {
        const selectedItems = dataTable.current ? dataTable.current.getSelectedItems() : [];
        return selectedItems[0].id;
    };

    const createNewHauliersConfiguration = async (configuration: ICreateSettingsModel): Promise<void> => {
        await props.createHauliersConfiguration(configuration);
        toggleCreateHauliersConfigurationModal();
        props.fetchDeliveryPlanningHaulierSettings();
    };

    const deleteHauliersConfiguration = async (): Promise<void> => {
        await props.deleteDeliveryPlanningHauliersConfiguration(getId());
        props.fetchDeliveryPlanningHaulierSettings();
    };

    const updateHauliersConfiguration = async (configuration: ISettingsModel): Promise<void> => {
        await props.updateDeliveryPlanningHaulierConfiguration(configuration);
        props.fetchDeliveryPlanningHaulierSettings();
    }

    return (
        <React.Fragment>
            {props.isLoading && <LoadingAnimation />}
            <DataTable
                data={props.data}
                expandable={false}
                withCheckboxes={true}
                selectVariant="single"
                columns={[
                    ColumnFactory.standardColumn('name', 'Name'),
                    ColumnFactory.standardColumn('email', 'Email'),
                    ColumnFactory.standardColumn('subject', 'Subject'),
                    ColumnFactory.standardColumn('body', 'Body'),
                    ColumnFactory.standardColumn('country', 'Country'),
                    ColumnFactory.standardColumn('beCode', 'BeCode'),
                ]}
                actions={{
                    primaryActionIsMoreBtn: false,
                    primaryActionLabel: 'Add new',
                    primaryAction: toggleCreateHauliersConfigurationModal,
                    parentContextualActions: [
                        { isMoreBtn: false, label: 'Edit', action: toggleEditModal },
                        { isMoreBtn: false, label: 'Delete', action: deleteHauliersConfiguration },
                    ]
                }}
                reference={dataTable}
                tableName="SettingsBrokersCustomsTable"
                showPagination={true}
            />
            {isCreateHauliersConfigurationModalOpened && <ConfigurationModal
                title="Create haulier configuration"
                visible={isCreateHauliersConfigurationModalOpened}
                closeModal={toggleCreateHauliersConfigurationModal}
                saveFunction={createNewHauliersConfiguration}
                isLoading={props.createHaulierSettingsPending}
            />}
            {isEditConfigurationModalOpened && <ConfigurationModal
                title="Edit haulier configuration"
                visible={isEditConfigurationModalOpened}
                closeModal={toggleEditModal}
                saveFunction={updateHauliersConfiguration}
                initialValues={getRowToEdit()}
                isLoading={props.updateHaulierSettingsPending}
            />}
            {isErrorModalOpen && <ErrorModal
                visible={isErrorModalOpen}
                closeModal={toggleErrorModal}
                primaryButtonFunc={toggleErrorModal}
            />}
        </React.Fragment>
    );
}

const mapStateToProps = (state: IStore): IMapStateToProps => ({
    updateHaulierSettingsPending: state.settings.updateDeliveryPlanningHaulierSettingsPending,
    createHaulierSettingsPending: state.settings.createDeliveryPlanningHaulierSettingsPending,
});

const mapDispatchToProps = (dispatch: ThunkDispatch<any, any, Action>): IDispatch => ({
    fetchDeliveryPlanningHaulierSettings: () => dispatch(fetchDeliveryPlanningHaulierSettings(true)),
    createHauliersConfiguration: (configuration: ICreateSettingsModel) => dispatch(createHauliersConfiguration(configuration)),
    deleteDeliveryPlanningHauliersConfiguration: (id: string) => dispatch(deleteDeliveryPlanningHauliersConfiguration(id)),
    updateDeliveryPlanningHaulierConfiguration: (configuration: ISettingsModel) => dispatch(updateDeliveryPlanningHaulierConfiguration(configuration)),
});

export const HaulierTable = connect<IMapStateToProps, IDispatch, IHaulierTableOwnProps, IStore>(mapStateToProps, mapDispatchToProps)(_HaulierTable);