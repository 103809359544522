import React from 'react';
import { OneStatus } from 'components/status-row/OneStatus';
import { StatusRow } from 'components/status-row';
import { QuarterGrid } from 'components/grid/quarter-grid';
import { formatValueIfNullOrUndefined } from 'utilities/util';

interface IDangerousGoodsRow {
    isDangerousGoods?: boolean;
    dangerousGoodsUNNO?: string;
    dangerousGoodsClass?: string;
    dangerousGoodsDescription?: string;
    dangerousGoodsADRRequired?: boolean;
}

export const DangerousGoodsRow: React.SFC<IDangerousGoodsRow> = (props: IDangerousGoodsRow) => (
    <StatusRow title="Dangerous goods">
        <QuarterGrid>
            <OneStatus label="Dangerous goods">
                {props.isDangerousGoods ? 'Y' : 'N'}
            </OneStatus>
            <OneStatus label="UNNO">
                {formatValueIfNullOrUndefined(props.dangerousGoodsUNNO)}
            </OneStatus>
            <OneStatus label="IMO class">
                {formatValueIfNullOrUndefined(props.dangerousGoodsClass)}
            </OneStatus>
            <OneStatus label="Description">
                {formatValueIfNullOrUndefined(props.dangerousGoodsDescription)}
            </OneStatus>
        </QuarterGrid>
        <QuarterGrid>
            <OneStatus label="ADR required">
                {props.dangerousGoodsADRRequired ? 'Y' : 'N'}
            </OneStatus>
        </QuarterGrid>
    </StatusRow>
);
