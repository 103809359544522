import { ColumnFactory } from 'components/data-table/ColumnFactory';
import React from 'react';
import { Column } from 'react-table';

export const iconColumn = (accessor: string, name: string, iconClass: string, onClick: (id: string) => void): Column => {
    const cell = (rowInfo: { value: string }) => (
        <button onClick={() => onClick(rowInfo.value)}>
            <i className={`fa ${iconClass}`} aria-hidden="true" />
        </button>
    );
    return ColumnFactory.getColumnOptions(accessor, name, cell);
};
