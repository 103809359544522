import React from 'react';
import { FormInput } from 'components/input/FormInput';
import { DoubleGrid } from 'components/grid/double-grid';

interface IEditCBLDetailsFormAirFirstLine {
    isDisabled?: boolean;
}

export const EditCBLDetailsFormAirFirstLine: React.SFC<IEditCBLDetailsFormAirFirstLine> = (props: IEditCBLDetailsFormAirFirstLine) => (
    <DoubleGrid>
        <FormInput
            label="Flight number"
            name="FlightNumber"
            disabled={props.isDisabled}
        />
    </DoubleGrid>
);
