import React, { FunctionComponent, useRef } from 'react';
import { DataTable } from 'components/data-table/DamcoTable';
import { ColumnFactory } from 'components/data-table/ColumnFactory';
import { BUSINNESS_FUNCTIONS } from 'constants/roles';
// tslint:disable-next-line: no-submodule-imports
import { withPermissions, IWithPermissions } from 'src/HOC/withPermissions';
import { cloneDataWithDisplayNames } from 'utilities/tables';
import { IEquipmentList } from 'reducers/fact/models';

interface IEquipmentsTableOwnProps {
    data: IEquipmentList[];
    pageCount: number;
    fetchEquipments: any;
}

interface IEquipmentsTableProps extends IWithPermissions, IEquipmentsTableOwnProps {}

const _EquipmentsTable: FunctionComponent<IEquipmentsTableProps> = (props) => {
    const dataTable = useRef<any>();
    const data = cloneDataWithDisplayNames(props.data).map(equipment => ({
        ...equipment,
        equipmentNumberDisplayName: [equipment.equipmentNumber, `/exception-management/fact/equipments/${equipment.equipmentId}`]
    }));
    
    return (
        <React.Fragment>
            <DataTable
                data={data}
                showColumnOptions={true}
                columns={[
                    ColumnFactory.linkedColumn('equipmentNumberDisplayName', 'Equipment number'),
                    ColumnFactory.standardColumn('sealNumberDisplayName', 'Seal number'),
                    ColumnFactory.dateColumn('createDateDisplayName', 'Create date'),
                    ColumnFactory.dateColumn('updateDateDisplayName', 'Update date'),
                    ColumnFactory.standardColumn('isGOHDisplayName', 'Is GOH'),
                    ColumnFactory.standardColumn('isDangerousGoodsDisplayName', 'Is DG'),
                    ColumnFactory.standardColumn('equipmentTypeDisplayName', 'Equipment type'),
                    ColumnFactory.standardColumn('equipmentSizeDisplayName', 'Equipment size'),
                    ColumnFactory.standardColumn('equipmentSubstitutionSizeDisplayName', 'Equipment substitution size'),
                    ColumnFactory.standardColumn('equipmentSubstitutionTypeDisplayName', 'Equipment substitution type'),
                    ColumnFactory.standardColumn('serviceAtDestinationDisplayName', 'Service at destination'),
                    ColumnFactory.standardColumn('serviceAtOriginDisplayName', 'Service at origin'),
                    ColumnFactory.standardColumn('actualDeliveryDateTimeDisplayName', 'Actual delivery date time'),
                    ColumnFactory.standardColumn('volumeDisplayName', 'Volume'),
                ]}
                subColumns={[
                    ColumnFactory.standardColumn('documentNumberDisplayName', 'Document number'),
                    ColumnFactory.standardColumn('originalDocumentNumberDisplayName', 'Original document number'),
                    ColumnFactory.standardColumn('documentTypeDisplayName', 'Document type'),
                    ColumnFactory.dateColumn('createDateDisplayName', 'Create date'),
                    ColumnFactory.dateColumn('updateDateDisplayName', 'Update date'),
                ]}
                expandable={true}
                withCheckboxes={false}
                tableName="FactEquipmentsList"
                reference={dataTable}
                pageCount={props.pageCount}
                manualPagination={true}
                fetchData={props.fetchEquipments}
            />
        </React.Fragment>
    );
}

const ROLES: BUSINNESS_FUNCTIONS[] = [];

export const EquipmentsTable = withPermissions(_EquipmentsTable, ROLES) as any;
