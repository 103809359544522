import React, { Component } from 'react';
import { LoadingAnimation } from 'components/loading';
import { ColumnFactory } from 'components/data-table/ColumnFactory';
import { ThunkDispatch } from 'redux-thunk';
import { Action } from 'redux';
import { exportCustomsReleaseToExcel } from 'actions/export';
import { connect } from 'react-redux';
import { DataTable } from 'components/data-table/DamcoTable';
import { uniqWith, isEqual, xorWith } from 'lodash';
import { fetchHBLFCRDetailsOnList } from 'actions/customs';
import { mapStatusToStringForCT, mapStatusToStringForCustomsRelease } from 'constants/statuses';
import { UpdateClearanceForCustomsRelease } from 'modules/customs/ocean/components/UpdateClearanceForCustomsRelease';
import { Dialog } from 'components/dialog';
import { downloadFile } from 'utilities/download';
import { cloneDataWithDisplayNames } from 'utilities/tables';

export interface ICustomsTable {
    selected: boolean;
}

interface IMapDispatchToProps {
    fetchHBLFCRDetails: (id: string, voyageId: string) => Promise<any>;
    exportCustomsReleaseToExcel: () => Promise<void>;
}

interface ICustomsTableOwnProps {
    data: any;
    isLoading: boolean;
    pageCount: number;
    fetchBls: any;
    refreshFunction: () => Promise<void>;
}

interface IHAWBReleaseTableProps extends ICustomsTableOwnProps, IMapDispatchToProps {}

interface IHAWBReleaseTableState {
    isEditMultipleContainersModalOpened: boolean;
    details: any[];
    isErrorDialogOpened: boolean;
    errorMessage: string;
}

class _HAWBRelease extends Component<IHAWBReleaseTableProps, IHAWBReleaseTableState> {
    public dataTable = React.createRef<any>();

    public state = {
        isEditMultipleContainersModalOpened: false,
        details: [],
        isErrorDialogOpened: false,
        errorMessage: 'Some error occured'
    };

    public componentDidUpdate(prevProps: IHAWBReleaseTableProps) {
        const isArrayEqual = (x: any[], y: any[]) => xorWith(x, y, isEqual).length === 0;

        if(!isArrayEqual(this.props.data, prevProps.data)) {
            this.setState({ isErrorDialogOpened: false });
        }
    }

    public render() {
        const data = cloneDataWithDisplayNames(this.props.data, 'DD-MM-YYYY HH:mm').map((obj: any, index: number) => ({
            ...obj,
            statusDisplayName: mapStatusToStringForCT(obj.status),
            children: obj.equipments.map((child: any, localIndex: number) => ({
                ...child,
                id: index.toFixed(1) + localIndex / 10,
                parentNode: 0,
                voyageId: obj.voyageId,
                cL1_StatusDisplayName: mapStatusToStringForCustomsRelease(child.customsHeaderStatus)
            }))
        }));
        
        return(
            <React.Fragment>
                {this.props.isLoading && <LoadingAnimation />}
                {this.state.isErrorDialogOpened && <Dialog 
                    isVisible={this.state.isErrorDialogOpened}
                    message={this.state.errorMessage} 
                    closeDialog={this.toggleErrorDialog}
                />}
                <DataTable
                    data={data}
                    columns={[
                        ColumnFactory.standardColumn('vesselNameDisplayName', 'Flight number'),
                        ColumnFactory.standardColumn('polDisplayName', 'POL'),
                        ColumnFactory.standardColumn('podDisplayName', 'POD'),
                        ColumnFactory.standardColumn('newETADisplayName', 'New ETA'),
                        ColumnFactory.standardColumn('ataDisplayName', 'ATA'),
                        ColumnFactory.standardColumn('daysTillETADisplayName', 'Days till ETA'),
                        ColumnFactory.standardColumn('statusDisplayName', 'Status')
                    ]}
                    subColumns={[
                        ColumnFactory.standardColumn('equipmentNumberDisplayName', 'HAWB number'),
                        ColumnFactory.standardColumn('cL1_TypeDisplayName', 'Clearance type'),
                        ColumnFactory.standardColumn('cL1_Broker.name', 'Broker'),
                        ColumnFactory.standardColumn('cL1_StatusDateDisplayName', 'Cleared date'),
                        ColumnFactory.standardColumn('cL1_StatusDisplayName', 'Customs status'),
                    ]}
                    expandable={true}
                    withCheckboxes={true}
                    selectVariant="multiple"
                    actions={{
                        primaryActionIsMoreBtn: false,
                        primaryActionLabel: 'Export to excel',
                        primaryAction: this.exportToExcel,
                        childContextualActions: [
                            { isMoreBtn: false, label: 'Update clearance details', action: this.checkIfCanUpdateClearanceDetails },
                        ]
                    }}
                    reference={this.dataTable}
                    tableName="CustomsAirClearanceList"
                    manualPagination={true}
                    fetchData={this.props.fetchBls}
                    pageCount={this.props.pageCount}
                    showColumnOptions={true}
                />
                {this.state.isEditMultipleContainersModalOpened && <UpdateClearanceForCustomsRelease
                    visible={this.state.isEditMultipleContainersModalOpened}
                    closeModal={this.toggleEditMultipleContainersModal}
                    equipId={this.getIds().blId}
                    voyageId={this.getIds().voyageId}
                    data={this.state.details}
                    refreshFunction={this.props.refreshFunction}
                />}
            </React.Fragment>
        );
    }

    private exportToExcel = async () => {
        const res = await this.props.exportCustomsReleaseToExcel();
        await downloadFile(res);
    };

    private toggleEditMultipleContainersModal = async () => {
        const selectedItems = this.dataTable.current ? this.dataTable.current.getSelectedItems() : [];

        if(!this.state.isEditMultipleContainersModalOpened) {
            const checkSelectedItems = uniqWith(selectedItems.map((hbl: any) => ({
                CL1_Broker: hbl.cL1_Broker,
                CL1_Comments: hbl.cL1_Comments,
                CL1_CustomsClearedDate: hbl.cL1_StatusDate,
                CL1_Status: hbl.cL1_Status,
                CL1_Type: hbl.cL1_Type,
                EIDR: hbl.eidr,
                SFD: hbl.sfd,
                UCR: hbl.ucr
            })), isEqual);

            if(checkSelectedItems.length === 1) {
                this.setState({
                    isEditMultipleContainersModalOpened: true,
                    details: checkSelectedItems
                });
            }
        } else {
            this.setState({ isEditMultipleContainersModalOpened: false });
        }
    };

    private getIds = () => {
        const selectedItems = this.dataTable.current ? this.dataTable.current.getSelectedItems() : [];
        const getValues = (property: string) => selectedItems.map((item: any) => {
            return item[property];
        });

        return {
            hblId: getValues('hblId'),
            blId: getValues('equipmentId'),
            voyageId: getValues('voyageId'),
        };
    };

    private selectedItemsAsUniq = () => {
        const selectedItems = this.dataTable.current ? this.dataTable.current.getSelectedItems() : [];
        return uniqWith(selectedItems.map((hbl: any) => {
            return {
                CL1_Broker: hbl.cL1_Broker,
                CL1_Comments: hbl.cL1_Comments,
                CL1_CustomsClearedDate: hbl.cL1_StatusDate,
                CL1_Status: hbl.cL1_Status,
                CL1_Type: hbl.cL1_Type,
                EIDR: hbl.eidr,
                SFD: hbl.sfd,
                UCR: hbl.ucr
            };
        }), isEqual);
    };

    private toggleErrorDialog = () => this.setState(state => ({
        isErrorDialogOpened: !state.isErrorDialogOpened
    }))

    private checkIfCanUpdateClearanceDetails = () => {
        if(this.selectedItemsAsUniq().length === 1) {
            this.setState({ isErrorDialogOpened: false });
            this.toggleEditMultipleContainersModal();
        } else {
            this.setState({
                isErrorDialogOpened: true,
                errorMessage: 'Selected equipments are not equal'
            });
        }
    };
}

const mapDispatchToProps = (dispatch: ThunkDispatch<any, any, Action>): IMapDispatchToProps => ({
    fetchHBLFCRDetails: (id: string, voyageId: string) => dispatch(fetchHBLFCRDetailsOnList(id, voyageId)),
    exportCustomsReleaseToExcel: () => dispatch(exportCustomsReleaseToExcel(true))
});

export const HAWBReleaseTable = connect(undefined, mapDispatchToProps)(_HAWBRelease);
