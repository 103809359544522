import React, { Component } from 'react';
import { connect } from 'react-redux';
import { PageHeader } from 'components/page-header';
import { Page } from 'components/page-container';
import { Filters } from 'modules/filters/containers/Filters';
import { FiltersInput } from 'modules/filters/components/FiltersInput';
import { FiltersSelect } from 'modules/filters/components/FiltersSelect';
import { FiltersDateInput } from 'modules/filters/components/FiltersDateInput';
import { ThunkDispatch } from 'redux-thunk';
import { Action } from 'redux';
import { getContainersLocation } from 'actions/planning';
import { IStore } from 'reducers/index';
import { IEquipmentEmptyReturnLocationsData } from 'reducers/planning/models';
import { TRANSPORT_MODE_OPTIONS, ALL_TRANSPORT_TYPE_OPTIONS } from 'constants/transport';
import {HandleError} from 'components/handle-error';
import { fetchDeliveryPlanningHaulierSettings } from 'actions/settings';
import { ISettingsModel } from 'reducers/settings/models';
import { IOption } from 'components/select/Select';
import { getHaulierText } from 'utilities/util';
import { setSortForTable } from 'utilities/tables';
import { LoadingAnimation } from 'components/loading';
import { DeliveryAndReturnLocationTable } from 'modules/delivery-and-return/ocean/components/DeliveryAndReturnLocationTable';
import { setSort, resetSort } from 'actions/filters';
import { ISortModel } from 'reducers/filters/models';


interface IMapStateToProps {
    isLoading: boolean;
    isGetEquipmentsLocationError: boolean;
    isUpdateEmptyReturnActualsError: boolean;
    isAddEquipmentDeliveriesError: boolean;
    locations: IEquipmentEmptyReturnLocationsData;
    deliveryPlanningHaulierSettings: ISettingsModel[];
    fetchDeliveryPlanningHaulierSettingsPending: boolean;
    isExportLoading: boolean;
    exportDeliveryLocationError: string;
    pickUpDateEmpty: boolean;
    dropOffDateEmpty: boolean;
}

interface IDispatch {
    getContainersLocation: (pageNumber: number, pageSize: number) => Promise<void>;
    fetchDeliveryPlanningHaulierSettings: () => void;
    setSort: (sort: ISortModel) => void;
    resetSort: () => void;
}

type Props = IDispatch & IMapStateToProps;

interface IDeliveryAndReturnLocationListState {
    page: number;
    pageSize: number;
}

class _DeliveryAndReturnLocationList extends Component<Props, IDeliveryAndReturnLocationListState> {
    public state = {
        page: 1,
        pageSize: 10
    };

    public componentDidMount() {
        this.props.fetchDeliveryPlanningHaulierSettings();
    }

    public render() {
        const filters = [
            <FiltersInput
                key="equipmentNumber"
                name="equipmentNumber"
                label="Equipment Number"
                id="equipmentNumber"
            />,
            <FiltersSelect
                key="transportMode"
                name="transportMode"
                label="Transport mode"
                options={TRANSPORT_MODE_OPTIONS}
            />,
            <FiltersSelect
                key="transportType"
                name="transportType"
                label="Transport type"
                options={ALL_TRANSPORT_TYPE_OPTIONS}
            />,
            <FiltersSelect
                key="haulier"
                name="haulier"
                label="Haulier"
                options={this.getHauliers()}
            />,
            <FiltersInput
                key="pickUpLocation"
                name="pickUpLocation"
                label="Pick up location"
            />,
            <FiltersDateInput
                type="date"
                key="pickUpDate_from"
                name="pickUpDate_from"
                label="Pick up date (from)"
                relatedField="pickUpDate"
                readOnly={this.props.pickUpDateEmpty}
            />,
            <FiltersDateInput
                type="date"
                key="pickUpDate_to"
                name="pickUpDate_to"
                label="Pick up date (to)"
                isEndOfDay={true}
                relatedField="pickUpDate"
                readOnly={this.props.pickUpDateEmpty}
            />,
            <FiltersInput
                key="dropOffLocation"
                name="dropOffLocation"
                label="Drop-off location"
            />,
            <FiltersDateInput
                type="date"
                key="dropOffDate_from"
                name="dropOffDate_from"
                label="Drop-off date (from)"
                relatedField="dropOffDate"
                readOnly={this.props.dropOffDateEmpty}
            />,
            <FiltersDateInput
                type="date"
                key="dropOffDate_to"
                name="dropOffDate_to"
                label="Drop-off date (to)"
                isEndOfDay={true}
                relatedField="dropOffDate"
                readOnly={this.props.dropOffDateEmpty}
            />,
            <FiltersInput
                key="POD"
                name="podName"
                label="POD"
            />,
            <FiltersInput
                key="PONumber"
                name="PONumber"
                label="PO Number"
            />,
            <FiltersInput
                key="lastUserUpdated"
                name="lastUserUpdated"
                label="Last updated by"
            />,
        ];
        const {
            isGetEquipmentsLocationError,
            isUpdateEmptyReturnActualsError,
            isAddEquipmentDeliveriesError,
        } = this.props;

        return (
            <>
                <PageHeader>Location</PageHeader>
                {this.props.isExportLoading && <LoadingAnimation />}
                <HandleError
                    exportDeliveryLocationError={this.props.exportDeliveryLocationError}
                />
                <HandleError
                    isGetEquipmentsLocationError={isGetEquipmentsLocationError}
                    isUpdateEmptyReturnActualsError={isUpdateEmptyReturnActualsError}
                    isAddEquipmentDeliveriesError={isAddEquipmentDeliveriesError}
                />
                <Page>
                    <Filters
                        tableNameKey="delivery-location"
                        filters={filters}
                        fetchFunction={() => this.props.getContainersLocation(this.state.page, this.state.pageSize)}
                        withoutPagination={true}
                    />
                    <DeliveryAndReturnLocationTable 
                        data={this.props.locations.data}
                        isLoading={this.props.isLoading}
                        pageCount={Math.ceil(this.props.locations.totalCount / this.props.locations.pageSize)}
                        fetchEquipments={this.fetchEquipments}
                    />
                </Page>
            </>
        );
    };

    public componentWillUnmount() {
        this.props.resetSort();
    }

    private fetchEquipments = (tableData: any) => {
        this.setState({
            page: tableData.page + 1,
            pageSize: tableData.pageSize
        }, () => {
            setSortForTable(tableData.sorted, this.props.setSort);
            this.props.getContainersLocation(this.state.page, this.state.pageSize);
        });
    };

    private getHauliers(): IOption[] {
        const {deliveryPlanningHaulierSettings: hauliers} = this.props;

        return hauliers.map((haulier) => ({
            value: haulier.id,
            optionText: getHaulierText(haulier)
        }));
    };
}

const mapStateToProps = (state: IStore): IMapStateToProps => ({
    isLoading: state.planning.isGetEquipmentsLocationPending,
    isGetEquipmentsLocationError: !!state.planning.getEquipmentsLocationError,
    locations: state.planning.locations,
    isUpdateEmptyReturnActualsError: !!state.planning.updateEmptyReturnActualsError,
    isAddEquipmentDeliveriesError: !!state.planning.addEquipmentDeliveriesError,
    deliveryPlanningHaulierSettings: state.settings.deliveryPlanningHaulierSettings,
    fetchDeliveryPlanningHaulierSettingsPending: state.settings.fetchDeliveryPlanningHaulierSettingsPending,
    isExportLoading: state.exportReducer.isExportDelivAndReturnLocLoading,
    exportDeliveryLocationError: state.exportReducer.exportDeliveryAndReturnLocationError,
    pickUpDateEmpty: state.filters.filters.pickupdate_empty,
    dropOffDateEmpty: state.filters.filters.dropoffdate_empty
});



const mapDispatchToProps = (dispatch: ThunkDispatch<any, any, Action>): IDispatch => ({
    getContainersLocation: (pageNumber: number, pageSize: number) => dispatch(getContainersLocation(pageNumber, pageSize)),
    fetchDeliveryPlanningHaulierSettings: () => dispatch(fetchDeliveryPlanningHaulierSettings(true)),
    setSort: (sort: ISortModel) => dispatch(setSort(sort)),
    resetSort: () => dispatch(resetSort()),
});

export const DeliveryAndReturnLocationList = connect(mapStateToProps, mapDispatchToProps)(_DeliveryAndReturnLocationList);
