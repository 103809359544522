import React, { Component } from 'react';
import { formatValueIfNullOrUndefined, formatDate, getArrayOfButtons } from 'utilities/util';
import { mapStatusToStringForCR } from 'constants/statuses';
import { connect } from 'react-redux';
import { editHblDetails } from 'actions/bill-release';
import { ThunkDispatch } from 'redux-thunk';
import { Action } from 'redux';
import { EditHblPopup } from 'modules/bill-release/shared/components/EditBlModal.tsx/EditHblPopup';
import { IStore } from 'reducers/index';
import { LoadingAnimation } from 'components/loading';
import { BUSINNESS_FUNCTIONS } from 'constants/roles';
import { surrenderType } from 'modules/bill-release/shared/components/EditBlModal.tsx/surrenderType';
// tslint:disable-next-line: no-submodule-imports
import { IWithPermissions, withPermissions } from 'src/HOC/withPermissions';
import { PropertiesRibbonWrapper } from 'components/properties-ribbon';

interface IHAWBDetailsTopBarState {
    isEditDetailsOpened: boolean;
}

interface IMapDispatchToProps {
    editHblDetails: (body: any) => Promise<void>;
}

interface IMapStateToProps {
    isLoading: boolean;
}

interface IHAWBDetailsTopBar extends IWithPermissions, IMapStateToProps, IMapDispatchToProps {
    vessels: any[];
    surrenderType: string;
    hblId: number;
    originalBLSurrendered: boolean;
    originalBLSurrenderDate: Date | string;
    refreshFunction: () => Promise<void>;
}

class _HAWBDetailsTopBar extends Component<IHAWBDetailsTopBar, IHAWBDetailsTopBarState> {
    public state: IHAWBDetailsTopBarState = {
        isEditDetailsOpened: false,
    };

    public render() {
        const lastVessel = this.props.vessels[this.props.vessels.length - 1];
        const actions = [
            {label: 'Update HAWB details', action: this.toggleEditDetailsModal},
        ];
        const propertiesRibbonData = {
            labels: [
                {label: 'First POL', value: formatValueIfNullOrUndefined(lastVessel.loadingPort)},
                {label: 'Last POD', value: formatValueIfNullOrUndefined(lastVessel.destinationPort)},
                {label: 'Original ETA', value: formatDate(lastVessel.originalETA)},
                {label: 'New ETA', value: formatDate(lastVessel.currentETA)},
                {label: 'Status', value: mapStatusToStringForCR(formatValueIfNullOrUndefined(lastVessel.billReleaseStatus))},
            ],
            actions: getArrayOfButtons(this.props.permissions, actions)
        };

        return (
            <React.Fragment>
                { this.props.isLoading && <LoadingAnimation /> }
                <PropertiesRibbonWrapper content={propertiesRibbonData} />
                {this.state.isEditDetailsOpened && <EditHblPopup
                    visible={this.state.isEditDetailsOpened}
                    closeModal={this.toggleEditDetailsModal}
                    blId={this.props.hblId}
                    initialValues={this.getInitialValuesForEditHblDetails()}
                    isSurrendered={this.props.originalBLSurrendered}
                    submitFunc={this.editHawbDetails}
                    isHbl={true}
                    isAir={true}
                    modalName="HAWB"
                />}
            </React.Fragment>
        );
    }

    private toggleEditDetailsModal = () => this.setState((state) => ({
        isEditDetailsOpened: !state.isEditDetailsOpened,
    }));

    private getInitialValuesForEditHblDetails = () => {
        const type = surrenderType.find(({ optionText }) => optionText === this.props.surrenderType)
        return {
            SurrenderType: type ? type.value : '',
            SurrenderedDate: this.props.originalBLSurrenderDate,
        }
    }

    private editHawbDetails = async (data: any) => {
        await this.props.editHblDetails(data);
        await this.props.refreshFunction();
    }
}

const mapStateToProps = (state: IStore): IMapStateToProps => ({
    isLoading: state.bill_release.isEditBLDetailsLoading
});

const mapDispatchToProps = (dispatch: ThunkDispatch<any, any, Action>): IMapDispatchToProps => ({
    editHblDetails: (body: any) => dispatch(editHblDetails(body)),
});

const ROLES: BUSINNESS_FUNCTIONS[] = [BUSINNESS_FUNCTIONS.OCEAN_HBL_RELEASE_EDIT_HBL];

const __HAWBDetailsTopBar = withPermissions<IHAWBDetailsTopBar>(_HAWBDetailsTopBar, ROLES);

export const HAWBDetailsTopBar = connect(mapStateToProps, mapDispatchToProps)(__HAWBDetailsTopBar) as any;