import React, { FunctionComponent } from 'react';
import { ModalScreen } from 'components/modal-screen';
import { ModalType } from 'components/modal-screen/ModalType';
import { connect } from 'react-redux';
import { updateMilestone, fetchMilestones } from 'actions/vessels';
import { ThunkDispatch } from 'redux-thunk';
import { Action, compose } from 'redux';
import { IStore } from 'reducers/index';
import { DoubleGrid } from 'components/grid/double-grid';
import { FormDateInput } from 'components/date-input/FormDateInput';
import { Dialog } from 'components/dialog';
import { reduxForm, InjectedFormProps } from 'redux-form';
import { Accordion } from 'components/accordion';
import { ErrorDialog } from 'components/error-dialog';
import API from 'constants/api';

interface IEditMilestonesPopupOwnProps {
    selectedEvents: any[];
    isVisible: boolean;
    pageInfo: {page: number; pageSize: number};
    unSelectItemsInTable: () => void;
    closeModal: () => void;
}

interface IEditMilestonesPopup extends InjectedFormProps<any, IEditMilestonesPopupOwnProps>, IDispatch, IEditMilestonesPopupOwnProps {};

interface IDispatch {
    updateMilestone: (data: any) => Promise<void>;
    fetchMilestones: (pageNumber: number, pageSize: number) => Promise<any>;
}

const _EditMilestonesPopup: FunctionComponent<IEditMilestonesPopup> = (props) => {
    const renderForm = props.selectedEvents.map((selectedEvent: any, i: number) => {
        if(selectedEvent.name.split(' ')[0] === 'Vessel') {
            const name = `${selectedEvent.name} - ${selectedEvent.vesselName}, ${selectedEvent.vesselCode}, ${selectedEvent.voyage}, ${selectedEvent.pol}, ${selectedEvent.pod}`;

            return(
                <Accordion text={name} key={i}>
                    <DoubleGrid>
                        <FormDateInput
                            label="Carrier estimated date"
                            name={`carrierEstimatedDate_${selectedEvent.id}`}
                            readOnly={true}
                            withTime={true}
                        />
                        <FormDateInput
                            label="Ops estimated date"
                            name={`opsEstimatedDate_${selectedEvent.id}`}
                            readOnly={false}
                            withTime={true}
                        />
                    </DoubleGrid>
                    <DoubleGrid>
                        <FormDateInput
                            label="Carrier actual date"
                            name={`carrierActualDate_${selectedEvent.id}`}
                            readOnly={true}
                            withTime={true}
                        />
                        <FormDateInput
                            label="Ops actual date"
                            name={`opsActualDate_${selectedEvent.id}`}
                            readOnly={false}
                            withTime={true}
                        />
                    </DoubleGrid>
                    <Dialog
                        isVisible={true}
                        message="The ATA and ATD dates will be copied to all containers on the associated ship"
                    />
                </Accordion>
            )
        } else {
            const name = `${selectedEvent.name} - ${selectedEvent.containerNumber}, ${selectedEvent.sealNumber}, ${selectedEvent.vesselName}, ${selectedEvent.vesselCode}, ${selectedEvent.voyage}, ${selectedEvent.pol}, ${selectedEvent.pod}`;
            return(
                <Accordion text={name} key={i}>
                    <DoubleGrid>
                        <FormDateInput
                            label="Carrier actual date"
                            name={`carrierActualDate_${selectedEvent.id}`}
                            readOnly={true}
                            withTime={true}
                        />
                        <FormDateInput
                            label="Ops actual date"
                            name={`opsActualDate_${selectedEvent.id}`}
                            readOnly={false}
                            withTime={true}
                        />
                    </DoubleGrid>
                </Accordion>
            )
        }
    });

    const onSubmit = async (config: any) => {
        await Promise.all(props.selectedEvents.map(async selectedEvent => {
            const milestone = {
                eventType: selectedEvent.eventType,
                id: selectedEvent.itemId,
                actualDate: config[`opsActualDate_${selectedEvent.id}`],
                estimatedDate: config[`opsEstimatedDate_${selectedEvent.id}`]
            };

            await props.updateMilestone(milestone);
        }));

        props.closeModal();
        props.unSelectItemsInTable();
        await props.fetchMilestones(props.pageInfo.page, props.pageInfo.pageSize);
    }

    return (
        <form>
            <ModalScreen
                title="Edit milestones"
                modalType={ModalType.l()}
                visible={props.isVisible}
                closeModal={props.closeModal}
                primaryButtonTitle="Save"
                primaryButtonType="submit"
                primaryButtonFunc={props.handleSubmit(onSubmit)}
                secondaryButtonTitle="Cancel"
                secondaryButtonFunc={props.closeModal}
            >
                <React.Fragment>
                    <ErrorDialog endpoint={API.ContainerTracking.UpdateMilestones} />
                    {renderForm}
                </React.Fragment>
            </ModalScreen>
        </form>
    );
}

const mapDispatchToProps = (dispatch: ThunkDispatch<any, any, Action>): IDispatch => ({
    updateMilestone: (data: any) => dispatch(updateMilestone(data)),
    fetchMilestones: (pageNumber: number, pageSize: number) => dispatch(fetchMilestones(pageNumber, pageSize)),
});

const FORM_NAME = 'MILESTONES_MODAL';

export const EditMilestonesPopup = compose(
    reduxForm<any, IEditMilestonesPopupOwnProps>({ form: FORM_NAME }),
    connect<{}, IDispatch, IEditMilestonesPopupOwnProps, IStore>(null, mapDispatchToProps)
)(_EditMilestonesPopup)