import React from 'react';
import { Link } from 'react-router-dom';
import { IRoute } from '../../routes';

interface IMobileNavigationLink extends IRoute {
    index: number;
}

export const MobileNavigationLink = (props: IMobileNavigationLink) => (
    <div className="nav-mobile-column" key={`item-${props.index}`}>
        <div className={'nav-mobile-title'}>
            <Link to={props.to as string}>{props.name}</Link>
        </div>
    </div>
)