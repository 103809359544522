import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Page } from 'components/page-container';
import { ThunkDispatch } from 'redux-thunk';
import { Action } from 'redux';
import { IStore } from 'reducers/index';
import { getGOHTranslations } from 'actions/settings';
import { IResultWithPagination } from 'reducers/models';
import { GOHTable } from './GOHTable';
import { ErrorDialog } from 'components/error-dialog';
import API from 'constants/api';

interface IMapStateToProps {
    translations: IResultWithPagination<any>;
}
interface IMapDispatchToProps {
    getGOHTranslations: (pageNumber: number, pageSize: number) => Promise<void>;
}

interface IGOHManagementTableProps extends IMapStateToProps, IMapDispatchToProps {}

interface IGOHManagementTableState {
    page: number;
    pageSize: number;
}

class _GOHManagementTable extends Component<IGOHManagementTableProps, IGOHManagementTableState> {
    public state = {
        page: 1,
        pageSize: 10,
    };

    public render() {
        return (
            <Page>
                <ErrorDialog endpoint={API.Settings.GetGOHTranslations} />
                <ErrorDialog endpoint={API.Settings.AddGOHTranslation} />
                <ErrorDialog endpoint={API.Settings.UpdateGOHTranslation} />
                <ErrorDialog endpoint={API.Settings.DeleteGOHTranslation} />
                <GOHTable 
                    data={this.props.translations.result}
                    pageCount={Math.ceil(this.props.translations.totalCount / this.props.translations.pageSize)}
                    fetchPrefixes={this.fetchPrefixes}
                />
            </Page>
        );
    }

    private fetchPrefixes = (tableData: any) => {
        this.setState({
            page: tableData.page + 1,
            pageSize: tableData.pageSize
        }, () => {
            this.props.getGOHTranslations(this.state.page, this.state.pageSize);
        });
    };
}

const mapStateToProps = (state: IStore): IMapStateToProps => ({
    translations: state.settings.GOHTranslations
});

const mapDispatchToProps = (dispatch: ThunkDispatch<any, any, Action>): IMapDispatchToProps => ({
    getGOHTranslations: (pageNumber: number, pageSize: number) => dispatch(getGOHTranslations(pageNumber, pageSize)),
});

export const GOHManagementTable = connect<IMapStateToProps, IMapDispatchToProps, {}, IStore>(mapStateToProps, mapDispatchToProps)(_GOHManagementTable);
