import React from 'react';

interface IProgressIndicatorStepIcon {
    isActive: boolean;
    isDone: boolean;
    isError: boolean;
}

const setIcon = (active: boolean, done: boolean, error: boolean) => {
    if(active && !done) {
        return {
            icon: 'fa-dot-circle',
            color: 'black'
        }
    } else if(done) {
        return {
            icon: 'fa-check-circle',
            color: 'green'
        };
    } else if(error) {
        return {
            icon: 'fa-exclamation-circle',
            color: 'red'
        };
    } else {
        return {
            icon: 'fa-clock',
            color: 'inactive'
        }
    }
}

export const ProgressIndicatorStepIcon = (props: IProgressIndicatorStepIcon) => {
    const iconObj = setIcon(props.isActive, props.isDone, props.isError);

    return(
        <div className="step-indicator__step--icon">
            <i className={`fa ${iconObj.icon} ${iconObj.color}`} aria-hidden="true" />
        </div>
    );
}