import React from 'react';
import { LoadingAnimation } from 'components/loading';
import { ColumnFactory } from 'components/data-table/ColumnFactory';
import { IStore } from 'reducers/index';
import { ThunkDispatch } from 'redux-thunk';
import { Action } from 'redux';
import { fetchDeliveryPlanningHaulierSettings } from 'actions/settings';
import { connect } from 'react-redux';
import { ISettingsModel } from 'reducers/settings/models';
import { DataTable } from 'components/data-table/DamcoTable';
import { downloadFile } from 'utilities/download';
import { exportEquipmentDeliveryAndReturnLocationToExcel } from 'actions/export';
import { AddTransportationRowModal } from 'modules/modals/components/AddTransportationRowModal';
import { getContainersLocation } from 'actions/planning';
import { hauliersToEnum, getArrayOfButtons } from 'utilities/util';
import { Transport_Mode, Transport_Type } from 'constants/transport';
// tslint:disable-next-line: no-submodule-imports
import { withPermissions, IWithPermissions } from 'src/HOC/withPermissions';
import { BUSINNESS_FUNCTIONS } from 'constants/roles';

export interface IDARTable {
    selected: boolean;
}

interface IMapStateToProps {
    deliveryPlanningHaulierSettings: ISettingsModel[];
    fetchDeliveryPlanningHaulierSettingsPending: boolean;
}

interface IDispatch {
    fetchDeliveryPlanningHaulierSettings: () => void;
    exportEquipmentDeliveryAndReturnLocationToExcel: () => Promise<void>;
    getContainersLocation: () => Promise<void>;
}

interface IDARTableOwnProps {
    data: any;
    isLoading: boolean;
    pageCount: number;
    fetchEquipments: any;
 }
interface IDARTableProps extends IWithPermissions, IDARTableOwnProps, IDispatch, IMapStateToProps {}

interface IDARTableState {
    isEditActualsModalOpened: boolean;
}

class _DARTable extends React.Component<IDARTableProps, IDARTableState> {
    public dataTable = React.createRef<any>();

    public state: IDARTableState = {
        isEditActualsModalOpened: false
    };

    public componentDidMount() {
        this.props.fetchDeliveryPlanningHaulierSettings();
    }

    public render() {
        const hauliers = hauliersToEnum(this.props.deliveryPlanningHaulierSettings);
        const data = this.props.data.map((obj: any) => ({
            ...obj,
            transportModeDisplayName: Transport_Mode[obj.transportMode],
            transportTypeDisplayName: Transport_Type[obj.transportType],
            haulierDisplayName: hauliers[obj.haulier],
            equipmentNumberDisplayName: [obj.equipmentNumber, `/delivery-and-return/equipment/${obj.equipmentId}`]
        }));

        return (
            <React.Fragment>
                {this.props.isLoading && <LoadingAnimation />}
                <DataTable
                    data={data}
                    columns={[
                        ColumnFactory.linkedColumn('equipmentNumberDisplayName', 'Equipment number'),
                        ColumnFactory.standardColumn('transportModeDisplayName', 'Transport mode'),
                        ColumnFactory.standardColumn('transportTypeDisplayName', 'Transport type'),
                        ColumnFactory.standardColumn('haulierDisplayName', 'Haulier'),
                        ColumnFactory.standardColumn('pickupLocationDisplayName', 'Pickup location'),
                        ColumnFactory.standardColumn('pickupDateDisplayName', 'Pickup date'),
                        ColumnFactory.standardColumn('dropOffLocationDisplayName', 'Drop-off location'),
                        ColumnFactory.standardColumn('dropOffDateDisplayName', 'Drop-off date'),
                        ColumnFactory.standardColumn('deliveryCommentsDisplayName', 'Delivery comments'),
                    ]}
                    withCheckboxes={true}
                    selectVariant="single"
                    reference={this.dataTable}
                    tableName="DnRLocationList"
                    actions={{
                        primaryActionIsMoreBtn: false,
                        primaryActionLabel: 'Export to excel',
                        primaryAction: this.exportToExcel,
                        parentContextualActions: getArrayOfButtons(
                            this.props.permissions,
                            [
                                { isMoreBtn: false, label: 'Update actuals', action: this.toggleEditActualsModal }
                            ])
                    }}
                    manualPagination={true}
                    fetchData={this.props.fetchEquipments}
                    pageCount={this.props.pageCount}
                    showColumnOptions={true}
                />
                {this.state.isEditActualsModalOpened && <AddTransportationRowModal
                    visible={this.state.isEditActualsModalOpened}
                    closeModal={this.toggleEditActualsModal}
                    id={this.getIds()}
                    initialValues={this.getInitialValues()}
                    isEmptyReturn={true}
                    refetchFunction={this.props.getContainersLocation}
                    fromPlanning={false}
                />}
            </React.Fragment>
        )
    }

    private exportToExcel = async () => {
        const res = await this.props.exportEquipmentDeliveryAndReturnLocationToExcel();
        await downloadFile(res);
    };

    private toggleEditActualsModal = () => this.setState((state) => ({
        isEditActualsModalOpened: !state.isEditActualsModalOpened,
    }));

    private getIds = () => {
        const selectedItems = this.dataTable.current ? this.dataTable.current.getSelectedItems() : [];
        
        if(selectedItems.length > 0) {
            return selectedItems[0].id;
        }
        return null;
    };

    private getInitialValues = () => {
        const selectedItems = this.dataTable.current ? this.dataTable.current.getSelectedItems() : [];

        if(selectedItems[0]) {
            return {
                transportMode: selectedItems[0].transportMode,
                transportType: selectedItems[0].transportType,
                haulier: selectedItems[0].haulier,
                pickupLocationCode: selectedItems[0].pickupLocationCode,
                dropOffDate: selectedItems[0].dropOffDate,
                pickupDate: selectedItems[0].pickupDate,
                dropOffLocationCode: selectedItems[0].dropOffLocationCode,
                id: selectedItems[0].id
            }
        }
        return;
    };
}

const ROLES: BUSINNESS_FUNCTIONS[] = [BUSINNESS_FUNCTIONS.AIR_DELIVERY_DETAILS_EQUIPMENT_UPDATE_ACTUALS];

const mapStateToProps = (state: IStore): IMapStateToProps => ({
    fetchDeliveryPlanningHaulierSettingsPending: state.settings.fetchDeliveryPlanningHaulierSettingsPending,
    deliveryPlanningHaulierSettings: state.settings.deliveryPlanningHaulierSettings,
})

const mapDispatchToProps = (dispatch: ThunkDispatch<any, any, Action>): IDispatch => ({
    fetchDeliveryPlanningHaulierSettings: () => dispatch(fetchDeliveryPlanningHaulierSettings(true)),
    exportEquipmentDeliveryAndReturnLocationToExcel: () => dispatch(exportEquipmentDeliveryAndReturnLocationToExcel(false)),
    getContainersLocation: () => dispatch(getContainersLocation(1, 10)),
});

const __DeliveryAndReturnLocationTable = connect<IMapStateToProps, IDispatch, IDARTableOwnProps, IStore>(mapStateToProps, mapDispatchToProps)(_DARTable);
export const DeliveryAndReturnLocationTable = withPermissions(__DeliveryAndReturnLocationTable, ROLES) as any;
