import { Column, CellInfo } from 'react-table';
import { Link } from 'react-router-dom';
import React from 'react';
import { ColumnFactory } from 'components/data-table/ColumnFactory';

export const linkedColumnWithFunction = (accessorFunction: (row: any) => object, name: string, createLink: (values: object) => string, id: string): Column => {
    const Cell = (cell: CellInfo) => {
        const link = createLink(cell.value);
        return (
            <div>
                <Link
                    to={link}
                    id="table-link"
                >
                    {cell.value.name}
                </Link>
            </div>
        );
    };
    return ColumnFactory.getColumnOptionsOld(accessorFunction, name, Cell, id);
};
