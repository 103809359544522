import React from 'react';

export interface ITextarea {
    label: string;
    value?: string;
    onChange?: (value: string) => void;
    input?: any
    id?: string;
}

export const Textarea: React.FunctionComponent<ITextarea> = (props) => (
    <div className="form-group">
        <label>{props.label}</label>
        <textarea
            className="form-control"
            value={props.input ? props.input.value : props.value}
            onChange={props.input ? props.input.onChange : (event: any) => (props.onChange as any)(event.target.value)}
            id={props.id}
        />
    </div>
);
