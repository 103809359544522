import React, { Component } from 'react';
import { LoadingAnimation } from 'components/loading';
import { ColumnFactory } from 'components/data-table/ColumnFactory';
import { DataTable } from 'components/data-table/DataTable';
import { connect } from 'react-redux';
import { IStore } from 'reducers/index';
import { IHolds } from 'reducers/customs/models';
import { Button, ButtonType } from 'components/button';
import styled from 'styled-components';
import { EditHoldModal } from 'modules/customs/ocean/components/EditHoldModal';
import { ConfirmModal } from 'components/confirm-modal';
import { AddNewFlightModal, IAddNewFlightModalFields } from 'modules/customs/air/components/AddNewFlightModal';
import { IOption } from 'components/select/Select';

const SButton = styled(Button)`
    margin-top: 10px;
`;

interface IMapStateToProps {
    data: IHolds[] | any;
    isLoading: boolean;
}

interface IHoldsProps extends IMapStateToProps {
    holds: any[];
    dataForDropdown: IOption[][];
    removeHolds: (id: number) => void;
    editHolds: (body: IHolds) => void;
    addHolds: (body: IHolds) => void;
}

interface IHoldsState {
    isHoldModalOpened: boolean;
    isEditHoldModalOpened: boolean;
    isConfirmDeleteModalOpened: boolean;
    holdId: number;
    holdToDelete: number;
}

class _Holds extends Component<IHoldsProps, IHoldsState> {
    public state: IHoldsState = {
        isHoldModalOpened: false,
        isEditHoldModalOpened: false,
        isConfirmDeleteModalOpened: false,
        holdToDelete: 0,
        holdId: 0
    };

    public render() {
        if (this.props.isLoading) {
            return <LoadingAnimation />
        }

        return(
            <>
                <DataTable
                    data={this.props.holds}
                    columns={[
                        ColumnFactory.standardColumnOld('holdType', 'Hold type'),
                        ColumnFactory.standardColumnOld('equipmentNumber', 'Equipment number'),
                        ColumnFactory.dateColumnOld('startDate', 'State date'),
                        ColumnFactory.dateColumnOld('endDate', 'End date'),
                        ColumnFactory.standardColumnOld('comment', 'Comments'),
                        ColumnFactory.multiActionColumn('holdId', 'Actions', {
                            delete: {
                                func: (id: string) => this.showRemoveConfirmationDelete(Number(id)),
                                iconClass: 'fa-times'
                            },
                            edit: {
                                func: (id: string) => this.toggleEditHoldModal(Number(id)),
                                iconClass: 'fa-pencil'
                            }
                        })
                    ]}
                />
                <SButton
                    buttonType={ButtonType.Blue}
                    onClick={this.toggleHoldModal}
                >
                    Add new
                </SButton>
                <ConfirmModal
                    visible={this.state.isConfirmDeleteModalOpened}
                    title="Remove hold"
                    closeModal={this.hideConfirmDelete}
                    onConfirm={() => {
                        this.hideConfirmDelete();
                        this.props.removeHolds(this.state.holdToDelete)
                    }}
                >
                    Are you sure to remove the hold?
                </ConfirmModal>

                {this.state.isHoldModalOpened && <AddNewFlightModal
                    title="Add new hold"
                    visible={this.state.isHoldModalOpened}
                    closeModal={this.toggleHoldModal}
                    addHold={this.addHold}
                    dataForDropdown={this.props.dataForDropdown}
                />}

                {this.state.isEditHoldModalOpened && <EditHoldModal
                    title="Edit hold"
                    visible={this.state.isEditHoldModalOpened}
                    closeModal={() => this.setState({ isEditHoldModalOpened: false })}
                    editHold={this.editHold}
                    initialValues={this.getInitialValues(this.state.holdId)}
                    dataForDropdown={this.props.dataForDropdown}
                    isAir={true}
                />}
            </>
        );
    }

    private hideConfirmDelete = () => {
        this.setState({
            isConfirmDeleteModalOpened: false,
        })
    };

    private showRemoveConfirmationDelete = (holdId: number) => {
        this.setState({
            isConfirmDeleteModalOpened: true,
            holdToDelete: holdId
        })
    };

    private addHold = (fields: IAddNewFlightModalFields) => {
        const hold = {
            holdId: this.state.holdId,
            holdType: fields.HoldType,
            equipmentNumber: fields.EquipmentNumber,
            startDate: fields.StartDate,
            endDate: fields.EndDate,
            comment: fields.Comments
        };

        this.setState((state: IHoldsState) => ({
            holdId: state.holdId + 1
        }));

        this.props.addHolds(hold);
        this.toggleHoldModal();
    }

    private editHold = (fields: any) => {
        const hold = {
            holdId: this.state.holdId,
            holdType: fields.HoldType,
            equipmentNumber: fields.EquipmentNumber,
            startDate: fields.StartDate,
            endDate: fields.EndDate,
            comment: fields.Comments
        };

        this.props.editHolds(hold);
        this.setState({ isEditHoldModalOpened: false });
    }

    private getInitialValues = (id: any) => {
        const hold = this.props.holds.find((obj: any) => obj.holdId === id);

        const initValues = hold ? {
            HoldType: hold.holdType,
            EquipmentNumber: hold.HAWBNumber,
            StartDate: hold.startDate,
            EndDate: hold.endDate,
            Comment: hold.comment
        } : {};
        return initValues;
    };

    private toggleHoldModal = () => this.setState((state) => ({
        isHoldModalOpened: !state.isHoldModalOpened
    }));

    private toggleEditHoldModal = (id: number) => this.setState((state) => ({
        isEditHoldModalOpened: !state.isEditHoldModalOpened,
        holdId: id
    }));
}

const mapStateToProps = (state: IStore): IMapStateToProps => ({
    data: state.customs.hblFcrDetails,
    isLoading: state.customs.isFetchHBLFCRDetailsLoading
});

export const Holds = connect(mapStateToProps)(_Holds);
