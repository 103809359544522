import React, { Component, ReactNode } from 'react';
import { ModalType } from './ModalType';

export interface IModalScreenProps {
    title: string;
    modalType: ModalType;
    visible: boolean;
    children: ReactNode;
    primaryButtonType?: 'submit' | 'reset' | 'button';
    primaryButtonTitle?: string;
    primaryButtonId?: string;
    primaryButtonIsDisabled?: boolean;
    secondaryButtonTitle?: string;
    primaryButtonFunc?: () => void;
    secondaryButtonFunc?: () => void;
    closeModal: () => void;
    buttons?: ReactNode;
    id?: string;
    hideSecondaryButton?: boolean;
}

export class ModalScreen extends Component<IModalScreenProps> {
    public render() {
        const closeId = this.props.id ? `${this.props.id}-close` : '';

        const modalBody = document.querySelector('.modal-body') as HTMLElement;
        const getHeight = (): number => {
            if(modalBody !== null) {
                return modalBody.offsetHeight;
            }
            return 0;
        };
        
        return(
            <div
                className={this.props.modalType.getClass()}
                style={{display: this.props.visible ? 'block' : 'none'}}
                id={this.props.id}
            >
                <div className="modal-dialog">
                    <div className="modal-content" style={{ maxHeight: 'initial' }}>
                        <div className="modal-header">
                            <h5 className="modal-title">{this.props.title}</h5>
                            <button id={closeId} type="button" className="close" data-dismiss="modal" aria-label="Close" onClick={() => this.props.closeModal()}>
                                <span aria-hidden="true"><i className="fa fa-times" aria-hidden="true" /></span>
                            </button>
                        </div>
                        <div className="modal-body" style={{ overflow: getHeight() > 300 ? 'scroll' : 'initial'}}>
                            {this.props.children}
                        </div>
                        <div className="modal-footer">
                            {this.props.buttons}
                            { this.props.hideSecondaryButton ? '' : (<button
                                type="button"
                                className="button-large button-transparent"
                                onClick={this.props.secondaryButtonFunc}
                            >
                                {this.props.secondaryButtonTitle}
                            </button>
                            )}
                            <button
                                type={this.props.primaryButtonType ? this.props.primaryButtonType : 'button'}
                                className="button-large button-blue"
                                onClick={this.props.primaryButtonFunc}
                                disabled={this.props.primaryButtonIsDisabled}
                                id={this.props.primaryButtonId}
                            >
                                {this.props.primaryButtonTitle}
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        )
    }

}
