export const downloadFile = (
  res: any,
  mimeType: string = 'application/vnd.ms-excel',
  fileName: string = 'data.xlsx'
) => {
  if (mimeType === '') {
    mimeType = 'application/vnd.ms-excel';
  }
  if (fileName === '') {
    fileName = 'data.xlsx';
  }
  const blob = new Blob([res], { type: mimeType });

  const a = document.createElement('a');
  a.setAttribute('type', 'hidden');
  a.href = URL.createObjectURL(blob);
  a.download = fileName;
  document.body.appendChild(a);
  a.click();
  a.remove();
};
