import React, { Component } from 'react';
import { PageHeader } from 'components/page-header';
import { CustomsReleaseTable } from 'modules/customs/ocean/components/CustomsReleaseTable';
import { Page } from 'components/page-container';
import { Filters } from 'modules/filters/containers/Filters';
import { FiltersInput } from 'modules/filters/components/FiltersInput';
import { FiltersDateInput } from 'modules/filters/components/FiltersDateInput';
import { FiltersSelect } from 'modules/filters/components/FiltersSelect';
import { statusesValues } from 'utilities/util';
import { connect } from 'react-redux';
import { ThunkDispatch } from 'redux-thunk';
import { Action } from 'redux';
import { IStore } from 'reducers/index';
import { fetchEquipmentsList } from 'actions/customs';
import { LoadingAnimation } from 'components/loading';
import { HandleError } from 'components/handle-error';
import { CUSTOMS_RELEASE_STATUS } from 'constants/statuses';
import { SubNavigation } from 'components/sub-navigation';
import { ILink } from 'components/sub-navigation/models/Link';
import { ISortModel } from 'reducers/filters/models';
import { setSort, resetSort } from 'actions/filters';
import { setSortForTable } from 'utilities/tables';

const links: ILink[] = [{
    to: '/customs/ocean-clearance',
    linkText: 'Ocean clearanace',
}, {
    to: '/customs/customs-release',
    linkText: 'Customs release',
}];

interface IMapStateToProps {
    isLoading: boolean;
    isUpdateHBLDetailsError: boolean;
    isFetchHBLFCRDetailsError: boolean;
    isFetchBlsError: boolean;
    isExportLoading: boolean;
    exportCustomsError: string;
    equipments: any;
    newEtaEmpty: boolean;
    ataEmpty: boolean;
}

interface IMapDispatchToProps {
    fetchEquipmentsList: (pageNumber: number, pageSize: number) => Promise<void>;
    setSort: (sort: ISortModel) => void;
    resetSort: () => void;
}

interface ICustomsReleaseProps extends IMapStateToProps, IMapDispatchToProps {}

interface ICustomsReleaseState {
    page: number;
    pageSize: number;
}

class _CustomsRelease extends Component<ICustomsReleaseProps, ICustomsReleaseState> {
    public state = {
        page: 1,
        pageSize: 10
    };

    public render() {
        const { isFetchHBLFCRDetailsError, isUpdateHBLDetailsError, isFetchBlsError } = this.props;
        const filters = [
            <FiltersInput
                key="vesselName"
                name="VesselName"
                label="Vessel name"
            />,
            <FiltersInput
                key="MBL"
                name="cblNumber"
                label="MBL"
            />,
            <FiltersInput
                key="EquipmentNumber"
                name="EquipmentNumber"
                label="Equipment number"
            />,
            <FiltersInput
                key="POL"
                name="polName"
                label="POL"
            />,
            <FiltersInput
                key="POD"
                name="podName"
                label="POD"
            />,
            <FiltersSelect
                key="status"
                name="Status"
                label="Customs status"
                type="number"
                options={statusesValues(CUSTOMS_RELEASE_STATUS)}
            />,
            <FiltersDateInput
                type="date"
                key="newEta_from"
                name="newEta_from"
                label="New ETA (from)"
                relatedField="newEta"
                readOnly={this.props.newEtaEmpty}
            />,
            <FiltersDateInput
                type="date"
                key="newEta_to"
                name="newEta_to"
                label="New ETA (to)"
                isEndOfDay={true}
                relatedField="newEta"
                readOnly={this.props.newEtaEmpty}
            />,
            <FiltersDateInput
                type="date"
                key="ata_from"
                name="ATA_from"
                label="ATA (from)"
                relatedField="ata"
                readOnly={this.props.ataEmpty}
            />,
            <FiltersDateInput
                type="date"
                key="ata_to"
                name="ata_to"
                label="ATA (to)"
                isEndOfDay={true}
                relatedField="ata"
                readOnly={this.props.ataEmpty}
            />,
            <FiltersInput
                key="daysTillEta"
                name="daysTillEta"
                label="Days till ETA"
                type="number"
            />,
            <FiltersInput
                key="lastUserUpdated"
                name="lastUserUpdated"
                label="Last updated by"
            />,
        ];
        
        return (
            <>
                <PageHeader id="ocean-clearance-header">
                    Customs release
                </PageHeader>
                <Page>
                    {this.props.isLoading || this.props.isExportLoading && <LoadingAnimation />}
                    <HandleError
                        exportCustomsError={this.props.exportCustomsError}
                    />
                    <HandleError
                        isUpdateHBLDetailsError={isUpdateHBLDetailsError}
                        isFetchHBLFCRDetailsError={isFetchHBLFCRDetailsError}
                        isFetchBlsError={isFetchBlsError}
                    />
                    <Filters
                        tableNameKey="customs-ocean"
                        filters={filters}
                        fetchFunction={() => this.props.fetchEquipmentsList(this.state.page, this.state.pageSize)}
                    />
                    <SubNavigation links={links} />
                    <CustomsReleaseTable
                        isLoading={this.props.isLoading}
                        data={this.props.equipments.result}
                        pageCount={Math.ceil(this.props.equipments.totalCount / this.props.equipments.pageSize)}
                        fetchBls={this.fetchEquipments}
                        refreshFunction={() => this.props.fetchEquipmentsList(1, 10)}
                    />
                </Page>
            </>
        );
    }

    public componentWillUnmount() {
        this.props.resetSort();
    }

    private fetchEquipments = (tableData: any) => {
        this.setState({
            page: tableData.page + 1,
            pageSize: tableData.pageSize
        }, () => {
            setSortForTable(tableData.sorted, this.props.setSort);
            this.props.fetchEquipmentsList(this.state.page, this.state.pageSize);
        });
    };
}

const mapStateToProps = (state: IStore): IMapStateToProps => ({
    isLoading: state.customs.isFetchEquipmentsLoading,
    isUpdateHBLDetailsError: !!state.customs.updateHBLDetailsError,
    isFetchHBLFCRDetailsError: !!state.customs.fetchHBLFCRDetailsError,
    isFetchBlsError: !!state.customs.fetchBlsError,
    isExportLoading: state.exportReducer.isExportCustomsLoading,
    exportCustomsError: state.exportReducer.exportCustomsError,
    equipments: state.customs.equipments,
    newEtaEmpty: state.filters.filters.neweta_empty,
    ataEmpty: state.filters.filters.ata_empty
});



const mapDispatchToProps = (dispatch: ThunkDispatch<any, any, Action>): IMapDispatchToProps => ({
    fetchEquipmentsList: (pageNumber: number, pageSize: number) => dispatch(fetchEquipmentsList(pageNumber, pageSize)),
    setSort: (sort: ISortModel) => dispatch(setSort(sort)),
    resetSort: () => dispatch(resetSort()),
});

export const CustomsRelease = connect(mapStateToProps, mapDispatchToProps)(_CustomsRelease);
