import React, { FunctionComponent, useEffect } from 'react';
import { connect } from 'react-redux';
import { ThunkDispatch } from 'redux-thunk';
import { Action } from 'redux';
import { fetchDeliveryPlanningHaulierSettings } from 'actions/settings';
import { IStore } from 'reducers/index';
import { ISettingsModel } from 'reducers/settings/models';
import { HaulierTable } from 'modules/settings/delivery-planning/containers/HaulierTable';

interface IDispatch {
    fetchDeliveryPlanningHaulierSettings: () => void;
}

interface IMapStateToProps {
    deliveryPlanningHaulierSettings: ISettingsModel[];
    fetchDeliveryPlanningHaulierSettingsPending: boolean;
}

interface IHaulierConfiguration extends IDispatch, IMapStateToProps{}

const _HaulierConfiguration: FunctionComponent<IHaulierConfiguration> = (props) => {
    useEffect(() => {
        props.fetchDeliveryPlanningHaulierSettings();
    }, []);

    return (
        <HaulierTable
            data={props.deliveryPlanningHaulierSettings}
            isLoading={props.fetchDeliveryPlanningHaulierSettingsPending}
        />
    );
}

const mapStateToProps = (state: IStore): IMapStateToProps => ({
    deliveryPlanningHaulierSettings: state.settings.deliveryPlanningHaulierSettings,
    fetchDeliveryPlanningHaulierSettingsPending: state.settings.fetchDeliveryPlanningHaulierSettingsPending,
});

const mapDispatchToProps = (dispatch: ThunkDispatch<any, any, Action>): IDispatch => ({
    fetchDeliveryPlanningHaulierSettings: () => dispatch(fetchDeliveryPlanningHaulierSettings(true)),
});

export const HaulierConfiguration = connect<IMapStateToProps, IDispatch, IStore>(mapStateToProps, mapDispatchToProps)(_HaulierConfiguration);