import React, { FunctionComponent } from 'react';
import { ModalScreen } from 'components/modal-screen';
import { ModalType } from 'components/modal-screen/ModalType';
import { Accordion } from 'components/accordion';
import { LoadingAnimation } from 'components/loading';
import { connect } from 'react-redux';
import { IStore } from 'reducers/index';
import { submit } from 'redux-form';
import { IHBLFCRDetails } from 'reducers/customs/models';
import { ThunkDispatch } from 'redux-thunk';
import { Action } from 'redux';
import { RouteComponentProps, withRouter } from 'react-router';
import { fetchHBLFCRDetails, resetUpdateHBLDetailsError, updateEquipments } from 'actions/customs';
import { Dialog } from 'components/dialog';
import { UpdateHBLCustomsClearanceForm, FORM_NAME } from './UpdateClearanceForCustomsReleaseForm';

interface IMapStateToProps {
    hblDetails: IHBLFCRDetails;
    isUpdateHBLDetailsLoading: boolean;
    updateHBLDetailsError: string;
}

interface IMapDispatchToProps {
    updateEquipments: (body: any) => void;
    fetchHBLFCRDetails: (id: string, voyageId: string) => void;
    submit: (formName: string) => void;
    resetUpdateHBLDetailsError: () => void;
}

interface IUpdateHBLCustomsClearanceModal {
    visible: boolean;
    voyageId: number[];
    equipId: number[];
    data: any;
    isDetails?: boolean;
    closeModal: () => void;
    refreshFunction?: () => Promise<void>
}

interface IRouteProps {
    id: string;
    voyageId: string;
}

interface IUpdateHBLCustomsModal extends IUpdateHBLCustomsClearanceModal, IMapStateToProps, IMapDispatchToProps, RouteComponentProps<IRouteProps> {}

const _UpdateHBLCustomsModal: FunctionComponent<IUpdateHBLCustomsModal> = (props) => {
    const titleEnd = props.equipId.length > 1 ? `${props.equipId.length} equipments` : `${props.equipId.length} equipment`;

    const saveHBLDetails = async (fields: any): Promise<void> => {
        await Promise.all(props.equipId.map((_: any, index: number) => {
            props.updateEquipments({
                CL1_Type: fields.CL1_Type,
                CL1_Broker: fields.CL1_Broker,
                CL1_StatusDate: fields.CL1_StatusDate,
                CL1_Comments: fields.CL1_Comments,
                CL1_Status: fields.CL1_Status,
                EquipmentId: props.equipId[index],
                VoyageId: props.voyageId[index],
                UCR:fields.UCR,
                SFD: fields.SFD,
                EIDR: fields.EIDR
            });
        }));

        props.closeModal();
        if (props.refreshFunction) {
            props.refreshFunction();
        }
    }

    const getInitialDataForClearance = () => {
        if(props.data[0]) {
            return {
                CL1_Type: props.data[0].CL1_Type,
                CL1_Broker: props.data[0].CL1_Broker ? props.data[0].CL1_Broker.brokerId : null,
                CL1_StatusDate: props.data[0].CL1_CustomsClearedDate,
                CL1_Comments: props.data[0].CL1_Comments,
                CL1_Status: props.data[0].CL1_Status,
                EIDR: props.data[0].EIDR,
                UCR: props.data[0].UCR,
                SFD: props.data[0].SFD
            }
        }
        return;
    };
    
    return(
        <ModalScreen
            title={`Update clearance details - ${titleEnd}`}
            modalType={ModalType.xl()}
            visible={props.visible}
            closeModal={props.closeModal}
            secondaryButtonFunc={props.closeModal}
            primaryButtonTitle="Save"
            primaryButtonType="submit"
            primaryButtonId="update-hbl-customs-button"
            primaryButtonFunc={() => props.submit(FORM_NAME)}
            secondaryButtonTitle="Cancel"
            id="update-hbl-customs-modal"
        >
            <div>
                { props.isUpdateHBLDetailsLoading && <LoadingAnimation /> }
                <Dialog
                    message={props.updateHBLDetailsError}
                    dialogType={500}
                    isVisible={!!props.updateHBLDetailsError}
                    closeDialog={props.resetUpdateHBLDetailsError}
                />
                <Accordion text="Clearance">
                    <UpdateHBLCustomsClearanceForm
                        onSubmit={saveHBLDetails}
                        initialValues={getInitialDataForClearance()}
                    />
                </Accordion>
            </div>
        </ModalScreen>
    );
}

const mapStateToProps = (state: IStore): IMapStateToProps => ({
    hblDetails: state.customs.hblFcrDetails,
    isUpdateHBLDetailsLoading: state.customs.isUpdateHBLDetailsLoading,
    updateHBLDetailsError: state.customs.updateHBLDetailsError,
});

const mapDispatchToProps = (dispatch: ThunkDispatch<any, any, Action>): IMapDispatchToProps => ({
    fetchHBLFCRDetails: (id: string, voyageId: string) => dispatch(fetchHBLFCRDetails(id, voyageId)),
    updateEquipments: (body: any) => dispatch(updateEquipments(body)),
    submit: (formName: string) => dispatch(submit(formName)),
    resetUpdateHBLDetailsError: () => dispatch(resetUpdateHBLDetailsError()),
});

export const UpdateClearanceForCustomsRelease = connect<IMapStateToProps, IMapDispatchToProps, IUpdateHBLCustomsClearanceModal, IStore>(mapStateToProps, mapDispatchToProps)
(withRouter<RouteComponentProps<IRouteProps>>(_UpdateHBLCustomsModal));
