import React, { Component } from 'react';
import { TimeLineInput } from './containers/input';
import { IComment, IComments } from 'reducers/timeline/models';
import { UserComment } from 'components/timeline/components/comment';
import moment from 'moment';
import { LoadingAnimation } from 'components/loading';
import { connect } from 'react-redux';
import { IStore } from 'reducers/index';
import { TYPES } from 'action-types/timeline';
import { Dialog } from 'components/dialog';
import { Button, ButtonType } from 'components/button';
import {
    fetchComments,
    IAddComment,
    addNewComment,
    loadMoreComments,
    IFetchCommentsData,
    ILoadMoreCommentsData,
    setFilters,
    getFetchEndpointForCapability,
    getAddEndpointForCapability,
    fetchAdditionalComments,
    IAdditionalRequest,
} from 'actions/timeline';
import { ThunkDispatch } from 'redux-thunk';
import { Action } from 'redux';
import { Filters, ICommentFilters } from './components/Filters';
import { ErrorDialog } from 'components/error-dialog';
import { isEqual } from 'lodash';

export enum Capability {
    ContainerTracking,
    DeliveryPlanning,
    Customs,
}

interface IMapState {
    error: string;
    comments: IComments;
    additionalComments: IComments;
    isLoading: boolean;
    isAddCommentLoading: boolean;
}

interface IMapDispatch {
    resetError: () => void;
    fetchComments: (data: IFetchCommentsData) => Promise<void>;
    fetchAdditionalComments: (data: IFetchCommentsData) => Promise<void>;
    loadMoreComments: (data: ILoadMoreCommentsData) => Promise<void>;
    addComment: (comment: IAddComment, isAir: boolean, capability: Capability) => Promise<void>;
    setFilters: (filters: ICommentFilters, data: IFetchCommentsData, additionalRequest?: IAdditionalRequest) => void;
}

interface ITimelineOwnProps {
    className?: string;
    objectType: string;
    objectId: number;
    isAir: boolean;
    capability: Capability;
    additionalSources?: {
        ids: number[];
        objectTypes: string[];
    }
}

interface ITimelineProps extends ITimelineOwnProps, IMapState, IMapDispatch {}

class _Timeline extends Component<ITimelineProps> {
    public componentDidMount() {
        this.fetchComments();
    }

    public componentDidUpdate(prevProps: ITimelineProps) {
        if(!isEqual(prevProps.additionalSources, this.props.additionalSources)) {
            this.fetchComments();
        }
    }

    public render() {
        const compare = (a: any, b: any) => {
            let comparison = 0;
            if (a.date < b.date) {
              comparison = 1;
            } else if (a.date > b.date) {
              comparison = -1;
            }
            return comparison;
        }

        const comments = [...this.props.comments.result, ...this.props.additionalComments.result].sort(compare).map((item: IComment, i: number) => {
            return (
                <UserComment
                    key={i}
                    title={item.user}
                    time={moment
                        .utc(item.date)
                        .utcOffset(1)
                        .format('MMMM Do YYYY, hh:mm a')}
                    message={item.content}
                    type={item.messageType}
                />
            );
        });

        return (
            <>
                {this.props.isLoading && <LoadingAnimation />}
                <div className={`time-line-widget ${this.props.className}`}>
                    <ul className="timeline-list">
                        <Dialog
                            isVisible={this.props.error !== ''}
                            dialogType={500}
                            message={this.props.error}
                            closeDialog={this.props.resetError}
                        />
                        <ErrorDialog endpoint={getFetchEndpointForCapability(this.props.capability, this.props.objectType, this.props.objectId)} />
                        <ErrorDialog endpoint={getAddEndpointForCapability(this.props.capability)} />
                        <TimeLineInput isLoading={this.props.isAddCommentLoading} onSubmit={this.addComment} />
                        <Filters onChange={this.applyFilters} />
                        {comments}
                        {this.shouldShowLoadMore() && (
                            <div className="align-text-center">
                                <Button
                                    buttonType={ButtonType.Transparent}
                                    style={{ textAlign: 'center' }}
                                    onClick={this.loadMore}
                                >
                                    Load more
                                </Button>
                            </div>
                        )}
                    </ul>
                </div>
            </>
        );
    }

    private addComment = async (content: string) => {
        const { objectId, objectType, isAir, capability } = this.props;

        const comment = {
            content,
            objectId,
            objectType,
        };

        await this.props.addComment(comment, isAir, capability);
        await this.fetchComments();
    };

    private fetchComments = async () => {
        if(this.props.additionalSources && this.props.additionalSources.ids && this.props.additionalSources.objectTypes) {
            await Promise.all(this.props.additionalSources.ids.map(async (id: number, index: number) => {
                await this.props.fetchAdditionalComments({ 
                    id, 
                    objectType: this.props.additionalSources ? this.props.additionalSources.objectTypes[index] : '', 
                    isAir: this.props.isAir, 
                    capability: this.props.capability
                })
            }));
        }

        await this.props.fetchComments({ 
            id: this.props.objectId, 
            objectType: this.props.objectType, 
            isAir: this.props.isAir, 
            capability: this.props.capability 
        });
    }

    private loadMore = () => {
        const {
            objectId: id,
            objectType,
            isAir,
            capability,
            comments: { page },
        } = this.props;

        return this.props.loadMoreComments({
            id,
            objectType,
            isAir,
            capability,
            page: page + 1,
        });
    };

    private applyFilters = (filters: ICommentFilters) => {
        const { objectId: id, objectType, isAir, capability } = this.props;
        const data = { id, objectType, isAir, capability };

        if(this.props.additionalSources) {
            const additionalData = {
                ids: this.props.additionalSources.ids,
                objectTypes: this.props.additionalSources.objectTypes
            };
            this.props.setFilters(filters, data, additionalData);
        } else {
            this.props.setFilters(filters, data);
        }
    };

    private shouldShowLoadMore = () =>
        this.props.comments.page * this.props.comments.pageSize < this.props.comments.totalCount;
}

const mapStateToProps = (state: IStore): IMapState => ({
    error: state.timeline.error,
    comments: state.timeline.comments,
    additionalComments: state.timeline.additionalComments,
    isLoading: state.timeline.fetchCommentsLoading,
    isAddCommentLoading: state.timeline.addCommentLoading,
});

const mapDispatchToProps = (dispatch: ThunkDispatch<any, any, Action>): IMapDispatch => ({
    resetError: () => dispatch({ type: TYPES.RESET_ERROR }),
    fetchComments: (data: IFetchCommentsData) => dispatch(fetchComments(data)),
    fetchAdditionalComments: (data: IFetchCommentsData) => dispatch(fetchAdditionalComments(data)),
    loadMoreComments: (data: ILoadMoreCommentsData) => dispatch(loadMoreComments(data)),
    addComment: (comment: IAddComment, isAir: boolean, capability: Capability) =>
    dispatch(addNewComment(comment, isAir, capability)),
    setFilters: (filters: ICommentFilters, data: IFetchCommentsData, additionalRequest?: IAdditionalRequest) => dispatch(setFilters(filters, data, additionalRequest)),
});

export const Timeline = connect<IMapState, IMapDispatch, ITimelineOwnProps, IStore>(mapStateToProps, mapDispatchToProps)(_Timeline);
