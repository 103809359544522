import React from 'react';
import { ColumnFactory } from 'components/data-table/ColumnFactory';
import { Accordion } from 'components/accordion';
import {IHawbDetails} from 'reducers/customs/models';
import { DataTable } from 'components/data-table/DamcoTable';
import { cloneDataWithDisplayNames } from 'utilities/tables';



export const HawbTable: React.StatelessComponent<IHawbDetails> = (props: IHawbDetails) => {
    const data = cloneDataWithDisplayNames(props.equipments).map((obj: any) => ({
        ...obj,
        children: obj.pOs.map((pos: any) => ({
            ...pos,
            ...pos.skUs[0]
        }))
    }));

    return (
        <Accordion text="Equipment">
            <DataTable
                data={data}
                withCheckboxes={false}
                expandable={true}
                columns={[
                    ColumnFactory.standardColumn('HAWBNumberDisplayName', 'HAWB Number'),
                    ColumnFactory.standardColumn('serviceDisplayName', 'Service'),
                    ColumnFactory.standardColumn('equipmentTypeDisplayName', 'Shipment type'),
                    ColumnFactory.standardColumn('equipmentTypeDisplayName', 'Size/type'),
                    ColumnFactory.standardColumn('totalPackagesDisplayName', 'Total packages'),
                    ColumnFactory.standardColumn('packagesTypeDisplayName', 'Package type'),
                    ColumnFactory.standardColumn('weightDisplayName', 'Weight'),
                    ColumnFactory.standardColumn('volumeDisplayName', 'Volume'),
                    ColumnFactory.standardColumn('volumeDisplayName', 'Chargeable weight')
                ]}
                subColumns={[
                    ColumnFactory.standardColumn('poNumberDisplayName', 'PO number'),
                    ColumnFactory.standardColumn('skuNumberDisplayName', 'SKU'),
                    ColumnFactory.standardColumn('goodsDescriptionDisplayName', 'Description'),
                    ColumnFactory.standardColumn('packagesDisplayName', 'Packages'),
                    ColumnFactory.standardColumn('pieceTypeDisplayName', 'Piece type'),
                    ColumnFactory.standardColumn('termsDisplayName', 'Terms'),
                ]}
                reference={null as any}
                tableName="CustomsHAWBEquipmentTable"
            />
        </Accordion>
    );
}
