import React from 'react';
import { StandardColumn } from 'components/data-table/columns/StandardColumn';
import { linkedColumn } from 'components/data-table/columns/LinkedColumn';
import { linkedColumnWithFunction } from 'components/data-table/columns/LinkedColumnWithFunction';
import { constantColumn } from 'components/data-table/columns/ConstantColumn';
import { dateColumn } from 'components/data-table/columns/DateColumn';
import { booleanColumn } from 'components/data-table/columns/BooleanColumn';
import { checkBoxColumn } from 'components/data-table/columns/CheckboxColumn';
import { columnWithCustomCell } from 'components/data-table/columns/ColumnWithCustomCell';
import { expandColumn } from 'components/data-table/columns/ExpandColumn';
import { conditionalExpandColumn } from 'components/data-table/columns/ConditionalExpandColumn';
import { iconColumn } from 'components/data-table/columns/IconColumn';
import { MappedColumn } from 'components/data-table/columns/MappedColumn';
import { StandardColumnWithDisabledSort } from 'components/data-table/columns/StandardColumnWithDisabledSort';
import { DateColumnWithDisabledSort } from 'components/data-table/columns/DateColumnWithDisabledSort';
import { BooleanColumnWithDisabledSort } from 'components/data-table/columns/BooleanColumnWithDisabledSort';
import { ValueBasedOnIdColumn } from 'components/data-table/columns/ValueBasedOnIdColumn';
import { MultiActionColumn } from 'components/data-table/columns/MultiActionColumn';
import { onClickColumn } from './columns/OnClickColumn';
import { StandardHeader } from './headers/StandardHeader';
import { Column } from 'react-table';
import { StandardColumnOld } from './columns/OldStandardColumn';
import { dateColumnOld } from './columns/DateColumnOld';

export class ColumnFactory {
    public static standardColumn = StandardColumn;

    public static linkedColumn = linkedColumn;

    public static linkedColumnWithFunction = linkedColumnWithFunction;

    public static constantColumn = constantColumn;

    public static dateColumn = dateColumn;

    public static booleanColumn = booleanColumn;

    public static checkBoxColumn = checkBoxColumn;

    public static columnWithCustomCell = columnWithCustomCell;

    public static expandColumn = expandColumn;

    public static conditionalExpandColumn = conditionalExpandColumn;

    public static iconColumn = iconColumn;

    public static mappedColumn = MappedColumn;

    public static standardColumnWithDisabledSort = StandardColumnWithDisabledSort;

    public static dateColumnWithDisabledSort = DateColumnWithDisabledSort;

    public static booleanColumnWithDisabledSort = BooleanColumnWithDisabledSort;

    public static valueBasedOndIdColumn = ValueBasedOnIdColumn;

    public static multiActionColumn = MultiActionColumn;

    public static onClickColumn = onClickColumn;

    public static standardColumnOld = StandardColumnOld;

    public static dateColumnOld = dateColumnOld;

    public static getColumnOptions = (accessor: string | any, name: string, Cell: React.ReactNode, id?: string, sortable: boolean = true, editable: boolean = false, isStatus: boolean = false, isLink: boolean = false) => ({
        headerClassName: 'data-table header',
        show: true,
        accessor,
        isLink,
        id: id ? id : Math.random().toString(),
        fieldHeader: name,
        Cell,
        sortable,
        editable,
        isStatus,
    });

    public static getColumnOptionsOld = (accessor: string | any, name: string, Cell: React.ReactNode, id?: string, sortable: boolean = true): Column => ({
        headerClassName: 'data-table header',
        show: true,
        accessor,
        id,
        Header: () => <StandardHeader name={name}/>,
        Cell,
        sortable,
    });
}