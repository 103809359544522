import React from 'react';
import { PageHeader } from 'components/page-header';
import { HandleError } from 'components/handle-error';
import { Page } from 'components/page-container';
import { PageWithTimeline } from 'components/page-with-timeline';
import { RouteComponentProps } from 'react-router';
import { AirDestinationSummaryTopBar } from 'views/delivery-and-return/air-delivery-details/components/AirDestinationSummaryTopBar';
import { EmptyReturnDocumentsRow } from 'modules/details-pages/components/EmptyReturnDocumentsRow';
import { DeliveryFailureRow } from 'modules/details-pages/components/DeliveryFailureRow';
import { NonConformanceRow } from 'modules/details-pages/components/NonConformance';
import { CustomsClearanceRow } from 'modules/details-pages/components/CustomsClearance';
import { connect } from 'react-redux';
import { ThunkDispatch } from 'redux-thunk';
import { Action } from 'redux';
import { getAirDeliveryDetails } from 'actions/planning';
import { IStore } from 'reducers/index';
import { LoadingAnimation } from 'components/loading';
import { IEquipmentDetails } from 'reducers/planning/models';
import { DeliveryPlanningTypes } from 'constants/comment';
import { ProgressIndicator } from 'components/progress-indicator';
import { IBubble } from 'reducers/vessels/models';
import { UserDetails } from 'components/user-details';
import { Capability } from 'components/timeline';
import { DeliveryRow } from '../equipment-details/components/DeliveryRow';
import { HBLFCRDetailsTable } from 'modules/details-pages/components/HBLFCRDetailsTable';
import { DeliveryBottomTable } from 'modules/details-pages/components/DeliveryBottomTable';
import { GenericError } from 'components/error-dialog/GenericError';
import { AirDeliveryTable } from 'views/delivery-and-return/air-delivery-details/components/AirDeliveryTable';


interface IMapStateToProps {
    isLoading: boolean;
    airDeliveryDetails: IEquipmentDetails;
    bubbles: IBubble[];
}

interface IDispatch {
    getAirReturnDetails: (id: number) => Promise<void>;
}

interface IAirDeliveryDetails extends IMapStateToProps, IDispatch, RouteComponentProps<{ id: string }> {
}

export class _AirDeliveryDetails extends React.Component<IAirDeliveryDetails> {
    public componentDidMount() {
        const id = parseInt(this.props.match.params.id, 10);
        if (!Number.isNaN(id)) {
            this.props.getAirReturnDetails(id);
        }
    };

    public render() {
        const id = parseInt(this.props.match.params.id, 10);

        if (Number.isNaN(id)) {
            return <GenericError />;
        }

        return (
            <React.Fragment>
                {this.props.isLoading && <LoadingAnimation />}
                <PageHeader backButton={true}>Equipment : {this.props.airDeliveryDetails.equipmentNumber}</PageHeader>
                <ProgressIndicator data={this.props.bubbles} />
                <HandleError />
                <Page>
                    <AirDestinationSummaryTopBar
                        deliveryPlannedDate={this.props.airDeliveryDetails.deliveryPlannedDate}
                        currentLocation={this.props.airDeliveryDetails.deliveryCurrentLocation}
                        deliveryActualDate={this.props.airDeliveryDetails.deliveryActualDate}
                        deliveryStatus={this.props.airDeliveryDetails.deliveryStatus}
                        equipment={this.props.airDeliveryDetails}
                        getAirReturn={this.props.getAirReturnDetails}
                    />
                    <PageWithTimeline
                        objectId={id}
                        objectType={DeliveryPlanningTypes.Equipment}
                        isAir={true}
                        capability={Capability.DeliveryPlanning}
                    >
                        <AirDeliveryTable equipmentId={id} />
                        <DeliveryRow {...this.props.airDeliveryDetails} />
                        <EmptyReturnDocumentsRow
                            documentsPARNRequired={this.props.airDeliveryDetails.documentsPARNRequired}
                            documentsPARNNumber={this.props.airDeliveryDetails.documentsPARNNumber}
                            documentsCMRRequired={this.props.airDeliveryDetails.documentsCMRRequired}
                            documentsCMRNumber={this.props.airDeliveryDetails.documentsCMRNumber}
                            destinationPort={this.props.airDeliveryDetails.destinationPort}
                        />
                        <HBLFCRDetailsTable data={this.props.airDeliveryDetails.hbLs} />
                        <DeliveryFailureRow
                            deliveryFailureDate={this.props.airDeliveryDetails.deliveryFailureDate}
                            deliveryFailureReasonCodeId={this.props.airDeliveryDetails.deliveryFailureReasonCodeId}
                            deliveryFailureResponsible={this.props.airDeliveryDetails.deliveryFailureResponsible}
                            deliveryPlannedDate={this.props.airDeliveryDetails.deliveryPlannedDate}
                        />
                        <NonConformanceRow {...this.props.airDeliveryDetails} />
                        <CustomsClearanceRow
                            cL1_Broker={this.props.airDeliveryDetails.cL1_Broker}
                            cL1_Type={this.props.airDeliveryDetails.cL1_Type}
                            cL1_Status={this.props.airDeliveryDetails.cL1_Status}
                            cL1_StatusDate={this.props.airDeliveryDetails.cL1_StatusDate}
                            cL1_Comments={this.props.airDeliveryDetails.cL1_Comments}
                        />
                        <DeliveryBottomTable data={this.props.airDeliveryDetails.pOs} />
                        <UserDetails
                            user={this.props.airDeliveryDetails.lastUserUpdated}
                            date={this.props.airDeliveryDetails.updateDate}
                        />
                    </PageWithTimeline>
                </Page>
            </React.Fragment>
        );
    }
}

const mapStateToProps = (state: IStore): IMapStateToProps => ({
    isLoading: state.planning.isGetAirDeliveryDetailsPending,
    airDeliveryDetails: state.planning.airDeliveryDetails,
    bubbles: state.planning.airDeliveryDetails.bubbleDataDeliveryAndReturn
});

const mapDispatchToProps = (dispatch: ThunkDispatch<any, any, Action>) => ({
    getAirReturnDetails: (id: number) => dispatch(getAirDeliveryDetails(id)),
});

export const AirDeliveryDetails = connect(mapStateToProps, mapDispatchToProps)(_AirDeliveryDetails);
