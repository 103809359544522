import React, { useRef } from 'react';
import { LoadingAnimation } from 'components/loading';
import { ColumnFactory } from 'components/data-table/ColumnFactory';
import { IDocument } from 'reducers/fact/models';
import { DataTable } from 'components/data-table/DamcoTable';
import { cloneDataWithDisplayNames } from 'utilities/tables';

interface IFactTableOwnProps {
    data: IDocument[];
    isLoading: boolean;
    pageCount: number;
    fetchDocuments: any;
}

export const FactTable: React.StatelessComponent<IFactTableOwnProps> = (props) => {
    const dataTable = useRef<any>();
    const data = cloneDataWithDisplayNames(props.data).map(document => ({
        ...document,
        originalDocumentNumberDisplayName: [document.originalDocumentNumber, `/exception-management/fact/documents/${document.documentId}`]
    }));

    return (
        <React.Fragment>
            {props.isLoading && <LoadingAnimation />}
            <DataTable
                data={data}
                expandable={false}
                withCheckboxes={true}
                columns={[
                    ColumnFactory.linkedColumn('originalDocumentNumberDisplayName', 'Original document number'),
                    ColumnFactory.dateColumn('etdDisplayName', 'ETD'),
                    ColumnFactory.dateColumn('atdDisplayName', 'ATD'),
                    ColumnFactory.dateColumn('etaDisplayName', 'ETA'),
                    ColumnFactory.dateColumn('ataDisplayName', 'ATA')
                ]}
                reference={dataTable}
                tableName="FactDocumentsListTable"
                pageCount={props.pageCount}
                manualPagination={true}
                fetchData={props.fetchDocuments}
                showColumnOptions={true}
        />
        </React.Fragment>
    );
}
