import React, { FunctionComponent, Dispatch } from 'react';
import { ModalScreen } from 'components/modal-screen';
import { ModalType } from 'components/modal-screen/ModalType';
import { INotifyCustomerAction } from 'actions/modals';
import { CTReasonCodes } from 'constants/reasonCodes';
import { DoubleGrid } from 'components/grid/double-grid';
import { FormDateInput } from 'components/date-input/FormDateInput';
import { InjectedFormProps, reduxForm, reset } from 'redux-form';
import { FormInput } from 'components/input/FormInput';
import { commas } from 'utilities/validate';
import { Dialog } from 'components/dialog';

interface IMapStateToProps {
    isLoading: boolean;
    reasonCodes: any[];
    isGetReasonCodesLoading: boolean;
}

interface IDispatch {
    notifyCustomer: (data: INotifyCustomerAction) => Promise<void>;
    getReasonCodes: (group?: CTReasonCodes) => Promise<void>;
}

interface IDQReportModalOwnProps {
    errors: any[];
    visible: boolean;
    closeModal: () => void;
    generateReport: (fields: any) => void;
}

interface IDQReportFields {
    etdfrom: string;
    etdto: string;
    etafrom: string;
    etato: string;
    fcrs: string;
    etss: string;
}

interface IDQReportModal extends IDQReportModalOwnProps, IDispatch, IMapStateToProps, InjectedFormProps<IDQReportFields, IDQReportModalOwnProps> {};

const _DQReportModal: FunctionComponent<IDQReportModal> = props => {
    const renderErrors = () => {
        if(props.errors !== undefined && props.errors.length) {
            return props.errors.map((err: any, i: number) => (
                <Dialog
                    key={i}
                    isVisible={true}
                    dialogType={400}
                    message={err.value}
                />
            ));
        }
        return;
    };

    return (
        <form onSubmit={props.handleSubmit(props.generateReport)}>
            <ModalScreen
                title="DQ issues report"
                visible={props.visible}
                modalType={ModalType.s()}
                closeModal={props.closeModal}
                primaryButtonTitle="Save"
                primaryButtonType="submit"
                secondaryButtonTitle="Cancel"
                secondaryButtonFunc={props.closeModal}
                hideSecondaryButton={true}
            >
                {renderErrors()}
                <DoubleGrid>
                    <FormDateInput
                        label="ETD (from)"
                        name="etdfrom"
                        withTime={true}
                    />
                    <FormDateInput
                        label="ETD (to)"
                        name="etdto"
                        withTime={true}
                    />
                </DoubleGrid>
                <DoubleGrid>
                    <FormDateInput
                        label="ETA (from)"
                        name="etafrom"
                        withTime={true}
                    />
                    <FormDateInput
                        label="ETA (to)"
                        name="etato"
                        withTime={true}
                    />
                </DoubleGrid>
                <DoubleGrid>
                    <FormInput
                        label="FCR"
                        name="fcrs"
                        validate={commas}
                    />
                    <FormInput
                        label="ETS"
                        name="etss"
                        validate={commas}
                    />
                </DoubleGrid>
            </ModalScreen>
        </form>
    );
}

const FORM_NAME = 'GENERATE_DQ_ISSUES_REPORT';
const onSubmitSuccess = (_: any, dispatch: Dispatch<any>) => dispatch(reset(FORM_NAME));

 export const DQReportModal = reduxForm<IDQReportFields, IDQReportModalOwnProps>({
    form: FORM_NAME,
    onSubmitSuccess,
})(_DQReportModal);