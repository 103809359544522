import { TYPES } from 'action-types/filters';
import { ISortModel } from 'reducers/filters/models';
import { ReactNodeArray } from 'react';

export const setSort = (sort: ISortModel) => ({
    type: TYPES.SET_SORT,
    ...sort,
});

export const resetSort = () => ({
    type: TYPES.RESET_SORT,
});

export const setFirstPage = () => ({
    type: TYPES.SET_FIRST_PAGE,
});

export const setLastPage = () => ({
    type: TYPES.SET_LAST_PAGE,
});

export const setNextPage = () => ({
    type: TYPES.SET_NEXT_PAGE,
});

export const setPrevoiusPage = () => ({
    type: TYPES.SET_PREVIOUS_PAGE,
});

export const setEmptyFilters = (filters: ReactNodeArray) => ({
    type: TYPES.SET_EMPTY_FILTERS,
    filters
});

export const setFilter = (name: string, value: string | boolean | any[] | number) => ({
    type: TYPES.SET_FILTER,
    name,
    value,
});

export const applyFiltersAction = () => ({
    type: TYPES.APPLY_FILTERS,
});

export const clearFilters = () => ({
    type: TYPES.CLEAR_FILTERS
});

export const setEmptyDateFlagForETD = (name: string, value: boolean = true): any => ({ type: TYPES.SET_EMPTY_DATE, name: `${name}_empty`.toLowerCase(), value });