import React from 'react';
import { OneStatus } from 'components/status-row/OneStatus';
import { StatusRow } from 'components/status-row';
import { QuarterGrid } from 'components/grid/quarter-grid';
import { formatValueIfNullOrUndefined } from 'utilities/util';
import { mapStatusToStringForClearance1 } from 'constants/statuses';

interface ICustomsClearanceRow {
    cL1_Broker: string;
    cL1_Comments: string;
    cL1_Status: string;
    cL1_StatusDate: string;
    cL1_Type: string;
}

export const CustomsClearanceRow: React.SFC<ICustomsClearanceRow> = (props: ICustomsClearanceRow) => (
    <StatusRow title="Customs clearance">
        <QuarterGrid>
            <OneStatus label="Clearance type">
                {formatValueIfNullOrUndefined(props.cL1_Type)}
            </OneStatus>
            <OneStatus label="Broker">
                {formatValueIfNullOrUndefined(props.cL1_Broker)}
            </OneStatus>
            <OneStatus label="Customs status">
                {mapStatusToStringForClearance1(props.cL1_Status)}
            </OneStatus>
            <OneStatus label="Status date">
                {formatValueIfNullOrUndefined(props.cL1_StatusDate)}
            </OneStatus>
        </QuarterGrid>
        <QuarterGrid>
            <OneStatus label="Comments">
                {formatValueIfNullOrUndefined(props.cL1_Comments) || '-'}
            </OneStatus>
        </QuarterGrid>
    </StatusRow>
);
