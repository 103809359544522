import { callApiGet } from './../utilities/apiCaller';
import { ThunkDispatch } from 'redux-thunk';
import { TYPES } from 'action-types/changelog'
import { Action } from 'redux';
import { generateError } from 'utilities/util';

export const getChangelog = () => {
    return async (dispatch: ThunkDispatch<any, any, Action>) => {
        dispatch({ type: TYPES.FETCH_CHANGELOG.PENDING });
        try {
            const res = await callApiGet('changelog.json');
            dispatch({ type: TYPES.FETCH_CHANGELOG.FULFILLED, changelog: res.data });
        } catch (e) {
            const error = generateError(e);
            dispatch({ type: TYPES.FETCH_CHANGELOG.REJECTED, error });
            throw e;
        }
    }
};
