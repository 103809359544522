import React, { FunctionComponent } from 'react';
import { formatDate, formatValueIfNullOrUndefined } from 'utilities/util';
import { PropertiesRibbonWrapper } from 'components/properties-ribbon';
import { connect } from 'react-redux';
import { ThunkDispatch } from 'redux-thunk';
import { Action } from 'redux';
import { createIsso } from 'actions/fact';

interface IETSDetailsTopBarOwnProps {
    documentNumber: string;
    createDate: string;
    updateDate: string;
    eta: string;
    etd: string;
    isDeleted: boolean;
    packages: number;
    volume: number;
    quantity: number;
    weight: number;
    id: number;
}

interface IMapDispatchToProps {
    createIsso: (equipmentTransportShipmentId: number) => Promise<void>;
}

interface IETSDetailsTopBarProps extends IETSDetailsTopBarOwnProps, IMapDispatchToProps {};

const _ETSDetailsTopBar: FunctionComponent<IETSDetailsTopBarProps> = (props) => {
    const isDeleted = props.isDeleted ? 'Y' : 'N';
    const createAction = () => props.createIsso(props.id);
    
    const propertiesRibbonData = {
        labels: [
            {label: 'Document number', value: formatValueIfNullOrUndefined(props.documentNumber)},
            {label: 'Create date', value: formatDate(props.createDate, 'DD-MM-YYYY, h:mm')},
            {label: 'Create date', value: formatDate(props.updateDate, 'DD-MM-YYYY, h:mm')},
            {label: 'ETA', value: formatDate(props.eta, 'DD-MM-YYYY, h:mm')},
            {label: 'ETD', value: formatDate(props.etd, 'DD-MM-YYYY, h:mm')},
            {label: 'Is deleted', value: formatValueIfNullOrUndefined(isDeleted)},
            {label: 'Packaged', value: formatValueIfNullOrUndefined(props.packages)},
            {label: 'Quantity', value: formatValueIfNullOrUndefined(props.quantity)},
            {label: 'Volume', value: formatValueIfNullOrUndefined(props.volume)},
            {label: 'Quantity', value: formatValueIfNullOrUndefined(props.quantity)},
            {label: 'Weight', value: formatValueIfNullOrUndefined(props.weight)}
        ],
        actions: [
            {label: 'Create', action: createAction }
        ]
    }

    return <PropertiesRibbonWrapper content={propertiesRibbonData} />;
};

const mapDispatchToProps = (dispatch: ThunkDispatch<any, any, Action>): IMapDispatchToProps => ({
    createIsso: (equipmentTransportShipmentId: number) => dispatch(createIsso(equipmentTransportShipmentId))
})

export const ETSDetailsTopBar = connect<undefined, IMapDispatchToProps, IETSDetailsTopBarOwnProps>(undefined, mapDispatchToProps)(_ETSDetailsTopBar);
