import React from 'react';
import { StatusRow } from 'components/status-row';
import { formatValueIfNullOrUndefined, formatDate, getHaulierText } from 'utilities/util';
import { OneStatus } from 'components/status-row/OneStatus';
import { mapStatusToStringForClearance2 } from 'constants/statuses';
import { QuarterGrid } from 'components/grid/quarter-grid';

export const ClearanceTable: React.StatelessComponent<any> = (props: any) => {
    return(
        <StatusRow title="Customs clearance">
            <QuarterGrid>
                <OneStatus label="Clearance type">
                    {formatValueIfNullOrUndefined(props.cL2_Type || props.clearance2Type)}
                </OneStatus>
                <OneStatus label="Broker">
                    {props.cL2_Broker ? getHaulierText(props.cL2_Broker) : '-'}
                </OneStatus>
                <OneStatus label="Cleared date">
                    {formatDate(props.cL2_CustomsClearedDate || props.clearance2StatusDate)}
                </OneStatus>
                <OneStatus label="Status">
                    {formatValueIfNullOrUndefined(mapStatusToStringForClearance2(props.cL2_Status || props.clearance2Status))}
                </OneStatus>
            </QuarterGrid>
            <QuarterGrid>
                <OneStatus label="Comments">
                    {formatValueIfNullOrUndefined(props.cL2_Comments || props.clearance2Comments)}
                </OneStatus>
            </QuarterGrid>
        </StatusRow>
    );
};