import React from 'react';
import { Accordion } from 'components/accordion';

interface IStatusRow {
    title: string;
    children: React.ReactNode | React.ReactNodeArray;
}
export const StatusRow: React.SFC<IStatusRow> = (props: IStatusRow) => (
    <Accordion text={props.title}>
        <div className="grid-wrapper">
            {props.children}
        </div>
    </Accordion>
);
