import React from 'react';
import { InjectedFormProps, reduxForm, reset, getFormValues } from 'redux-form';
import { ModalType } from 'components/modal-screen/ModalType';
import { ModalScreen } from 'components/modal-screen';
import { DoubleGrid } from 'components/grid/double-grid';
import { Action, compose } from 'redux';
import { ThunkDispatch } from 'redux-thunk';
import { connect } from 'react-redux';
import { IStore } from 'reducers/index';
import { ISendTransportOrderToHaulier, resetSendTransportOrderErrors, sendTransportOrderToHaulier } from 'actions/planning';
import { LoadingAnimation } from 'components/loading';
import { Transport_Order_Type } from 'constants/transport';
import { Textarea } from 'components/textarea';
import { SingleGrid } from 'components/grid/single-grid';
import { DeliveryPlanningHaulierSelect } from 'components/select/DeliveryPlanningHaulierSelect';
import { areRequired } from 'utilities/validate';
import { ErrorDialog } from 'components/error-dialog';
import API from 'constants/api';

interface ISendPreAlertModal {
    visible: boolean;
    closeModal: () => void;
    transportConfig: Transport_Order_Type;
    id: number[];
    isAir: boolean;
    transportOrderName: string;
}

interface IMapStateToProps {
    isSendTransportOrderToHaulierPending: boolean;
    formValues: any;
}

interface IDispatch {
    sendTransportOrderToHaulier: (data: ISendTransportOrderToHaulier, isAir: boolean) => Promise<void>;
    resetSendTransportOrderErrors: () => void;
    resetFields: () => void;
}

type Props = ISendPreAlertModal & InjectedFormProps<{}, ISendPreAlertModal> & IDispatch & IMapStateToProps;

class _SendTransportOrderToHaulierModal extends React.Component<Props> {
    public static FORM_NAME = 'SEND_TRANSPORT_ORDER_TO_HAULIER';

    public componentDidUpdate(prevProps: Props) {
        if(!this.props.visible && prevProps.visible) {
            this.props.resetSendTransportOrderErrors();
            this.props.resetFields();
        }
    }

    public render() {
        const title = this.props.id.length > 1 ? `- ${this.props.id.length} equipments` : '';
        return (
            <form onSubmit={this.props.handleSubmit(this.onSubmit)}>
                {this.props.isSendTransportOrderToHaulierPending && <LoadingAnimation />}
                <ModalScreen
                    title={`Send ${this.props.transportOrderName} transport order ${title}`}
                    visible={this.props.visible}
                    modalType={ModalType.s()}
                    closeModal={this.props.closeModal}
                    primaryButtonTitle="Send"
                    primaryButtonType="submit"
                    primaryButtonId="submit-transport-order-button"
                    primaryButtonIsDisabled={!this.props.valid}
                    secondaryButtonTitle="Cancel"
                    secondaryButtonFunc={this.props.closeModal}
                    id="send-transport-order-modal"
                >
                    <ErrorDialog endpoint={API.DeliveryPlanning.SendTransportOrderToHaulier} />
                    <DoubleGrid>
                        {(this.props.visible && <DeliveryPlanningHaulierSelect
                            name="haulierId"
                            label="Haulier"
                        />) as React.ReactChild}
                    </DoubleGrid>
                    <SingleGrid>
                        <Textarea
                            label="Note to haulier"
                            onChange={() => null}
                        />
                    </SingleGrid>
                </ModalScreen>
            </form>
        );
    }

    private onSubmit = async ({ haulierId }: { haulierId: string }) => {
        const data = {
            haulierId,
            transportConfig: this.props.transportConfig,
            equipmentIds: this.props.id,
        };
        try {
            await this.props.sendTransportOrderToHaulier(data, this.props.isAir);
            this.props.closeModal();
        } catch (e) {
            console.warn(e);
        }
    }
}

const mapStateToProps = (state: IStore): IMapStateToProps => ({
    isSendTransportOrderToHaulierPending: state.planning.isSendTransportOrderToHaulierPending,
    formValues: getFormValues(_SendTransportOrderToHaulierModal.FORM_NAME)(state),
});

const mapDispatchToProps = (dispatch: ThunkDispatch<any, any, Action>): IDispatch => ({
    sendTransportOrderToHaulier: (data: ISendTransportOrderToHaulier, isAir: boolean) => dispatch(sendTransportOrderToHaulier(data, isAir)),
    resetSendTransportOrderErrors: () => dispatch(resetSendTransportOrderErrors()),
    resetFields: () => dispatch(reset(_SendTransportOrderToHaulierModal.FORM_NAME)),
});

const validate = (values: any): object => {
    const requiredFields = [ 'haulierName' ];
    return areRequired(values, requiredFields);
};

export const SendTransportOrderToHaulierModal = compose(
    connect<IMapStateToProps, IDispatch, ISendPreAlertModal, IStore>(mapStateToProps, mapDispatchToProps),
    reduxForm<{}, ISendPreAlertModal>({
        form: _SendTransportOrderToHaulierModal.FORM_NAME,
        validate,
    })
)(_SendTransportOrderToHaulierModal);