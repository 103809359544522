import React from 'react';
import { formatDate, formatValueIfNullOrUndefined } from 'utilities/util';
import { PropertiesRibbonWrapper } from 'components/properties-ribbon';

interface ICblDetailsTopBarOwnProps {
    number: string;
    transportContractNumber: string;
    internalNumber: string;
    createDate: string;
    updateDate: string;
}

export const CblDetailsTopBar: React.StatelessComponent<ICblDetailsTopBarOwnProps> = (props) => {
    const propertiesRibbonData = {
        labels: [
            {label: 'CBL number', value: formatValueIfNullOrUndefined(props.number)},
            {label: 'Transport contract number', value: formatValueIfNullOrUndefined(props.transportContractNumber)},
            {label: 'Internal number', value: formatValueIfNullOrUndefined(props.internalNumber)},
            {label: 'Create date', value: formatDate(props.createDate, 'DD-MM-YYYY, h:mm')},
            {label: 'Update date', value: formatDate(props.updateDate, 'DD-MM-YYYY, h:mm')},
        ],
        actions: []
    }

    return <PropertiesRibbonWrapper content={propertiesRibbonData} />;
};
