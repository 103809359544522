import * as Msal from 'msal';
import { IConfig, IAppContext } from './msal.interaface';
import store from './../../store';
import axios, { AxiosError } from 'axios';

const state: IAppContext = {
    stopLoopingRedirect: false,
    scopes: []
};

const loggerCallback = (_: any, message: string) => {
    console.warn(message);
};

const logger = new Msal.Logger(loggerCallback, { level: Msal.LogLevel.Info });

const authCallback = (errorDesc: string, _: any, error: string) => {
    if (errorDesc) {
        console.warn(error + ':' + errorDesc);
        state.stopLoopingRedirect = true;
        const localMsalApp = window.msal as Msal.UserAgentApplication;
        localMsalApp.logout();
    } else {
        store.dispatch({type: 'LOGIN_PENDING'});
        acquireToken();
    }
};

const checkUser = async (localMsalApp: any, successCallback: any) => {
    localMsalApp.getUser();
    const user = localMsalApp.getUser();
    if (!user) {
        localMsalApp.loginRedirect(state.scopes);
    }
    else {
        try {
            const accessToken = await localMsalApp.acquireTokenSilent(state.scopes);
            state.accessToken = accessToken;
            if (state.launchApp) {
                state.launchApp();
            }
            if (successCallback) {
                successCallback();
            }
        } catch (error) {
            localMsalApp.acquireTokenRedirect(state.scopes);
        }
    }
}
export const acquireToken = (successCallback?: () => void) => {
    const localMsalApp = window.msal as Msal.UserAgentApplication;
    localMsalApp.acquireTokenSilent(state.scopes);
    checkUser(localMsalApp, successCallback);
};

const requestInterceptor = async (config: any) => {
    const localMsalApp = window.msal as Msal.UserAgentApplication;
    const authorizationTokenName = 'Authorization';

    const token = await localMsalApp.acquireTokenSilent([localMsalApp.clientId])
        .then ((result: string) => {
            return result;
        })
        .catch((error: any) => {
            localMsalApp.logout();
        });

    config.headers.common[authorizationTokenName]= `Bearer ${token}`;
    return config;
}

const responseErrorIntercetor = (error: AxiosError) => {
    if (error.response && error.response.status === 401) {
        authentication.signOut();
    }

    throw error;
}

export const authentication = {
    initialize: (config: IConfig) : Msal.UserAgentApplication => {
        const instance = config.instance;
        const authority = `${instance}/${config.tenant}/${config.signInPolicy}`;
        const scopes = config.scopes;

        if (!scopes || scopes.length === 0) {
            state.stopLoopingRedirect = true;
        }
        state.scopes = scopes;

        return new Msal.UserAgentApplication(config.applicationId, authority, authCallback, {
            logger,
            storeAuthStateInCookie: true,
            cacheLocation: config.cacheLocation,
            postLogoutRedirectUri: config.postLogoutRedirectUri,
            redirectUri: config.redirectUri,
            validateAuthority: false
            }
        );
    },
    run: (launchApp: any, successCallback: any) => {
        state.launchApp = launchApp;
        const localMsalApp = window.msal as Msal.UserAgentApplication;

        if (!localMsalApp.isCallback(window.location.hash) && window.parent === window && !window.opener) {
            if (!state.stopLoopingRedirect) {
                acquireToken(successCallback);
            }
        }
    },
    getSilentToken: async (successCallback: any) => {
        const localMsalApp = window.msal as Msal.UserAgentApplication;
        try {
            const accessToken = await localMsalApp.acquireTokenSilent(state.scopes);
            state.accessToken = accessToken;
            if (state.launchApp) {
                state.launchApp();
            }
            if (successCallback) {
                successCallback();
            }
        } catch (e) {
            localMsalApp.logout();
            localMsalApp.acquireTokenRedirect(state.scopes);
        }
    },
    signOut: () => {
        const localMsalApp = window.msal as Msal.UserAgentApplication;
        localMsalApp.logout();
        localStorage.clear();
    },
    getUser: () : Msal.User => {
        const localMsalApp = window.msal as Msal.UserAgentApplication;
        return localMsalApp.getUser();
    },
    setAuthorizationInterceptors: () => {
        axios.interceptors.request.use(requestInterceptor);
        axios.interceptors.response.use((response) => response, responseErrorIntercetor);
    },
    getAccessToken: () => state.accessToken
};

export default authentication;