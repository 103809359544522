export const areRequired = (values: any, names: string[]): object => {
    return names.reduce((prev: object, name: string) => {
        if (!values[name]) {
            prev[name] = 'Field cannot be empty';
        }
        return prev;
    }, {});
};

export const email = (value: string) =>
    value && !/^[a-zA-Z0-9.!#$%&’*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/i.test(value)
        ? 'Invalid email address'
        : undefined;

export const required = (value: string) => (value ? undefined : 'This field is required');

export const commas = (value: string) =>
    value && !/^\w+(?:,\w+)*$/.test(value)
        ? 'Value should match a pattern: word1,word2 and so on'
        : undefined;
