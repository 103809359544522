import { Column } from 'react-table';
import React from 'react';

interface ICheckboxColumnConfig {
    allChecked?: boolean,
}

export const checkBoxColumn = (
    onChange: (value: any) => void,
    toggleAll: () => void,
    withHeader: boolean = true,
    config: ICheckboxColumnConfig = {}): Column => {
        return {
            id: 'checkbox',
            accessor: '',
            className: '-checkbox',
            sortable: false,
            width: 45,
            style: {
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
            },
            Cell: ({ original }: any) => {
                return (
                    <input
                        type="checkbox"
                        className="checkbox checkbox-column"
                        checked={original.selected || false}
                        onChange={() => onChange(original)}
                    />
                );
            },
            Header: () => {
                if (withHeader) {
                    return (
                        <input
                            id="checkbox"
                            type="checkbox"
                            className="checkbox"
                            checked={config.allChecked}
                            onChange={toggleAll}
                        />
                    );
                }
                return <div/>
            },
        }
}