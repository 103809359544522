import { ThunkDispatch } from 'redux-thunk';
import { Action } from 'redux';
import { TYPES } from 'action-types/modals';
import { callApiPost, callApiGet } from 'utilities/apiCaller';
import API from 'constants/api';
import { CTReasonCodes } from 'constants/reasonCodes';
import { generateError } from 'utilities/util';
import { Dispatch } from 'redux';
import { Capability } from 'components/timeline';

export interface INotifyCustomerAction {
    ReasonCode?: string;
    ReasonCodeID?: number;
    ReasonCodeComment?: string;
    BlIds?: number[];
    POIDs?: number[];
}

export interface ISendDeRiggingRequestAction {
    DeRigger: string | number;
}

export const notifyCustomer = (data: INotifyCustomerAction, isAir: boolean) => {
    return async (dispatch: ThunkDispatch<any, any, Action>): Promise<void> => {
        dispatch({
            type: TYPES.NOTIFY_CUSTOMER.PENDING,
        });
        try {
            await callApiPost(API.NotifyCustomer, data, {headers: {TransportMode: isAir ? 'Air' : 'Ocean'}});
            dispatch({
                type: TYPES.NOTIFY_CUSTOMER.FULFILLED,
            });
        } catch (e) {
            const error = generateError(e);

            dispatch({
                type: TYPES.NOTIFY_CUSTOMER.REJECTED, error
            });

            throw e;
        }
    }
};

export const resetNotifyCustomerError = () => (dispatch: Dispatch) => {
    dispatch({ type: TYPES.NOTIFY_CUSTOMER.RESET_ERROR });
}

const getReasonCodeEndpointForCapability = (capability: Capability) => {
    switch (capability) {
        case Capability.ContainerTracking:
            return API.ContainerTracking.GetReasonCodes;
        case Capability.Customs:
            return API.Customs.GetReasonCodes;
        case Capability.DeliveryPlanning:
            return API.DeliveryPlanning.GetReasonCodes;
        default:
            return ''
    }
};

export const getReasonCodes = (capability: Capability, withDisabled: boolean, group: CTReasonCodes = CTReasonCodes.Ocean_LateShipment) => {
    return async (dispatch: ThunkDispatch<any, any, Action>) => {
        dispatch({
            type: TYPES.GET_REASON_CODES.PENDING
        });
        try {
            const query = { group, withDisabled };
            const config = { headers: { TransportMode: group === CTReasonCodes.Air_LateShipment ? 'Air' : 'Ocean' } };
            const res = await callApiGet(getReasonCodeEndpointForCapability(capability), query, config);
            dispatch({
                type: TYPES.GET_REASON_CODES.FULFILLED,
                result: res.data
            });
        } catch (e) {
            dispatch({
                type: TYPES.GET_REASON_CODES.REJECTED
            });
        }
    }
}

export const sendDeRiggingRequest = (body: ISendDeRiggingRequestAction) => {
    return async (dispatch: ThunkDispatch<any, any, Action>) => {
        dispatch({
            type: TYPES.SEND_DERIGGING_REQUEST.PENDING
        });
        try {
            await callApiPost(API.DeliveryPlanning.SendDeRiggingRequest, body, {headers: {TransportMode: 'Ocean'}});
            dispatch({
                type: TYPES.SEND_DERIGGING_REQUEST.FULFILLED
            });
        } catch (e) {
            dispatch({
                type: TYPES.SEND_DERIGGING_REQUEST.REJECTED
            });
        }
    }
}

export const notifyCustomerForCustoms = (data: any, isAir: boolean) => {
    return async (dispatch: ThunkDispatch<any, any, Action>): Promise<void> => {
        dispatch({
            type: TYPES.NOTIFY_CUSTOMER_FOR_CUSTOMS.PENDING,
        });
        try {
            await callApiPost(API.Customs.NotifyCustomer, data, {headers: {TransportMode: isAir ? 'Air' : 'Ocean'}});
            dispatch({
                type: TYPES.NOTIFY_CUSTOMER_FOR_CUSTOMS.FULFILLED,
            });
        } catch (e) {
            const error = generateError(e);

            dispatch({
                type: TYPES.NOTIFY_CUSTOMER_FOR_CUSTOMS.REJECTED, error,
            });

            throw e;
        }
    }
};

export const resetNotifyCustomerForCustomsError = () => (dispatch: Dispatch) => {
    dispatch({ type: TYPES.NOTIFY_CUSTOMER_FOR_CUSTOMS.RESET_ERROR });
}

export const notifyCustomerForDP = (data: any, isAir: boolean) => {
    return async (dispatch: ThunkDispatch<any, any, Action>): Promise<void> => {
        dispatch({
            type: TYPES.NOTIFY_CUSTOMER_FOR_DP.PENDING,
        });
        try {
            await callApiPost(API.DeliveryPlanning.NotifyCustomer, data, {headers: {TransportMode: isAir ? 'Air' : 'Ocean'}});
            dispatch({
                type: TYPES.NOTIFY_CUSTOMER_FOR_DP.FULFILLED,
            });
        } catch (e) {
            const error = generateError(e);

            dispatch({
                type: TYPES.NOTIFY_CUSTOMER_FOR_DP.REJECTED, error,
            });

            throw e;
        }
    }
};