import React, { Component } from 'react';
import { PageHeader } from 'components/page-header';
import { Page } from 'components/page-container';
import { Filters } from 'modules/filters/containers/Filters';
import { FiltersInput } from 'modules/filters/components/FiltersInput';
import { MilestonesTable } from 'modules/tracking/milestones/MilestonesTable';
import { connect } from 'react-redux';
import { ThunkDispatch } from 'redux-thunk';
import { Action } from 'redux';
import { fetchMilestones } from 'actions/vessels';
import { setSortForTable } from 'utilities/tables';
import { setSort, resetSort } from 'actions/filters';
import { ISortModel } from 'reducers/filters/models';
import { IStore } from 'reducers/index';
import { ErrorDialog } from 'components/error-dialog';
import API from 'constants/api';

interface IMilestonesState {
    page: number;
    pageSize: number;
}

interface IMapDispatchToProps {
    fetchMilestones: (pageNumber: number, pageSize: number) => Promise<void>;
    setSort: (sort: ISortModel) => any;
    resetSort: () => void;
}

interface IMapStateToStore {
    milestones: any;
}

interface IMilestonesProps extends IMapStateToStore,IMapDispatchToProps {};

class _Milestones extends Component<IMilestonesProps, IMilestonesState> {
    public state = {
        page: 1,
        pageSize: 10,
    };

    public render() {
        const filters = [
            <FiltersInput
                key="equipmentNumber"
                name="equipmentNumber"
                label="Container number"
            />,
            <FiltersInput
                key="sealNumber"
                name="sealNumber"
                label="Seal number"
            />,
            <FiltersInput
                key="vesselName"
                name="vesselName"
                label="Vessel name"
            />,
            <FiltersInput
                key="vesselCode"
                name="vesselCode"
                label="Vessel code"
            />,
            <FiltersInput
                key="voyageNumber"
                name="voyageNumber"
                label="Voyage"
            />,
            <FiltersInput
                key="firstPOL"
                name="firstPOL"
                label="First POL"
            />,
            <FiltersInput
                key="lastPOL"
                name="lastPOL"
                label="Last POL"
            />,
            <FiltersInput
                key="firstPOD"
                name="firstPOD"
                label="First POD"
            />,
            <FiltersInput
                key="lastPOD"
                name="lastPOD"
                label="Last POD"
            />,
            <FiltersInput
                key="cblNumber"
                name="cblNumber"
                label="CBL"
            />,
            <FiltersInput
                key="carrierName"
                name="carrierName"
                label="Carrier"
            />,
            <FiltersInput
                key="customer"
                name="customer"
                label="Customer"
            />,
        ];

        return (
            <React.Fragment>
                <PageHeader>Milestones</PageHeader>
                <Page>
                    <ErrorDialog endpoint={API.ContainerTracking.GetMilestones} />
                    <Filters
                        tableNameKey="tracking-vessels"
                        filters={filters}
                        fetchFunction={() => this.props.fetchMilestones(this.state.page, this.state.pageSize)}
                        withoutPagination={true}
                    />
                    <MilestonesTable
                        data={this.props.milestones.result}
                        pageCount={Math.ceil(this.props.milestones.totalCount / this.props.milestones.pageSize)}
                        fetchMilestones={this.fetchMilestones}
                        pageInfo={{
                            page: this.state.page,
                            pageSize: this.state.pageSize
                        }}
                    />
                </Page>
            </React.Fragment>
        );
    }

    public componentWillUnmount() {
        this.props.resetSort();
    }

    private fetchMilestones = (tableData: any) => {
        this.setState({
            page: tableData.page + 1,
            pageSize: tableData.pageSize
        }, () => {
            setSortForTable(tableData.sorted, this.props.setSort);
            this.props.fetchMilestones(this.state.page, this.state.pageSize);
        });
    };
}

const mapStateToProps = (state: IStore): IMapStateToStore => ({
    milestones: state.vessels.milestones
})

const mapDispatchToProps = (dispatch: ThunkDispatch<any, any, Action>): IMapDispatchToProps => ({
    fetchMilestones: (pageNumber: number, pageSize: number) => dispatch(fetchMilestones(pageNumber, pageSize)),
    setSort: (sort: ISortModel) => dispatch(setSort(sort)),
    resetSort: () => dispatch(resetSort()),
})

export const Milestones = connect(mapStateToProps, mapDispatchToProps)(_Milestones);