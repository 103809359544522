import React, { FunctionComponent } from 'react';
import { Timeline, Capability } from 'components/timeline';

interface IPageWithTimeline {
    objectId: number;
    objectType: string;
    children: React.ReactNode | React.ReactNodeArray;
    isAir: boolean;
    capability: Capability;
    additionalSources?: {
        ids: number[];
        objectTypes: string[];
    }
}

export const PageWithTimeline: FunctionComponent<IPageWithTimeline> = (props) => (
    <div className="grid-wrapper">
        <div className="col-70">
            {props.children}
        </div>
        <div className="col-30">
            <Timeline
                capability={props.capability}
                objectId={props.objectId}
                objectType={props.objectType}
                isAir={props.isAir}
                additionalSources={props.additionalSources}
            />
        </div>
    </div>
);
