import React from 'react';
import { StatusRow } from 'components/status-row';
import { QuarterGrid } from 'components/grid/quarter-grid';
import { OneStatus } from 'components/status-row/OneStatus';
import { formatValueIfNullOrUndefined, formatDate } from 'utilities/util';

interface ILeg {
    modalTitle: string;
    billType: any;
    vesselName: string;
    voyage: string;
    flightNumber: string;
    pod: string;
    pol: string;
    atd: Date | string;
    ata: Date | string;
    currentETA: Date | string;
}

export const Leg = (props: ILeg) => (
    <StatusRow title={props.modalTitle}>
        <QuarterGrid>
            <OneStatus label="Bill type">
                {formatValueIfNullOrUndefined(props.billType)}
            </OneStatus>
            <OneStatus label="Flight number">
                {formatValueIfNullOrUndefined(props.vesselName)}
            </OneStatus>
            <OneStatus label="Voyage">
                {formatValueIfNullOrUndefined(props.flightNumber)}
            </OneStatus>
            <OneStatus label="POL">
                {formatValueIfNullOrUndefined(props.pol)}
            </OneStatus>
        </QuarterGrid>
        <QuarterGrid>
            <OneStatus label="POD">
                {formatValueIfNullOrUndefined(props.pod)}
            </OneStatus>
            <OneStatus label="ATD">
                {formatDate(formatValueIfNullOrUndefined(props.atd))}
            </OneStatus>
            <OneStatus label="Current ETA">
                {formatDate(formatValueIfNullOrUndefined(props.currentETA))}
            </OneStatus>
            <OneStatus label="ATA">
                {formatDate(formatValueIfNullOrUndefined(props.ata))}
            </OneStatus>
        </QuarterGrid>
    </StatusRow>
);
