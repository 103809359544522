import React from 'react';
import { ColumnFactory } from 'components/data-table/ColumnFactory';
import { DataTable } from 'components/data-table/DamcoTable';
import { StatusRow } from 'components/status-row';

interface IPurchaseOrderNumbersTableProps {
    data: string[];
}

export const PurchaseOrderNumbersTable: React.StatelessComponent<IPurchaseOrderNumbersTableProps> = (props) => {
    return(
        <StatusRow title="Purchase order numbers">
            <DataTable
                data={props.data}
                columns={[
                    ColumnFactory.standardColumn('voyageDisplayName', 'Purchase Order Numbers'),
                ]}
                withCheckboxes={false}
                reference={null as any}
                tableName="FactPurchaseOrderNumbersTable"
                showPagination={true}
            />
        </StatusRow>
    );
};

