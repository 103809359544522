import React from 'react';
import { StatusRow } from 'components/status-row';
import { CBLDetailsTable } from './CBLDetailsTable';

interface ICarrierBlRowProps {
    bLs: any;
    loadingPort: string;
    destinationPort: string;
}

export const CarrierBlRow: React.StatelessComponent<ICarrierBlRowProps> = (props: ICarrierBlRowProps) => (
    <StatusRow title="Carrier B/L">
        <CBLDetailsTable
            bLs={props.bLs}
            loadingPort={props.loadingPort}
            destinationPort={props.destinationPort}
        />
    </StatusRow>
);
