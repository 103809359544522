import React from 'react';
import { ColumnFactory } from 'components/data-table/ColumnFactory';
import { DataTable } from 'components/data-table/DamcoTable';
import { ICblEquipment } from 'reducers/fact/models';
import { StatusRow } from 'components/status-row';
import { cloneDataWithDisplayNames } from 'utilities/tables';

interface IEquipmentsTableProps {
    equipments: ICblEquipment[];
}

export const EquipmentsTable: React.FunctionComponent<IEquipmentsTableProps> = (props) => {
    const data = cloneDataWithDisplayNames(props.equipments, 'DD-MM-YYYY, h:mm');

    return(
        <StatusRow title="Equipments">
            <DataTable
                data={data}
                columns={[
                    ColumnFactory.standardColumn('equipmentNumberDisplayName', 'Equipment number'),
                    ColumnFactory.standardColumn('sealNumberDisplayName', 'Seal number'),
                    ColumnFactory.standardColumn('typeDisplayName', 'Type'),
                    ColumnFactory.standardColumn('sizeDisplayName', 'Size'),
                    ColumnFactory.standardColumn('insertDateDisplayName', 'Insert date'),
                    ColumnFactory.standardColumn('updateDateDisplayName', 'Update date'),
                    ColumnFactory.standardColumn('equipmentTotalVolumeDisplayName', 'Equipment total volume'),
                ]}
                subColumns={[
                    ColumnFactory.standardColumn('documentNumberDisplayName', 'Document number'),
                    ColumnFactory.standardColumn('originalDocumentNumberDisplayName', 'Original document number'),
                    ColumnFactory.standardColumn('typeDisplayName', 'Type'),
                    ColumnFactory.standardColumn('volumeDisplayName', 'Volume'),
                    ColumnFactory.standardColumn('essoCblOnEtVolumeFactorDisplayName', 'ESSO CBL on ET volume factor'),
                    ColumnFactory.standardColumn('essoEtOnCblVolumeFactorDisplayName', 'ESSO ET on CBL volume factor'),
                ]}
                withCheckboxes={false}
                reference={null as any}
                tableName="CblDetailsEquipmentsTable"
                showPagination={true}
                expandable={true}
            />
        </StatusRow>
    );
};

