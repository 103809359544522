import React from 'react';
import { LoadingAnimation } from 'components/loading';
import { ColumnFactory } from 'components/data-table/ColumnFactory';
import { IBL, IBLVessel } from 'reducers/vessels/models';
import { connect } from 'react-redux';
import { ThunkDispatch } from 'redux-thunk';
import { Action } from 'redux';
import { exportContainerTrackingToExcel } from 'actions/export';
import { DataTable } from 'components/data-table/DamcoTable';
import { downloadFile } from 'utilities/download';
import { EditCBLDetailsPopup } from './EditCBLDetailsPopup';
import { NotifyCustomerModal } from 'modules/modals/components/NotifyCustomerModal';
import { openCBLDetailsModal, fetchMRNDetails } from 'actions/vessels';
import { mapStatusToStringForCT } from 'constants/statuses';
import { uniqWith, isEqual, xorWith } from 'lodash';
// tslint:disable-next-line: no-submodule-imports
import { withPermissions, IWithPermissions } from 'src/HOC/withPermissions';
import { BUSINNESS_FUNCTIONS } from 'constants/roles';
import { getArrayOfButtons } from 'utilities/util';
import { IStore } from 'reducers/index';
import { Dialog } from 'components/dialog';
import { UpdateCustomsDetailsModal } from 'modules/modals/components/UpdateCustomsDetailsModal';

interface IMapDispatchToProps {
    exportContainerTrackingToExcel: (isAir: boolean) => Promise<any>;
    openCBLDetailsModal: (blId: number) => void;
    fetchMRNDetails: (blId: number[]) => Promise<void>;
}

interface MapStateToProps {
    mrnData: any[];
}

interface IContainersTableOwnProps {
    data: any[];
    isLoading: boolean;
    pageCount: number;
    fetchBls: any;
}

interface IContainersTableProps extends IWithPermissions, IContainersTableOwnProps, IMapDispatchToProps, MapStateToProps {}

interface IContainersTableState{
    isNotifyCustomerModalOpened: boolean;
    isMultiselectErrorDialogOpened: boolean;
    isUpdateCustomsModalOpened: boolean;
    errorMessage: string;
    mrnData: any[];
}

class _ContainersTable extends React.Component<IContainersTableProps, IContainersTableState> {
    public dataTable = React.createRef<any>();

    public state = {
        isNotifyCustomerModalOpened: false,
        isMultiselectErrorDialogOpened: false,
        isUpdateCustomsModalOpened: false,
        errorMessage: 'Some error occured',
        mrnData: []
    };

    public componentDidUpdate(prevProps: IContainersTableProps) {
        const isArrayEqual = (x: any[], y: any[]) => xorWith(x, y, isEqual).length === 0;

        if(!isArrayEqual(this.props.data, prevProps.data)) {
            this.setState({ isMultiselectErrorDialogOpened: false });
        }
    }

    public render() {
        const data = this.props.data.map((bl: IBL, index: number) => {
            const vesselToShow = bl.vessels.length > 1 ? bl.vessels.find((vessel: IBLVessel) => vessel.isCurrentTranship) : bl.vessels[0];
            const transhipmentAtRIsk = () => {
                if (bl.vessels.find((vessel: IBLVessel) => vessel.transhipmentAtRisk)) {
                    return true;
                }
                return vesselToShow && vesselToShow.transhipmentAtRisk;
            };
            return {
                ...bl,
                ...vesselToShow,
                id: index,
                blNumberDisplayName: [bl.blNumber, `/tracking/mbl-tracking/${bl.blId}`],
                vesselNameDisplayName: [vesselToShow ? vesselToShow.vesselName : '-', `/tracking/${vesselToShow ? vesselToShow.voyageId : undefined}/${vesselToShow ? vesselToShow.voyageId : undefined}`],
                transhipmentAtRisk: transhipmentAtRIsk(),
                children: bl.vessels.map((obj: any, localIndex: number) => ({
                    ...obj,
                    id: `${index.toFixed(1)} + ${localIndex} / 10`,
                    vesselNameDisplayName: [obj.vesselName, `/tracking/${obj.voyageId}`],
                    voyageStatusDisplayName: mapStatusToStringForCT(obj.voyageStatus),
                    parentNode: 0
                })),
                expander: bl.vessels.length > 1,
                selected: ((bl as any).selected),
                voyageDelayReasonCode: bl.voyageDelayReasonCode === null ? '-' : bl.voyageDelayReasonCode
            };
        });
        const blIds = this.dataTable.current ? this.dataTable.current.getSelectedItems().map((item: any) => item.blId) : [];
        
        return (
            <React.Fragment>
                {this.props.isLoading && <LoadingAnimation/>}
                {this.state.isMultiselectErrorDialogOpened && <Dialog 
                    isVisible={this.state.isMultiselectErrorDialogOpened}
                    message={this.state.errorMessage} 
                    closeDialog={this.toggleMultiselectErrorDialog}
                />}
                <DataTable
                    data={data}
                    showColumnOptions={true}
                    columns={[
                        ColumnFactory.linkedColumn('blNumberDisplayName', 'CB/L number'),
                        ColumnFactory.linkedColumn('vesselNameDisplayName', 'Vessel name'),
                        ColumnFactory.standardColumn('carrierNameDisplayName', 'Carrier'),
                        ColumnFactory.standardColumn('loadingPortDisplayName', 'POL'),
                        ColumnFactory.standardColumn('destinationPortDisplayName', 'POD'),
                        ColumnFactory.standardColumn('dgDisplayName', 'DG'),
                        ColumnFactory.standardColumn('transhipmentDisplayName', 'Transhipment'),
                        ColumnFactory.standardColumn('etdDisplayName', 'ETD'),
                        ColumnFactory.standardColumn('atdDisplayName', 'ATD'),
                        ColumnFactory.standardColumn('originalETADisplayName', 'Original ETA'),
                        ColumnFactory.standardColumn('currentETADisplayName', 'New ETA'),
                        ColumnFactory.standardColumn('transhipmentAtRiskDisplayName', 'Transhipment at risk'),
                        ColumnFactory.standardColumn('ataDisplayName', 'ATA'),
                        ColumnFactory.standardColumn('voyageDelayReasonCodeDisplayName', 'Reason code'),
                    ]}
                    subColumns={[
                        ColumnFactory.linkedColumn('vesselNameDisplayName', 'Vessel name',),
                        ColumnFactory.standardColumn('loadingPortDisplayName', 'POL'),
                        ColumnFactory.standardColumn('destinationPortDisplayName', 'POD'),
                        ColumnFactory.standardColumn('atdDisplayName', 'ATD'),
                        ColumnFactory.standardColumn('originalETADisplayName', 'Original ETA'),
                        ColumnFactory.standardColumn('currentETADisplayName', 'New ETA'),
                        ColumnFactory.standardColumn('transhipmentAtRiskDisplayName', 'Transhipment at Risk'),
                        ColumnFactory.standardColumn('ataDisplayName', 'ATA'),
                        ColumnFactory.standardColumn('voyageStatusDisplayName', 'Status'),
                    ]}
                    withCheckboxes={true}
                    expandable={true}
                    selectVariant="multiple"
                    tableName="MBLTrackingList"
                    actions={{
                        primaryActionIsMoreBtn: false,
                        primaryActionLabel: 'Export to excel',
                        primaryAction: this.exportToExcel,
                        parentContextualActions: getArrayOfButtons(
                            this.props.permissions,
                            [
                                { isMoreBtn: false, label: 'Update schedule details', action: this.checkIfCblEditModalShouldOpen },
                                { isMoreBtn: false, label: 'Notify customer', action: this.toggleNotifyCustomerModal },
                                { isMoreBtn: false, label: 'Update customs details', action: this.getMRNData }
                            ])
                    }}
                    reference={this.dataTable}
                    manualPagination={true}
                    pageCount={this.props.pageCount}
                    fetchData={this.props.fetchBls}
                />
                <EditCBLDetailsPopup />
                {this.state.isNotifyCustomerModalOpened && <NotifyCustomerModal
                    visible={this.state.isNotifyCustomerModalOpened}
                    closeModal={this.toggleNotifyCustomerModal}
                    refreshFunction={this.props.fetchBls}
                    blId={blIds}
                    isAir={false}
                />}
                {this.state.isUpdateCustomsModalOpened && <UpdateCustomsDetailsModal
                    data={this.props.mrnData}
                    visible={this.state.isUpdateCustomsModalOpened}
                    closeModal={() => this.setState({ isUpdateCustomsModalOpened: false })}
                    ids={this.getIdsFromSelectedItems()}
                />}
            </React.Fragment>
        );
    };

    private exportToExcel = async () => {
        const res = await this.props.exportContainerTrackingToExcel(false);
        await downloadFile(res);
    };

    private toggleNotifyCustomerModal = () => this.setState((state) => ({
        isNotifyCustomerModalOpened: !state.isNotifyCustomerModalOpened
    }));

    private getBlId = () => {
        if(this.dataTable.current) {
            return this.dataTable.current.getSelectedItems()[0].blId;
        }
    };

    private canEditCBLDetails = () => {
        const selectedItems = this.dataTable.current ? this.dataTable.current.getSelectedItems() : [];
        if(selectedItems.length > 1) {
            const vessels = this.dataTable.current.getSelectedItems().map((selectedItem: any) => selectedItem.vessels);
            const sameLength = vessels.reduce((prev: any, current: any) => prev.length === current.length);
            const uniqCombination = uniqWith(selectedItems.map((selectedItem: any) => selectedItem.vessels.map(() => ({
                voyageId: selectedItem.voyageId,
                vesselName: selectedItem.vesselName,
                carrierName: selectedItem.carrierName,
            }))), isEqual);

            if(sameLength && uniqCombination.length === 1) {
                return true;
            } else {
                return false;
            }
        }
        return true;
    };

    private checkIfCblEditModalShouldOpen = () => {
        if(this.canEditCBLDetails()) {
            this.setState({ isMultiselectErrorDialogOpened: false });
            this.props.openCBLDetailsModal(this.getBlId());
        } else {
            this.setState({
                isMultiselectErrorDialogOpened: true,
                errorMessage: 'For selected CBLs vessels are not equal. Number of vessels, vessel name, voyage name should be same.'
            });
        }
    };

    private toggleMultiselectErrorDialog = () => this.setState(state => ({
        isMultiselectErrorDialogOpened: !state.isMultiselectErrorDialogOpened
    }));

    private getIdsFromSelectedItems = () => {
        const selectedItems = this.dataTable.current.getSelectedItems();
        return selectedItems.map((bl: any) => bl.blId);
    }

    private getMRNData = () => {
        const ids = this.getIdsFromSelectedItems();
        this.props.fetchMRNDetails(ids);

        this.setState({ isUpdateCustomsModalOpened: true });
    }
}

const ROLES: BUSINNESS_FUNCTIONS[] = [
    BUSINNESS_FUNCTIONS.OCEAN_MBL_TRACKING_EDIT_CBL,
    BUSINNESS_FUNCTIONS.OCEAN_MBL_TRACKING_NOTIFY_CUSTOMER,
    BUSINNESS_FUNCTIONS.OCEAN_MBL_TRACKING_EDIT_MRN
];

const mapDispatchToProps = (dispatch: ThunkDispatch<any, any, Action>): IMapDispatchToProps => ({
    fetchMRNDetails: (blId: number[]) => dispatch(fetchMRNDetails(blId)),
    exportContainerTrackingToExcel: (isAir: boolean) => dispatch(exportContainerTrackingToExcel(isAir)),
    openCBLDetailsModal: (blId: number) => dispatch(openCBLDetailsModal(blId)),
});

const mapStateToProps = (state: IStore): MapStateToProps => ({
    mrnData: state.vessels.mrnData
});

const __ContainersTable = connect<any, IMapDispatchToProps, IContainersTableOwnProps, IStore>(mapStateToProps, mapDispatchToProps)(_ContainersTable);
export const ContainersTable = withPermissions(__ContainersTable, ROLES) as any;
