import React, { Component } from 'react';
import { IVessel } from 'reducers/vessels/models';
import { formatDate, formatValueIfNullOrUndefined, getArrayOfButtons } from 'utilities/util';
import { VesselDetailsPopup } from 'modules/tracking/vessels/components/VesselDetailsPopup';
import { mapStatusToStringForCT } from 'constants/statuses';
import { BUSINNESS_FUNCTIONS } from 'constants/roles';
import { IRoles } from 'utilities/roles';
import { PropertiesRibbonWrapper } from 'components/properties-ribbon';
// tslint:disable-next-line: no-submodule-imports
import { IWithPermissions, withPermissions } from 'src/HOC/withPermissions';

interface IMapStateToProps {
    roles: IRoles[];
}

interface VesselDetailsTopBarProps extends IWithPermissions, IMapStateToProps, IVessel {}

interface IVesselDetailsTopBarState {
    editVesselDetailsPopup: boolean;
}

class _VesselDetailsTopBar extends Component<VesselDetailsTopBarProps, IVesselDetailsTopBarState> {
    public state: IVesselDetailsTopBarState = {
        editVesselDetailsPopup: false
    };

    public render() {
        const propertiesRibbonData = {
            labels: [
                {label: 'ATD', value: formatDate(this.props.atd)},
                {label: 'Original ETA', value: formatDate(this.props.originalETA)},
                {label: 'Current ETA', value: formatDate(this.props.currentETA)},
                {label: 'ATA', value: formatDate(this.props.ata)},
                {label: 'Status', value: mapStatusToStringForCT(formatValueIfNullOrUndefined(this.props.voyageStatus))},
                {label: 'Vessel code', value: formatValueIfNullOrUndefined(this.props.vesselCode)},
            ],
            actions: getArrayOfButtons(this.props.permissions, [{label: 'Edit vessel details', action: this.toggleEditVesselDetailsPopup}])
        }

        return(
            <React.Fragment>
                <PropertiesRibbonWrapper content={propertiesRibbonData} />
                {this.state.editVesselDetailsPopup && <VesselDetailsPopup
                    visible={this.state.editVesselDetailsPopup}
                    closeModal={this.toggleEditVesselDetailsPopup}
                    initialValues={this.getDataForEditModal()}
                    isDetails={true}
                />}
            </React.Fragment>
        )
    }

    private toggleEditVesselDetailsPopup = () => this.setState((state) => ({
        editVesselDetailsPopup: !state.editVesselDetailsPopup
    }));

    private getDataForEditModal = () => ({
        VesselCode: this.props.vesselCode,
        VesselName: this.props.vesselName,
        VoyageReference: this.props.voyageReference,
        VoyageId: this.props.voyageId,
        ATD: this.props.atd,
        ATA: this.props.ata,
        ETD: this.props.etd,
        ETA: this.props.eta,
        POLCode: this.props.loadingPort,
        PODCode: this.props.destinationPort,
        Status: this.props.voyageStatus,
        ReasonCode: this.props.voyageDelayReasonCode,
        Bls: this.props.bLs,
        Comments: this.props.comments
    });
}

const ROLES: BUSINNESS_FUNCTIONS[] = [BUSINNESS_FUNCTIONS.OCEAN_VESSEL_TRACKING_EDIT_VESSEL];

export const VesselDetailsTopBar = withPermissions(_VesselDetailsTopBar, ROLES) as any;
