export enum SORT_ORDER {
    ASCENDING = '0',
    DESCENDING = '1',
}

export interface ISortModel {
    order: SORT_ORDER,
    column: string;
    desc: boolean;
}
