import React, { Component } from 'react';
import { IMAWBDetailsVessels } from 'reducers/vessels/models';
import { formatValueIfNullOrUndefined, getArrayOfButtons } from 'utilities/util';
import { NotifyCustomerModal } from 'modules/modals/components/NotifyCustomerModal';
import { connect } from 'react-redux';
import { CTReasonCodes } from 'constants/reasonCodes';
import { EditMAWBDetailsPopup } from 'modules/tracking/containers/components/EditMAWBDetailsPopup';
import { fetchMAWBDetails, openCBLDetailsModal, fetchMRNDetails } from 'actions/vessels';
import { ThunkDispatch } from 'redux-thunk';
import { Action } from 'redux';
import { BUSINNESS_FUNCTIONS } from 'constants/roles';
import { PropertiesRibbonWrapper } from 'components/properties-ribbon';
// tslint:disable-next-line: no-submodule-imports
import { IWithPermissions, withPermissions } from 'src/HOC/withPermissions';
import { IStore } from 'reducers/index';
import { UpdateCustomsDetailsModal } from 'modules/modals/components/UpdateCustomsDetailsModal';

interface IMawbDetailsTopBarState {
    isNotifyCustomerPopupOpened: boolean;
    isUpdateCustomsModalOpened: boolean;
}

interface IMAWBDetailsOwnProps {
    blId: number;
    threePl: string;
    fourPLCarrierName: string;
    fourPLReference: string;
}

interface MapStateToProps {
    mrnData: any[];
}

interface IDispatch {
    fetchMRNDetails: (blId: number[]) => Promise<void>;
    openCBLDetailsModal: (blId: number) => void;
    fetchMAWBDetails: (blId: number) => Promise<void>;
}

interface IMawbDetailsProps extends IMAWBDetailsOwnProps, IWithPermissions, IMAWBDetailsVessels, IDispatch, MapStateToProps {}

class _MawbDetailsTopBar extends Component<IMawbDetailsProps, IMawbDetailsTopBarState> {
    public state: IMawbDetailsTopBarState = {
        isNotifyCustomerPopupOpened: false,
        isUpdateCustomsModalOpened: false
    };

    public render() {
        const actions = [
            {label: 'Update flight details', action: () => this.props.openCBLDetailsModal(this.props.blId)},
            {label: 'Notify customer', action: this.toggleNotifyCustomerPopup},
            {label: 'Update customs details', action: this.getMRNData}
        ];
        const propertiesRibbonData = {
            labels: [
                {label: '3PL', value: formatValueIfNullOrUndefined(this.props.threePl)},
                {label: 'Actual carrier', value: formatValueIfNullOrUndefined(this.props.fourPLCarrierName)},
                {label: 'Reference', value: formatValueIfNullOrUndefined(this.props.fourPLReference)},
                {label: 'Reason code', value: formatValueIfNullOrUndefined(this.props.voyageDelayReasonCode)},
            ],
            actions: getArrayOfButtons(this.props.permissions, actions)
        }

        return(
            <>
                <PropertiesRibbonWrapper content={propertiesRibbonData} />
                <EditMAWBDetailsPopup isDetails={true} />
                {this.state.isNotifyCustomerPopupOpened && <NotifyCustomerModal
                    visible={this.state.isNotifyCustomerPopupOpened}
                    closeModal={this.toggleNotifyCustomerPopup}
                    blId={[this.props.blId]}
                    group={CTReasonCodes.Air_LateShipment}
                    refreshFunction={this.fetchMAWBDetails}
                    isAir={true}
                />}
                {this.state.isUpdateCustomsModalOpened && <UpdateCustomsDetailsModal
                    data={this.props.mrnData}
                    visible={this.state.isUpdateCustomsModalOpened}
                    closeModal={() => this.setState({ isUpdateCustomsModalOpened: false })}
                    ids={this.props.blId}
                />}
            </>
        )
    }

    private fetchMAWBDetails = async (ids: number[]) => {
        await Promise.all(ids.map(id => this.props.fetchMAWBDetails(id)));
    }

    private toggleNotifyCustomerPopup = () => this.setState((state) => ({
        isNotifyCustomerPopupOpened: !state.isNotifyCustomerPopupOpened
    }));

    private getMRNData = () => {
        this.props.fetchMRNDetails([this.props.blId]);

        this.setState({ isUpdateCustomsModalOpened: true });
    }
}

const mapStateToProps = (state: IStore): MapStateToProps => ({
    mrnData: state.vessels.mrnData
});

const mapDispatchToProps = (dispatch: ThunkDispatch<any, any, Action>): IDispatch => ({
    fetchMRNDetails: (blId: number[]) => dispatch(fetchMRNDetails(blId)),
    openCBLDetailsModal: (blId: number) => dispatch(openCBLDetailsModal(blId)),
    fetchMAWBDetails: (blId: number) => dispatch(fetchMAWBDetails(blId)),
});

const ROLES: BUSINNESS_FUNCTIONS[] = [
    BUSINNESS_FUNCTIONS.AIR_MAWB_TRACKING_EDIT_CBL, 
    BUSINNESS_FUNCTIONS.AIR_MAWB_TRACKING_NOTIFY_CUSTOMER,
    BUSINNESS_FUNCTIONS.AIR_MAWB_TRACKING_EDIT_MRN
];

const __MawbDetailsTopBar = withPermissions<IMawbDetailsProps>(_MawbDetailsTopBar, ROLES);

export const MawbDetailsTopBar = connect<{}, IDispatch>(mapStateToProps, mapDispatchToProps)(__MawbDetailsTopBar) as any;