import React from 'react';
import { ModalScreen } from 'components/modal-screen';
import { ModalType } from 'components/modal-screen/ModalType';

interface IConfirmModal {
    visible: boolean;
    title: string;
    children: React.ReactChild | React.ReactChild[],
    buttonId?: string;
    closeModal(): void;
    onConfirm(): void;
}

const _ConfirmModal = (props: IConfirmModal) => (
    <ModalScreen
        title={props.title}
        modalType={ModalType.s()}
        visible={props.visible}
        closeModal={props.closeModal}
        secondaryButtonFunc={props.closeModal}
        primaryButtonTitle="Yes"
        primaryButtonFunc={props.onConfirm}
        secondaryButtonTitle="No"
        primaryButtonId={props.buttonId}
    >
        {props.children}
    </ModalScreen>
);

export const ConfirmModal = _ConfirmModal;