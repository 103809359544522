import React, { Component } from 'react';
import { Redirect, Switch } from 'react-router';
import { ILink } from 'components/sub-navigation/models/Link';
import { SubNavigationVertical } from 'components/sub-navigation-vertical';
import { Contracts } from 'modules/settings/demurrage-and-detention/containers/contracts';
import { RouteWithPermissions } from 'containers/route-with-permissions';
import { BUSINNESS_FUNCTIONS } from 'constants/roles';
import { ReasonCodes } from './containers/ReasonCodes';

export class DemurrageAndDetention extends Component {
    private subLinks: ILink[] = [
        {
            to: 'contracts',
            linkText: 'Contracts'
        },
        {
            to: 'reason-codes',
            linkText: 'Reason codes'
        },
    ];

    public render() {
        return (
            <div className="grid-wrapper">
                <div className="col-10">
                    <SubNavigationVertical
                        links={this.subLinks}
                    />
                </div>
                <div className="col-90">
                    <Switch>
                        <RouteWithPermissions
                            path="/exception-management/settings/demurrage-and-detention/contracts"
                            component={Contracts}
                            functions={[ BUSINNESS_FUNCTIONS.CONFIGURATION_DELIVERY_PLANNING ]}
                        />
                        <RouteWithPermissions
                            path="/exception-management/settings/demurrage-and-detention/reason-codes"
                            component={ReasonCodes}
                            functions={[ BUSINNESS_FUNCTIONS.CONFIGURATION_DELIVERY_PLANNING ]}
                        />
                        <Redirect to="/exception-management/settings/demurrage-and-detention/contracts" />
                    </Switch>
                </div>
            </div>
        );
    }
}