import React from 'react';
import { connect } from 'react-redux';
import { ColumnFactory } from 'components/data-table/ColumnFactory';
import { DataTable } from 'components/data-table/DataTable';
import { withTableCheckboxes, IWithTableCheckboxesProps } from 'containers/data-table/withCheckboxes';
import { Button, ButtonType } from 'components/button';
import { ThunkDispatch } from 'redux-thunk';
import { Action } from 'redux';
import { deleteEquipmentDeliveries, getEquipmentDeliveries } from 'actions/planning';
import { LoadingAnimation } from 'components/loading';
import { IStore } from 'reducers/index';
import { Transport_Mode, Transport_Type } from 'constants/transport';
import styled from 'styled-components';
import { AreYouSureModal } from 'modules/modals/components/AreYouSureModal';
import { AddTransportationRowModal } from 'modules/modals/components/AddTransportationRowModal';
import { ISettingsModel } from 'reducers/settings/models';
import { fetchDeliveryPlanningHaulierSettings } from 'actions/settings';
import { hauliersToEnum } from 'utilities/util';
import { cloneDataWithDisplayNames } from 'utilities/tables';

interface IEditEquipmentDeliveriesTableOwnProps {
    ids: number[];
    isEmptyReturn?: boolean;
    isAir?: boolean;
}

interface IEditEquipmentDeliveriesTable
    extends IEditEquipmentDeliveriesTableOwnProps, IWithTableCheckboxesProps, IDispatch, IMapStateToProps {}

interface IMapStateToProps {
    isLoading: boolean;
    data: any[];
    deliveryPlanningHaulierSettings: ISettingsModel[];
    fetchDeliveryPlanningHaulierSettingsPending: boolean;
}

interface IDispatch {
    deleteEquipmentDeliveries: (containerId: number, ids: { deliveriesIds: number[]}) => Promise<void>;
    getEquipmentDeliveries: (id: number) => Promise<void>;
    fetchDeliveryPlanningHaulierSettings: () => void,
}

interface IEditEquipmentDeliveriesTableState {
    isAreYouSureModalOpened: boolean;
    isInsertRowModalOpened: boolean;
    isEdit: boolean;
}

const SButton = styled(Button)`
    margin-right: 10px;
    margin-bottom: 10px;
`;

class _EditEquipmentDeliveriesTable extends React.Component<IEditEquipmentDeliveriesTable, IEditEquipmentDeliveriesTableState> {
    public state = {
        isAreYouSureModalOpened: false,
        isInsertRowModalOpened: false,
        isEdit: false,
    };

    public componentDidMount() {
        this.props.fetchDeliveryPlanningHaulierSettings();
    }

    public render() {
        const { dropOffDateName, pickupDateName } = this.getNames();
        const hauliers = hauliersToEnum(this.props.deliveryPlanningHaulierSettings);
        const data = cloneDataWithDisplayNames(this.props.data).map((obj: any) => ({
            ...obj,
            transportModeDisplayName: Transport_Mode[obj.transportMode],
            transportTypeDisplayName: Transport_Type[obj.transportType],
            haulierDisplayName: hauliers[obj.haulier]
        }));

        return (
            <React.Fragment>
                {this.props.isLoading || this.props.fetchDeliveryPlanningHaulierSettingsPending && <LoadingAnimation/>}
                {this.state.isInsertRowModalOpened && <AddTransportationRowModal
                    visible={this.state.isInsertRowModalOpened}
                    closeModal={this.toggleAddTransportationRowModal}
                    id={this.props.ids[0]}
                    initialValues={this.state.isEdit ? this.props.checked[0] : {}}
                    isEmptyReturn={this.props.isEmptyReturn}
                    isAir={this.props.isAir}
                    fromPlanning={true}
                />}
                <AreYouSureModal
                    visible={this.state.isAreYouSureModalOpened}
                    onClose={this.toggleAreYouSureModal}
                    onAccept={this.deleteDeliveries}
                />
                <SButton
                    buttonType={ButtonType.Blue}
                    onClick={this.toggleAddTransportationRowModal}
                    id="insert-row-button"
                >
                    Insert row
                </SButton>
                <SButton
                    buttonType={ButtonType.Transparent}
                    onClick={this.toggleEditTransportationRowModal}
                    isDisabled={!this.props.checked.length}
                    id="edit-row-button"
                >
                    Edit row
                </SButton>
                <SButton
                    buttonType={ButtonType.Transparent}
                    onClick={this.toggleAreYouSureModal}
                    isDisabled={!this.props.checked.length}
                    id="delete-row-button"
                >
                    Remove row
                </SButton>
                <DataTable
                    data={data}
                    columns={[
                        ColumnFactory.checkBoxColumn(this.props.toggleCheckbox, () => false, false),
                        ColumnFactory.standardColumnOld('transportModeDisplayName', 'Transport mode'),
                        ColumnFactory.standardColumnOld('transportTypeDisplayName', 'Transport type'),
                        ColumnFactory.standardColumnOld('haulierDisplayName', 'Haulier'),
                        ColumnFactory.standardColumnOld('isTransportOrderSentDisplayName', 'TO sent'),
                        ColumnFactory.standardColumnOld('pickupLocationDisplayName', 'Pickup location'),
                        ColumnFactory.dateColumnOld(pickupDateName, 'Pickup date'),
                        ColumnFactory.standardColumnOld('dropOffLocationDisplayName', 'Drop-off location'),
                        ColumnFactory.dateColumnOld(dropOffDateName, 'Drop-off date'),
                        ColumnFactory.standardColumnOld('deliveryReferenceDisplayName', 'Delivery reference'),
                    ]}
                />
            </React.Fragment>
        );
    }

    private getNames = () => {
        return this.props.isEmptyReturn ?  {
            dropOffDateName: 'dropOffDate',
            pickupDateName: 'pickupDate'
        } : {
            dropOffDateName: 'estimatedDropOffDate',
            pickupDateName: 'estimatedPickupDate',
        };
    };

    private toggleAreYouSureModal = () => this.setState((state: IEditEquipmentDeliveriesTableState) => ({
        isAreYouSureModalOpened: !state.isAreYouSureModalOpened,
    }));

    private toggleAddTransportationRowModal = () => this.setState((state: IEditEquipmentDeliveriesTableState) => ({
        isInsertRowModalOpened: !state.isInsertRowModalOpened,
        isEdit: false,
    }));

    private toggleEditTransportationRowModal = () => this.setState((state: IEditEquipmentDeliveriesTableState) => ({
        isInsertRowModalOpened: !state.isInsertRowModalOpened,
        isEdit: true,
    }));

    private deleteDeliveries = async () => {
        this.props.ids.map(async (containerId: number) => {
            const deliveriesIds = this.props.checked.map(({ id }: any) => id);
            await this.props.deleteEquipmentDeliveries(containerId, { deliveriesIds });
            this.props.getEquipmentDeliveries(containerId);
        })
    }
}

const mapStateToProps = (state: IStore): IMapStateToProps => {
    const {
        fetchDeliveryPlanningHaulierSettingsPending,
        deliveryPlanningHaulierSettings,
    } = state.settings;
    return {
        deliveryPlanningHaulierSettings,
        isLoading: state.planning.isDeleteEquipmentDeliveriesPending || state.planning.isGetEquipmentsPending,
        fetchDeliveryPlanningHaulierSettingsPending,
        data: state.planning.equipmentDeliveries,
    }
};

const mapDispatchToProps = (dispatch: ThunkDispatch<any, any, Action>) => ({
    deleteEquipmentDeliveries: (containerId: number, ids: { deliveriesIds: number[]}) => dispatch(deleteEquipmentDeliveries(containerId, ids, false)),
    getEquipmentDeliveries: (id: number) => dispatch(getEquipmentDeliveries(id, false)),
    fetchDeliveryPlanningHaulierSettings: () => dispatch(fetchDeliveryPlanningHaulierSettings(true)),
});

export const EditEquipmentDeliveriesTable = connect<IMapStateToProps, IDispatch, IEditEquipmentDeliveriesTableOwnProps, IStore>(mapStateToProps, mapDispatchToProps)(
    withTableCheckboxes<IEditEquipmentDeliveriesTableOwnProps>(_EditEquipmentDeliveriesTable, 'id')
);
