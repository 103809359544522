import React from 'react';
import { ModalScreen } from 'components/modal-screen';
import { ModalType } from 'components/modal-screen/ModalType';
import { FormInput } from 'components/input/FormInput';
import { InjectedFormProps, reduxForm, reset } from 'redux-form';
import { Dispatch } from 'redux';
import { DoubleGrid } from 'components/grid/double-grid';
import { LoadingAnimation } from 'components/loading';
import { SingleGrid } from 'components/grid/single-grid';
import { FormTextArea } from 'components/textarea/FormTextArea';
import { ISettingsModel } from 'reducers/settings/models';

interface IConfigurationModal {
    title: string;
    visible: boolean;
    closeModal: () => void;
    saveFunction: (configuration: ISettingsModel) => void;
    isLoading: boolean;
}

const FORM_NAME = 'CONFIGURATION_MODAL';

const emailValidation = (value: string) => value && !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(value) ? 'Invalid email address' : undefined;

const _ConfigurationModal: React.SFC<InjectedFormProps<{}, IConfigurationModal> & IConfigurationModal> = (props: InjectedFormProps<{}, IConfigurationModal> & IConfigurationModal) => {
    if (props.isLoading) {
        return <LoadingAnimation />
    }

    return (
        <form onSubmit={props.handleSubmit(props.saveFunction)}>
            <ModalScreen
                title={props.title}
                visible={props.visible}
                modalType={ModalType.s()}
                closeModal={props.closeModal}
                primaryButtonTitle="Done"
                primaryButtonFunc={() => null}
                primaryButtonType="submit"
                secondaryButtonTitle="Cancel"
                secondaryButtonFunc={props.closeModal}
            >
                <DoubleGrid>
                    <FormInput
                        label="Name"
                        name="Name"
                    />
                    <FormInput
                        label="Email"
                        name="Email"
                        validate={emailValidation}
                    />
                </DoubleGrid>
                <SingleGrid>
                    <FormInput
                        label="Subject"
                        name="Subject"
                    />
                </SingleGrid>
                <SingleGrid>
                    <FormTextArea
                        label="Body"
                        name="Body"
                    />
                </SingleGrid>
                <DoubleGrid>
                    <FormInput
                        label="Country"
                        name="Country"
                    />
                    <FormInput
                        label="BeCode"
                        name="BeCode"
                    />
                </DoubleGrid>
            </ModalScreen>
        </form>
    );
};

const afterSubmit = (_: any, dispatch: Dispatch) => dispatch(reset(FORM_NAME));

export const ConfigurationModal = reduxForm<{}, IConfigurationModal>({
    form: FORM_NAME,
    onSubmitSuccess: afterSubmit,
})(_ConfigurationModal);
