import React from 'react';

interface ISingleGrid {
    children: React.ReactChild;
}

export const SingleGrid: React.FunctionComponent<ISingleGrid> = (props) => (
    <div className="grid-wrapper">
        <div className="col-100">
            {props.children}
        </div>
    </div>
);

