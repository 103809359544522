import { TYPES } from 'action-types/settings';
import {
    ISettingsModel,
    IGlobalSettings,
    IReasonCode,
    ICustomerConfigurationsList,
    IGOHTranslations,
} from 'reducers/settings/models';
import { ICarrier } from 'views/exception-management/settings/container-tracking/containers/Carriers';
import { IResultWithPagination } from 'reducers/models';
import { IOfficePrefix } from 'reducers/fact/models';

export interface ISettingsAction {
    readonly type: string;
    readonly error?: string;
    readonly settings?: ISettingsModel[];
    readonly globalSettings?: IGlobalSettings[];
    readonly reasonCodes?: IReasonCode[];
    readonly customerConfigurations?: ICustomerConfigurationsList;
    readonly carriers?: ICarrier[];
    readonly officePrefixes?: IResultWithPagination<IOfficePrefix>;
    readonly GOHTranslations?: IGOHTranslations;
}

export interface ISettingsState {
    containerTrackingCarriers: ICarrier[];
    containerTrackingGlobalSettings: IGlobalSettings[];
    deliveryPlanningGlobalSettings: IGlobalSettings[];
    customsGlobalSettings: IGlobalSettings[];
    deliveryPlanningDerigSettings: ISettingsModel[];
    deliveryPlanningHaulierSettings: ISettingsModel[];
    containerTrackingReasonCodes: IReasonCode[];
    deliveryPlanningReasonCodes: IReasonCode[];
    customsReasonCodes: IReasonCode[];
    containerTrackingCarriersPending: boolean;
    containerTrackingCarriersError: string;
    fetchDeliveryPlanningDeRigSettingsPending: boolean;
    fetchDeliveryPlanningHaulierSettingsPending: boolean;
    createDeliveryPlanningDeRigSettingsPending: boolean;
    createDeliveryPlanningHaulierSettingsPending: boolean;
    updateDeliveryPlanningDeRigSettingsPending: boolean;
    updateDeliveryPlanningHaulierSettingsPending: boolean;
    deleteDeliveryPlanningDeRigSettingsPending: boolean;
    deleteDeliveryPlanningHaulierSettingsPending: boolean;
    fetchContainerTrackingGlobalsSettingsPending: boolean;
    fetchDeliveryPlanningGlobalsSettingsPending: boolean;
    fetchCustomsGlobalsSettingsPending: boolean;
    updateCustomsGlobalsSettingsPending: boolean;
    updateContainerTrackingGlobalSettingsPending: boolean;
    fetchReasonCodeIsLoading: boolean;
    fetchReasonCodeIsError: boolean;
    updateDeliveryPlanningReasonCodeIsLoading: boolean;
    updateDeliveryPlanningReasonCodeError: string;
    deleteDeliveryPlanningReasonCodeIsLoading: boolean;
    deleteDeliveryPlanningReasonCodeIsError: boolean;
    createDeliveryPlanningReasonCodeIsLoading: boolean;
    createDeliveryPlanningReasonCodeError: string;
    updateContainerTrackingReasonCodeIsLoading: boolean;
    updateContainerTrackingReasonCodeError: string;
    deleteContainerTrackingReasonCodeIsLoading: boolean;
    deleteContainerTrackingReasonCodeIsError: boolean;
    createContainerTrackingReasonCodeIsLoading: boolean;
    createContainerTrackingReasonCodeError: string;
    updateCustomsReasonCodeIsLoading: boolean;
    updateCustomsReasonCodeError: string;
    deleteCustomsReasonCodeIsLoading: boolean;
    deleteCustomsReasonCodeIsError: boolean;
    createCustomsReasonCodeIsLoading: boolean;
    createCustomsReasonCodeError: string;
    customsBrokerSettings: ISettingsModel[];
    fetchCustomsBrokerSettingsPending: boolean;
    createCustomsBrokerSettingsPending: boolean;
    updateCustomsBrokerSettingsPending: boolean;
    deleteCustomsBrokerSettingsPending: boolean;
    isCreateDeliveryPlanningCustomerPending: boolean;
    isCreateDeliveryPlanningCustomerError: boolean;
    isGetDeliveryPlanningCustomerPending: boolean;
    isGetDeliveryPlanningCustomerError: boolean;
    isEditDeliveryPlanningCustomerPending: boolean;
    isEditDeliveryPlanningCustomerError: boolean;
    isDeleteDeliveryPlanningCustomerPending: boolean;
    isDeleteDeliveryPlanningCustomerError: boolean;
    customerConfigurations: ICustomerConfigurationsList;
    factOfficePrefixes: IResultWithPagination<IOfficePrefix>;
    dndReasonCodes: IReasonCode[];
    fetchDndReasonCodesPending: boolean;
    createDndReasonCodesPending: boolean;
    updateDndReasonCodesPending: boolean;
    deleteDndReasonCodesPending: boolean;
    createDndReasonCodesError: string;
    updateDndReasonCodesError: string;
    GOHTranslations: IGOHTranslations;
}

const defaultState: ISettingsState = {
    containerTrackingCarriers: [],
    containerTrackingGlobalSettings: [],
    deliveryPlanningGlobalSettings: [],
    customsGlobalSettings: [],
    deliveryPlanningDerigSettings: [],
    deliveryPlanningHaulierSettings: [],
    containerTrackingReasonCodes: [],
    deliveryPlanningReasonCodes: [],
    customsReasonCodes: [],
    containerTrackingCarriersPending: false,
    containerTrackingCarriersError: '',
    fetchDeliveryPlanningDeRigSettingsPending: false,
    fetchDeliveryPlanningHaulierSettingsPending: false,
    createDeliveryPlanningDeRigSettingsPending: false,
    createDeliveryPlanningHaulierSettingsPending: false,
    updateDeliveryPlanningDeRigSettingsPending: false,
    updateDeliveryPlanningHaulierSettingsPending: false,
    deleteDeliveryPlanningDeRigSettingsPending: false,
    deleteDeliveryPlanningHaulierSettingsPending: false,
    fetchContainerTrackingGlobalsSettingsPending: false,
    fetchDeliveryPlanningGlobalsSettingsPending: false,
    fetchCustomsGlobalsSettingsPending: false,
    updateCustomsGlobalsSettingsPending: false,
    updateContainerTrackingGlobalSettingsPending: false,
    fetchReasonCodeIsLoading: false,
    fetchReasonCodeIsError: false,
    updateDeliveryPlanningReasonCodeIsLoading: false,
    updateDeliveryPlanningReasonCodeError: '',
    deleteDeliveryPlanningReasonCodeIsLoading: false,
    deleteDeliveryPlanningReasonCodeIsError: false,
    createDeliveryPlanningReasonCodeIsLoading: false,
    createDeliveryPlanningReasonCodeError: '',
    updateContainerTrackingReasonCodeIsLoading: false,
    updateContainerTrackingReasonCodeError: '',
    deleteContainerTrackingReasonCodeIsLoading: false,
    deleteContainerTrackingReasonCodeIsError: false,
    createContainerTrackingReasonCodeIsLoading: false,
    createContainerTrackingReasonCodeError: '',
    updateCustomsReasonCodeIsLoading: false,
    updateCustomsReasonCodeError: '',
    deleteCustomsReasonCodeIsLoading: false,
    deleteCustomsReasonCodeIsError: false,
    createCustomsReasonCodeIsLoading: false,
    createCustomsReasonCodeError: '',
    customsBrokerSettings: [],
    fetchCustomsBrokerSettingsPending: false,
    createCustomsBrokerSettingsPending: false,
    updateCustomsBrokerSettingsPending: false,
    deleteCustomsBrokerSettingsPending: false,
    isCreateDeliveryPlanningCustomerPending: false,
    isCreateDeliveryPlanningCustomerError: false,
    isGetDeliveryPlanningCustomerPending: false,
    isGetDeliveryPlanningCustomerError: false,
    isEditDeliveryPlanningCustomerPending: false,
    isEditDeliveryPlanningCustomerError: false,
    isDeleteDeliveryPlanningCustomerPending: false,
    isDeleteDeliveryPlanningCustomerError: false,
    customerConfigurations: {
        data: [],
        pageNumber: 1,
        pageSize: 20,
        totalCount: 0,
    },

    factOfficePrefixes: {
        result: [],
        pageNumber: 1,
        pageSize: 20,
        totalCount: 0,
    },
    dndReasonCodes: [],
    fetchDndReasonCodesPending: false,
    createDndReasonCodesPending: false,
    updateDndReasonCodesPending: false,
    deleteDndReasonCodesPending: false,
    createDndReasonCodesError: '',
    updateDndReasonCodesError: '',
    GOHTranslations: {
        result: [],
        pageNumber: 1,
        pageSize: 20,
        totalCount: 0,
    }
};

export const reducer = (state: ISettingsState = defaultState, action: ISettingsAction): ISettingsState => {
    switch (action.type) {
        case TYPES.CREATE_DELIVERY_PLANNING_DERIG_SETTINGS.PENDING:
            return {
                ...state,
                createDeliveryPlanningDeRigSettingsPending: true,
            };
        case TYPES.CREATE_DELIVERY_PLANNING_DERIG_SETTINGS.FULFILLED:
            return {
                ...state,
                createDeliveryPlanningDeRigSettingsPending: false,
            };
        case TYPES.FETCH_DELIVERY_PLANNING_DERIG_SETTINGS.PENDING:
            return {
                ...state,
                fetchDeliveryPlanningDeRigSettingsPending: true,
            };
        case TYPES.FETCH_DELIVERY_PLANNING_DERIG_SETTINGS.FULFILLED:
            return {
                ...state,
                deliveryPlanningDerigSettings: action.settings || defaultState.deliveryPlanningDerigSettings,
                fetchDeliveryPlanningDeRigSettingsPending: false,
            };
        case TYPES.FETCH_DELIVERY_PLANNING_DERIG_SETTINGS.REJECTED:
            return {
                ...state,
                fetchDeliveryPlanningDeRigSettingsPending: false,
            };
        case TYPES.UPDATE_DELIVERY_PLANNING_DERIG_SETTINGS.PENDING:
            return {
                ...state,
                updateDeliveryPlanningDeRigSettingsPending: false,
            };
        case TYPES.UPDATE_DELIVERY_PLANNING_DERIG_SETTINGS.FULFILLED:
            return {
                ...state,
                updateDeliveryPlanningDeRigSettingsPending: false,
            };
        case TYPES.DELETE_DELIVERY_PLANNING_DERIG_SETTINGS.PENDING:
            return {
                ...state,
                deleteDeliveryPlanningDeRigSettingsPending: true,
            };
        case TYPES.DELETE_DELIVERY_PLANNING_DERIG_SETTINGS.FULFILLED:
            return {
                ...state,
                deleteDeliveryPlanningDeRigSettingsPending: false,
            };
        case TYPES.CREATE_DELIVERY_PLANNING_HAULIERS_SETTINGS.PENDING:
            return {
                ...state,
                createDeliveryPlanningHaulierSettingsPending: true,
            };
        case TYPES.CREATE_DELIVERY_PLANNING_HAULIERS_SETTINGS.FULFILLED:
            return {
                ...state,
                createDeliveryPlanningHaulierSettingsPending: false,
            };
        case TYPES.FETCH_DELIVERY_PLANNING_HAULIER_SETTINGS.PENDING:
            return {
                ...state,
                fetchDeliveryPlanningHaulierSettingsPending: true,
            };
        case TYPES.FETCH_DELIVERY_PLANNING_HAULIER_SETTINGS.FULFILLED:
            return {
                ...state,
                deliveryPlanningHaulierSettings: action.settings || defaultState.deliveryPlanningHaulierSettings,
                fetchDeliveryPlanningHaulierSettingsPending: false,
            };
        case TYPES.FETCH_DELIVERY_PLANNING_HAULIER_SETTINGS.REJECTED:
            return {
                ...state,
                fetchDeliveryPlanningHaulierSettingsPending: false,
            };
        case TYPES.UPDATE_DELIVERY_PLANNING_HAULIER_SETTINGS.PENDING:
            return {
                ...state,
                updateDeliveryPlanningHaulierSettingsPending: true,
            };
        case TYPES.UPDATE_DELIVERY_PLANNING_HAULIER_SETTINGS.FULFILLED:
            return {
                ...state,
                updateDeliveryPlanningHaulierSettingsPending: false,
            };
        case TYPES.DELETE_DELIVERY_PLANNING_HAULIERS_SETTINGS.PENDING:
            return {
                ...state,
                deleteDeliveryPlanningHaulierSettingsPending: true,
            };
        case TYPES.DELETE_DELIVERY_PLANNING_HAULIERS_SETTINGS.FULFILLED:
            return {
                ...state,
                deleteDeliveryPlanningHaulierSettingsPending: false,
            };

        case TYPES.FETCH_CONTAINER_TRACKING_GLOBALS_SETTINGS.PENDING:
            return {
                ...state,
                fetchContainerTrackingGlobalsSettingsPending: true,
            };
        case TYPES.FETCH_CONTAINER_TRACKING_GLOBALS_SETTINGS.FULFILLED:
            return {
                ...state,
                fetchContainerTrackingGlobalsSettingsPending: false,
                containerTrackingGlobalSettings: action.globalSettings || defaultState.containerTrackingGlobalSettings
            };
        case TYPES.FETCH_CONTAINER_TRACKING_GLOBALS_SETTINGS.REJECTED:
            return {
                ...state,
                fetchContainerTrackingGlobalsSettingsPending: false,
            };
        case TYPES.FETCH_DELIVERY_PLANNING_GLOBALS_SETTINGS.PENDING:
            return {
                ...state,
                fetchDeliveryPlanningGlobalsSettingsPending: true,
            };
        case TYPES.FETCH_DELIVERY_PLANNING_GLOBALS_SETTINGS.FULFILLED:
            return {
                ...state,
                fetchDeliveryPlanningGlobalsSettingsPending: false,
                deliveryPlanningGlobalSettings: action.globalSettings || defaultState.deliveryPlanningGlobalSettings
            };
        case TYPES.FETCH_DELIVERY_PLANNING_GLOBALS_SETTINGS.REJECTED:
            return {
                ...state,
                fetchDeliveryPlanningGlobalsSettingsPending: false,
            };
        case TYPES.FETCH_CUSTOMS_GLOBALS_SETTINGS.PENDING:
            return {
                ...state,
                fetchCustomsGlobalsSettingsPending: true,
            };
        case TYPES.FETCH_CUSTOMS_GLOBALS_SETTINGS.FULFILLED:
            return {
                ...state,
                fetchCustomsGlobalsSettingsPending: false,
                customsGlobalSettings: action.globalSettings || defaultState.customsGlobalSettings
            };
        case TYPES.FETCH_CUSTOMS_GLOBALS_SETTINGS.REJECTED:
            return {
                ...state,
                fetchCustomsGlobalsSettingsPending: false,
            };
        case TYPES.UPDATE_CUSTOMS_GLOBAL_SETTINGS.PENDING:
            return {
                ...state,
                updateCustomsGlobalsSettingsPending: true,
            };
        case TYPES.UPDATE_CUSTOMS_GLOBAL_SETTINGS.FULFILLED:
            return {
                ...state,
                updateCustomsGlobalsSettingsPending: false,
            };
        case TYPES.UPDATE_CUSTOMS_GLOBAL_SETTINGS.REJECTED:
            return {
                ...state,
                updateCustomsGlobalsSettingsPending: false,
            };
        case TYPES.UPDATE_CONTAINER_TRACKING_GLOBAL_SETTINGS.PENDING:
            return {
                ...state,
                updateContainerTrackingGlobalSettingsPending: true
            };
        case TYPES.UPDATE_CONTAINER_TRACKING_GLOBAL_SETTINGS.FULFILLED:
            return {
                ...state,
                updateContainerTrackingGlobalSettingsPending: false,
            };
        case TYPES.UPDATE_CONTAINER_TRACKING_GLOBAL_SETTINGS.REJECTED:
            return {
                ...state,
                updateContainerTrackingGlobalSettingsPending: false
            };
        case TYPES.FETCH_DELIVERY_PLANNING_REASON_CODES.PENDING:
            return {
                ...state,
                fetchReasonCodeIsLoading: true,
                fetchReasonCodeIsError: false
            };
        case TYPES.FETCH_DELIVERY_PLANNING_REASON_CODES.FULFILLED:
            return {
                ...state,
                fetchReasonCodeIsLoading: false,
                fetchReasonCodeIsError: false,
                deliveryPlanningReasonCodes: action.reasonCodes || defaultState.deliveryPlanningReasonCodes
            };
        case TYPES.FETCH_DELIVERY_PLANNING_REASON_CODES.REJECTED:
            return {
                ...state,
                fetchReasonCodeIsLoading: false,
                fetchReasonCodeIsError: true
            };
        case TYPES.UPDATE_DELIVERY_PLANNING_REASON_CODES.PENDING:
            return {
                ...state,
                updateDeliveryPlanningReasonCodeIsLoading: true,
                updateDeliveryPlanningReasonCodeError: '',
            };
        case TYPES.UPDATE_DELIVERY_PLANNING_REASON_CODES.FULFILLED:
            return {
                ...state,
                updateDeliveryPlanningReasonCodeIsLoading: false,
                updateDeliveryPlanningReasonCodeError: '',
            };
        case TYPES.UPDATE_DELIVERY_PLANNING_REASON_CODES.REJECTED:
            return {
                ...state,
                updateDeliveryPlanningReasonCodeIsLoading: false,
                updateDeliveryPlanningReasonCodeError: action.error || defaultState.updateDeliveryPlanningReasonCodeError,
            };
        case TYPES.UPDATE_DELIVERY_PLANNING_REASON_CODES.RESET_ERROR:
            return {
                ...state,
                updateDeliveryPlanningReasonCodeError: '',
            };
        case TYPES.DELETE_DELIVERY_PLANNING_REASON_CODES.PENDING:
            return {
                ...state,
                deleteDeliveryPlanningReasonCodeIsLoading: true,
                deleteDeliveryPlanningReasonCodeIsError: false,
            };
        case TYPES.DELETE_DELIVERY_PLANNING_REASON_CODES.FULFILLED:
            return {
                ...state,
                deleteDeliveryPlanningReasonCodeIsLoading: false,
                deleteDeliveryPlanningReasonCodeIsError: false,
            };
        case TYPES.DELETE_DELIVERY_PLANNING_REASON_CODES.REJECTED:
            return {
                ...state,
                deleteDeliveryPlanningReasonCodeIsLoading: false,
                deleteDeliveryPlanningReasonCodeIsError: true,
            };
        case TYPES.CREATE_DELIVERY_PLANNING_REASON_CODES.PENDING:
            return {
                ...state,
                createDeliveryPlanningReasonCodeIsLoading: true,
                createDeliveryPlanningReasonCodeError: '',
            };
        case TYPES.CREATE_DELIVERY_PLANNING_REASON_CODES.FULFILLED:
            return {
                ...state,
                createDeliveryPlanningReasonCodeIsLoading: false,
                createDeliveryPlanningReasonCodeError: '',
            };
        case TYPES.CREATE_DELIVERY_PLANNING_REASON_CODES.REJECTED:
            return {
                ...state,
                createDeliveryPlanningReasonCodeIsLoading: false,
                createDeliveryPlanningReasonCodeError: action.error || defaultState.createDeliveryPlanningReasonCodeError,
            };
        case TYPES.CREATE_DELIVERY_PLANNING_REASON_CODES.RESET_ERROR:
            return {
                ...state,
                createDeliveryPlanningReasonCodeError: '',
            };
        case TYPES.FETCH_CONTAINER_TRACKING_REASON_CODES.PENDING:
            return {
                ...state,
                fetchReasonCodeIsLoading: true,
                fetchReasonCodeIsError: false
            };
        case TYPES.FETCH_CONTAINER_TRACKING_REASON_CODES.FULFILLED:
            return {
                ...state,
                fetchReasonCodeIsLoading: false,
                fetchReasonCodeIsError: false,
                containerTrackingReasonCodes: action.reasonCodes || defaultState.containerTrackingReasonCodes
            };
        case TYPES.FETCH_CONTAINER_TRACKING_REASON_CODES.REJECTED:
            return {
                ...state,
                fetchReasonCodeIsLoading: false,
                fetchReasonCodeIsError: true
            };
        case TYPES.UPDATE_CONTAINER_TRACKING_REASON_CODES.PENDING:
            return {
                ...state,
                updateContainerTrackingReasonCodeIsLoading: true,
                updateContainerTrackingReasonCodeError: '',
            }
        case TYPES.UPDATE_CONTAINER_TRACKING_REASON_CODES.FULFILLED:
            return {
                ...state,
                updateContainerTrackingReasonCodeIsLoading: false,
                updateContainerTrackingReasonCodeError: '',
            }
        case TYPES.UPDATE_CONTAINER_TRACKING_REASON_CODES.REJECTED:
            return {
                ...state,
                updateContainerTrackingReasonCodeIsLoading: false,
                updateContainerTrackingReasonCodeError: action.error || defaultState.updateContainerTrackingReasonCodeError,
            };
        case TYPES.UPDATE_CONTAINER_TRACKING_REASON_CODES.RESET_ERROR:
            return {
                ...state,
                updateContainerTrackingReasonCodeError: '',
            };
        case TYPES.DELETE_CONTAINER_TRACKING_REASON_CODES.PENDING:
            return {
                ...state,
                deleteContainerTrackingReasonCodeIsLoading: true,
                deleteContainerTrackingReasonCodeIsError: false,
            };
        case TYPES.DELETE_CONTAINER_TRACKING_REASON_CODES.FULFILLED:
            return {
                ...state,
                deleteContainerTrackingReasonCodeIsLoading: false,
                deleteContainerTrackingReasonCodeIsError: false,
            };
        case TYPES.DELETE_CONTAINER_TRACKING_REASON_CODES.REJECTED:
            return {
                ...state,
                deleteContainerTrackingReasonCodeIsLoading: false,
                deleteContainerTrackingReasonCodeIsError: true,
            };
        case TYPES.CREATE_CONTAINER_TRACKING_REASON_CODES.PENDING:
            return {
                ...state,
                createContainerTrackingReasonCodeIsLoading: true,
                createContainerTrackingReasonCodeError: '',
            };
        case TYPES.CREATE_CONTAINER_TRACKING_REASON_CODES.FULFILLED:
            return {
                ...state,
                createContainerTrackingReasonCodeIsLoading: false,
                createContainerTrackingReasonCodeError: '',
            };
        case TYPES.CREATE_CONTAINER_TRACKING_REASON_CODES.REJECTED:
            return {
                ...state,
                createContainerTrackingReasonCodeIsLoading: false,
                createContainerTrackingReasonCodeError: action.error || defaultState.createContainerTrackingReasonCodeError,
            };
        case TYPES.CREATE_CONTAINER_TRACKING_REASON_CODES.RESET_ERROR:
            return {
                ...state,
                createContainerTrackingReasonCodeError: '',
            };
        case TYPES.FETCH_CUSTOMS_REASON_CODES.PENDING:
            return {
                ...state,
                fetchReasonCodeIsLoading: true,
                fetchReasonCodeIsError: false
            };
        case TYPES.FETCH_CUSTOMS_REASON_CODES.FULFILLED:
            return {
                ...state,
                fetchReasonCodeIsLoading: false,
                fetchReasonCodeIsError: false,
                customsReasonCodes: action.reasonCodes || defaultState.customsReasonCodes
            };
        case TYPES.FETCH_CUSTOMS_REASON_CODES.REJECTED:
            return {
                ...state,
                fetchReasonCodeIsLoading: false,
                fetchReasonCodeIsError: true
            };
        case TYPES.UPDATE_CUSTOMS_REASON_CODES.PENDING:
            return {
                ...state,
                updateCustomsReasonCodeIsLoading: true,
                updateCustomsReasonCodeError: '',
            }
        case TYPES.UPDATE_CUSTOMS_REASON_CODES.FULFILLED:
            return {
                ...state,
                updateCustomsReasonCodeIsLoading: false,
                updateCustomsReasonCodeError: '',
            }
        case TYPES.UPDATE_CUSTOMS_REASON_CODES.REJECTED:
            return {
                ...state,
                updateCustomsReasonCodeIsLoading: false,
                updateCustomsReasonCodeError: action.error || defaultState.updateCustomsReasonCodeError,
            };
        case TYPES.UPDATE_CUSTOMS_REASON_CODES.RESET_ERROR:
            return {
                ...state,
                updateCustomsReasonCodeError: '',
            };
        case TYPES.DELETE_CUSTOMS_REASON_CODES.PENDING:
            return {
                ...state,
                deleteCustomsReasonCodeIsLoading: true,
                deleteCustomsReasonCodeIsError: false,
            };
        case TYPES.DELETE_CUSTOMS_REASON_CODES.FULFILLED:
            return {
                ...state,
                deleteCustomsReasonCodeIsLoading: false,
                deleteCustomsReasonCodeIsError: false,
            };
        case TYPES.DELETE_CUSTOMS_REASON_CODES.REJECTED:
            return {
                ...state,
                deleteCustomsReasonCodeIsLoading: false,
                deleteCustomsReasonCodeIsError: true,
            };
        case TYPES.CREATE_CUSTOMS_REASON_CODES.PENDING:
            return {
                ...state,
                createCustomsReasonCodeIsLoading: true,
                createCustomsReasonCodeError: '',
            };
        case TYPES.CREATE_CUSTOMS_REASON_CODES.FULFILLED:
            return {
                ...state,
                createCustomsReasonCodeIsLoading: false,
                createCustomsReasonCodeError: '',
            };
        case TYPES.CREATE_CUSTOMS_REASON_CODES.REJECTED:
            return {
                ...state,
                createCustomsReasonCodeIsLoading: false,
                createCustomsReasonCodeError: action.error || defaultState.createCustomsReasonCodeError,
            };
        case TYPES.CREATE_CUSTOMS_REASON_CODES.RESET_ERROR:
            return {
                ...state,
                createCustomsReasonCodeError: '',
            };
        case TYPES.GET_DELIVERY_PLANNING_CUSTOMERS.PENDING:
            return {
                ...state,
                isGetDeliveryPlanningCustomerPending: true,
                isGetDeliveryPlanningCustomerError: false,
            };
        case TYPES.GET_DELIVERY_PLANNING_CUSTOMERS.FULFILLED:
            return {
                ...state,
                isGetDeliveryPlanningCustomerPending: false,
                isGetDeliveryPlanningCustomerError: false,
                customerConfigurations: action.customerConfigurations as ICustomerConfigurationsList,
            };
        case TYPES.GET_DELIVERY_PLANNING_CUSTOMERS.REJECTED:
            return {
                ...state,
                isGetDeliveryPlanningCustomerPending: false,
                isGetDeliveryPlanningCustomerError: true,
            };
        case TYPES.CREATE_DELIVERY_PLANNING_CUSTOMER.PENDING:
            return {
                ...state,
                isCreateDeliveryPlanningCustomerPending: true,
                isCreateDeliveryPlanningCustomerError: false,
            };
        case TYPES.CREATE_DELIVERY_PLANNING_CUSTOMER.FULFILLED:
            return {
                ...state,
                isCreateDeliveryPlanningCustomerPending: false,
                isCreateDeliveryPlanningCustomerError: false,
            };
        case TYPES.CREATE_DELIVERY_PLANNING_CUSTOMER.REJECTED:
            return {
                ...state,
                isCreateDeliveryPlanningCustomerPending: false,
                isCreateDeliveryPlanningCustomerError: true,
            };
        case TYPES.EDIT_DELIVERY_PLANNING_CUSTOMER.PENDING:
            return {
                ...state,
                isEditDeliveryPlanningCustomerPending: true,
                isEditDeliveryPlanningCustomerError: false,
            };
        case TYPES.EDIT_DELIVERY_PLANNING_CUSTOMER.FULFILLED:
            return {
                ...state,
                isEditDeliveryPlanningCustomerPending: false,
                isEditDeliveryPlanningCustomerError: false,
            };
        case TYPES.EDIT_DELIVERY_PLANNING_CUSTOMER.REJECTED:
            return {
                ...state,
                isEditDeliveryPlanningCustomerPending: false,
                isEditDeliveryPlanningCustomerError: true,
            };
        case TYPES.DELETE_DELIVERY_PLANNING_CUSTOMER.PENDING:
            return {
                ...state,
                isDeleteDeliveryPlanningCustomerPending: true,
                isDeleteDeliveryPlanningCustomerError: false,
            };
        case TYPES.DELETE_DELIVERY_PLANNING_CUSTOMER.FULFILLED:
            return {
                ...state,
                isDeleteDeliveryPlanningCustomerPending: false,
                isDeleteDeliveryPlanningCustomerError: false,
            };
        case TYPES.DELETE_DELIVERY_PLANNING_CUSTOMER.REJECTED:
            return {
                ...state,
                isDeleteDeliveryPlanningCustomerPending: false,
                isDeleteDeliveryPlanningCustomerError: true,
            };

        case TYPES.CREATE_CUSTOMS_BROKERS_SETTINGS.PENDING:
            return {
                ...state,
                createCustomsBrokerSettingsPending: true,
            };
        case TYPES.CREATE_CUSTOMS_BROKERS_SETTINGS.FULFILLED:
            return {
                ...state,
                createCustomsBrokerSettingsPending: false,
            };
        case TYPES.FETCH_CUSTOMS_BROKER_SETTINGS.PENDING:
            return {
                ...state,
                fetchCustomsBrokerSettingsPending: true,
            };
        case TYPES.FETCH_CUSTOMS_BROKER_SETTINGS.FULFILLED:
            return {
                ...state,
                customsBrokerSettings: action.settings || defaultState.customsBrokerSettings,
                fetchCustomsBrokerSettingsPending: false,
            };
        case TYPES.FETCH_CUSTOMS_BROKER_SETTINGS.REJECTED:
            return {
                ...state,
                fetchCustomsBrokerSettingsPending: false,
            };
        case TYPES.UPDATE_CUSTOMS_BROKER_SETTINGS.PENDING:
            return {
                ...state,
                updateCustomsBrokerSettingsPending: true,
            };
        case TYPES.UPDATE_CUSTOMS_BROKER_SETTINGS.FULFILLED:
            return {
                ...state,
                updateCustomsBrokerSettingsPending: false,
            };
        case TYPES.DELETE_CUSTOMS_BROKERS_SETTINGS.PENDING:
            return {
                ...state,
                deleteCustomsBrokerSettingsPending: true,
            };
        case TYPES.DELETE_CUSTOMS_BROKERS_SETTINGS.FULFILLED:
            return {
                ...state,
                deleteCustomsBrokerSettingsPending: false,
            };
        case TYPES.FETCH_CONTAINER_TRACKING_CARRIER_SETTINGS.PENDING:
            return {
                ...state,
                containerTrackingCarriersPending: true,
                containerTrackingCarriersError: '',
            };
        case TYPES.FETCH_CONTAINER_TRACKING_CARRIER_SETTINGS.FULFILLED:
            return {
                ...state,
                containerTrackingCarriersPending: false,
                containerTrackingCarriersError: '',
                containerTrackingCarriers: action.carriers || defaultState.containerTrackingCarriers,
            };
        case TYPES.FETCH_CONTAINER_TRACKING_CARRIER_SETTINGS.REJECTED:
            return {
                ...state,
                containerTrackingCarriersPending: false,
                containerTrackingCarriersError: action.error || defaultState.containerTrackingCarriersError,
                containerTrackingCarriers: defaultState.containerTrackingCarriers,
            };
        case TYPES.UPDATE_CONTAINER_TRACKING_CARRIER_SETTINGS.PENDING:
        case TYPES.ADD_CONTAINER_TRACKING_CARRIER_SETTINGS.PENDING:
        case TYPES.DELETE_CONTAINER_TRACKING_CARRIER_SETTINGS.PENDING:
            return {
                ...state,
                containerTrackingCarriersError: '',
                containerTrackingCarriersPending: true,
            };
        case TYPES.UPDATE_CONTAINER_TRACKING_CARRIER_SETTINGS.FULFILLED:
        case TYPES.ADD_CONTAINER_TRACKING_CARRIER_SETTINGS.FULFILLED:
        case TYPES.DELETE_CONTAINER_TRACKING_CARRIER_SETTINGS.FULFILLED:
            return {
                ...state,
                containerTrackingCarriersError: '',
                containerTrackingCarriersPending: false,
            };
        case TYPES.UPDATE_CONTAINER_TRACKING_CARRIER_SETTINGS.REJECTED:
        case TYPES.ADD_CONTAINER_TRACKING_CARRIER_SETTINGS.REJECTED:
        case TYPES.DELETE_CONTAINER_TRACKING_CARRIER_SETTINGS.REJECTED:
            return {
                ...state,
                containerTrackingCarriersError: action.error || defaultState.containerTrackingCarriersError,
                containerTrackingCarriersPending: false,
            };
        case TYPES.UPDATE_CONTAINER_TRACKING_CARRIER_SETTINGS.RESET_ERROR:
            return {
                ...state,
                containerTrackingCarriersError: '',
            };
        case TYPES.FETCH_FACT_OFFICE_PREFIXES:
            return {
                ...state,
                factOfficePrefixes: action.officePrefixes || defaultState.factOfficePrefixes,
            };
        case TYPES.FETCH_DEMURRAGE_AND_DETENTION_REASON_CODES.PENDING:
            return {
                ...state,
                fetchReasonCodeIsLoading: true,
                fetchReasonCodeIsError: false
            }
        case TYPES.FETCH_DEMURRAGE_AND_DETENTION_REASON_CODES.FULFILLED:
            return {
                ...state,
                fetchReasonCodeIsLoading: false,
                fetchReasonCodeIsError: false,
                dndReasonCodes: action.reasonCodes || defaultState.dndReasonCodes
            }
        case TYPES.FETCH_DEMURRAGE_AND_DETENTION_REASON_CODES.REJECTED:
            return {
                ...state,
                fetchReasonCodeIsLoading: false,
                fetchReasonCodeIsError: true
            }
        case TYPES.FETCH_GOH_TRANSLATIONS.FULFILLED:
            return {
                ...state,
                GOHTranslations: action.GOHTranslations || defaultState.GOHTranslations
            }
        default:
            return state;
    }
};
