import React from 'react';
import { ColumnFactory } from 'components/data-table/ColumnFactory';
import {Accordion} from 'components/accordion';
import { DataTable } from 'components/data-table/DamcoTable';
import { cloneDataWithDisplayNames } from 'utilities/tables';

export const PoTable = (props: { data: any[] }) => {
    const data = cloneDataWithDisplayNames(props.data).map((po: any) => ({
        ...po,
        children: po.sku
    }));

    return(
        <Accordion text="POs">
            <DataTable
                data={data}
                withCheckboxes={false}
                expandable={true}
                columns={[
                    ColumnFactory.standardColumn('poNumberDisplayName', 'PO')
                ]}
                subColumns={[
                    ColumnFactory.standardColumn('descriptionDisplayName', 'Description'),
                    ColumnFactory.standardColumn('packagesDisplayName', 'Packages'),
                    ColumnFactory.standardColumn('pieceTypeDisplayName', 'Piece type'),
                    ColumnFactory.standardColumn('termsDisplayName', 'Terms'),
                ]}
                tableName="CustomsEquipmentDetailsPOTable"
                reference={null as any}
                showPagination={true}
            />
        </Accordion>
    );
}
