import React from 'react';
import { ColumnFactory } from 'components/data-table/ColumnFactory';
import { DataTable } from 'components/data-table/DamcoTable';
import { ICblBooking } from 'reducers/fact/models';
import { StatusRow } from 'components/status-row';
import { cloneDataWithDisplayNames } from 'utilities/tables';

interface IBookingsTableProps {
    bookings: ICblBooking[];
}

export const BookingsTable: React.StatelessComponent<IBookingsTableProps> = (props) => {
    const data = cloneDataWithDisplayNames(props.bookings, 'DD-MM-YYYY, h:mm');

    return(
        <StatusRow title="Bookings">
            <DataTable
                data={data}
                columns={[
                    ColumnFactory.standardColumn('numberDisplayName', 'Booking number'),
                    ColumnFactory.standardColumn('cargoTypeDisplayName', 'Cargo type'),
                    ColumnFactory.standardColumn('placeOfDeliveryNameDisplayName', 'Place of delivery'),
                    ColumnFactory.standardColumn('destinationSiteNameDisplayName', 'Destination site'),
                    ColumnFactory.standardColumn('ultimatePlaceOfDeliveryNameDisplayName', 'Ultimate place of delivery'),
                    ColumnFactory.standardColumn('paymentTypeDisplayName', 'Payment type'),
                    ColumnFactory.standardColumn('originSiteNameDisplayName', 'Origin site'),
                    ColumnFactory.standardColumn('internalShipmentNumber', 'Internal shipment number'),
                    ColumnFactory.standardColumn('consigneeNameDisplayName', 'Consignee name'),
                    ColumnFactory.standardColumn('consigneeBusinessEntityCodeDisplayName', 'Consignee business entity code'),
                    ColumnFactory.standardColumn('consigneeCountryCodeDisplayName', 'Consignee country code'),
                    ColumnFactory.standardColumn('consigneeFactCodeDisplayName', 'Consignee FACT code'),
                    ColumnFactory.standardColumn('consigneeSCVCodeDisplayName', 'Consignee SCV code'),
                ]}
                withCheckboxes={false}
                reference={null as any}
                tableName="CblDetailsBookingsTable"
                showPagination={true}
                expandable={false}
            />
        </StatusRow>
    );
};

