import React from 'react';
import { ColumnFactory } from 'components/data-table/ColumnFactory';
import { DataTable } from 'components/data-table/DamcoTable';
import { IETSCbl } from 'reducers/fact/models';
import { StatusRow } from 'components/status-row';
import { cloneDataWithDisplayNames } from 'utilities/tables';

interface ICblTableProps {
    cbls: IETSCbl[];
}

export const CblTable: React.StatelessComponent<ICblTableProps> = (props) => {
    const data = cloneDataWithDisplayNames(props.cbls, 'DD-MM-YYYY, h:mm').map(cbl => ({
        ...cbl,
        cblNumberDisplayName: [cbl.cblNumber, `/exception-management/fact/cbls/${cbl.cblId}`]
    }));

    return(
        <StatusRow title="Cbls">
            <DataTable
                data={data}
                columns={[
                    ColumnFactory.linkedColumn('cblNumberDisplayName', 'CBL number'),
                    ColumnFactory.standardColumn('carrierBookingReferenceDisplayName', 'Carrier booking reference'),
                    ColumnFactory.standardColumn('carrierCodeDisplayName', 'Carrier code'),
                    ColumnFactory.standardColumn('createDateDisplayName', 'Create date'),
                    ColumnFactory.standardColumn('updateDateDisplayName', 'Update date'),
                    ColumnFactory.standardColumn('transportContractNumberDisplayName', 'Transport contract number'),
                    ColumnFactory.standardColumn('etsCblOnETVolumeFactor', 'ETS CBL on ET volume factor'),
                    ColumnFactory.standardColumn('etsOnCblVolumeFactor', 'ETS on CBL volume factor'),
                ]}
                withCheckboxes={false}
                reference={null as any}
                tableName="ETSDetailsCblsTable"
                showPagination={true}
                expandable={false}
            />
        </StatusRow>
    );
};

