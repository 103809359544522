import { TYPES } from 'action-types/fact';
import { 
    IDocuments, 
    IDocumentDetails, 
    IEquipmentDetails, 
    ICblDetails, 
    IETSDetails, 
    IEquipments, 
    IEssoList, 
    IIssoList,
    ICblList,
    IShipmentDetails,
    IASNMessage
} from './models';

interface IAction {
    readonly type: string;
    readonly documents: IDocuments;
    readonly documentDetails: IDocumentDetails;
    readonly errors: any[];
    readonly equipmentDetails: IEquipmentDetails;
    readonly cblDetails: ICblDetails;
    readonly etsDetails: IETSDetails;
    readonly equipmentList: IEquipments;
    readonly essoList: IEssoList;
    readonly issoList: IIssoList;
    readonly cblList: ICblList;
    readonly shipmentDetails: IShipmentDetails;
    readonly ASNMessages: IASNMessage[];
}
export interface IFactState {
    readonly isFetchFactDataLoading: boolean;
    readonly isFetchFactDataError: boolean;
    readonly isFetchASNMessagesLoading: boolean;
    readonly isFetchASNMessagesError: boolean;
    readonly documents: IDocuments;
    readonly documentDetails: IDocumentDetails;
    readonly documentDetailsPendingError: string | string[];
    readonly equipmentDetails: IEquipmentDetails;
    readonly equipmentDetailsPendingError: string | string[];
    readonly cblDetails: ICblDetails;
    readonly isCblDetailsLoading: boolean;
    readonly cblDetailsPendingError: string | string[];
    readonly etsDetails: IETSDetails;
    readonly etsDetailsPendingError: string | string[];
    readonly equipmentList: IEquipments;
    readonly equipmentListPendingError: string | string[];
    readonly isEquipmentListLoading: boolean;
    readonly exportFactFileError: string | string[];
    readonly isExportFactFileLoading: boolean;
    readonly essoList: IEssoList;
    readonly isEssoListLoading: boolean;
    readonly essoListError: string | string[];
    readonly issoList: IIssoList;
    readonly isIssoListLoading: boolean;
    readonly issoListError: string | string[];
    readonly cblList: ICblList;
    readonly isCblListLoading: boolean;
    readonly cblListError: string | string[];
    readonly shipmentDetails: IShipmentDetails;
    readonly shipmentDetailsPendingError: string | string[];
    readonly isShipmentDetailsLoading: boolean;
    readonly ASNMessages: IASNMessage[];
    readonly dqIssuesReportErrors: any[];
}

const defaultState: IFactState = {
    isFetchFactDataLoading: false,
    isFetchFactDataError: false,
    isFetchASNMessagesLoading: false,
    isFetchASNMessagesError: false,
    documents: {
        pageNumber: 1,
        pageSize: 0,
        result: [],
        totalCount: 0
    },
    documentDetails: {
        documentId: 0,
        documentNumber: '',
        originalDocumentNumber: '',
        type: '',
        issuerCountryCode: '',
        issuerBECode: '',
        issuerName: '',
        issuerFactCode: '',
        issuerSCVCode: '',
        issuerFunctionCode: '',
        consigneeCountryCode: '',
        consigneeBusinessEntityCode: '',
        consigneeName: '',
        consigneeFactCode: '',
        consigneeSCVCode: '',
        invoiceeCountryCode: '',
        invoiceeBusinessEntityCode: '',
        invoiceeName: '',
        invoiceeFactCode: '',
        invoiceeSCVCode: '',
        shipperCountryCode: '',
        shipperBusinessEntityCode: '',
        shipperName: '',
        shipperFACTCode: '',
        shipperSCVCode: '',
        placeOfReceiptCode: '',
        placeOfReceiptName: '',
        placeOfDeliveryCode: '',
        placeOfDeliveryName: '',
        portOfLoadingCode: '',
        portOfLoadingName: '',
        portOfDischargeCode: '',
        portOfDischargeName: '',
        prepaidCollectIndicator: '',
        estimatedDeliveryTime: '',
        goodsReceiptDate: '',
        serviceAtOrigin: '',
        serviceAtDestination: '',
        approverUserCompanyWideCode: '',
        changeUserCompanyWideCode: '',
        changeReason: '',
        createDate: '',
        lastUpdateDate: '',
        isCancelled: false,
        asns: [],
        equipments: [],
        essos: [],
        equipmentTransportShipments: [],
        cbls: [],
        bookings: [],
        voyages: [],
        purchaseOrderNumbers: []
    },
    documentDetailsPendingError: '',
    equipmentDetails: {
        actualDeliveryDateTime: '',
        cbls: [],
        createDate: '',
        documents: [],
        equipmentId: 0,
        equipmentNumber: '',
        equipmentTransportShipments: [],
        isDangerousGoods: false,
        isGOH: false,
        isHotBox: false,
        lastUpdatedBy: '',
        lastUpdatedByDocumentId: 0,
        quantity: 0,
        serviceAtDestination: '',
        serviceAtOrigin: '',
        shipmentTypeAtDestination: '',
        shipmentTypeAtOrigin: '',
        size: '',
        substitutionSize: '',
        substitutionType: '',
        totalPackages: 0,
        transportIdentification: '',
        type: '',
        updateDate: '',
        volume: 0,
        voyages: [],
        weight: 0
    },
    equipmentDetailsPendingError: '',
    cblDetails: {
        bookings: [],
        carrierBookingReference: '',
        carrierBusinessEntityCode: '',
        carrierCode: '',
        carrierConutryCode: '',
        carrierFACTCode: '',
        carrierName: '',
        cblId: 0,
        consigneeCountryCode: '',
        consigneeFACTCode: '',
        consigneeName: '',
        consigneeSCVCode: '',
        createDate: '',
        documents: [],
        equipmentTransportShipments: [],
        equipments: [],
        finalPortOfDischargeCode: '',
        finalPortOfDischargeName: '',
        internalNumber: '',
        lastUpdatedBy: '',
        lastUpdatedByDocumentId: 0,
        number: '',
        originalPortOfLoadingCode: '',
        originalPortOfLoadingName: '',
        placeOfDeliveryCode: '',
        placeOfDeliveryName: '',
        shipments: [],
        shipperBusinessEntityCode: '',
        shipperContryCode: '',
        shipperFACTCode: '',
        shipperName: '',
        shipperSCVCode: '',
        transportContractNumber: '',
        updateDate: '',
        voyages: [],
    },
    cblDetailsPendingError: '',
    isCblDetailsLoading: false,
    etsDetails: {
        cbls: [],
        createDate: '',
        documentNumber: '',
        documents: [],
        equipmentTransport: {
            createDate: '',
            equipmentNumber: '',
            equipmentTransportId: 0,
            etsOnEtVolumeFactor: 0,
            packages: 0,
            quantity: 0,
            sealNumber: '',
            size: '',
            substitutionSize: null,
            substitutionType: null,
            updateDate: '',
            volume: 0,
            weight: 0
        },
        equipmentTransportShipmentId: 0,
        eta: '',
        etd: '',
        etsOnEtVolumeFactor: 0,
        isDeleted: false,
        issos: [],
        packages: 0,
        quantity: 0,
        shipment: {
            bookingAgent: null,
            bookings: [],
            cargoType: '',
            consigneeBusinessEntityCode: '',
            consigneeCountryCode: '',
            consigneeName: '',
            cosigneeFACTCode: '',
            documentNumber: '',
            placeOfConsolidationCode: '',
            placeOfConsolidationName: '',
            placeOfDeconsolidationCode: '',
            placeOfDeconsolidationName: '',
            placeOfDeliveryCode: '',
            placeOfDeliveryName: '',
            prepaidCollectIndicator: '',
            reconsolidationPoint: null,
            shipmentId: 0
        },
        updateDate: '',
        volume: 0,
        voyages: [],
        weight: 0,
    },
    etsDetailsPendingError: '',
    equipmentList: {
        pageNumber: 0,
        pageSize: 0,
        result: [],
        totalCount: 0
    },
    equipmentListPendingError: '',
    isEquipmentListLoading: false,
    exportFactFileError: '',
    isExportFactFileLoading: false,
    essoList: {
        result: [],
        pageNumber: 0,
        pageSize: 0,
        totalCount: 0
    },
    essoListError: '',
    isEssoListLoading: false,
    issoList: {
        result: [],
        pageNumber: 0,
        pageSize: 0,
        totalCount: 0
    },
    issoListError: '',
    isIssoListLoading: false,
    cblList: {
        result: [],
        pageNumber: 0,
        pageSize: 0,
        totalCount: 0
    },
    isCblListLoading: false,
    cblListError: '',
    shipmentDetails: {
        bookings: [],
        cargoType: '',
        cbls: [],
        consigneeBusinessEntityCode: '',
        consigneeCountryCode: '',
        consigneeFACTCode: '',
        consigneeName: '',
        documentNumber: '',
        documents: [],
        equipmentTransportShipments: [],
        placeOfConsolidationCode: '',
        placeOfConsolidationName: '',
        placeOfDeconsolidationCode: '',
        placeOfDeconsolidationName: '',
        placeOfDeliveryCode: '',
        placeOfDeliveryName: '',
        prepaidCollectIndicator: '',
        reconsolidationPoint: '',
        shipmentId: 0
    },
    shipmentDetailsPendingError: '',
    isShipmentDetailsLoading: false,
    ASNMessages: [],
    dqIssuesReportErrors: []
};

export const reducer = (state: IFactState = defaultState, action: IAction): IFactState => {
    switch (action.type) {
        case TYPES.FETCH_FACT_DATA.PENDING:
            return {
                ...state,
                isFetchFactDataLoading: true,
                isFetchFactDataError: false
            };
        case TYPES.FETCH_FACT_DATA.FULFILLED:
            return {
                ...state,
                documents: action.documents,
                isFetchFactDataLoading: false,
                isFetchFactDataError: false
            };
        case TYPES.FETCH_FACT_DATA.REJECTED:
            return {
                ...state,
                isFetchFactDataLoading: false,
                isFetchFactDataError: true
            };
        case TYPES.FETCH_DOCUMENT_DETAILS.PENDING:
            return {
                ...state,
                documentDetailsPendingError: ''
            };
        case TYPES.FETCH_DOCUMENT_DETAILS.FULFILLED:
            return {
                ...state,
                documentDetails: action.documentDetails || defaultState.documentDetails
            };
            case TYPES.FETCH_DOCUMENT_DETAILS.REJECTED:
            return {
                ...state,
                documentDetailsPendingError: action.errors
            };
        case TYPES.FETCH_DOCUMENT_DETAILS.RESET_ERROR:
            return {
                ...state,
                documentDetailsPendingError: ''
            };
        case TYPES.FETCH_EQUIPMENT_DETAILS.PENDING:
            return {
                ...state,
                equipmentDetailsPendingError: ''
            };
        case TYPES.FETCH_EQUIPMENT_DETAILS.FULFILLED:
            return {
                ...state,
                equipmentDetails: action.equipmentDetails || defaultState.equipmentDetails
            };
        case TYPES.FETCH_EQUIPMENT_DETAILS.REJECTED:
            return {
                ...state,
                equipmentDetailsPendingError: action.errors
            };
        case TYPES.FETCH_EQUIPMENT_DETAILS.RESET_ERROR:
            return {
                ...state,
                cblDetailsPendingError: ''
            };
        case TYPES.FETCH_CBL_DETAILS.PENDING:
            return {
                ...state,
                cblDetailsPendingError: '',
                isCblDetailsLoading: true
            };
        case TYPES.FETCH_CBL_DETAILS.FULFILLED:
            return {
                ...state,
                cblDetails: action.cblDetails || defaultState.cblDetails,
                isCblDetailsLoading: false
            };
        case TYPES.FETCH_CBL_DETAILS.REJECTED:
            return {
                ...state,
                cblDetailsPendingError: action.errors,
                isCblDetailsLoading: false
            };
        case TYPES.FETCH_CBL_DETAILS.RESET_ERROR:
            return {
                ...state,
                cblDetailsPendingError: ''
            };
        case TYPES.FETCH_EQUIPMENT_TRANSPORT_SHIPPMENT_DETAILS.PENDING:
            return {
                ...state,
                etsDetailsPendingError: ''
            };
        case TYPES.FETCH_EQUIPMENT_TRANSPORT_SHIPPMENT_DETAILS.FULFILLED:
            return {
                ...state,
                etsDetails: action.etsDetails || defaultState.etsDetails
            };
        case TYPES.FETCH_EQUIPMENT_TRANSPORT_SHIPPMENT_DETAILS.REJECTED:
            return {
                ...state,
                etsDetailsPendingError: action.errors
            };
        case TYPES.FETCH_EQUIPMENT_TRANSPORT_SHIPPMENT_DETAILS.RESET_ERROR:
            return {
                ...state,
                etsDetailsPendingError: ''
            };
        case TYPES.FETCH_EQUIPMENTS_LIST.PENDING:
            return {
                ...state,
                equipmentDetailsPendingError: '',
                isEquipmentListLoading: true
            };
        case TYPES.FETCH_EQUIPMENTS_LIST.FULFILLED:
            return {
                ...state,
                equipmentList: {
                    pageNumber: action.equipmentList.pageNumber || defaultState.equipmentList.pageNumber,
                    pageSize: action.equipmentList.pageSize || defaultState.equipmentList.pageSize,
                    result: action.equipmentList.result || defaultState.equipmentList.result,
                    totalCount: action.equipmentList.totalCount || defaultState.equipmentList.totalCount,
                },
                isEquipmentListLoading: false
            };
        case TYPES.FETCH_EQUIPMENTS_LIST.REJECTED:
            return {
                ...state,
                equipmentDetailsPendingError: action.errors,
                isEquipmentListLoading: false
            };
        case TYPES.FETCH_EQUIPMENTS_LIST.RESET_ERROR:
            return {
                ...state,
                equipmentDetailsPendingError: ''
            };
        case TYPES.GET_FILE.PENDING:
            return {
                ...state,
                exportFactFileError: '',
                isExportFactFileLoading: true
            };
        case TYPES.GET_FILE.FULFILLED:
            return {
                ...state,
                exportFactFileError: '',
                isExportFactFileLoading: false
            };
        case TYPES.GET_FILE.REJECTED:
            return {
                ...state,
                exportFactFileError: '',
                isExportFactFileLoading: false
            };
        case TYPES.GET_FILE.RESET_ERROR:
            return {
                ...state,
                exportFactFileError: action.errors
            };
        case TYPES.FETCH_ESSO_LIST.PENDING:
            return {
                ...state,
                essoListError: '',
                isEssoListLoading: true
            };
        case TYPES.FETCH_ESSO_LIST.FULFILLED:
            return {
                ...state,
                essoList: {
                    pageNumber: action.essoList.pageNumber || defaultState.essoList.pageNumber,
                    pageSize: action.essoList.pageSize || defaultState.essoList.pageSize,
                    result: action.essoList.result || defaultState.essoList.result,
                    totalCount: action.essoList.totalCount || defaultState.essoList.totalCount,
                },
                isEssoListLoading: false
            };
        case TYPES.FETCH_ESSO_LIST.REJECTED:
            return {
                ...state,
                essoListError: action.errors,
                isEssoListLoading: false
            };
        case TYPES.FETCH_ESSO_LIST.RESET_ERROR:
            return {
                ...state,
                issoListError: ''
            };
        case TYPES.FETCH_ISSO_LIST.PENDING:
            return {
                ...state,
                issoListError: '',
                isIssoListLoading: true
            };
        case TYPES.FETCH_ISSO_LIST.FULFILLED:
            return {
                ...state,
                issoList: {
                    pageNumber: action.issoList.pageNumber || defaultState.issoList.pageNumber,
                    pageSize: action.issoList.pageSize || defaultState.issoList.pageSize,
                    result: action.issoList.result || defaultState.issoList.result,
                    totalCount: action.issoList.totalCount || defaultState.issoList.totalCount,
                },
                isIssoListLoading: false
            };
        case TYPES.FETCH_ISSO_LIST.REJECTED:
            return {
                ...state,
                issoListError: action.errors,
                isIssoListLoading: false
            };
        case TYPES.FETCH_ISSO_LIST.RESET_ERROR:
            return {
                ...state,
                issoListError: ''
            };
        case TYPES.FETCH_CBLS_LIST.PENDING:
            return {
                ...state,
                cblListError: '',
                isCblListLoading: true
            };
        case TYPES.FETCH_CBLS_LIST.FULFILLED:
            return {
                ...state,
                cblList: {
                    pageNumber: action.cblList.pageNumber || defaultState.cblList.pageNumber,
                    pageSize: action.cblList.pageSize || defaultState.cblList.pageSize,
                    result: action.cblList.result || defaultState.cblList.result,
                    totalCount: action.cblList.totalCount || defaultState.cblList.totalCount,
                },
                isCblListLoading: false
            };
        case TYPES.FETCH_CBLS_LIST.REJECTED:
            return {
                ...state,
                cblListError: action.errors,
                isCblListLoading: false
            };
        case TYPES.FETCH_CBLS_LIST.RESET_ERROR:
            return {
                ...state,
                cblListError: ''
            };
        case TYPES.FETCH_SHIPMENT_DETAILS.PENDING:
            return {
                ...state,
                shipmentDetailsPendingError: '',
                isShipmentDetailsLoading: true
            };
        case TYPES.FETCH_SHIPMENT_DETAILS.FULFILLED:
            return {
                ...state,
                shipmentDetails: action.shipmentDetails || defaultState.shipmentDetails,
                isShipmentDetailsLoading: false
            };
        case TYPES.FETCH_SHIPMENT_DETAILS.REJECTED:
            return {
                ...state,
                shipmentDetailsPendingError: action.errors,
                isShipmentDetailsLoading: false
            };
        case TYPES.FETCH_SHIPMENT_DETAILS.RESET_ERROR:
            return {
                ...state,
                shipmentDetailsPendingError: ''
            };
        case TYPES.FETCH_ASN_MESSAGES.PENDING:
            return {
                ...state,
                isFetchASNMessagesLoading: true,
                isFetchASNMessagesError: false
            };
        case TYPES.FETCH_ASN_MESSAGES.FULFILLED:
            return {
                ...state,
                ASNMessages: action.ASNMessages,
                isFetchASNMessagesLoading: false,
                isFetchASNMessagesError: false
            };
        case TYPES.FETCH_ASN_MESSAGES.REJECTED:
            return {
                ...state,
                isFetchASNMessagesLoading: false,
                isFetchASNMessagesError: true
            };
        case TYPES.GENERATE_DQ_ISSUES_REPORT.PENDING:
            return {
                ...state,
                dqIssuesReportErrors: []
            }
        case TYPES.GENERATE_DQ_ISSUES_REPORT.REJECTED:
            return {
                ...state,
                dqIssuesReportErrors: action.errors || defaultState.dqIssuesReportErrors
            }
        default:
            return state;
    }
};
