import React from 'react';
import { ColumnFactory } from 'components/data-table/ColumnFactory';
import { Transport_Mode, Transport_Type } from 'constants/transport';
import { connect } from 'react-redux';
import { ThunkDispatch } from 'redux-thunk';
import { Action } from 'redux';
import { getEquipmentDeliveries } from 'actions/planning';
import { IStore } from 'reducers/index';
import { IEquipmentDelivery } from 'reducers/planning/models';
import { LoadingAnimation } from 'components/loading';
import { fetchDeliveryPlanningHaulierSettings } from 'actions/settings';
import { ISettingsModel } from 'reducers/settings/models';
import { hauliersToEnum } from 'utilities/util';
import { DataTable } from 'components/data-table/DamcoTable';
import { cloneDataWithDisplayNames } from 'utilities/tables';

interface IMapStateToProps {
    isGetEquipmentDeliveriesPending: boolean;
    equipmentDeliveries: IEquipmentDelivery[];
    deliveryPlanningHaulierSettings: ISettingsModel[];
    fetchDeliveryPlanningHaulierSettingsPending: boolean;
}

interface IDispatch {
    getEquipmentDeliveries: (id: number) => Promise<void>;
    fetchDeliveryPlanningHaulierSettings: () => void;
}

interface IAirDeliveryTable extends IDispatch, IMapStateToProps {
    equipmentId: number;
}

class _AirDeliveryTable extends React.Component<IAirDeliveryTable> {
    public componentDidMount() {
        this.props.getEquipmentDeliveries(this.props.equipmentId);
        this.props.fetchDeliveryPlanningHaulierSettings();
    }

    public render() {
        const hauliers = hauliersToEnum(this.props.deliveryPlanningHaulierSettings);
        const data = cloneDataWithDisplayNames(this.props.equipmentDeliveries).map((obj: any) => ({
            ...obj,
            transportModeDisplayName: Transport_Mode[obj.transportMode],
            transportTypeDisplayName: Transport_Type[obj.transportType],
            haulierDisplayName: hauliers[obj.haulier],
        }));

        return (
            <React.Fragment>
                {(this.props.isGetEquipmentDeliveriesPending || this.props.fetchDeliveryPlanningHaulierSettingsPending) && <LoadingAnimation />}
                <DataTable
                    data={data}
                    withCheckboxes={false}
                    expandable={false}
                    columns={[
                        ColumnFactory.standardColumn('transportModeDisplayName', 'Transport mode'),
                        ColumnFactory.standardColumn('transportTypeDisplayName', 'Transport type'),
                        ColumnFactory.standardColumn('haulierDisplayName', 'Haulier'),
                        ColumnFactory.standardColumn('isTransportOrderSentDisplayName', 'Sent'),
                        ColumnFactory.standardColumn('pickupLocationDisplayName', 'Pickup'),
                        ColumnFactory.standardColumn('estimatedPickupDateDisplayName', 'Planned Date/Time'),
                        ColumnFactory.standardColumn('pickupDateDisplayName', 'Date/Time'),
                        ColumnFactory.standardColumn('dropOffDateDisplayName', 'Drop-off'),
                        ColumnFactory.standardColumn('estimatedDropOffDateDisplayName', 'Planned Date/Time'),
                        ColumnFactory.standardColumn('deliveryDropOffDateDisplayName', 'Date/Time'),
                        ColumnFactory.standardColumn('deliveryReferenceDisplayName', 'Delivery Reference'),
                    ]}
                    reference={null as any}
                    tableName="AirDeliveryDetailsTable"
                    showPagination={true}
                />
            </React.Fragment>
        );
    }
}

const mapStateToProps = (state: IStore): IMapStateToProps => {
    const {
        equipmentDeliveries,
        isGetEquipmentDeliveriesPending,
    } = state.planning;
    const {
        deliveryPlanningHaulierSettings,
        fetchDeliveryPlanningHaulierSettingsPending,
    } = state.settings;
    return{
        equipmentDeliveries,
        isGetEquipmentDeliveriesPending,
        fetchDeliveryPlanningHaulierSettingsPending,
        deliveryPlanningHaulierSettings,
    };
};

const mapDispatchToProps = (dispatch: ThunkDispatch<any, any, Action>): IDispatch => ({
    getEquipmentDeliveries: (id: number) => dispatch(getEquipmentDeliveries(id, true)),
    fetchDeliveryPlanningHaulierSettings: () => dispatch(fetchDeliveryPlanningHaulierSettings(true)),
});

export const AirDeliveryTable = connect(mapStateToProps, mapDispatchToProps)(_AirDeliveryTable);
