import { combineReducers } from 'redux';
import { reducer as auth, IAuthState } from 'reducers/auth';
import { reducer as bill_release, IBillReleaseState } from 'reducers/bill-release';
import { IModalsState, reducer as modals } from 'reducers/modals';
import { ISettingsState, reducer as settings } from 'reducers/settings';
import { reducer as user, IUserState} from 'reducers/user';
import { IVesselsState, reducer as vessels } from 'reducers/vessels';
import { reducer as timeline, ITimelineState} from 'reducers/timeline';
import { reducer as form } from 'redux-form';
import { reducer as ui } from 'react-redux-ui-tools'
import { IFiltersState, reducer as filters } from 'reducers/filters';
import { IPlanningState, reducer as planning } from 'reducers/planning';
import { IDemurrageAndDetentionState, reducer as demurrageAndDetention } from 'reducers/demurrage-and-detention';
import { reducer as customs, ICustomsState } from 'reducers/customs';
import { reducer as fact, IFactState } from 'reducers/fact';
import { IUI } from './models';
import { reducer as vesselSchedule, IVesselsScheduleState } from './schedule';
import { reducer as exportReducer, IExportState } from './export';
import { reducer as api, IApiState } from './api';
import { reducer as eventLogs, IEventLogsState } from './event-logs';
import { reducer as changelog, IChangeLogState } from './changelog';
import { reducer as importReducer, IImportState } from './import';
import { ICustomerManagementState, reducer as customerManagementReducer } from './customer-management';

export interface IStore {
    readonly api: IApiState;
    readonly auth: IAuthState;
    readonly vessels: IVesselsState,
    readonly planning: IPlanningState;
    readonly filters: IFiltersState;
    readonly settings: ISettingsState;
    readonly user: IUserState;
    readonly timeline: ITimelineState;
    readonly bill_release: IBillReleaseState;
    readonly form: any;
    readonly modals: IModalsState;
    readonly customs: ICustomsState;
    readonly demurrageAndDetention: IDemurrageAndDetentionState;
    readonly fact: IFactState;
    readonly ui: IUI;
    readonly vesselSchedule: IVesselsScheduleState;
    readonly exportReducer: IExportState;
    readonly eventLogs: IEventLogsState;
    readonly changelog: IChangeLogState;
    readonly importReducer: IImportState;
    readonly customerManagementReducer: ICustomerManagementState;
}

export default combineReducers({
    api,
    auth,
    bill_release,
    form,
    modals,
    planning,
    settings,
    filters,
    timeline,
    user,
    vessels,
    customs,
    demurrageAndDetention,
    fact,
    vesselSchedule,
    exportReducer,
    ui,
    eventLogs,
    changelog,
    importReducer,
    customerManagementReducer
});
