import React from 'react';
import { Page } from 'components/page-container';
import { PageHeader } from 'components/page-header';
import { HBLFCRTopBar } from './components/HBLFCRTopBar';
import { ConsigneeTable } from './components/ConsigneeTable';
import { EquipmentTable } from './components/EquipmentTable';
import { PageWithTimeline } from 'components/page-with-timeline';
import { ClearanceTable } from 'modules/customs/ocean/components/ClearanceTable';
import { HoldsRow } from 'modules/details-pages/components/HoldsRow';
import { LoadingAnimation } from 'components/loading';
import { ThunkDispatch} from 'redux-thunk';
import { fetchHBLFCRDetails } from 'actions/customs';
import { Action } from 'redux';
import { RouteComponentProps } from 'react-router-dom';
import { connect } from 'react-redux';
import { IStore } from 'reducers/index';
import { IHBLFCRDetails } from 'reducers/customs/models';
import { HandleError } from 'components/handle-error';
import { VesselTable } from 'modules/details-pages/components/VesselTable';
import { CustomsTypes } from 'constants/comment';
import { ProgressIndicator } from 'components/progress-indicator';
import { IBubble } from 'reducers/vessels/models';
import { UserDetails } from 'components/user-details';
import { Capability } from 'components/timeline';

interface IHBLFCRDetailsProps extends IDispatch, RouteComponentProps<any>, IMapState {}

interface IDispatch {
    fetchHBLFCRDetails: (id: string, voyageId: string) => Promise<void>;
}

interface IMapState {
    hblFcrDetails: IHBLFCRDetails;
    isFetchHBLFCRDetailsLoading: boolean;
    isFetchHBLFCRDetailsError: boolean;
    isUpdateHBLDetailsError: boolean;
    bubbles: IBubble[];
}

class _HBLFCRDetails extends React.Component<IHBLFCRDetailsProps> {
    public componentDidMount() {
        const { id, voyageId } = this.props.match.params;
        this.props.fetchHBLFCRDetails(id, voyageId);
    }

    public render() {
        const { isUpdateHBLDetailsError, isFetchHBLFCRDetailsError } = this.props;

        return (
            <>
                { this.props.isFetchHBLFCRDetailsLoading && <LoadingAnimation /> }
                <PageHeader
                    backButton={true}
                    id="hbl-fcr-details"
                >
                    HBL/FCR: {this.props.hblFcrDetails.hblNumber}
                </PageHeader>
                <HandleError
                    isUpdateHBLDetailsError={isUpdateHBLDetailsError}
                    isFetchHBLFCRDetailsError={isFetchHBLFCRDetailsError}
                />
                <ProgressIndicator data={this.props.bubbles} />
                <HBLFCRTopBar
                    {...this.props.hblFcrDetails}
                    hblId={this.props.match.params.id}
                    voyageId={this.props.match.params.voyageId}
                    data={this.props.hblFcrDetails}
                />
                <Page>
                    <PageWithTimeline
                        objectId={this.props.match.params.id}
                        objectType={CustomsTypes.Hbl}
                        isAir={false}
                        capability={Capability.Customs}
                    >
                        <VesselTable
                            vessel={this.props.hblFcrDetails.vesselName}
                            voyage={this.props.hblFcrDetails.voyageReference}
                            pol={this.props.hblFcrDetails.pol}
                            pod={this.props.hblFcrDetails.pod}
                        />
                        <ClearanceTable {...this.props.hblFcrDetails} />
                        <HoldsRow data={this.props.hblFcrDetails.holds} />
                        <ConsigneeTable {...this.props.hblFcrDetails} />
                        <EquipmentTable {...this.props.hblFcrDetails} />
                        <UserDetails
                            user={this.props.hblFcrDetails.lastUserUpdated}
                            date={this.props.hblFcrDetails.updateDate}
                        />
                    </PageWithTimeline>
                </Page>
            </>
        );
    }
}

const mapDispatchToProps = (dispatch: ThunkDispatch<any, any, Action>): IDispatch => ({
    fetchHBLFCRDetails: (id: string,  voyageId: string) => dispatch(fetchHBLFCRDetails(id, voyageId)),
});

const mapStateToProps = (state: IStore): IMapState => ({
    isFetchHBLFCRDetailsLoading: state.customs.isFetchHBLFCRDetailsLoading,
    hblFcrDetails: state.customs.hblFcrDetails,
    isFetchHBLFCRDetailsError: !!state.customs.fetchHBLFCRDetailsError,
    isUpdateHBLDetailsError: !!state.customs.updateHBLDetailsError,
    bubbles: state.customs.hblFcrDetails.bubbleData
});

export const HBLFCRDetails = connect(mapStateToProps, mapDispatchToProps)(_HBLFCRDetails);
