import React from 'react';
import { ColumnFactory } from 'components/data-table/ColumnFactory';
import { LoadingAnimation } from 'components/loading';
import { connect } from 'react-redux';
import { DataTable } from 'components/data-table/DamcoTable';
import { CreateNewReasonCodeModal } from 'modules/settings/shared/CreateNewReasonCodeModal';
import { ThunkDispatch } from 'redux-thunk';
import { Action } from 'redux';
import { fetchDeliveryPlanningReasonCodes, createDeliveryPlanningReasonCode, updateDeliveryPlanningReasonCode, resetCreateDeliveryPlanningReasonCodeError, deleteDeliveryPlanningReasonCode, resetUpdateDeliveryPlanningReasonCodeError } from 'actions/settings';
import { ConfirmModal } from 'components/confirm-modal';
import { ErrorModal } from 'modules/modals/components/ErrorModal';
import { EditReasonCodeModal } from 'modules/settings/shared/EditReasonCodeModal';
import { IStore } from 'reducers/index';
import { cloneDataWithDisplayNames, prettifyString } from 'utilities/tables';
import { ReasonCommentMode } from 'constants/reasonCodes';
import { ErrorDialog } from 'components/error-dialog';
import API from 'constants/api';

interface IReasonCodesTableOwnProps {
    isLoading: boolean;
    data: any[];
    createDeliveryPlanningReasonCodeError: string;
    refreshSettings: () => Promise<void>;
    editFunction: (id: number, body: any) => Promise<void>;
}

interface IMapStateToProps {
    reasonCodes: any[];
    updateReasonCodeError: string;
}

interface IMapDispatchToProps {
    fetchDeliveryPlanningReasonCodes: (group?: string) => Promise<void>;
    updateDeliveryPlanningReasonCode: (id: number, body: any) => Promise<void>;
    createDeliveryPlanningReasonCode: (body: any) => Promise<void>;
    resetCreateDeliveryPlanningReasonCodeError: () => void;
    removeReasonCode: (id: number) => Promise<void>;
    resetUpdateReasonCodeError: () => void;
}

interface IReasonCodesTableProps extends IReasonCodesTableOwnProps, IMapStateToProps, IMapDispatchToProps {}

interface IReasonCodesTableState {
    isEditConfigurationModalOpened: boolean;
    isCreateNewReasonCodeModalOpened: boolean;
    isConfirmModalOpen: boolean;
    isErrorModalOpen: boolean;
    editRowKey: string | null;
    editedItem: any;
}

export class _ReasonCodesTable extends React.Component<IReasonCodesTableProps, IReasonCodesTableState> {
    public dataTable = React.createRef<any>();

    public state: IReasonCodesTableState = {
        isEditConfigurationModalOpened: false,
        isCreateNewReasonCodeModalOpened: false,
        isConfirmModalOpen: false,
        isErrorModalOpen: false,
        editRowKey: null,
        editedItem: null
    };

    public render() {
        const data = cloneDataWithDisplayNames(this.props.data).map(reasonCode => ({
            ...reasonCode,
            groupDisplayName: prettifyString(reasonCode.group) || '-',
            commentModeDisplayName: ReasonCommentMode[reasonCode.commentMode] || '-',
            sendEventDisplayName: reasonCode.sendEvent ? 'Yes' : 'No'
        }));
        
        return (
            <React.Fragment>
                {this.props.isLoading && <LoadingAnimation />}
                <ErrorDialog endpoint={API.Settings.FetchDeliveryPlanningReasonCodes(this.getId())} />
                <ErrorDialog endpoint={API.Settings.DeleteDeliveryPlanningReasonCode(this.getId())} />
                <ErrorDialog endpoint={API.Settings.CreateDeliveryPlanningReasonCode} />
                <DataTable
                    data={data}
                    expandable={false}
                    withCheckboxes={true}
                    selectVariant="single"
                    columns={[
                        ColumnFactory.standardColumn('reasonCodeDisplayName', 'Reason code'),
                        ColumnFactory.standardColumn('groupDisplayName', 'Group'),
                        ColumnFactory.standardColumn('commentModeDisplayName', 'Comments mode'),
                        ColumnFactory.standardColumn('sendEventDisplayName', 'Send event'),
                    ]}
                    actions={{
                        primaryActionIsMoreBtn: false,
                        primaryActionLabel: 'Add new',
                        primaryAction: this.toggleCreateReasonCodeModal,
                        parentContextualActions: [
                            { isMoreBtn: false, label: 'Edit', action: this.toggleEditModal },
                            { isMoreBtn: false, label: 'Delete', action: this.toggleConfirmModal },
                        ]
                    }}
                    reference={this.dataTable}
                    tableName="SettingsGlobalsTable"
                    showPagination={true}
                />
                {this.state.isCreateNewReasonCodeModalOpened && <CreateNewReasonCodeModal
                    title="Create reason code"
                    visible={this.state.isCreateNewReasonCodeModalOpened}
                    closeModal={this.toggleCreateReasonCodeModal}
                    saveFunction={this.createNewReasonCode}
                    reasonCodes={this.props.reasonCodes}
                    errorMessage={this.props.createDeliveryPlanningReasonCodeError}
                    resetErrorMessage={this.props.resetCreateDeliveryPlanningReasonCodeError}
                    hasCommentMode={true}
                    sendEventEnabled={true}
                    capability="dp"
                />}
                {this.state.isEditConfigurationModalOpened && <EditReasonCodeModal
                    title="Edit reason code"
                    visible={this.state.isEditConfigurationModalOpened}
                    closeModal={this.toggleEditModal}
                    saveFunction={this.updateSettings}
                    initialValues={this.getRowToEdit()}
                    reasonCodes={this.props.reasonCodes}
                    hasCommentMode={true}
                    error={this.props.updateReasonCodeError}
                    resetError={this.props.resetUpdateReasonCodeError}
                    sendEventEnabled={true}
                />}
                {this.state.isConfirmModalOpen && <ConfirmModal
                    visible={this.state.isConfirmModalOpen}
                    title="Delete reason code confirmation"
                    closeModal={this.toggleConfirmModal}
                    onConfirm={this.removeSettings}
                >
                    <p>Are you sure?</p>
                </ConfirmModal>}
                {this.state.isErrorModalOpen && <ErrorModal
                    visible={this.state.isErrorModalOpen}
                    closeModal={this.toggleErrorModal}
                    primaryButtonFunc={this.toggleErrorModal}
                />}
            </React.Fragment>
        );
    }

    private toggleCreateReasonCodeModal = () => this.setState((state) => ({
        isCreateNewReasonCodeModalOpened: !state.isCreateNewReasonCodeModalOpened
    }));

    private toggleConfirmModal = () => this.setState((state) => ({
        isConfirmModalOpen: !state.isConfirmModalOpen
    }));

    private toggleErrorModal = () => this.setState((state) => ({
        isErrorModalOpen: !state.isErrorModalOpen
    }));

    private createNewReasonCode = async (configuration: any) => {
        const newReasonCode = {
            code: configuration.ReasonCode,
            group: configuration.Group,
            commentMode: configuration.commentMode,
            sendEvent: configuration.sendEvent
        };
        await this.props.createDeliveryPlanningReasonCode(newReasonCode);
        this.toggleCreateReasonCodeModal();
        await this.props.fetchDeliveryPlanningReasonCodes();
    };

    private toggleEditModal = (key?: string) => {
        const selectedItems = this.dataTable.current ? this.dataTable.current.getSelectedItems() : [];
        if (selectedItems[0]) {
            const rowToEdit = selectedItems[0];

            this.setState((state) => ({
                isEditConfigurationModalOpened: !state.isEditConfigurationModalOpened,
                editRowKey: key || null,
                editedItem: rowToEdit ? rowToEdit : null
            }))
        }
    };

    private updateSettings = async (configuration: any) => {
        await this.props.updateDeliveryPlanningReasonCode(this.getId(), {
            Code: configuration.ReasonCode,
            Group: configuration.Group,
            CommentMode: configuration.commentMode,
            SendEvent: configuration.sendEvent,
            ReasonCodeId: this.getId()
        });
        this.toggleEditModal();
        await this.props.refreshSettings();
    };

    private removeSettings = async () => {
        this.toggleConfirmModal();
        this.dataTable.current.hideContextRibbon();
        await this.props.removeReasonCode(this.getId());
        await this.props.refreshSettings();
    };

    private getRowToEdit = () => {
        const selectedItems = this.dataTable.current ? this.dataTable.current.getSelectedItems() : [];

        return {
            ReasonCode: selectedItems[0].reasonCode,
            Group: selectedItems[0].group,
            commentMode: selectedItems[0].commentMode,
            sendEvent: selectedItems[0].sendEvent
        };
    };

    private getId = () => {
        const selectedItems = this.dataTable.current ? this.dataTable.current.getSelectedItems() : [];
        if(selectedItems[0]) {
            return selectedItems[0].id;
        }
        return;
    };
}

const mapStateToProps = (state: IStore): IMapStateToProps => ({
    reasonCodes: state.settings.deliveryPlanningReasonCodes,
    updateReasonCodeError: state.settings.updateDeliveryPlanningReasonCodeError
});

const mapDispatchToProps = (dispatch: ThunkDispatch<any, any, Action>): IMapDispatchToProps => ({
    fetchDeliveryPlanningReasonCodes: (group?: string) => dispatch(fetchDeliveryPlanningReasonCodes(group)),
    updateDeliveryPlanningReasonCode: (id: number, body: any) => dispatch(updateDeliveryPlanningReasonCode(id, body)),
    createDeliveryPlanningReasonCode: (body: any) => dispatch(createDeliveryPlanningReasonCode(body)),
    resetCreateDeliveryPlanningReasonCodeError: () => dispatch(resetCreateDeliveryPlanningReasonCodeError()),
    removeReasonCode: (id: number) => dispatch(deleteDeliveryPlanningReasonCode(id)),
    resetUpdateReasonCodeError: () => dispatch(resetUpdateDeliveryPlanningReasonCodeError()),
});

export const ReasonCodesTable = connect(mapStateToProps, mapDispatchToProps)(_ReasonCodesTable);