import React, { Component } from 'react';
import { formatValueIfNullOrUndefined, getArrayOfButtons, formatDate } from 'utilities/util';
import { mapStatusToStringForCR } from 'constants/statuses';
import { connect } from 'react-redux';
import { editHblDetails } from 'actions/bill-release';
import { ThunkDispatch } from 'redux-thunk';
import { Action } from 'redux';
import { EditHblPopup } from 'modules/bill-release/shared/components/EditBlModal.tsx/EditHblPopup';
import { IStore } from 'reducers/index';
import { LoadingAnimation } from 'components/loading';
import { BUSINNESS_FUNCTIONS } from 'constants/roles';
import { surrenderType } from 'modules/bill-release/shared/components/EditBlModal.tsx/surrenderType';
// tslint:disable-next-line: no-submodule-imports
import { IWithPermissions, withPermissions } from 'src/HOC/withPermissions';
import { PropertiesRibbonWrapper } from 'components/properties-ribbon';

interface IHBLDetailsTopBarState {
    isEditDetailsOpened: boolean;
}

interface IMapDispatchToProps {
    editHblDetails: (body: any) => Promise<void>;
}

interface IMapStateToProps {
    isLoading: boolean;
}

interface IHBLDetailsTopBar extends IWithPermissions, IMapStateToProps, IMapDispatchToProps {
    vessels: any[];
    surrenderType: any;
    hblId: number;
    originalBLSurrendered: boolean;
    originalBLSurrenderDate: Date | string;
}

class _HBLDetailsTopBar extends Component<IHBLDetailsTopBar, IHBLDetailsTopBarState> {
    public state: IHBLDetailsTopBarState = {
        isEditDetailsOpened: false,
    };

    public render() {
        const lastVessel = this.props.vessels[this.props.vessels.length - 1] || {};
        const actions = [
            {label: 'Update HBL details', action: this.toggleEditDetailsModal},
        ];
        const propertiesRibbonData = {
            labels: [
                {label: 'First POL', value: formatValueIfNullOrUndefined(lastVessel.loadingPort)},
                {label: 'Last POD', value: formatValueIfNullOrUndefined(lastVessel.destinationPort)},
                {label: 'Original ETA', value: formatDate(lastVessel.originalETA)},
                {label: 'New ETA', value: formatDate(lastVessel.currentETA)},
                {label: 'Status', value: mapStatusToStringForCR(formatValueIfNullOrUndefined(lastVessel.carrierReleaseStatus))},
            ],
            actions: getArrayOfButtons(this.props.permissions, actions)
        };

        return (
            <React.Fragment>
                { this.props.isLoading && <LoadingAnimation /> }
                <PropertiesRibbonWrapper content={propertiesRibbonData} />
                {this.state.isEditDetailsOpened && <EditHblPopup
                    visible={this.state.isEditDetailsOpened}
                    closeModal={this.toggleEditDetailsModal}
                    blId={this.props.hblId}
                    initialValues={this.getInitialValuesForEditHblDetails()}
                    isSurrendered={this.props.originalBLSurrendered}
                    submitFunc={this.props.editHblDetails}
                    isHbl={true}
                    modalName="HBL"
                />}
            </React.Fragment>
        );
    }

    private toggleEditDetailsModal = () => this.setState((state) => ({
        isEditDetailsOpened: !state.isEditDetailsOpened,
    }));

    private getInitialValuesForEditHblDetails = () => {
        const type = surrenderType.find(({ optionText }) => optionText === this.props.surrenderType)
        return {
            SurrenderType: type ? type.value : '',
            SurrenderedDate: this.props.originalBLSurrenderDate,
        }
    };
}

const mapStateToProps = (state: IStore): IMapStateToProps => ({
    isLoading: state.bill_release.isEditBLDetailsLoading
});

const mapDispatchToProps = (dispatch: ThunkDispatch<any, any, Action>): IMapDispatchToProps => ({
    editHblDetails: (body: any) => dispatch(editHblDetails(body)),
});

const ROLES: BUSINNESS_FUNCTIONS[] = [BUSINNESS_FUNCTIONS.OCEAN_HBL_RELEASE_EDIT_HBL];

const __HBLDetailsTopBar = withPermissions<IHBLDetailsTopBar>(_HBLDetailsTopBar, ROLES);

export const HBLDetailsTopBar = connect(mapStateToProps, mapDispatchToProps)(__HBLDetailsTopBar) as any;