import React from 'react';
import { WrappedFieldArrayProps } from 'redux-form';
import { IPlanningHBL } from 'reducers/planning/models';
import { TripleGrid } from 'components/grid/triple-grid';
import { FormInput } from 'components/input/FormInput';

export const HBLFieldArray = ({ fields }: WrappedFieldArrayProps<IPlanningHBL>) => <>
    {fields.length === 0 && <span>No HBLs found</span>}
    {fields.map(hbl => <TripleGrid key={hbl}>
        <FormInput
            name={`${hbl}.number`}
            label="Number"
            disabled={true}
        />
        <FormInput
            name={`${hbl}.deconsolidationLocation`}
            label="Deconsolidation location"
        />
        <FormInput
            name={`${hbl}.reference`}
            label="Reference"
        />
    </TripleGrid>)}
</>
