import React, { Component } from 'react';
import { PageHeader } from 'components/page-header';
import { Page } from 'components/page-container';
import { Filters } from 'modules/filters/containers/Filters';
import { FiltersInput } from 'modules/filters/components/FiltersInput';
import { connect } from 'react-redux';
import { ThunkDispatch } from 'redux-thunk';
import { getPoPlanning } from 'actions/planning';
import { Action } from 'redux';
import { IStore } from 'reducers/index';
import { FiltersDateInput } from 'modules/filters/components/FiltersDateInput';
import { FiltersSelect } from 'modules/filters/components/FiltersSelect';
import { statusesValues } from 'utilities/util';
import { POTable } from 'modules/planning/components/POTable';
import { SubNavigation } from 'components/sub-navigation';
import { ILink } from 'components/sub-navigation/models/Link';
import { IPoPlanning } from 'reducers/planning/models';
import { setSort, resetSort } from 'actions/filters';
import { ISortModel } from 'reducers/filters/models';
import { DP_STATUS } from 'constants/statuses';
import { setSortForTable } from 'utilities/tables';

const links: ILink[] = [{
    to: '/planning/po',
    linkText: 'Ocean',
}, {
    to: '/planning/po/air',
    linkText: 'Air',
}];

interface IDispatch {
    getPoPlanning: (pageNumber: number, pageSize: number) => Promise<void>;
    setSort: (sort: ISortModel) => void;
    resetSort: () => void;
}

interface IMapStateToProps {
    poPlanningList: IPoPlanning;
    fetchPoPlanningListError: string;
    poPlanningListIsLoading: boolean;
    ataEmpty: boolean;
    newEtaEmpty: boolean;
    originalPlannedDateEmpty: boolean;
}

interface IPlanning extends IMapStateToProps, IDispatch {}

interface IPlanningState {
    page: number;
    pageSize: number;
}

class _POPlanning extends Component<IPlanning, IPlanningState> {
    public state = {
        page: 1,
        pageSize: 10
    };

    public componentWillUnmount() {
        this.props.resetSort();
    };

    public render() {
        const filters = [
            <FiltersInput
                key="PONumber"
                name="PONumber"
                label="PO number"
            />,
            <FiltersInput
                key="EquipmentNumber"
                name="EquipmentNumber"
                label="Equipment number"
            />,
            <FiltersDateInput
                type="date"
                key="newEta_from"
                name="newEta_from"
                label="New ETA (from)"
                isEndOfDay={false}
                relatedField="newEta"
                readOnly={this.props.newEtaEmpty}
            />,
            <FiltersDateInput
                type="date"
                key="newEta_to"
                name="newEta_to"
                label="New ETA (to)"
                isEndOfDay={true}
                relatedField="newEta"
                readOnly={this.props.newEtaEmpty}
            />,
            <FiltersDateInput
                type="date"
                key="ata_from"
                name="ata_from"
                label="ATA (from)"
                isEndOfDay={true}
                relatedField="ata"
                readOnly={this.props.ataEmpty}
            />,
            <FiltersDateInput
                type="date"
                key="ata_to"
                name="ata_to"
                label="ATA (to)"
                isEndOfDay={true}
                relatedField="ata"
                readOnly={this.props.ataEmpty}
            />,
            <FiltersDateInput
                type="date"
                key="OriginalPlannedDate_from"
                name="OriginalPlannedDate_from"
                label="Original planned date (from)"
                relatedField="originalPlannedDate"
                readOnly={this.props.originalPlannedDateEmpty}
            />,
            <FiltersDateInput
                type="date"
                key="OriginalPlannedDate_to"
                name="OriginalPlannedDate_to"
                label="Original planned date (to)"
                isEndOfDay={true}
                relatedField="originalPlannedDate"
                readOnly={this.props.originalPlannedDateEmpty}
            />,
            <FiltersInput
                key="POD"
                name="podName"
                label="POD"
            />,
            <FiltersInput
                key="DeliveryLocation"
                name="DeliveryLocation"
                label="Delivery location"
            />,
            <FiltersSelect
                type="number"
                key="deliveryStatus"
                name="deliveryStatus"
                label="Status"
                options={statusesValues(DP_STATUS)}
            />,
        ];

        return (
            <>
                <PageHeader>PO planning</PageHeader>
                <Page>
                    <Filters
                        tableNameKey="planning-ocean"
                        error={this.props.fetchPoPlanningListError}
                        filters={filters}
                        fetchFunction={() => this.props.getPoPlanning(this.state.page, this.state.pageSize)}
                    />
                    <SubNavigation links={links} />
                    <POTable
                        data={this.props.poPlanningList.result}
                        isLoading={this.props.poPlanningListIsLoading}
                        pageCount={Math.ceil(this.props.poPlanningList.totalCount / this.props.poPlanningList.pageSize)}
                        fetchEquipments={this.fetchEquipments}
                        refreshFunction={() => this.props.getPoPlanning(1, 10)}
                    />
                </Page>
            </>
        );
    };

    private fetchEquipments = (tableData: any) => {
        this.setState({
            page: tableData.page + 1,
            pageSize: tableData.pageSize
        }, () => {
            setSortForTable(tableData.sorted, this.props.setSort);
            this.props.getPoPlanning(this.state.page, this.state.pageSize);
        });
    };
}

const mapStateToProps = (state: IStore): IMapStateToProps => ({
    poPlanningList: state.planning.poPlanningList,
    fetchPoPlanningListError: state.planning.fetchPoPlanningListError,
    poPlanningListIsLoading: state.planning.poPlanningListIsLoading,
    newEtaEmpty: state.filters.filters.neweta_empty,
    ataEmpty: state.filters.filters.ata_empty,
    originalPlannedDateEmpty: state.filters.filters.originalplanneddate_empty
});

const mapDispatchToProps = (dispatch: ThunkDispatch<any, any, Action>): IDispatch => ({
    getPoPlanning: (pageNumber: number, pageSize: number) => dispatch(getPoPlanning(pageNumber, pageSize)),
    setSort: (sort: ISortModel) => dispatch(setSort(sort)),
    resetSort: () => dispatch(resetSort()),
});

export const POPlanning = connect<IMapStateToProps, IDispatch, {}, IStore>(mapStateToProps, mapDispatchToProps)(_POPlanning);
