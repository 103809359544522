import React, { Component } from 'react';
import { EditReleaseDetailsPopup } from 'views/bill-release/carrier-bl-details/components/EditReleaseDetailsPopup';
import { RequestReleaseModal } from 'modules/bill-release/cbl/components/RequestReleaseModal';
import { formatDate, formatValueIfNullOrUndefined, getArrayOfButtons } from 'utilities/util';
import { ICblEquipment } from 'reducers/bill-release/models';
import { connect } from 'react-redux';
import { ThunkDispatch } from 'redux-thunk';
import { Action } from 'redux';
import { editCblDetails } from 'actions/bill-release';
import { EditHblPopup } from 'modules/bill-release/shared/components/EditBlModal.tsx/EditHblPopup';
import { IStore } from 'reducers/index';
import { LoadingAnimation } from 'components/loading';
import { BUSINNESS_FUNCTIONS } from 'constants/roles';
import { uniqBy } from 'lodash';
import { surrenderType } from 'modules/bill-release/shared/components/EditBlModal.tsx/surrenderType';
import { mapStatusToStringForCR } from 'constants/statuses';
// tslint:disable-next-line: no-submodule-imports
import { withPermissions, IWithPermissions } from 'src/HOC/withPermissions';
import { PropertiesRibbonWrapper } from 'components/properties-ribbon';
import { fetchMRNDetails } from 'actions/vessels';
import { UpdateCustomsDetailsModal } from 'modules/modals/components/UpdateCustomsDetailsModal';

interface ICarrierBLDetailsTopBarState {
    editReleaseDetailsPopup: boolean;
    requestModalOpened: boolean;
    isUpdateReleaseDetailsModalOpened: boolean;
    isUpdateCustomsModalOpened: boolean;
}

interface IMapDispatchToProps {
    editCblDetails: (body: any) => Promise<void>;
    fetchMRNDetails: (blId: number[]) => Promise<void>;
}

interface IMapStateToProps {
    isLoading: boolean;
    mrnData: any[];
}

interface ICarrierBLDetailsTopBarProps extends IWithPermissions, IMapStateToProps, IMapDispatchToProps {
    id: number;
    carrierName: string;
    blNumber: string;
    billType: any;
    carrierReleaseStatus: number | string;
    vesselName: string;
    voyage: string;
    destinationPort: string;
    newETA: Date | string;
    ata: Date | string;
    surrenderType: string;
    blId: number;
    voyageId: number;
    originalBLSurrenderDate: Date | string;
    originalBLSurrendered: boolean;
    equipments: ICblEquipment[];
    refreshFunction: () => Promise<void>;
}

class _CarrierBLDetailsTopBar extends Component<ICarrierBLDetailsTopBarProps, ICarrierBLDetailsTopBarState> {
    public state: ICarrierBLDetailsTopBarState = {
        editReleaseDetailsPopup: false,
        requestModalOpened: false,
        isUpdateReleaseDetailsModalOpened: false,
        isUpdateCustomsModalOpened: false
    };

    public render() {
        const actions = [
            {label: 'Update CBL details', action: this.toggleEditReleaseDetailsPopup},
            {label: 'Request release', action: this.toggleRequestReleaseModal},
            {label: 'Update release details', action: this.toggleUpdateReleaseDetailsModal},
            {label: 'Update customs details', action: this.getMRNData}
        ];
        const propertiesRibbonData = {
            labels: [
                {label: 'Vessel name', value: formatValueIfNullOrUndefined(this.props.vesselName)},
                {label: 'Voyage', value: formatValueIfNullOrUndefined(this.props.billType)},
                {label: 'Carrier', value: formatValueIfNullOrUndefined(this.props.carrierName)},
                {label: 'SVC', value: formatValueIfNullOrUndefined(this.props.blNumber)},
                {label: 'POD', value: formatValueIfNullOrUndefined(this.props.destinationPort)},
                {label: 'Current ETA', value: formatDate(this.props.newETA)},
                {label: 'ATA', value: formatDate(this.props.ata)},
                {label: 'Bill type', value: formatValueIfNullOrUndefined(this.props.billType)},
                {label: 'Release status', value: mapStatusToStringForCR(formatValueIfNullOrUndefined(this.props.carrierReleaseStatus))},
            ],
            actions: getArrayOfButtons(this.props.permissions, actions)
        }
        const ids = {
            voyageIds: [this.props.voyageId],
            blIds: [this.props.blId]
        };

        return(
            <>
                { this.props.isLoading && <LoadingAnimation /> }
                <PropertiesRibbonWrapper content={propertiesRibbonData} />
                {this.state.editReleaseDetailsPopup && <EditHblPopup
                    visible={this.state.editReleaseDetailsPopup}
                    closeModal={this.toggleEditReleaseDetailsPopup}
                    blId={this.props.blId}
                    initialValues={this.getInitialValuesForEditHblDetails()}
                    isSurrendered={this.props.originalBLSurrendered}
                    submitFunc={this.editCblDetails}
                    modalName="CBL"
                />}
                {this.state.requestModalOpened && <RequestReleaseModal
                    visible={this.state.requestModalOpened}
                    closeModal={this.toggleRequestReleaseModal}
                    equipments={uniqBy(this.props.equipments, 'equipmentId')}
                    carrierName={this.props.carrierName}
                    editingItemsCount={0}
                    ids={ids}
                />}
                {this.state.isUpdateReleaseDetailsModalOpened && <EditReleaseDetailsPopup
                    visible={this.state.isUpdateReleaseDetailsModalOpened}
                    closeModal={this.toggleUpdateReleaseDetailsModal}
                    data={this.getEqupmentsForReleaseDetailsModal()}
                    editingItemsCount={0}
                    isDetails={true}
                    id={this.props.id}
                    cblId={this.props.blId}
                />}
                {this.state.isUpdateCustomsModalOpened && <UpdateCustomsDetailsModal
                    data={this.props.mrnData}
                    visible={this.state.isUpdateCustomsModalOpened}
                    closeModal={() => this.setState({ isUpdateCustomsModalOpened: false })}
                    ids={this.props.blId}
                />}
            </>
        )
    }

    private toggleEditReleaseDetailsPopup = () => this.setState((state) => ({
        editReleaseDetailsPopup: !state.editReleaseDetailsPopup
    }));

    private toggleRequestReleaseModal = () => this.setState((state) => ({
        requestModalOpened: !state.requestModalOpened
    }));

    private toggleUpdateReleaseDetailsModal = () => this.setState((state) => ({
        isUpdateReleaseDetailsModalOpened: !state.isUpdateReleaseDetailsModalOpened
    }));

    private getInitialValuesForEditHblDetails = () => {
        const type = surrenderType.find(({ optionText }) => optionText === this.props.surrenderType)
        return {
            SurrenderType: type ? type.value : '',
            SurrenderedDate: this.props.originalBLSurrenderDate,
        }
    }

    private editCblDetails = async (data: any) => {
        await this.props.editCblDetails(data);
        await this.props.refreshFunction();
    }

    private getEqupmentsForReleaseDetailsModal = () => {
        return uniqBy(this.props.equipments, 'equipmentId').map(({expiryDate, emptyReturn, ...item}) => ({
            ...item,
            emptyLocation: emptyReturn,
            pinExpiryDate: expiryDate,
        }));
    };

    private getMRNData = () => {
        this.props.fetchMRNDetails([this.props.blId]);

        this.setState({ isUpdateCustomsModalOpened: true });
    }
};

const mapStateToProps = (state: IStore): IMapStateToProps => ({
    isLoading: state.bill_release.isEditBLDetailsLoading,
    mrnData: state.vessels.mrnData
});

const mapDispatchToProps = (dispatch: ThunkDispatch<any, any, Action>): IMapDispatchToProps => ({
    fetchMRNDetails: (blId: number[]) => dispatch(fetchMRNDetails(blId)),
    editCblDetails: (body: any) => dispatch(editCblDetails(body)),
});

const ROLES: BUSINNESS_FUNCTIONS[] = [
    BUSINNESS_FUNCTIONS.OCEAN_CBL_RELEASE_EDIT_CBL,
    BUSINNESS_FUNCTIONS.OCEAN_CBL_RELEASE_REQUEST_RELEASE,
    BUSINNESS_FUNCTIONS.OCEAN_CBL_RELEASE_UPDATE_RELEASE_DETAILS,
    BUSINNESS_FUNCTIONS.OCEAN_MBL_TRACKING_EDIT_MRN
];

const __CarrierBLDetailsTopBar = withPermissions<ICarrierBLDetailsTopBarProps>(_CarrierBLDetailsTopBar, ROLES);

export const CarrierBLDetailsTopBar = connect(mapStateToProps, mapDispatchToProps)(__CarrierBLDetailsTopBar) as any;
