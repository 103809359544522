import React from 'react';
import { Arrow } from 'components/arrow';
import styled from 'styled-components';

const SLink: any = styled.a`
    cursor: pointer !important;

    i {
        margin-left: 5px;
    }
`;

interface IToggleArrowProps{
    onToggle?: (event: React.MouseEvent<HTMLElement>) => void;
    isExpanded: boolean;
    expandedText?: string;
    nonExpandedText?: string;
}

const defaultProps = {
    expandedText: 'Less filters',
    nonExpandedText: 'More filters ',
};

export const ToggleArrow: React.FunctionComponent<IToggleArrowProps> = (props) => (
    <SLink
        onClick={props.onToggle}
        className="regular-link-text hide-filter"
    >
        {!props.isExpanded ? props.nonExpandedText : props.expandedText}
        <Arrow isUp={props.isExpanded} />
    </SLink>
);


ToggleArrow.defaultProps = defaultProps;