import React from 'react';
import { Select } from 'components/select/Select';

interface IFiltersBooleanSelect {
    trueOptionText: string;
    falseOptionText: string;
    label: string;
    value?: any; // injected
    name: string;
    type: string;
    onChange?(event: any): void; // injected
}

export const FiltersBooleanSelect = (props: IFiltersBooleanSelect) => {
    return(
        <div className="col-25">
            <Select
                label={props.label}
                value={props.value}
                onChange={props.onChange}
                options={[
                    {
                        value: '',
                        optionText: 'Select value...'
                    },
                    {
                        value: true,
                        optionText: props.trueOptionText
                    }, {
                        value: false,
                        optionText: props.falseOptionText
                    }
                ]}
            />
        </div>
    )
};
