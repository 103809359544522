import React, { FunctionComponent, useRef, useState } from 'react';
import { LoadingAnimation } from 'components/loading';
import { ColumnFactory } from 'components/data-table/ColumnFactory';
import { connect } from 'react-redux';
import { DataTable } from 'components/data-table/DamcoTable';
import { AddContractModal } from 'modules/demurrage-and-detention/components/AddNewContractModal';
import { ThunkDispatch } from 'redux-thunk';
import { Action } from 'redux';
import { getDDContracts, addNewDDContract, removeDDContract, updateNewDDContract } from 'actions/demurrageAndDetention';
import { IAddNewDDContractBody, IUpdateDDContractBody } from 'reducers/demurrage-and-detention/models';
import { EditContractModal } from 'modules/demurrage-and-detention/components/EditContractModal';
import { ErrorDialog } from 'components/error-dialog';
import API from 'constants/api';
import { cloneDataWithDisplayNames } from 'utilities/tables';
import { FeeType, ContractFromAndTo } from 'constants/contracts';

interface IContractsTableOwnProps {
    data: any[];
    isLoading: boolean;
    pageCount: number;
    fetchContracts: any;
}

interface IMapDispatchToProps {
    getDDContracts: () => Promise<void>;
    addNewDDContract: (body: IAddNewDDContractBody) => Promise<void>;
    updateDDContract: (body: IUpdateDDContractBody) => Promise<void>;
    removeDDContract: (id: number) => Promise<void>;
}

interface IContractsTableProps extends IContractsTableOwnProps, IMapDispatchToProps {}

const _ContractsTable: FunctionComponent<IContractsTableProps> = (props) => {
    const [isAddContractModalOpened, setAddContractModal] = useState<boolean>(false);
    const [isEditContractModalOpened, setEditContractModal] = useState<boolean>(false);
    const dataTable = useRef<any>();

    const toggleAddContractModal = () => setAddContractModal(!isAddContractModalOpened);

    const toggleEditContractModal = () => setEditContractModal(!isEditContractModalOpened);

    const addNewContract = async (fields: any) => {
        const reqBody = {
            beCode: fields.beCode,
            customer: fields.customer,
            feetype: fields.feeType,
            from: fields.from,
            to: fields.to,
            scac: fields.scac,
            containerType: fields.containerType,
            containerSize: fields.containerSize,
            freeDays: fields.freeDays,
            iMODG: fields.imodg,
            validFrom: fields.validFrom,
            validTo: fields.validTo,
            portLevel: fields.portLevel,
            oceanPort: fields.oceanPort,
            inlandPort: fields.inlandPort,
            damcoNVOCC: fields.damcoNVOCC,
            freetimeCalcType: fields.freetimeCalcType,
            charge: fields.charge,
            currency: fields.currency
        };

        await props.addNewDDContract(reqBody);
        await setAddContractModal(false);
        await props.getDDContracts();
    };

    const updateContract = async (fields: any) => {
        const removeTableSelection = dataTable.current ? dataTable.current.hideContextRibbon : null;
        const reqBody = {
            contractId: fields.contractId,
            beCode: fields.beCode,
            customer: fields.customer,
            feetype: fields.feeType,
            from: fields.from,
            to: fields.to,
            scac: fields.scac,
            containerType: fields.containerType,
            containerSize: fields.containerSize,
            freeDays: fields.freeDays,
            iMODG: fields.imodg,
            validFrom: fields.validFrom,
            validTo: fields.validTo,
            portLevel: fields.portLevel,
            oceanPort: fields.oceanPort,
            inlandPort: fields.inlandPort,
            damcoNVOCC: fields.damcoNVOCC,
            freetimeCalcType: fields.freetimeCalcType,
            charge: fields.charge,
            currency: fields.currency
        };

        setEditContractModal(false);
        await removeTableSelection();
        await props.updateDDContract(reqBody);
        await props.getDDContracts();
    }

    const getInitialValues = () => {
        const selectedItems = dataTable.current ? dataTable.current.getSelectedItems() : [];
        const data = selectedItems[0];

        return {
            beCode: data.beCode,
            contractId: data.contractId,
            customer: data.customer,
            feeType: FeeType[data.feeType],
            from: ContractFromAndTo[data.from],
            to: ContractFromAndTo[data.to],
            scac: data.scac,
            containerType: data.containerType,
            containerSize: data.containerSize,
            imodg: data.imodg,
            validFrom: data.validFrom,
            validTo: data.validTo,
            portLevel: data.portLevel,
            oceanPort: data.oceanPort,
            inlandPort: data.inlandPort,
            damcoNVOCC: data.damcoNVOCC,
            freetimeCalcType: data.freetimeCalcType,
            charge: data.charge,
            currency: data.currency,
            freeDays: data.freeDays
        };
    };

    const deleteContract = async () => {
        await props.removeDDContract(getId());
        await dataTable.current.hideContextRibbon();
        await props.getDDContracts();
    };

    const getId = () => {
        const selectedItems = dataTable.current ? dataTable.current.getSelectedItems() : [];
        return selectedItems[0].contractId;
    };

    const mappedData = cloneDataWithDisplayNames(props.data).map((item: any, i: number) => ({
        ...item,
        id: i
    }));

    return (
        <React.Fragment>
            {props.isLoading && <LoadingAnimation />}
            <ErrorDialog endpoint={API.DemurrageAndDetention.GetDDContracts} />
            <ErrorDialog endpoint={API.DemurrageAndDetention.UpdateDDContract} />
            <ErrorDialog endpoint={API.DemurrageAndDetention.AddDDContract} />
            <DataTable
                data={mappedData}
                withCheckboxes={true}
                expandable={false}
                selectVariant="single"
                columns={[
                    ColumnFactory.standardColumn('beCode', 'BE Code'),
                    ColumnFactory.standardColumn('contractId', 'ID'),
                    ColumnFactory.standardColumn('containerType', 'Type'),
                    ColumnFactory.standardColumn('customer', 'Name'),
                    ColumnFactory.standardColumn('etd', 'Short name'),
                    ColumnFactory.standardColumn('atd', 'Code'),
                    ColumnFactory.standardColumn('etaOriginal', 'Timezone ID'),
                    ColumnFactory.standardColumn('eta', 'SCV code'),
                    ColumnFactory.standardColumn('ata', 'Is Damco party')
                ]}
                actions={{
                    primaryActionIsMoreBtn: false,
                    primaryActionLabel: 'Add contract',
                    primaryAction: toggleAddContractModal,
                    parentContextualActions: [
                        { isMoreBtn: false, label: 'Edit contract', action: toggleEditContractModal },
                        { isMoreBtn: false, label: 'Delete contract', action: deleteContract },
                    ]
                }}
                reference={dataTable}
                tableName="SettingsDnDContractTable"
                manualPagination={true}
                fetchData={props.fetchContracts}
                pageCount={props.pageCount}
                showPagination={true}
            />
            {isAddContractModalOpened && <AddContractModal
                title="Add new contract"
                visible={isAddContractModalOpened}
                closeModal={toggleAddContractModal}
                onSubmit={addNewContract}
            />}
            {isEditContractModalOpened && <EditContractModal
                title="Edit contract"
                visible={isEditContractModalOpened}
                closeModal={toggleEditContractModal}
                onSubmit={updateContract}
                initialValues={getInitialValues()}
            />}
        </React.Fragment>
    );
}

const mapDispatchToProps = (dispatch: ThunkDispatch<any, any, Action>): IMapDispatchToProps => ({
    getDDContracts: () => dispatch(getDDContracts(1, 10)),
    addNewDDContract: (body: IAddNewDDContractBody) => dispatch(addNewDDContract(body)),
    updateDDContract: (body: IUpdateDDContractBody) => dispatch(updateNewDDContract(body)),
    removeDDContract: (id: number) => dispatch(removeDDContract(id)),
});

export const ContractsTable = connect<{}, IMapDispatchToProps>(undefined, mapDispatchToProps)(_ContractsTable);
