import React, { Component } from 'react';
import { PageHeader } from 'components/page-header';
import { PlanningTable } from 'modules/planning/components/PlanningTable';
import { Page } from 'components/page-container';
import { Filters } from 'modules/filters/containers/Filters';
import { FiltersInput } from 'modules/filters/components/FiltersInput';
import { connect } from 'react-redux';
import { ThunkDispatch } from 'redux-thunk';
import { getEquipmentsList } from 'actions/planning';
import { Action } from 'redux';
import { IStore } from 'reducers/index';
import { IEquipmentListData } from 'reducers/planning/models';
import { FiltersDateInput } from 'modules/filters/components/FiltersDateInput';
import { FiltersSelect } from 'modules/filters/components/FiltersSelect';
import { FiltersBooleanSelect } from 'modules/filters/components/FiltersBooleanSelect';
import { statusesValues, getHaulierText } from 'utilities/util';
import { DERIG_STATUSES_OPTIONS, PLANNING_STATUS_OPTIONS } from 'constants/statuses';
import { IOption } from 'components/select/Select';
import { ISettingsModel } from 'reducers/settings/models';
import { fetchDeliveryPlanningHaulierSettings } from 'actions/settings';
import { LoadingAnimation } from 'components/loading';
import { HandleError } from 'components/handle-error';
import { setSort, resetSort } from 'actions/filters';
import { ISortModel } from 'reducers/filters/models';
import { setSortForTable } from 'utilities/tables';

interface IDispatch {
    getEquipmentsList: (pageNumber: number, pageSize: number) => Promise<void>;
    fetchDeliveryPlanningHaulierSettings: () => void;
    setSort: (sort: ISortModel) => void;
    resetSort: () => void;
}

interface IMapStateToProps {
    equipments: IEquipmentListData;
    isLoading: boolean;
    isGetEquipmentsError: boolean;
    deliveryPlanningHaulierSettings: ISettingsModel[];
    isExportLoading: boolean;
    exportPlanningError: string;
    clientDeliveryEmpty: boolean;
    newEtaEmpty: boolean;
    ataEmpty: boolean;
}

interface IPlanning extends IMapStateToProps, IDispatch {}

interface IPlanningState {
    page: number;
    pageSize: number;
}

class _Planning extends Component<IPlanning, IPlanningState> {
    public state = {
        page: 1,
        pageSize: 10
    };

    public componentDidMount() {
        this.props.fetchDeliveryPlanningHaulierSettings();
    }

    public render() {
        const filters = [
            <FiltersInput
                key="equipmentNumber"
                name="EquipmentNumber"
                label="Equipment Number"
                id="equipmentNumberFilter"
            />,
            <FiltersDateInput
                type="date"
                key="clientDelivery_from"
                name="clientDelivery_from"
                label="Client delivery (from)"
                relatedField="clientDelivery"
                readOnly={this.props.clientDeliveryEmpty}
            />,
            <FiltersDateInput
                type="date"
                key="ClientDelivery_to"
                name="ClientDelivery_to"
                label="Client delivery (to)"
                isEndOfDay={true}
                relatedField="clientDelivery"
                readOnly={this.props.clientDeliveryEmpty}
            />,
            <FiltersInput
                type="number"
                key="Demurrage"
                name="Demurrage"
                label="Demurrage"
            />,
            <FiltersDateInput
                type="date"
                key="newEta_from"
                name="newEta_from"
                label="New ETA (from)"
                relatedField="newEta"
                readOnly={this.props.newEtaEmpty}
            />,
            <FiltersDateInput
                type="date"
                key="newEta_to"
                name="newEta_to"
                label="New ETA (to)"
                isEndOfDay={true}
                relatedField="newEta"
                readOnly={this.props.newEtaEmpty}
            />,
            <FiltersDateInput
                type="date"
                key="ata_from"
                name="ata_from"
                label="ATA (from)"
                relatedField="ata"
                readOnly={this.props.ataEmpty}
            />,
            <FiltersDateInput
                type="date"
                key="ata_to"
                name="ata_to"
                label="ATA (to)"
                isEndOfDay={true}
                relatedField="ata"
                readOnly={this.props.ataEmpty}
            />,
            <FiltersSelect
                type="number"
                key="status"
                name="DeliveryStatus"
                label="Status"
                options={statusesValues(PLANNING_STATUS_OPTIONS)}
            />,
            <FiltersBooleanSelect
                type="string"
                key="hotbox"
                name="IsHotBox"
                label="Hotbox"
                falseOptionText="No"
                trueOptionText="Yes"
            />,
            <FiltersInput
                key="pickUpLocation"
                name="pickUpLocation"
                label="Pick up location"
            />,
            <FiltersBooleanSelect
                type="string"
                key="goh"
                name="IsGOH"
                label="GOH"
                falseOptionText="No"
                trueOptionText="Yes"
            />,
            <FiltersSelect
                type="number"
                key="DeRigStatus"
                name="DeRigStatus"
                label="Derig status"
                options={DERIG_STATUSES_OPTIONS}
            />,
            <FiltersBooleanSelect
                type="string"
                key="dg"
                name="IsDangerousGoods"
                label="DG"
                falseOptionText="No"
                trueOptionText="Yes"
            />,
            <FiltersSelect
                key="Haulier"
                name="Haulier"
                label="Haulier"
                options={this.getHauliers()}
            />,
            <FiltersInput
                key="location"
                name="location"
                label="Delivery location"
            />,
            <FiltersInput
                key="POD"
                name="podName"
                label="POD"
            />,
            <FiltersInput
                key="PONumber"
                name="PONumber"
                label="PO Number"
            />,
            <FiltersInput
                key="lastUserUpdated"
                name="lastUserUpdated"
                label="Last updated by"
            />,
            <FiltersInput
                key="cblNumber"
                name="cblNumber"
                label="CBL number"
            />,
        ];

        return (
            <>
                <PageHeader>Container planning</PageHeader>
                <Page>
                    {this.props.isExportLoading && <LoadingAnimation />}
                    <HandleError
                        exportPlanningError={this.props.exportPlanningError}
                    />
                    <Filters
                        tableNameKey="planning-ocean"
                        error={this.props.isGetEquipmentsError}
                        filters={filters}
                        fetchFunction={() => this.props.getEquipmentsList(this.state.page, this.state.pageSize)}
                    />
                    <PlanningTable
                        data={this.props.equipments.data}
                        isLoading={this.props.isLoading}
                        pageCount={Math.ceil(this.props.equipments.totalCount / this.props.equipments.pageSize)}
                        fetchEquipments={this.fetchEquipments}
                    />
                </Page>
            </>
        );
    }

    public componentWillUnmount() {
        this.props.resetSort();
    }

    private fetchEquipments = (tableData: any) => {
        this.setState({
            page: tableData.page + 1,
            pageSize: tableData.pageSize
        }, () => {
            setSortForTable(tableData.sorted, this.props.setSort);
            this.props.getEquipmentsList(this.state.page, this.state.pageSize);
        });
    };

    private getHauliers = (): IOption[] => {
        const {deliveryPlanningHaulierSettings: hauliers} = this.props;

        return hauliers.map((haulier) => ({
            value: haulier.id,
            optionText: getHaulierText(haulier)
        }));
    };
}

const mapStateToProps = (state: IStore): IMapStateToProps => ({
    equipments: state.planning.equipments,
    isLoading: state.planning.isGetEquipmentsPending,
    isGetEquipmentsError: !!state.planning.getEquipmentsError,
    deliveryPlanningHaulierSettings: state.settings.deliveryPlanningHaulierSettings,
    isExportLoading: state.exportReducer.isExportEquipmentPlanningLoading,
    exportPlanningError: state.exportReducer.exportPlanningError,
    clientDeliveryEmpty: state.filters.filters.clientdelivery_empty,
    newEtaEmpty: state.filters.filters.neweta_empty,
    ataEmpty: state.filters.filters.ata_empty
});

const mapDispatchToProps = (dispatch: ThunkDispatch<any, any, Action>): IDispatch => ({
    getEquipmentsList: (pageNumber: number, pageSize: number) => dispatch(getEquipmentsList(pageNumber, pageSize)),
    fetchDeliveryPlanningHaulierSettings: () => dispatch(fetchDeliveryPlanningHaulierSettings(true)),
    setSort: (sort: ISortModel) => dispatch(setSort(sort)),
    resetSort: () => dispatch(resetSort()),
});

export const Planning = connect<IMapStateToProps, IDispatch, {}, IStore>(mapStateToProps, mapDispatchToProps)(_Planning);
