import React, { FunctionComponent, useRef, useState } from 'react';
import { ColumnFactory } from 'components/data-table/ColumnFactory';
import { IDetention } from 'reducers/demurrage-and-detention/models';
import { downloadFile } from 'utilities/download';
import { connect } from 'react-redux';
import { ThunkDispatch } from 'redux-thunk';
import { Action } from 'redux';
import { exportDetentionToExcel } from 'actions/export';
import { DataTable } from 'components/data-table/DamcoTable';
import { EditDAndDDetailsPopup } from 'modules/modals/components/EditDAndDDetailsPopup';
// tslint:disable-next-line: no-submodule-imports
import { IWithPermissions, withPermissions } from 'src/HOC/withPermissions';
import { getArrayOfButtons } from 'utilities/util';
import { BUSINNESS_FUNCTIONS } from 'constants/roles';
import { IStore } from 'reducers/index';
import { cloneDataWithDisplayNames } from 'utilities/tables';

interface IMapDispatchToProps {
    exportDetentionToExcel: () => Promise<any>;
}

interface IDetentionTableOwnProps {
    data: IDetention[];
    refetchFunction: () => void;
    pageCount: number;
    fetchEquipments: any;
}

interface IDetentionTableProps extends IWithPermissions, IDetentionTableOwnProps, IMapDispatchToProps {}

const _DetentionTable: FunctionComponent<IDetentionTableProps> = (props) => {
    const [isEditDnDModalOpened, setEditDnDModalOpened] = useState<boolean>(false);
    const dataTable = useRef<any>();

    const exportToExcel = async (): Promise<void> => {
        const res = await props.exportDetentionToExcel();
        await downloadFile(res);
    };

    const toggleEditDnDModal = (): void => setEditDnDModalOpened(!isEditDnDModalOpened);

    const getId = (): any => {
        const selectedItem = dataTable.current ? dataTable.current.getSelectedItems() : null;
        
        if(selectedItem[0]) {
            return selectedItem[0].id
        }
    };

    const data = cloneDataWithDisplayNames(props.data).map((obj: any) => ({
        ...obj,
        equipmentNumberDisplayName: [obj.equipmentNumber, `/demurrage-and-detention/${obj.id}`],
        carrierNameDisplayName: obj.carrierNames.join(', ')
    }));

    return(
        <React.Fragment>
            <DataTable
                data={data}
                columns={[
                    ColumnFactory.linkedColumn('equipmentNumberDisplayName', 'Equipment number'),
                    ColumnFactory.standardColumn('vesselNameDisplayName', 'Vessel name'),
                    ColumnFactory.standardColumn('destinationPortDisplayName', 'Destination Port'),
                    ColumnFactory.standardColumn('deliveryLocationDisplayName', 'Delivery Location'),
                    ColumnFactory.standardColumn('atdDisplayName', 'Actual delivery date'),
                    ColumnFactory.standardColumn('emptyReturnDateDisplayName', 'Empty return date'),
                    ColumnFactory.standardColumn('carrierNameDisplayName', 'Carrier'),
                    ColumnFactory.standardColumn('detentionContractIdDisplayName', 'Contract #'),
                    ColumnFactory.standardColumn('detentionFreeTimePeriodDisplayName', 'Freetime period'),
                    ColumnFactory.standardColumn('firstChargeableDayDetentionDisplayName', 'First chargeable day'),
                    ColumnFactory.standardColumn('gateOutPortDateDisplayName', 'Gate out date'),
                    ColumnFactory.standardColumn('numberOfDaysDetentionDisplayName', 'Days applicable'),
                    ColumnFactory.standardColumn('detentionResponsibleDisplayName', 'Responsible'),
                    ColumnFactory.standardColumn('detentionReasonCodeDisplayName', 'Reason code'),
                ]}
                withCheckboxes={true}
                selectVariant="single"
                reference={dataTable}
                tableName="DetentionList"
                actions={{
                    primaryActionIsMoreBtn: false,
                    primaryActionLabel: 'Export to excel',
                    primaryAction: exportToExcel,
                    parentContextualActions: getArrayOfButtons(
                        props.permissions,
                        [
                            { isMoreBtn: false, label: 'Update D&D details', action: toggleEditDnDModal }
                        ])
                }}
                manualPagination={true}
                fetchData={props.fetchEquipments}
                pageCount={props.pageCount}
                showColumnOptions={true}
            />
            {isEditDnDModalOpened && <EditDAndDDetailsPopup
                closeModal={toggleEditDnDModal}
                visible={isEditDnDModalOpened}
                id={getId()}
                refetchFunction={props.refetchFunction}
            />}
        </React.Fragment>
    );
}

const ROLES: BUSINNESS_FUNCTIONS[] = [BUSINNESS_FUNCTIONS.OCEAN_DD_EQUIPMENT_UPDATE_DELIVERY_DETAILS];

const mapDispatchToProps = (dispatch:ThunkDispatch<any, any, Action>): IMapDispatchToProps => ({
    exportDetentionToExcel: () => dispatch(exportDetentionToExcel(false)),
});

const __DetentionTable = connect<undefined, IMapDispatchToProps, IDetentionTableOwnProps, IStore>(undefined, mapDispatchToProps)(_DetentionTable);
export const DetentionTable = withPermissions(__DetentionTable, ROLES) as any;