import React, { Component } from 'react';
import { EditCBLDetailsPopup } from 'modules/tracking/containers/components/EditCBLDetailsPopup';
import { IBLDetailsVessels } from 'reducers/vessels/models';
import { formatValueIfNullOrUndefined, getArrayOfButtons } from 'utilities/util';
import { NotifyCustomerModal } from 'modules/modals/components/NotifyCustomerModal';
import { connect } from 'react-redux';
import { Dispatch } from 'redux';
import { openCBLDetailsModal } from 'actions/vessels';
import { BUSINNESS_FUNCTIONS } from 'constants/roles';
import { PropertiesRibbonWrapper } from 'components/properties-ribbon';
// tslint:disable-next-line: no-submodule-imports
import { withPermissions, IWithPermissions } from 'src/HOC/withPermissions';


interface ICarrierBLDetailsTopBarState {
    isNotifyCustomerPopupOpened: boolean;
}

interface ICarrierBlDetailsProps extends IWithPermissions, IBLDetailsVessels, IDispatch {
    blId: number;
    refreshFunctionForNotify: (blIds: number[]) => Promise<void>;
}

interface IDispatch {
    openCBLDetailsModal: (blId: number) => void;
}

class _CarrierBLDetailsTopBar extends Component<ICarrierBlDetailsProps, ICarrierBLDetailsTopBarState> {
    public state: ICarrierBLDetailsTopBarState = {
        isNotifyCustomerPopupOpened: false
    };

    public render() {
        const actions = [
            {label: 'Update schedule details', action: () => this.props.openCBLDetailsModal(this.props.blId)},
            {label: 'Notify customer', action: this.toggleNotifyCustomerPopup},
        ];
        const propertiesRibbonData = {
            labels: [
                {label: 'Carrier', value: formatValueIfNullOrUndefined(this.props.carrierName)},
                {label: 'Reason code', value: formatValueIfNullOrUndefined(this.props.voyageDelayReasonCode)},
            ],
            actions: getArrayOfButtons(this.props.permissions, actions)
        }

        return(
            <>
                <PropertiesRibbonWrapper content={propertiesRibbonData} />
                <EditCBLDetailsPopup isDetails={true} />
                {this.state.isNotifyCustomerPopupOpened && <NotifyCustomerModal
                    visible={this.state.isNotifyCustomerPopupOpened}
                    closeModal={this.toggleNotifyCustomerPopup}
                    blId={[this.props.blId]}
                    refreshFunction={this.props.refreshFunctionForNotify}
                    isAir={false}
                />}
            </>
        )
    }
    private toggleNotifyCustomerPopup = () => this.setState((state) => ({
        isNotifyCustomerPopupOpened: !state.isNotifyCustomerPopupOpened
    }));
}

const mapDispatchToProps = (dispatch: Dispatch): IDispatch => ({
    openCBLDetailsModal: (blId: number) => dispatch(openCBLDetailsModal(blId)),
});

const ROLES: BUSINNESS_FUNCTIONS[] = [
    BUSINNESS_FUNCTIONS.OCEAN_MBL_TRACKING_EDIT_CBL, 
    BUSINNESS_FUNCTIONS.OCEAN_MBL_TRACKING_NOTIFY_CUSTOMER
];

const _CarrierBLDetailsTopBarConnect = withPermissions<ICarrierBlDetailsProps>(_CarrierBLDetailsTopBar, ROLES);

export const CarrierBLDetailsTopBar = connect(undefined, mapDispatchToProps)(_CarrierBLDetailsTopBarConnect) as any;
