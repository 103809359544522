import React from 'react';
import { ColumnFactory } from 'components/data-table/ColumnFactory';
import { DataTable } from 'components/data-table/DamcoTable';
import { IShipmentETS } from 'reducers/fact/models';
import { StatusRow } from 'components/status-row';
import { cloneDataWithDisplayNames } from 'utilities/tables';

interface IETSTableProps {
    data: IShipmentETS[];
    equipmentId: number;
}

export const ETSTable: React.FunctionComponent<IETSTableProps> = (props) => {
    const data = cloneDataWithDisplayNames(props.data).map(ets => ({
        ...ets,
        equipmentTransportShipmentDocumentNumberDisplayName: [ets.equipmentTransportShipmentDocumentNumber, `/exception-management/fact/equipments/${props.equipmentId}/${ets.id}`],
        anyIssoSentDisplayName: ets.anyIssoSent ? 'Y' : 'N',
        isCancelledDisplayName: ets.isCancelled ? 'Y' : 'N',
    }));

    return(
        <StatusRow title="Equipment transport shipment">
            <DataTable
                data={data}
                columns={[
                    ColumnFactory.linkedColumn('equipmentTransportShipmentDocumentNumberDisplayName', 'ETS document number'),
                    ColumnFactory.standardColumn('anyIssoSentDisplayName', 'Any ISSO sent'),
                    ColumnFactory.standardColumn('createDateDisplayName', 'Create date'),
                    ColumnFactory.standardColumn('lastUpdateDateDisplayName', 'Last update date'),
                    ColumnFactory.linkedColumn('isCancelledDisplayName', 'Is cancelled'),
                    ColumnFactory.standardColumn('equipmentNumberDisplayName', 'Equipment number'),
                    ColumnFactory.standardColumn('equipmentSealNumberDisplayName', 'Equipment seal number'),
                ]}
                withCheckboxes={false}
                reference={null as any}
                tableName="ShipmentDetailsBookingsTable"
                showPagination={true}
                expandable={false}
            />
        </StatusRow>
    );
};

