import React from 'react';
import { ModalScreen } from 'components/modal-screen';
import { ModalType } from 'components/modal-screen/ModalType';
import { FormInput } from 'components/input/FormInput';
import { InjectedFormProps, reduxForm, reset } from 'redux-form';
import { Dispatch } from 'redux';
import { DoubleGrid } from 'components/grid/double-grid';
import { Dialog } from 'components/dialog';
import { ICarrier } from 'views/exception-management/settings/container-tracking/containers/Carriers';
import { SingleGrid } from 'components/grid/single-grid';
import { FormTextArea } from 'components/textarea/FormTextArea';
import { email, required } from 'utilities/validate';

interface IEditCarrerModalOwnProps {
    visible: boolean;
    closeModal: () => void;
    saveFunction: (carrier: ICarrier) => void;
    error: string;
    resetError: () => void;
}

interface IEditCarrierModal extends IEditCarrerModalOwnProps, InjectedFormProps<{}, IEditCarrerModalOwnProps> {}

const FORM_NAME = 'EDIT_CARRIER_MODAL';

const _EditCarrierModal = (props: IEditCarrierModal) => {
    return (
        <form onSubmit={props.handleSubmit(props.saveFunction)}>
            <ModalScreen
                title="Edit carrier"
                visible={props.visible}
                modalType={ModalType.s()}
                closeModal={props.closeModal}
                primaryButtonTitle="Save"
                primaryButtonFunc={() => null}
                primaryButtonType="submit"
                secondaryButtonTitle="Cancel"
                secondaryButtonFunc={props.closeModal}
            >
                {props.error && props.resetError && (
                    <Dialog
                        message={props.error}
                        dialogType={500}
                        isVisible={!!props.error}
                        closeDialog={props.resetError}
                    />
                )}
                <DoubleGrid>
                    <FormInput type="email" label="Email" name="email" validate={[email, required]} />
                </DoubleGrid>
                <SingleGrid>
                    <FormInput label="Subject template" name="subjectTemplate" validate={[required]} />
                </SingleGrid>
                <SingleGrid>
                    <FormTextArea label="Body template" name="bodyTemplate" validate={[required]} />
                </SingleGrid>
                <DoubleGrid>
                    <FormInput label="Carrier code" name="carrierCode" validate={[required]} />
                    <FormInput label="Carrier name" name="carrierName" validate={[required]} />
                </DoubleGrid>
                <DoubleGrid>
                    <FormInput label="Destination port code" name="destinationPortCode" validate={[required]} />
                    <FormInput label="Destination port name" name="destinationPortName" validate={[required]} />
                </DoubleGrid>
            </ModalScreen>
        </form>
    );
};

const afterSubmit = (_: any, dispatch: Dispatch) => dispatch(reset(FORM_NAME));

export const EditCarrierModal = reduxForm<ICarrier, IEditCarrerModalOwnProps>({
    form: FORM_NAME,
    onSubmitSuccess: afterSubmit,
    enableReinitialize: true,
})(_EditCarrierModal);
