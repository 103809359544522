import * as React from 'react';
import { withRouter, RouteComponentProps } from 'react-router';
import { ILink } from '../sub-navigation/models/Link';
import { HorizontalTab } from 'components/sub-navigation/components/HorizontalTab';

interface ISubNavigationProps {
    links: ILink[];
}

const _SubNavigation = (props: RouteComponentProps<{}> & ISubNavigationProps) => {
    const navigationLinks = props.links.map((link: ILink, index: number) => {
        const onClick = () => props.history.push(link.to);

        const splittedPathname = props.location.pathname.split('/');
        const splittedLink = link.to.split('/');
        const capabilityFromUrl = splittedPathname[splittedPathname.length - 2];
        const capabilityFromLink = splittedLink[splittedLink.length - 1];

        return (
            <HorizontalTab
                link={link}
                index={index}
                onClick={onClick}
                key={index}
                active={capabilityFromUrl === capabilityFromLink}
            />
        );
    });
    return (
        <>
            <div style={{ marginLeft: '1rem' }}>
                <div className="dam-tabs-horizontal">
                    {navigationLinks}
                </div>
            </div>
            <div className="dam-tabs-horizontal">
                <input id="tab-one" name="grp1" defaultChecked={true} type="radio"/>
                <label htmlFor="tab-one" style={{ display: 'none' }}>Fake button</label>
                <div className="tab-content" />
            </div>
        </>
    )
};

export const SubNavigationSettings = withRouter(_SubNavigation);
