import React, { FunctionComponent, useState, useRef, useEffect } from 'react';
import { ColumnFactory } from 'components/data-table/ColumnFactory';
import { connect } from 'react-redux';
import { ThunkDispatch } from 'redux-thunk';
import { Action } from 'redux';
import { exportEquipmentPlanningToExcel, exportTransportDetailsToExcel } from 'actions/export';
import { DataTable } from 'components/data-table/DamcoTable';
import { downloadFile } from 'utilities/download';
import { EditDeliveryDetailsModal } from 'modules/modals/components/EditDevlieryDetailsModal';
import { IEquipmentListItem, IEquipmentDetails } from 'reducers/planning/models';
import { getHAWBList, getHAWBDetails } from 'actions/planning';
import { EditTransportationDetailsModal } from 'views/planning/deliver-planning-container-details/components/EditTransportationDetailsModal';
import { IStore } from 'reducers/index';
import { SendTransportOrderToHaulierModal } from 'modules/modals/components/SendTransportOrderToHaulierModal';
import { Transport_Order_Type, Transport_Mode, Transport_Type } from 'constants/transport';
import { mapStatusToStringForDP } from 'constants/statuses';
import { uniqWith, isEqual } from 'lodash';
// tslint:disable-next-line: no-submodule-imports
import { IWithPermissions, withPermissions } from 'src/HOC/withPermissions';
import { getArrayOfButtons, sendFileAsBase64 } from 'utilities/util';
import { BUSINNESS_FUNCTIONS } from 'constants/roles';
import { Dialog } from 'components/dialog';
import { ModalImport } from 'components/modal-import/ModalImport';
import { IImportErrors } from 'reducers/import/models';
import { importFinalDeliveryDetails, importTransportDetails } from 'actions/import';

interface IMapStateToProps {
    hawbDetails: IEquipmentDetails;
    errors: IImportErrors[];
}

interface IMapDispatchToProps {
    exportEquipmentPlanningToExcel: (isAir: boolean, downloadForReImport?: boolean, ids?: number[]) => Promise<void>;
    exportTransportDetailsToExcel: (isAir: boolean, onlyTemplate: boolean, ids?: number[]) => Promise<void>;
    getHAWBList: () => Promise<void>;
    getHAWBDetails: (id: number) => Promise<void>;
    importFinalDeliveryDetails: (isAir: boolean, file: File) => Promise<void>;
    importTransportDetails: (isAir: boolean, file: File) => Promise<void>;
}

interface IHAWBTableOwnProps {
    data: any[];
    pageCount: number;
    fetchEquipments: any;
}

interface IHAWBTable extends IWithPermissions, IHAWBTableOwnProps, IMapStateToProps, IMapDispatchToProps {}

interface IHAWBTableState {
    isEditFinalDeliveryDetailsOpened?: boolean;
    isEditTransportDetailsOpened?: boolean;
    isSendTransportOrderOpened?: boolean;
    isErrorDialogOpened?: boolean;
    importModalIsOpened?: boolean;
}

const _HAWBTable: FunctionComponent<IHAWBTable> = (props) => {
    const [modals, setModals] = useState<IHAWBTableState>({
        isEditFinalDeliveryDetailsOpened: false,
        isEditTransportDetailsOpened: false,
        isSendTransportOrderOpened: false,
        isErrorDialogOpened: false,
        importModalIsOpened: false
    });
    const [errorMessage, setErrorMessage] = useState<string>('Some error occured');
    const [transportOrderName, setTransportOrderName] = useState<string>('');

    const dataTable = useRef<any>();

    useEffect(() => setModals({isErrorDialogOpened: false}), [props.data]);

    const exportToExcel = async () : Promise<void> => {
        const res = await props.exportEquipmentPlanningToExcel(true);
        await downloadFile(res);
    };

    const getIds = (): number[] => {
        const selectedItems = dataTable.current ? dataTable.current.getSelectedItems() : [];
        
        return selectedItems.map((item: IEquipmentListItem) => item.id);
    };

    const toggleEditDeliveryDetailsModal = (): void => setModals({isEditFinalDeliveryDetailsOpened: !modals.isEditFinalDeliveryDetailsOpened});

    const toggleEditTransportModal = async (): Promise<void> => {
        const selectedItems = dataTable.current ? dataTable.current.getSelectedItems() : [];

        if(!modals.isEditTransportDetailsOpened && selectedItems.length === 1) {
            await props.getHAWBDetails(selectedItems[0].id);
        }
        setModals({isEditTransportDetailsOpened: !modals.isEditTransportDetailsOpened});
    };

    const toggleSendTransportOrderModal = (transportOrderType: Transport_Order_Type = Transport_Order_Type.Trucking): void => {
        setModals({isSendTransportOrderOpened: !modals.isSendTransportOrderOpened});

        const transportName = () => {
            if(Object.entries(Transport_Order_Type)[transportOrderType]) {
                return Object.entries(Transport_Order_Type)[transportOrderType][1];
            }
            return '';
        };
        setTransportOrderName(transportName() as string);
    }

    const getInitialValues = (): object => ({
        documentsPARNRequired: props.hawbDetails.documentsPARNRequired,
        documentsCRMRequired: props.hawbDetails.documentsCMRRequired,
        isDangerousGoods: props.hawbDetails.isDangerousGoods,
        dangerousGoodsUNNO: props.hawbDetails.dangerousGoodsUNNO,
        dangerousGoodsClass: props.hawbDetails.dangerousGoodsClass,
        dangerousGoodsDescription: props.hawbDetails.dangerousGoodsDescription,
        isDangerousGoodsADR: props.hawbDetails.dangerousGoodsADRRequired,
        comments: props.hawbDetails.nonConformanceComment,
        hbLs: props.hawbDetails.hbLs,
    });

    const disableIfDeliveriesNotEqual = () => {
        const selectedItems = dataTable.current ? dataTable.current.getSelectedItems() : [];

        return uniqWith(selectedItems.map((selectedItem: any) => selectedItem.deliveries.map((delivery: any) => {
            return {
                deliveryComments: selectedItem.deliveryComments,
                deliveryReference: selectedItem.deliveryReference,
                dropOffDate: selectedItem.dropOffDate,
                dropOffLocation: selectedItem.dropOffLocation,
                estimatedDropOffDate: selectedItem.estimatedDropOffDate,
                estimatedPickupDate: selectedItem.estimatedPickupDate,
                haulier: selectedItem.haulier,
                isTransportOrderSent: selectedItem.isTransportOrderSent,
                pickupDate: selectedItem.pickupDate,
                pickupLocation: selectedItem.pickupLocation,
                transportMode: selectedItem.transportMode,
                transportType: selectedItem.transportType
            };
        })), isEqual);
    };

    const toggleErrorDialog = (): void => setModals({isErrorDialogOpened: !modals.isErrorDialogOpened});

    const checkIfCanEditTransportationDetails = (): void => {
        if(disableIfDeliveriesNotEqual().length === 1) {
            setModals({ isErrorDialogOpened: false });
            toggleEditTransportModal();
        } else {
            setModals({isErrorDialogOpened: true});
            setErrorMessage('Deliveries are not equal');
        }
    };

    const exportToExcelForSelectedItems = async (downloadForReImport: boolean): Promise<void> => {
        const selectedItems = dataTable.current ? dataTable.current.getSelectedItems() : [];
        const ids = selectedItems.map((selectedItem: any) => selectedItem.id);
        const res = await props.exportEquipmentPlanningToExcel(true, downloadForReImport, ids);
        await downloadFile(res);
    };

    const exportToExcelTransportDetails = async (): Promise<void> => {
        const selectedItems = dataTable.current ? dataTable.current.getSelectedItems() : [];
        const ids = selectedItems.map((selectedItem: any) => selectedItem.id);
        const res = await props.exportTransportDetailsToExcel(true, false, ids);
        downloadFile(res);
    };

    const downloadExcelTemplate = async (): Promise<void> => {
        const res = await props.exportEquipmentPlanningToExcel(true, true, [0]);
        await downloadFile(res);
    };

    const toggleImportModal = () => setModals({importModalIsOpened: !modals.importModalIsOpened});

    const uploadFile = async (ref: any): Promise<void> => {
        await sendFileAsBase64(ref, async (val: File) => {
            await props.importFinalDeliveryDetails(true, val);
            await ref.current.uploadComplete();
        });
    };

    const uploadTransportDetails = async (ref: any): Promise<void> => {
        sendFileAsBase64(ref, async (val: File) => {
            await props.importTransportDetails(true, val);
            await ref.current.uploadComplete();
        });
    };

    const downloadTransportDetailsTemplate = async (): Promise<void> => {
        const res = await props.exportTransportDetailsToExcel(true, true);
        await downloadFile(res);
    };

    const data = props.data.map((obj: any) => ({
        ...obj,
        HAWBNumberDisplayName: [obj.HAWBNumber, `/planning/hawb/${obj.id}`],
        deliveryStatusDisplayName: mapStatusToStringForDP(obj.deliveryStatus),
        children: obj.deliveries.map((child: any) => ({
            ...child,
            transportModeDisplayName: Transport_Mode[child.transportMode],
            transportTypeDisplayName: Transport_Type[child.transportType],
        }))
    }));

    return (
        <React.Fragment>
            {modals.isErrorDialogOpened && <Dialog 
                isVisible={modals.isErrorDialogOpened}
                message={errorMessage} 
                closeDialog={toggleErrorDialog}
            />}
            <DataTable
                data={data}
                expandable={true}
                withCheckboxes={true}
                selectVariant="multiple"
                columns={[
                    ColumnFactory.linkedColumn('HAWBNumberDisplayName', 'HAWB Number'),
                    ColumnFactory.standardColumn('chargeableWeightDisplayName', 'Chargeable weight'),
                    ColumnFactory.standardColumn('isDangerousGoodsDisplayName', 'DG'),
                    ColumnFactory.standardColumn('newETADisplayName', 'New ETA'),
                    ColumnFactory.standardColumn('ataDisplayName', 'ATA'),
                    ColumnFactory.standardColumn('availableForBookingDisplayName', 'Available For Booking'),
                    ColumnFactory.standardColumn('deliveryLocationDisplayName', 'Delivery location'),
                    ColumnFactory.standardColumn('clientDeliveryDisplayName', 'Delivery date'),
                    ColumnFactory.standardColumn('deliveryStatusDisplayName', 'Status'),
                ]}
                subColumns={[
                    ColumnFactory.standardColumn('transportModeDisplayName', 'Transport Mode'),
                    ColumnFactory.standardColumn('transportTypeDisplayName', 'Transport Type'),
                    ColumnFactory.standardColumn('haulierDisplayName', 'Haulier'),
                    ColumnFactory.standardColumn('isTransportOrderSentDisplayName', 'TO Sent'),
                    ColumnFactory.standardColumn('pickupLocationDisplayName', 'Pickup location'),
                    ColumnFactory.standardColumn('estimatedPickupDateDisplayName', 'Pickup date'),
                    ColumnFactory.standardColumn('dropOffLocationDisplayName', 'Drop-off location'),
                    ColumnFactory.standardColumn('estimatedDropOffDateDisplayName', 'Drop-off date'),
                    ColumnFactory.standardColumn('deliveryReferenceDisplayName', 'Delivery reference'),
                ]}
                actions={{
                    primaryActionIsMoreBtn: false,
                    primaryActionLabel: 'Export to excel',
                    primaryAction: exportToExcel,
                    secondaryActions: [
                        {isMoreBtn: false, label: 'Import from excel', action: toggleImportModal},
                    ],
                    parentContextualActions: getArrayOfButtons(
                        props.permissions,
                        [
                            { isMoreBtn: false, label: 'Update final delivery details', action: toggleEditDeliveryDetailsModal },
                            { isMoreBtn: false, label: 'Update transport details', action: checkIfCanEditTransportationDetails },
                            { isMoreBtn: true, moreBtnActions: [
                                { label: 'Send trucking transport order', action: () => toggleSendTransportOrderModal(Transport_Order_Type.Trucking)},
                                { label: 'Send De-Rig Transport Order', action: () => toggleSendTransportOrderModal(Transport_Order_Type.DeRig)},
                                { label: 'Send Dark Storage Transport Order', action: () => toggleSendTransportOrderModal(Transport_Order_Type.DarkStorage)},
                                { label: 'Send Rail Transport Order', action: () => toggleSendTransportOrderModal(Transport_Order_Type.Rail)},
                                { label: 'Send Barge Transport Order', action: () => toggleSendTransportOrderModal(Transport_Order_Type.Barge)},
                                { label: 'Export selected', action: () => exportToExcelForSelectedItems(false) },
                                { label: 'Export for update final delivery details', action: () => exportToExcelForSelectedItems(true) },
                                { label: 'Export for update transport details', action: () => exportToExcelTransportDetails()},
                            ]}
                        ])
                }}
                reference={dataTable}
                tableName="HAWBPlanningTable"
                manualPagination={true}
                fetchData={props.fetchEquipments}
                pageCount={props.pageCount}
                showColumnOptions={true}
            />
            {modals.isEditFinalDeliveryDetailsOpened && <EditDeliveryDetailsModal
                visible={modals.isEditFinalDeliveryDetailsOpened}
                closeModal={toggleEditDeliveryDetailsModal}
                ids={getIds()}
                refetchFunction={props.getHAWBList}
                isAir={true}
                isFinal={true}
                equipments={dataTable.current ? dataTable.current.getSelectedItems() : []}
            />}
            {modals.isEditTransportDetailsOpened && <EditTransportationDetailsModal
                visible={modals.isEditTransportDetailsOpened}
                closeModal={toggleEditTransportModal}
                ids={getIds()}
                isAir={true}
                initialValues={getInitialValues()}
            />}
            {modals.isSendTransportOrderOpened && <SendTransportOrderToHaulierModal
                visible={modals.isSendTransportOrderOpened}
                closeModal={toggleSendTransportOrderModal}
                transportConfig={Transport_Order_Type.Trucking}
                transportOrderName={transportOrderName}
                id={getIds()}
                isAir={true}
            />}
            {modals.importModalIsOpened && <ModalImport
                uploadFinalDeliveryDetails={uploadFile}
                uploadTransportDetails={uploadTransportDetails}
                cancelMethod={() => null}
                downloadFinalDeliveryDetails={downloadExcelTemplate}
                downloadTransportDetails={downloadTransportDetailsTemplate}
                closeModal={toggleImportModal}
                fileFormats=".xlsx"
                downloadTitle="Download template"
                visible={modals.importModalIsOpened || false}
                errors={props.errors}
            />}
        </React.Fragment>
    );
}

const ROLES: BUSINNESS_FUNCTIONS[] = [
    BUSINNESS_FUNCTIONS.AIR_PLANNING_EDIT_DELIVERY_DETAILS,
    BUSINNESS_FUNCTIONS.AIR_PLANNING_EDIT_TRANSPORTATION_DETAILS,
    BUSINNESS_FUNCTIONS.AIR_PLANNING_SEND_TRANSPORT_ORDER
];

const mapStateToProps = (state: IStore): IMapStateToProps => ({
    hawbDetails: state.planning.hawbDetails,
    errors: state.importReducer.importPlanningOceanErrors
});

const mapDispatchToProps = (dispatch: ThunkDispatch<any, any, Action>): IMapDispatchToProps => ({
    exportEquipmentPlanningToExcel: (isAir: boolean, downloadForReImport: boolean = false, ids?: number[]) => dispatch(exportEquipmentPlanningToExcel(isAir, downloadForReImport, ids)),
    exportTransportDetailsToExcel: (isAir: boolean, onlyTemplate: boolean, ids?: number[]) => dispatch(exportTransportDetailsToExcel(isAir, onlyTemplate, ids)),
    getHAWBList: () => dispatch(getHAWBList()),
    getHAWBDetails: (id: number) => dispatch(getHAWBDetails(id)),
    importFinalDeliveryDetails: (isAir: boolean, file: File) => dispatch(importFinalDeliveryDetails(isAir, file)),
    importTransportDetails: (isAir: boolean, file: File) => dispatch(importTransportDetails(isAir, file)),
});

const __HAWBTable = connect<IMapStateToProps, IMapDispatchToProps, IHAWBTableOwnProps, IStore>(mapStateToProps, mapDispatchToProps)(_HAWBTable);
export const HAWBTable = withPermissions(__HAWBTable, ROLES) as any;
