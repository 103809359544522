import React, { useState, useRef } from 'react';
import DatePicker  from 'react-datepicker';
import moment, { Moment } from 'moment';
import { connect } from 'react-redux';
import { Dispatch } from 'redux';
import { change } from 'redux-form';
import styled from 'styled-components';
import { SErrorMessage } from 'components/input';
import { setFilter, setEmptyDateFlagForETD } from 'actions/filters';
import { IStore } from 'reducers/index';

export interface IDateInputProps {
    id: string,
    name: string,
    label?: string,
    withTime?: boolean;
    placeholder?: string;
    isEndOfDay?: boolean;
    relatedField?: string;
    clearInput(value: string): void;
}

const SDateInput = styled.div`
    .react-datepicker__time-list {
        padding-left: 0;
    }
    .input-btn-wrapper .react-datepicker .react-datepicker__navigation {
        color: transparent;
    }
    .input-btn-wrapper .react-datepicker .react-datepicker__navigation::before {
        color: white;
    }
`;

const _DateInput: React.FunctionComponent<any> = (props) => {
    const [isCalendarVisible, setCalendarVisibility] = useState<boolean>(false);
    const inputRef = useRef<any>();
    const buttonRef = useRef<any>();

    const dateFormat = props.withTime ? 'DD-MM-YYYY HH:mm' : 'DD-MM-YYYY';
    // const emptyDateFormat = props.withTime ? '0001-01-01T00:00:00.000Z' : '0001-01-01';

    const createDate = (value: Moment | string, format?: string): Moment => {
        const newDate = moment.utc(value, format);
        if(props.isEndOfDay) {
            return newDate.endOf('day');
        } else {
            return newDate;
        }
    }

    const selectValue = (): Moment | undefined => {
        if(props.input && props.input.value) {
            return moment.utc(props.input.value);
        } else if(props.value) {
            return  moment.utc(props.value);
        }
        return undefined;
    };

    const onChange = (value: Moment): void => {
        if(props.meta) {
            const { form } = props.meta;
            const field = props.input.name;
            setCalendarVisibility(false);

            if(value && value.isValid()) {
                props.changeDate(form, field, createDate(value));
            }
        } else if(props.onChange && value && value.isValid()) {
            props.onChange(createDate(value));
        }
    };

    const onBlur = (value: Moment | string): void => {
        if(!value || value.toString().length !== dateFormat.length || !moment(value, dateFormat).isValid()) {
            clearInput();
        } else if(props.meta) {
            const { form } = props.meta;
            const field = props.input.name;
            setCalendarVisibility(false);
            props.changeDate(form, field, createDate(value, dateFormat));
        } else if(props.onChange) {
            props.onChange(createDate(value, dateFormat));
        }
    };

    const toggleCalendar = (event: any): void => {
        // TODO: this is definitely a wrong way to get input ref, this needs fixing
        const dateField = event.currentTarget.parentNode.firstChild.firstChild.firstChild;
        setCalendarVisibility(!isCalendarVisible);
        if (dateField) {
            if(isCalendarVisible) {
                dateField.focus();
                props.setEmptyDateFlag(props.relatedField, false);
            } else {
                dateField.blur()
            }
        }
    };

    const clearInput = (): void => {
        if(props.meta) {
            const { form } = props.meta;
            const field = props.input.name;
            setCalendarVisibility(false);
            props.changeDate(form, field, '');
        } else if(props.onChange) {
            props.onChange(undefined);
        }
    };

    const setNoDate = (): void => {
        props.onChange(null)
        props.setEmptyDateFlag(props.relatedField);
    };

    const setValue = (): string | undefined => {
        if(props.readOnly && props.relatedField) {
            return 'Empty date'
        } else {
            return undefined;
        }
    };

    return (
        <SDateInput>
            {props.label && <label htmlFor="exampleInputEmail1" className="filter-label">{props.label}</label>}
            <div className="input-btn-wrapper">
                <DatePicker
                    id={props.id}
                    name={props.name || props.input.name}
                    selected={selectValue()}
                    onChange={onChange}
                    onChangeRaw={props.onChangeRaw}
                    onBlur={(e: any) => e && e.target && onBlur(e.target.value)}
                    locale="en-GB"
                    className="form-control datepicker"
                    popperPlacement="bottom-end"
                    placeholderText={props.placeholder ? props.placeholder : dateFormat}
                    disabled={!!props.readOnly}
                    showTimeSelect={props.withTime}
                    timeFormat="HH:mm"
                    timeIntervals={15}
                    dateFormat={dateFormat}
                    utcOffset={0}
                    ref={inputRef}
                    value={setValue()}
                >
                    <div className="date-picker-footer-container">
                        <a
                            href="javascript:void(0)"
                            className="date-picker-footer"
                            onClick={clearInput}
                            id="clear-date"
                        >
                            Clear
                        </a>
                        {props.relatedField && <a
                            href="javascript:void(0)"
                            className="date-picker-footer"
                            onClick={setNoDate}
                            id="clear-date"
                            style={{textAlign: 'right'}}
                        >
                            Empty date
                        </a>}
                    </div>
                </DatePicker>
                <button
                    type="button"
                    className="select-btn"
                    style={{position: 'relative', zIndex: 1}}
                    onClick={toggleCalendar}
                    ref={buttonRef}
                >
                    <span className="fa fa-calendar"/>
                </button>
            </div>
            {props.meta && props.meta.touched && props.meta.error && <SErrorMessage>{props.meta.error}</SErrorMessage>}
        </SDateInput>
    )
}

const mapDispatchToProps = (dispatch: Dispatch) => ({
    changeDate: (form: string, field: string, value: string) => dispatch(change(form, field, value)),
    setFilter: (name: string, value: string | boolean | any[] | number) => dispatch(setFilter(name, value)),
    setEmptyDateFlag: (name: string, value: boolean = true) => dispatch(setEmptyDateFlagForETD(name, value)),
});

export const DateInput = connect<undefined, any, any, IStore>(undefined, mapDispatchToProps)(_DateInput);
