import React from 'react';
import { StatusRow } from 'components/status-row';
import { OneStatus } from 'components/status-row/OneStatus';
import { QuarterGrid } from 'components/grid/quarter-grid';
import { formatValueIfNullOrUndefined } from 'utilities/util';

interface ICarrierRowProps {
    carrierBookingReference: string;
    carrierBusinessEntityCode: string;
    carrierCode: string;
    carrierConutryCode: string;
    carrierFACTCode: string;
    carrierName: string;
}

export const CarrierRow: React.StatelessComponent<ICarrierRowProps> = (props) => {
    return (
        <StatusRow title="Carrier">
            <QuarterGrid>
            <OneStatus label="Carrier name">
                    {formatValueIfNullOrUndefined(props.carrierName)}
                </OneStatus>
                <OneStatus label="Carrier booking reference">
                    {formatValueIfNullOrUndefined(props.carrierBookingReference)}
                </OneStatus>
                <OneStatus label="Carrier business entity code">
                    {formatValueIfNullOrUndefined(props.carrierBusinessEntityCode)}
                </OneStatus>
                <OneStatus label="Carrier code">
                    {formatValueIfNullOrUndefined(props.carrierCode)}
                </OneStatus>
            </QuarterGrid>
            <QuarterGrid>
                <OneStatus label="Carrier country code">
                    {formatValueIfNullOrUndefined(props.carrierConutryCode)}
                </OneStatus>
                <OneStatus label="Carrier FACT code">
                    {formatValueIfNullOrUndefined(props.carrierFACTCode)}
                </OneStatus>
            </QuarterGrid>
        </StatusRow>
    );
};
