import React from 'react';
import styled from 'styled-components';

const RedBadge = styled.span`
    padding: 2px 5px;
    background: #D62D23;
    border-radius: 3px;
    font-weight: 600;
    color: #fff;
    font-size: 13px;
    margin-right: 10px;
`;

const GreenBadge = styled.span`
    padding: 2px 5px;
    background: #2DBA5E;
    border-radius: 3px;
    font-weight: 600;
    color: #fff;
    font-size: 13px;
    margin-right: 10px;
`;

interface IChangeLogBadgeProps {
    type: 'bug' | 'feature';
}

export const ChangeLogBadge: React.StatelessComponent<IChangeLogBadgeProps> = (props) => {
    return props.type === 'bug' ? <RedBadge>bug</RedBadge> : <GreenBadge>feature</GreenBadge>;
};