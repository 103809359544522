import React from 'react';
import { ColumnFactory } from 'components/data-table/ColumnFactory';
import { LoadingAnimation } from 'components/loading';
import { IGlobalSettings } from 'reducers/settings/models';
import { DataTable } from 'components/data-table/DamcoTable';
import { EditGlobalSettingsModal } from 'modules/settings/shared/EditGlobalSettingsModal';

interface IConfigurationDataProps {
    isLoading: boolean;
    data: IGlobalSettings[];
    updateSettings: (configuration: IGlobalSettings) => void;
    refreshSettings: () => void;
}

interface IConfigurationTableState {
    isEditConfigurationModalOpened: boolean;
    editRowKey: string | null;
    editedItem: any;
}

export class GlobalsTable extends React.Component<IConfigurationDataProps, IConfigurationTableState> {
    public dataTable = React.createRef<any>();

    public state: IConfigurationTableState = {
        isEditConfigurationModalOpened: false,
        editRowKey: null,
        editedItem: null,
    };

    public render() {
        return (
            <React.Fragment>
                {this.props.isLoading && <LoadingAnimation />}
                <DataTable
                    data={this.props.data}
                    expandable={false}
                    withCheckboxes={true}
                    selectVariant="single"
                    columns={[
                        ColumnFactory.standardColumn('fieldName', 'Key'),
                        ColumnFactory.standardColumn('value', 'Value')
                    ]}
                    actions={{
                        parentContextualActions: [
                            { isMoreBtn: false, label: 'Edit', action: this.toggleEditModal },
                        ]
                    }}
                    reference={this.dataTable}
                    tableName="SettingsGlobalsTable"
                    showPagination={true}
                />
                {this.state.isEditConfigurationModalOpened && <EditGlobalSettingsModal
                    title="Edit settings"
                    visible={this.state.isEditConfigurationModalOpened}
                    editedItem={this.state.editedItem}
                    closeModal={this.toggleEditModal}
                    saveFunction={this.updateSettings}
                    initialValues={this.getInitialValues()}
                />}
            </React.Fragment>
        );
    }

    private toggleEditModal = (key?: string) => {
        const selectedItems = this.dataTable.current ? this.dataTable.current.getSelectedItems() : [];

        if (selectedItems.length !== 0) {
            const rowToEdit = selectedItems[0];

            this.setState((state: IConfigurationTableState) => ({
                isEditConfigurationModalOpened: !state.isEditConfigurationModalOpened,
                editRowKey: key || null,
                editedItem: rowToEdit ? rowToEdit : null
            }))
        }
    };

    private updateSettings = async (configuration: IGlobalSettings) => {
        await this.props.updateSettings(configuration);
        await this.toggleEditModal();
        await this.props.refreshSettings();
    };

    private getInitialValues = () => {
        const selectedItems = this.dataTable.current ? this.dataTable.current.getSelectedItems() : [];

        if(selectedItems.length > 0) {
            return {
                Key: selectedItems[0].key,
                OptionName: selectedItems[0].fieldName,
                Value: selectedItems[0].value
            }
        }
        return;
    };
}
