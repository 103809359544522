import React from 'react';
import { ColumnFactory } from 'components/data-table/ColumnFactory';
import { DataTable } from 'components/data-table/DamcoTable';
import { StatusRow } from 'components/status-row';
import { cloneDataWithDisplayNames } from 'utilities/tables';
import { IEquipmentTransportShipment, IIsso } from 'reducers/fact/models';

interface ITransportShipmentsTableProps {
    ets: IEquipmentTransportShipment[];
    equipmentId: number;
}

export const TransportShipmentsTable: React.FunctionComponent<ITransportShipmentsTableProps> = (props) => {
    const data = cloneDataWithDisplayNames(props.ets, 'DD-MM-YYYY, h:mm').map(ets => ({
        ...ets,
        equipmentTransportShipmentDocumentNumberDisplayName: [ets.equipmentTransportShipmentDocumentNumber, `/exception-management/fact/equipments/${props.equipmentId}/${ets.equipmentTransportShipmentId}`],
        anyIssoSentDisplayName: ets.anyIssoSent ? 'Y' : 'N',
        isCancelledDisplayName: ets.isCancelled ? 'Y' : 'N',
        children: ets.issos.map((isso: IIsso) => ({
            ...isso,
            financialChangeIndicatorDisplayName: isso.financialChangeIndicator ? 'Y' : 'N',
            isSentDisplayName: isso.isSent ? 'Y' : 'N',
        }))
    }));

    return(
        <StatusRow title="Equipment Transport Shipments">
            <DataTable
                data={data}
                columns={[
                    ColumnFactory.linkedColumn('equipmentTransportShipmentDocumentNumberDisplayName', 'Equipment transport shipment document number'),
                    ColumnFactory.standardColumn('anyIssoSentDisplayName', 'Any ISSO sent'),
                    ColumnFactory.standardColumn('isCancelledDisplayName', 'Is cancelled'),
                    ColumnFactory.standardColumn('createDateDisplayName', 'Create date'),
                    ColumnFactory.standardColumn('lastUpdateDateDisplayName', 'Last update date'),
                    ColumnFactory.standardColumn('shipmentNumberDisplayName', 'Shipment number'),
                    ColumnFactory.standardColumn('etsOnETVolumeFactor', 'ETS on ET volume factor'),
                ]}
                subColumns={[
                    ColumnFactory.standardColumn('issoNumberDisplayName', 'ISSO number'),
                    ColumnFactory.standardColumn('messageTypeDisplayName', 'Message type'),
                    ColumnFactory.standardColumn('isSentDisplayName', 'Is sent'),
                    ColumnFactory.standardColumn('generationDateDisplayName', 'Generation date'),
                    ColumnFactory.standardColumn('changedFinancialFieldsDisplayName', 'Changed financial fields'),
                    ColumnFactory.standardColumn('financialChangeIndicatorDisplayName', 'Financial change indicator'),
                    ColumnFactory.standardColumn('brainResponseStatusCodeDisplayName', 'Status')
                ]}
                withCheckboxes={false}
                reference={null as any}
                tableName="EquipmentDetailsVoyagesTable"
                showPagination={true}
                expandable={true}
            />
        </StatusRow>
    );
};

