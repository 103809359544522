import React, { Component } from 'react';
import { PageHeader } from 'components/page-header';
import { DeliveryAndReturnEquipmentTable } from 'modules/delivery-and-return/ocean/components/DeliveryAndReturnEquipmentTable';
import { Page } from 'components/page-container';
import { FiltersInput } from 'modules/filters/components/FiltersInput';
import { FiltersDateInput } from 'modules/filters/components/FiltersDateInput';
import { FiltersSelect } from 'modules/filters/components/FiltersSelect';
import { FiltersBooleanSelect } from 'modules/filters/components/FiltersBooleanSelect';
import { Filters } from 'modules/filters/containers/Filters';
import { statusesValues } from 'utilities/util';
import { connect } from 'react-redux';
import { ThunkDispatch } from 'redux-thunk';
import { Action } from 'redux';
import { getEmptyReturnEquipments } from 'actions/planning';
import { IStore } from 'reducers/index';
import { IEquipmentEmptyReturnData } from 'reducers/planning/models';
import { DELIVERY_STATUS } from 'constants/statuses';
import { HandleError } from 'components/handle-error';
import { LoadingAnimation } from 'components/loading';
import { setSort, resetSort } from 'actions/filters';
import { ISortModel } from 'reducers/filters/models';
import { setSortForTable } from 'utilities/tables';

interface IDeliveryAndReturnEquipmentList extends IMapStateToProps, IDispatch {
}

interface IMapStateToProps {
    equipments: IEquipmentEmptyReturnData,
    isLoading: boolean;
    isExportLoading: boolean;
    exportEquipmentError: string;
    gateOutDateEmpty: boolean;
    deliveryPlannedDateEmpty: boolean;
    deliveryActualDateEmpty: boolean;
}

interface IDispatch {
    getEmptyReturnEquipments: (pageNumber: number, pageSize: number) => Promise<void>;
    setSort: (sort: ISortModel) => void;
    resetSort: () => void;
}

interface IDeliveryAndReturnEquipmentListState {
    page: number;
    pageSize: number;
}

class _DeliveryAndReturnEquipmentList extends Component<IDeliveryAndReturnEquipmentList, IDeliveryAndReturnEquipmentListState> {
    public state = {
        page: 1,
        pageSize: 10,
    };

    public render() {
        const filters = [
            <FiltersInput
                key="EquipmentNumber"
                name="EquipmentNumber"
                label="Equipment number"
                id="equipmentNumberFilter"
            />,
            <FiltersSelect
                key="DeliveryStatus"
                name="DeliveryStatus"
                label="Status"
                type="number"
                options={statusesValues(DELIVERY_STATUS)}
            />,
            <FiltersInput
                key="LastLocation"
                name="LastLocation"
                label="Last location"
            />,
            <FiltersInput
                type="number"
                key="Detention"
                name="Detention"
                label="Detention"
            />,
            <FiltersBooleanSelect
                type="string"
                key="dg"
                name="IsDangerousGoods"
                label="Dangerous goods"
                falseOptionText="No"
                trueOptionText="Yes"
            />,
            <FiltersBooleanSelect
                type="string"
                key="goh"
                name="IsGOH"
                label="GOH"
                falseOptionText="No"
                trueOptionText="Yes"
            />,
            <FiltersBooleanSelect
                type="string"
                key="IsHotBox"
                name="IsHotBox"
                label="Hotbox"
                falseOptionText="No"
                trueOptionText="Yes"
            />,
            <FiltersInput
                type="number"
                key="Demurrage"
                name="Demurrage"
                label="Demurrage"
            />,
            <FiltersDateInput
                key="GateOutDate_from"
                name="GateOutDate_from"
                label="Gate out (from)"
                type="date"
                relatedField="gateOutDate"
                readOnly={this.props.gateOutDateEmpty}
            />,
            <FiltersDateInput
                key="GateOutDate_to"
                name="GateOutDate_to"
                label="Gate out (to)"
                type="date"
                isEndOfDay={true}
                relatedField="gateOutDate"
                readOnly={this.props.gateOutDateEmpty}
            />,
            <FiltersDateInput
                key="DeliveryPlannedDate_From"
                name="DeliveryPlannedDate_From"
                label="Planned delivery (from)"
                type="date"
                relatedField="deliveryPlannedDate"
                readOnly={this.props.deliveryPlannedDateEmpty}
            />,
            <FiltersDateInput
                key="DeliveryPlannedDate_To"
                name="DeliveryPlannedDate_To"
                label="Planned delivery (to)"
                type="date"
                isEndOfDay={true}
                relatedField="deliveryPlannedDate"
                readOnly={this.props.deliveryPlannedDateEmpty}
            />,
            <FiltersDateInput
                key="DeliveryActualDate_From"
                name="DeliveryActualDate_From"
                label="Actual delivery (from)"
                type="date"
                relatedField="deliveryActualDate"
                readOnly={this.props.deliveryActualDateEmpty}
            />,
            <FiltersDateInput
                key="DeliveryActualDate_To"
                name="DeliveryActualDate_To"
                label="Actual delivery (to)"
                type="date"
                isEndOfDay={true}
                relatedField="deliveryActualDate"
                readOnly={this.props.deliveryActualDateEmpty}
            />,
            <FiltersInput
                key="POD"
                name="podName"
                label="POD"
            />,
            <FiltersInput
                key="PONumber"
                name="PONumber"
                label="PO Number"
            />,
            <FiltersInput
                key="lastUserUpdated"
                name="lastUserUpdated"
                label="Last updated by"
            />,
        ];

        return (
            <>
                <PageHeader>Equipment</PageHeader>
                <Page>
                    {this.props.isExportLoading && <LoadingAnimation />}
                    <HandleError
                        exportEquipmentError={this.props.exportEquipmentError}
                    />
                    <Filters
                        tableNameKey="delivery-equipment"
                        filters={filters}
                        fetchFunction={() => this.props.getEmptyReturnEquipments(this.state.page, this.state.pageSize)}
                    />
                    <DeliveryAndReturnEquipmentTable
                        data={this.props.equipments.data}
                        isLoading={this.props.isLoading}
                        pageCount={Math.ceil(this.props.equipments.totalCount / this.props.equipments.pageSize)}
                        fetchEquipments={this.fetchEquipments}
                    />
                </Page>
            </>
        );
    }

    public componentWillUnmount() {
        this.props.resetSort();
    }

    private fetchEquipments = (tableData: any) => {
        this.setState({
            page: tableData.page + 1,
            pageSize: tableData.pageSize
        }, () => {
            setSortForTable(tableData.sorted, this.props.setSort);
            this.props.getEmptyReturnEquipments(this.state.page, this.state.pageSize);
        });
    };
}

const mapStateToProps = (state: IStore): IMapStateToProps => ({
    equipments: state.planning.emptyReturnEquipments,
    isLoading: state.planning.isGetEmptyReturnEquipmentPending,
    exportEquipmentError: state.exportReducer.exportDeliveryAndReturnEquipmentError,
    isExportLoading: state.exportReducer.isExportDelivAndReturnEquipLoading,
    gateOutDateEmpty: state.filters.filters.gateoutdate_empty,
    deliveryPlannedDateEmpty: state.filters.filters.deliveryplanneddate_empty,
    deliveryActualDateEmpty: state.filters.filters.deliveryactualdate_empty
});

const mapDispatchToProps = (dispatch: ThunkDispatch<any, any, Action>): IDispatch => ({
    getEmptyReturnEquipments: (pageNumber: number, pageSize: number) => dispatch(getEmptyReturnEquipments(pageNumber, pageSize)),
    setSort: (sort: ISortModel) => dispatch(setSort(sort)),
    resetSort: () => dispatch(resetSort()),
});

export const DeliveryAndReturnEquipmentList = connect(mapStateToProps, mapDispatchToProps)(_DeliveryAndReturnEquipmentList);
