import React from 'react';
import { Dialog } from 'components/dialog';

export class HandleError extends React.Component<any> {
    constructor(props: any) {
        super(props);
        this.state = {
            ...this.setInitialState()
        }
    }

    public componentDidUpdate(prevProps: any) {
        Object.keys(prevProps).forEach((key: string) => {
            if(!prevProps[key] && this.props[key] && !this.state[key]) {
                this.toggleDialog(key, true);
            }
        })
    }

    public render() {
        return(
            <>
                {this.renderDialogs()}
            </>
        )

    }

    private renderDialogs = () => {
        return Object.keys(this.state).map((key: string, index) => {
            return(
                <Dialog
                    key={index}
                    closeDialog={() => this.toggleDialog(key, false)}
                    isVisible={this.state[key]}
                    dialogType={500}
                    message="An error occured"
                />
            )
        })
    };

    private toggleDialog = (property: string, value: boolean) => {
        this.setState({
            [property]: value
        })
    };

    private setInitialState = () => {
        let state = {};
        Object.keys(this.props).forEach((key) => {
            state = {
                ...state,
                [key]: false,
            }
        });
        return state;
    }
}
