import * as React from 'react';
import { connect } from 'react-redux';
import { Dispatch } from 'redux';
import { change } from 'redux-form';
import { Select as SelectComponent } from './Select';
import { SErrorMessage } from 'components/input';

export interface IOption {
    value: any;
    optionText: string;
}

export interface ISelect {
    label?: string;
    value?: any;
    options: IOption[];
    disabled?: boolean;
}

const defaultProps = {
    value: '',
};

interface IDispatch {
    changeSelect: (form: string, field: string, value: any) => void;
}

export class _Select extends React.Component<any, any> {
    public static defaultProps = defaultProps;

    public static getDerivedStateFromProps(props: any, state: any) {
        if(props.input && ('value' in props.input) && props.input.value !== state.value) {
            return {
                value: props.input.value,
            };
        }
        return state;
    }

    public state = {
        value: this.props.input ? this.props.input.value : this.props.value
    };

    public componentDidMount() {
        if(this.props.input && (this.props.input.value || this.props.input.value === false || this.props.input.value === 0)) {
            return this.setReduxFormValue(this.props.input.value);
        }
        if(this.props.options.length) {
            this.setReduxFormValue(this.props.options[0].value);
        }
    }

    public render() {
        const { meta } = this.props;
        return(
            <>
                <SelectComponent
                    name={this.props.input ? this.props.input.name || this.props.name : this.props.name}
                    label={this.props.label}
                    options={this.props.options}
                    value={this.state.value}
                    onChange={this.onChange}
                    disabled={!!this.props.disabled}
                />
                {meta && meta.touched && meta.error && <SErrorMessage>{meta.error}</SErrorMessage>}
            </>
        );
    };

    private onChange = (event: any) => {
        this.setReduxFormValue(event.target.value);
        if(this.props.onChange) {
            this.props.onChange(event.target.value);
            this.setState({ value: event.target.value });
        }
    };

    private setReduxFormValue = (value: any): void => {
        if(this.props.meta) {
            const { form } = this.props.meta;
            const field = this.props.input.name;
            
            if(value === 'Select value...') {
                this.props.changeSelect(form, field, null);
                this.setState({ value: null });
            } else {
                this.props.changeSelect(form, field, value);
                this.setState({ value });
            }
        }
    }
}

const mapDispatchToProps = (dispatch: Dispatch): IDispatch => ({
    changeSelect: (form: string, field: string, value: any) => dispatch(change(form, field, value)),
});

export const Select = connect(undefined, mapDispatchToProps)(_Select) as any;
