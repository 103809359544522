import { TYPES } from 'action-types/planning';
import {
    IEquipmentDelivery,
    IEquipmentDetails,
    IEquipmentEmptyReturnData,
    IEquipmentListData,
    IEquipmentEmptyReturnLocationsData,
    IEquipmentDeliveryDetails,
    IHAWBListData,
    IAirDeliveryData,
    IHblWithCustomsData,
    IPoPlanning,
} from 'reducers/planning/models';
import { IReasonCode } from 'reducers/settings/models';
import { cloneDataWithDisplayNames } from 'utilities/tables';

interface IAction {
    readonly type: string;
    readonly equipments: IEquipmentListData;
    readonly hawb: IHAWBListData;
    readonly equipmentDetails: IEquipmentDetails;
    readonly hawbDetails: IEquipmentDetails;
    readonly equipmentDeliveries: IEquipmentDelivery[];
    readonly emptyReturnEquipments: IEquipmentEmptyReturnData;
    readonly locations: IEquipmentEmptyReturnLocationsData;
    readonly error: string;
    readonly reasonCodes: IReasonCode[];
    readonly airDelivery: IAirDeliveryData;
    readonly airDeliveryDetails: IEquipmentDetails;
    readonly equipmentsDeliveryDetails: IEquipmentDeliveryDetails[];
    readonly hblWithCustoms: any;
    readonly poPlanningList: IPoPlanning;
}

export interface IPlanningState {
    isGetEquipmentsPending: boolean;
    getEquipmentsError: string;
    isGetEquipmentDetailsPending: boolean;
    getEquipmentDetailsError: string;
    isAddEquipmentDeliveriesPending: boolean;
    addEquipmentDeliveriesError: string;
    isGetEquipmentDeliveriesPending: boolean;
    getEquipmentDeliveriesError: string;
    isEditEquipmentDeliveriesPending: boolean;
    editEquipmentDeliveriesError: string;
    isDeleteEquipmentDeliveriesPending: boolean;
    deleteEquipmentDeliveriesError: string;
    isDeliveryDetailsPending: boolean;
    deliveryDetailsError: string;
    isUpdateEquipmentDetailsPending: boolean;
    updateEquipmentDetailsError: string;
    isSendTransportOrderToHaulierPending: boolean;
    sendTransportOrderToHaulierError: string;
    isGetEmptyReturnEquipmentPending: boolean;
    getEmptyReturnEquipmentError: string;
    isUpdateEmptyReturnActualsPending: boolean;
    updateEmptyReturnActualsError: string;
    isUpdateEmptyReturnDetailsPending: boolean;
    updateEmptyReturnDetailsError: string;
    isGetEquipmentsLocationPending: boolean;
    getEquipmentsLocationError: string;
    isGetHAWBListPending: boolean;
    getHAWBListError: string;
    isGetHAWBDetailsPending: boolean;
    getHAWBDetailsError: string;
    isGetAirReturnPending: boolean;
    getAirReturnError: string;
    isGetAirDeliveryDetailsPending: boolean;
    getAirDeliveryDetailsError: string;
    equipments: IEquipmentListData;
    hawb: IHAWBListData;
    equipmentDetails: IEquipmentDetails;
    hawbDetails: IEquipmentDetails;
    equipmentDeliveries: IEquipmentDelivery[];
    emptyReturnEquipments: IEquipmentEmptyReturnData;
    sendTransportOrderError: string;
    locations: IEquipmentEmptyReturnLocationsData;
    reasonCodes: IReasonCode[];
    isGetDPReasonCodesPending: boolean;
    getDPReasonCodesError: string;
    airDelivery: IAirDeliveryData;
    isSetAvaialabilityForBookingLoading: boolean;
    setAvaialabilityForBookingError: string;
    airDeliveryDetails: IEquipmentDetails;
    isGetDeliveryDetailsForEquipmentsPending: boolean;
    getDeliveryDetailsForEquipmentsError: string;
    equipmentsDeliveryDetails: IEquipmentDeliveryDetails[];
    hblWithCustoms: IHblWithCustomsData;
    isFetchHblWithCustomsIsLoading: boolean;
    fetchHblWithCustomsIsError: string;
    poPlanningList: IPoPlanning;
    poPlanningListIsLoading: boolean;
    fetchPoPlanningListError: string;
}

const defaultState: IPlanningState = {
    isGetEquipmentsPending: false,
    getEquipmentsError: '',
    isGetEquipmentDetailsPending: false,
    getEquipmentDetailsError: '',
    isAddEquipmentDeliveriesPending: false,
    addEquipmentDeliveriesError: '',
    isGetEquipmentDeliveriesPending: false,
    getEquipmentDeliveriesError: '',
    isEditEquipmentDeliveriesPending: false,
    editEquipmentDeliveriesError: '',
    isDeleteEquipmentDeliveriesPending: false,
    deleteEquipmentDeliveriesError: '',
    isDeliveryDetailsPending: false,
    deliveryDetailsError: '',
    isSendTransportOrderToHaulierPending: false,
    sendTransportOrderToHaulierError: '',
    isUpdateEquipmentDetailsPending: false,
    updateEquipmentDetailsError: '',
    isGetEmptyReturnEquipmentPending: false,
    getEmptyReturnEquipmentError: '',
    isUpdateEmptyReturnActualsPending: false,
    updateEmptyReturnActualsError: '',
    isUpdateEmptyReturnDetailsPending: false,
    updateEmptyReturnDetailsError: '',
    isGetEquipmentsLocationPending: false,
    getEquipmentsLocationError: '',
    isGetDPReasonCodesPending: false,
    getDPReasonCodesError: '',
    isGetHAWBListPending: false,
    getHAWBListError: '',
    isGetHAWBDetailsPending: false,
    getHAWBDetailsError: '',
    isGetAirReturnPending: false,
    getAirReturnError: '',
    isGetAirDeliveryDetailsPending: false,
    getAirDeliveryDetailsError: '',
    sendTransportOrderError: '',
    isFetchHblWithCustomsIsLoading: false,
    fetchHblWithCustomsIsError: '',
    equipments: {
        data: [],
        pageNumber: 1,
        pageSize: 20,
        totalCount: 0,
    },
    hawb: {
        data: [],
        pageNumber: 1,
        pageSize: 20,
        totalCount: 0,
    },
    equipmentDetails: {
        hbLs: [],
        pOs: [],
        deliveries: [],
    } as any as IEquipmentDetails,
    equipmentDeliveries: [],
    emptyReturnEquipments: {
        data: [],
        pageNumber: 1,
        pageSize: 20,
        totalCount: 0,
    },
    locations: {
        data: [],
        pageNumber: 1,
        pageSize: 20,
        totalCount: 0,
    },
    reasonCodes: [],
    hawbDetails: ({
        hbLs: [],
        pOs: [],
    } as any) as IEquipmentDetails,
    airDelivery: {
        data: [],
        pageNumber: 1,
        pageSize: 20,
        totalCount: 0,
    },
    isSetAvaialabilityForBookingLoading: false,
    setAvaialabilityForBookingError: '',
    airDeliveryDetails: {
        hbLs: [],
        pOs: [],
    } as any as IEquipmentDetails,
    hblWithCustoms: {
        totalCount: 0,
        result: [
            {
                equipmentId: 0,
                equipmentNumber: '',
                cL1_Type: null,
                cL1_Status: null,
                cL2_Type: null,
                cL2_Status: null,
                hbLs: [
                    {
                        hblId: 0,
                        number: '',
                        type: '',
                        consigneeName: null,
                        consigneeBECode: null,
                    },
                ],
            },
        ],
    },
    isGetDeliveryDetailsForEquipmentsPending: false,
    getDeliveryDetailsForEquipmentsError: '',
    equipmentsDeliveryDetails: [],
    poPlanningList: {
        result: [],
        pageNumber: 1,
        pageSize: 20,
        totalCount: 0,
    },
    poPlanningListIsLoading: false,
    fetchPoPlanningListError: '',
};

export const reducer = (state: IPlanningState = defaultState, action: IAction): IPlanningState => {
    switch (action.type) {
        case TYPES.GET_EQUIPMENTS_LIST.PENDING:
            return {
                ...state,
                isGetEquipmentsPending: true,
                getEquipmentsError: '',
            };
        case TYPES.GET_EQUIPMENTS_LIST.FULFILLED:
            return {
                ...state,
                isGetEquipmentsPending: false,
                equipments: {
                    data: cloneDataWithDisplayNames(action.equipments.data) || defaultState.equipments.data,
                    pageNumber: action.equipments.pageNumber || defaultState.equipments.pageNumber,
                    pageSize: action.equipments.pageSize || defaultState.equipments.pageSize,
                    totalCount: action.equipments.totalCount || defaultState.equipments.totalCount
                },
            };
        case TYPES.GET_EQUIPMENTS_LIST.REJECTED:
            return {
                ...state,
                isGetEquipmentsPending: false,
                getEquipmentsError: action.error || '',
            };
        case TYPES.GET_EQUIPMENTS_LIST.RESET_ERROR:
            return {
                ...state,
                getEquipmentsError: '',
            }
        case TYPES.GET_EQUIPMENT_DETAILS.PENDING:
            return {
                ...state,
                isGetEquipmentDetailsPending: true,
                getEquipmentDetailsError: '',
            };
        case TYPES.GET_EQUIPMENT_DETAILS.FULFILLED:
            return {
                ...state,
                isGetEquipmentDetailsPending: false,
                equipmentDetails: action.equipmentDetails as IEquipmentDetails,
            };
        case TYPES.GET_EQUIPMENT_DETAILS.REJECTED:
            return {
                ...state,
                isGetEquipmentDetailsPending: false,
                getEquipmentDetailsError: action.error || '',
            };
        case TYPES.GET_EQUIPMENT_DETAILS.RESET_ERROR:
            return {
                ...state,
                getEquipmentDetailsError: '',
            }
        case TYPES.ADD_EQUIPMENT_DELIVERIES.PENDING:
            return {
                ...state,
                isAddEquipmentDeliveriesPending: true,
                addEquipmentDeliveriesError: '',
            };
        case TYPES.ADD_EQUIPMENT_DELIVERIES.FULFILLED:
            return {
                ...state,
                isAddEquipmentDeliveriesPending: false,
            };
        case TYPES.ADD_EQUIPMENT_DELIVERIES.REJECTED:
            return {
                ...state,
                isAddEquipmentDeliveriesPending: false,
                addEquipmentDeliveriesError: action.error || '',
            };
        case TYPES.ADD_EQUIPMENT_DELIVERIES.RESET_ERROR:
            return {
                ...state,
                addEquipmentDeliveriesError: '',
            }
        case TYPES.GET_EQUIPMENT_DELIVERIES.PENDING:
            return {
                ...state,
                isGetEquipmentDeliveriesPending: true,
                getEquipmentDeliveriesError: '',
            };
        case TYPES.GET_EQUIPMENT_DELIVERIES.FULFILLED:
            return {
                ...state,
                isGetEquipmentDeliveriesPending: false,
                equipmentDeliveries: action.equipmentDeliveries as IEquipmentDelivery[],
            };
        case TYPES.GET_EQUIPMENT_DELIVERIES.REJECTED:
            return {
                ...state,
                isGetEquipmentDeliveriesPending: false,
                getEquipmentDeliveriesError: action.error || '',
            };
        case TYPES.GET_EQUIPMENT_DELIVERIES.RESET_ERROR:
            return {
                ...state,
                getEquipmentDeliveriesError: '',
            }
        case TYPES.DELETE_EQUIPMENT_DELIVERIES.PENDING:
            return {
                ...state,
                isDeleteEquipmentDeliveriesPending: true,
                deleteEquipmentDeliveriesError: '',
            };
        case TYPES.DELETE_EQUIPMENT_DELIVERIES.FULFILLED:
            return {
                ...state,
                isDeleteEquipmentDeliveriesPending: false,
            };
        case TYPES.DELETE_EQUIPMENT_DELIVERIES.REJECTED:
            return {
                ...state,
                isDeleteEquipmentDeliveriesPending: false,
                deleteEquipmentDeliveriesError: action.error || '',
            };
        case TYPES.DELETE_EQUIPMENT_DELIVERIES.RESET_ERROR:
            return {
                ...state,
                deleteEquipmentDeliveriesError: '',
            }
        case TYPES.EDIT_EQUIPMENT_DELIVERIES.PENDING:
            return {
                ...state,
                isEditEquipmentDeliveriesPending: true,
                editEquipmentDeliveriesError: '',
            };
        case TYPES.EDIT_EQUIPMENT_DELIVERIES.FULFILLED:
            return {
                ...state,
                isEditEquipmentDeliveriesPending: false,
            };
        case TYPES.EDIT_EQUIPMENT_DELIVERIES.REJECTED:
            return {
                ...state,
                isEditEquipmentDeliveriesPending: false,
                editEquipmentDeliveriesError: action.error || '',
            };
        case TYPES.EDIT_EQUIPMENT_DELIVERIES.RESET_ERROR:
            return {
                ...state,
                editEquipmentDeliveriesError: '',
            }
        case TYPES.EDIT_DELIVERY_DETAILS.PENDING:
            return {
                ...state,
                isDeliveryDetailsPending: true,
                deliveryDetailsError: '',
            };
        case TYPES.EDIT_DELIVERY_DETAILS.FULFILLED:
            return {
                ...state,
                isDeliveryDetailsPending: false,
            };
        case TYPES.EDIT_DELIVERY_DETAILS.REJECTED:
            return {
                ...state,
                isDeliveryDetailsPending: false,
                deliveryDetailsError: action.error || '',
            };
        case TYPES.EDIT_DELIVERY_DETAILS.RESET_ERROR:
            return {
                ...state,
                deliveryDetailsError: '',
            }
        case TYPES.EDIT_DELIVERY_DETAILS.RESET_ERROR:
            return {
                ...state,
                deliveryDetailsError: '',
            };

        case TYPES.SEND_TRANSPORT_ORDER_TO_HAULIER.PENDING:
            return {
                ...state,
                isSendTransportOrderToHaulierPending: true,
                sendTransportOrderToHaulierError: '',
            };
        case TYPES.SEND_TRANSPORT_ORDER_TO_HAULIER.FULFILLED:
            return {
                ...state,
                isSendTransportOrderToHaulierPending: false,
            };
        case TYPES.SEND_TRANSPORT_ORDER_TO_HAULIER.REJECTED:
            return {
                ...state,
                isSendTransportOrderToHaulierPending: false,
                sendTransportOrderError: action.error || '',
            };
        case TYPES.SEND_TRANSPORT_ORDER_TO_HAULIER.RESET_ERROR:
            return {
                ...state,
                sendTransportOrderError: '',
            }
        case TYPES.UPDATE_EQUIPMENT_DETAILS.PENDING:
            return {
                ...state,
                isUpdateEquipmentDetailsPending: true,
                updateEquipmentDetailsError: '',
            };
        case TYPES.UPDATE_EQUIPMENT_DETAILS.FULFILLED:
            return {
                ...state,
                isUpdateEquipmentDetailsPending: false,
            };
        case TYPES.UPDATE_EQUIPMENT_DETAILS.REJECTED:
            return {
                ...state,
                isUpdateEquipmentDetailsPending: false,
                updateEquipmentDetailsError: action.error || '',
            };
        case TYPES.UPDATE_EQUIPMENT_DETAILS.RESET_ERROR:
            return {
                ...state,
                updateEquipmentDetailsError: '',
            }
        case TYPES.UPDATE_EQUIPMENT_DETAILS.RESET_ERROR:
            return {
                ...state,
                isUpdateEquipmentDetailsPending: false,
                updateEquipmentDetailsError: '',
            };
        case TYPES.GET_EMPTY_RETURN_EQUIPMENTS.PENDING:
            return {
                ...state,
                isGetEmptyReturnEquipmentPending: true,
                getEmptyReturnEquipmentError: '',
            };
        case TYPES.GET_EMPTY_RETURN_EQUIPMENTS.FULFILLED:
            return {
                ...state,
                isGetEmptyReturnEquipmentPending: false,
                emptyReturnEquipments: {
                    data: cloneDataWithDisplayNames(action.emptyReturnEquipments.data) || defaultState.emptyReturnEquipments.data,
                    totalCount: action.emptyReturnEquipments.totalCount || defaultState.emptyReturnEquipments.totalCount,
                    pageNumber: action.emptyReturnEquipments.pageNumber || defaultState.emptyReturnEquipments.pageNumber,
                    pageSize: action.emptyReturnEquipments.pageSize || defaultState.emptyReturnEquipments.pageSize
                }
            };
        case TYPES.GET_EMPTY_RETURN_EQUIPMENTS.REJECTED:
            return {
                ...state,
                isGetEmptyReturnEquipmentPending: false,
                getEmptyReturnEquipmentError: action.error || '',
            };
        case TYPES.GET_EMPTY_RETURN_EQUIPMENTS.RESET_ERROR:
            return {
                ...state,
                getEmptyReturnEquipmentError: '',
            }
        case TYPES.UPDATE_EMPTY_RETURN_ACTUALS.PENDING:
            return {
                ...state,
                updateEmptyReturnActualsError: '',
                isUpdateEmptyReturnActualsPending: true,
            };
        case TYPES.UPDATE_EMPTY_RETURN_ACTUALS.FULFILLED:
            return {
                ...state,
                isUpdateEmptyReturnActualsPending: false,
            };
        case TYPES.UPDATE_EMPTY_RETURN_ACTUALS.REJECTED:
            return {
                ...state,
                isUpdateEmptyReturnActualsPending: false,
                updateEmptyReturnActualsError: action.error || '',
            };
        case TYPES.UPDATE_EMPTY_RETURN_ACTUALS.RESET_ERROR:
            return {
                ...state,
                updateEmptyReturnActualsError: '',
            }
        case TYPES.UPDATE_EMPTY_RETURN_EQUIPMENT_DETAILS.PENDING:
            return {
                ...state,
                isUpdateEmptyReturnDetailsPending: true,
                updateEmptyReturnDetailsError: '',
            };
        case TYPES.UPDATE_EMPTY_RETURN_EQUIPMENT_DETAILS.FULFILLED:
            return {
                ...state,
                isUpdateEmptyReturnDetailsPending: false,
            };
        case TYPES.UPDATE_EMPTY_RETURN_EQUIPMENT_DETAILS.REJECTED:
            return {
                ...state,
                isUpdateEmptyReturnDetailsPending: false,
                updateEmptyReturnDetailsError: action.error || '',
            };
        case TYPES.UPDATE_EMPTY_RETURN_EQUIPMENT_DETAILS.RESET_ERROR:
            return {
                ...state,
                updateEmptyReturnDetailsError: '',
            }
        case TYPES.RESET_SEND_TRANSPORT_ORDER_ERROR:
            return {
                ...state,
                sendTransportOrderError: '',
            };
        case TYPES.GET_CONTAINERS_LOCATION.PENDING:
            return {
                ...state,
                isGetEquipmentsLocationPending: true,
                getEquipmentsLocationError: '',
            };
        case TYPES.GET_CONTAINERS_LOCATION.FULFILLED:
            return {
                ...state,
                isGetEquipmentsLocationPending: false,
                locations: {
                    data: cloneDataWithDisplayNames(action.locations.data) || defaultState.locations.data,
                    totalCount: action.locations.totalCount || defaultState.locations.totalCount,
                    pageNumber: action.locations.pageNumber || defaultState.locations.pageNumber,
                    pageSize: action.locations.pageSize || defaultState.locations.pageSize
                }
            };
        case TYPES.GET_CONTAINERS_LOCATION.REJECTED:
            return {
                ...state,
                isGetEquipmentsLocationPending: false,
                getEquipmentsLocationError: action.error || '',
            };
        case TYPES.GET_CONTAINERS_LOCATION.RESET_ERROR:
            return {
                ...state,
                getEquipmentsLocationError: '',
            }
        case TYPES.GET_DP_REASON_CODES.PENDING:
            return {
                ...state,
                isGetDPReasonCodesPending: true,
                getDPReasonCodesError: '',
            };
        case TYPES.GET_DP_REASON_CODES.FULFILLED:
            return {
                ...state,
                isGetDPReasonCodesPending: false,
                reasonCodes: action.reasonCodes,
            };
        case TYPES.GET_DP_REASON_CODES.REJECTED:
            return {
                ...state,
                isGetDPReasonCodesPending: false,
                getDPReasonCodesError: action.error || '',
            };
        case TYPES.GET_DP_REASON_CODES.RESET_ERROR:
            return {
                ...state,
                getDPReasonCodesError: '',
            }
        case TYPES.GET_HAWB_LIST.PENDING:
            return {
                ...state,
                isGetHAWBListPending: true,
                getHAWBListError: '',
            };
        case TYPES.GET_HAWB_LIST.FULFILLED:
            return {
                ...state,
                isGetHAWBListPending: false,
                getHAWBListError: '',
                hawb: {
                    data: cloneDataWithDisplayNames(action.hawb.data, 'DD-MM-YYYY HH:mm') || defaultState.hawb.data,
                    totalCount: action.hawb.totalCount || defaultState.hawb.totalCount,
                    pageNumber: action.hawb.pageNumber || defaultState.hawb.pageNumber,
                    pageSize: action.hawb.pageSize || defaultState.hawb.pageSize
                }
            };
        case TYPES.GET_HAWB_LIST.REJECTED:
            return {
                ...state,
                isGetHAWBListPending: false,
                getHAWBListError: action.error || '',
            };
        case TYPES.GET_HAWB_LIST.RESET_ERROR:
            return {
                ...state,
                getHAWBListError: '',
            }
        case TYPES.GET_HAWB_DETAILS.PENDING:
            return {
                ...state,
                isGetHAWBDetailsPending: true,
                getHAWBDetailsError: '',
            };
        case TYPES.GET_HAWB_DETAILS.FULFILLED:
            return {
                ...state,
                isGetHAWBDetailsPending: false,
                getHAWBDetailsError: '',
                hawbDetails: action.hawbDetails as IEquipmentDetails,
            };
        case TYPES.GET_HAWB_DETAILS.REJECTED:
            return {
                ...state,
                isGetHAWBDetailsPending: false,
                getHAWBDetailsError: action.error || '',
            };
        case TYPES.GET_HAWB_DETAILS.RESET_ERROR:
            return {
                ...state,
                getHAWBDetailsError: '',
            }
        case TYPES.GET_AIR_DELIVERY.PENDING:
            return {
                ...state,
                isGetAirReturnPending: true,
                getAirReturnError: '',
            };
        case TYPES.GET_AIR_DELIVERY.FULFILLED:
            return {
                ...state,
                isGetAirReturnPending: false,
                getAirReturnError: '',
                airDelivery: {
                    data: cloneDataWithDisplayNames(action.airDelivery.data, 'DD-MM-YYYY HH:mm') || defaultState.airDelivery.data,
                    totalCount: action.airDelivery.totalCount || defaultState.airDelivery.totalCount,
                    pageNumber: action.airDelivery.pageNumber || defaultState.airDelivery.pageNumber,
                    pageSize: action.airDelivery.pageSize || defaultState.airDelivery.pageSize
                }
            };
        case TYPES.GET_AIR_DELIVERY.REJECTED:
            return {
                ...state,
                isGetAirReturnPending: false,
                getAirReturnError: action.error || '',
            };
        case TYPES.GET_AIR_DELIVERY.RESET_ERROR:
            return {
                ...state,
                getAirReturnError: '',
            }
        case TYPES.SET_AVAILABILITY_FOR_BOOKING.PENDING:
            return {
                ...state,
                isSetAvaialabilityForBookingLoading: true,
                setAvaialabilityForBookingError: ''
            }
        case TYPES.SET_AVAILABILITY_FOR_BOOKING.FULFILLED:
            return {
                ...state,
                isSetAvaialabilityForBookingLoading: false,
                setAvaialabilityForBookingError: ''
            }
        case TYPES.SET_AVAILABILITY_FOR_BOOKING.REJECTED:
            return {
                ...state,
                isSetAvaialabilityForBookingLoading: false,
                setAvaialabilityForBookingError: action.error || '',
            }
        case TYPES.SET_AVAILABILITY_FOR_BOOKING.RESET_ERROR:
            return {
                ...state,
                setAvaialabilityForBookingError: '',
            }
        case TYPES.SET_AVAILABILITY_FOR_BOOKING.RESET_ERROR:
            return {
                ...state,
                setAvaialabilityForBookingError: '',
            }
        case TYPES.GET_AIR_DELIVERY_DETAILS.PENDING:
            return {
                ...state,
                isGetAirDeliveryDetailsPending: true,
                getAirDeliveryDetailsError: '',
            };
        case TYPES.GET_AIR_DELIVERY_DETAILS.FULFILLED:
            return {
                ...state,
                isGetAirDeliveryDetailsPending: false,
                getAirDeliveryDetailsError: '',
                airDeliveryDetails: action.airDeliveryDetails as IEquipmentDetails,
            };
        case TYPES.GET_AIR_DELIVERY_DETAILS.REJECTED:
            return {
                ...state,
                isGetAirDeliveryDetailsPending: false,
                getAirDeliveryDetailsError: action.error || '',
            };
        case TYPES.GET_AIR_DELIVERY_DETAILS.RESET_ERROR:
            return {
                ...state,
                getAirDeliveryDetailsError: '',
            }
        case TYPES.GET_DELIVERY_DETAILS_FOR_EQUIPMENTS.PENDING:
            return {
                ...state,
                isGetDeliveryDetailsForEquipmentsPending: true,
                getDeliveryDetailsForEquipmentsError: '',
            };
        case TYPES.GET_DELIVERY_DETAILS_FOR_EQUIPMENTS.FULFILLED:
            return {
                ...state,
                isGetDeliveryDetailsForEquipmentsPending: false,
                getDeliveryDetailsForEquipmentsError: '',
                equipmentsDeliveryDetails: action.equipmentsDeliveryDetails as IEquipmentDeliveryDetails[],
            };
        case TYPES.GET_DELIVERY_DETAILS_FOR_EQUIPMENTS.REJECTED:
            return {
                ...state,
                isGetDeliveryDetailsForEquipmentsPending: false,
                getDeliveryDetailsForEquipmentsError: action.error || '',
            };
        case TYPES.GET_DELIVERY_DETAILS_FOR_EQUIPMENTS.RESET_ERROR:
            return {
                ...state,
                getDeliveryDetailsForEquipmentsError: '',
            }
        case TYPES.FETCH_HBL_WITH_CUSTOMS.PENDING:
            return {
                ...state,
                isFetchHblWithCustomsIsLoading: true,
                fetchHblWithCustomsIsError: ''
            };
        case TYPES.FETCH_HBL_WITH_CUSTOMS.FULFILLED:
            return {
                ...state,
                isFetchHblWithCustomsIsLoading: false,
                fetchHblWithCustomsIsError: '',
                hblWithCustoms: action.hblWithCustoms
            };
        case TYPES.FETCH_HBL_WITH_CUSTOMS.REJECTED:
            return {
                ...state,
                isFetchHblWithCustomsIsLoading: false,
                fetchHblWithCustomsIsError: action.error || '',
            };
        case TYPES.FETCH_HBL_WITH_CUSTOMS.RESET_ERROR:
            return {
                ...state,
                fetchHblWithCustomsIsError: '',
            }

        case TYPES.GET_PO_PLANNING_LIST.PENDING:
            return {
                ...state,
                    poPlanningListIsLoading: true,
                    fetchPoPlanningListError: ''
            };
        case TYPES.GET_PO_PLANNING_LIST.FULFILLED:
            return {
                ...state,
                poPlanningListIsLoading: false,
                fetchPoPlanningListError: '',
                poPlanningList: {
                    result: action.poPlanningList.result || defaultState.poPlanningList.result,
                    pageNumber: action.poPlanningList.pageNumber || defaultState.poPlanningList.pageNumber,
                    pageSize: action.poPlanningList.pageSize || defaultState.poPlanningList.pageSize,
                    totalCount: action.poPlanningList.totalCount || defaultState.poPlanningList.totalCount
                }
            };
        case TYPES.GET_PO_PLANNING_LIST.REJECTED:
            return {
                ...state,
                isFetchHblWithCustomsIsLoading: false,
                fetchHblWithCustomsIsError: action.error || '',
            };
        case TYPES.GET_PO_PLANNING_LIST.RESET_ERROR:
            return {
                ...state,
                fetchHblWithCustomsIsError: '',
            }
        default:
            return state;
    }
};
