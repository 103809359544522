import React from 'react';
import { ColumnFactory } from 'components/data-table/ColumnFactory';
import { DataTable } from 'components/data-table/DamcoTable';
import { IFactEsso } from 'reducers/fact/models';
import { StatusRow } from 'components/status-row';
import { cloneDataWithDisplayNames } from 'utilities/tables';

interface IEssosTableProps {
    data: IFactEsso[];
}

export const EssosTable: React.StatelessComponent<IEssosTableProps> = (props) => {
    const data = cloneDataWithDisplayNames(props.data, 'DD-MM-YYYY, h:mm').map(esso => ({
        ...esso,
        financialChangeIndicatorDisplayName: esso.financialChangeIndicator ? 'Y' : 'N',
        reasonDisplayName: esso.reasonDisplayName ? esso.reasonDisplayName : '-'
    }));

    return(
        <StatusRow title="Essos">
            <DataTable
                data={data}
                expandable={true}
                columns={[
                    ColumnFactory.standardColumn('numberDisplayName', 'Number'),
                    ColumnFactory.standardColumn('correlationIdDisplayName', 'Correlation ID'),
                    ColumnFactory.standardColumn('generationDateDisplayName', 'Generation date'),
                    ColumnFactory.standardColumn('messageTypeDisplayName', 'Message type'),
                    ColumnFactory.standardColumn('isSentDisplayName', 'Is sent'),
                    ColumnFactory.standardColumn('brainResponseStatusCodeDisplayName', 'Brain response status code'),
                    ColumnFactory.standardColumn('changedFinancialFieldsDisplayName', 'Changed financial fields'),
                    ColumnFactory.standardColumn('financialChangeIndicatorDisplayName', 'Financial change indicator'),
                    ColumnFactory.standardColumn('reasonDisplayName', 'Reason')
                ]}
                subColumns={[
                    ColumnFactory.standardColumn('path', 'Path'),
                    ColumnFactory.standardColumn('value', 'Value'),
                ]}
                withCheckboxes={false}
                reference={null as any}
                tableName="FactDetailsEssosTable"
                showPagination={true}
            />
        </StatusRow>
    );
};