import React, { useState } from 'react';
import styled from 'styled-components';
import { ChangeLogBadge } from './Badge';
import { Link } from 'react-router-dom';
import { DescriptionModal } from './DescriptionModal';

const LogContainer = styled.div`
    border: 1px solid #dddddd;
    padding: 10px;
    margin-bottom: 25px;
`;

const LogHeader = styled.hgroup`
    padding: 10px 10px;
    margin-bottom: 15px;
    border-bottom: 1px solid #8C8C8C;
    display: flex;
    justify-content: space-between;
    align-items: center;
`;

const LogItems = styled.ul`
    list-style: none;
    padding-left: 10px;

    li {
        margin: 5px 0 ;
        line-height: 1.25em;
    }
`;

const LogVersion = styled.h1`
    color: #64B2D4;
`;

const LogDate = styled.h3`
    color: #000;
`;

interface ILogProps {
    data: {
        version: string;
        date: string;
        changes: Array<{ 
            type: 'bug' | 'feature'; 
            title: string; 
            description: string;
            sourceId: string;
        }>
    },
    parentNode: number;
}

interface ILogState {
    isModalWithDescOpen: boolean;
    description: string;
}

export const Log: React.StatelessComponent<ILogProps> = (props) => {
    const [state, setState] = useState<ILogState>({ isModalWithDescOpen: false, description: '' });

    const toggleModal = () => setState(prevState => ({ ...prevState, isModalWithDescOpen: !prevState.isModalWithDescOpen }));
    const handleClick = (i: number) => {
        setState(prevState => ({ ...prevState, description: props.data.changes[i].description }));
        toggleModal();
    };

    const changes = props.data.changes.map((change, index) => {
        const titleText = change.sourceId ? `${change.title} [${change.sourceId}]` : `${change.title}`;
        const title = change.description === '' 
            ? <span>{titleText}</span> 
            : <Link to="#" onClick={() => handleClick(index)}>{titleText}</Link>;
            
        return(
            <li key={index}>
                <ChangeLogBadge type={change.type}/>
                {title}
            </li>
        );
    });

    return(
        <React.Fragment>
            <LogContainer>
                <LogHeader>
                    <LogVersion>Version {props.data.version}</LogVersion>
                    <LogDate>{props.data.date}</LogDate>
                </LogHeader>
                <LogItems>
                    {changes}
                </LogItems>
            </LogContainer>
            <DescriptionModal
                visible={state.isModalWithDescOpen}
                closeModal={toggleModal}
                description={state.description}
            />
        </React.Fragment>
    );
};