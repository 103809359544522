import React from 'react';
import { ModalScreen } from 'components/modal-screen';
import { ModalType } from 'components/modal-screen/ModalType';
import { OneStatus } from 'components/status-row/OneStatus';
import { Accordion } from 'components/accordion';
import { TripleGrid } from 'components/grid/triple-grid';
import { DoubleGrid } from 'components/grid/double-grid';
import { SingleGrid } from 'components/grid/single-grid';
import { IStore } from 'reducers/index';
import { ThunkDispatch } from 'redux-thunk';
import { Action, compose } from 'redux';
import { getEquipmentDetails } from 'actions/planning';
import { IEquipmentDetails } from 'reducers/planning/models';
import { connect, Omit } from 'react-redux';
import { LoadingAnimation } from 'components/loading';
import { InjectedFormProps, reduxForm } from 'redux-form';
import { FormInput } from 'components/input/FormInput';
import { formatDate, formatValueIfNullOrUndefined, getReasonCodesForSelectedGroup } from 'utilities/util';
import { editDemurrageAndDetention, resetEditDemurrageAndDetentionError } from 'actions/demurrageAndDetention';
import { addNewComment, IAddComment } from 'actions/timeline';
import { DeliveryPlanningTypes } from 'constants/comment';
import { FormTextArea } from 'components/textarea/FormTextArea';
import { Dialog } from 'components/dialog';
import { Capability } from 'components/timeline';
import { fetchDemurrageAndDetentionReasonCodes } from 'actions/settings';
import { FormSelect } from 'components/select/FormSelect';
import { IOption } from 'components/select';

interface IEditDAndDDetailsPopup {
    id: number;
    visible: boolean;
    closeModal: () => void;
    refetchFunction: () => void;
}

interface IMapStateToProps {
    equipment: IEquipmentDetails;
    isLoading: boolean;
    editDAndDPendingError: string;
    initialValues: {
        demurrageReasonCode: any;
        demurrageResponsible: any;
        detentionReasonCode: any;
        detentionResponsible: any;
    };
    reasonCodes: any;
}

interface IDispatch {
    getEquipmentDetails: (id: number) => Promise<void>;
    editDemurrageAndDetention: (data: object) => Promise<void>;
    addNewComment: (data: IAddComment) => Promise<void>;
    resetEditDemurrageAndDetentionError: () => void;
    fetchDemurrageAndDetentionReasonCodes: () => Promise<void>;
}

interface IEditDAndDDetailsPopupProps extends
    IEditDAndDDetailsPopup,
    Omit<InjectedFormProps<{}, IEditDAndDDetailsPopup>, 'initialValues'>,
    IMapStateToProps,
    IDispatch {
}

interface IEditDAndDDetailsPopupState {
    comment: string;
}

class _EditDAndDDetailsPopup extends React.Component<IEditDAndDDetailsPopupProps, IEditDAndDDetailsPopupState> {
    public static FORM_NAME = 'EDIT_D&D_DETAILS_POPUP';

    public state = {
        comment: '',
    };
    
    public componentDidMount() {
        this.props.getEquipmentDetails(this.props.id);
        this.props.resetEditDemurrageAndDetentionError();
        this.props.fetchDemurrageAndDetentionReasonCodes();
    }

    public render() {
        const DEMURRAGE_REASON_CODES: IOption[] = [
            { value: null, optionText: 'Select value...'}, 
            ...getReasonCodesForSelectedGroup(this.props.reasonCodes, 'Demurrage')
        ];

        const DETENTION_REASON_CODES: IOption[] = [
            { value: null, optionText: 'Select value...'}, 
            ...getReasonCodesForSelectedGroup(this.props.reasonCodes, 'Detention')
        ];

        return (
            <form onSubmit={this.props.handleSubmit(this.onSubmit)}>
                <ModalScreen
                    title="Update D&amp;D details"
                    modalType={ModalType.s()}
                    primaryButtonTitle="Save"
                    primaryButtonType="submit"
                    secondaryButtonTitle="Cancel"
                    secondaryButtonFunc={this.props.closeModal}
                    visible={this.props.visible}
                    closeModal={this.props.closeModal}
                    id="edit-d-and-d-modal"
                >
                    <React.Fragment>
                        {this.props.isLoading && <LoadingAnimation />}
                        <Dialog
                            message={this.props.editDAndDPendingError}
                            dialogType={500}
                            isVisible={!!this.props.editDAndDPendingError}
                            closeDialog={this.props.resetEditDemurrageAndDetentionError}
                        />
                        <Accordion text="Demurrage">
                            <TripleGrid>
                                <OneStatus label="First chargeable date">
                                    <p className="regular-body-text">{formatDate(this.props.equipment.firstChargeableDayDemurrage)}</p>
                                </OneStatus>
                                <OneStatus label="Gate out port">
                                    <p className="regular-body-text">{formatDate(this.props.equipment.gateOutPortDate)}</p>
                                </OneStatus>
                                <OneStatus label="No of days demurrage">
                                    <p className="regular-body-text">{formatValueIfNullOrUndefined(this.props.equipment.numberOfDaysDemurrage)}</p>
                                </OneStatus>
                            </TripleGrid>
                            <DoubleGrid>
                                <FormSelect
                                    label="Reason Code"
                                    name="demurrageReasonCode"
                                    options={DEMURRAGE_REASON_CODES}
                                />
                                <FormInput
                                    label="Responsible"
                                    name="demurrageResponsible"
                                />
                            </DoubleGrid>
                        </Accordion>
                        <Accordion text="Detention">
                            <TripleGrid>
                                <OneStatus label="First chargeable date">
                                    <p className="regular-body-text">{formatDate(this.props.equipment.firstChargeableDayDetention)}</p>
                                </OneStatus>
                                <OneStatus label="Empty return">
                                    <p className="regular-body-text">{formatDate(this.props.equipment.emptyReturnDate)}</p>
                                </OneStatus>
                                <OneStatus label="No of days demurrage">
                                    <p className="regular-body-text">{formatValueIfNullOrUndefined(this.props.equipment.numberOfDaysDetention)}</p>
                                </OneStatus>
                            </TripleGrid>
                            <DoubleGrid>
                                <FormSelect
                                    label="Reason Code"
                                    name="detentionReasonCode"
                                    options={DETENTION_REASON_CODES}
                                />
                                <FormInput
                                    label="Responsible"
                                    name="detentionResponsible"
                                />
                            </DoubleGrid>
                        </Accordion>
                        <SingleGrid>
                            <FormTextArea
                                label="Comment"
                                name="comment"
                                id="comment"
                            />
                        </SingleGrid>
                    </React.Fragment>
                </ModalScreen>
            </form>
        );
    };

    private onSubmit = async (data: any) => {
        try {
            await this.props.editDemurrageAndDetention({
                ...data,
                equipmentId: this.props.id,
            });
            if(data.comment) {
                await this.props.addNewComment({
                    objectType: DeliveryPlanningTypes.Equipment,
                    content: data.comment,
                    objectId: this.props.id,
                });
            }
            this.props.refetchFunction();
            this.props.closeModal();
        } catch (e) {
            console.error(e);
        }
    };
}

const mapStateToProps = (state: IStore): IMapStateToProps => {
    const {
        equipmentDetails,
        isGetEquipmentDetailsPending,
    } = state.planning;
    const { isEditDAndDPending, editDAndDPendingError } = state.demurrageAndDetention;
    const initialValues = {
        demurrageReasonCode: equipmentDetails.demurrageReasonCode,
        demurrageResponsible: equipmentDetails.demurrageResponsible,
        detentionReasonCode: equipmentDetails.detentionReasonCode,
        detentionResponsible: equipmentDetails.detentionResponsible,
    };
    const { dndReasonCodes, fetchReasonCodeIsLoading } = state.settings;
    return {
        equipment: equipmentDetails,
        isLoading: isGetEquipmentDetailsPending || isEditDAndDPending || fetchReasonCodeIsLoading,
        initialValues,
        editDAndDPendingError,
        reasonCodes: dndReasonCodes
    };
};

const mapDispatchToProps = (dispatch: ThunkDispatch<any, any, Action>): IDispatch => ({
    getEquipmentDetails: (id: number) => dispatch(getEquipmentDetails(id)),
    editDemurrageAndDetention: (data: object) => dispatch(editDemurrageAndDetention(data)),
    addNewComment: (data: IAddComment) => dispatch(addNewComment(data, false, Capability.DeliveryPlanning)),
    resetEditDemurrageAndDetentionError: () => dispatch(resetEditDemurrageAndDetentionError()),
    fetchDemurrageAndDetentionReasonCodes: () => dispatch(fetchDemurrageAndDetentionReasonCodes())
});

export const EditDAndDDetailsPopup = compose(
    connect<IMapStateToProps, IDispatch, IEditDAndDDetailsPopup, IStore>(mapStateToProps, mapDispatchToProps),
    reduxForm<{}, IEditDAndDDetailsPopup>({
        form: _EditDAndDDetailsPopup.FORM_NAME,
        enableReinitialize: true,
    }),
)(_EditDAndDDetailsPopup);
