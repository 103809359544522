import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { RouteComponentProps } from 'react-router-dom';
import { ThunkDispatch } from 'redux-thunk';
import { Action } from 'redux';
import { fetchEquipmentDetails } from 'actions/fact';
import { ErrorDialog } from 'components/error-dialog';
import API from 'constants/api';
import { PageHeader } from 'components/page-header';
import { IStore } from 'reducers/index';
import { IEquipmentDetails } from 'reducers/fact/models';
import { EquipmentDetailsTopBar } from './components/EquipmentDetailsTopBar';
import { Page } from 'components/page-container';
import { ServiceRow } from './components/ServiceRow';
import { ShipmentRow } from './components/ShipmentRow';
import { SubstitutionsRow } from './components/SubstitutionsRow';
import { DocumentsTable } from './components/DocumentsTable';
import { VoyagesTable } from './components/VoyagesTable';
import { CblsTable } from './components/CblsTable';
import { TransportShipmentsTable } from './components/TransportShipmentsTable';
import { LastUpdateRow } from './components/LastUpdateRow';

interface IParams {
    equipmentId: string;
}

interface IMapDispatchToProps {
    fetchEquipmentDetails: (equipmentId: number) => Promise<void>;
}

interface IMapStateToProps {
    equipmentDetails: IEquipmentDetails;
}

interface IEquipmentDetailsProps extends RouteComponentProps<IParams>, IMapStateToProps, IMapDispatchToProps {}

const _EquipmentDetails: React.FunctionComponent<IEquipmentDetailsProps> = (props) => {
    const equipmentId = parseInt(props.match.params.equipmentId, 10);
    useEffect(() => {
        if (!Number.isNaN(equipmentId)) {
            props.fetchEquipmentDetails(equipmentId);
        }
    }, []);

    return(
        <React.Fragment>
            <ErrorDialog endpoint={API.Fact.GetEquipmentDetails} />
            <PageHeader backButton={true}>Equipment: {props.equipmentDetails.equipmentNumber}</PageHeader>
            <EquipmentDetailsTopBar
                equipmentNumber={props.equipmentDetails.equipmentNumber}
                transportIdentification={props.equipmentDetails.transportIdentification}
                type={props.equipmentDetails.type}
                size={props.equipmentDetails.size}
                createDate={props.equipmentDetails.createDate}
                lastUpdateDate={props.equipmentDetails.updateDate}
                weight={props.equipmentDetails.weight}
                volume={props.equipmentDetails.volume}
                quantity={props.equipmentDetails.quantity}
                totalPackages={props.equipmentDetails.totalPackages}
            />
            <Page>
                <ServiceRow 
                    serviceAtOrigin={props.equipmentDetails.serviceAtOrigin}
                    serviceAtDestination={props.equipmentDetails.serviceAtDestination}
                />
                <ShipmentRow 
                    shipmentTypeAtOrigin={props.equipmentDetails.shipmentTypeAtOrigin}
                    shipmentTypeAtDestination={props.equipmentDetails.shipmentTypeAtDestination}
                />
                <SubstitutionsRow 
                    substitutionSize={props.equipmentDetails.substitutionSize}
                    substitutionType={props.equipmentDetails.substitutionType}
                />
                <DocumentsTable documents={props.equipmentDetails.documents} />
                <VoyagesTable voyages={props.equipmentDetails.voyages} />
                <CblsTable cbls={props.equipmentDetails.cbls} />
                <TransportShipmentsTable ets={props.equipmentDetails.equipmentTransportShipments} equipmentId={props.equipmentDetails.equipmentId as any}/>
                <LastUpdateRow 
                    lastUpdatedBy={props.equipmentDetails.lastUpdatedBy}
                    lastUpdatedByDocumentId={props.equipmentDetails.lastUpdatedByDocumentId}
                />
            </Page>
        </React.Fragment>
    );
};

const mapStateToProps = (state: IStore): IMapStateToProps => ({
    equipmentDetails: state.fact.equipmentDetails
});

const mapDispatchToProps = (dispatch: ThunkDispatch<any, any, Action>): IMapDispatchToProps => ({
    fetchEquipmentDetails: (equipmentId: number) => dispatch(fetchEquipmentDetails(equipmentId)),
});

export const EquipmentDetails = connect<IMapStateToProps, IMapDispatchToProps, {}, IStore>(mapStateToProps, mapDispatchToProps)(_EquipmentDetails);