import React from 'react';
import { FormSelect } from 'components/select/FormSelect';
import { connect } from 'react-redux';
import { ThunkDispatch } from 'redux-thunk';
import { Action } from 'redux';
import { fetchDeliveryPlanningHaulierSettings } from 'actions/settings';
import { IStore } from 'reducers/index';
import { LoadingAnimation } from 'components/loading';
import { ISettingsModel } from 'reducers/settings/models';
import { getHaulierText } from 'utilities/util';
import { IOption } from '.';

interface IDeliveryPlanningHaulierSelect {
    name: string;
    label: string;
}

interface IMapStateToProps {
    deliveryPlanningHaulierSettings: ISettingsModel[];
    isLoading: boolean;
}

interface IDispatch {
    fetchDeliveryPlanningHaulierSettings: () => void;
}

type Props = IDeliveryPlanningHaulierSelect & IDispatch & IMapStateToProps;

export class _DeliveryPlanningHaulierSelect extends React.Component<Props> {
    public componentDidMount() {
        this.props.fetchDeliveryPlanningHaulierSettings();
    }

    public render() {
        const options: IOption[] = [{ value: '', optionText: 'Select value...' }];

        this.props.deliveryPlanningHaulierSettings.map((haulier: ISettingsModel) => {
            options.push({
                value: haulier.id,
                optionText: getHaulierText(haulier),
            });
        });

        return (
            this.props.isLoading
                ? <LoadingAnimation/>
                : ( <FormSelect
                        options={options}
                        {...this.props}
                />)
        );
    }
}

const mapStateToProps = (state: IStore): IMapStateToProps => ({
    deliveryPlanningHaulierSettings: state.settings.deliveryPlanningHaulierSettings,
    isLoading: state.settings.fetchDeliveryPlanningHaulierSettingsPending,
});

const mapDispatchToProps = (dispatch: ThunkDispatch<any, any, Action>): IDispatch => ({
    fetchDeliveryPlanningHaulierSettings: () => dispatch(fetchDeliveryPlanningHaulierSettings(true)),
});

export const DeliveryPlanningHaulierSelect = connect(mapStateToProps, mapDispatchToProps)(_DeliveryPlanningHaulierSelect);
