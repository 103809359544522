import React from 'react';
import { ColumnFactory } from 'components/data-table/ColumnFactory';
import { DataTable } from 'components/data-table/DamcoTable';
import { IFactAsns } from 'reducers/fact/models';
import { StatusRow } from 'components/status-row';
import { cloneDataWithDisplayNames } from 'utilities/tables';

interface IEssosTableProps {
    data: IFactAsns[];
}

export const AsnsTable: React.StatelessComponent<IEssosTableProps> = (props) => {
    const data = cloneDataWithDisplayNames(props.data);

    return(
        <StatusRow title="Asns">
            <DataTable
                data={data}
                expandable={true}
                columns={[
                    ColumnFactory.standardColumn('id', 'ASN'),
                    ColumnFactory.standardColumn('type', 'Type'),
                    ColumnFactory.standardColumn('timeStamp', 'Date'),
                ]}
                subColumns={[
                    ColumnFactory.standardColumn('pathDisplayName', 'Path'),
                    ColumnFactory.standardColumn('valueDisplayName', 'Error'),
                ]}
                withCheckboxes={false}
                reference={null as any}
                tableName="FactDetailsAsnsTable"
                showPagination={true}
            />
        </StatusRow>
    );
};

