import React from 'react';
import { BaseFieldProps, Field } from 'redux-form'
import { Textarea } from './index';

class CustomPropsField extends Field<any> {}

interface IFormTextArea extends BaseFieldProps {
    label: string;
    id?: string;
}

export const FormTextArea: React.FunctionComponent<IFormTextArea> = (props) => (
    <CustomPropsField
        component={Textarea}
        {...props}
    />
);
