import React from 'react';
import { ColumnFactory } from 'components/data-table/ColumnFactory';
import { DataTable } from 'components/data-table/DamcoTable';
import { cloneDataWithDisplayNames } from 'utilities/tables';

interface IReleaseTable {
    data: any[];
}

// const cellIfCarrierNotReleased = (cell: CellInfo) => {
//     if (cell.original.isCarrierReleased) {
//         return 'N/A';
//     }

//     return formatValueIfNullOrUndefined(cell.value);
// };

// const dateCellIfCarrierNotReleased = (cell: CellInfo) => {
//     if (cell.original.isCarrierReleased) {
//         return 'N/A';
//     }

//     return formatDate(cell.value);
// };

export const ReleaseTable = (props: IReleaseTable) => {
    const data = cloneDataWithDisplayNames(props.data);

    return(
        <DataTable
            withCheckboxes={false}
            expandable={false}
            showPagination={true}
            reference={null as any}
            data={data}
            columns={[
                ColumnFactory.standardColumn('equipmentNumberDisplayName', 'Equipment number'),
                ColumnFactory.standardColumn('serviceDisplayName', 'Service'),
                ColumnFactory.standardColumn('dgDisplayName', 'Dangerous goods'),
                ColumnFactory.standardColumn('hotboxDisplayName', 'Hotbox'),
                ColumnFactory.standardColumn('pinDisplayName', 'PIN'),
                ColumnFactory.standardColumn('expiryDateDisplayName', 'Expiry date'),
                ColumnFactory.standardColumn('expiresInDisplayName', 'Expires in'),
                ColumnFactory.standardColumn('pickupLocationDisplayName', 'Pickup location'),
                ColumnFactory.standardColumn('emptyLocationDisplayName', 'Empty return'),
                ColumnFactory.standardColumn('unloadDateDisplayName', 'Landed date'),
            ]}
            tableName="CBLReleaseTable"
        />
    );
};
