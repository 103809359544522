import { IOption } from 'components/select';

export enum DPReasonCodesGroups {
    Ocean_LateShipment =  'Ocean_LateShipment',
    Air_LateShipment = 'Air_LateShipment',
    Ocean_FailedDelivery = 'Ocean_FailedDelivery',
    Air_FailedDelivery = 'Air_FailedDelivery'
}

export enum CTReasonCodes {
    Ocean_LateShipment =  'Ocean_LateShipment',
    Air_LateShipment = 'Air_LateShipment',
}

export enum ReasonCommentMode {
    Disabled = 0,
    Enabled = 1,
    Required = 2,
}

export const reasonCommentModeOptions: IOption[] = Object.keys(ReasonCommentMode)
    .filter(key => typeof ReasonCommentMode[key] === 'number')
    .map((optionText) => ({ optionText, value: ReasonCommentMode[optionText]}));

export const sendEventOptions: IOption[] = [
    { optionText: 'Yes', value: true },
    { optionText: 'No', value: false }
]