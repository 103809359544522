import React from 'react';
import { StatusRow } from 'components/status-row';
import { OneStatus } from 'components/status-row/OneStatus';
import { QuarterGrid } from 'components/grid/quarter-grid';
import { formatValueIfNullOrUndefined } from 'utilities/util';

interface IIssuerRowProps {
    issuerCountryCode: string;
    issuerBECode: string;
    issuerName: string;
    issuerFactCode: string;
    issuerSCVCode: string;
    issuerFunctionCode: string;
}

export const IssuerRow: React.StatelessComponent<IIssuerRowProps> = (props) => {
    return (
        <StatusRow title="Issuer">
            <QuarterGrid>
                <OneStatus label="Issuer country code">
                    {formatValueIfNullOrUndefined(props.issuerCountryCode)}
                </OneStatus>
                <OneStatus label="Issuer BE code">
                    {formatValueIfNullOrUndefined(props.issuerBECode)}
                </OneStatus>
                <OneStatus label="Issuer name">
                    {formatValueIfNullOrUndefined(props.issuerName)}
                </OneStatus>
                <OneStatus label="Issuer fact code">
                    {formatValueIfNullOrUndefined(props.issuerFactCode)}
                </OneStatus>
            </QuarterGrid>
            <QuarterGrid>
                <OneStatus label="Issuer SCV code">
                    {formatValueIfNullOrUndefined(props.issuerSCVCode)}
                </OneStatus>
                <OneStatus label="Issuer function code">
                    {formatValueIfNullOrUndefined(props.issuerFunctionCode)}
                </OneStatus>
            </QuarterGrid>
        </StatusRow>
    );
};
