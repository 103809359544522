import React, { Component } from 'react';
import { Page } from 'components/page-container';
import { PageHeader } from 'components/page-header';
import { IStore } from 'reducers/index';
import { ThunkDispatch } from 'redux-thunk';
import { Action } from 'redux';
import { fetchBLList } from 'actions/vessels';
import { connect } from 'react-redux';
import { IBLList } from 'reducers/vessels/models';
import { Filters } from 'modules/filters/containers/Filters';
import { FiltersInput } from 'modules/filters/components/FiltersInput';
import { FiltersBooleanSelect } from 'modules/filters/components/FiltersBooleanSelect';
import { FiltersDateInput } from 'modules/filters/components/FiltersDateInput';
import { HandleError } from 'components/handle-error';
import { LoadingAnimation } from 'components/loading';
import { ContainersTable } from 'modules/tracking/containers/components/ContainersTable';
import { setSort, resetSort } from 'actions/filters';
import { ISortModel } from 'reducers/filters/models';
import { setSortForTable } from 'utilities/tables';

interface IDispatch {
    fetchBLList: (pageNumber: number, pageSize: number) => Promise<void>;
    setSort: (sort: ISortModel) => void;
    resetSort: () => void;
}

interface IMapStateToProps {
    blList: IBLList;
    isLoading: boolean;
    isExportLoading: boolean;
    containerTrackingError: string;
    etdEmpty: boolean;
    ataEmpty: boolean;
    atdEmpty: boolean;
    newEtaEmpty: boolean;
}

interface IMBLProps extends IDispatch, IMapStateToProps {}

interface IMBLState {
    page: number;
    pageSize: number;
}

class _MBL extends Component<IMBLProps, IMBLState> {
    public state = {
        page: 1,
        pageSize: 10,
    };

    public render() {
        const filters = [
            <FiltersInput
                key="cblNumber"
                name="cblNumber"
                label="CB/L Number"
                id="cbl-number"
            />,
            <FiltersInput
                key="voyage"
                name="VesselName"
                label="Vessel name"
            />,
            <FiltersInput
                key="carrier"
                name="CarrierName"
                label="Carrier"
            />,
            <FiltersInput
                key="POD"
                name="podName"
                label="POD"
            />,
            <FiltersInput
                key="POL"
                name="polName"
                label="POL"
            />,
            <FiltersBooleanSelect
                type="string"
                key="transhipment"
                name="Transhipment"
                label="Transhipment"
                falseOptionText="No"
                trueOptionText="Yes"
            />,
            <FiltersBooleanSelect
                type="string"
                key="DG"
                name="DG"
                label="Dangerous goods"
                falseOptionText="No"
                trueOptionText="Yes"
            />,
            <FiltersDateInput
                key="etd_from"
                name="etd_from"
                label="ETD (from)"
                type="date"
                relatedField="etd"
                readOnly={this.props.etdEmpty}
            />,
            <FiltersDateInput
                key="etd_to"
                name="etd_to"
                label="ETD (to)"
                type="date"
                isEndOfDay={true}
                relatedField="etd"
                readOnly={this.props.etdEmpty}
            />,
            <FiltersDateInput
                key="atd_from"
                name="atd_from"
                label="ATD (from)"
                type="date"
                relatedField="atd"
                readOnly={this.props.atdEmpty}
            />,
            <FiltersDateInput
                key="atd_to"
                name="atd_to"
                label="ATD (to)"
                type="date"
                isEndOfDay={true}
                relatedField="atd"
                readOnly={this.props.atdEmpty}
            />,
            <FiltersDateInput
                key="neweta_from"
                name="neweta_from"
                label="New ETA (from)"
                type="date"
                relatedField="newEta"
                readOnly={this.props.newEtaEmpty}
            />,
            <FiltersDateInput
                key="neweta_to"
                name="neweta_to"
                label="New ETA (to)"
                type="date"
                isEndOfDay={true}
                relatedField="newEta"
                readOnly={this.props.newEtaEmpty}
            />,
            <FiltersDateInput
                key="ata_from"
                name="ata_from"
                label="ATA (from)"
                type="date"
                relatedField="ata"
                readOnly={this.props.ataEmpty}
            />,
            <FiltersDateInput
                key="ata_to"
                name="ata_to"
                label="ATA (to)"
                type="date"
                isEndOfDay={true}
                relatedField="ata"
                readOnly={this.props.ataEmpty}
            />,
            <FiltersBooleanSelect
                type="string"
                key="TranshipmentAtRisk"
                name="TranshipmentAtRisk"
                label="Transhipment at risk"
                falseOptionText="No"
                trueOptionText="Yes"
            />,
            <FiltersInput
                key="PONumber"
                name="PONumber"
                label="PO Number"
            />,
            <FiltersInput
                key="lastUserUpdated"
                name="lastUserUpdated"
                label="Last updated by"
            />,
        ];
        return (
            <>
                <PageHeader>Ocean MBL tracking</PageHeader>
                <Page>
                    {(this.props.isExportLoading || this.props.isLoading) && <LoadingAnimation />}
                    <HandleError
                        containerTrackingError={this.props.containerTrackingError}
                    />
                    <Filters
                        tableNameKey="tracking-cbl-containers"
                        filters={filters}
                        fetchFunction={() => this.props.fetchBLList(this.state.page, this.state.pageSize)}
                        withoutPagination={true}
                    />
                    <ContainersTable 
                        data={this.props.blList.data}
                        isLoading={this.props.isLoading}
                        pageCount={Math.ceil(this.props.blList.totalCount / this.props.blList.pageSize)}
                        fetchBls={this.fetchBLs}
                    />
                </Page>
            </>
        );
    }

    public componentWillUnmount() {
        this.props.resetSort();
    }

    private fetchBLs = (tableData: any) => {
        this.setState({
            page: tableData.page + 1,
            pageSize: tableData.pageSize
        }, () => {
            setSortForTable(tableData.sorted, this.props.setSort);
            this.props.fetchBLList(this.state.page, this.state.pageSize);
        });
    };
}

const mapStateToProps = (state: IStore): IMapStateToProps => ({
    blList: state.vessels.blList,
    isLoading: state.vessels.isLoading || state.modals.isNotifyCustomerPending,
    isExportLoading: state.exportReducer.isExportContainerTrackingLoading,
    containerTrackingError: state.exportReducer.exportContainerTrackingError,
    etdEmpty: state.filters.filters.etd_empty,
    atdEmpty: state.filters.filters.atd_empty,
    ataEmpty: state.filters.filters.ata_empty,
    newEtaEmpty: state.filters.filters.neweta_empty
});

const mapDispatchToProps = (dispatch: ThunkDispatch<any, any, Action>): IDispatch => ({
    fetchBLList: (pageNumber: number, pageSize: number) => dispatch(fetchBLList(pageNumber, pageSize)),
    setSort: (sort: ISortModel) => dispatch(setSort(sort)),
    resetSort: () => dispatch(resetSort()),
});

export const MBL = connect(mapStateToProps, mapDispatchToProps)(_MBL);
