import React from 'react';
import { ColumnFactory } from 'components/data-table/ColumnFactory';
import { formatValueIfNullOrUndefined } from 'utilities/util';
import { CellInfo } from 'react-table';

export const StandardColumn = (accessor: string, name: string) => {
    const cell = (rowInfo: CellInfo) => (
        <div key={`${rowInfo.index}-${rowInfo.viewIndex}`}>
            {formatValueIfNullOrUndefined(rowInfo.value)}
        </div>
    );
    return ColumnFactory.getColumnOptions(accessor, name, cell);
};