export class ModalType {
    public static xl = (): ModalType => new ModalType('modal extra-large');
    public static l = (): ModalType => new ModalType('modal large');
    public static m = (): ModalType => new ModalType('modal medium');
    public static s = (): ModalType => new ModalType('modal small');
    public static default = (): ModalType => new ModalType('modal');

    private cssClass: string;

    private constructor(value: string){
        this.cssClass = value;
    }

    public getClass = (): string => this.cssClass;
}