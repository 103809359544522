import React from 'react';
import { BaseFieldProps, Field } from 'redux-form'
import { Select, ISelect } from 'components/select';

class CustomPropsField extends Field<any> {}

interface IFormSelect extends ISelect, BaseFieldProps {
}

export const FormSelect: React.SFC<IFormSelect> = (props: IFormSelect) => (
    <CustomPropsField
        component={Select}
        {...props}
    />
);

