import { CARRIER_RELEASE_ROUTES, CUSTOMS_ROUTES, DELIVERY_ROUTES, PLANNING_ROUTES, TRACKING_ROUTES, EXCEPTION_MANAGEMENT_ROUTES } from 'constants/routes';
import { BUSINNESS_FUNCTIONS } from 'constants/roles';

export interface IRoute {
    name: string;
    to: string;
    links?: IRouteLink[];
    businessFunctions?: BUSINNESS_FUNCTIONS[];
}

export interface IRouteLink {
    to: string;
    text: string;
    businessFunctions?: BUSINNESS_FUNCTIONS[];
}

export const ROUTES: IRoute[] = [{
    name: 'Tracking',
    to: '/tracking',
    links: [{
        text: 'Vessel tracking',
        to: TRACKING_ROUTES.VESSEL_TRACKING,
        businessFunctions: [ BUSINNESS_FUNCTIONS.OCEAN_VESSEL_TRACKING_LIST ],
    }, {
        text: 'Ocean MBL tracking',
        to: TRACKING_ROUTES.OCEAN_MBL_TRACKING,
        businessFunctions: [ BUSINNESS_FUNCTIONS.OCEAN_MBL_TRACKING_LIST ],
    }, {
        text: 'MAWB Tracking',
        to: TRACKING_ROUTES.MAWB_TRACKING,
        businessFunctions: [ BUSINNESS_FUNCTIONS.AIR_MAWB_TRACKING_LIST ],
    }, {
        text: 'Milestones',
        to: TRACKING_ROUTES.MILESTONES,
        businessFunctions: [ BUSINNESS_FUNCTIONS.OCEAN_MILESTONES_LIST ]
    }],
}, {
    name: 'Carrier release',
    to: '/bill-release',
    links: [{
        text: 'HBL release',
        to: CARRIER_RELEASE_ROUTES.HBL_RELEASE,
        businessFunctions: [ BUSINNESS_FUNCTIONS.OCEAN_HBL_RELEASE_LIST ],
    }, {
        text: 'CB/L release',
        to: CARRIER_RELEASE_ROUTES.CBL_RELEASE,
        businessFunctions: [ BUSINNESS_FUNCTIONS.OCEAN_CBL_RELEASE_LIST ],
    }, {
        text: 'HAWB release',
        to: CARRIER_RELEASE_ROUTES.HAWB_RELEASE,
        businessFunctions: [ BUSINNESS_FUNCTIONS.AIR_HAWB_RELEASE_LIST ],
    }],
}, {
    name: 'Customs',
    to: '/customs/ocean-clearance',
    links: [{
        text: 'Ocean clearance',
        to: CUSTOMS_ROUTES.OCEAN_CLEARANCE,
        businessFunctions: [ BUSINNESS_FUNCTIONS.OCEAN_CUSTOMS_LIST ],
    }, {
        text: 'Air clearance',
        to: CUSTOMS_ROUTES.AIR_CLEARANCE,
        businessFunctions: [ BUSINNESS_FUNCTIONS.AIR_CUSTOMS_LIST ],
    }],
}, {
    name: 'Planning',
    to: '/planning',
    links: [{
        text: 'Container planning',
        to: PLANNING_ROUTES.CONTAINER_PLANNING,
        businessFunctions: [ BUSINNESS_FUNCTIONS.OCEAN_PLANNING_CONTAINER_LIST ],
    }, {
        text: 'HAWB planning',
        to: PLANNING_ROUTES.HAWB_PLANNING,
        businessFunctions: [ BUSINNESS_FUNCTIONS.AIR_PLANNING_CONTAINER_LIST ],
    }, {
        text: 'PO planning',
        to: PLANNING_ROUTES.PO_PLANNING,
        businessFunctions: [ BUSINNESS_FUNCTIONS.OCEAN_PO_PLANNING_GET_POS || BUSINNESS_FUNCTIONS.AIR_PO_PLANNING_GET_POS ],
    }],
}, {
    name: 'Delivery',
    to: '/delivery-and-return',
    links: [{
        text: 'Equipment',
        to: DELIVERY_ROUTES.EQUIPMENT,
        businessFunctions: [ BUSINNESS_FUNCTIONS.OCEAN_DELIVERY_EQUIPMENT_LIST ],
    }, {
        text: 'Location',
        to: DELIVERY_ROUTES.LOCATION,
        businessFunctions: [ BUSINNESS_FUNCTIONS.OCEAN_DELIVERY_LOCATION_LIST ],
    }, {
        text: 'Demurrage & Detention',
        to: DELIVERY_ROUTES.DD_DEMURRAGE,
        businessFunctions: [ BUSINNESS_FUNCTIONS.OCEAN_DD_DETENTION_LIST ],
    }, {
        text: 'Air delivery',
        to: DELIVERY_ROUTES.AIR_DELIVERY,
        businessFunctions: [ BUSINNESS_FUNCTIONS.AIR_DELIVERY_EQUIPMENT_LIST ],
    }],
}, {
    name: 'Exception management',
    to: '/exception-management',
    links: [{
        text: 'FACT',
        to: EXCEPTION_MANAGEMENT_ROUTES.FACT,
        businessFunctions: [
            BUSINNESS_FUNCTIONS.OCEAN_FACT_WRAPPER_LIST,
            BUSINNESS_FUNCTIONS.AIR_FACT_WRAPPER_LIST,
        ],
    }, {
        text: 'Event logs',
        to: EXCEPTION_MANAGEMENT_ROUTES.EVENT_LOGS,
        businessFunctions: [ BUSINNESS_FUNCTIONS.MONITORING_EVENT_LOGS_LIST ],
    }, {
        text: 'Settings',
        to: EXCEPTION_MANAGEMENT_ROUTES.SETTINGS,
        businessFunctions: [ BUSINNESS_FUNCTIONS.CONFIGURATION_GLOBAL ],
    },{
        text: 'Customer management',
        to: EXCEPTION_MANAGEMENT_ROUTES.CUSTOMER_MANAGEMENT,
        businessFunctions: [ BUSINNESS_FUNCTIONS.CUSTOMER_MANAGEMENT ],
    }]
}];