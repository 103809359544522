import { callApiPut, callApiPost } from 'utilities/apiCaller';
import { ThunkDispatch } from 'redux-thunk';
import { Action } from 'redux';
import { TYPES } from 'action-types/import';
import API from 'constants/api';

export const importFinalDeliveryDetails = (isAir: boolean, file: File) => {
    return async (dispatch: ThunkDispatch<any, any, Action>) => {
        dispatch({ type: TYPES.IMPORT_FINAL_DELIVERY_DETAILS.PENDING });
        try {
            const res = await callApiPut(API.Import.ImportFinalDeliveryDetails(isAir), file, { 
                responseType: 'blob', 
                headers: {TransportMode: isAir ? 'Air' : 'Ocean'} 
            });

            if (res.data.size !== 0) {
                dispatch({ type: TYPES.IMPORT_FINAL_DELIVERY_DETAILS.FULFILLED, res: res.data });
            } else {
                dispatch({ type: TYPES.IMPORT_FINAL_DELIVERY_DETAILS.FULFILLED });
            }
        } catch (e) {
            const fr = new FileReader();
            fr.onload = () => {
                const errors = JSON.parse(fr.result as any);
                dispatch({ type: TYPES.IMPORT_FINAL_DELIVERY_DETAILS.REJECTED, errors });
            };
            fr.readAsText(e.response.data);
        }
    }
};
export const resetImportErrors = () => (dispatch: ThunkDispatch<any, any, Action>) => dispatch({type: TYPES.IMPORT_FINAL_DELIVERY_DETAILS.RESET_ERROR});

export const importTransportDetails = (isAir: boolean, file: File) => {
    return async (dispatch: ThunkDispatch<any, any, Action>) => {
        dispatch({type: TYPES.IMPORT_TRANSPORT_DETAILS.PENDING});
        try {   
            const res = await callApiPost(API.Import.ImportTransportDetails(isAir), file, { 
                responseType: 'blob', 
                headers: {TransportMode: isAir ? 'Air' : 'Ocean'} 
            });
            
            if (res.data.size !== 0) {
                dispatch({ type: TYPES.IMPORT_TRANSPORT_DETAILS.FULFILLED, res: res.data });
            } else {
                dispatch({ type: TYPES.IMPORT_TRANSPORT_DETAILS.FULFILLED });
            }
        } catch (e) {
            const fr = new FileReader();
            fr.onload = () => {
                const errors = JSON.parse(fr.result as any);
                dispatch({ type: TYPES.IMPORT_TRANSPORT_DETAILS.REJECTED, errors });
            };
            fr.readAsText(e.response.data);
        }
    }
};
export const resetImportTransportDetailsErrors = () => (dispatch: ThunkDispatch<any, any, Action>) => dispatch({type: TYPES.IMPORT_TRANSPORT_DETAILS.RESET_ERROR});