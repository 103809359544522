import React from 'react';
import { Switch, Redirect } from 'react-router-dom';
import { RouteWithPermissions } from 'containers/route-with-permissions';
import { BUSINNESS_FUNCTIONS } from 'constants/roles';
import { Fact } from 'views/exception-management/fact/documents/list/FactList';
import { FactDetails } from 'views/exception-management/fact/documents/details/FactDetails';
import { EquipmentsList } from './equipments/list/EquipmentsList';
import { CblsList } from './cbls/list/CblsList';
import { EquipmentDetails } from './equipments/details/EquipmentDetails';
import { CblDetails } from './cbls/details/CblDetails';
import { ETSDetails } from './ets/details/ETSDetails';
import { EssoList } from './esso/list/EssoList';
import { IssoList } from './isso/list/IssoList';
import { ShipmentDetails } from './shipment/ShipmentDetails';

export const FactRouting = () => {
    return(
        <Switch>
            <RouteWithPermissions
                path="/exception-management/fact/equipments/:equipmentId/:etsId/:shipmentId"
                component={ShipmentDetails}
                functions={[BUSINNESS_FUNCTIONS.OCEAN_FACT_WRAPPER_LIST, BUSINNESS_FUNCTIONS.AIR_FACT_WRAPPER_LIST ]}
            />
            <RouteWithPermissions
                path="/exception-management/fact/equipments/:equipmentId/:etsId"
                component={ETSDetails}
                functions={[BUSINNESS_FUNCTIONS.OCEAN_FACT_WRAPPER_LIST, BUSINNESS_FUNCTIONS.AIR_FACT_WRAPPER_LIST ]}
            />
            <RouteWithPermissions
                path="/exception-management/fact/ets/:etsId"
                component={ETSDetails}
                functions={[BUSINNESS_FUNCTIONS.OCEAN_FACT_WRAPPER_LIST, BUSINNESS_FUNCTIONS.AIR_FACT_WRAPPER_LIST ]}
            />
            <RouteWithPermissions
                path="/exception-management/fact/documents/:documentId"
                component={FactDetails}
                functions={[ BUSINNESS_FUNCTIONS.OCEAN_FACT_WRAPPER_LIST, BUSINNESS_FUNCTIONS.AIR_FACT_WRAPPER_LIST ]}
            />
            <RouteWithPermissions
                path="/exception-management/fact/equipments/:equipmentId"
                component={EquipmentDetails}
                functions={[BUSINNESS_FUNCTIONS.OCEAN_FACT_WRAPPER_LIST, BUSINNESS_FUNCTIONS.AIR_FACT_WRAPPER_LIST ]}
            />
            <RouteWithPermissions
                path="/exception-management/fact/cbls/:cblId"
                component={CblDetails}
                functions={[BUSINNESS_FUNCTIONS.OCEAN_FACT_WRAPPER_LIST, BUSINNESS_FUNCTIONS.AIR_FACT_WRAPPER_LIST ]}
            />
            <RouteWithPermissions
                path="/exception-management/fact/documents"
                component={Fact}
                functions={[ BUSINNESS_FUNCTIONS.OCEAN_FACT_WRAPPER_LIST, BUSINNESS_FUNCTIONS.AIR_FACT_WRAPPER_LIST ]}
            />
            <RouteWithPermissions
                path="/exception-management/fact/equipments"
                component={EquipmentsList}
                functions={[BUSINNESS_FUNCTIONS.OCEAN_FACT_WRAPPER_LIST, BUSINNESS_FUNCTIONS.AIR_FACT_WRAPPER_LIST ]}
            />
            <RouteWithPermissions
                path="/exception-management/fact/cbls"
                component={CblsList}
                functions={[BUSINNESS_FUNCTIONS.OCEAN_FACT_WRAPPER_LIST, BUSINNESS_FUNCTIONS.AIR_FACT_WRAPPER_LIST ]}
            />
            <RouteWithPermissions
                path="/exception-management/fact/isso"
                component={IssoList}
                functions={[BUSINNESS_FUNCTIONS.OCEAN_FACT_WRAPPER_LIST, BUSINNESS_FUNCTIONS.AIR_FACT_WRAPPER_LIST ]}
            />
            <RouteWithPermissions
                path="/exception-management/fact/esso"
                component={EssoList}
                functions={[BUSINNESS_FUNCTIONS.OCEAN_FACT_WRAPPER_LIST, BUSINNESS_FUNCTIONS.AIR_FACT_WRAPPER_LIST ]}
            />
            <Redirect to="/exception-management/fact/documents" />
        </Switch>
    );
};