import React, { FunctionComponent, useEffect } from 'react';
import { PageHeader } from 'components/page-header';
import { CarrierBLDetailsTopBar } from 'views/bill-release/carrier-bl-details/components/CarrierBLDetailsTopBar';
import { ReleaseRow } from 'views/bill-release/carrier-bl-details/components/ReleaseRow';
import { EquipmentRow } from './components/EquipmentRow';
import { PageWithTimeline } from 'components/page-with-timeline';
import { Page } from 'components/page-container';
import { connect } from 'react-redux';
import { ThunkDispatch } from 'redux-thunk';
import { Action } from 'redux';
import { ContainerTrackingTypes } from 'constants/comment';
import { IStore } from 'reducers/index';
import { RouteComponentProps } from 'react-router-dom';
import { fetchCblDetails } from 'actions/bill-release';
import { ICblDetails } from 'reducers/bill-release/models';
import { LoadingAnimation } from 'components/loading';
import { ProgressIndicator } from 'components/progress-indicator';
import { IBubble } from 'reducers/vessels/models';
import { UserDetails } from 'components/user-details';
import { Capability } from 'components/timeline';
import { GenericError } from 'components/error-dialog/GenericError';

interface IMapStateToProps {
    isCblDetailsLoading: boolean;
    cblDetails: ICblDetails;
    bubbles: IBubble[];
}

interface IMapDispatchToProps {
    fetchDetails: (id: number) => Promise<void>;
}

interface ICarrierBLDetails extends IMapStateToProps, IMapDispatchToProps, RouteComponentProps<{ blId: string }> {}

const _CarrierBLDetails: FunctionComponent<ICarrierBLDetails> = (props) => {
    const id = parseInt(props.match.params.blId, 10);

    useEffect(() => {
        if (!Number.isNaN(id)) {
            props.fetchDetails(id);
        }
    }, []);

    if (Number.isNaN(id)) {
        return <GenericError />;
    }

    return (
        <>
            {props.isCblDetailsLoading && <LoadingAnimation />}
            <PageHeader backButton={true}>Carrier B/L: {props.cblDetails.blNumber}</PageHeader>
            <ProgressIndicator data={props.bubbles} />
            <CarrierBLDetailsTopBar
                carrierName={props.cblDetails.carrierName}
                blNumber={props.cblDetails.blNumber}
                billType={props.cblDetails.billType}
                carrierReleaseStatus={props.cblDetails.carrierReleaseStatus}
                vesselName={props.cblDetails.vesselName}
                voyage={''}
                destinationPort={props.cblDetails.destinationPort}
                newETA={props.cblDetails.newETA}
                ata={props.cblDetails.ata}
                equipments={props.cblDetails.equipments}
                surrenderType={props.cblDetails.surrenderType}
                blId={props.cblDetails.blId}
                originalBLSurrenderDate={props.cblDetails.originalBLSurrenderDate}
                originalBLSurrendered={props.cblDetails.originalBLSurrendered}
                id={id}
                refreshFunction={() => props.fetchDetails(id)}
                voyageId={props.cblDetails.voyageId}
            />
            <Page>
                <PageWithTimeline
                    objectId={id}
                    objectType={ContainerTrackingTypes.Cbl}
                    isAir={false}
                    capability={Capability.ContainerTracking}
                >
                    <ReleaseRow data={props.cblDetails.equipments}/>
                    <EquipmentRow data={props.cblDetails.equipments} />
                    <UserDetails
                        user={props.cblDetails.lastUserUpdated}
                        date={props.cblDetails.updateDate}
                    />
                </PageWithTimeline>
            </Page>
        </>
    );
}

const mapStateToProps = (state: IStore): IMapStateToProps => ({
    cblDetails: state.bill_release.cblDetails,
    isCblDetailsLoading: state.bill_release.isFetchCblDetailsLoading,
    bubbles: state.bill_release.cblDetails.bubbleData,
});

const mapDispatchToProps = (dispatch: ThunkDispatch<any, any, Action>): IMapDispatchToProps => ({
    fetchDetails: (id: number) => dispatch(fetchCblDetails(id)),
});

export const CarrierBLDetails = connect<IMapStateToProps, IMapDispatchToProps, {}, IStore>(mapStateToProps, mapDispatchToProps)(_CarrierBLDetails);
