import React from 'react';
import { Switch, Redirect } from 'react-router-dom';
import { Milestones } from './milestones';
import { TRACKING_ROUTES } from 'constants/routes';
import { RouteWithPermissions } from 'containers/route-with-permissions';
import { MawbFlight } from './mawb/details';
import { BUSINNESS_FUNCTIONS } from 'constants/roles';
import { Vessels } from './vessels/main';
import { MBL } from './mbl/list';
import { MawbList } from './mawb/list';
import { VesselDetails } from './vessels/details';
import { OceanBLDetails } from './mbl/details';

export const TrackingRouting = () => {
    return(
        <Switch>
            <RouteWithPermissions
                path="/tracking/mawb-tracking/:blId"
                component={MawbFlight}
                functions={[ BUSINNESS_FUNCTIONS.AIR_MAWB_TRACKING_DETAILS]}
            />
            <RouteWithPermissions
                path="/tracking/mbl-tracking/:blId"
                component={OceanBLDetails}
                functions={[ BUSINNESS_FUNCTIONS.OCEAN_MBL_TRACKING_DETAILS]}
            />
            <RouteWithPermissions
                path="/tracking/milestones"
                component={Milestones}
                functions={[BUSINNESS_FUNCTIONS.OCEAN_MILESTONES_LIST]}
            />
            <RouteWithPermissions
                exact={true}
                path="/tracking/"
                component={Vessels}
                functions={[ BUSINNESS_FUNCTIONS.OCEAN_VESSEL_TRACKING_LIST]}
            />
            <RouteWithPermissions
                exact={true}
                path="/tracking/mbl-tracking"
                component={MBL}
                functions={[ BUSINNESS_FUNCTIONS.OCEAN_MBL_TRACKING_LIST]}
            />
            <RouteWithPermissions
                exact={true}
                path="/tracking/mawb-tracking"
                component={MawbList}
                functions={[ BUSINNESS_FUNCTIONS.AIR_MAWB_TRACKING_LIST]}
            />
            <RouteWithPermissions
                path="/tracking/:voyageId"
                component={VesselDetails}
                functions={[BUSINNESS_FUNCTIONS.OCEAN_VESSEL_TRACKING_DETAILS]}
            />
            <Redirect to={TRACKING_ROUTES.MILESTONES} />
        </Switch>
    )
};
