import { ICblDetails, IHblDetails } from './models';
import { TYPES } from 'action-types/bill-release';
import { cloneDataWithDisplayNames } from 'utilities/tables';

interface IAction {
    readonly type: string;
    readonly containers: any;
    readonly cbl: any;
    readonly hbl: any;
    readonly hawb: any;
    readonly cblDetails: ICblDetails;
    readonly hblDetails: IHblDetails;
    readonly hawbDetails: IHblDetails;
    readonly error?: string;
}

export interface IBillReleaseState {
    readonly isLoading: boolean;
    readonly requestReleaseError: string;
    readonly isUpdateReleaseDetailsLoading: boolean;
    readonly updateReleaseDetailsError: string;
    readonly isFetchCblListLoading: boolean;
    readonly fetchCblListError: string;
    readonly isFetchHawbListLoading: boolean;
    readonly fetchHawbListError: string;
    readonly isFetchHblListLoading: boolean;
    readonly fetchHblListError: string;
    readonly isFetchCblDetailsLoading: boolean;
    readonly fetchCblDetailsError: string;
    readonly isFetchHblDetailsLoading: boolean;
    readonly fetchHblDetailsError: string;
    readonly isFetchHawbDetailsLoading: boolean;
    readonly fetchHawbDetailsError: string;
    readonly isEditBLDetailsLoading: boolean;
    readonly editBLDetailsError: string;
    readonly bill_release: any;
    readonly cbl: any;
    readonly hbl: any;
    readonly hawb: any;
    readonly cblDetails: ICblDetails;
    readonly hblDetails: IHblDetails;
    readonly hawbDetails: IHblDetails;
}

const defaultState: IBillReleaseState = {
    isLoading: false,
    requestReleaseError: '',
    isFetchCblListLoading: false,
    fetchCblListError: '',
    isUpdateReleaseDetailsLoading: false,
    updateReleaseDetailsError: '',
    isFetchHblListLoading: false,
    fetchHblListError: '',
    isFetchHawbListLoading: false,
    fetchHawbListError: '',
    isFetchCblDetailsLoading: false,
    fetchCblDetailsError: '',
    isFetchHawbDetailsLoading: false,
    fetchHawbDetailsError: '',
    isFetchHblDetailsLoading: false,
    fetchHblDetailsError: '',
    isEditBLDetailsLoading: false,
    editBLDetailsError: '',
    bill_release: [],
    cbl: {
        pageNumber: 1,
        pageSize: 20,
        totalCount: 0,
        data: []
    },
    hbl: {
        pageNumber: 1,
        pageSize: 20,
        totalCount: 0,
        data: []
    },
    hawb: {
        pageNumber: 1,
        pageSize: 20,
        totalCount: 0,
        data: []
    },
    cblDetails: {
        ata: '',
        billType: null,
        bubbleData: [],
        blId: 0,
        blNumber: '',
        carrierName: '',
        carrierReleaseStatus: 0,
        daysTillETA: 0,
        destinationPort: '',
        equipments: [],
        loadingPort: '',
        newETA: '',
        originalBLSurrenderDate: '',
        originalBLSurrendered: '',
        surrenderType: '',
        transportMode: '',
        vesselCode: '',
        vesselName: '',
        voyageStatus: 0,
        lastUserUpdated: null,
        updateDate: null,
        voyageId: 0
    },
    hblDetails: {
        billType: null,
        bubbleData: [],
        equipments: [],
        hblId: 0,
        hblNumber: '',
        originalHBLSurrenderDate: '',
        originalHBLSurrendered: null,
        surrenderType: null,
        vessels: [
            {
                '3PL': '',
                FlightNumber: '',
                ata: '',
                atd: '',
                blid: 0,
                blNumber: '',
                carrierReleaseStatus: '',
                currentETA: '',
                daysTillETA: 0,
                destinationPort: '',
                etd: '',
                isCurrentTranship: 0,
                loadingPort: '',
                newETA: '',
                transhipment: 0,
                transportMode: '',
                vesselCode: '',
                vesselName: '',
                voyageDelayReasonCode: '',
                voyageId: 0,
                voyageStatus: 0
            }
        ],
        lastUserUpdated: null,
        updateDate: null,
    },
    hawbDetails: {
        billType: null,
        bubbleData: [],
        equipments: [],
        hblId: 0,
        hblNumber: '',
        originalHBLSurrenderDate: '',
        originalHBLSurrendered: null,
        surrenderType: null,
        vessels: [
            {
                '3PL': '',
                FlightNumber: '',
                ata: '',
                atd: '',
                blid: 0,
                blNumber: '',
                carrierReleaseStatus: '',
                currentETA: '',
                daysTillETA: 0,
                destinationPort: '',
                etd: '',
                isCurrentTranship: 0,
                loadingPort: '',
                newETA: '',
                transhipment: 0,
                transportMode: '',
                vesselCode: '',
                vesselName: '',
                voyageDelayReasonCode: '',
                voyageId: 0,
                voyageStatus: 0
            }
        ],
        lastUserUpdated: null,
        updateDate: null,
    }
};

export const reducer = (state: IBillReleaseState = defaultState, action: IAction): IBillReleaseState => {
    switch (action.type) {
        case TYPES.UPDATE_RELEASE_DETAILS.PENDING:
            return {
                ...state,
                isUpdateReleaseDetailsLoading: true,
                updateReleaseDetailsError: ''
            };
        case TYPES.UPDATE_RELEASE_DETAILS.FULFILLED:
            return {
                ...state,
                isUpdateReleaseDetailsLoading: false,
                updateReleaseDetailsError: '',
            };
        case TYPES.UPDATE_RELEASE_DETAILS.REJECTED:
            return {
                ...state,
                isUpdateReleaseDetailsLoading: false,
                updateReleaseDetailsError: action.error || '',
            };
        case TYPES.UPDATE_RELEASE_DETAILS.RESET_ERROR:
            return {
                ...state,
                updateReleaseDetailsError: '',
            }
        case TYPES.REQUEST_RELEASE.PENDING:
            return {
                ...state,
                isLoading: true,
                requestReleaseError: '',
            };
        case TYPES.REQUEST_RELEASE.FULFILLED:
            return {
                ...state,
                isLoading: false,
                requestReleaseError: '',
            };
        case TYPES.REQUEST_RELEASE.REJECTED:
            return {
                ...state,
                isLoading: false,
                requestReleaseError: action.error || '',
            };
        case TYPES.REQUEST_RELEASE.RESET_ERROR:
            return {
                ...state,
                requestReleaseError: '',
            }
        case TYPES.FETCH_CBL_LIST.PENDING:
            return {
                ...state,
                isFetchCblListLoading: true,
                fetchCblListError: ''
            };
        case TYPES.FETCH_CBL_LIST.FULFILLED:
            return {
                ...state,
                isFetchCblListLoading: false,
                fetchCblListError: '',
                cbl: {
                    data: cloneDataWithDisplayNames(action.cbl.result) || state.cbl.data,
                    pageNumber: action.cbl.pageNumber || state.cbl.pageNumber,
                    pageSize: action.cbl.pageSize || state.cbl.pageSize,
                    totalCount: action.cbl.totalCount || state.cbl.totalCount
                }
            };
        case TYPES.FETCH_CBL_LIST.REJECTED:
            return {
                ...state,
                isFetchCblListLoading: false,
                fetchCblListError: action.error || '',
            };
        case TYPES.FETCH_CBL_LIST.RESET_ERROR:
            return {
                ...state,
                fetchCblListError: '',
            }
        case TYPES.FETCH_HBL_LIST.PENDING:
            return {
                ...state,
                isFetchHblListLoading: true,
                fetchHblListError: ''
            };
        case TYPES.FETCH_HBL_LIST.FULFILLED:
            return {
                ...state,
                isFetchHblListLoading: false,
                fetchHblListError: '',
                hbl: {
                    data: cloneDataWithDisplayNames(action.hbl.result) || state.hbl.data,
                    pageNumber: action.hbl.pageNumber || state.hbl.pageNumber,
                    pageSize: action.hbl.pageSize || state.hbl.pageSize,
                    totalCount: action.hbl.totalCount || state.hbl.totalCount
                }
            };
        case TYPES.FETCH_HBL_LIST.REJECTED:
            return {
                ...state,
                isFetchHblListLoading: false,
                fetchHblListError: action.error || '',
            };
        case TYPES.FETCH_HBL_LIST.RESET_ERROR:
            return {
                ...state,
                fetchHblListError: '',
            }
        case TYPES.FETCH_CBL_DETAILS.PENDING:
            return {
                ...state,
                isFetchCblDetailsLoading: true,
                fetchCblDetailsError: ''
            };
        case TYPES.FETCH_CBL_DETAILS.FULFILLED:
            return {
                ...state,
                isFetchCblDetailsLoading: false,
                fetchCblDetailsError: '',
                cblDetails: {
                    ata: action.cblDetails.ata || state.cblDetails.ata,
                    billType: action.cblDetails.billType || state.cblDetails.billType,
                    bubbleData: action.cblDetails.bubbleData || state.cblDetails.bubbleData,
                    blId: action.cblDetails.blId || state.cblDetails.blId,
                    blNumber: action.cblDetails.blNumber || state.cblDetails.blNumber,
                    carrierName: action.cblDetails.carrierName || state.cblDetails.carrierName,
                    carrierReleaseStatus: action.cblDetails.carrierReleaseStatus || state.cblDetails.carrierReleaseStatus,
                    daysTillETA: action.cblDetails.daysTillETA || state.cblDetails.daysTillETA,
                    destinationPort: action.cblDetails.destinationPort || state.cblDetails.destinationPort,
                    equipments: action.cblDetails.equipments || state.cblDetails.equipments,
                    loadingPort: action.cblDetails.loadingPort || state.cblDetails.loadingPort,
                    newETA: action.cblDetails.newETA || state.cblDetails.newETA,
                    originalBLSurrenderDate: action.cblDetails.originalBLSurrenderDate || state.cblDetails.originalBLSurrenderDate,
                    originalBLSurrendered: action.cblDetails.originalBLSurrendered || state.cblDetails.originalBLSurrendered,
                    surrenderType: action.cblDetails.surrenderType || state.cblDetails.surrenderType,
                    transportMode: action.cblDetails.transportMode || state.cblDetails.transportMode,
                    vesselCode: action.cblDetails.vesselCode || state.cblDetails.vesselCode,
                    vesselName: action.cblDetails.vesselName || state.cblDetails.vesselName,
                    voyageStatus: action.cblDetails.voyageStatus || state.cblDetails.voyageStatus,
                    lastUserUpdated: action.cblDetails.lastUserUpdated || state.cblDetails.lastUserUpdated,
                    updateDate: action.cblDetails.updateDate || state.cblDetails.updateDate,
                    voyageId: action.cblDetails.voyageId || state.cblDetails.voyageId
                }
            };
        case TYPES.FETCH_CBL_DETAILS.REJECTED:
            return {
                ...state,
                isFetchCblDetailsLoading: false,
                fetchCblDetailsError: action.error || '',
            };
        case TYPES.FETCH_CBL_DETAILS.RESET_ERROR:
            return {
                ...state,
                fetchCblDetailsError: '',
            }
        case TYPES.FETCH_HBL_DETAILS.PENDING:
            return {
                ...state,
                isFetchHblDetailsLoading: true,
                fetchHblDetailsError: ''
            };
        case TYPES.FETCH_HBL_DETAILS.FULFILLED:
            return {
                ...state,
                isFetchHblDetailsLoading: false,
                fetchHblDetailsError: '',
                hblDetails: {
                    billType: action.hblDetails.billType || state.hblDetails.billType,
                    bubbleData: action.hblDetails.bubbleData || state.hblDetails.bubbleData,
                    equipments: action.hblDetails.equipments || state.hblDetails.equipments,
                    hblId: action.hblDetails.hblId || state.hblDetails.hblId,
                    hblNumber: action.hblDetails.hblNumber || state.hblDetails.hblNumber,
                    originalHBLSurrenderDate: action.hblDetails.originalHBLSurrenderDate || state.hblDetails.originalHBLSurrenderDate,
                    originalHBLSurrendered: action.hblDetails.originalHBLSurrendered || state.hblDetails.originalHBLSurrendered,
                    surrenderType: action.hblDetails.surrenderType || state.hblDetails.surrenderType,
                    vessels: action.hblDetails.vessels || state.hblDetails.vessels,
                    lastUserUpdated: action.hblDetails.lastUserUpdated || state.hblDetails.lastUserUpdated,
                    updateDate: action.hblDetails.updateDate || state.hblDetails.updateDate,
                }
            };
        case TYPES.FETCH_HBL_DETAILS.REJECTED:
            return {
                ...state,
                isFetchHblDetailsLoading: false,
                fetchHblDetailsError: action.error || '',
            };
        case TYPES.FETCH_HBL_DETAILS.RESET_ERROR:
            return {
                ...state,
                fetchHblDetailsError: '',
            }
        case TYPES.EDIT_HBL_DETAILS.PENDING:
            return {
                ...state,
                isEditBLDetailsLoading: true,
                editBLDetailsError: ''
            };
        case TYPES.EDIT_HBL_DETAILS.FULFILLED:
            return {
                ...state,
                isEditBLDetailsLoading: false,
                editBLDetailsError: ''
            };
        case TYPES.EDIT_HBL_DETAILS.REJECTED:
            return {
                ...state,
                isEditBLDetailsLoading: false,
                editBLDetailsError: action.error || '',
            };
        case TYPES.EDIT_HBL_DETAILS.RESET_ERROR:
            return {
                ...state,
                editBLDetailsError: '',
            }
        case TYPES.FETCH_HAWB_LIST.PENDING:
            return {
                ...state,
                isFetchHawbListLoading: true,
                fetchHawbListError: ''
            };
        case TYPES.FETCH_HAWB_LIST.FULFILLED:
            return {
                ...state,
                isFetchHawbListLoading: false,
                fetchHawbListError: '',
                hawb: {
                    data: action.hawb.result || state.hawb.data,
                    pageNumber: action.hawb.pageNumber || state.hawb.pageNumber,
                    pageSize: action.hawb.pageSize || state.hawb.pageSize,
                    totalCount: action.hawb.totalCount || state.hawb.totalCount
                }
            };
        case TYPES.FETCH_HAWB_LIST.REJECTED:
            return {
                ...state,
                isFetchHawbListLoading: false,
                fetchHawbListError: action.error || '',
            };
        case TYPES.FETCH_HAWB_LIST.RESET_ERROR:
            return {
                ...state,
                fetchHawbListError: '',
            }
        case TYPES.FETCH_HAWB_DETAILS.PENDING:
            return {
                ...state,
                isFetchHawbDetailsLoading: true,
                fetchHawbDetailsError: ''
            };
        case TYPES.FETCH_HAWB_DETAILS.FULFILLED:
            return {
                ...state,
                isFetchHawbDetailsLoading: false,
                fetchHawbDetailsError: '',
                hawbDetails: {
                    billType: action.hblDetails.billType || state.hblDetails.billType,
                    bubbleData: action.hblDetails.bubbleData || state.hblDetails.bubbleData,
                    equipments: action.hblDetails.equipments || state.hblDetails.equipments,
                    hblId: action.hblDetails.hblId || state.hblDetails.hblId,
                    hblNumber: action.hblDetails.hblNumber || state.hblDetails.hblNumber,
                    originalHBLSurrenderDate: action.hblDetails.originalHBLSurrenderDate || state.hblDetails.originalHBLSurrenderDate,
                    originalHBLSurrendered: action.hblDetails.originalHBLSurrendered || state.hblDetails.originalHBLSurrendered,
                    surrenderType: action.hblDetails.surrenderType || state.hblDetails.surrenderType,
                    vessels: action.hblDetails.vessels || state.hblDetails.vessels,
                    lastUserUpdated: action.hblDetails.lastUserUpdated || state.hblDetails.lastUserUpdated,
                    updateDate: action.hblDetails.updateDate || state.hblDetails.updateDate,
                }
            };
        case TYPES.FETCH_HAWB_DETAILS.REJECTED:
            return {
                ...state,
                isFetchHawbDetailsLoading: false,
                fetchHawbDetailsError: action.error || '',
            };
        case TYPES.FETCH_HAWB_DETAILS.RESET_ERROR:
            return {
                ...state,
                fetchHawbDetailsError: '',
            }
        case TYPES.EDIT_HAWB_DETAILS.PENDING:
            return {
                ...state,
                isEditBLDetailsLoading: true,
                editBLDetailsError: ''
            };
        case TYPES.EDIT_HAWB_DETAILS.FULFILLED:
            return {
                ...state,
                isEditBLDetailsLoading: false,
                editBLDetailsError: ''
            };
        case TYPES.EDIT_HAWB_DETAILS.REJECTED:
            return {
                ...state,
                isEditBLDetailsLoading: false,
                editBLDetailsError: action.error || '',
            };
        case TYPES.EDIT_HAWB_DETAILS.RESET_ERROR:
            return {
                ...state,
                editBLDetailsError: '',
            }
        default:
            return state;
    }
};
