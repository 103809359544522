import React from 'react';
import { StatusRow } from 'components/status-row';
import { OneStatus } from 'components/status-row/OneStatus';
import { QuarterGrid } from 'components/grid/quarter-grid';
import { formatValueIfNullOrUndefined } from 'utilities/util';
import { IETSShipmentBooking } from 'reducers/fact/models';
import { DataTable } from 'components/data-table/DamcoTable';
import { cloneDataWithDisplayNames } from 'utilities/tables';
import { ColumnFactory } from 'components/data-table/ColumnFactory';
import { Link } from 'react-router-dom';

interface IShipmentRowProps {
    bookingAgent: any;
    bookings: IETSShipmentBooking[];
    cargoType: string;
    consigneeBusinessEntityCode: string;
    consigneeCountryCode: string;
    consigneeName: string;
    cosigneeFACTCode: string;
    documentNumber: string;
    placeOfConsolidationCode: string;
    placeOfConsolidationName: string;
    placeOfDeconsolidationCode: string;
    placeOfDeconsolidationName: string;
    placeOfDeliveryCode: string;
    placeOfDeliveryName: string;
    prepaidCollectIndicator: string;
    reconsolidationPoint: any;
    ids: string[];
}

export const ShipmentRow: React.StatelessComponent<IShipmentRowProps> = (props) => {
    const data = cloneDataWithDisplayNames(props.bookings);
    
    return (
        <StatusRow title="Shipment">
            <QuarterGrid>
                <OneStatus label="Booking agent">
                    {formatValueIfNullOrUndefined(props.bookingAgent)}
                </OneStatus>
                <OneStatus label="Cargo type">
                    {formatValueIfNullOrUndefined(props.cargoType)}
                </OneStatus>
                <OneStatus label="Consignee business entity code">
                    {formatValueIfNullOrUndefined(props.consigneeBusinessEntityCode)}
                </OneStatus>
                <OneStatus label="Consignee country code">
                    {formatValueIfNullOrUndefined(props.consigneeCountryCode)}
                </OneStatus>
            </QuarterGrid>
            <QuarterGrid>
                <OneStatus label="Consignee name">
                    {formatValueIfNullOrUndefined(props.consigneeName)}
                </OneStatus>
                <OneStatus label="Cosignee FACT code">
                    {formatValueIfNullOrUndefined(props.cosigneeFACTCode)}
                </OneStatus>
                <OneStatus label="Document number">
                    <Link to={`/exception-management/fact/equipments/${props.ids[1]}/${props.ids[0]}/${props.ids[2]}`}>{formatValueIfNullOrUndefined(props.documentNumber)}</Link>
                </OneStatus>
                <OneStatus label="Place of consolidation code">
                    {formatValueIfNullOrUndefined(props.placeOfConsolidationCode)}
                </OneStatus>
            </QuarterGrid>
            <QuarterGrid>
                <OneStatus label="Place of consolidation name">
                    {formatValueIfNullOrUndefined(props.placeOfConsolidationName)}
                </OneStatus>
                <OneStatus label="Place of deconsolidation code">
                    {formatValueIfNullOrUndefined(props.placeOfDeconsolidationCode)}
                </OneStatus>
                <OneStatus label="Place of deconsolidation name">
                    {formatValueIfNullOrUndefined(props.placeOfDeconsolidationName)}
                </OneStatus>
                <OneStatus label="Place of delivery code">
                    {formatValueIfNullOrUndefined(props.placeOfDeliveryCode)}
                </OneStatus>
            </QuarterGrid>
            <QuarterGrid>
                <OneStatus label="Place of delivery name">
                    {formatValueIfNullOrUndefined(props.placeOfDeliveryName)}
                </OneStatus>
                <OneStatus label="Prepaid collect indicator">
                    {formatValueIfNullOrUndefined(props.prepaidCollectIndicator)}
                </OneStatus>
                <OneStatus label="Reconsolidation point">
                    {formatValueIfNullOrUndefined(props.reconsolidationPoint)}
                </OneStatus>
                <OneStatus label="Place of delivery code">
                    {formatValueIfNullOrUndefined(props.placeOfDeliveryCode)}
                </OneStatus>
            </QuarterGrid>
            <DataTable
                data={data}
                columns={[
                    ColumnFactory.standardColumn('cargoTypeDisplayName', 'Cargo type'),
                    ColumnFactory.standardColumn('consigneeBusinessEntityCodeDisplayName', 'Consignee business entity code'),
                    ColumnFactory.standardColumn('consigneeCountryCodeDisplayName', 'Consignee country code'),
                    ColumnFactory.standardColumn('consigneeFACTCodeDisplayName', 'Consignee  FACT code'),
                    ColumnFactory.standardColumn('consigneeNameDisplayName', 'Consignee  name'),
                    ColumnFactory.standardColumn('consigneeSCVCodeDisplayName', 'Consignee SCV code'),
                    ColumnFactory.standardColumn('destinationSiteCodeDisplayName', 'Destination site code'),
                    ColumnFactory.standardColumn('destinationSiteNameDisplayName', 'Destination site name'),
                    ColumnFactory.standardColumn('numberDisplayName', 'Number'),
                    ColumnFactory.standardColumn('originSiteCodeDisplayName', 'Origin site code'),
                    ColumnFactory.standardColumn('originSiteNameDisplayName', 'Origin site name'),
                    ColumnFactory.standardColumn('paymentTypeDisplayName', 'Payment type'),
                    ColumnFactory.standardColumn('placeOfDeliveryCodeDisplayName', 'Place of delivery code'),
                    ColumnFactory.standardColumn('placeOfDeliveryNameDisplayName', 'Place of delivery name'),
                    ColumnFactory.standardColumn('ultimatePlaceOfDeliveryCodeDisplayName', 'Ultimate place of delivery code'),
                    ColumnFactory.standardColumn('ultimatePlaceOfDeliveryNameDisplayName', 'Ultimate place of delivery name'),
                ]}
                withCheckboxes={false}
                reference={null as any}
                tableName="ETSDetailsShipmentTable"
                showPagination={true}
                expandable={false}
            />
        </StatusRow>
    );
};
