import React from 'react';
import { LoadingAnimation } from 'components/loading';
import { ColumnFactory } from 'components/data-table/ColumnFactory';
import { downloadFile } from 'utilities/download';
import { ThunkDispatch } from 'redux-thunk';
import { Action } from 'redux';
import { exportCblReleaseToExcel } from 'actions/export';
import { connect } from 'react-redux';
import { DataTable } from 'components/data-table/DamcoTable';
import { EditHblPopup } from 'modules/bill-release/shared/components/EditBlModal.tsx/EditHblPopup';
import { RequestReleaseModal } from './RequestReleaseModal';
import { EditReleaseDetailsPopup } from 'views/bill-release/carrier-bl-details/components/EditReleaseDetailsPopup';
import { surrenderType } from 'modules/bill-release/shared/components/EditBlModal.tsx/surrenderType';
import { editCblDetails, fetchCblList } from 'actions/bill-release';
import { uniqBy, xorWith, isEqual } from 'lodash';
import { mapStatusToStringForCR } from 'constants/statuses';
// tslint:disable-next-line: no-submodule-imports
import { withPermissions, IWithPermissions } from 'src/HOC/withPermissions';
import { BUSINNESS_FUNCTIONS } from 'constants/roles';
import { getArrayOfButtons } from 'utilities/util';
import { IStore } from 'reducers/index';
import { Dialog } from 'components/dialog';

interface IMapDispatchToProps {
    exportCblReleaseToExcel: () => Promise<any>;
    editCblDetails: (body: any) => Promise<void>;
    fetchCblList: () => void;
}

interface ICBLTableOwnProps {
    data: any[];
    isLoading: boolean;
    pageCount: number;
    fetchCbls: any;
}

interface ICBLTableProps extends IWithPermissions, ICBLTableOwnProps, IMapDispatchToProps {}

interface ICBLTableState {
    isEditCBLPopupOpened: boolean;
    requestModalOpened: boolean;
    isUpdateReleaseDetailsModalOpened: boolean;
    editCblModal: boolean;
    notifyCustomer: boolean;
    isErrorDialogOpened: boolean;
    errorMessage: string;
}

class _CBLTable extends React.Component<ICBLTableProps, ICBLTableState> {
    public dataTable = React.createRef<any>();

    public state = {
        isEditCBLPopupOpened: false,
        requestModalOpened: false,
        isUpdateReleaseDetailsModalOpened: false,
        editCblModal: true,
        notifyCustomer: true,
        isErrorDialogOpened: false,
        errorMessage: 'Some error occured'
    };

    public componentDidUpdate(prevProps: ICBLTableProps) {
        const isArrayEqual = (x: any[], y: any[]) => xorWith(x, y, isEqual).length === 0;

        if(!isArrayEqual(this.props.data, prevProps.data)) {
            this.setState({ isErrorDialogOpened: false });
        }
    }

    public render() {
        const data = this.props.data.map((obj: any, index: number) => ({
            ...obj,
            blNumberDisplayName: [obj.blNumber, `/bill-release/cbl/${obj.blId}`],
            id: index,
            carrierReleaseStatusDisplayName: mapStatusToStringForCR(obj.carrierReleaseStatus)
        }));

        return(
            <React.Fragment>
                {this.props.isLoading && <LoadingAnimation />}
                {this.state.isErrorDialogOpened && <Dialog 
                    isVisible={this.state.isErrorDialogOpened}
                    message={this.state.errorMessage} 
                    closeDialog={this.toggleErrorDialog}
                />}
                <DataTable
                    data={data}
                    columns={[
                        ColumnFactory.linkedColumn('blNumberDisplayName', 'CB/L number'),
                        ColumnFactory.standardColumn('vesselNameDisplayName', 'Vessel name'),
                        ColumnFactory.standardColumn('carrierNameDisplayName', 'Carrier'),
                        ColumnFactory.standardColumn('destinationPortDisplayName', 'POD'),
                        ColumnFactory.standardColumn('newETADisplayName', 'New ETA'),
                        ColumnFactory.standardColumn('daysTillETADisplayName', 'Days till ETA'),
                        ColumnFactory.standardColumn('ataDisplayName', 'ATA'),
                        ColumnFactory.standardColumn('billTypeDisplayName', 'Bill type'),
                        ColumnFactory.standardColumn('surrenderTypeDisplayName', 'Surrender type'),
                        ColumnFactory.standardColumn('originalBLSurrenderedDisplayName', 'Original CBL surrendered'),
                        ColumnFactory.standardColumn('originalBLSurrenderDateDisplayName', 'Original surrender date'),
                        ColumnFactory.standardColumn('carrierReleaseStatusDisplayName', 'Carrier release status')
                    ]}
                    subColumns={[
                        ColumnFactory.standardColumn('equipmentNumberDisplayName', 'Equipment number'),
                        ColumnFactory.standardColumn('hblNumberDisplayName', 'HBL/FCR number'),
                        ColumnFactory.standardColumn('originalBLSurrenderedDisplayName', 'Original HBL surrendered'),
                        ColumnFactory.standardColumn('dangerousGoodsDisplayName', 'Dangerous goods'),
                        ColumnFactory.standardColumn('hotBoxDisplayName', 'Hotbox'),
                        ColumnFactory.standardColumn('pinDisplayName', 'PIN'),
                        ColumnFactory.standardColumn('pinExpiryDateDisplayName', 'Expiry date'),
                        ColumnFactory.standardColumn('expiresInDisplayName', 'Expires in'),
                        ColumnFactory.standardColumn('pickupLocationDisplayName', 'Pickup location'),
                        ColumnFactory.standardColumn('emptyLocationDisplayName', 'Empty return'),
                        ColumnFactory.standardColumn('unloadDateDisplayName', 'Landed date'),
                    ]}
                    expandable={true}
                    withCheckboxes={true}
                    selectVariant="multiple"
                    reference={this.dataTable}
                    tableName="CBLReleaseList"
                    actions={{
                        primaryActionIsMoreBtn: false,
                        primaryActionLabel: 'Export to excel',
                        primaryAction: this.exportToExcel,
                        parentContextualActions: getArrayOfButtons(
                            this.props.permissions,
                            [
                                { isMoreBtn: false, label: 'Update CBL details', action: this.checkIfCanUpdateCblDetails },
                                { isMoreBtn: false, label: 'Request release', action: this.checkIfCanRequestRelease },
                                { isMoreBtn: false, label: 'Update release details', action: this.checkIfCanUpdateReleaseDetails }
                            ])
                    }}
                    manualPagination={true}
                    fetchData={this.props.fetchCbls}
                    pageCount={this.props.pageCount}
                    showColumnOptions={true}
                />
                {this.state.isEditCBLPopupOpened && <EditHblPopup
                    visible={this.state.isEditCBLPopupOpened}
                    closeModal={this.toggleEditCBLModal}
                    blId={this.dataTable.current && this.dataTable.current.getSelectedItems()[0].blId}
                    initialValues={this.getInitialValuesForEditHblDetails()}
                    isSurrendered={this.dataTable.current && this.dataTable.current.getSelectedItems()[0].originalBLSurrendered}
                    submitFunc={this.editCblDetails}
                    isHbl={false}
                    modalName="CBL"
                />}
                {this.state.requestModalOpened && <RequestReleaseModal
                    visible={this.state.requestModalOpened}
                    closeModal={this.toggleRequestReleaseModal}
                    equipments={this.getUniqueEquipmentsWithVesselData()}
                    carrierName={this.getCurrentCarrierName()}
                    editingItemsCount={this.dataTable.current ? this.dataTable.current.getSelectedItems().length : 0}
                    ids={this.getIdsForSelectedItems()}
                />}
                {this.state.isUpdateReleaseDetailsModalOpened && <EditReleaseDetailsPopup
                    visible={this.state.isUpdateReleaseDetailsModalOpened}
                    closeModal={this.toggleUpdateReleaseDetailsModal}
                    data={this.dataTable.current && this.dataTable.current.getSelectedItems()[0] ? uniqBy(this.dataTable.current.getSelectedItems()[0].equipments, 'equipmentId') : []}
                    editingItemsCount={this.dataTable.current ? this.dataTable.current.getSelectedItems().length : 0}
                    cblId={this.dataTable.current && this.dataTable.current.getSelectedItems()[0] ? this.dataTable.current.getSelectedItems()[0].blId : 0}
                />}
            </React.Fragment>
        );
    };

    private exportToExcel = async () => {
        const res = await this.props.exportCblReleaseToExcel();
        await downloadFile(res);
    };

    private toggleEditCBLModal = () => this.setState((state) => ({
        isEditCBLPopupOpened: !state.isEditCBLPopupOpened
    }));

    private toggleRequestReleaseModal = () => this.setState((state) => ({
        requestModalOpened: !state.requestModalOpened
    }));

    private toggleUpdateReleaseDetailsModal = () => {
        if (this.state.isUpdateReleaseDetailsModalOpened) {
            this.props.fetchCblList();
        }
        this.setState((state) => ({
            isUpdateReleaseDetailsModalOpened: !state.isUpdateReleaseDetailsModalOpened
        }))
    };

    private getInitialValuesForEditHblDetails = () => {
        const selectedItems = this.dataTable.current ? this.dataTable.current.getSelectedItems() : [];

        if (selectedItems[0]) {
            const type = surrenderType.find(({ optionText }) => optionText === this.dataTable.current.getSelectedItems()[0].surrenderType)
            return {
                SurrenderType: type ? type.value : '',
                SurrenderedDate: this.dataTable.current.getSelectedItems()[0].originalBLSurrenderDate,
            }
        }
        return {};
    };

    private editCblDetails = async (data: any) => {
        await this.props.editCblDetails(data);
        await this.props.fetchCblList();
    };

    private getUniqueEquipmentsWithVesselData() {
        const selectedItems = this.dataTable.current ? this.dataTable.current.getSelectedItems() : [];
        const [firstItem] = selectedItems;
        const equipments = selectedItems.flatMap((item: any) => item.equipments);

        return firstItem
            ? uniqBy(equipments, 'equipmentId').map(item => ({
                ...item,
                vesselName: firstItem.vesselName,
            }))
            : []
    };

    private getCurrentCarrierName() {
        const selectedItems = this.dataTable.current ? this.dataTable.current.getSelectedItems() : [];

        if (selectedItems[0] && selectedItems[0]) {
            return selectedItems[0].carrierName;
        }
        return '';
    };

    private getIdsForSelectedItems = () => {
        const selectedItems = this.dataTable.current ? this.dataTable.current.getSelectedItems() : [];
        const voyageIds: number[] = [];
        const blIds: number[] = [];

        selectedItems.map((item: any) => {
            voyageIds.push(item.voyageId);
            blIds.push(item.blId)
        });

        return {
            blIds, 
            voyageIds
        }
    };

    private allowEditCBLDetails() {
        const selectedItems = this.dataTable.current ? this.dataTable.current.getSelectedItems() : [];
        return selectedItems.length === 1;
    };

    private hasSameVesselAndVoyage() {
        const selectedItems = this.dataTable.current ? this.dataTable.current.getSelectedItems() : [];

        return selectedItems.length > 0 && selectedItems.slice(1).every((item: any) => {
            const [{voyageId}] = selectedItems;
            
            return item.voyageId === voyageId;
        });
    };

    private checkIfCanUpdateCblDetails = () => {
        if(this.allowEditCBLDetails()) {
            this.setState({ isErrorDialogOpened: false });
            this.toggleEditCBLModal();
        } else {
            this.setState({
                isErrorDialogOpened: true,
                errorMessage: 'CBL might be updated only if 1 CBL is selected'
            });
        }
    };

    private checkIfCanRequestRelease = () => {
        if(this.hasSameVesselAndVoyage()) {
            this.setState({ isErrorDialogOpened: false });
            this.toggleRequestReleaseModal();
        } else {
            this.setState({
                isErrorDialogOpened: true,
                errorMessage: 'Selected CBLs does not have same vessels and voyages'
            });
        }
    };

    private checkIfCanUpdateReleaseDetails = () => {
        if(this.hasSameVesselAndVoyage()) {
            this.setState({ isErrorDialogOpened: false });
            this.toggleUpdateReleaseDetailsModal();
        } else {
            this.setState({
                isErrorDialogOpened: true,
                errorMessage: 'Selected CBLs does not have same vessels and voyages'
            });
        }
    };

    private toggleErrorDialog = (errorMessage?: string) => this.setState((state) => ({
        isErrorDialogOpened: !state.isErrorDialogOpened,
        errorMessage: errorMessage ? errorMessage : state.errorMessage
    }));
}

const ROLES: BUSINNESS_FUNCTIONS[] = [
    BUSINNESS_FUNCTIONS.OCEAN_CBL_RELEASE_EDIT_CBL,
    BUSINNESS_FUNCTIONS.OCEAN_CBL_RELEASE_REQUEST_RELEASE,
    BUSINNESS_FUNCTIONS.OCEAN_CBL_RELEASE_UPDATE_RELEASE_DETAILS
];

const mapDispatchToProps = (dispatch: ThunkDispatch<any, any, Action>): IMapDispatchToProps => ({
    exportCblReleaseToExcel: () => dispatch(exportCblReleaseToExcel()),
    editCblDetails: (body: any) => dispatch(editCblDetails(body)),
    fetchCblList: () => dispatch(fetchCblList()),
});

const __CBLTable = connect<undefined, IMapDispatchToProps, ICBLTableOwnProps, IStore>(undefined, mapDispatchToProps)(_CBLTable);
export const CBLTable = withPermissions(__CBLTable, ROLES) as any;
