import { TYPES } from 'action-types/customer-management';
import { ThunkDispatch } from 'redux-thunk';
import { callApiPost } from 'utilities/apiCaller';
import { generateError } from 'utilities/util';
import { Action } from 'redux';
import API from 'constants/api';

export const addNewCustomer = (data: string, verifyOnly: boolean) => {
  return async (dispatch: ThunkDispatch<any, any, Action>) => {
    dispatch({ type: TYPES.ADD_CUSTOMER.PENDING });
    try {
      await callApiPost(
        API.CustomerManagement.AddNewCustomer,
        { Customer: data, VerifyOnly: verifyOnly },
        { headers: { TransportMode: 'Ocean' } }
      );
      dispatch({ type: TYPES.ADD_CUSTOMER.FULFILLED });
    } catch (e) {
      const error = generateError(e);
      dispatch({ type: TYPES.ADD_CUSTOMER.REJECTED, error });
      throw e;
    }
  };
};
