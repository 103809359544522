import React from 'react';
import { ColumnFactory } from 'components/data-table/ColumnFactory';
import { DataTable } from 'components/data-table/DamcoTable';
import { IShipmentDocument } from 'reducers/fact/models';
import { StatusRow } from 'components/status-row';
import { cloneDataWithDisplayNames } from 'utilities/tables';

interface IDocumentsTableProps {
    data: IShipmentDocument[];
}

export const DocumentsTable: React.FunctionComponent<IDocumentsTableProps> = (props) => {
    const data = cloneDataWithDisplayNames(props.data).map(document => ({
        ...document,
        documentNumberDisplayName: [document.documentNumber, `/exception-management/fact/documents/${document.documentId}`],
        isCanceledDisplayName: document.isCanceled ? 'Y' : 'N'
    }));

    return(
        <StatusRow title="Documents">
            <DataTable
                data={data}
                columns={[
                    ColumnFactory.linkedColumn('documentNumberDisplayName', 'Document number'),
                    ColumnFactory.standardColumn('documentTypeDisplayName', 'Document type'),
                    ColumnFactory.standardColumn('originalDocumentNumberDisplayName', 'Original document number'),
                    ColumnFactory.standardColumn('isCanceledDisplayName', 'Is canceled')
                ]}
                withCheckboxes={false}
                reference={null as any}
                tableName="ShipmentDetailsBookingsTable"
                showPagination={true}
                expandable={false}
            />
        </StatusRow>
    );
};

