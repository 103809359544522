import React from 'react';
import { Redirect, Route, RouteProps, withRouter } from 'react-router';
import { BUSINNESS_FUNCTIONS } from 'constants/roles';
import { IStore } from 'reducers/index';
import { connect } from 'react-redux';
import { hasRequiredRole, IRoles } from 'utilities/roles';

interface IRouteWithPermissionsOwnProps extends RouteProps, IMapStateToProps {
    functions: BUSINNESS_FUNCTIONS[];
}

class _RouteWithPermissions extends React.Component<IRouteWithPermissionsOwnProps> {
    public render() {
        if(this.hasRequiredPermission(this.props.roles)) {
            return <Route {...this.props} />;
        }
        return <Redirect to="/unauthorized" />;
    }

    private hasRequiredPermission = (roles: IRoles[]) => hasRequiredRole(this.props.functions, roles);
}

interface IMapStateToProps {
    roles: IRoles[];
}

const mapStateToProps = (state: IStore): IMapStateToProps => ({
    roles: state.user.roles
});

export const RouteWithPermissions = withRouter(connect(mapStateToProps)(_RouteWithPermissions) as any) as any;