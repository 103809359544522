import React, { Component } from 'react';
import { PageHeader } from 'components/page-header';
import { PageWithTimeline } from 'components/page-with-timeline';
import { DemurrageAndDetentionContainerDetailsTopBar } from './containers/DemurrageAndDetentionContainerDetailsTopBar';
import { DangerousGoodsRow } from 'modules/details-pages/components/DangerousGoodsRow';
import { VesselRow } from 'modules/details-pages/components/VesselRow';
import { ReleaseRow } from 'modules/details-pages/components/ReleaseRow';
import { EquipmentRow } from 'modules/details-pages/components/EquipmentRow';
import { Page } from 'components/page-container';
import { connect } from 'react-redux';
import { IStore } from 'reducers/index';
import { ThunkDispatch } from 'redux-thunk';
import { Action } from 'redux';
import { getEquipmentDetails } from 'actions/planning';
import { IEquipmentDetails } from 'reducers/planning/models';
import { DeliveryTable } from 'views/planning/deliver-planning-container-details/components/DeliveryTable';
import { LCLRequirementsRow } from 'modules/details-pages/components/LCLRequirementsRow';
import { DeliveryBottomTable } from 'modules/details-pages/components/DeliveryBottomTable';
import { RouteComponentProps } from 'react-router';
import { LoadingAnimation } from 'components/loading';
import { EmptyReturnDocumentsRow } from 'modules/details-pages/components/EmptyReturnDocumentsRow';
import { CustomerReferencesRow } from 'modules/details-pages/components/CustomerReferencesRow';
import { MilestonesRow } from 'modules/details-pages/components/MilestonesRow';
import { DeliveryFailureRow } from 'modules/details-pages/components/DeliveryFailureRow';
import { NonConformanceRow } from 'modules/details-pages/components/NonConformance';
import { DemurrageAndDetentionRow } from 'modules/details-pages/components/DemurrageAndDetention';
import { DeliveryPlanningTypes } from 'constants/comment';
import { ProgressIndicator } from 'components/progress-indicator';
import { IBubble } from 'reducers/vessels/models';
import { UserDetails } from 'components/user-details';
import { Capability } from 'components/timeline';
import { GenericError } from 'components/error-dialog/GenericError';
import { ClearanceTable } from 'views/planning/deliver-planning-container-details/components/ClearanceTable';

interface IMapStateToProps {
    equipment: IEquipmentDetails;
    isLoading: boolean;
    bubbles: IBubble[];
}

interface IDispatch {
    getEquipmentDetails: (id: number) => Promise<void>;
}

interface IDemurrageAndDetentionContainerDetails extends IMapStateToProps, IDispatch, RouteComponentProps<{ id: string }> {
}

class _DemurrageAndDetentionContainerDetails extends Component<IDemurrageAndDetentionContainerDetails> {
    public componentDidMount() {
        this.getEquipmentDetails();
    }

    public render() {
        const id = parseInt(this.props.match.params.id, 10);

        if (Number.isNaN(id)) {
            return <GenericError />;
        }

        return (
            <div>
                {this.props.isLoading && <LoadingAnimation />}
                <PageHeader backButton={true}>Container : {this.props.equipment.equipmentNumber}</PageHeader>
                <ProgressIndicator data={this.props.bubbles} />
                <DemurrageAndDetentionContainerDetailsTopBar
                    equipment={this.props.equipment}
                    refetchFunction={this.getEquipmentDetails}
                />
                <Page>
                    <PageWithTimeline
                        objectId={id}
                        objectType={DeliveryPlanningTypes.Equipment}
                        isAir={false}
                        capability={Capability.DeliveryPlanning}
                    >
                        <DeliveryTable
                            id={id}
                            isEmptyReturn={true}
                        />
                        <EmptyReturnDocumentsRow {...this.props.equipment} />
                        <CustomerReferencesRow {...this.props.equipment} />
                        <MilestonesRow {...this.props.equipment} />
                        <DeliveryFailureRow {...this.props.equipment} />
                        <NonConformanceRow {...this.props.equipment} />
                        <DemurrageAndDetentionRow {...this.props.equipment} />
                        <LCLRequirementsRow {...this.props.equipment} />
                        <DangerousGoodsRow {...this.props.equipment} />
                        <VesselRow {...this.props.equipment} />
                        <ClearanceTable {...this.props.equipment} />
                        <ReleaseRow {...this.props.equipment} />
                        <EquipmentRow {...this.props.equipment} />
                        <DeliveryBottomTable data={this.props.equipment.pOs} />
                        <UserDetails
                            user={this.props.equipment.lastUserUpdated}
                            date={this.props.equipment.updateDate}
                        />
                    </PageWithTimeline>
                </Page>
            </div>
        );
    }

    private getEquipmentDetails = () => {
        const id = parseInt(this.props.match.params.id, 10);
        if (!Number.isNaN(id)) {
            this.props.getEquipmentDetails(id);
        }
    }
}

const mapStateToProps = (state: IStore): IMapStateToProps => ({
    equipment: state.planning.equipmentDetails,
    isLoading: state.planning.isGetEquipmentDetailsPending,
    bubbles: state.planning.equipmentDetails.bubbleDataDD
});

const mapDispatchToProps = (dispatch: ThunkDispatch<any, any, Action>): IDispatch => ({
    getEquipmentDetails: (id: number) => dispatch(getEquipmentDetails(id)),
});

export const DemurrageAndDetentionContainerDetails = connect(mapStateToProps, mapDispatchToProps)(_DemurrageAndDetentionContainerDetails);
