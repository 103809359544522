import React from 'react';
import { Switch, Redirect } from 'react-router';
import { DemurrageAndDetentionContainerDetails } from 'views/demurrage-and-detention/container-details';
import { Detention } from 'views/demurrage-and-detention/detention';
import { Demurrage } from 'views/demurrage-and-detention/demurrage';
import { RouteWithPermissions } from 'containers/route-with-permissions';
import { BUSINNESS_FUNCTIONS } from 'constants/roles';

export const DemurrageAndDetentionRouting = () => {
    return(
        <Switch>
            <RouteWithPermissions 
                path="/demurrage-and-detention/demurrage" 
                component={Demurrage} 
                functions={[ BUSINNESS_FUNCTIONS.OCEAN_DD_DEMURRAGE_LIST ]} 
            />
            <RouteWithPermissions 
                path="/demurrage-and-detention/detention" 
                component={Detention} 
                functions={[ BUSINNESS_FUNCTIONS.OCEAN_DD_DETENTION_LIST ]} 
            />
            <RouteWithPermissions 
                path="/demurrage-and-detention/:id" 
                component={DemurrageAndDetentionContainerDetails} 
                functions={[ BUSINNESS_FUNCTIONS.OCEAN_DD_EQUIPMENT_DETAILS ]} 
            />
            <Redirect to="/demurrage-and-detention/demurrage" />
        </Switch>
    );
};