import { createAsyncActionConstants } from 'utilities/util';

export const TYPES = {
    FETCH_CUSTOMS: createAsyncActionConstants('FETCH_CUSTOMS'),
    FETCH_BLS: createAsyncActionConstants('FETCH_BLS'),
    FETCH_HBLFCR_DETAILS: createAsyncActionConstants('FETCH_HBLFCR_DETAILS'),
    FETCH_HBLFCR_DETAILS_ON_LIST: createAsyncActionConstants('FETCH_HBLFCR_DETAILS_ON_LIST'),
    UPDATE_HBL_DETAILS: createAsyncActionConstants('UPDATE_HBL_DETAILS'),
    FETCH_CONTAINER_DETAILS: createAsyncActionConstants('FETCH_CONTAINER_DETAILS'),
    FETCH_AIR_CLEARANCE: createAsyncActionConstants('FETCH_AIR_CLEARANCE'),
    FETCH_CUSTOMS_HAWB_DETAILS: createAsyncActionConstants('FETCH_CUSTOMS_HAWB_DETAILS'),
    FETCH_HAWB_DETAILS_ON_LIST: createAsyncActionConstants('FETCH_HAWB_DETAILS_ON_LIST'),
    FETCH_EQUIPMENTS: createAsyncActionConstants('FETCH_EQUIPMENTS'),
    UPDATE_EQUIPMENTS: createAsyncActionConstants('UPDATE_EQUIPMENTS'),
};