import React, { FunctionComponent, useState, useRef } from 'react';
import { ColumnFactory } from 'components/data-table/ColumnFactory';
import { LoadingAnimation } from 'components/loading';
import { ISettingsModel, ICreateSettingsModel } from 'reducers/settings/models';
import { ConfigurationModal } from './ConfigurationModal';
import { DataTable } from 'components/data-table/DamcoTable';
import { connect } from 'react-redux';
import { ThunkDispatch } from 'redux-thunk';
import { Action } from 'redux';
import { IStore } from 'reducers/index';
import { fetchDeliveryPlanningDerigSettings, createDeRigConfiguration, deleteDeliveryPlanningDerigConfiguration, updateDeliveryPlanningDerigConfiguration } from 'actions/settings';
import { ErrorModal } from 'modules/modals/components/ErrorModal';

interface IDispatch {
    fetchDeliveryPlanningDerigSettings: () => any;
    createDeRigConfiguration: (configuration: ICreateSettingsModel) => Promise<void>;
    deleteDeliveryPlanningDerigConfiguration: (id: string) => Promise<void>;
    updateDeliveryPlanningDerigConfiguration: (configuration: ISettingsModel) => Promise<void>;
}

interface IMapStateToProps {
    customsBrokerSettings: ISettingsModel[];
    updateDerigPending: boolean;
    createDerigPending: boolean;
}

interface IDerigTableOwnProps {
    isLoading: boolean;
    data: ISettingsModel[];
}

interface IDerigTable extends IDerigTableOwnProps, IMapStateToProps, IDispatch {}

const _DerigTable: FunctionComponent<IDerigTable> = (props) => {
    const [isEditConfigurationModalOpened, setEditConfigurationModalOpened] = useState<boolean>(false);
    const [isCreateDerigConfigurationModalOpened, setCreateDerigConfigurationModalOpened] = useState<boolean>(false);
    const [isErrorModalOpen, setErrorModalOpen] = useState<boolean>(false);

    const dataTable = useRef<any>();

    const toggleDeliveryPlanningDerigSettings = (): void => setCreateDerigConfigurationModalOpened(!isCreateDerigConfigurationModalOpened);

    const toggleEditModal = (id?: string): void => setEditConfigurationModalOpened(!isEditConfigurationModalOpened);

    const toggleErrorModal = (): void => setErrorModalOpen(!isErrorModalOpen);

    const createNewDeRigConfiguration = async (fields: ICreateSettingsModel): Promise<void> => {
        await props.createDeRigConfiguration(fields);
        toggleDeliveryPlanningDerigSettings();
        await props.fetchDeliveryPlanningDerigSettings();
    };

    const deleteConfiguration = async (): Promise<void> => {
        await deleteDerigConfiguration(getId());
        await dataTable.current.hideContextRibbon();
    };

    const updateConfiguration = async (configuration: ISettingsModel): Promise<void> => {
        await updateDerigConfiguration(configuration);
        toggleEditModal();
    };

    const getRowToEdit = (): object => {
        const selectedItems = dataTable.current ? dataTable.current.getSelectedItems() : [];

        return {
            Name: selectedItems[0].name,
            Email: selectedItems[0].email,
            Subject: selectedItems[0].subject,
            Country: selectedItems[0].country,
            BeCode: selectedItems[0].beCode,
            Body: selectedItems[0].body,
            Id: selectedItems[0].id,
        }
    };

    const getId = (): string => {
        const selectedItems = dataTable.current ? dataTable.current.getSelectedItems() : [];
        return selectedItems[0].id;
    };

    const deleteDerigConfiguration = async (id: string): Promise<void> => {
        await props.deleteDeliveryPlanningDerigConfiguration(id);
        await props.fetchDeliveryPlanningDerigSettings();
    };

    const updateDerigConfiguration = async (configuration: ISettingsModel): Promise<void> => {
        await props.updateDeliveryPlanningDerigConfiguration(configuration);
        await props.fetchDeliveryPlanningDerigSettings();
    };

    return (
        <React.Fragment>
            {props.isLoading && <LoadingAnimation />}
            <DataTable
                data={props.data}
                expandable={false}
                withCheckboxes={true}
                selectVariant="single"
                columns={[
                    ColumnFactory.standardColumn('name', 'Name'),
                    ColumnFactory.standardColumn('email', 'Email'),
                    ColumnFactory.standardColumn('subject', 'Subject'),
                    ColumnFactory.standardColumn('body', 'Body'),
                    ColumnFactory.standardColumn('country', 'Country'),
                    ColumnFactory.standardColumn('beCode', 'BeCode'),
                ]}
                actions={{
                    primaryActionIsMoreBtn: false,
                    primaryActionLabel: 'Add new',
                    primaryAction: toggleDeliveryPlanningDerigSettings,
                    parentContextualActions: [
                        { isMoreBtn: false, label: 'Edit', action: toggleEditModal },
                        { isMoreBtn: false, label: 'Delete', action: deleteConfiguration },
                    ]
                }}
                reference={dataTable}
                tableName="SettingsDerigDPTable"
                showPagination={true}
            />
            {isCreateDerigConfigurationModalOpened && <ConfigurationModal
                title="Create derig configuration"
                visible={isCreateDerigConfigurationModalOpened}
                closeModal={toggleDeliveryPlanningDerigSettings}
                saveFunction={createNewDeRigConfiguration}
                isLoading={props.createDerigPending}
            />}
            {isEditConfigurationModalOpened && <ConfigurationModal
                title="Edit derig configuration"
                visible={isEditConfigurationModalOpened}
                closeModal={toggleEditModal}
                saveFunction={updateConfiguration}
                initialValues={getRowToEdit()}
                isLoading={props.updateDerigPending}
            />}
            {isErrorModalOpen && <ErrorModal
                visible={isErrorModalOpen}
                closeModal={toggleErrorModal}
                primaryButtonFunc={toggleErrorModal}
            />}
        </React.Fragment>
    );
}

const mapStateToProps = (state: IStore): IMapStateToProps => ({
    customsBrokerSettings: state.settings.customsBrokerSettings,
    createDerigPending: state.settings.createDeliveryPlanningDeRigSettingsPending,
    updateDerigPending: state.settings.updateDeliveryPlanningDeRigSettingsPending,
});

const mapDispatchToProps = (dispatch: ThunkDispatch<any, any, Action>): IDispatch => ({
    fetchDeliveryPlanningDerigSettings: () => dispatch(fetchDeliveryPlanningDerigSettings()),
    createDeRigConfiguration: (configuration: ICreateSettingsModel) => dispatch(createDeRigConfiguration(configuration)),
    deleteDeliveryPlanningDerigConfiguration: (id: string) => dispatch(deleteDeliveryPlanningDerigConfiguration(id)),
    updateDeliveryPlanningDerigConfiguration: (configuration: ISettingsModel) => dispatch(updateDeliveryPlanningDerigConfiguration(configuration)),
});

export const DerigTable = connect<IMapStateToProps, IDispatch, IDerigTableOwnProps, IStore>(mapStateToProps, mapDispatchToProps)(_DerigTable);