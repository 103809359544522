import * as React from 'react';
import { withRouter, RouteComponentProps } from 'react-router';
import { ILink } from './models/Link';
import { HorizontalTab } from 'components/sub-navigation/components/HorizontalTab';

interface ISubNavigationProps {
    links: ILink[];
}

const _SubNavigation: React.SFC<RouteComponentProps<{}> & ISubNavigationProps> = (props: RouteComponentProps<{}> & ISubNavigationProps) => {
    const navigationLinks = props.links.map((link, index) => {
        const onClick = () => props.history.push(link.to);
        return(
            <HorizontalTab
                link={link}
                index={index}
                onClick={onClick}
                key={index}
                active={props.match.path === link.to}
            />
        );
    });
    return(
        <>
            <div style={{ marginLeft: '1rem' }}>
                <div className="dam-tabs-horizontal">
                    {navigationLinks}
                </div>
            </div>
            <div className="dam-tabs-horizontal">
                <input id="tab-one" name="grp1" defaultChecked={true} type="radio"/>
                <label htmlFor="tab-one" style={{ display: 'none' }}>Fake button</label>
                <div className="tab-content" />
            </div>
        </>
    )
};

export const SubNavigation = withRouter(_SubNavigation);
