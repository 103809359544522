import React from 'react';
import { Accordion } from 'components/accordion';
import { QuarterGrid } from 'components/grid/quarter-grid';
import { OneStatus } from 'components/status-row/OneStatus';

interface IVesselTableProps {
    vessel: string;
    voyage: string;
    pol: string;
    pod: string;
}

export const VesselTable: React.StatelessComponent<IVesselTableProps> = (props: IVesselTableProps) => {
    return(
        <Accordion text="Vessel">
            <QuarterGrid>
                <OneStatus
                    label="Vessel name"
                    children={props.vessel}
                />
                <OneStatus 
                    label="Voyage"
                    children={props.voyage}
                />
                <OneStatus 
                    label="POD"
                    children={props.pod}
                />
                <OneStatus 
                    label="POL"
                    children={props.pol}
                />
            </QuarterGrid>
        </Accordion>
    );
}