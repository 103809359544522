import { CONFIG } from 'configs/index';

class API {
    public static ContainerTracking = {
        GetVessels: `${CONFIG.API.ContainerTracking}/GetVessels`,
        GetVesselDetails: `${CONFIG.API.ContainerTracking}/GetVesselDetails`,
        GetBLList: `${CONFIG.API.ContainerTracking}/GetBLsList`,
        GetBlDetails: `${CONFIG.API.ContainerTracking}/GetBLDetails`,
        EditVesselDetails: `${CONFIG.API.LogicApps.EditVesselDetails}`,
        EditVesselDetailsOnCBLLevel: `${CONFIG.API.LogicApps.EditVesselDetailsOnCBLLevel}`,
        AddVesselToBl: `${CONFIG.API.ContainerTracking}/add-vessel-to-bl`,
        GetReasonCodes: `${CONFIG.API.ContainerTracking}/GetReasonCodes`,
        AirGetMawbList: `${CONFIG.API.ContainerTracking}/GetBLsList`,
        EditVessel: `${CONFIG.API.LogicApps.EditVessel}`,
        GetMilestones: `${CONFIG.API.ContainerTracking}/GetMilestones`,
        UpdateMilestones: `${CONFIG.API.ContainerTracking}/UpdateMilestoneDate`,

        GetMRNData: (blId: number[]) => `${CONFIG.API.ContainerTracking}/GetBLEquipmentsMRN?${blId.map((bl:number)=>'blId='+bl).join('&')}`,
        SaveMRNData: `${CONFIG.API.ContainerTracking}/UpdateEquipmentsMRN`
    };

    public static Comments = {
        Get: (objectType: string, id: number) => `${CONFIG.API.ContainerTracking}/comments/${objectType}/${id}`,
        Add: `${CONFIG.API.ContainerTracking}/comments`,
        DeliveryPlanningGet: (objectType: string, id: number) => `${CONFIG.API.DeliveryPlanning}/comments/${objectType}/${id}`,
        DeliveryPlanningAdd: `${CONFIG.API.DeliveryPlanning}/comments`,
        CustomsGet: (objectType: string, id: number) => `${CONFIG.API.Customs}/comments/${objectType}/${id}`,
        CustomsAdd: `${CONFIG.API.Customs}/comments`,
    };

    public static Settings = {
        UpdateContainerTrackingCarrierSettings: `${CONFIG.API.ContainerTracking}/UpdateCarrierEmailConfiguration`,
        DeleteContainerTrackingCarrierSettings: `${CONFIG.API.ContainerTracking}/DeleteCarrierEmailConfiguration`,
        AddContainerTrackingCarrierSettings: `${CONFIG.API.ContainerTracking}/AddCarrierEmailConfiguration`,
        FetchContainerTrackingCarrierSettings: `${CONFIG.API.ContainerTracking}/GetCarrierEmailConfiguration`,
        AddContainerTrackingCustomer: `${CONFIG.API.ContainerTracking}/AddCustomerConfiguration`,
        FetchContainerTrackingGlobalSettings: `${CONFIG.API.ContainerTracking}/GetSettings`,
        FetchDeliveryPlanningGlobalSettings: `${CONFIG.API.DeliveryPlanning}/configurations`,
        FetchCustomsGlobalSettings: `${CONFIG.API.Customs}/getconfiguration`,
        FetchDeliveryPlanningHaulierSettings: `${CONFIG.API.DeliveryPlanning}/GetHauliersConfigurations`,
        FetchDeliveryStatusDeRigConfigurations: `${CONFIG.API.DeliveryPlanning}/GetDeRigConfigurations`,
        CreateDeliveryPlanningDeRigConfiguration: `${CONFIG.API.DeliveryPlanning}/CreateDeRigConfiguration`,
        CreateDeliveryPlanningHauliersConfiguration: `${CONFIG.API.DeliveryPlanning}/CreateHaulierConfiguration`,
        DeleteDeliveryPlanningDeRigConfiguration: `${CONFIG.API.DeliveryPlanning}/DeleteDeRigConfiguration`,
        DeleteDeliveryPlanningHaulierConfiguration: `${CONFIG.API.DeliveryPlanning}/DeleteHaulierConfiguration`,
        UpdateDeliveryPlanningDeRigConfiguration: `${CONFIG.API.DeliveryPlanning}/UpdateDeRigConfiguration`,
        UpdateDeliveryPlanningHaulierConfiguration: `${CONFIG.API.DeliveryPlanning}/UpdateHaulierConfiguration`,
        UpdateCustomsGlobalSettings: `${CONFIG.API.Customs}/UpdateConfiguration`,
        UpdateContainerTrackingGlobalSettings : `${CONFIG.API.ContainerTracking}/UpdateSetting`,
        FetchCustomsBrokerSettings: `${CONFIG.API.Customs}/GetBrokers`,
        CreateCustomsBrokersConfiguration: `${CONFIG.API.Customs}/AddBroker`,
        DeleteCustomsBrokerConfiguration: `${CONFIG.API.Customs}/DeleteBroker`,
        UpdateCustomsBrokerConfiguration: `${CONFIG.API.Customs}/UpdateBroker`,
        FetchDeliveryPlanningReasonCodes: (param: string) => `${CONFIG.API.DeliveryPlanning}/GetDelayReasonCodes?withDisabled=true`,
        UpdateDeliveryPlanningReasonCode: (id: number) => `${CONFIG.API.DeliveryPlanning}/DelayReasonCodes/${id}`,
        DeleteDeliveryPlanningReasonCode: (id: number) => `${CONFIG.API.DeliveryPlanning}/DelayReasonCodes/${id}`,
        CreateDeliveryPlanningReasonCode: `${CONFIG.API.DeliveryPlanning}/DelayReasonCodes`,
        FetchContainerTrackingReasonCodes: (param: string) => `${CONFIG.API.ContainerTracking}/GetReasonCodes?withDisabled=true`,
        UpdateContainerTrackingReasonCode: `${CONFIG.API.ContainerTracking}/UpdateReasonCode`,
        DeleteContainerTrackingReasonCode: (id: number) => `${CONFIG.API.ContainerTracking}/ReasonCodes/${id}`,
        CreateContainerTrackingReasonCode: `${CONFIG.API.ContainerTracking}/CreateReasonCode`,
        FetchCustomsReasonCodes: (param: string) => `${CONFIG.API.Customs}/GetReasonCodes?withDisabled=true`,
        UpdateCustomsReasonCode: `${CONFIG.API.Customs}/UpdateReasonCode`,
        DeleteCustomsReasonCode: (id: number) => `${CONFIG.API.Customs}/ReasonCodes/${id}`,
        CreateCustomsReasonCode: `${CONFIG.API.Customs}/CreateReasonCode`,
        CreateDeliveryPlanningCustomer: `${CONFIG.API.DeliveryPlanning}/PostCustomerConfiguration`,
        PutDeliveryPlanningCustomer: `${CONFIG.API.DeliveryPlanning}/PutCustomerConfiguration`,
        GetDeliveryPlanningCustomer: `${CONFIG.API.DeliveryPlanning}/GetCustomerConfiguration`,
        DeleteCustomerConfiguration: `${CONFIG.API.DeliveryPlanning}/DeleteCustomerConfiguration`,
        FetchFactOfficePrefixes: `${CONFIG.API.FactWrapper}/GetOfficePrefixes`,
        UpdateFactOfficePrefixes: `${CONFIG.API.FactWrapper}/UpdateOfficePrefix`,
        DeleteFactOfficePrefix: `${CONFIG.API.FactWrapper}/DeleteOfficePrefix`,
        AddFactOfficePrefix: `${CONFIG.API.FactWrapper}/AddOfficePrefix`,
        FetchDemurrageAndDetentionReasonCodes: `${CONFIG.API.DeliveryPlanning}/dndreasoncodes`,
        UpdateDemurrageAndDetentionReasonCode: (id: number) => `${CONFIG.API.DeliveryPlanning}/dndreasoncodes/${id}`,
        DeleteDemurrageAndDetentionReasonCode: (id: number) => `${CONFIG.API.DeliveryPlanning}/dndreasoncodes/${id}`,
        CreateDemurrageAndDetentionReasonCode: `${CONFIG.API.DeliveryPlanning}/dndreasoncodes`,

        AddGOHTranslation: `${CONFIG.API.FactWrapper}/AddGOHTranslation`,
        GetGOHTranslations: `${CONFIG.API.FactWrapper}/GetGOHTranslations`,
        DeleteGOHTranslation: `${CONFIG.API.FactWrapper}/DeleteGOHTranslation`,
        UpdateGOHTranslation: `${CONFIG.API.FactWrapper}/UpdateGOHTranslation`
    };

    public static DeliveryPlanning = {
        GetEquipmentsList: `${CONFIG.API.DeliveryPlanning}/GetEquipments`,
        GetEquipmentDeliveries: `${CONFIG.API.DeliveryPlanning}/equipments/%%/deliveries`,
        AddEquipmentDeliveries: `${CONFIG.API.DeliveryPlanning}/equipments/%%/deliveries`,
        EditEquipmentDeliveries: `${CONFIG.API.DeliveryPlanning}/equipments/%%/deliveries/%%`,
        DeleteEquipmentDeliveries: `${CONFIG.API.DeliveryPlanning}/equipments/%%/deliveries`,
        GetEquipmentDetails: `${CONFIG.API.DeliveryPlanning}/Equipments/%%`,
        EditDeliveryDetails: `${CONFIG.API.DeliveryPlanning}/equipments/delivery-details`,
        SendTransportOrderToHaulier: `${CONFIG.API.LogicApps.SendTransportOrderToHaulier}`,
        SendDeRiggingRequest: `${CONFIG.API.DeliveryPlanning}/equipments/de-rigging-request`,
        UpdateEquipmentDetails: `${CONFIG.API.LogicApps.UpdateEquipmentDetails}`,
        UpdateEmptyReturnEquipmentDetails: `${CONFIG.API.LogicApps.UpdateEmptyReturnEquipmentDetails}`,
        GetEmptyReturnEquipments: `${CONFIG.API.DeliveryPlanning}/GetEqDelivAndRet`,
        UpdateEmptyReturnActuals: `${CONFIG.API.LogicApps.UpdateEmptyReturnActuals}`,
        GetContainersLocation: `${CONFIG.API.DeliveryPlanning}/GetContainersLocation`,
        GetReasonCodes: `${CONFIG.API.DeliveryPlanning}/GetDelayReasonCodes`,
        GetDeliveryDetailsForEquipments: `${CONFIG.API.DeliveryPlanning}/GetDeliveryDetailsForEquipments`,
        SetAvailibilityForBooking: `${CONFIG.API.LogicApps.AvailableForBooking}`,
        GetHblWithCustoms: `${CONFIG.API.DeliveryPlanning}/GetHBLWithCustoms`,
        GetLocationsByCodes: `${CONFIG.API.DeliveryPlanning}/GetLocationsByCodes`,
        GetPos: `${CONFIG.API.DeliveryPlanning}/GetPOs`,
        NotifyCustomer: `${CONFIG.API.DeliveryPlanning}/NotifyCustomer`,
    };

    public static Customs = {
        GetBls: `${CONFIG.API.Customs}/getbls`,
        GetHBLFCRDetails: `${CONFIG.API.Customs}/GetHBLDetails`,
        UpdateHBLDetails: `${CONFIG.API.LogicApps.UpdateHBLDetails}`,
        GetContainerDetails: `${CONFIG.API.Customs}/GetContainerDetails`,
        AirGetAirClearance: `${CONFIG.API.Customs}/getbls`,
        AirGetHawbDetails: `${CONFIG.API.Customs}/GetHBLDetails`,
        NotifyCustomer: `${CONFIG.API.LogicApps.NotifyCustomerForCustoms}`,
        GetReasonCodes: `${CONFIG.API.Customs}/getreasoncodes`,
        GetEquipments: `${CONFIG.API.Customs}/GetEquipments`,
        UpdateEquipments: `${CONFIG.API.Customs}/UpdateEquipments`
    };

    public static BillRelease = {
        ReleaseUpdate: `${CONFIG.API.ContainerTracking}/CarrierRelaseUpdate`,
        RequestCarrierRelease: `${CONFIG.API.ContainerTracking}/CarrierContainerReleaseManually`,
        CblList: `${CONFIG.API.ContainerTracking}/GetCBLsListCarrierRelease`,
        HblList: `${CONFIG.API.ContainerTracking}/GetHBLsListCarrierRelease`,
        CblDetails: (id: number) => `${CONFIG.API.ContainerTracking}/GetCBLReleaseTrackingDetails?blid=${id}`,
        HblDetails: (id: number) => `${CONFIG.API.ContainerTracking}/GetHBLReleaseTrackingDetails?hblid=${id}`,
        EditCblDetails: `${CONFIG.API.ContainerTracking}/OriginalBLReceivedUpdate`,
        EditHblDetails: `${CONFIG.API.ContainerTracking}/EditHBLReleaseTrackingDetails `,
        NotifyCustomerWithCarrierContainerRelease: `${CONFIG.API.ContainerTracking}/NotifyCustomerWithCarrierContainerRelease`,
    };

    public static DemurrageAndDetention = {
        GetDDContracts: `${CONFIG.API.DeliveryPlanning}/GetDDContracts`,
        AddDDContract: `${CONFIG.API.DeliveryPlanning}/AddDDContract`,
        UpdateDDContract: `${CONFIG.API.DeliveryPlanning}/UpdateDDContract`,
        DeleteContract: (id: number) => `${CONFIG.API.DeliveryPlanning}/DDContract/${id}`,
        GetDemurrageEquipments: `${CONFIG.API.DeliveryPlanning}/GetDemurrageEquipments`,
        GetDetentionEquipments: `${CONFIG.API.DeliveryPlanning}/GetDetentionEquipments`,
        EditDnDContainerDetails: `${CONFIG.API.DeliveryPlanning}/EditDnDContainerDetails`,
    };

    public static UAM = {
        GetCTBusinessFunctions: `${CONFIG.API.ContainerTracking}/GetUAMBusinessFunctionsForUserToken`,
        GetCustomsBusinessFunctions: `${CONFIG.API.Customs}/GetUAMBusinessFunctionsForUserToken`,
        GetDPBusinessFunctions: `${CONFIG.API.DeliveryPlanning}/GetUAMBusinessFunctionsForUserToken`,
    }

    public static Fact = {
        GetData: `${CONFIG.API.FactWrapper}/GetDocuments`,
        GetASNMessages: `${CONFIG.API.FactWrapper}/GetDocumentASNs`,
        GetDocumentDetails: `${CONFIG.API.FactWrapper}/GetDocumentDetails`,

        AddOfficePrefix: `${CONFIG.API.FactWrapper}/AddOfficePrefix`,
        GetOfficePrefixes: `${CONFIG.API.FactWrapper}/GetOfficePrefixes`,
        DeleteOfficePrefix: `${CONFIG.API.FactWrapper}/DeleteOfficePrefix`,
        UpdateOfficePrefix: `${CONFIG.API.FactWrapper}/UpdateOfficePrefix`,
        GetEquipmentDetails: `${CONFIG.API.FactWrapper}/GetEquipmentDetails`,
        GetEquipmentDeliveryShippmentDetails: `${CONFIG.API.FactWrapper}/getEquipmentTransportShipmentDetails`,
        GetCblDetails: `${CONFIG.API.FactWrapper}/getCblDetails`,
        GetEquipmentsList: `${CONFIG.API.FactWrapper}/GetEquipments`,
        GetFile: `${CONFIG.API.FactWrapper}/GetFile`,
        GetESSOList: `${CONFIG.API.FactWrapper}/GetEssos`,
        GetISSOList: `${CONFIG.API.FactWrapper}/GetIssos`,
        GetCblsList: `${CONFIG.API.FactWrapper}/GetCBLs`,
        FetchShipmentDetails: `${CONFIG.API.FactWrapper}/GetShipmentDetails`,
        EssoResend: `${CONFIG.API.FactWrapper}/resend-esso`,
        IssoResend: `${CONFIG.API.FactWrapper}/resend-isso`,
        GenerateDQReport: `${CONFIG.API.FactWrapper}/export-dq-issues`
    }

    public static VesselSchedule = {
        FetchList: `${CONFIG.API.VesselSchedule}/GetVesselScheduleList`,
        CreateVesselSchedule: `${CONFIG.API.VesselSchedule}/CreateVesselSchedule`,
        UpdateVesselSchedule: `${CONFIG.API.VesselSchedule}/UpdateVesselSchedule`,
    }

    public static Export = {
        ExportEquipmentPlanningToExcel: `${CONFIG.API.DeliveryPlanning}/ExpEqPlanningToExcel`,
        ExportEquipmentDeliveryAndReturnEquipmentToExcel: `${CONFIG.API.DeliveryPlanning}/ExportEqDelivandRetEqToExcel`,
        ExportEquipmentDeliveryAndReturnLocationToExcel: `${CONFIG.API.DeliveryPlanning}/ExportEqDelivandRetLocToExcel`,
        ExportDemurrageToExcel: `${CONFIG.API.DeliveryPlanning}/ExportDemurrageToExcel`,
        ExportDetentionToExcel: `${CONFIG.API.DeliveryPlanning}/ExportDetentionToExcel`,
        ExportVesselTrackingToExcel: `${CONFIG.API.ContainerTracking}/ExportVesselTrackingToExcel`,
        ExportContainerTrackingToExcel: `${CONFIG.API.ContainerTracking}/ExportBLSTrackToExcel`,
        ExportHblReleaseToExcel: `${CONFIG.API.ContainerTracking}/ExportHBLsListCarrRelease`,
        ExportCblReleaseToExcel: `${CONFIG.API.ContainerTracking}/ExportCBLsListCarrRelease`,
        ExportCustomsToExcel: `${CONFIG.API.Customs}/ExportCustomsToExcel`,
        ExportCustomsReleaseToExcel: `${CONFIG.API.Customs}/ExportEquipmentsToExcel`,
        ExportTransportDetailsToExcel: `${CONFIG.API.DeliveryPlanning}/ExpTranspDetToExcel`
    };

    public static Import = {
        ImportFinalDeliveryDetails: (isAir?: boolean) => `${CONFIG.API.DeliveryPlanning}/import/${isAir ? 'aircontainerplanning' : 'oceancontainerplanning'}`,
        ImportTransportDetails: (isAir?: boolean) => `${CONFIG.API.DeliveryPlanning}/import-transport-details/${isAir ? 'air' : 'ocean'}`
    };

    public static NotifyCustomer: string = `${CONFIG.API.LogicApps.NotifyCustomer}`;

    public static Presentation = {
        GetLogEventsFromDatabase: `${CONFIG.API.Presentation}/GetLogEventsFromDatabase`
    }

    public static CustomerManagement = {
        AddNewCustomer: `${CONFIG.API.ContainerTracking}/AddCustomerConfiguration`
    }

    public static Headers: object = {
        'Access-Control-Allow-Origin': '*',
        'Access-Control-Allow-Headers': 'Origin, X-Requested-With, Content-Type, Accept'
    };
}

export default API;
