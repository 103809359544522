export enum Transport_Mode {
    Truck = 1,
    Barge = 2,
    Rail = 3,
    Storage = 4,
}

export enum Transport_Type {
    GasCheck = 1,
    Fumigation = 2,
    InlandDepot = 3,
    DarkStorage = 4,
    DeconWarehouse = 5,
    Shunt = 6,
    DropAndswap = 7,
    LiveTip = 8,
    MilkRun = 9,
    DeRig = 10,
    EmptyReturn = 11,
    OutPort = 12
}

export enum Transport_Order_Type {
    Trucking = 0,
    DeRig = 1,
    DarkStorage = 2,
    Rail = 3,
    Barge = 4
}

export const TRANSPORT_MODE_OPTIONS = [
    { value: Transport_Mode.Truck, optionText: 'Truck' },
    { value: Transport_Mode.Barge, optionText: 'Barge' },
    { value: Transport_Mode.Rail, optionText: 'Rail' },
    { value: Transport_Mode.Storage, optionText: 'Storage' },
];

export const TRANSPORT_TYPE_OPTIONS = {
    [Transport_Mode.Truck]: [
        { value: Transport_Type.GasCheck, optionText: 'Gas check' },
        { value: Transport_Type.Fumigation, optionText: 'Fumigation' },
        { value: Transport_Type.InlandDepot, optionText: 'Inland Depot' },
        { value: Transport_Type.Shunt, optionText: 'Shunt' },
        { value: Transport_Type.OutPort, optionText: 'Out Port' },
        { value: Transport_Type.DropAndswap, optionText: 'Drop and swap' },
        { value: Transport_Type.LiveTip, optionText: 'LiveTip' },
        { value: Transport_Type.MilkRun, optionText: 'MilkRun' },
        { value: Transport_Type.EmptyReturn, optionText: 'Empty return' },
        { value: Transport_Type.DeRig, optionText: 'DeRig' },
        { value: Transport_Type.DarkStorage, optionText: 'Dark Storage' },
    ],
    [Transport_Mode.Barge]: [
        { value: Transport_Type.OutPort, optionText: 'Out Port' },
        { value: Transport_Type.InlandDepot, optionText: 'Inland Depot' },
    ],
    [Transport_Mode.Rail]: [
        { value: Transport_Type.OutPort, optionText: 'Out Port' },
        { value: Transport_Type.InlandDepot, optionText: 'Inland Depot' },
    ],
    [Transport_Mode.Storage]: [
        { value: Transport_Type.InlandDepot, optionText: 'Inland Depot' },
        { value: Transport_Type.DarkStorage, optionText: 'Dark Storage' },
        { value: Transport_Type.DeconWarehouse, optionText: 'Decon Warehouse' },
        { value: Transport_Type.DeRig, optionText: 'DeRig' },
    ]
};

export const ALL_TRANSPORT_TYPE_OPTIONS = TRANSPORT_MODE_OPTIONS.reduce((types, transportMode) => [
    ...types,
    ...TRANSPORT_TYPE_OPTIONS[transportMode.value]
], []);
