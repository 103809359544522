import { 
    CARRIER_RELEASE_ROUTES, 
    CUSTOMS_ROUTES, 
    DELIVERY_ROUTES, 
    PLANNING_ROUTES, 
    TRACKING_ROUTES, 
    VESSEL_SCHEDULE_ROUTES, 
    EXCEPTION_MANAGEMENT_ROUTES 
} from 'constants/routes';

export enum BUSINNESS_FUNCTIONS {
    OCEAN_VESSEL_TRACKING_LIST = 'Ocean-VesselTracking-List',
    OCEAN_VESSEL_TRACKING_DETAILS = 'Ocean-VesselTracking-Details',
    OCEAN_VESSEL_TRACKING_EDIT_VESSEL = 'Ocean-VesselTracking-EditVessel',
    OCEAN_MBL_TRACKING_LIST = 'Ocean-MBLTracking-List',
    OCEAN_MBL_TRACKING_DETAILS = 'Ocean-MBLTracking-Details',
    OCEAN_MBL_TRACKING_EDIT_CBL = 'Ocean-MBLTracking-EditCBL',
    OCEAN_MBL_TRACKING_NOTIFY_CUSTOMER = 'Ocean-MBLTracking-NotifyCustomer',
    OCEAN_MILESTONES_LIST = 'Milestone-List',
    OCEAN_MILESTONES_MODAL = 'Milestone-Modal',
    OCEAN_HBL_RELEASE_LIST = 'Ocean-HBLRelease-List',
    OCEAN_HBL_RELEASE_DETAILS = 'Ocean-HBLRelease-Details',
    OCEAN_HBL_RELEASE_EDIT_HBL = 'Ocean-HBLRelease-EditHBL',
    OCEAN_CBL_RELEASE_LIST = 'Ocean-CBLRelease-List',
    OCEAN_CBL_RELEASE_DETAILS = 'Ocean-CBLRelease-Details',
    OCEAN_CBL_RELEASE_EDIT_CBL = 'Ocean-CBLRelease-EditCBL',
    OCEAN_CBL_RELEASE_REQUEST_RELEASE = 'Ocean-CBLRelease-RequestRelease',
    OCEAN_CBL_RELEASE_UPDATE_RELEASE_DETAILS = 'Ocean-CBLRelease-UpdateReleaseDetails',
    OCEAN_CUSTOMS_LIST = 'Ocean-Customs-List',
    OCEAN_CUSTOMS_HBL_DETAILS = 'Ocean-Customs-HBLDetails',
    OCEAN_CUSTOMS_UPDATE_HBL_CUSTOMS_DETAILS = 'Ocean-Customs-UpdateHBLCustomsDetails',
    OCEAN_CUSTOMS_CONTAINER_DETAILS = 'Ocean-Customs-ContainerDetails',
    OCEAN_CUSTOMS_NOTIFY_CUSTOMER = 'Ocean-Customs-NotifyCustomer',
    OCEAN_PLANNING_CONTAINER_LIST = 'Ocean-Planning-ContainerList',
    OCEAN_PLANNING_CONTAINER_DETAILS = 'Ocean-Planning-ContainerDetails',
    OCEAN_PLANNING_EDIT_DELIVERY_DETAILS = 'Ocean-Planning-EditDeliveryDeatils',
    OCEAN_PLANNING_EDIT_TRANSPORTATION_DETAILS = 'Ocean-Planning-EditTransportationDetails',
    OCEAN_PLANNING_SEND_PRE_ALERT = 'Ocean-Planning-SendPreAlert',
    OCEAN_PLANNING_SEND_TRANSPORT_ORDER = 'Ocean-Planning-SendTransportOrder',
    OCEAN_PLANNING_SEND_DE_RIGGING_REQUEST = 'Ocean-Planning-SendDeRiggingRequest',
    OCEAN_PLANNING_EDIT_CONTAINER_DETAILS_AND_DELIVERY_LEG = 'Ocean-Planning-EditContainerDetailsAndDeliveryLeg',
    OCEAN_DELIVERY_EQUIPMENT_LIST = 'Ocean-Delivery-Equipment-List',
    OCEAN_DELIVERY_EQUIPMENT_DETAILS = 'Ocean-Delivery-Equipment-Details',
    OCEAN_DELIVERY_EQUIPMENT_UPDATE_DELIVERY_DETAILS = 'Ocean-Delivery-Equipment-UpdateDeliveryDetails',
    OCEAN_DELIVERY_EQUIPMENT_UPDATE_ACTUALS = 'Ocean-Delivery-Equipment-UpdateActuals',
    OCEAN_DELIVERY_LOCATION_LIST = 'Ocean-Delivery-Location-List',
    OCEAN_DELIVERY_EDIT_TRANSPORTATION_DETAILS = 'Ocean-Delivery-EditTransportationDetails',
    OCEAN_DD_DEMURRAGE_LIST = 'Ocean-DD-DemurrageList',
    OCEAN_DD_DETENTION_LIST = 'Ocean-DD-DetentionList',
    OCEAN_DD_EQUIPMENT_DETAILS = 'Ocean-DD-EquipmentDetails',
    OCEAN_DD_EQUIPMENT_UPDATE_DELIVERY_DETAILS = 'Ocean-DD-EquipmentUpdateDeliveryDetails',
    OCEAN_VESSEL_SCHEDULE_LIST = 'Ocean-VesselSchedule-List',
    OCEAN_VESSEL_SCHEDULE_LIST_REQUEST_SCHEDULE = 'Ocean-VesselSchedule-RequestSchedule',
    AIR_MAWB_TRACKING_LIST = 'Air-MAWBTracking-List',
    AIR_MAWB_TRACKING_DETAILS = 'Air-MAWBTracking-Details',
    AIR_MAWB_TRACKING_EDIT_CBL = 'Air-MAWBTracking-EditCBL',
    AIR_MAWB_TRACKING_NOTIFY_CUSTOMER = 'Air-MAWBTracking-NotifyCustomer',
    AIR_HAWB_RELEASE_LIST = 'Air-HAWBRelease-List',
    AIR_HAWB_RELEASE_DETAILS = 'Air-HAWBRelease-Details',
    AIR_HAWB_RELEASE_EDIT_HBL = 'Air-HAWBRelease-EditHBL',
    AIR_CUSTOMS_LIST = 'Air-Customs-List',
    AIR_CUSTOMS_HBL_DETAILS = 'Air-Customs-HBLDetails',
    AIR_CUSTOMS_UPDATE_HBL_CUSTOMS_DETAILS = 'Air-Customs-UpdateHBLCustomsDetails',
    AIR_CUSTOMS_CONTAINER_DETAILS = 'Air-Customs-ContainerDetails',
    AIR_CUSTOMS_NOTIFY_CUSTOMER = 'Air-Customs-NotifyCustomer',
    AIR_PLANNING_CONTAINER_LIST = 'Air-Planning-ContainerList',
    AIR_PLANNING_CONTAINER_DETAILS = 'Air-Planning-ContainerDetails',
    AIR_PLANNING_EDIT_DELIVERY_DETAILS = 'Air-Planning-EditDeliveryDeatils',
    AIR_PLANNING_EDIT_TRANSPORTATION_DETAILS = 'Air-Planning-EditTransportationDetails',
    AIR_PLANNING_SEND_PRE_ALERT = 'Air-Planning-SendPreAlert',
    AIR_PLANNING_SEND_TRANSPORT_ORDER = 'Air-Planning-SendTransportOrder',
    AIR_PLANNING_SEND_DE_RIGGIN_REQUEST = 'Air-Planning-SendDeRiggingRequest',
    AIR_PLANNING_EDIT_CONTAINER_DETAILS_AND_DELIVERY_LEG = 'Air-Planning-EditContainerDetailsAndDeliveryLeg',
    AIR_PLANNING_CONFIRM_AVAILABILITY = 'Air-Planning-ConfirmAvailability',
    AIR_DELIVERY_EQUIPMENT_LIST = 'Air-Delivery-Equipment-List',
    AIR_DELIVERY_EQUIPMENT_DETAILS = 'Air-Delivery-Equipment-Details',
    AIR_DELIVERY_EQUIPMENT_UPDATE_DELIVERY_DETAILS = 'Air-Delivery-Equipment-UpdateDeliveryDetails',
    AIR_DELIVERY_DETAILS_EQUIPMENT_UPDATE_ACTUALS = 'Air-Delivery-Equipment-UpdateActuals',
    AIR_DELIVERY_EDIT_TRANSPORTATION_DETAILS = 'Air-Delivery-EditTransportationDetails',
    OCEAN_FACT_WRAPPER_LIST = 'Ocean-FactWrapper-List',
    AIR_FACT_WRAPPER_LIST = 'Air-FactWrapper-List',
    CONFIGURATION_GLOBAL = 'Configuration-Global',
    CONFIGURATION_CONTAINER_TRACKING = 'Configuration-ContainerTracking',
    CONFIGURATION_DELIVERY_PLANNING = 'Configuration-DeliveryPlanning',
    CONFIGURATION_CUSTOMS = 'Configuration-Customs',
    CONFIGURATION_FACT_WRAPPER = 'Configuration-FactWrapper',
    CONFIGURATION_VESSEL_SCHEDULE = 'Configuration-VesselSchedule',
    ADMINISTRATOR = 'ADMINISTRATOR',
    OCEAN_PO_PLANNING_NOTIFY_CUSTOMER = 'Ocean-POPlanning-NotifyCustomer',
    AIR_PO_PLANNING_NOTIFY_CUSTOMER = 'Air-POPlanning-NotifyCustomer',
    OCEAN_PO_PLANNING_GET_POS = 'Ocean-POPlanning-GetPOs',
    AIR_PO_PLANNING_GET_POS = 'Air-POPlanning-GetPOs',
    MONITORING_EVENT_LOGS_LIST = 'Monitoring-EventLogs-List',
    OCEAN_MBL_TRACKING_EDIT_MRN = 'Ocean-MBLTracking-EditMRN',
    AIR_MAWB_TRACKING_EDIT_MRN = 'Air-MAWBTracking-EditMRN',
    CUSTOMER_MANAGEMENT = 'Customer-Management'
}

export const defaultRedirect = {
    [BUSINNESS_FUNCTIONS.OCEAN_VESSEL_TRACKING_LIST]: TRACKING_ROUTES.VESSEL_TRACKING,
    [BUSINNESS_FUNCTIONS.OCEAN_MBL_TRACKING_LIST]: TRACKING_ROUTES.OCEAN_MBL_TRACKING,
    [BUSINNESS_FUNCTIONS.AIR_MAWB_TRACKING_LIST]: TRACKING_ROUTES.MAWB_TRACKING,
    [BUSINNESS_FUNCTIONS.OCEAN_HBL_RELEASE_LIST]: CARRIER_RELEASE_ROUTES.HBL_RELEASE,
    [BUSINNESS_FUNCTIONS.OCEAN_CBL_RELEASE_LIST]: CARRIER_RELEASE_ROUTES.CBL_RELEASE,
    [BUSINNESS_FUNCTIONS.AIR_HAWB_RELEASE_LIST]: CARRIER_RELEASE_ROUTES.HAWB_RELEASE,

    [BUSINNESS_FUNCTIONS.OCEAN_CUSTOMS_LIST]: CUSTOMS_ROUTES.OCEAN_CLEARANCE,
    [BUSINNESS_FUNCTIONS.AIR_CUSTOMS_LIST]: CUSTOMS_ROUTES.AIR_CLEARANCE,

    [BUSINNESS_FUNCTIONS.OCEAN_PLANNING_CONTAINER_LIST]: PLANNING_ROUTES.CONTAINER_PLANNING,
    [BUSINNESS_FUNCTIONS.AIR_PLANNING_CONTAINER_LIST]: PLANNING_ROUTES.HAWB_PLANNING,
    [BUSINNESS_FUNCTIONS.OCEAN_DELIVERY_EQUIPMENT_LIST]: DELIVERY_ROUTES.EQUIPMENT,
    [BUSINNESS_FUNCTIONS.OCEAN_DELIVERY_LOCATION_LIST]: DELIVERY_ROUTES.LOCATION,
    [BUSINNESS_FUNCTIONS.OCEAN_DD_DEMURRAGE_LIST]: DELIVERY_ROUTES.DD_DEMURRAGE,
    [BUSINNESS_FUNCTIONS.OCEAN_DD_DETENTION_LIST]: DELIVERY_ROUTES.DD_DETENTION,
    [BUSINNESS_FUNCTIONS.AIR_DELIVERY_EQUIPMENT_LIST]: DELIVERY_ROUTES.AIR_DELIVERY,

    [BUSINNESS_FUNCTIONS.OCEAN_VESSEL_SCHEDULE_LIST]: VESSEL_SCHEDULE_ROUTES.VESSEL_SCHEDULE,  
    [BUSINNESS_FUNCTIONS.OCEAN_FACT_WRAPPER_LIST]: EXCEPTION_MANAGEMENT_ROUTES.FACT,
    [BUSINNESS_FUNCTIONS.AIR_FACT_WRAPPER_LIST]: EXCEPTION_MANAGEMENT_ROUTES.FACT,
    [BUSINNESS_FUNCTIONS.CONFIGURATION_CONTAINER_TRACKING]: EXCEPTION_MANAGEMENT_ROUTES.SETTINGS,
    [BUSINNESS_FUNCTIONS.CONFIGURATION_DELIVERY_PLANNING]: EXCEPTION_MANAGEMENT_ROUTES.SETTINGS,
    [BUSINNESS_FUNCTIONS.CONFIGURATION_CUSTOMS]: EXCEPTION_MANAGEMENT_ROUTES.SETTINGS,
    [BUSINNESS_FUNCTIONS.CONFIGURATION_FACT_WRAPPER]: EXCEPTION_MANAGEMENT_ROUTES.SETTINGS,
    [BUSINNESS_FUNCTIONS.CONFIGURATION_VESSEL_SCHEDULE]: EXCEPTION_MANAGEMENT_ROUTES.SETTINGS,
    [BUSINNESS_FUNCTIONS.MONITORING_EVENT_LOGS_LIST]: EXCEPTION_MANAGEMENT_ROUTES.EVENT_LOGS,
    [BUSINNESS_FUNCTIONS.CONFIGURATION_GLOBAL]: EXCEPTION_MANAGEMENT_ROUTES.SETTINGS,
    [BUSINNESS_FUNCTIONS.CUSTOMER_MANAGEMENT]: EXCEPTION_MANAGEMENT_ROUTES.CUSTOMER_MANAGEMENT
};

export const ALL_ROLES = [
    BUSINNESS_FUNCTIONS.OCEAN_VESSEL_TRACKING_LIST,
    BUSINNESS_FUNCTIONS.OCEAN_MBL_TRACKING_LIST,
    BUSINNESS_FUNCTIONS.AIR_MAWB_TRACKING_LIST,
    BUSINNESS_FUNCTIONS.OCEAN_HBL_RELEASE_LIST,
    BUSINNESS_FUNCTIONS.OCEAN_CBL_RELEASE_LIST,
    BUSINNESS_FUNCTIONS.AIR_HAWB_RELEASE_LIST,
    BUSINNESS_FUNCTIONS.OCEAN_CUSTOMS_LIST,
    BUSINNESS_FUNCTIONS.AIR_CUSTOMS_LIST,
    BUSINNESS_FUNCTIONS.OCEAN_PLANNING_CONTAINER_LIST,
    BUSINNESS_FUNCTIONS.AIR_PLANNING_CONTAINER_LIST,
    BUSINNESS_FUNCTIONS.OCEAN_DELIVERY_EQUIPMENT_LIST,
    BUSINNESS_FUNCTIONS.OCEAN_DELIVERY_LOCATION_LIST,
    BUSINNESS_FUNCTIONS.OCEAN_DD_DEMURRAGE_LIST,
    BUSINNESS_FUNCTIONS.OCEAN_DD_DETENTION_LIST,
    BUSINNESS_FUNCTIONS.AIR_DELIVERY_EQUIPMENT_LIST,
    BUSINNESS_FUNCTIONS.OCEAN_VESSEL_SCHEDULE_LIST,
    BUSINNESS_FUNCTIONS.OCEAN_FACT_WRAPPER_LIST,
    BUSINNESS_FUNCTIONS.MONITORING_EVENT_LOGS_LIST,
    BUSINNESS_FUNCTIONS.CONFIGURATION_GLOBAL,
    BUSINNESS_FUNCTIONS.CUSTOMER_MANAGEMENT
];

