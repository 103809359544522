import React, { Component } from 'react';
import { PageHeader } from 'components/page-header';
import { Page } from 'components/page-container';
import { AddNewCustomer } from 'modules/exception-management/customers';
import { ThunkDispatch } from 'redux-thunk';
import { Action } from 'redux';
import { addNewCustomer } from 'actions/customerManagement';
import { connect } from 'react-redux';
import { IStore } from 'reducers/index';
import { ErrorDialog } from 'components/error-dialog';
import API from 'constants/api';

interface IMapDispatch {
  addNewCustomer: (name: string) => Promise<void>;
  verifyCustomer: (name: string) => Promise<void>;
}

interface IMapStateToProps {
  isLoading: boolean;
  requestSucceeded: boolean;
}

interface CustomerManagementProps extends IMapStateToProps, IMapDispatch {}

class _CustomerManagement extends Component<CustomerManagementProps> {
  public state = {
    selectedEventLog: undefined,
    page: 1,
    pageSize: 10,
  };

  public render() {
    return (
      <React.Fragment>
        <PageHeader>Customer management</PageHeader>
        <Page>
          <ErrorDialog endpoint={API.CustomerManagement.AddNewCustomer} />
          <AddNewCustomer
            addCustomerFunction={this.props.addNewCustomer}
            verifyCustomerFunction={this.props.verifyCustomer}
            isLoading={this.props.isLoading}
            requestSucceeded={this.props.requestSucceeded}
          />
        </Page>
      </React.Fragment>
    );
  }
}

const mapStateToProps = (state: IStore): IMapStateToProps => ({
  isLoading: state.customerManagementReducer.isLoading,
  requestSucceeded: state.customerManagementReducer.requestSucceeded,
});

const mapDispatchToProps = (
  dispatch: ThunkDispatch<any, any, Action>
): IMapDispatch => ({
  addNewCustomer: (name: string) => dispatch(addNewCustomer(name, false)),
  verifyCustomer: (name: string) => dispatch(addNewCustomer(name, true)),
});

export const CustomerManagement = connect(
  mapStateToProps,
  mapDispatchToProps
)(_CustomerManagement);
