import { cloneDataWithDisplayNames } from 'utilities/tables';
import { EVENT_LOGS } from 'action-types/event-logs';
import { IEventLogsBody, IEventLogsAction } from './models';

export interface IEventLogsState {
    readonly logs: IEventLogsBody;
}

const defaultState: IEventLogsState = {
    logs: {
        pageNumber: 1,
        pageSize: 10,
        result: [],
        totalCount: 0
    }
};

export interface IEventsAction {
    readonly type: string;
    readonly data: IEventLogsAction;
}

export const reducer = (state: IEventLogsState = defaultState, action: IEventsAction): IEventLogsState => {
    switch (action.type) {
        case EVENT_LOGS.DATA_FETCHED:
            return {
                ...state,
                logs: {
                    result: cloneDataWithDisplayNames(action.data.result, 'DD-MM-YYYY HH:mm') || defaultState.logs.result,
                    pageNumber: action.data.pageNumber || defaultState.logs.pageNumber,
                    pageSize: action.data.pageSize || defaultState.logs.pageSize,
                    totalCount: action.data.totalCount || defaultState.logs.totalCount
                }
            };
        default:
            return state;
    }
};
