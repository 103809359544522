import styled from 'styled-components';

export const NavbarContainer: any = styled.div`
    padding: 0;
    display: flex;
    justify-content: space-between;
    position: relative;
`;

export const NavbarMenu: any = styled.nav`
    padding: 0 !important;
`;