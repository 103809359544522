import React, { Component } from 'react';
import { PageHeader } from 'components/page-header';
import { Page } from 'components/page-container';
import { Filters } from 'modules/filters/containers/Filters';
import { FiltersInput } from 'modules/filters/components/FiltersInput';
import { IStore } from 'reducers/index';
import { ThunkDispatch } from 'redux-thunk';
import { Action } from 'redux';
import { connect } from 'react-redux';
import { EventLogsTable } from './components/EventLogsTable';
import { FiltersDateInput } from 'modules/filters/components/FiltersDateInput';
import { fetchEventLogs } from 'actions/event-logs';
import { FiltersSelect } from 'modules/filters/components/FiltersSelect';
import { IOption } from 'components/select/Select';
import { EventLogDetailsPopup } from './components/EventLogDetailsPopup';
import { IEventLog, IEventLogsAction } from 'reducers/event-logs/models';
import { setSort } from 'actions/filters';
import { ISortModel } from 'reducers/filters/models';
import { setSortForTable } from 'utilities/tables';

const TYPE_OPTIONS: IOption[] = [
    { optionText: 'Inbound', value: 'Inbound' },
    { optionText: 'Outbound', value: 'Outbound' },
];

const filters = [
    <FiltersDateInput
        name="EventLogDate_Start"
        label="Event log date (from)"
        key="dateFrom"
        type="date"
        withTime={true}
    />,
    <FiltersDateInput name="EventLogDate_End" label="Event log date (to)" key="dateTo" type="date" withTime={true} />,
    <FiltersInput name="Status" label="Status" key="status" />,
    <FiltersInput name="System" label="System" key="system" />,
    <FiltersInput name="Message" label="Message" key="message" />,
    <FiltersInput name="Body" label="Body" key="body" />,
    <FiltersSelect name="Type" label="Type" key="type" options={TYPE_OPTIONS} />,
    <FiltersSelect
        type="number"
        key="capability"
        name="Capability"
        label="Capability"
        options={[
            {optionText: 'Container tracking', value: 0},
            {optionText: 'Delivery planning', value: 1},
            {optionText: 'Customs', value: 2}
        ]}
    />,
    <FiltersInput name="CBLNumber" label="CBL Number" key="cbl" />,
    <FiltersInput name="VesselNumber" label="Vessel Number" key="vessel" />,
    <FiltersInput name="VoyageNumber" label="Voyage Number" key="voyage" />,
    <FiltersInput name="EquipmentNumber" label="Equipment Number" key="equipment" />,
    <FiltersInput name="PONumber" label="PO Number" key="po" />,
    <FiltersInput name="CorrelationId" label="Correlation ID" key="correlationId" />,
    <FiltersInput name="RunId" label="Run ID" key="runId" />,
    <FiltersInput name="Action" label="Action" key="action" />,
    <FiltersInput name="InputBody" label="Input Body" key="inputBody" />,
    <FiltersInput name="HBL" label="HBL Number" key="hbl" />,
    <FiltersInput name="FCR" label="FCR Number" key="fcr" />,
    <FiltersInput name="SKUNumber" label="SKU Number" key="sku" />,
    <FiltersInput name="Workflow" label="Workflow" key="workflow" />,
    <FiltersInput name="User" label="Triggered by" key="user" />,
];

interface IMapState {
    data: IEventLogsAction;
}

interface IMapDispatch {
    fetchData: (pageNumber: number, pageSize: number) => Promise<void>;
    setSort: (sort: ISortModel) => void;
}

interface IEventLogsProps extends IMapState, IMapDispatch {}

interface IEventLogsState {
    selectedEventLog?: IEventLog;
    page: number;
    pageSize: number;
}

class _EventLogs extends Component<IEventLogsProps, IEventLogsState> {
    public state = {
        selectedEventLog: undefined,
        page: 1,
        pageSize: 10
    };

    public render() {
        return (
            <React.Fragment>
                <PageHeader>Event logs</PageHeader>
                <Page>
                    <Filters 
                        tableNameKey="fact" 
                        fetchFunction={() => this.props.fetchData(this.state.page, this.state.pageSize)} 
                        filters={filters} 
                    />
                    <EventLogsTable
                        data={this.props.data.result}
                        openDialog={eventLog => this.setState({ selectedEventLog: eventLog })}
                        pageCount={Math.ceil(this.props.data.totalCount / this.props.data.pageSize)}
                        fetchEvents={this.fetchEventsList}
                    />
                    <EventLogDetailsPopup
                        eventLog={this.state.selectedEventLog}
                        visible={!!this.state.selectedEventLog}
                        closeModal={() => this.setState({ selectedEventLog: undefined })}
                    />
                </Page>
            </React.Fragment>
        );
    }

    private fetchEventsList = (tableData: any) => {
        this.setState({
            page: tableData.page + 1,
            pageSize: tableData.pageSize
        }, () => {
            setSortForTable(tableData.sorted, this.props.setSort);
            this.props.fetchData(this.state.page, this.state.pageSize);
        });
    };
}

const mapStateToProps = (state: IStore): IMapState => ({
    data: state.eventLogs.logs,
});

const mapDispatch = (dispatch: ThunkDispatch<any, any, Action>): IMapDispatch => ({
    fetchData: (pageNumber: number, pageSize: number) => dispatch(fetchEventLogs(pageNumber, pageSize)),
    setSort: (sort: ISortModel) => dispatch(setSort(sort)),
});

export const EventLogs = connect<IMapState, IMapDispatch, {}, IStore>(mapStateToProps, mapDispatch)(_EventLogs);
