import React from 'react';
import { ColumnFactory } from 'components/data-table/ColumnFactory';
import { IOfficePrefix } from 'reducers/fact/models';
import { connect } from 'react-redux';
import { DataTable } from 'components/data-table/DamcoTable';
import { PrefixModal } from '../components/PrefixModal';
import { ThunkDispatch } from 'redux-thunk';
import { Action } from 'redux';
import { addFactOfficePrefix, updateFactOfficePrefix, deleteFactOfficePrefix, getFactOfficePrefixes } from 'actions/settings';

interface IPrefixesTableOwnProps {
    data: IOfficePrefix[];
    pageCount: number;
    fetchPrefixes: any;
}

interface IMapDispatchToProps {
    addOfficePrefix: (body: IOfficePrefix) => Promise<void>;
    getOfficePrefixs: () => Promise<void>;
    updateOfficePrefix: (body: IOfficePrefix) => Promise<void>;
    removeOfficePrefix: (body: IOfficePrefix) => Promise<void>;
}

interface IPrefixesTableProps extends IPrefixesTableOwnProps, IMapDispatchToProps {}

interface IPrefixesTableState {
    isAddPrefixModalOpened: boolean;
    isEditPrefixModalOpened: boolean;
}

class _PrefixesTable extends React.Component<IPrefixesTableProps, IPrefixesTableState> {
    public dataTable = React.createRef<any>();
    
    public state: IPrefixesTableState = {
        isAddPrefixModalOpened: false,
        isEditPrefixModalOpened: false
    };

    public render() {
        return (
            <React.Fragment>
                <DataTable
                    data={this.props.data}
                    withCheckboxes={true}
                    expandable={false}
                    selectVariant="single"
                    columns={[
                        ColumnFactory.standardColumn('placeOfReceiptSite', 'Place of receipt site'),
                        ColumnFactory.standardColumn('prefix', 'Prefix'),
                        ColumnFactory.standardColumn('countryCodeOfDocumentIssuer', 'Country coude of document issuer'),
                        ColumnFactory.standardColumn('businessEntityCode', 'Business entity code'),
                        ColumnFactory.standardColumn('functionCode', 'Function code'),
                        ColumnFactory.standardColumn('description', 'Description'),
                    ]}
                    actions={{
                        primaryActionIsMoreBtn: false,
                        primaryActionLabel: 'Add prefix',
                        primaryAction: this.toggleAddPrefixModal,
                        parentContextualActions: [
                            { isMoreBtn: false, label: 'Edit prefix', action: this.toggleEditPrefixModal },
                            { isMoreBtn: false, label: 'Delete prefix', action: this.deletePrefix },
                        ]
                    }}
                    reference={this.dataTable}
                    tableName="SettingsFACTTable"
                    manualPagination={true}
                    fetchData={this.props.fetchPrefixes}
                    pageCount={this.props.pageCount}
                    showPagination={true}
                />
                {this.state.isAddPrefixModalOpened && <PrefixModal
                    title="Add prefix"
                    closeModal={this.toggleAddPrefixModal}
                    saveFunction={this.addPrefix}
                />}
                {this.state.isEditPrefixModalOpened && <PrefixModal
                    title="Edit prefix"
                    closeModal={this.toggleEditPrefixModal}
                    saveFunction={this.updatePrefix}
                    initialValues={this.getSelectedItem() || {}}
                />}
            </React.Fragment>
        );
    }

    private toggleAddPrefixModal = () => this.setState((state) => ({
        isAddPrefixModalOpened: !state.isAddPrefixModalOpened,
    }));

    private toggleEditPrefixModal = () => this.setState((state) => ({
        isEditPrefixModalOpened: !state.isEditPrefixModalOpened,
    }));

    private addPrefix = async (prefix: IOfficePrefix) => {
        await this.props.addOfficePrefix(prefix);
        await this.toggleAddPrefixModal();
    };

    private updatePrefix = async (fields: IOfficePrefix) => {
        await this.props.updateOfficePrefix(fields);
        await this.toggleEditPrefixModal();
        await this.props.getOfficePrefixs();
    };

    private deletePrefix = async () => {
        await this.props.removeOfficePrefix(this.getSelectedItem());
        await this.dataTable.current.hideContextRibbon();
        await this.props.getOfficePrefixs();
    };

    private getSelectedItem = () => {
        const selectedItems = this.dataTable.current ? this.dataTable.current.getSelectedItems() : [];
        if(selectedItems[0]) {
            return selectedItems[0];
        }
        return;
    };
}

const mapDispatchToProps = (dispatch: ThunkDispatch<any,any, Action>): IMapDispatchToProps => ({
    addOfficePrefix: (body: IOfficePrefix) => dispatch(addFactOfficePrefix(body)),
    getOfficePrefixs: () => dispatch(getFactOfficePrefixes()),
    updateOfficePrefix: (prefix: IOfficePrefix) => dispatch(updateFactOfficePrefix(prefix)),
    removeOfficePrefix: (prefix: IOfficePrefix) => dispatch(deleteFactOfficePrefix(prefix)),
})

export const PrefixesTable = connect(undefined, mapDispatchToProps)(_PrefixesTable);
