import React from 'react';
import { LoadingAnimation } from 'components/loading';
import { ColumnFactory } from 'components/data-table/ColumnFactory';
import { IEquipmentEmptyReturn, IEquipmentDetails } from 'reducers/planning/models';
import { connect } from 'react-redux';
import { downloadFile } from 'utilities/download';
import { ThunkDispatch } from 'redux-thunk';
import { Action } from 'redux';
import { exportEquipmentDeliveryAndReturnEquipmentToExcel } from 'actions/export';
import { DataTable } from 'components/data-table/DamcoTable';
import { EditEmptyReturnDetailsPopup } from './EditEmptyReturnDetailsPopup';
import { EditTransportationDetailsModal } from 'views/delivery-and-return/equipment-details/components/EditTransportationDetailsModal';
import { EditDAndDDetailsPopup } from 'modules/modals/components/EditDAndDDetailsPopup';
import { getEquipmentDetails, getEmptyReturnEquipments } from 'actions/planning';
import { IStore } from 'reducers/index';
import { mapStatusToStringForDelivery } from 'constants/statuses';
import { Transport_Mode, Transport_Type} from 'constants/transport';
import { hauliersToEnum, getArrayOfButtons } from 'utilities/util';
import { ISettingsModel } from 'reducers/settings/models';
import { fetchDeliveryPlanningHaulierSettings } from 'actions/settings';
// tslint:disable-next-line: no-submodule-imports
import { withPermissions, IWithPermissions } from 'src/HOC/withPermissions';
import { BUSINNESS_FUNCTIONS } from 'constants/roles';

interface IMapStateToProps {
    equipment: IEquipmentDetails;
    deliveryPlanningHaulierSettings: ISettingsModel[];
    fetchDeliveryPlanningHaulierSettingsPending: boolean;
}

interface IMapDispatchToProps {
    exportEquipmentDeliveryAndReturnEquipmentToExcel: () => Promise<any>;
    getEquipmentDetails: (id: number) => Promise<void>;
    getEmptyReturnEquipments: () => Promise<void>;
    fetchDeliveryPlanningHaulierSettings: () => void;
}

interface IDeliveryAndReturnEquipmentTableOwnProps {
    data: IEquipmentEmptyReturn[];
    isLoading: boolean;
    pageCount: number;
    fetchEquipments: any;
}

interface IDeliveryAndReturnEquipmentTableProps extends IWithPermissions, IDeliveryAndReturnEquipmentTableOwnProps, IMapStateToProps, IMapDispatchToProps {}

interface IDeliveryAndReturnEquipmentTableState {
    updateDeliveryDetailsModal: boolean;
    isEditTransportationDetailsModalOpened: boolean;
    isEditDetailsDnDModalOpened: boolean;
}

class _DeliveryAndReturnEquipmentTable extends React.Component<IDeliveryAndReturnEquipmentTableProps, IDeliveryAndReturnEquipmentTableState> {
    public dataTable = React.createRef<any>();

    public state: IDeliveryAndReturnEquipmentTableState = {
        updateDeliveryDetailsModal: false,
        isEditTransportationDetailsModalOpened: false,
        isEditDetailsDnDModalOpened: false
    };

    public componentDidMount() {
        this.props.fetchDeliveryPlanningHaulierSettings();
    }

    public render() {
        const hauliers = hauliersToEnum(this.props.deliveryPlanningHaulierSettings);
        const data = this.props.data.map((obj: any) => ({
            ...obj,
            equipmentNumberDisplayName: [obj.equipmentNumber, `/delivery-and-return/equipment/${obj.id}`],
            deliveryStatusDisplayName: mapStatusToStringForDelivery(obj.deliveryStatus),
            children: obj.deliveries.map((child: any) => ({
                ...child,
                transportModeDisplayName: Transport_Mode[child.transportMode],
                transportTypeDisplayName: Transport_Type[child.transportType],
                haulierDisplayName: hauliers[child.haulier],
                parentNode: 0
            }))
        }));

        return (
            <div>
                {(this.props.isLoading || this.props.fetchDeliveryPlanningHaulierSettingsPending) && <LoadingAnimation />}
                <DataTable
                    data={data}
                    expandable={true}
                    withCheckboxes={true}
                    selectVariant="single"
                    columns={[
                        ColumnFactory.linkedColumn('equipmentNumberDisplayName', 'Equipment number'),
                        ColumnFactory.standardColumn('isDangerousGoodsDisplayName', 'DG'),
                        ColumnFactory.standardColumn('isGOHDisplayName', 'GOH'),
                        ColumnFactory.standardColumn('isHotBoxDisplayName', 'Hotbox'),
                        ColumnFactory.standardColumn('demurrageDisplayName', 'Demurrage'),
                        ColumnFactory.standardColumn('detentionDisplayName', 'Detention'),
                        ColumnFactory.standardColumn('gateOutDateDisplayName', 'Gate out'),
                        ColumnFactory.standardColumn('deliveryPlannedDateDisplayName', 'Planned delivery'),
                        ColumnFactory.standardColumn('deliveryActualDateDisplayName', 'Actual Delivery'),
                        ColumnFactory.standardColumn('lastLocationDisplayName', 'Last location'),
                        ColumnFactory.standardColumn('deliveryStatusDisplayName', 'Status'),
                    ]}
                    subColumns={[
                        ColumnFactory.standardColumn('transportModeDisplayName', 'Transport mode'),
                        ColumnFactory.standardColumn('transportTypeDisplayName', 'Transport type'),
                        ColumnFactory.standardColumn('haulierDisplayName', 'Haulier'),
                        ColumnFactory.standardColumn('isTransportOrderSentDisplayName', 'TO sent'),
                        ColumnFactory.standardColumn('pickupLocation', 'Pickup location'),
                        ColumnFactory.standardColumn('estimatedPickupDateDisplayName', 'Estimated date'),
                        ColumnFactory.standardColumn('pickupDateDisplayName', 'Pickup date'),
                        ColumnFactory.standardColumn('dropOffLocation', 'Drop-off location'),
                        ColumnFactory.standardColumn('estimatedDropOffDateDisplayName', 'Estimated date'),
                        ColumnFactory.standardColumn('dropOffDateDisplayName', 'Drop-off date'),
                        ColumnFactory.standardColumn('deliveryReferenceDisplayName', 'Delivery reference'),
                    ]}
                    actions={{
                        primaryActionIsMoreBtn: false,
                        primaryActionLabel: 'Export to excel',
                        primaryAction: this.exportToExcel,
                        parentContextualActions: getArrayOfButtons(
                            this.props.permissions,
                            [
                                { isMoreBtn: false, label: 'Update final delivery details', action: this.toggleUpdateDeliveryDetailsModal },
                                { isMoreBtn: false, label: 'Update transport details', action: this.toggleEditTransportationDetailsModal },
                                { isMoreBtn: false, label: 'Update D&D details', action: this.toggleEditDnDDetailsModal }
                            ])
                    }}
                    reference={this.dataTable}
                    tableName="DnREquipmentList"
                    manualPagination={true}
                    fetchData={this.props.fetchEquipments}
                    pageCount={this.props.pageCount}
                    showColumnOptions={true}
                />
                {this.state.updateDeliveryDetailsModal && <EditEmptyReturnDetailsPopup
                    closeModal={this.toggleUpdateDeliveryDetailsModal}
                    visible={this.state.updateDeliveryDetailsModal}
                    initialValues={this.props.equipment}
                    refetchFunction={this.props.getEmptyReturnEquipments as any}
                    status={this.props.equipment.deliveryStatusEnum}
                />}
                {this.state.isEditTransportationDetailsModalOpened && <EditTransportationDetailsModal
                    visible={this.state.isEditTransportationDetailsModalOpened}
                    closeModal={this.toggleEditTransportationDetailsModal}
                    isAir={false}
                    id={this.getId()}
                    refetchFunction={this.props.getEmptyReturnEquipments}
                />}
                {this.state.isEditDetailsDnDModalOpened && <EditDAndDDetailsPopup
                    visible={this.state.isEditDetailsDnDModalOpened}
                    closeModal={this.toggleEditDnDDetailsModal}
                    id={this.getId()}
                    refetchFunction={this.props.getEmptyReturnEquipments}
                />}
            </div>
        );
    }

    private exportToExcel = async () => {
        const res = await this.props.exportEquipmentDeliveryAndReturnEquipmentToExcel();
        await downloadFile(res);
    };

    private getId = () => {
        const selectedItems = this.dataTable.current ? this.dataTable.current.getSelectedItems() : [];

        return selectedItems[0].id;
    };

    private toggleUpdateDeliveryDetailsModal = async () => {
        const selectedItems = this.dataTable.current ? this.dataTable.current.getSelectedItems() : [];

        if(!this.state.updateDeliveryDetailsModal) {
            await this.props.getEquipmentDetails(selectedItems[0].id);
        }
        this.setState((state) => ({
            updateDeliveryDetailsModal: !state.updateDeliveryDetailsModal
        }));
    };

    private toggleEditTransportationDetailsModal = () => this.setState((state) => ({
        isEditTransportationDetailsModalOpened: !state.isEditTransportationDetailsModalOpened
    }));

    private toggleEditDnDDetailsModal = () => this.setState((state) => ({
        isEditDetailsDnDModalOpened: !state.isEditDetailsDnDModalOpened
    }));
}

const ROLES: BUSINNESS_FUNCTIONS[] = [
    BUSINNESS_FUNCTIONS.OCEAN_DELIVERY_EQUIPMENT_UPDATE_DELIVERY_DETAILS,
    BUSINNESS_FUNCTIONS.OCEAN_DELIVERY_EDIT_TRANSPORTATION_DETAILS,
    BUSINNESS_FUNCTIONS.OCEAN_DELIVERY_EQUIPMENT_UPDATE_ACTUALS
];

const mapStateToProps = (state: IStore): IMapStateToProps => ({
    equipment: state.planning.equipmentDetails,
    deliveryPlanningHaulierSettings: state.settings.deliveryPlanningHaulierSettings,
    fetchDeliveryPlanningHaulierSettingsPending: state.settings.fetchDeliveryPlanningHaulierSettingsPending
});

const mapDispatchToProps = (dispatch: ThunkDispatch<any, any, Action>): IMapDispatchToProps => ({
    exportEquipmentDeliveryAndReturnEquipmentToExcel: () => dispatch(exportEquipmentDeliveryAndReturnEquipmentToExcel(false)),
    getEquipmentDetails: (id: number) => dispatch(getEquipmentDetails(id)),
    getEmptyReturnEquipments: () => dispatch(getEmptyReturnEquipments(1, 10)),
    fetchDeliveryPlanningHaulierSettings: () => dispatch(fetchDeliveryPlanningHaulierSettings(true)),
});

const __DeliveryAndReturnEquipmentTable = connect<IMapStateToProps, IMapDispatchToProps, IDeliveryAndReturnEquipmentTableOwnProps ,IStore>(mapStateToProps, mapDispatchToProps)(_DeliveryAndReturnEquipmentTable);
export const DeliveryAndReturnEquipmentTable = withPermissions(__DeliveryAndReturnEquipmentTable, ROLES) as any;
