import React from 'react';
import { StatusRow } from 'components/status-row';
import { ReleaseTable } from './ReleaseTable';

interface IReleaseRow {
    data: any[];
}

export const ReleaseRow = (props: IReleaseRow) => (
    <StatusRow title="Release">
        <ReleaseTable data={props.data} />
    </StatusRow>
);
