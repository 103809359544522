import React, { ReactNodeArray } from 'react';
import { FilterFooter } from 'modules/filters/components/FilterFooter';
import { connect } from 'react-redux';
import { IStore } from 'reducers/index';
import { SORT_ORDER } from 'reducers/filters/models';
import { ThunkDispatch } from 'redux-thunk';
import { Action } from 'redux';
import {
    applyFiltersAction,
    clearFilters,
    setEmptyFilters,
    setFilter
} from 'actions/filters';
import { HandleError } from 'components/handle-error';
import { withRouter, RouteComponentProps } from 'react-router-dom';
import queryString from 'query-string';
import { parseURLParamForFilters } from 'utilities/util';
import moment from 'moment';

interface IFiltersState {
    isExpanded: boolean;
}

interface IFilterOwnProps {
    filters: ReactNodeArray;
    error?: boolean;
    fetchFunction: () => Promise<void>;
    buttons?: React.ReactChildren | React.ReactChild | React.ReactNodeArray;
    withoutFilters?: boolean;
    withoutPagination?: boolean;
    tableNameKey: string;
}

interface IMapStateToProps {
    sort: {
        order: SORT_ORDER;
        column: string;
    }
    pageSize: number;
    totalCount: number;
    pageNumber: number;
    currentFilters: object;
    appliedFilters: object;
}

interface IDispatch {
    setEmptyFilters: (filters: ReactNodeArray) => void;
    setFilter: (name: string, value: string | boolean | any[] | number) => void;
    applyFilters: () => void;
    clearFilters: () => void;
}

interface IFilterProps extends RouteComponentProps, IDispatch, IMapStateToProps, IFilterOwnProps {
}

class _Filters extends React.Component<IFilterProps, IFiltersState> {
    private static origin = '';

    public state: IFiltersState = {
        isExpanded: false,
    };

    public context: any;

    public componentDidMount() {
        if (_Filters.origin !== this.props.tableNameKey) {
            this.props.setEmptyFilters(this.props.filters);
            this.props.clearFilters();
        }
        _Filters.origin = this.props.tableNameKey;

        const query = queryString.parse(this.props.location.search);
        // tslint:disable-next-line: forin
        for (const param in query) {
            if(param === 'VesselNames') {
                this.props.setFilter(parseURLParamForFilters(param), [query[param]]);
            } else {
                this.props.setFilter(parseURLParamForFilters(param), query[param] as string);
            }
        }
        this.props.applyFilters();
    }

    public render() {
        const rows = this.state.isExpanded ? this.renderRows(this.props.filters) : this.renderFirstRow(this.props.filters);

        return (
            <React.Fragment>
                <HandleError
                    error={this.props.error}
                />
                {!this.props.withoutFilters && <div className="grid-wrapper">
                    <form className="filter-search-criteria">
                        <fieldset>
                            <div className="filter-header">
                                Filters
                            </div>
                            <div className="filter-body">
                                {rows}
                            </div>
                        </fieldset>
                        <FilterFooter
                            onApplyClick={this.onApplyClick}
                            onClearClick={this.onClearClick}
                            isExpanded={this.state.isExpanded}
                            onToggleClick={this.expandFilters}
                        />
                    </form>
                </div>}
            </React.Fragment>
        )
    }

    private injectStateControl = (fields: ReactNodeArray): ReactNodeArray => {
        return fields.map((field: any) => {
            const { name, type } = field.props;
            const onChange = (event: any) => {
                switch (type) {
                    case 'input': {
                        return this.props.setFilter(name, event.target.value);
                    }
                    case 'date': {
                    return this.props.setFilter(name, event);
                    }
                    case 'arrayOne': {
                        return this.props.setFilter(name, [event.target.value]);
                    }
                    case 'array': {
                        return this.props.setFilter(name, event);
                    }
                    case 'boolean': {
                        const returnBoolean = () => {
                            const e = event as any;
                            return e.target.value === 'true';
                        };
                        return this.props.setFilter(name, returnBoolean());
                    }
                    case 'number': {
                        if(event.target.value) {
                            return this.props.setFilter(name, event.target.value);
                        }
                        return this.props.setFilter(name, '');
                    }
                    default: {
                        return this.props.setFilter(name, event.target.value);
                    }
                }
            };
            return (
                <field.type
                    {...field.props}
                    onChange={onChange}
                    value={this.props.currentFilters[name] || ''}
                    key={name}
                />
            );
        });
    };


    private renderRows = (fields: ReactNodeArray) => {
        const fieldsWithStateControl = this.injectStateControl(fields);
        const rowsNumber = Math.ceil(fields.length / 4);
        const rows = [];
        for(let n = 0; n < rowsNumber; n++) {
            const row = (
                <div className="grid-wrapper" key={n}>
                    {fieldsWithStateControl[n * 4]}
                    {fieldsWithStateControl[n * 4 + 1]}
                    {fieldsWithStateControl[n * 4 + 2]}
                    {fieldsWithStateControl[n * 4 + 3]}
                </div>
            );
            rows.push(row);
        }
        return rows;
    };

    private renderFirstRow = (fields: ReactNodeArray) => this.renderRows(fields.slice(0, 4));

    private expandFilters = (): void => this.setState((state: IFiltersState) => ({
        isExpanded: !state.isExpanded,
    }));

    private onApplyClick = () => {
        this.props.applyFilters();

        const filtersToUrl = {};
        // tslint:disable-next-line: forin
        for (const property in this.props.currentFilters) {
            if(Boolean(this.props.currentFilters[property]) && moment.isMoment(this.props.currentFilters[property])) {
                filtersToUrl[property] = moment(this.props.currentFilters[property]).format('YYYY-MM-DD');
            } else if (Boolean(this.props.currentFilters[property])) {
                filtersToUrl[property] = this.props.currentFilters[property];
            }
        }

        if (Object.keys(filtersToUrl).length) {
            this.props.history.push({
                pathname: this.props.location.pathname,
                search: queryString.stringify(filtersToUrl)
            })
        }

        this.props.fetchFunction();
    };

    private onClearClick = () => {
        this.props.clearFilters();
        
        if(this.props.location.search) {
            this.props.history.push(this.props.location.pathname);
        }

        this.props.fetchFunction();
    };
}

const mapStateToProps = (state: IStore): IMapStateToProps => ({
    sort: {
        order: state.filters.order,
        column: state.filters.column,
    },
    pageSize: state.filters.pageSize,
    totalCount: state.filters.totalCount,
    pageNumber: state.filters.pageNumber,
    currentFilters: state.filters.filters,
    appliedFilters: state.filters.appliedFilters,
});

const mapDispatchToProps = (dispatch: ThunkDispatch<any, any, Action>): IDispatch => ({
    setEmptyFilters: (filters: ReactNodeArray) => dispatch(setEmptyFilters(filters)),
    setFilter: (name: string, value: string | boolean | any[] | number) => dispatch(setFilter(name, value)),
    applyFilters: () => dispatch(applyFiltersAction()),
    clearFilters: () => dispatch(clearFilters()),
});

const __Filters = connect(mapStateToProps, mapDispatchToProps)(_Filters);
export const Filters = withRouter<any>(__Filters);
