import React, { FunctionComponent, useState, useRef } from 'react';
import { IVessel } from 'reducers/vessels/models';
import { DataTable } from 'components/data-table/DamcoTable';
import { connect } from 'react-redux';
import { downloadFile } from 'utilities/download';
import { ThunkDispatch } from 'redux-thunk';
import { Action } from 'redux';
import { exportVesselTrackingToExcel } from 'actions/export';
import { mapStatusToStringForCT } from 'constants/statuses';
import { ColumnFactory } from 'components/data-table/ColumnFactory';
import { VesselDetailsPopup } from './VesselDetailsPopup';
import { BUSINNESS_FUNCTIONS } from 'constants/roles';
// tslint:disable-next-line: no-submodule-imports
import { withPermissions, IWithPermissions } from 'src/HOC/withPermissions';
import { getArrayOfButtons } from 'utilities/util';
import { IStore } from 'reducers/index';

interface IVesselsTableOwnProps {
    data: IVessel[];
    pageCount: number;
    fetchVessels: any;
}

interface IMapDispatchToProps {
    exportVesselTrackingToExcel: () => Promise<void>;
}

interface IVesselsTableProps extends IWithPermissions, IVesselsTableOwnProps, IMapDispatchToProps {}

const _VesselsTable: FunctionComponent<IVesselsTableProps> = (props) => {
    const [editVesselPopupIsOpen, setEditVesselPopupIsOpen] = useState<boolean>(false);
    const dataTable = useRef<any>();

    const exportToExcel = async () => {
        const res = await props.exportVesselTrackingToExcel();
        downloadFile(res);
    };

    const toggleEditVesselDetailsPopup = () => setEditVesselPopupIsOpen(!editVesselPopupIsOpen);

    const getInitialValues = () => {
        const selectedItems = dataTable.current ? dataTable.current.getSelectedItems() : [];

        if(selectedItems[0]) {
            return {
                VoyageReference: selectedItems[0].voyageNumber,
                VesselName: selectedItems[0].vesselName,
                ETA: selectedItems[0].eta,
                ETD: selectedItems[0].etd,
                ATD: selectedItems[0].atd,
                ATA: selectedItems[0].ata,
                POLCode: selectedItems[0].pol,
                PODCode: selectedItems[0].pod,
                VoyageId: selectedItems[0].voyageId,
            }
        }
        return;
    };

    const data = props.data.map((obj: any, index: number) => ({
        ...obj,
        vesselNameDisplayName: [obj.vesselName, `/tracking/${obj.voyageId}`],
        statusDisplayName: mapStatusToStringForCT(obj.status),
        id: index,
        children: obj.children.map((_obj: any) => ({
            numberDisplayName: [_obj.number, `/tracking/mbl-tracking/${_obj.masterBillOfLadingId}`]
        }))
    }));

    return (
        <React.Fragment>
            <DataTable
                data={data}
                showColumnOptions={true}
                columns={[
                    ColumnFactory.linkedColumn('vesselNameDisplayName', 'Vessel name'),
                    ColumnFactory.standardColumn('voyageNumberDisplayName', 'Voyage'),
                    ColumnFactory.standardColumn('polDisplayName', 'POL'),
                    ColumnFactory.standardColumn('podDisplayName', 'POD'),
                    ColumnFactory.dateColumn('etdDisplayName', 'ETD'),
                    ColumnFactory.dateColumn('atdDisplayName', 'ATD'),
                    ColumnFactory.dateColumn('etaOriginalDisplayName', 'Original ETA'),
                    ColumnFactory.dateColumn('etaDisplayName', 'New ETA'),
                    ColumnFactory.dateColumn('ataDisplayName', 'ATA'),
                    ColumnFactory.dateColumn('statusDisplayName', 'Status')
                ]}
                subColumns={[
                    ColumnFactory.linkedColumn('numberDisplayName', 'B/L number'),
                ]}
                expandable={true}
                withCheckboxes={true}
                selectVariant="single"
                tableName="VesselTrackingList"
                actions={{
                    primaryActionIsMoreBtn: false,
                    primaryActionLabel: 'Export to excel',
                    primaryAction: exportToExcel,
                    parentContextualActions: getArrayOfButtons(
                        props.permissions,
                        [
                            {isMoreBtn: false, label: 'Update vessel details', action: toggleEditVesselDetailsPopup}
                        ])
                }}
                reference={dataTable}
                pageCount={props.pageCount}
                manualPagination={true}
                fetchData={props.fetchVessels}
            />
            {editVesselPopupIsOpen && <VesselDetailsPopup
                visible={editVesselPopupIsOpen}
                closeModal={toggleEditVesselDetailsPopup}
                initialValues={dataTable.current ? getInitialValues() : {}}
            />}
        </React.Fragment>
    );
}

const ROLES: BUSINNESS_FUNCTIONS[] = [BUSINNESS_FUNCTIONS.OCEAN_VESSEL_TRACKING_EDIT_VESSEL];

const mapDispatchToProps = (dispatch: ThunkDispatch<any, any, Action>): IMapDispatchToProps => ({
    exportVesselTrackingToExcel: () => dispatch(exportVesselTrackingToExcel())
})

const __VesselsTable = connect<undefined, IMapDispatchToProps, IVesselsTableOwnProps, IStore>(undefined, mapDispatchToProps)(_VesselsTable);
export const VesselsTable = withPermissions(__VesselsTable, ROLES) as any;
