import { uniqBy, flatten } from 'lodash';
import { User } from 'msal';
import { TYPES } from 'action-types/user';
import { ThunkDispatch } from 'redux-thunk';
import { Action } from 'redux';
import { callApiPost } from 'utilities/apiCaller';
import API from 'constants/api';
import { generateErrors } from 'utilities/util';
import { IStore } from 'reducers/index';

export const setUser = (user: User) => ({type: TYPES.SET_USER, user});

export const getUAMBusinessFunctions = () => {
    return async (dispatch: ThunkDispatch<any, any, Action>) => {
        dispatch({type: TYPES.GET_UAM_BUSINESS_FUNCTIONS.PENDING});
        const promises = [
            dispatch(getCTBusinessFunctions())
        ];
        try {
            const res = await Promise.all(promises.map(p => p.catch(_ => 'No permissions')));
            const flatArray = uniqBy(flatten(res), 'name' || 'Name');
            localStorage.setItem('roles', JSON.stringify(flatArray));
            dispatch({type: TYPES.GET_UAM_BUSINESS_FUNCTIONS.FULFILLED, roles: flatArray});
        } catch(e) {
            const error = generateErrors(e);
            dispatch({type: TYPES.GET_UAM_BUSINESS_FUNCTIONS.REJECTED, error});
        }
    }
};

const getCTBusinessFunctions = () => {
    return async (dispatch: ThunkDispatch<any, any, Action>) => {
        dispatch({type: TYPES.GET_CT_UAM_BUSINESS_FUNCTIONS.PENDING});
        try {
            const res = await callApiPost(API.UAM.GetCTBusinessFunctions);
            dispatch({type: TYPES.GET_CT_UAM_BUSINESS_FUNCTIONS.FULFILLED, roles: res.data});
            return res.data;
        } catch (e) {
            dispatch({type: TYPES.GET_CT_UAM_BUSINESS_FUNCTIONS.REJECTED});
            throw e;
        }
    }
};

export const checkIfRolesAreLoaded = () => {
    return async (dispatch: ThunkDispatch<any, any, Action>, getState: () => IStore) => {
        dispatch({type: TYPES.CHECKING_FOR_ROLES.PENDING});
        if(getState().user.roles.length > 0) {
            dispatch({type: TYPES.CHECKING_FOR_ROLES.FULFILLED});
            return true;
        } else {
            dispatch({type: TYPES.CHECKING_FOR_ROLES.REJECTED});
            return false;
        }
    }
};