import React from 'react';
import { OneStatus } from 'components/status-row/OneStatus';
import { StatusRow } from 'components/status-row';
import { QuarterGrid } from 'components/grid/quarter-grid';
import { formatDate } from 'utilities/util';

interface IMilestonesRow {
    milestonesGateInDCDate?: string;
    milestonesGateOutDCDate?: string;
    milestonesUnloadDCDate?: string;
    milestonesArrivalInLandDepotDate?: string;
    expectedDeliveryDate?: any;
}

export const MilestonesRow: React.SFC<IMilestonesRow> = (props: IMilestonesRow) => (
    <StatusRow title="Delivery">
        <QuarterGrid>
            <OneStatus label="Check in DC">
                {formatDate(props.milestonesGateInDCDate)}
            </OneStatus>
            <OneStatus label="Equipment Unload DC">
                {formatDate(props.milestonesUnloadDCDate)}
            </OneStatus>
            <OneStatus label="Check out DC">
                {formatDate(props.milestonesGateOutDCDate)}
            </OneStatus>
            <OneStatus label="Arrival into Inland Depot">
                {formatDate(props.milestonesArrivalInLandDepotDate)}
            </OneStatus>
        </QuarterGrid>
        <QuarterGrid>
            <OneStatus label="Requested delivery date">
                {formatDate(props.expectedDeliveryDate)}
            </OneStatus>
        </QuarterGrid>
    </StatusRow>
);
