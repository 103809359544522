import React from 'react';
import { Accordion } from 'components/accordion';
import { QuarterGrid } from 'components/grid/quarter-grid';
import { OneStatus } from 'components/status-row/OneStatus';
import { IHawbDetails } from 'reducers/customs/models';
import { formatValueIfNullOrUndefined } from 'utilities/util';

export const ConsigneeTable = (props: IHawbDetails) => (
    <Accordion text="Consignee">
        <QuarterGrid>
            <OneStatus label="Consignee name">
                {formatValueIfNullOrUndefined(props.consigneeName)}
            </OneStatus>
            <OneStatus label="BE Code">
                {formatValueIfNullOrUndefined(props.consigneeBECode)}
            </OneStatus>
        </QuarterGrid>
    </Accordion>
);
