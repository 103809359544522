import { API } from 'action-types/api';
import { Action } from 'redux';

export interface IApiResetErrorAction extends Action {
    readonly endpoint: string;
}

export interface IApiErrorAction extends Action {
    readonly endpoint: string;
    readonly errors: string[];
}

export interface IApiState {
    readonly currentRequests: number;
    readonly errors: {
        [endpoint: string]: string[] | undefined;
    };
}

const defaultState: IApiState = {
    currentRequests: 0,
    errors: {},
};

export const reducer = (state: IApiState = defaultState, action: IApiErrorAction): IApiState => {
    switch (action.type) {
        case API.REQUEST_START:
            return {
                ...state,
                currentRequests: state.currentRequests + 1,
            };
        case API.REQUEST_END:
            return {
                ...state,
                currentRequests: state.currentRequests - 1,
            };
        case API.ADD_ERROR:
            return {
                ...state,
                errors: {
                    ...state.errors,
                    [action.endpoint]: action.errors,
                }
            }
        case API.RESET_ERROR:
            return {
                ...state,
                errors: {
                    ...state.errors,
                    [action.endpoint]: undefined,
                }
            }
        default:
            return state;
    }
};
