import { TYPES } from 'action-types/auth';
import { ThunkDispatch } from 'redux-thunk';
import { Action } from 'redux';
import { generateErrors } from 'utilities/util';

export const detectAuthStatus = () => {
    return (dispatch: ThunkDispatch<any, any, Action>) => {
        dispatch({type: TYPES.LOGIN.PENDING});
        try {
            dispatch({type: TYPES.LOGIN.FULFILLED});
        } catch (e) {
            const errors = generateErrors(e);
            dispatch({type: TYPES.LOGIN.REJECTED, errors})
        }
    }
}
