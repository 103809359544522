import React, { useState, useRef, FunctionComponent } from 'react';
import { DataTable } from 'components/data-table/DamcoTable';
import { ColumnFactory } from 'components/data-table/ColumnFactory';
import { BUSINNESS_FUNCTIONS } from 'constants/roles';
// tslint:disable-next-line: no-submodule-imports
import { withPermissions, IWithPermissions } from 'src/HOC/withPermissions';
import { getArrayOfButtons } from 'utilities/util';
import { EditMilestonesPopup } from './MilestonesModal';
import { cloneDataWithDisplayNames } from 'utilities/tables';

interface IMilestonesTablePropsOwnProps {
    data: any[];
    pageCount: number;
    fetchMilestones: any;
    pageInfo: {page: number; pageSize: number};
}

interface IMapDispatchToProps {
    exportVesselTrackingToExcel: () => Promise<void>;
}

interface IMilestonesTableProps extends IWithPermissions, IMilestonesTablePropsOwnProps, IMapDispatchToProps {};

const _MilestonesTable: FunctionComponent<IMilestonesTableProps> = (props) => {
    const [editMilestonesPopupIsOpen, setEditMilestonesPopupIsOpen] = useState<boolean>(false);
    const dataTableRef = useRef<any>();

    const toggleEditVesselDetailsPopup = () => setEditMilestonesPopupIsOpen(!editMilestonesPopupIsOpen);

    const getSelectedEvents = () => dataTableRef.current.getSelectedItems();

    const unSelectItemsInTable = () => {
        if(dataTableRef.current) {
            dataTableRef.current.hideContextRibbon();
        }
    };

    const createInitialValues = () => {
        return getSelectedEvents().reduce((prev: any, milestone: any, i: number) => {
            const values = {
                [`carrierEstimatedDate_${getSelectedEvents()[i].id}`]: milestone.carrierEstimatedDate || milestone.CarrierEstimatedDate,
                [`opsEstimatedDate_${getSelectedEvents()[i].id}`]: milestone.opsEstimatedDate || milestone.OpsEstimatedDate,
                [`carrierActualDate_${getSelectedEvents()[i].id}`]: milestone.carrierActualDate || milestone.CarrierActualDate,
                [`opsActualDate_${getSelectedEvents()[i].id}`]: milestone.opsActualDate || milestone.OpsActualDate,
            };

            return {
                ...prev,
                ...values,
            };
        }, {});
    }

    const data = cloneDataWithDisplayNames(props.data).map((milestones: any) => ({
        ...milestones,
        children: milestones.milestones.map((milestone: any, i: number) => ({
            ...milestone,
            itemId: milestone.id,
            id: i + milestones.equipmentId
        }))
    }));
    
    return (
        <React.Fragment>
            <DataTable
                data={data}
                showColumnOptions={true}
                columns={[
                    ColumnFactory.standardColumn('EquipmentNumberDisplayName', 'Container number'),
                    ColumnFactory.standardColumn('sealNumberDisplayName', 'Seal number'),
                    ColumnFactory.standardColumn('FirstVesselNameDisplayName', 'First vessel name'),
                    ColumnFactory.standardColumn('LastVesselNameDisplayName', 'Last vessel name'),
                    ColumnFactory.standardColumn('firstVoyageDisplayName', 'First Voyage'),
                    ColumnFactory.standardColumn('lastVoyageDisplayName', 'Last Voyage'),
                    ColumnFactory.standardColumn('firstPOLDisplayName', 'First POL'),
                    ColumnFactory.standardColumn('lastPOLDisplayName', 'Last POL'),
                    ColumnFactory.standardColumn('firstPODDisplayName', 'First POD'),
                    ColumnFactory.standardColumn('lastPODDisplayName', 'Last POD'),
                    ColumnFactory.standardColumn('CBLNumberDisplayName', 'CBL'),
                    ColumnFactory.standardColumn('carrierNameDisplayName', 'Carrier'),
                    ColumnFactory.standardColumn('customerDisplayName', 'Customer')
                ]}
                subColumns={[
                    ColumnFactory.standardColumn('nameDisplayName', ' '),
                    ColumnFactory.standardColumn('carrierEstimatedDateDisplayName', 'Carrier estimated date'),
                    ColumnFactory.standardColumn('opsEstimatedDateDisplayName', 'Ops Estimated date'),
                    ColumnFactory.standardColumn('carrierActualDateDisplayName', 'Carrier actual date'),
                    ColumnFactory.standardColumn('opsActualDateDisplayName', 'Ops Actual date'),
                ]}
                expandable={true}
                withCheckboxes={true}
                selectVariant="multiple"
                tableName="Milestoneslist"
                actions={{
                    parentContextualActions: getArrayOfButtons(
                        props.permissions,
                        [
                            {isMoreBtn: false, label: 'Update milestones', action: toggleEditVesselDetailsPopup}
                        ]
                    )
                }}
                reference={dataTableRef}
                pageCount={props.pageCount}
                manualPagination={true}
                fetchData={props.fetchMilestones}
            />
            {editMilestonesPopupIsOpen && <EditMilestonesPopup
                selectedEvents={getSelectedEvents()}
                initialValues={createInitialValues()}
                isVisible={editMilestonesPopupIsOpen}
                closeModal={toggleEditVesselDetailsPopup}
                unSelectItemsInTable={unSelectItemsInTable}
                pageInfo={props.pageInfo}
            />}
        </React.Fragment>
    );
}

const ROLES: BUSINNESS_FUNCTIONS[] = [BUSINNESS_FUNCTIONS.OCEAN_MILESTONES_MODAL];

export const MilestonesTable = withPermissions(_MilestonesTable, ROLES) as any;
