import React, { Component } from 'react';
import { connect } from 'react-redux';
import { ModalScreen } from 'components/modal-screen';
import { ModalType } from 'components/modal-screen/ModalType';
import { ThunkDispatch } from 'redux-thunk';
import { Action } from 'redux';
import { INotifyCustomerAction, notifyCustomer, getReasonCodes } from 'actions/modals';
import { IStore } from 'reducers/index';
import { LoadingAnimation } from 'components/loading';
import { CTReasonCodes } from 'constants/reasonCodes';
import { DataTable } from 'components/data-table/DataTable';
import { ColumnFactory } from 'components/data-table/ColumnFactory';
import { RowInfo } from 'react-table';
import { Capability } from 'components/timeline';

interface IMapStateToProps {
    isLoading: boolean;
    reasonCodes: any[];
    isGetReasonCodesLoading: boolean;
}

interface IDispatch {
    notifyCustomer: (data: INotifyCustomerAction) => Promise<void>;
    getReasonCodes: (group?: CTReasonCodes) => Promise<void>;
}

interface IASNMessagesModal extends IDispatch, IMapStateToProps {
    visible: boolean;
    closeModal: () => void;
    data: any[];
}

class _ASNMessagesModal extends Component<IASNMessagesModal> {
    public render() {
        if (this.props.isLoading || this.props.isGetReasonCodesLoading) {
            return <LoadingAnimation />;
        }

        return (
            <ModalScreen
                title="ASN messages"
                visible={this.props.visible}
                modalType={ModalType.s()}
                closeModal={this.props.closeModal}
                primaryButtonTitle="Close"
                primaryButtonFunc={this.props.closeModal}
                secondaryButtonTitle="Cancel"
                secondaryButtonFunc={this.props.closeModal}
                hideSecondaryButton={true}
            >
                <DataTable
                    data={this.props.data}
                    SubComponent={(row: RowInfo) => (
                        <DataTable
                            data={row.original.errors}
                            columns={[
                                ColumnFactory.standardColumnOld('path', 'Path'),
                                ColumnFactory.standardColumnOld('value', 'Error'),
                            ]}
                        />
                    )}
                    columns={[
                        ColumnFactory.expandColumn(),
                        ColumnFactory.standardColumnOld('id', 'ASN'),
                        ColumnFactory.standardColumnOld('type', 'Type'),
                        ColumnFactory.dateColumnOld('timeStamp', 'Date', 'DD-MM-YYYY HH:mm'),
                    ]}
                />
            </ModalScreen>
        );
    }

}

const mapStateToProps = (state: IStore): IMapStateToProps => ({
    isLoading: state.modals.isNotifyCustomerPending,
    reasonCodes: state.modals.codes,
    isGetReasonCodesLoading: state.modals.isGetReasonCodesLoading
});

const mapDispatchToProps = (dispatch: ThunkDispatch<any, any, Action>): IDispatch => ({
    notifyCustomer: (data: INotifyCustomerAction) => dispatch(notifyCustomer(data, false)),
    getReasonCodes: (group?: CTReasonCodes) => dispatch(getReasonCodes(Capability.ContainerTracking, false, group)),
});

export const ASNMessagesModal = connect(mapStateToProps, mapDispatchToProps)(_ASNMessagesModal);
