import { TYPES } from 'action-types/user';
import { IUser } from './models';
import { IRoles } from 'utilities/roles';

interface IAction {
    readonly type: string;
    readonly user: IUser;
    readonly roles: IRoles[];
}

export interface IUserState {
    readonly user: IUser;
    readonly roles: IRoles[];
    readonly rolesAreLoading: boolean;
}

const defaultState: IUserState = {
    user: {
        displayableId: '',
        idToken: {
            acr: '',
            aud: '',
            auth_time: 0,
            authenticationSource: '',
            company: '',
            exp: 0,
            family_name: '',
            given_name: '',
            iat: 0,
            idp: '',
            iss: '',
            name: '',
            nbf: 0,
            nonce: '',
            oid: '',
            sub: '',
            userGroups: [],
            ver: '',
            email: '',
        },
        identityProvider: '',
        name: '',
        userIdentifier: ''
    },
    roles: JSON.parse(localStorage.getItem('roles') || '[]'),
    rolesAreLoading: false
};

export const reducer = (state: IUserState = defaultState, action: IAction): IUserState => {
    switch (action.type) {
        case TYPES.SET_USER:
            return {
                ...state,
                user: action.user,
            };
        case TYPES.GET_UAM_BUSINESS_FUNCTIONS.PENDING:
            return {
                ...state,
                rolesAreLoading: true,
            };
        case TYPES.GET_UAM_BUSINESS_FUNCTIONS.FULFILLED:
            return {
                ...state,
                roles: action.roles,
            };
        case TYPES.GET_UAM_BUSINESS_FUNCTIONS.REJECTED:
            return {
                ...state,
                rolesAreLoading: false,
            };
        case TYPES.CHECKING_FOR_ROLES.FULFILLED:
            return {
                ...state,
                rolesAreLoading: false
            }
        case TYPES.CHECKING_FOR_ROLES.REJECTED:
            return {
                ...state,
                rolesAreLoading: true
            }
        default:
            return state;
    }
};
