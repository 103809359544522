import React from 'react';
import { formatDate, formatValueIfNullOrUndefined, getArrayWithMoreOptions } from 'utilities/util';
import { EditDeliveryDetailsModal } from 'modules/modals/components/EditDevlieryDetailsModal';
import { SendTransportOrderToHaulierModal } from 'modules/modals/components/SendTransportOrderToHaulierModal';
import { Transport_Order_Type } from 'constants/transport';
import { IEquipmentDetails } from 'reducers/planning/models';
import { BUSINNESS_FUNCTIONS } from 'constants/roles';
import { EditTransportationDetailsModal, IEditDeliveryDetailsForm } from 'views/planning/deliver-planning-container-details/components/EditTransportationDetailsModal';
import { connect } from 'react-redux';
import { IStore } from 'reducers/index';
// tslint:disable-next-line: no-submodule-imports
import { withPermissions, IWithPermissions } from 'src/HOC/withPermissions';
import { PropertiesRibbonWrapper } from 'components/properties-ribbon';

interface IMapStateToProps {
    hawbDetails: IEquipmentDetails;
}

interface IHAWBDetailsTopBar extends IWithPermissions, IMapStateToProps {
    customsClearanceStatus: string;
    equipmentLandedDate: string;
    deliveryPlannedDate: string;
    deliverySite: string;
    deliveryStatus: string;
    id: number;
    clientDelivery: string;
    deliveryLocation: string;
    shift: string;
    getHAWBDetails: () => Promise<void>;
    equipment: IEquipmentDetails;
}

interface IHAWBDetailsTopBarState {
    isEditFinalDeliveryDetailsOpened: boolean;
    isSendTransportOrderToHaulierOpened: boolean;
    isSendPreAlertOpened: boolean;
    isEditDetailsModalOpened: boolean;
    transportOrderType: Transport_Order_Type;
    transportOrderName: string;
}

class _HAWBDetailsTopBar extends React.Component<IHAWBDetailsTopBar, IHAWBDetailsTopBarState> {
    public state: IHAWBDetailsTopBarState = {
        isSendTransportOrderToHaulierOpened: false,
        isEditFinalDeliveryDetailsOpened: false,
        isSendPreAlertOpened: false,
        isEditDetailsModalOpened: false,
        transportOrderType: Transport_Order_Type.Trucking,
        transportOrderName: ''
    };

    public render() {
        const actions = [
            {label: 'Update final delivery details', action: this.toggleEditFinalDeliveryDetailsModal},
            {label: 'Update Transport details', action: this.toggleEditDetailsModalOpened},
            {label: 'Send Trucking Transport Order', action: () => this.toggleSendTransportOrderToHaulierModal(Transport_Order_Type.Trucking)},
            {label: 'Send De-Rig Transport Order', action: () => this.toggleSendTransportOrderToHaulierModal(Transport_Order_Type.DeRig)},
            {label: 'Send Dark Storage Transport Order', action: () => this.toggleSendTransportOrderToHaulierModal(Transport_Order_Type.DarkStorage)},
            {label: 'Send Rail Transport Order', action: () => this.toggleSendTransportOrderToHaulierModal(Transport_Order_Type.Rail)},
            {label: 'Send Barge Transport Order', action: () => this.toggleSendTransportOrderToHaulierModal(Transport_Order_Type.Barge)},
        ];
        const propertiesRibbonData = {
            labels: [
                {label: 'Customs Clearance Status', value: formatValueIfNullOrUndefined(this.props.customsClearanceStatus)},
                {label: 'Equipment Landed', value: formatDate(this.props.equipmentLandedDate)},
                {label: 'Planned Date/Time', value: formatDate(this.props.deliveryPlannedDate)},
                {label: 'Delivery Site', value: formatValueIfNullOrUndefined(this.props.deliveryLocation)},
                {label: 'Status', value: formatValueIfNullOrUndefined(this.props.deliveryStatus)},
            ],
            actions: getArrayWithMoreOptions(this.props.permissions, actions)
        };

        return (
            <React.Fragment>
                <PropertiesRibbonWrapper content={propertiesRibbonData} />
                {this.state.isEditFinalDeliveryDetailsOpened && <EditDeliveryDetailsModal
                    visible={this.state.isEditFinalDeliveryDetailsOpened}
                    closeModal={this.toggleEditFinalDeliveryDetailsModal}
                    ids={[this.props.id]}
                    refetchFunction={this.props.getHAWBDetails}
                    isAir={true}
                    isFinal={true}
                />}
                {this.state.isSendTransportOrderToHaulierOpened && <SendTransportOrderToHaulierModal
                    visible={this.state.isSendTransportOrderToHaulierOpened}
                    closeModal={this.toggleSendTransportOrderToHaulierModal}
                    transportConfig={this.state.transportOrderType}
                    id={[this.props.id]}
                    isAir={true}
                    transportOrderName={this.state.transportOrderName}
                />}
                {this.state.isEditDetailsModalOpened && <EditTransportationDetailsModal
                    visible={this.state.isEditDetailsModalOpened}
                    closeModal={this.toggleEditDetailsModalOpened}
                    ids={[this.props.equipment.id]}
                    initialValues={this.getInitialValues()}
                    isAir={true}
                    isDetails={true}
                />}
            </React.Fragment>
        );
    }

    private toggleEditFinalDeliveryDetailsModal = () => this.setState((state) => ({
        isEditFinalDeliveryDetailsOpened: !state.isEditFinalDeliveryDetailsOpened,
    }));

    private toggleSendTransportOrderToHaulierModal = (transportOrderType: Transport_Order_Type = Transport_Order_Type.Trucking) => {
        const transportName = () => {
            if(Object.entries(Transport_Order_Type)[transportOrderType]) {
                return Object.entries(Transport_Order_Type)[transportOrderType][1];
            }
            return '';
        };

        this.setState((prevState) => ({
            isSendTransportOrderToHaulierOpened: !prevState.isSendTransportOrderToHaulierOpened,
            transportOrderType,
            transportOrderName: transportName() as string
        }));
    }

    private toggleEditDetailsModalOpened = () => this.setState((state) => ({
        isEditDetailsModalOpened: !state.isEditDetailsModalOpened,
    }));

    private getInitialValues = (): IEditDeliveryDetailsForm => ({
        documentsPARNRequired: this.props.hawbDetails.documentsPARNRequired,
        documentsCRMRequired: this.props.hawbDetails.documentsCMRRequired,
        isDangerousGoods: this.props.hawbDetails.isDangerousGoods,
        dangerousGoodsUNNO: this.props.hawbDetails.dangerousGoodsUNNO,
        dangerousGoodsClass: this.props.hawbDetails.dangerousGoodsClass,
        dangerousGoodsDescription: this.props.hawbDetails.dangerousGoodsDescription,
        isDangerousGoodsADR: this.props.hawbDetails.dangerousGoodsADRRequired,
        comments: this.props.hawbDetails.nonConformanceComment,
        hbLs: this.props.hawbDetails.hbLs,
    });
}

const mapStateToProps = (state: IStore): IMapStateToProps => ({
    hawbDetails: state.planning.hawbDetails
});

const ROLES: BUSINNESS_FUNCTIONS[] = [
    BUSINNESS_FUNCTIONS.AIR_PLANNING_EDIT_CONTAINER_DETAILS_AND_DELIVERY_LEG,
    BUSINNESS_FUNCTIONS.AIR_PLANNING_SEND_TRANSPORT_ORDER,
    BUSINNESS_FUNCTIONS.AIR_PLANNING_EDIT_DELIVERY_DETAILS
];

const __HAWBDetailsTopBar = withPermissions<IHAWBDetailsTopBar>(_HAWBDetailsTopBar, ROLES);

export const HAWBDetailsTopBar = connect(mapStateToProps)(__HAWBDetailsTopBar) as any;
