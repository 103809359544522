import React from 'react';

interface ICountableProps {
    children: any;
    getNumberOfChildrens: (childrens: any) => void;
}

export class Countable extends React.Component<ICountableProps> {
    public componentDidMount() {
        const data = [...this.props.children].map((item: any) => {
            if(typeof item === 'object') {
                return item.props.collapse;
            }
        });
        this.props.getNumberOfChildrens(data);
    };

    public render() {
        return(
            <React.Fragment>
                {this.props.children}
            </React.Fragment>
        );
    };
};