import React from 'react';
import { LoadingAnimation } from 'components/loading';
import { ColumnFactory } from 'components/data-table/ColumnFactory';
import { IBL, IBLVessel } from 'reducers/vessels/models';
import { ThunkDispatch } from 'redux-thunk';
import { Action } from 'redux';
import { exportContainerTrackingToExcel } from 'actions/export';
import { downloadFile } from 'utilities/download';
import { connect } from 'react-redux';
import { DataTable } from 'components/data-table/DamcoTable';
import { EditMAWBDetailsPopup } from 'modules/tracking/containers/components/EditMAWBDetailsPopup';
import { NotifyCustomerModal } from 'modules/modals/components/NotifyCustomerModal';
import { CTReasonCodes } from 'constants/reasonCodes';
import { FlightDetailsPopup } from './EditFlightDetailsModal';
import { openCBLDetailsModal, fetchMRNDetails } from 'actions/vessels';
import { mapStatusToStringForCT } from 'constants/statuses';
import { InPageDialog } from 'damco-components';
// tslint:disable-next-line: no-submodule-imports
import { withPermissions, IWithPermissions } from 'src/HOC/withPermissions';
import { BUSINNESS_FUNCTIONS } from 'constants/roles';
import { getArrayOfButtons } from 'utilities/util';
import { IStore } from 'reducers/index';
import { cloneDataWithDisplayNames } from 'utilities/tables';
import { UpdateCustomsDetailsModal } from 'modules/modals/components/UpdateCustomsDetailsModal';


interface MapStateToProps {
    mrnData: any[];
}

interface IDispatchProps {
    fetchMRNDetails: (blId: number[]) => Promise<void>;
    exportContainerTrackingToExcel: (isAir: boolean) => Promise<any>;
    openCBLDetailsModal: (blId: number) => void;
}

interface IMawbTableOwnProps {
    data: any[];
    isLoading: boolean;
    pageCount: number;
    fetchMawbList: any;
}

interface IMawbTableProps extends IWithPermissions, IMawbTableOwnProps, MapStateToProps, IDispatchProps {}

interface IMawbTableState {
    isNotifyCustomerModalOpened: boolean;
    isEditFlightDetailsPopupOpened: boolean;
    isMultiselectErrorDialogOpened: boolean;
    errorMessage: string;
    isUpdateCustomsModalOpened: boolean;
}

class _MawbTable extends React.Component<IMawbTableProps, IMawbTableState> {
    public dataTable = React.createRef<any>();

    public state: IMawbTableState = {
        isEditFlightDetailsPopupOpened: false,
        isNotifyCustomerModalOpened: false,
        isMultiselectErrorDialogOpened: false,
        errorMessage: 'Some error occured',
        isUpdateCustomsModalOpened: false
    };

    public render() {
        const data = cloneDataWithDisplayNames(this.props.data, 'DD-MM-YYYY HH:mm').map((bl: IBL, index: number) => {
            const vesselToShow = bl.vessels.length > 1 ? bl.vessels.find((vessel: IBLVessel) => vessel.isCurrentTranship) : bl.vessels[0];
            const transhipmentAtRIsk = () => {
                if (bl.vessels.find((vessel: IBLVessel) => vessel.transhipmentAtRisk)) {
                    return true;
                }
                return vesselToShow && vesselToShow.transhipmentAtRisk;
            };
            return {
                ...bl,
                ...vesselToShow,
                MAWBNumberDisplayName: [bl.MAWBNumber, `/tracking/mawb-tracking/${bl.blId}`],
                transhipmentAtRisk: transhipmentAtRIsk(),
                children: bl.vessels.map((child: any, localIndex: number) => ({
                    ...child,
                    voyageStatusDisplayName: mapStatusToStringForCT(child.voyageStatus),
                    transhipmentAtRiskDisplayName: transhipmentAtRIsk() === true ? 'Y' : 'N',
                    id: index.toFixed(1) + localIndex / 10,
                    parentNode: 0,
                    threePlDisplayName: bl['3PLDisplayName'],
                    blId: bl.blId
                })),
                expander: bl.vessels.length > 1,
                selected: ((bl as any).selected),
                id: index
            };
        });

        return (
            <React.Fragment>
                {this.props.isLoading && <LoadingAnimation/>}
                {this.state.isMultiselectErrorDialogOpened && <InPageDialog 
                    show={this.state.isMultiselectErrorDialogOpened}
                    type="error" 
                    message={this.state.errorMessage} 
                    closeMethod={this.toggleMultiselectErrorDialog}
                />}
                <DataTable
                    data={data}
                    showColumnOptions={true}
                    columns={[
                        ColumnFactory.linkedColumn('MAWBNumberDisplayName', 'MAWB number'),
                        ColumnFactory.standardColumn('3PLDisplayName', '3PL'),
                        ColumnFactory.standardColumn('fourPLCarrierNameDisplayName', 'Actual carrier'),
                        ColumnFactory.standardColumn('fourPLReferenceDisplayName', 'Reference'),
                        ColumnFactory.standardColumn('loadingPortDisplayName', 'POL'),
                        ColumnFactory.standardColumn('destinationPortDisplayName', 'POD'),
                        ColumnFactory.standardColumn('dgDisplayName', 'DG'),
                        ColumnFactory.standardColumn('etdDisplayName', 'ETD'),
                        ColumnFactory.standardColumn('atdDisplayName', 'ATD'),
                        ColumnFactory.standardColumn('originalETADisplayName', 'Original ETA'),
                        ColumnFactory.standardColumn('currentETADisplayName', 'New ETA'),
                        ColumnFactory.standardColumn('ataDisplayName', 'ATA'),
                        ColumnFactory.standardColumn('voyageDelayReasonCodeDisplayName', 'Reason code'),
                    ]}
                    subColumns={[
                        ColumnFactory.standardColumn('FlightNumberDisplayName', 'Flight number'),
                        ColumnFactory.standardColumn('threePlDisplayName', '3PL'),
                        ColumnFactory.standardColumn('loadingPortDisplayName', 'POL'),
                        ColumnFactory.standardColumn('destinationPort', 'POD'),
                        ColumnFactory.standardColumn('atdDisplayName', 'ATD'),
                        ColumnFactory.standardColumn('originalETADisplayName', 'Original ETA'),
                        ColumnFactory.standardColumn('currentETADisplayName', 'New ETA'),
                        ColumnFactory.standardColumn('transhipmentAtRiskDisplayName', 'Transhipment at Risk'),
                        ColumnFactory.standardColumn('ataDisplayName', 'ATA'),
                        ColumnFactory.standardColumn('voyageStatusDisplayName', 'Status'),
                    ]}
                    withCheckboxes={true}
                    expandable={true}
                    selectVariant="single"
                    tableName="MAWBTrackingList"
                    actions={{
                        primaryActionIsMoreBtn: false,
                        primaryActionLabel: 'Export to excel',
                        primaryAction: this.exportToExcel,
                        parentContextualActions: getArrayOfButtons(
                            this.props.permissions,
                            [
                                { isMoreBtn: false, label: 'Update schedule details', action: this.checkIfMawbEditModalShouldOpen },
                                { isMoreBtn: false, label: 'Notify customer', action: this.toggleNotifyCustomerModal },
                                { isMoreBtn: false, label: 'Update customs details', action: this.getMRNData }
                            ]),
                        childContextualActions: getArrayOfButtons(
                            this.props.permissions,
                            [
                                { isMoreBtn: false, label: 'Update flight details', action: this.toggleEditFlightDetailsPopup },
                                { isMoreBtn: false, label: 'Notify customer', action: this.toggleNotifyCustomerModal },
                                { isMoreBtn: false, label: 'Update customs details', action: this.getMRNData }
                            ])
                    }}
                    reference={this.dataTable}
                    manualPagination={true}
                    pageCount={this.props.pageCount}
                    fetchData={this.props.fetchMawbList}
                />
                <EditMAWBDetailsPopup />
                { this.state.isEditFlightDetailsPopupOpened && <FlightDetailsPopup
                    visible={this.state.isEditFlightDetailsPopupOpened}
                    closeModal={this.toggleEditFlightDetailsPopup}
                    initialValues={this.getInitialValues()}
                /> }
                {this.state.isNotifyCustomerModalOpened && <NotifyCustomerModal
                    visible={this.state.isNotifyCustomerModalOpened}
                    closeModal={this.toggleNotifyCustomerModal}
                    blId={this.getIds().blId}
                    group={CTReasonCodes.Air_LateShipment}
                    isAir={true}
                    refreshFunction={this.props.fetchMawbList}
                />}
                {this.state.isUpdateCustomsModalOpened && <UpdateCustomsDetailsModal
                    data={this.props.mrnData}
                    visible={this.state.isUpdateCustomsModalOpened}
                    closeModal={() => this.setState({ isUpdateCustomsModalOpened: false })}
                    isAir={true}
                    ids={this.getIdsFromSelectedItems()}
                />}
            </React.Fragment>
        );
    }

    private exportToExcel = async () => {
        const res = await this.props.exportContainerTrackingToExcel(true);
        await downloadFile(res);
    };

    private toggleEditFlightDetailsPopup = () => this.setState((state) => ({
        isEditFlightDetailsPopupOpened: !state.isEditFlightDetailsPopupOpened,
    }));

    private toggleNotifyCustomerModal = () => this.setState((state) => ({
        isNotifyCustomerModalOpened: !state.isNotifyCustomerModalOpened
    }));

    private getInitialValues = () => {
        const selectedFlight = this.dataTable.current ? this.dataTable.current.getSelectedItems() : [];
        return {
            ...selectedFlight,
            VoyageReference: selectedFlight[0].voyageReference,
            VesselName: selectedFlight[0].FlightNumber,
            ATD: selectedFlight[0].atd,
            ATA: selectedFlight[0].ata,
            POL: selectedFlight[0].loadingPort,
            POD: selectedFlight[0].destinationPort,
            Status: selectedFlight[0].status,
            VoyageId: selectedFlight[0].voyageId,
            VesselCode: selectedFlight[0].vesselCode
        }
    };

    private getIds = () => {
        const selectedItems = this.dataTable.current ? this.dataTable.current.getSelectedItems() : [];
        const returnValue = (property: string) => selectedItems.map((item: any) => item[property]);
        const blId = returnValue('blId');
        return {
            blId
        }
    };

    private canEditMawbDetails = () => {
        const selectedItems = this.dataTable.current ? this.dataTable.current.getSelectedItems() : [];
        if(selectedItems.length > 1) {
            const vessels = this.dataTable.current.getSelectedItems().map((selectedItem: any) => selectedItem.vessels);
            const sameLength = vessels.reduce((prev: any, current: any) => prev.length === current.length);

            if(sameLength) {
                return true;
            } else {
                return false;
            }
        }
        return true;
    };

    private checkIfMawbEditModalShouldOpen = () => {
        if(this.canEditMawbDetails()) {
            this.props.openCBLDetailsModal(this.getIds().blId[0]);
        } else {
            const message = 'For selected CBLs vessels are not equal. Number of vessels, vessel name, voyage name should be same.';
            this.toggleMultiselectErrorDialog(message)
        }
    };

    private toggleMultiselectErrorDialog = (errorMessage: string) => this.setState((state) => ({
        isMultiselectErrorDialogOpened: !state.isMultiselectErrorDialogOpened,
        errorMessage
    }));

    private getIdsFromSelectedItems = () => {
        const selectedItems = this.dataTable.current.getSelectedItems();
        return selectedItems.map((bl: any) => bl.blId);
    }
 
    private getMRNData = () => {
            const ids = this.getIdsFromSelectedItems();
            this.props.fetchMRNDetails(ids);
            this.setState({ isUpdateCustomsModalOpened: true });
    };
}

const ROLES: BUSINNESS_FUNCTIONS[] = [
    BUSINNESS_FUNCTIONS.AIR_MAWB_TRACKING_EDIT_CBL,
    BUSINNESS_FUNCTIONS.AIR_MAWB_TRACKING_NOTIFY_CUSTOMER,
    BUSINNESS_FUNCTIONS.AIR_MAWB_TRACKING_EDIT_MRN
];

const mapStateToProps = (state: IStore): MapStateToProps => ({
    mrnData: state.vessels.mrnData
});

const mapDispatchToProps = (dispatch: ThunkDispatch<any, any, Action>): IDispatchProps => ({
    fetchMRNDetails: (blId: number[]) => dispatch(fetchMRNDetails(blId, true)),
    exportContainerTrackingToExcel: (isAir: boolean) => dispatch(exportContainerTrackingToExcel(isAir)),
    openCBLDetailsModal: (blId: number) => dispatch(openCBLDetailsModal(blId)),
});

const __MawbTable = connect<any, IDispatchProps, IMawbTableOwnProps, IStore>(mapStateToProps, mapDispatchToProps)(_MawbTable);
export const MawbTable = withPermissions(__MawbTable, ROLES) as any;