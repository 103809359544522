import React from 'react';
import { PageHeader } from 'components/page-header';
import { HandleError } from 'components/handle-error';
import { Page } from 'components/page-container';
import { PageWithTimeline } from 'components/page-with-timeline';
import { HAWBDetailsTopBar } from 'views/planning/hawb-details/components/HAWBDetailsTopBar';
import { DocumentsRow } from 'modules/details-pages/components/DocumentsRow';
import { DeliveryTable } from 'views/planning/deliver-planning-container-details/components/DeliveryTable';
import { DangerousGoodsRow } from 'modules/details-pages/components/DangerousGoodsRow';
import { CustomsClearanceRow } from 'modules/details-pages/components/CustomsClearance';
import { RouteComponentProps } from 'react-router';
import { connect } from 'react-redux';
import { ThunkDispatch } from 'redux-thunk';
import { Action } from 'redux';
import { getHAWBDetails } from 'actions/planning';
import { IStore } from 'reducers/index';
import { IEquipmentDetails } from 'reducers/planning/models';
import { DeliveryPlanningTypes } from 'constants/comment';
import { LoadingAnimation } from 'components/loading';
import { IBubble } from 'reducers/vessels/models';
import { ProgressIndicator } from 'components/progress-indicator';
import { UserDetails } from 'components/user-details';
import { DetailsRow } from '../deliver-planning-container-details/components/DetailsRow';
import { Capability } from 'components/timeline';
import { HBLFCRDetailsTable } from 'modules/details-pages/components/HBLFCRDetailsTable';
import { FlightDetailsRow } from 'modules/details-pages/components/FlightDetails';
import { DeliveryBottomTable } from 'modules/details-pages/components/DeliveryBottomTable';
import { GenericError } from 'components/error-dialog/GenericError';

interface IMapStateToProps {
    hawbDetails: IEquipmentDetails;
    isLoading: boolean;
    bubbles: IBubble[];
}

interface IDispatch {
    getHAWBDetails: (id: number) => Promise<void>;
}

interface IHAWBDetails extends RouteComponentProps<{ id: string }>, IDispatch, IMapStateToProps {}

class _HAWBDetails extends React.Component<IHAWBDetails> {
    public componentDidMount() {
        const id = parseInt(this.props.match.params.id, 10);
        if (!Number.isNaN(id)) {
            this.props.getHAWBDetails(id);
        }
    }

    public render() {
        const id = parseInt(this.props.match.params.id, 10);

        if (Number.isNaN(id)) {
            return <GenericError />;
        }

        return (
            <React.Fragment>
                {this.props.isLoading && <LoadingAnimation />}
                <PageHeader backButton={true}>HAWB: {this.props.hawbDetails.equipmentNumber}</PageHeader>
                <HandleError />
                <ProgressIndicator data={this.props.bubbles} />
                <Page>
                    <HAWBDetailsTopBar
                        customsClearanceStatus={this.props.hawbDetails.cL2_Status}
                        equipmentLandedDate={this.props.hawbDetails.equipmentLandedDate}
                        deliveryPlannedDate={this.props.hawbDetails.deliveryPlannedDate}
                        deliverySite={this.props.hawbDetails.deliverySite}
                        deliveryStatus={this.props.hawbDetails.deliveryStatus}
                        getHAWBDetails={() => this.props.getHAWBDetails(id)}
                        id={id}
                        clientDelivery={this.props.hawbDetails.deliveryPlannedDate}
                        deliveryLocation={this.props.hawbDetails.deliveryCurrentLocation}
                        shift={this.props.hawbDetails.referencesShift}
                        equipment={this.props.hawbDetails}
                    />
                    <PageWithTimeline
                        objectId={id}
                        objectType={DeliveryPlanningTypes.Equipment}
                        isAir={true}
                        capability={Capability.DeliveryPlanning}
                    >
                        <DeliveryTable
                            id={id}
                        />
                        <DetailsRow {...this.props.hawbDetails} />
                        <DocumentsRow
                            documentsCMRRequired={this.props.hawbDetails.documentsCMRRequired}
                            documentsPARNRequired={this.props.hawbDetails.documentsPARNRequired}
                        />
                        <HBLFCRDetailsTable data={this.props.hawbDetails.hbLs} />
                        <DangerousGoodsRow
                            isDangerousGoods={this.props.hawbDetails.isDangerousGoods}
                            dangerousGoodsUNNO={this.props.hawbDetails.dangerousGoodsUNNO}
                            dangerousGoodsClass={this.props.hawbDetails.dangerousGoodsClass}
                            dangerousGoodsDescription={this.props.hawbDetails.dangerousGoodsDescription}
                            dangerousGoodsADRRequired={this.props.hawbDetails.dangerousGoodsADRRequired}
                        />
                        <FlightDetailsRow {...this.props.hawbDetails} />
                        <CustomsClearanceRow
                            cL1_Broker={this.props.hawbDetails.cL1_Broker}
                            cL1_Type={this.props.hawbDetails.cL1_Type}
                            cL1_Status={this.props.hawbDetails.cL1_Status}
                            cL1_StatusDate={this.props.hawbDetails.cL1_StatusDate}
                            cL1_Comments={this.props.hawbDetails.cL1_Comments}
                        />
                        <DeliveryBottomTable data={this.props.hawbDetails.pOs} />
                        <UserDetails
                            user={this.props.hawbDetails.lastUserUpdated}
                            date={this.props.hawbDetails.updateDate}
                        />
                    </PageWithTimeline>
                </Page>
            </React.Fragment>
        );
    }
}

const mapStateToProps = (state: IStore): IMapStateToProps => ({
    hawbDetails: state.planning.hawbDetails,
    isLoading: state.planning.isGetHAWBDetailsPending,
    bubbles: state.planning.hawbDetails.bubbleDataPlanning
});

const mapDispatchToProps = (dispatch: ThunkDispatch<any, any, Action>): IDispatch => ({
    getHAWBDetails: (id: number) => dispatch(getHAWBDetails(id)),
});

export const HAWBDetails = connect<IMapStateToProps, IDispatch, {}, IStore>(mapStateToProps, mapDispatchToProps)(_HAWBDetails);
