import React, { Component } from 'react';
import { PageHeader } from 'components/page-header';
import { HAWBTable } from 'modules/bill-release/hawb/components/HAWBTable';
import { Page } from 'components/page-container';
import { Filters } from 'modules/filters/containers/Filters';
import { FiltersInput } from 'modules/filters/components/FiltersInput';
import { FiltersDateInput } from 'modules/filters/components/FiltersDateInput';
import { FiltersSelect } from 'modules/filters/components/FiltersSelect';
import { connect } from 'react-redux';
import { IStore } from 'reducers/index';
import { ThunkDispatch } from 'redux-thunk';
import { Action } from 'redux';
import { fetchHawbList } from 'actions/bill-release';
import { exportHblReleaseToExcel } from 'actions/export';
import { LoadingAnimation } from 'components/loading';
import { HandleError } from 'components/handle-error';
import { surrenderType } from 'modules/bill-release/shared/components/EditBlModal.tsx/surrenderType';
import { setSort, resetSort } from 'actions/filters';
import { ISortModel } from 'reducers/filters/models';
import { setSortForTable } from 'utilities/tables';

interface IMapStateToProps {
    hawbList: any;
    isLoading: boolean;
    isExportLoading: boolean;
    hawbError: string;
    newEtaEmpty: boolean;
    ataEmpty: boolean;
    surrenderDateEmpty: boolean;
}

interface IMapDispatchToProps {
    fetchHawbList: (pageNumber: number, pageSize: number) => Promise<void>;
    exportHblReleaseToExcel: (isAir: boolean) => Promise<void>;
    setSort: (sort: ISortModel) => void;
    resetSort: () => void;
}

interface IHawb extends IMapStateToProps, IMapDispatchToProps {}

interface IHawbState {
    page: number;
    pageSize: number;
}

class _Hawb extends Component<IHawb, IHawbState> {
    public state: IHawbState = {
        page: 1,
        pageSize: 10
    };

    public render() {
        const filters = [
            <FiltersInput
                key="hblNumber"
                name="hblNumber"
                label="HAWB number"
            />,
            <FiltersInput
                key="vesselName"
                name="vesselName"
                label="Flight Number"
            />,
            <FiltersInput
                key="pod"
                name="podName"
                label="POD"
            />,
            <FiltersInput
                type="number"
                key="daysTillETA_from"
                name="daysTillETA_from"
                label="Days till ETA (from)"
            />,
            <FiltersInput
                type="number"
                key="daysTillETA_to"
                name="daysTillETA_to"
                label="Days till ETA (to)"
            />,
            <FiltersDateInput
                key="newETA_from"
                name="newETA_from"
                label="New ETA (from)"
                type="date"
                withTime={true}
                relatedField="newEta"
                readOnly={this.props.newEtaEmpty}
            />,
            <FiltersDateInput
                key="newETA_to"
                name="newETA_to"
                label="New ETA (to)"
                type="date"
                withTime={true}
                relatedField="newEta"
                readOnly={this.props.newEtaEmpty}
            />,
            <FiltersDateInput
                key="surrenderDate_from"
                name="surrenderDate_from"
                label="Surrender date (from)"
                type="date"
                withTime={true}
                relatedField="surrenderDate"
                readOnly={this.props.surrenderDateEmpty}
            />,
            <FiltersDateInput
                key="surrenderDate_to"
                name="surrenderDate_to"
                label="Surrender date (to)"
                type="date"
                withTime={true}
                relatedField="surrenderDate"
                readOnly={this.props.surrenderDateEmpty}
            />,
            <FiltersDateInput
                key="ata_from"
                name="ata_from"
                label="ATA (from)"
                type="date"
                withTime={true}
                relatedField="ata"
                readOnly={this.props.ataEmpty}
            />,
            <FiltersDateInput
                key="ata_to"
                name="ata_to"
                label="ATA (to)"
                type="date"
                withTime={true}
                relatedField="ata"
                readOnly={this.props.ataEmpty}
            />,
            <FiltersSelect
                key="billType"
                name="billType"
                label="Bill type"
                type="number"
                options={[{ optionText: 'bill type 1', value: 0 }]}
            />,
            <FiltersSelect
                key="surrenderType"
                name="surrenderType"
                label="Surrender type"
                type="number"
                options={surrenderType}
            />,
            <FiltersInput
                key="PONumber"
                name="PONumber"
                label="PO Number"
            />,
            <FiltersInput
                key="lastUserUpdated"
                name="lastUserUpdated"
                label="Last updated by"
            />,
        ];

        return (
            <>
                <PageHeader>HAWB release</PageHeader>
                <Page>
                    {this.props.isExportLoading && <LoadingAnimation />}
                    <HandleError
                        hawbError={this.props.hawbError}
                    />
                    <Filters
                        tableNameKey="ct-hawb"
                        fetchFunction={() => this.props.fetchHawbList(this.state.page, this.state.pageSize)}
                        filters={filters}
                    />
                    <HAWBTable
                        data={this.props.hawbList.data}
                        isLoading={this.props.isLoading}
                        pageCount={Math.ceil(this.props.hawbList.totalCount / this.props.hawbList.pageSize)}
                        fetchHawbs={this.fetchHawbs}
                    />
                </Page>
            </>
        );
    }

    public componentWillUnmount() {
        this.props.resetSort();
    }

    private fetchHawbs = (tableData: any) => {
        this.setState({
            page: tableData.page + 1,
            pageSize: tableData.pageSize
        }, () => {
            setSortForTable(tableData.sorted, this.props.setSort);
            this.props.fetchHawbList(this.state.page, this.state.pageSize);
        });
        
    };
}

const mapStateToProps = (state: IStore): IMapStateToProps => ({
    hawbList: state.bill_release.hawb,
    isLoading: state.bill_release.isFetchHawbListLoading,
    isExportLoading: state.exportReducer.isExportHblReleaseLoading,
    hawbError: state.exportReducer.exportHblError,
    newEtaEmpty: state.filters.filters.neweta_empty,
    ataEmpty: state.filters.filters.ata_empty,
    surrenderDateEmpty: state.filters.filters.surrenderdate_empty
});

const mapDispatchToProps = (dispatch: ThunkDispatch<any, any, Action>): IMapDispatchToProps => ({
    fetchHawbList: (pageNumber: number, pageSize: number) => dispatch(fetchHawbList(pageNumber, pageSize)),
    exportHblReleaseToExcel: (isAir: boolean) => dispatch(exportHblReleaseToExcel(isAir)),
    setSort: (sort: ISortModel) => dispatch(setSort(sort)),
    resetSort: () => dispatch(resetSort()),
});

export const Hawb = connect(mapStateToProps, mapDispatchToProps)(_Hawb);
