import moment from 'moment';
import { IStore } from 'reducers/index';
import { ISettingsModel, IReasonCodeToUpdate } from 'reducers/settings/models';
import { IAction } from 'components/properties-ribbon/models';
import { IOption } from 'components/select/Select';

interface IAsyncActionConstants {
    PENDING: string;
    FULFILLED: string;
    REJECTED: string;
    RESET_ERROR: string;
}

export const createAsyncActionConstants = (action: string): IAsyncActionConstants => ({
    PENDING: `${action}_PENDING`,
    FULFILLED: `${action}_FULFILLED`,
    REJECTED: `${action}_REJECTED`,
    RESET_ERROR: `${action}_RESET_ERROR`
});

export const formatDate = (date: string | Date | null | undefined, dateFormat: string = 'DD-MM-YYYY') => {
    if (date === null || date === undefined) {
        return '-';
    }
    return moment.utc(date).format(dateFormat);
}

export const formatValueIfNullOrUndefined = (value: any) => value === null || value === undefined ? '-' : value;

export const statusesValues = (statuses: any) => {
    const data = [];

    for (const item in statuses) {
        if (item) {
            data.push({
                optionText: statuses[item].text,
                value: statuses[item].value,
            });
        }
    }
    return data;
};


export const formatString = (fmt: string, ...args: any[]): string  => {
    return fmt
        .split('%%')
        .reduce((aggregate, chunk, i) => aggregate + chunk + (args[i] || ''), '');
};

export const applyFilters = (getState: () => IStore, options: { isAirInFilters?: boolean, isAirInBody?: boolean } = {}, body: object = {}) => ({
    PageNumber: getState().filters.pageNumber,
    Page: getState().filters.pageNumber,
    PageSize: getState().filters.pageSize,
    Sorting: getState().filters.column ? {
        order: getState().filters.order,
        column: getState().filters.column,
    }: undefined,
    Filters: {
        ...getState().filters.appliedFilters,
        status: (getState().filters.appliedFilters as any).status ? (getState().filters.appliedFilters as any).status : undefined,
        TransportMode: options.isAirInFilters ? 'air': undefined,
    },
    TransportMode: options.isAirInBody ? 'air' : undefined,
    ...body,
});

export const applyFiltersWithoutPageDetails = (getState: () => IStore, options: { isAirInFilters?: boolean, isAirInBody?: boolean } = {}, body: object = {}) => ({
    Sorting: getState().filters.column ? {
        order: getState().filters.order,
        column: getState().filters.column,
    }: undefined,
    Filters: {
        TransportMode: options.isAirInFilters ? 'air': 'ocean',
        ...getState().filters.appliedFilters,
        status: (getState().filters.appliedFilters as any).status ? (getState().filters.appliedFilters as any).status : undefined,
    },
    TransportMode: options.isAirInBody ? 'air' : 'ocean',
    ...body,
});

export const applyPageDetailsWithoutFilters = (getState: () => IStore, options: { isAirInFilters?: boolean, isAirInBody?: boolean } = {}, body: object = {}) => ({
    Page: getState().filters.pageNumber,
    PageNumber: getState().filters.pageNumber,
    PageSize: getState().filters.pageSize,
    ...body,
});

export const getHaulierText = (haulier: ISettingsModel) => `${haulier.country || '-'}/${haulier.name || '-'}`;

export const hauliersToEnum = (hauliers: ISettingsModel[]) => {
    return hauliers.reduce((obj, item) => ({
        ...obj,
        [item.id]: getHaulierText(item)
    }), {});
}

export const count = (array: any[], value: any) => {
    let val = 0;

    // tslint:disable-next-line:prefer-for-of
    for(let i = 0; i < array.length; i++) {
         if(array[i] === value) {
            val++;
         }
    }

    return val;
}

export const generateError = (e: any): string => {
    if (e && e.response && e.response.data && e.response.data.errors && e.response.data.errors.length) {
        return e.response.data.errors[0].value;
    }

    return 'Error has occurred';
}


export const getArrayOfButtons = (arr: boolean[], actions: any[]) => arr.map((val: boolean, index: number) => {
    if(val) {
        return actions[index];
    }
    return null;
}).filter(el => el !== null);

export const getArrayWithMoreOptions = (arr: boolean[], actions: IAction[]) => actions.map((action: IAction, index: number) => {
    if(arr[arr.length - 1] && index >= arr.length) {
        return action;
    }
    if(arr[index]) {
        return action;
    }
    return {label: '', action: () => null};
});
export interface IError {
    key: string;
    value: string;
}

export const generateErrors = (e: any): string[] => {
    if (e && e.response && e.response.data && e.response.data.errors && e.response.data.errors.length) {
        return e.response.data.errors.map((error: IError) => error.value);
    }

    return ['Error has occurred'];
}

export const mapReasonCodesToOptions = (reasonCodes: IReasonCodeToUpdate[]): IOption[] => reasonCodes.map(obj => ({
    value: obj.reasonCode,
    optionText: obj.reasonCode
}))

export const getReasonCodesForSelectedGroup = (reasonCodes: IReasonCodeToUpdate[], groupId: string) => {
    const data = reasonCodes.filter((reasonCode: any) => reasonCode.group === groupId);
    return typeof data === 'undefined' ? [] : mapReasonCodesToOptions(data);
}

export const parseForURL = (s: string) => s.replace(/([a-z])([A-Z])/g, '$1-$2').toLowerCase();

export const parseURLParamForFilters = (s: string) => s.split('-').map((part: string) => part.charAt(0) + part.slice(1)).join('');

export const sendFileAsBase64 = (ref: React.RefObject<any>, callback: any) => {
    const file = ref.current.getFiles()[0];
    const reader = new FileReader();
  
    reader.onload = () => {
        callback(reader.result)
    };
    reader.readAsArrayBuffer(file);
}