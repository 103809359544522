import React from 'react';
import styled from 'styled-components';
import { Button, ButtonType } from 'components/button';
import { authentication } from 'containers/authorization/authentication';
import { connect } from 'react-redux';
import { IStore } from 'reducers/index';
import { IRoles } from 'utilities/roles';

const SWrapper = styled.div`
    position: absolute;
    left: 0;
    top: 64px;
    width: 100%;
    height: calc(100% - 64px);
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background: #FFF;
`;

const SContainer = styled.div`
    width: 700px;
    display: flex;
    flex-direction: column;
    text-align: center;
    align-items: center;
`;

const SButton = styled(Button)`
    width: 100px;
`;

interface IMapStateToProps {
    userRoles: IRoles[];
}

class _UnauthorizedPage extends React.Component<IMapStateToProps> {
    public render() {
        return(
            <SWrapper>
                <SContainer>
                    <p className="large-body-text">You do not have permisions</p>
                    <SButton 
                        buttonType={ButtonType.Blue} 
                        onClick={this.signOut}
                    >
                        Sign out
                    </SButton>
                </SContainer>
            </SWrapper>
        );
    }

    private signOut = () => authentication.signOut();
}; 

const mapStateToProps = (state: IStore): IMapStateToProps => ({
    userRoles: state.user.roles
});

export const UnauthorizedPage = connect(mapStateToProps)(_UnauthorizedPage);