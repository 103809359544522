import React from 'react';
import { getFormValues, InjectedFormProps, reduxForm } from 'redux-form';
import { ModalScreen } from 'components/modal-screen/index';
import { ModalType } from 'components/modal-screen/ModalType';
import { Action, compose } from 'redux';
import { connect } from 'react-redux';
import { IStore } from 'reducers/index';
import { ThunkDispatch } from 'redux-thunk';
import { editDeliveryDetails, getDeliveryDetailsForEquipments, resetEditDeliveryDetailsError } from 'actions/planning';
import { LoadingAnimation } from 'components/loading';
import { IEquipmentDeliveryDetails } from 'reducers/planning/models';
import { EditFinalDeliveryDetailsModalRow } from 'modules/modals/components/EditFinalDeliveryDetailsModalRow';
import { uniqWith } from 'lodash';
import { Accordion } from 'components/accordion';
import { callApiPost } from 'utilities/apiCaller';
import API from 'constants/api';
import { isObject } from 'lodash';
import { ErrorDialog } from 'components/error-dialog';

interface IEditDeliveryDetailsModal {
    visible: boolean;
    closeModal: () => void;
    ids: number[];
    refetchFunction: any;
    isAir: boolean;
    isFinal?: boolean;
    equipments?: any[];
}

interface IMapStateToProps {
    isLoading: boolean;
    equipmentsDeliveryDetails: IEquipmentDeliveryDetails[];
    initialValues: object;
    formValues: object;
    deliveryDetailsError: string;
}

interface IDispatch {
    editDeliveryDetails: (fields: any, isAir: boolean) => Promise<void>;
    getDeliveryDetailsForEquipments: (ids: number[], isAir: boolean) => Promise<void>;
    resetEditDeliveryDetailsError: () => void;
}

type IProps = InjectedFormProps<{}, IEditDeliveryDetailsModal> & IEditDeliveryDetailsModal & IDispatch & IMapStateToProps;

const FORM_NAME = 'EDIT_DELIVERY_DETAILS_MODAL';

class _EditDeliveryDetailsModal extends React.Component<IProps> {
    public static createInitialValues(initialValues: IEquipmentDeliveryDetails[]) {
        return initialValues.reduce((prev, equipmentDeliveryDetails) => {
            const values = {
                [`clientDelivery_${equipmentDeliveryDetails.equipmentId}`]: equipmentDeliveryDetails.clientDelivery,
                [`shift_${equipmentDeliveryDetails.equipmentId}`]: equipmentDeliveryDetails.shift,
                [`deliveryLocation_${equipmentDeliveryDetails.equipmentId}`]: equipmentDeliveryDetails.deliveryLocationCode,
                [`beCode_${equipmentDeliveryDetails.equipmentId}`]: equipmentDeliveryDetails.beCode,
                [`name_${equipmentDeliveryDetails.equipmentId}`]: equipmentDeliveryDetails.customer,
                [`address_${equipmentDeliveryDetails.equipmentId}`]: equipmentDeliveryDetails.selectedCustomerConfigurationId,
            };
            return {
                ...prev,
                ...values,
            };
        }, {});
    }

    public componentDidMount() {
        if (this.props.ids && this.props.ids.length > 0) {
            this.props.getDeliveryDetailsForEquipments(this.props.ids, this.props.isAir);
        }
    }

    public componentDidUpdate(prevProps: IProps) {
        if(!prevProps.visible && this.props.visible) {
            if (this.props.ids && this.props.ids.length > 0) {
                this.props.getDeliveryDetailsForEquipments(this.props.ids, this.props.isAir);
            }
        }

        if (isObject(this.props.formValues) && isObject(prevProps.formValues) && this.props.formValues !== prevProps.formValues) {
            this.groupBy().forEach(({ equipmentId }) => {
                const currentDeliveryLocation = this.props.formValues[`deliveryLocation_${equipmentId}`];
                const previousDeliveryLocation = prevProps.formValues[`deliveryLocation_${equipmentId}`];

                if (currentDeliveryLocation != null  && currentDeliveryLocation !== previousDeliveryLocation) {
                    this.updateCity(equipmentId)
                }
            });
        }
    }

    public render() {
        const groups = this.groupBy();
        const equipments = this.props.isAir ? this.groupAirEquipmentNumbers() : this.groupEquipmentNumbers();

        const getGroupedRows = groups.map((item, index) => {
            const selectedConfiguration = this.props.formValues && this.props.formValues[`address_${item.equipmentId}`];
            const numbers = equipments
                .filter((equipment: any) => equipment.deliveryLocation === item.deliveryLocation)
                .map((obj: any) => this.props.isAir ? obj.HAWBNumber : obj.equipmentNumber)
                .toString();

            return(
                <Accordion text={`${item.beCode ? item.beCode : '-'}, ${item.deliveryLocation ? item.deliveryLocation : '-'} - ${numbers}`} key={index}>
                    <EditFinalDeliveryDetailsModalRow
                        key={item.equipmentId}
                        equipmentDeliveryDetails={item}
                        selectedConfigurationId={selectedConfiguration}
                    />
                </Accordion>
            );
        });

        const rows = this.props.equipmentsDeliveryDetails.map((equipmentDeliveryDetails) => {
            const selectedConfiguration = this.props.formValues && this.props.formValues[`address_${equipmentDeliveryDetails.equipmentId}`];
            return (
                <EditFinalDeliveryDetailsModalRow
                    key={equipmentDeliveryDetails.equipmentId}
                    equipmentDeliveryDetails={equipmentDeliveryDetails}
                    selectedConfigurationId={selectedConfiguration}
                />
            );
        });

        const title = this.props.ids.length > 1 ? `- ${this.props.ids.length} equipments` : '';

        return (
            <form onSubmit={this.props.handleSubmit(this.onSubmit)}>
                {this.props.isLoading && <LoadingAnimation />}
                <ModalScreen
                    title={`Update ${this.props.isFinal ?  'final ' : ' '}delivery details ${title}`}
                    visible={this.props.visible}
                    modalType={ModalType.m()}
                    closeModal={this.props.closeModal}
                    primaryButtonTitle="Done"
                    primaryButtonType="submit"
                    primaryButtonId="submit-edit-final-delivery-details"
                    secondaryButtonTitle="Cancel"
                    secondaryButtonFunc={this.props.closeModal}
                    id="edit-final-delivery-details"
                >
                    <React.Fragment>
                        <ErrorDialog endpoint={API.DeliveryPlanning.EditDeliveryDetails} preserveError={false} />
                        {this.props.ids.length === 1 ? rows : getGroupedRows}
                    </React.Fragment>
                </ModalScreen>
            </form>
        );
    }

    private onSubmit = async (fields: object) => {
        const groups = this.groupBy();
        const mapGroupedToAllSelected = groups.map((group) => {
            return this.props.equipmentsDeliveryDetails
                .filter(item => item.beCode === group.beCode && item.deliveryLocation === group.deliveryLocation && Number(item.selectedCustomerConfigurationId) === Number(group.selectedCustomerConfigurationId))
                .map((item) => {
                    const SelectedCustomerConfigurationId = fields[`address_${group.equipmentId}`] ? Number(fields[`address_${group.equipmentId}`]) : null
                    return {
                        EquipmentId: item.equipmentId,
                        EstimatedDeliveryDropOffDate: fields[`clientDelivery_${group.equipmentId}`],
                        DeliveryDropOffLocationCode: fields[`deliveryLocation_${group.equipmentId}`],
                        Shift: fields[`shift_${group.equipmentId}`],
                        SelectedCustomerConfigurationId,
                    }
            });
        });
        const flatMapGroupedToAllSelected = mapGroupedToAllSelected.reduce((acc, item) => {
            return acc.concat(item);
        }, []);

        const deliveryDetails = this.props.equipmentsDeliveryDetails.map((equipmentDeliveryDetails) => {
            const SelectedCustomerConfigurationId = fields[`address_${equipmentDeliveryDetails.equipmentId}`] ? Number(fields[`address_${equipmentDeliveryDetails.equipmentId}`]) : null;
            return {
                EquipmentId: equipmentDeliveryDetails.equipmentId,
                EstimatedDeliveryDropOffDate: fields[`clientDelivery_${equipmentDeliveryDetails.equipmentId}`],
                DeliveryDropOffLocationCode: fields[`deliveryLocation_${equipmentDeliveryDetails.equipmentId}`],
                Shift: fields[`shift_${equipmentDeliveryDetails.equipmentId}`],
                SelectedCustomerConfigurationId
            };
        });

        await this.props.editDeliveryDetails({ DeliveryDetails: this.props.ids.length === 1 ? deliveryDetails : flatMapGroupedToAllSelected }, this.props.isAir);
        this.props.closeModal();
        this.props.refetchFunction();
    }

    private updateCity = async (equipmentId: number) => {
        const deliveryLocation = this.props.formValues[`deliveryLocation_${equipmentId}`];

        if (deliveryLocation.length === 8) {
            const query = { query: deliveryLocation, page: 1, pageSize: 1 };
            const config = { headers: { TransportMode: this.props.isAir ? 'Air' : 'Ocean' } };

            const { data: { totalCount, result } } = await callApiPost(API.DeliveryPlanning.GetLocationsByCodes, query, config);

            if (totalCount > 0) {
                this.props.change(`city_${equipmentId}`, result[0].cityName);
                this.props.change(`country_${equipmentId}`, result[0].countryName);
                this.props.change(`address_${equipmentId}`, result[0].address);
                this.props.change(`zipCode_${equipmentId}`, result[0].zipCode);
            }
        } else if (deliveryLocation.length !== 8) {
            this.props.change(`city_${equipmentId}`, '');
            this.props.change(`country_${equipmentId}`, '');
            this.props.change(`address_${equipmentId}`, '');
            this.props.change(`zipCode_${equipmentId}`, '');
        }
    }

    private groupBy = () => uniqWith(this.props.equipmentsDeliveryDetails, (a: IEquipmentDeliveryDetails, b: IEquipmentDeliveryDetails,) => a.beCode === b.beCode && a.deliveryLocation === b.deliveryLocation);

    private groupEquipmentNumbers = () => uniqWith(this.props.equipments, (a, b) => a.deliveryLocation === b.deliveryLocation && a.equipmentNumber === b.equipmentNumber);

    private groupAirEquipmentNumbers = () => uniqWith(this.props.equipments, (a, b) => a.deliveryLocation === b.deliveryLocation && a.HAWBNumber === b.HAWBNumber);
}

const mapStateToProps = (state: IStore): IMapStateToProps => ({
    isLoading: state.planning.isDeliveryDetailsPending || state.planning.isGetDeliveryDetailsForEquipmentsPending,
    deliveryDetailsError: state.planning.deliveryDetailsError,
    equipmentsDeliveryDetails: state.planning.equipmentsDeliveryDetails,
    initialValues: _EditDeliveryDetailsModal.createInitialValues(state.planning.equipmentsDeliveryDetails),
    formValues: getFormValues(FORM_NAME)(state),
});

const mapDispatchToProps = (dispatch: ThunkDispatch<any, any, Action>) => ({
    getDeliveryDetailsForEquipments: (ids: number[], isAir: boolean) => dispatch(getDeliveryDetailsForEquipments(ids, isAir)),
    editDeliveryDetails: (fields: any, isAir: boolean) => dispatch(editDeliveryDetails(fields, isAir)),
    resetEditDeliveryDetailsError: () => dispatch(resetEditDeliveryDetailsError()),
});

export const EditDeliveryDetailsModal = compose(
    connect<IMapStateToProps, IDispatch, IEditDeliveryDetailsModal, IStore>(mapStateToProps, mapDispatchToProps),
    reduxForm<{}, IEditDeliveryDetailsModal>({
        form: FORM_NAME,
        enableReinitialize: true,
    }),
)(_EditDeliveryDetailsModal);
