import React from 'react';
import { ColumnFactory } from 'components/data-table/ColumnFactory';
import { Column } from 'react-table';

export const MultiActionColumn = (accessor: string, name: string, actions: any, sortable: boolean = false): Column => {
    const buttons = (rowInfo: { value: string }) => Object.keys(actions).map((key: string, index: number) => {
        return(
            <button
                style={{ marginRight: 5 }}
                key={index}
                onClick={() => actions[key].func(rowInfo.value)}
            >
                <i className={`fa ${actions[key].iconClass}`} aria-hidden="true" />
            </button>
        )
    });
    const cell = (rowInfo: { value: string }) => (
        <div>
            {buttons(rowInfo)}
        </div>
    );
    return ColumnFactory.getColumnOptionsOld(accessor, name, cell, '', sortable);
};