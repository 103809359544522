import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Page } from 'components/page-container';
import { ThunkDispatch } from 'redux-thunk';
import { Action } from 'redux';
import { IStore } from 'reducers/index';
import { PrefixesTable } from './PrefixesTable';
import { getFactOfficePrefixes } from 'actions/settings';
import { IOfficePrefix } from 'reducers/fact/models';
import { IResultWithPagination } from 'reducers/models';

interface IMapStateToProps {
    prefixes: IResultWithPagination<IOfficePrefix>;
}
interface IMapDispatchToProps {
    getOfficePrefixes: (pageNumber: number, pageSize: number) => Promise<void>;
}

interface IOfficePrefixesProps extends IMapStateToProps, IMapDispatchToProps {}

interface IOfficePrefixesState {
    page: number;
    pageSize: number;
}

class _OfficePrefixes extends Component<IOfficePrefixesProps, IOfficePrefixesState> {
    public state: IOfficePrefixesState = {
        page: 1,
        pageSize: 10,
    };

    public render() {
        return (
            <Page>
                <PrefixesTable 
                    data={this.props.prefixes.result}
                    pageCount={Math.ceil(this.props.prefixes.totalCount / this.props.prefixes.pageSize)}
                    fetchPrefixes={this.fetchPrefixes}
                />
            </Page>
        );
    }

    private fetchPrefixes = (tableData: any) => {
        this.setState({
            page: tableData.page + 1,
            pageSize: tableData.pageSize
        }, () => {
            this.props.getOfficePrefixes(this.state.page, this.state.pageSize);
        });
    };
}

const mapStateToProps = (state: IStore): IMapStateToProps => ({
    prefixes: state.settings.factOfficePrefixes,
});

const mapDispatchToProps = (dispatch: ThunkDispatch<any, any, Action>): IMapDispatchToProps => ({
    getOfficePrefixes: (pageNumber: number, pageSize: number) => dispatch(getFactOfficePrefixes(pageNumber, pageSize)),
});

export const OfficePrefixes = connect(mapStateToProps, mapDispatchToProps)(_OfficePrefixes);
