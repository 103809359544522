import React, { FunctionComponent } from 'react';
import { ModalScreen } from 'components/modal-screen';
import { ModalType } from 'components/modal-screen/ModalType';
import { reduxForm, InjectedFormProps } from 'redux-form';
import { DataTable } from 'components/data-table/DataTable';
import { ColumnFactory } from 'components/data-table/ColumnFactory';
import { withTableCheckboxes, IWithTableCheckboxesProps } from 'containers/data-table/withCheckboxes';
import { FormInput } from 'components/input/FormInput';
import { saveMRNDetails, fetchMRNDetails } from 'actions/vessels';
import { ThunkDispatch } from 'redux-thunk';
import { Action, compose } from 'redux';
import { connect } from 'react-redux';

interface MapDispatchToProps {
    fetchMRNDetails: (blId: number[]) => Promise<void>;
    saveMRNData: (data: any, isAir?: boolean) => Promise<void>;
}

interface UpdateCustomsDetailsModalProps extends IWithTableCheckboxesProps, MapDispatchToProps, InjectedFormProps<{}, any> {
    ids: number[];
    isAir?: boolean;
    data: any[];
    visible: boolean;
    closeModal: () => void;
}


const _UpdateCustomsDetailsModal: FunctionComponent<UpdateCustomsDetailsModalProps> = props => {
    const onSubmit = async (config: any) => {
        if(props.getSelectedData().length > 0) {
            const selectedEquipments = props.getSelectedData().map((equip: any) => ({
                ...equip,
                mrn: config.mrn
            }));
            await props.saveMRNData({ equipments: selectedEquipments }, props.isAir);
            await props.fetchMRNDetails(props.ids);
            props.clearCheckedCheckboxes();
        }
    };

    return (
        <form onSubmit={props.handleSubmit(onSubmit)}>
            <ModalScreen
                title="Update BL details"
                visible={props.visible}
                modalType={ModalType.m()}
                closeModal={props.closeModal}
                primaryButtonTitle="Save"
                primaryButtonType="submit"
                secondaryButtonTitle="Cancel"
                secondaryButtonFunc={props.closeModal}
            >
                <DataTable
                    data={props.data}
                    columns={[
                        ColumnFactory.checkBoxColumn(props.toggleCheckbox, () => null, false),
                        ColumnFactory.standardColumnOld('equipmentNumber', 'Equipment'),
                        ColumnFactory.standardColumnOld('mrn', 'MRN Number')
                    ]}
                />
                <FormInput 
                    name="mrn"
                    label="MRN Number"
                />
            </ModalScreen>
        </form>
    );
}

const mapDispatchToProps = (dispatch: ThunkDispatch<any, any, Action>): MapDispatchToProps => ({
    fetchMRNDetails: (blId:  number[]) => dispatch(fetchMRNDetails(blId)),
    saveMRNData: (data: any, isAir?: boolean) => dispatch(saveMRNDetails(data, isAir))
})

const FORM_NAME = 'UPDATE_CUSTOMS_DETAILS_MODAL';

export const UpdateCustomsDetailsModal = compose(
    connect(undefined, mapDispatchToProps),
    reduxForm({
        form: FORM_NAME,
        enableReinitialize: true
    })
)(withTableCheckboxes(_UpdateCustomsDetailsModal, 'equipmentId', true)) as any


