import React from 'react';
import { connect } from 'react-redux';
import { Action } from 'redux';
import { ThunkDispatch } from 'redux-thunk';
import {
    fetchDeliveryPlanningGlobalsConfiguration
} from 'actions/settings';
import { GlobalsTable } from 'modules/settings/delivery-planning/containers/GlobalsTable';
import { IStore } from 'reducers/index';

interface IDispatch {
    fetchDeliveryPlanningGlobalsConfiguration: () => {};
}

interface IMapStateToProps {
    globalSettings: any[];
    isDeliveryPlanningGlobalSettingsPending: boolean;
}

interface IGlobalsProps extends IMapStateToProps, IDispatch {}

class _Globals extends React.Component<IGlobalsProps> {
    public componentDidMount() {
        this.props.fetchDeliveryPlanningGlobalsConfiguration()
    }

    public render() {
        return(
            <GlobalsTable 
                isLoading={this.props.isDeliveryPlanningGlobalSettingsPending}
                data={this.props.globalSettings}
                updateSettings={() => null}
                refreshSettings={this.props.fetchDeliveryPlanningGlobalsConfiguration}
            />
        )
    }
}

const mapStateToProps = (state: IStore): IMapStateToProps => ({
    globalSettings: state.settings.deliveryPlanningGlobalSettings,
    isDeliveryPlanningGlobalSettingsPending: state.settings.fetchDeliveryPlanningGlobalsSettingsPending
});

const mapDispatchToProps = (dispatch: ThunkDispatch<any, any, Action>): IDispatch => ({
    fetchDeliveryPlanningGlobalsConfiguration: () => dispatch(fetchDeliveryPlanningGlobalsConfiguration()),
});

export const Globals = connect(mapStateToProps, mapDispatchToProps)(_Globals);
