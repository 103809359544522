import React, { Component } from 'react';
import { PageHeader } from 'components/page-header';
import { OceanClearanceTable } from 'modules/customs/ocean/components/OceanClearanceTable';
import { Page } from 'components/page-container';
import { Filters } from 'modules/filters/containers/Filters';
import { FiltersInput } from 'modules/filters/components/FiltersInput';
import { FiltersDateInput } from 'modules/filters/components/FiltersDateInput';
import { FiltersSelect } from 'modules/filters/components/FiltersSelect';
import { statusesValues } from 'utilities/util';
import { connect } from 'react-redux';
import { ThunkDispatch } from 'redux-thunk';
import { Action } from 'redux';
import { IStore } from 'reducers/index';
import { fetchBlList } from 'actions/customs';
import { LoadingAnimation } from 'components/loading';
import { HandleError } from 'components/handle-error';
import { CUSTOMS_STATUS } from 'constants/statuses';
import { ILink } from 'components/sub-navigation/models/Link';
import { SubNavigation } from 'components/sub-navigation';
import { setSort, resetSort } from 'actions/filters';
import { ISortModel } from 'reducers/filters/models';
import { setSortForTable } from 'utilities/tables';

const links: ILink[] = [{
    to: '/customs/ocean-clearance',
    linkText: 'Ocean clearance',
}, {
    to: '/customs/customs-release',
    linkText: 'Customs release',
}];

interface IMapStateToProps {
    bls: any;
    isLoading: boolean;
    isUpdateHBLDetailsError: boolean;
    isFetchHBLFCRDetailsError: boolean;
    isFetchBlsError: boolean;
    isExportLoading: boolean;
    exportCustomsError: string;
    newEtaEmpty: boolean;
    ataEmpty: boolean;
}

interface IMapDispatchToProps {
    fetchBlList: (pageNumber: number, pageSize: number) => Promise<void>;
    setSort: (sort: ISortModel) => void;
    resetSort: () => void;
}

interface IOceanClearanceProps extends IMapStateToProps, IMapDispatchToProps {}

interface IOceanClearanceState {
    page: number;
    pageSize: number;
}

class _OceanClearance extends Component<IOceanClearanceProps, IOceanClearanceState> {
    public state = {
        page: 1,
        pageSize: 10
    };

    public render() {
        const { isFetchHBLFCRDetailsError, isUpdateHBLDetailsError, isFetchBlsError } = this.props;
        const filters = [
            <FiltersInput
                key="hblfcrnumber"
                name="HBLFCRNumber"
                label="HBL/FCR number"
                id="hbl-number-filter"
            />,
            <FiltersInput
                key="vesselName"
                name="VesselName"
                label="Vessel name"
            />,
            <FiltersInput
                key="POD"
                name="podName"
                label="POD"
            />,
            <FiltersInput
                key="POL"
                name="polName"
                label="POL"
            />,
            <FiltersInput
                key="voyage"
                name="VoyageNumber"
                label="Voyage"
            />,
            <FiltersDateInput
                type="date"
                key="newEta_from"
                name="newEta_from"
                label="New ETA (from)"
                relatedField="newEta"
                readOnly={this.props.newEtaEmpty}
            />,
            <FiltersDateInput
                type="date"
                key="newEta_to"
                name="newEta_to"
                label="New ETA (to)"
                isEndOfDay={true}
                relatedField="newEta"
                readOnly={this.props.newEtaEmpty}
            />,
            <FiltersDateInput
                type="date"
                key="ata_from"
                name="ata_from"
                label="ATA (from)"
                relatedField="ata"
                readOnly={this.props.ataEmpty}
            />,
            <FiltersDateInput
                type="date"
                key="ata_to"
                name="ata_to"
                label="ATA (to)"
                isEndOfDay={true}
                relatedField="ata"
                readOnly={this.props.ataEmpty}
            />,
            <FiltersSelect
                key="status"
                name="Status"
                label="Customs status"
                type="number"
                options={statusesValues(CUSTOMS_STATUS)}
            />,
            <FiltersInput
                key="PONumber"
                name="PONumber"
                label="PO Number"
            />,
            <FiltersInput
                key="lastUserUpdated"
                name="lastUserUpdated"
                label="Last updated by"
            />,
        ];
        return (
            <>
                <PageHeader id="ocean-clearance-header">
                    Ocean clearance
                </PageHeader>
                <Page>
                    {this.props.isLoading || this.props.isExportLoading && <LoadingAnimation />}
                    <HandleError
                        exportCustomsError={this.props.exportCustomsError}
                    />
                    <HandleError
                        isUpdateHBLDetailsError={isUpdateHBLDetailsError}
                        isFetchHBLFCRDetailsError={isFetchHBLFCRDetailsError}
                        isFetchBlsError={isFetchBlsError}
                    />
                    <Filters
                        tableNameKey="customs-ocean"
                        filters={filters}
                        fetchFunction={() => this.props.fetchBlList(this.state.page, this.state.pageSize)}
                    />
                    <SubNavigation links={links} />
                    <OceanClearanceTable
                        isLoading={this.props.isLoading}
                        data={this.props.bls.result}
                        pageCount={Math.ceil(this.props.bls.totalCount / this.props.bls.pageSize)}
                        fetchBls={this.fetchBls}
                        refreshFunction={() => this.props.fetchBlList(1, 10)}
                    />
                </Page>
            </>
        );
    }

    public componentWillUnmount() {
        this.props.resetSort();
    }

    private fetchBls = (tableData: any) => {
        this.setState({
            page: tableData.page + 1,
            pageSize: tableData.pageSize
        }, () => {
            setSortForTable(tableData.sorted, this.props.setSort);
            this.props.fetchBlList(this.state.page, this.state.pageSize);
        });
    };
}

const mapStateToProps = (state: IStore): IMapStateToProps => ({
    bls: state.customs.bls,
    isLoading: state.customs.isFetchBlsLoading,
    isUpdateHBLDetailsError: !!state.customs.updateHBLDetailsError,
    isFetchHBLFCRDetailsError: !!state.customs.fetchHBLFCRDetailsError,
    isFetchBlsError: !!state.customs.fetchBlsError,
    isExportLoading: state.exportReducer.isExportCustomsLoading,
    exportCustomsError: state.exportReducer.exportCustomsError,
    newEtaEmpty: state.filters.filters.neweta_empty,
    ataEmpty: state.filters.filters.ata_empty
});

const mapDispatchToProps = (dispatch: ThunkDispatch<any, any, Action>): IMapDispatchToProps => ({
    fetchBlList: (pageNumber: number, pageSize: number) => dispatch(fetchBlList(pageNumber, pageSize)),
    setSort: (sort: ISortModel) => dispatch(setSort(sort)),
    resetSort: () => dispatch(resetSort()),
});

export const OceanClearance = connect(mapStateToProps, mapDispatchToProps)(_OceanClearance);
