import React, { Component } from 'react';
import { ModalScreen } from 'components/modal-screen';
import { ModalType } from 'components/modal-screen/ModalType';
import { DoubleGrid } from 'components/grid/double-grid';
import { SingleGrid } from 'components/grid/single-grid';
import { OneStatus } from 'components/status-row/OneStatus';
import { ButtonType, Button } from 'components/button';
import { InjectedFormProps, reduxForm } from 'redux-form';
import { FormDateInput } from 'components/date-input/FormDateInput';
import { FormTextArea } from 'components/textarea/FormTextArea';
import { FormSelect } from 'components/select/FormSelect';
import { surrenderType } from './surrenderType';
import { resetEditHblDetailsError } from 'actions/bill-release';
import { connect } from 'react-redux';
import { IStore } from 'reducers/index';
import { Dialog } from 'components/dialog';
import { compose, Action } from 'redux';
import { ThunkDispatch } from 'redux-thunk';

interface IMapDispatchToProps {
    resetEditHblDetailsError: () => void;
}

interface IMapStateToProps {
    editHblDetailsError: string;
}

interface IEditHblPopupOwnProps {
    visible: boolean;
    blId: number;
    isSurrendered: boolean;
    isHbl?: boolean;
    isAir?: boolean;
    modalName: 'HBL' | 'CBL' | 'HAWB';
    closeModal: () => void;
    submitFunc: (body: any) => Promise<void>;
}

interface IEditHblPopupProps extends IEditHblPopupOwnProps, IMapDispatchToProps, IMapStateToProps, InjectedFormProps<{}, IEditHblPopupOwnProps> {}

interface IEditHblPopupState {
    active: number | boolean;
}

export class _EditHblPopup extends Component<IEditHblPopupProps, IEditHblPopupState> {
    public state: IEditHblPopupState = {
        active: false,
    };

    public componentDidUpdate(prevProps: IEditHblPopupProps) {
        if(this.props.isSurrendered !== prevProps.isSurrendered) {
            if(this.props.isSurrendered === null || this.props.isSurrendered === undefined) {
                this.setState({ active: false });
            } else {
                this.setState({ active: this.props.isSurrendered });
            }
        }

        if (this.props.visible && !prevProps.visible) {
            this.props.resetEditHblDetailsError();
        }
    }

    public render() {
        const buttons = [
            { text: 'No', value: false },
            { text: 'Yes', value: true }
        ];

        const radioButtons = buttons.map((button) => (
            <Button
                key={button.text}
                buttonType={ButtonType.Transparent}
                onClick={() => this.toggleButton(button.value)}
                isActive={button.value === this.state.active}
            >
                {button.text}
            </Button>
        ));

        return (
            <form onSubmit={this.props.handleSubmit(this.onSubmit)}>
                <ModalScreen
                    title={`Update ${this.props.modalName} details`}
                    modalType={ModalType.s()}
                    primaryButtonTitle="Save"
                    primaryButtonType="submit"
                    secondaryButtonTitle="Cancel"
                    secondaryButtonFunc={this.props.closeModal}
                    visible={this.props.visible}
                    closeModal={this.props.closeModal}
                >
                    <Dialog
                        message={this.props.editHblDetailsError}
                        dialogType={500}
                        isVisible={!!this.props.editHblDetailsError}
                        closeDialog={this.props.resetEditHblDetailsError}
                    />
                    <div className="page-container">
                        <DoubleGrid>
                            <OneStatus label="Original Bill Surrendered">
                                <div className="option-btn-group">
                                    {radioButtons}
                                </div>
                            </OneStatus>
                            <FormDateInput
                                name="SurrenderedDate"
                                label="Original Bill Surrendered date"
                                withTime={this.props.isAir ? true : false}
                            />
                        </DoubleGrid>
                        <SingleGrid>
                            <FormSelect
                                label="Surrender type"
                                name="SurrenderType"
                                options={[
                                    { optionText: 'Select value...', value: '' },
                                    ...surrenderType
                                ]}
                            />
                        </SingleGrid>
                        <SingleGrid>
                            <FormTextArea
                                name="Comment"
                                label="Comment"
                            />
                        </SingleGrid>
                    </div>
                </ModalScreen>
            </form>
        );
    }

    private onSubmit = async (body: any) => {
        try {
            const detectIdToSend = this.props.isHbl ? 'HBLId' : 'MBLId';
            const requestBody = {
                [detectIdToSend]: this.props.blId,
                SurrenderType: Number(body.SurrenderType),
                Surrendered: this.state.active,
                SurrenderDate: body.SurrenderedDate,
            };

            await this.props.submitFunc(requestBody);
            await this.props.closeModal();
        } catch (error) {
            console.error(error);
        }
    }

    private toggleButton = (active: boolean) => this.setState({ active });
};

const FORM_NAME = 'EDIT_HBL_DETAILS';

const mapStateToProps = ({ bill_release }: IStore): IMapStateToProps => ({
    editHblDetailsError: bill_release.editBLDetailsError
})

const mapDispatchToProps = (dispatch: ThunkDispatch<any, any, Action>): IMapDispatchToProps => ({
    resetEditHblDetailsError: () => dispatch(resetEditHblDetailsError()),
})

export const EditHblPopup = compose(
    reduxForm<{}, IEditHblPopupOwnProps>({ form: FORM_NAME, enableReinitialize: true }),
    connect<IMapStateToProps, IMapDispatchToProps, IEditHblPopupOwnProps, IStore>(mapStateToProps, mapDispatchToProps)
)(_EditHblPopup);
