export enum TRACKING_ROUTES {
    VESSEL_TRACKING = '/tracking',
    OCEAN_MBL_TRACKING = '/tracking/mbl-tracking',
    MAWB_TRACKING = '/tracking/mawb-tracking',
    MILESTONES = '/tracking/milestones'
}

export enum CARRIER_RELEASE_ROUTES {
    HBL_RELEASE = '/bill-release/hbl',
    CBL_RELEASE = '/bill-release/cbl',
    HAWB_RELEASE = '/bill-release/hawb',
}

export enum CUSTOMS_ROUTES {
    OCEAN_CLEARANCE = '/customs/ocean-clearance',
    AIR_CLEARANCE = '/customs/air-clearance'
}

export enum PLANNING_ROUTES {
    CONTAINER_PLANNING = '/planning',
    HAWB_PLANNING = '/planning/hawb',
    PO_PLANNING = '/planning/po'
}

export enum DELIVERY_ROUTES {
    EQUIPMENT = '/delivery-and-return/equipment',
    LOCATION = '/delivery-and-return/location',
    DD_DEMURRAGE = '/demurrage-and-detention/demurrage',
    DD_DETENTION = '/demurrage-and-detention/detention',
    AIR_DELIVERY = '/delivery-and-return/air',
}

export enum VESSEL_SCHEDULE_ROUTES {
    VESSEL_SCHEDULE = '/vessel-schedule',
}

export enum EXCEPTION_MANAGEMENT_ROUTES {
    FACT = '/exception-management/fact',
    SETTINGS = '/exception-management/settings',
    EVENT_LOGS = '/exception-management/event-logs',
    CUSTOMER_MANAGEMENT = '/exception-management/customer-management',
}

export enum OTHER_ROUTES {
    UNAUTHORIZED = '/unauthorized'
}