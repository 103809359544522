import React from 'react';
import { ColumnFactory } from 'components/data-table/ColumnFactory';
import { DataTable } from 'components/data-table/DamcoTable';
import { cloneDataWithDisplayNames } from 'utilities/tables';

interface IEquipmentTable {
    data: any[];
}

export const EquipmentTable = (props: IEquipmentTable) => {
    const data = cloneDataWithDisplayNames(props.data);

    return(
        <DataTable
            showPagination={true}
            expandable={false}
            withCheckboxes={false}
            reference={null as any}
            data={data}
            columns={[
                ColumnFactory.standardColumn('equipmentNumberDisplayName', 'Equipment number'),
                ColumnFactory.standardColumn('serviceDisplayName', 'Service'),
                ColumnFactory.standardColumn('shipmentTypeDisplayName', 'Shipment type'),
                ColumnFactory.standardColumn('sizeTypeDisplayName', 'Size/Tpe'),
                ColumnFactory.standardColumn('hotBoxDisplayName', 'Hotbox'),
                ColumnFactory.standardColumn('sealNumberDisplayName', 'Seal number'),
                ColumnFactory.standardColumn('totalPackagesDisplayName', 'Total packages'),
                ColumnFactory.standardColumn('packageTypeDisplayName', 'Package type'),
                ColumnFactory.standardColumn('grossWeightDisplayName', 'Weight'),
                ColumnFactory.standardColumn('volumeDisplayName', 'Volume'),
            ]}
            tableName="CBLEquipmentTable"
        />
    );
};
