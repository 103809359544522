import { TYPES } from 'action-types/modals';
import { IReasonCode } from 'reducers/settings/models';

interface IAction {
    readonly type: string;
    readonly error?: string;
    readonly result: any;
}

export interface IModalsState {
    isNotifyCustomerPending: boolean;
    notifyCustomerError: string,
    isGetReasonCodesLoading: boolean;
    codes: IReasonCode[];
}

const defaultState: IModalsState = {
    isNotifyCustomerPending: false,
    notifyCustomerError: '',
    isGetReasonCodesLoading: false,
    codes: []
};

export const reducer = (state: IModalsState = defaultState, action: IAction): IModalsState => {
    switch (action.type) {
        case TYPES.NOTIFY_CUSTOMER.PENDING:
            return {
                ...state,
                isNotifyCustomerPending: true,
                notifyCustomerError: '',
            };
        case TYPES.NOTIFY_CUSTOMER.FULFILLED:
            return {
                ...state,
                isNotifyCustomerPending: false,
                notifyCustomerError: '',
            };
        case TYPES.NOTIFY_CUSTOMER.REJECTED:
            return {
                ...state,
                isNotifyCustomerPending: false,
                notifyCustomerError: action.error || '',
            };
        case TYPES.NOTIFY_CUSTOMER.RESET_ERROR:
            return {
                ...state,
                notifyCustomerError: '',
            };
        case TYPES.GET_REASON_CODES.PENDING:
            return {
                ...state,
                isGetReasonCodesLoading: true
            };
        case TYPES.GET_REASON_CODES.FULFILLED:
            return {
                ...state,
                codes: action.result || state.codes ,
                isGetReasonCodesLoading: false
            };
        case TYPES.GET_REASON_CODES.REJECTED:
            return {
                ...state,
                isGetReasonCodesLoading: false
            };
        case TYPES.NOTIFY_CUSTOMER_FOR_CUSTOMS.PENDING:
            return {
                ...state,
                isNotifyCustomerPending: true,
                notifyCustomerError: '',
            };
        case TYPES.NOTIFY_CUSTOMER_FOR_CUSTOMS.FULFILLED:
            return {
                ...state,
                isNotifyCustomerPending: false,
                notifyCustomerError: '',
            };
        case TYPES.NOTIFY_CUSTOMER_FOR_CUSTOMS.REJECTED:
            return {
                ...state,
                isNotifyCustomerPending: false,
                notifyCustomerError: action.error || '',
            };
        case TYPES.NOTIFY_CUSTOMER_FOR_CUSTOMS.RESET_ERROR:
            return {
                ...state,
                notifyCustomerError: '',
            };
        default:
            return state;
    }
};
