import React from 'react';
import { OneStatus } from 'components/status-row/OneStatus';
import { StatusRow } from 'components/status-row';
import { QuarterGrid } from 'components/grid/quarter-grid';
import { formatDate, formatValueIfNullOrUndefined } from 'utilities/util';

interface IDemurrageAndDetention {
    firstChargeableDayDemmurage?: string;
    gateOutPortDate?: string;
    numberOfDaysDemmurage?: number;
    demurrageReasonCode?: string;
    demurrageResponsible?: string;
    firstChargeableDayDetention?: string;
    milestonesEmptyReturnDate?: string;
    numberOfDaysDetention?: number;
    detentionReasonCode?: string;
    detentionResponsible?: string;
}

export const DemurrageAndDetentionRow: React.SFC<IDemurrageAndDetention> = (props: IDemurrageAndDetention) => (
    <StatusRow title="Demurrage & Detention">
        <div className="grid-wrapper">
            <QuarterGrid>
                <OneStatus label="First chargeable day demurrage">
                    {formatDate(props.firstChargeableDayDemmurage)}
                </OneStatus>
                <OneStatus label="Gate Out Port">
                    {formatDate(props.gateOutPortDate)}
                </OneStatus>
                <OneStatus label="No. of days demurrage">
                    {formatValueIfNullOrUndefined(props.numberOfDaysDemmurage)}
                </OneStatus>
                <OneStatus label="Reason Code">
                    {formatValueIfNullOrUndefined(props.demurrageReasonCode)}
                </OneStatus>
            </QuarterGrid>
            <QuarterGrid>
                <OneStatus label="Responsible">
                    {formatValueIfNullOrUndefined(props.demurrageResponsible)}
                </OneStatus>
            </QuarterGrid>
        </div>
        <div className="grid-wrapper">
            <QuarterGrid>
                <OneStatus label="First chargeable day detention">
                    {formatDate(props.firstChargeableDayDetention)}
                </OneStatus>
                <OneStatus label="Empty Return">
                    {formatDate(props.milestonesEmptyReturnDate)}
                </OneStatus>
                <OneStatus label="No. of days detention">
                    {formatValueIfNullOrUndefined(props.numberOfDaysDetention)}
                </OneStatus>
                <OneStatus label="Reason Code">
                    {formatValueIfNullOrUndefined(props.detentionReasonCode)}
                </OneStatus>
            </QuarterGrid>
            <QuarterGrid>
                <OneStatus label="Responsible">
                    {formatValueIfNullOrUndefined(props.detentionResponsible)}
                </OneStatus>
            </QuarterGrid>
        </div>
    </StatusRow>
);
