import React, { Component } from 'react';
import { ModalScreen } from 'components/modal-screen';
import { ModalType } from 'components/modal-screen/ModalType';
import { LoadingAnimation } from 'components/loading';
import { connect } from 'react-redux';
import {
    editVesselDetailsOnCBLLevel,
    sendCommentToVessel,
    IBodyCommentVessel,
    saveMawbDetails,
    closeCBLDetailsModal,
    resetEditVesselDetailsError,
    resetSaveMawbDetailsError,
} from 'actions/vessels';
import { submit, reset } from 'redux-form';
import { ThunkDispatch } from 'redux-thunk';
import { Action } from 'redux';
import { FORM_NAME } from './EditCblDetailsForm';
import { Dialog } from 'components/dialog';
import { IStore } from 'reducers/index';
import { EditCBLDetailsPopupTable } from 'modules/tracking/containers/components/EditCBLDetailsPopupTable';
import { IBLVessel } from 'reducers/vessels/models';
import { ContainerTrackingTypes } from 'constants/comment';
import { fetchComments } from 'actions/timeline';
import { Capability } from 'components/timeline';
import { ErrorDialog } from 'components/error-dialog';
import API from 'constants/api';

interface IEditCBLMAWBDetailsPopupOwnProps {
    isDetails?: boolean;
    refetchFunction: () => Promise<void>;
    vessels: IBLVessel[];
    isAir?: boolean;
}

interface IEditCBLMAWBDetailsPopup extends IDispatch, IMapState, IEditCBLMAWBDetailsPopupOwnProps {
}

interface IEditCBLMAWBDetailsPopupState {
    isAddVesselModalOpened: boolean;
    isDialogVisible: boolean;
    message: string;
    dialogType: number;
    isAnySelected: boolean;
}

interface IMapState {
    isLoading: boolean;
    cblDetailsModalBLId: number;
    isOpenCBLDetailsModalOpened: boolean;
    editVesselPendingError: string;
    editMawbDetailsError: string;
}

interface IDispatch {
    closeModal: () => void;
    submit: (formName: string) => {};
    reset: (formName: string) => {};
    editVesselDetailsOnCBLLevel: (fields: any, blId: number, onSuccess: () => void, onRejected: (error: any) => void) => {};
    sendCommentToVessel: (body: IBodyCommentVessel, onSuccess: () => void, onRejected: (error: any) => void) => {};
    saveCbl: (params: any) => Promise<void>;
    fetchComments: (objectType: string, id: number) => Promise<any>;
    resetEditVesselDetailsError: () => void;
    resetSaveMawbDetailsError: () => void;
}

class _EditCblDetailsPopup extends Component<IEditCBLMAWBDetailsPopup, IEditCBLMAWBDetailsPopupState> {
    public state: IEditCBLMAWBDetailsPopupState = {
        isDialogVisible: false,
        message: '',
        dialogType: 200,
        isAddVesselModalOpened: false,
        isAnySelected: false,
    };

    public render() {
        const title = this.props.isAir ? 'Update flight details' : 'Update schedule details';

        return (
            <>
                { this.props.isLoading && <LoadingAnimation />}
                <ModalScreen
                    title={title}
                    modalType={ModalType.l()}
                    visible={this.props.isOpenCBLDetailsModalOpened}
                    closeModal={this.props.closeModal}
                    primaryButtonTitle="Save"
                    primaryButtonId="edit-cbl-details-popup-save"
                    id="edit-cbl-details-popup"
                    primaryButtonFunc={() => this.props.submit(FORM_NAME)}
                    primaryButtonIsDisabled={!this.state.isAnySelected}
                    secondaryButtonTitle="Cancel"
                    secondaryButtonFunc={this.props.closeModal}
                >
                    <ErrorDialog endpoint={API.ContainerTracking.EditVessel} />
                    <ErrorDialog endpoint={API.ContainerTracking.EditVesselDetails} />
                    {this.props.isOpenCBLDetailsModalOpened && <EditCBLDetailsPopupTable
                        blId={this.props.cblDetailsModalBLId}
                        data={this.props.vessels}
                        editVessel={this.editVessel}
                        refetchFunction={this.props.refetchFunction}
                        isAir={!!this.props.isAir}
                        isAnySelected={this.checkIfAnySelected}
                    />}
                    <Dialog
                        message={this.state.message}
                        dialogType={this.state.dialogType}
                        isVisible={this.state.isDialogVisible}
                        closeDialog={() => this.setState({ isDialogVisible: false })}
                    />
                </ModalScreen>
            </>
        );
    };

    private editVessel = async (fields: any) => {
        try {
            const selectedFlight = this.props.vessels.find((obj: any) => obj.selected) as any;

            if (selectedFlight) {
                await this.props.saveCbl({
                    VoyageId: fields.VoyageId,
                    MblId: fields.MblId,
                    ETA: fields.ETA,
                    ETD: fields.ETD,
                    ATA: fields.ATA,
                    ATD: fields.ATD
                });

                if (fields.comment) {
                    await this.props.sendCommentToVessel({
                            content: fields.comment,
                            ObjectId: fields.VoyageId,
                            ObjectType: ContainerTrackingTypes.Voyage,
                        },
                        () => {
                            this.props.fetchComments(ContainerTrackingTypes.Voyage, this.props.cblDetailsModalBLId);
                        },
                        () => {
                            this.setState({
                                message: 'Some error occured!',
                                isDialogVisible: true,
                                dialogType: 400,
                            })
                        });
                }
                this.props.reset(FORM_NAME);
                this.props.refetchFunction();
                this.props.closeModal();
            } else {
                this.setState({
                    message: 'Some error occured!',
                    isDialogVisible: true,
                    dialogType: 400,
                })
            }
        } catch (error) {
            console.error(error);
        }
    };

    private checkIfAnySelected = (list: any) => {
        if(list.length > 0) {
            this.setState({
                isAnySelected: true
            });
        } else {
            this.setState({
                isAnySelected: false
            });
        }
    };
}

const mapStateToProps = (state: IStore): IMapState => ({
    isLoading: state.vessels.isLoading,
    cblDetailsModalBLId: state.vessels.cblDetailsModalBLId,
    isOpenCBLDetailsModalOpened: state.vessels.isOpenCBLDetailsModalOpened,
    editVesselPendingError: state.vessels.editVesselPendingError,
    editMawbDetailsError: state.vessels.editMawbDetailsError
});

const mapDispatchToProps = (dispatch: ThunkDispatch<any, any, Action>): IDispatch => ({
    fetchComments: (objectType: string, id: number) => dispatch(fetchComments({
        objectType,
        id,
        isAir: true,
        capability: Capability.ContainerTracking,
    })),
    closeModal: () => dispatch(closeCBLDetailsModal()),
    submit: (formName: string) => dispatch(submit(formName)),
    reset: (formName: string) => dispatch(reset(formName)),
    sendCommentToVessel: (body: IBodyCommentVessel, onSuccess: () => void, onRejected: (error: any) => void) => dispatch(sendCommentToVessel(body, onSuccess, onRejected)),
    editVesselDetailsOnCBLLevel: (fields: any, blId: number, onSuccess: () => void, onRejected: (error: any) => void) => dispatch(editVesselDetailsOnCBLLevel(fields, blId, onSuccess, onRejected)),
    saveCbl: (params: any) => dispatch(saveMawbDetails(params)),
    resetEditVesselDetailsError: () => dispatch(resetEditVesselDetailsError()),
    resetSaveMawbDetailsError: () => dispatch(resetSaveMawbDetailsError())
});

export const EditCBLMAWBDetailsPopup = connect<IMapState, IDispatch, IEditCBLMAWBDetailsPopupOwnProps, IStore>(mapStateToProps, mapDispatchToProps)(_EditCblDetailsPopup);
