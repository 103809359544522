import React, { Component } from 'react';
import { connect } from 'react-redux';
import { ReasonCodesTable } from 'modules/settings/customs/containers/ReasonCodesTable';
import { ThunkDispatch } from 'redux-thunk';
import { Action } from 'redux';
import { fetchCustomsReasonCodes } from 'actions/settings';
import { IStore } from 'reducers/index';
import { IReasonCode } from 'reducers/settings/models';

interface IMapStateToProps {
    reasonCodes: IReasonCode[];
}

interface IMapDispatchToProps {
    fetchCustomsReasonCodes: (group?: string) => Promise<void>;
}

interface IReasonCodes extends IMapStateToProps, IMapDispatchToProps {};

interface IReasonCodesState {
    createNewReasonCodeModalIsOpen: boolean;
}

class _ReasonCodes extends Component<IReasonCodes, IReasonCodesState> {
    public state: IReasonCodesState = {
        createNewReasonCodeModalIsOpen: false
    };

    public componentDidMount() {
        this.props.fetchCustomsReasonCodes();
    }

    public render() {
        return(
            <ReasonCodesTable
                data={this.props.reasonCodes}
            />
        );
    }
}

const mapStateToProps = (state: IStore): IMapStateToProps => ({
    reasonCodes: state.settings.customsReasonCodes,
});

const mapDispatchToProps = (dispatch: ThunkDispatch<any, any, Action>): IMapDispatchToProps => ({
    fetchCustomsReasonCodes: (group?: string) => dispatch(fetchCustomsReasonCodes(group))
});

export const ReasonCodes = connect(mapStateToProps, mapDispatchToProps)(_ReasonCodes);