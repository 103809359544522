import { callApiPost } from './../utilities/apiCaller';
import { TYPES } from 'action-types/timeline';
import { ThunkDispatch } from 'redux-thunk';
import { Action } from 'redux';
import { callApiPut } from 'utilities/apiCaller';
import API from 'constants/api';
import { generateError } from 'utilities/util';
import { Capability } from 'components/timeline';
import { IStore } from 'reducers/index';
import { ICommentFilters } from 'components/timeline/components/Filters';

export interface IAddComment {
    objectId: number;
    objectType: string;
    content: string;
    commentId?: number;
}

export interface IFetchCommentsData {
    objectType: string;
    id: number;
    isAir: boolean;
    capability: Capability;
}

export interface IAdditionalRequest {
    objectTypes: string[];
    ids: number[];
}

export interface ILoadMoreCommentsData extends IFetchCommentsData {
    page: number;
}

export const getFetchEndpointForCapability = (capability: Capability, objectType: string, id: number) => {
    switch (capability) {
        case Capability.Customs:
            return API.Comments.CustomsGet(objectType, id);
        case Capability.DeliveryPlanning:
            return API.Comments.DeliveryPlanningGet(objectType, id);
        default:
            return API.Comments.Get(objectType, id);
    }
};

export const getAddEndpointForCapability = (capability: Capability) => {
    switch (capability) {
        case Capability.Customs:
            return API.Comments.CustomsAdd;
        case Capability.DeliveryPlanning:
            return API.Comments.DeliveryPlanningAdd;
        default:
            return API.Comments.Add;
    }
};

export const addNewComment = (body: IAddComment, isAir: boolean, capability: Capability) => {
    return async (dispatch: ThunkDispatch<any, any, Action>) => {
        dispatch({ type: TYPES.ADD_COMMENT.PENDING });
        try {
            const endpoint = getAddEndpointForCapability(capability);
            const options = { headers: { TransportMode: isAir ? 'Air' : 'Ocean' } };

            await callApiPut(endpoint, body, options);

            dispatch({
                type: TYPES.ADD_COMMENT.FULFILLED,
            });
        } catch (e) {
            dispatch({ type: TYPES.ADD_COMMENT.REJECTED });
        }
    };
};

export const fetchComments = ({ objectType, id, isAir, capability }: IFetchCommentsData) => {
    return async (dispatch: ThunkDispatch<any, any, Action>, getState: () => IStore) => {
        const endpoint = getFetchEndpointForCapability(capability, objectType, id);
        const options = { headers: { TransportMode: isAir ? 'Air' : 'Ocean' } };
        const { filters } = getState().timeline;
        const res = await callApiPost(endpoint, { pageSize: 10, filters }, options);
        dispatch({
            type: TYPES.FETCH_COMMENTS.FULFILLED,
            comments: res.data,
        });
    };
};

export const fetchAdditionalComments = ({ objectType, id, isAir, capability }: IFetchCommentsData) => {
    return async (dispatch: ThunkDispatch<any, any, Action>, getState: () => IStore) => {
        const endpoint = getFetchEndpointForCapability(capability, objectType, id);
        const options = { headers: { TransportMode: isAir ? 'Air' : 'Ocean' } };
        const { filters } = getState().timeline;
        const res = await callApiPost(endpoint, { pageSize: 10, filters }, options);
        dispatch({
            type: TYPES.FETCH_ADDITIONAL_COMMENTS.FULFILLED,
            additionalComments: res.data,
        });
    };
};

export const loadMoreComments = ({ objectType, id, isAir, capability, page }: ILoadMoreCommentsData) => {
    return async (dispatch: ThunkDispatch<any, any, Action>, getState: () => IStore) => {
        dispatch({ type: TYPES.LOAD_MORE_COMMENTS.PENDING });

        try {
            const endpoint = getFetchEndpointForCapability(capability, objectType, id);
            const options = { headers: { TransportMode: isAir ? 'Air' : 'Ocean' } };
            const { filters } = getState().timeline;
            const res = await callApiPost(
                endpoint,
                {
                    page,
                    pageSize: 10,
                    filters,
                },
                options,
            );

            dispatch({
                type: TYPES.LOAD_MORE_COMMENTS.FULFILLED,
                comments: res.data,
            });
        } catch (e) {
            const error = generateError(e);

            dispatch({
                type: TYPES.LOAD_MORE_COMMENTS.REJECTED,
                error,
            });
        }
    };
};

export const setFilters = (filters: ICommentFilters, fetchData: IFetchCommentsData, additionalRequest?: IAdditionalRequest) => {
    return async (dispatch: ThunkDispatch<any, any, Action>) => {
        dispatch({ type: TYPES.SET_FILTERS, filters });
        if(additionalRequest) {
            const data: IFetchCommentsData[] = [];

            additionalRequest.ids.forEach((id: number, i: number) => data.push({
                objectType: additionalRequest.objectTypes[i],
                id,
                isAir: fetchData.isAir,
                capability: fetchData.capability
            }));
            await Promise.all(additionalRequest.ids.map(async (id: number, i: number) => {
                await dispatch(fetchAdditionalComments({
                    objectType: additionalRequest.objectTypes[i],
                    id,
                    isAir: fetchData.isAir,
                    capability: fetchData.capability
                }));
            }));
            dispatch(fetchComments(fetchData));
        } else {
            dispatch(fetchComments(fetchData));
        }
    };
}
