import React from 'react';
import { Switch } from 'react-router-dom';
import { Planning } from './main';
import { DeliveryAndReturnContainerDetails } from 'views/planning/deliver-planning-container-details';
import { HAWBPlanning } from 'views/planning/hawb-planning';
import { HAWBDetails } from 'views/planning/hawb-details';
import { RouteWithPermissions } from 'containers/route-with-permissions';
import { BUSINNESS_FUNCTIONS } from 'constants/roles';
import { POPlanning } from 'views/planning/po-planning/POPlanning';
import { AirPOPlanning } from './po-planning-air/POPlanningAir';

export const PlanningRouting = () => {
    return(
        <Switch>
            <RouteWithPermissions 
                exact={true} 
                path="/planning/" 
                component={Planning} 
                functions={[ BUSINNESS_FUNCTIONS.OCEAN_PLANNING_CONTAINER_LIST ]}
            />
            <RouteWithPermissions 
                exact={true} 
                path="/planning/hawb" 
                component={HAWBPlanning} 
                functions={[ BUSINNESS_FUNCTIONS.AIR_PLANNING_CONTAINER_LIST ]} 
            />
            <RouteWithPermissions 
                exact={true} 
                path="/planning/po" 
                component={POPlanning} 
                functions={[ BUSINNESS_FUNCTIONS.OCEAN_PO_PLANNING_GET_POS ]} 
            />
            <RouteWithPermissions 
                exact={true} 
                path="/planning/po/air" 
                component={AirPOPlanning} 
                functions={[ BUSINNESS_FUNCTIONS.AIR_PO_PLANNING_GET_POS ]} 
            />
            <RouteWithPermissions 
                path="/planning/hawb/:id" 
                component={HAWBDetails} 
                functions={[ BUSINNESS_FUNCTIONS.AIR_PLANNING_CONTAINER_DETAILS ]} 
            />
            <RouteWithPermissions 
                path="/planning/:id" 
                component={DeliveryAndReturnContainerDetails} 
                functions={[ BUSINNESS_FUNCTIONS.OCEAN_PLANNING_CONTAINER_DETAILS ]} 
            />
        </Switch>
    )
}