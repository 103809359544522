import React, { FunctionComponent, useEffect } from 'react';
import { connect } from 'react-redux';
import { Page } from 'components/page-container';
import { PageHeader } from 'components/page-header';
import { PageWithTimeline } from 'components/page-with-timeline';
import { VesselDetailsTopBar } from './components/VesselDetailsTopBar';
import { ThunkDispatch } from 'redux-thunk';
import { Action } from 'redux';
import { fetchVesselDetails } from 'actions/vessels';
import { RouteComponentProps } from 'react-router';
import { IStore } from 'reducers/index';
import { IVessel, IBubble } from 'reducers/vessels/models';
import { UserDetails } from 'components/user-details';
import { CarrierBlRow } from './components/CarrierBlRow';
import { ContainerTrackingTypes } from 'constants/comment';
import { ProgressIndicator } from 'components/progress-indicator';
import { Capability } from 'components/timeline';
import { ErrorDialog } from 'components/error-dialog';
import API from 'constants/api';
import { GenericError } from 'components/error-dialog/GenericError';

interface IParams {
    voyageId: string;
}

interface IDispatch {
    fetchVesselDetails: (voyageId: number) => Promise<void>;
}

interface IMapStateToProps {
    vesselDetails: IVessel;
    bubbles: IBubble[];
}

interface IVesselDetails extends RouteComponentProps<IParams>, IDispatch, IMapStateToProps {
}

const _VesselDetails: FunctionComponent<IVesselDetails> = (props) => {
    const voyageId = parseInt(props.match.params.voyageId, 10);

    useEffect(() => {
        if (!Number.isNaN(voyageId)) {
            props.fetchVesselDetails(voyageId);
        }
    }, []);

    const { bLs, loadingPort, destinationPort, vesselName } = props.vesselDetails;

    if (Number.isNaN(voyageId)) {
        return <GenericError />;
    }

    return (
        <React.Fragment>
            <ErrorDialog endpoint={API.ContainerTracking.GetVesselDetails} />
            <PageHeader backButton={true}>Vessel Name: {vesselName}</PageHeader>
            <ProgressIndicator data={props.bubbles} />
            <VesselDetailsTopBar {...props.vesselDetails} />
            <Page>
                <PageWithTimeline
                    objectId={voyageId}
                    objectType={ContainerTrackingTypes.Voyage}
                    isAir={false}
                    capability={Capability.ContainerTracking}
                >
                    <CarrierBlRow
                        bLs={bLs}
                        loadingPort={loadingPort}
                        destinationPort={destinationPort}
                    />
                    <UserDetails
                        user={props.vesselDetails.lastUserUpdated}
                        date={props.vesselDetails.updateDate}
                    />
                </PageWithTimeline>
            </Page>
        </React.Fragment>
    );
}

const mapStateToProps = (state: IStore): IMapStateToProps => ({
    vesselDetails: state.vessels.vesselDetails,
    bubbles: state.vessels.vesselDetails.bubbleData
});

const mapDispatchToProps = (dispatch: ThunkDispatch<any, any, Action>): IDispatch => ({
    fetchVesselDetails: (voyageId: number) => dispatch(fetchVesselDetails(voyageId, false)),
});

export const VesselDetails = connect<IMapStateToProps, IDispatch, {}, IStore>(mapStateToProps, mapDispatchToProps)(_VesselDetails);
