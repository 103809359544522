import React from 'react';
import { ModalScreen } from 'components/modal-screen';
import { ModalType } from 'components/modal-screen/ModalType';
import { OneStatus } from 'components/status-row/OneStatus';
import { reduxForm, InjectedFormProps } from 'redux-form';
import { FormInput } from 'components/input/FormInput';
import { DoubleGrid } from 'components/grid/double-grid';
import { SingleGrid } from 'components/grid/single-grid';
import { FormDateInput } from 'components/date-input/FormDateInput';
import { ThunkDispatch } from 'redux-thunk';
import { Action, compose } from 'redux';
import { connect, Omit } from 'react-redux';
import { editVesselDetails, fetchVesselDetails, fetchMawbList, resetEditVesselDetailsError, resetSaveMawbDetailsError } from 'actions/vessels';
import { LoadingAnimation } from 'components/loading';
import { IStore } from 'reducers/index';
import { IAddComment, addNewComment } from 'actions/timeline';
import { ContainerTrackingTypes } from 'constants/comment';
import { Capability } from 'components/timeline';
import { ErrorDialog } from 'components/error-dialog';
import API from 'constants/api';

interface IDispatch {
    editVesselDetails: (fields: IFlightDetailsForm) => Promise<void>;
    fetchVesselDetails: (voyageId: number) => Promise<void>;
    fetchMawbList: () => Promise<void>;
    addNewComment: (body: IAddComment) => void;
    resetEditVesselDetailsError: () => void;
    resetSaveMawbDetailsError: () => void;
}

interface IMapStateToProps {
    isLoading: boolean;
}

interface IFlightDetailsPopupOwnProps {
    visible: boolean;
    isDetails?: boolean;
    closeModal: () => void;
    initialValues: Partial<IFlightDetailsForm>;
}

interface IFlightDetailsPopup extends
    IFlightDetailsPopupOwnProps,
    Omit<InjectedFormProps<IFlightDetailsForm, IFlightDetailsPopupOwnProps>, 'initialValues'>,
    IMapStateToProps,
    IDispatch {}

interface IFlightDetailsPopupState {
    comment: string;
}

export interface IFlightDetailsForm {
    VesselName: string;
    ATD: string;
    ATA: string;
    POL: string;
    POD: string;
}

const FORM_NAME: string = 'FLIGHT_DETAILS_POPUP';

class _FlightDetailsPopup extends React.Component<IFlightDetailsPopup, IFlightDetailsPopupState> {
    public state: IFlightDetailsPopupState = {
        comment: ''
    };

    public render() {
        return (
            <React.Fragment>
                {this.props.isLoading && <LoadingAnimation />}
                <form onSubmit={this.props.handleSubmit(this.submit)}>
                    <ModalScreen
                        title="Update flight details"
                        modalType={ModalType.s()}
                        primaryButtonTitle="Save"
                        primaryButtonType="submit"
                        secondaryButtonTitle="Cancel"
                        secondaryButtonFunc={this.props.closeModal}
                        visible={this.props.visible}
                        closeModal={this.props.closeModal}
                    >
                        <section className="page-container">
                            <ErrorDialog endpoint={API.ContainerTracking.EditVessel} />
                            <DoubleGrid>
                                <FormInput
                                    label="Flight number"
                                    name="VesselName"
                                    disabled={true}
                                />
                            </DoubleGrid>
                            <DoubleGrid>
                                <OneStatus label="ATD">
                                    <FormDateInput
                                        name="ATD"
                                        clearInput={() => null}
                                        withTime={true}
                                    />
                                </OneStatus>
                                <OneStatus label="ATA">
                                    <FormDateInput
                                        name="ATA"
                                        clearInput={() => null}
                                        withTime={true}
                                    />
                                </OneStatus>
                            </DoubleGrid>
                            <DoubleGrid>
                                <FormInput
                                    label="POL"
                                    name="POL"
                                    disabled={true}
                                />
                                <FormInput
                                    label="POD"
                                    name="POD"
                                    disabled={true}
                                />
                            </DoubleGrid>
                            <SingleGrid>
                                <OneStatus label="Comment">
                                    <textarea
                                        className="form-control"
                                        onChange={(e: any) => this.setState({ comment: e.target.value })}
                                    />
                                </OneStatus>
                            </SingleGrid>
                        </section>
                    </ModalScreen>
                </form>
            </React.Fragment>
        );
    }

    private submit = async (fields: any) => {
        try {
            await this.props.editVesselDetails(fields);
            await this.props.closeModal();

            if(this.state.comment.length > 0) {
                await this.props.addNewComment({
                    objectId: fields.VoyageId,
                    objectType: ContainerTrackingTypes.Voyage,
                    content: this.state.comment
                });
            }
            if(this.props.isDetails) {
                await this.props.fetchVesselDetails(fields.VoyageId);
            } else {
                await this.props.fetchMawbList();
            }
        } catch (error) {
            console.error(error);
        }
    };
}

const mapStateToProps = (state: IStore): IMapStateToProps => ({
    isLoading: state.vessels.isLoading
});

const mapDispatchToProps = (dispatch: ThunkDispatch<any, any, Action>): IDispatch => ({
    editVesselDetails: (data: any) => dispatch(editVesselDetails(data, true, undefined, undefined)),
    fetchVesselDetails: (voyageId: number) => dispatch(fetchVesselDetails(voyageId, true)),
    fetchMawbList: () => dispatch(fetchMawbList()),
    addNewComment: (body: IAddComment) => dispatch(addNewComment(body, true, Capability.ContainerTracking)),
    resetEditVesselDetailsError: () => dispatch(resetEditVesselDetailsError()),
    resetSaveMawbDetailsError: () => dispatch(resetSaveMawbDetailsError())
});

export const FlightDetailsPopup = compose(
    connect<IMapStateToProps, IDispatch, IFlightDetailsPopupOwnProps, IStore>(mapStateToProps, mapDispatchToProps),
    reduxForm<IFlightDetailsForm, IFlightDetailsPopupOwnProps>({
        form: FORM_NAME,
    }),
)(_FlightDetailsPopup);
