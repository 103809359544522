import React from 'react';
import { ProgressIndicatorStepIcon } from '../icon';
import { formatDate, formatValueIfNullOrUndefined } from 'utilities/util';

interface IProgressIndicatorStep {
    isActive: boolean;
    isDone: boolean;
    isError?: boolean;
    status: string;
    line1?: string;
    line2?: string;
    colSize: string;
    isLink?: boolean;
    linkAddress?: string;
}

export const ProgressIndicatorStep = (props: IProgressIndicatorStep) => {
    const lightGray = (!props.isDone && !props.isActive) ? 'light-gray-color' : '';
    const bold = props.isActive ? 'bold' : '';
    const status = (props.isLink && props.linkAddress) ? <a href={props.linkAddress} className={`large-link-text ${lightGray} ${bold}`}>{props.status}</a> : <p className={`large-body-text ${lightGray} ${bold}`}>{props.status}</p>;

    return(
        <li className={`step-indicator__step ${props.colSize}`}>
            <ProgressIndicatorStepIcon
                isActive={props.isActive}
                isDone={props.isDone}
                isError={props.isError || false}
            />
            <div className="step-indicator__step--details">
                {status}
                {props.line1 && <span className={`small-body-text ${lightGray}`}>{formatDate(props.line1)}</span>}
                {props.line2 && <span className={`small-body-text ${lightGray}`}>{formatValueIfNullOrUndefined(props.line2)}</span>}
            </div>
        </li>
    );
}