import * as React from 'react';

export enum ButtonType {
    Blue = 'button button-blue',
    Red = 'button button-red',
    Transparent = 'button button-transparent',
    Contextual = 'button button-context',
    Close = 'close',
}

interface IButtonProps{
    type?: 'submit' | 'reset' | 'button';
    id?: string;
    buttonType: ButtonType;
    isDisabled?: boolean;
    isActive?: boolean;
    children: React.ReactNode;
    style?: React.CSSProperties;
    className?: string;
    onClick?: (e: any) => any;
}

export const Button: React.SFC<IButtonProps> = (props: IButtonProps) => {
    const activeClassName = props.isActive ? 'active': '';
    return (
        <button
            id={props.id}
            style={props.style}
            type={props.type || 'button'}
            disabled={props.isDisabled}
            className={`${props.buttonType} ${activeClassName} ${props.className ? props.className : ''}`}
            onClick={props.onClick}
        >
            {props.children}
        </button>
    );
};
