import React from 'react';
import { ModalScreen } from 'components/modal-screen';
import { ModalType } from 'components/modal-screen/ModalType';
import { FormInput } from 'components/input/FormInput';
import { InjectedFormProps, reduxForm, reset } from 'redux-form';
import { Dispatch } from 'redux';
import { DoubleGrid } from 'components/grid/double-grid';
import { IReasonCode, IReasonCodeToUpdate } from 'reducers/settings/models';
import { FormSelect } from 'components/select/FormSelect';
import { reasonCommentModeOptions, sendEventOptions } from 'constants/reasonCodes';
import { Dialog } from 'components/dialog';
import { required } from 'utilities/validate';
import { constOptions } from './ConstantReasonCodes';

interface IEditReasonCodeModalOwnProps {
    title: string;
    visible: boolean;
    reasonCodes: any[];
    hasCommentMode: boolean;
    error: string;
    sendEventEnabled?: boolean;
    resetError: () => void;
    closeModal: () => void;
    saveFunction: (configuration: any) => void;
}

interface IEditReasonCodeModal extends IEditReasonCodeModalOwnProps, InjectedFormProps<IReasonCodeToUpdate, IEditReasonCodeModalOwnProps> {}

const FORM_NAME = 'EDIT_REASON_CODE_MODAL';

const _EditReasonCodeModal = (props: IEditReasonCodeModal) => {
    const groups: string[] = props.reasonCodes
        .map((reasonCode: IReasonCode) => reasonCode.group)
        .filter(group => group !== 'Ocean_LateShipment' && group !== 'Air_LateShipment' && group !== null);

    const mappedOptions = [...new Set(groups)]
        .map(group => ({ optionText: group, value: group }))
        .concat(...constOptions);

    return (
        <form onSubmit={props.handleSubmit(props.saveFunction)}>
            <ModalScreen
                title={props.title}
                visible={props.visible}
                modalType={ModalType.s()}
                closeModal={props.closeModal}
                primaryButtonTitle="Save"
                primaryButtonFunc={() => null}
                primaryButtonType="submit"
                secondaryButtonTitle="Cancel"
                secondaryButtonFunc={props.closeModal}
            >
                {props.error && props.resetError && <Dialog
                    message={props.error}
                    dialogType={500}
                    isVisible={!!props.error}
                    closeDialog={props.resetError}
                />}
                <DoubleGrid>
                    <FormInput
                        label="Reason code"
                        name="ReasonCode"
                        validate={[required]}
                    />
                    <FormSelect
                        label="Group"
                        name="Group"
                        options={[
                            {optionText: 'Select value...', value: null},
                            ...mappedOptions
                        ]}
                        validate={[required]}
                    />
                </DoubleGrid>
                <DoubleGrid>
                    {props.hasCommentMode && <FormSelect
                        label="Comment mode"
                        name="commentMode"
                        options={reasonCommentModeOptions}
                    />}
                    {props.sendEventEnabled && <FormSelect 
                        label="Send event"
                        name="sendEvent"
                        options={sendEventOptions}
                    />}
                </DoubleGrid>
            </ModalScreen>
        </form>
    );
};

const afterSubmit = (_: any, dispatch: Dispatch) => dispatch(reset(FORM_NAME));

export const EditReasonCodeModal = reduxForm<IReasonCodeToUpdate, IEditReasonCodeModalOwnProps>({
    form: FORM_NAME,
    onSubmitSuccess: afterSubmit,
    enableReinitialize: true
})(_EditReasonCodeModal);
