import React, { Component } from 'react';
import { authentication } from 'containers/authorization/authentication';

import {
    DropdowContainer,
    DropdownUserArea,
    UserImage,
    UserDesc,
    Text,
    ButtonsArea
} from './DropdownStyles';
import { Button, ButtonType } from 'components/button';
import { Button as MenuButton } from './../UserAreaStyles';
import { Link } from 'react-router-dom';

interface IDropdownState {
    isShown: boolean;
}

interface IDropdownProps {
    user: string;
    company: string;
    email: string;
}

class Dropdown extends Component<IDropdownProps, IDropdownState> {
    public state = {
        isShown: false
    }

    public render() {
        return (
            <React.Fragment>
                <MenuButton
                    onClick={this.showDropdown}
                    style={
                        this.state.isShown ? {
                            background: '#FFF',
                            borderRight: '1px solid #cfd2d6',
                            borderLeft: '1px solid #cfd2d6',
                            borderBorrom: '0'
                        } : null
                    }
                >
                    <i className="fa fa-user-circle" aria-hidden="true" />
                </MenuButton>
                {
                    this.state.isShown ?
                        (
                            <DropdowContainer>
                                <DropdownUserArea>
                                    <UserImage>
                                        <i className="fa fa-user-circle" aria-hidden="true" />
                                    </UserImage>
                                    <UserDesc>
                                        <Text className="regular-body-text">{this.props.user}</Text>
                                        {this.props.email && <Text className="regular-body-text">{this.props.email}</Text>}
                                        <Text className="regular-body-text">{this.props.company}</Text>
                                    </UserDesc>
                                </DropdownUserArea>
                                <ButtonsArea>
                                    <Button buttonType={ButtonType.Transparent}>
                                        <Link to="/changelog">Changelog</Link>
                                    </Button>
                                    <Button
                                        buttonType={ButtonType.Transparent}
                                        onClick={this.onLogout}
                                    >
                                        Logout
                                </Button>
                                </ButtonsArea>
                            </DropdowContainer>
                        ) : null
                }
            </React.Fragment>
        );
    }

    private showDropdown = () => {
        this.setState({
            isShown: true
        }, () => {
            document.addEventListener('click', this.closeDropdown);
        });
    }

    private closeDropdown = () => {
        this.setState({
            isShown: false
        }, () => {
            document.removeEventListener('click', this.closeDropdown);
        });
    }

    private onLogout = () => {
        authentication.signOut();
    }
};

export default Dropdown;
