import React, { useState } from 'react';
import { IContainer } from 'reducers/customs/models';
import {formatDate, formatValueIfNullOrUndefined, getArrayOfButtons} from 'utilities/util';
import { PropertiesRibbonWrapper } from 'components/properties-ribbon';
import { mapStatusToStringForCustoms, mapStatusToStringForCT } from 'constants/statuses';
import { UpdateClearanceForCustomsRelease } from 'modules/customs/ocean/components/UpdateClearanceForCustomsRelease';
import { BUSINNESS_FUNCTIONS } from 'constants/roles';
// tslint:disable-next-line: no-submodule-imports
import { withPermissions, IWithPermissions } from 'src/HOC/withPermissions';

interface ITopBarProps extends IWithPermissions, IContainer {
    equipId: number[];
    voyageId: number;
    refreshFunction: any;
    data: any;
}

const _CustomsTrackingContainerDetailsTopBar: React.FunctionComponent<ITopBarProps> = (props) => {
    const [isUpdateClearanceForCustomsReleaseOpened, setUpdateClearanceForCustomsReleaseOpened] = useState<boolean>(false);

    const {
        vesselName,
        voyageReference,
        pol,
        pod,
        currentETA,
        ata,
        landedDate,
        voyageStatus,
        numberOfHbls,
    } = props;
    const { customsStatus } = props.equipment

    const toggleUpdateClearanceForCustomsReleaseModal = () => setUpdateClearanceForCustomsReleaseOpened(!isUpdateClearanceForCustomsReleaseOpened);

    const propertiesRibbonData = {
        labels: [
            {label: 'Vessel name', value: formatValueIfNullOrUndefined(vesselName)},
            {label: 'Voyage', value: formatValueIfNullOrUndefined(voyageReference)},
            {label: 'POL', value: formatValueIfNullOrUndefined(pol)},
            {label: 'POD', value: formatValueIfNullOrUndefined(pod)},
            {label: 'Current ETA', value: formatDate(currentETA)},
            {label: 'ATA', value: formatDate(ata)},
            {label: 'Container landed date', value: formatDate(landedDate)},
            {label: 'Vessel status', value: formatValueIfNullOrUndefined(mapStatusToStringForCT(voyageStatus))},
            {label: 'No. of HBLs', value: formatValueIfNullOrUndefined(numberOfHbls)},
            {label: 'Customs status', value: formatValueIfNullOrUndefined(mapStatusToStringForCustoms(customsStatus))}
        ],
        actions: getArrayOfButtons(props.permissions, [{label: 'Update clearance data', action: toggleUpdateClearanceForCustomsReleaseModal }])
    };

    const data = [{
        CL1_Type: props.data.cL1_Type,
        CL1_Broker: props.data.cL1_Broker,
        CL1_CustomsClearedDate: props.data.cL1_StatusDate,
        CL1_Comments: props.data.cL1_Comments,
        CL1_Status: props.data.cL1_Status,
        EIDR: props.data.eidr,
        SFD:props.data.sfd,
        UCR:props.data.ucr
    }];

    return(
        <React.Fragment>
            <PropertiesRibbonWrapper content={propertiesRibbonData} />
            {isUpdateClearanceForCustomsReleaseOpened && <UpdateClearanceForCustomsRelease
                visible={isUpdateClearanceForCustomsReleaseOpened}
                closeModal={toggleUpdateClearanceForCustomsReleaseModal}
                equipId={props.equipId}
                voyageId={[props.voyageId]}
                data={data}
                refreshFunction={() => props.refreshFunction(props.equipId[0])}
            />}
        </React.Fragment>
    );
}

const ROLES: BUSINNESS_FUNCTIONS[] = [BUSINNESS_FUNCTIONS.OCEAN_CUSTOMS_UPDATE_HBL_CUSTOMS_DETAILS];

export const CustomsTrackingContainerDetailsTopBar = withPermissions(_CustomsTrackingContainerDetailsTopBar, ROLES) as any;