import React from 'react';
import { Switch } from 'react-router-dom';
import { DeliveryAndReturnEquipmentList } from 'views/delivery-and-return/equipment';
import { DeliveryAndReturnLocationList } from 'views/delivery-and-return/location';
import { DeliveryAndReturnEquipmentDetails } from 'views/delivery-and-return/equipment-details';
import { AirDelivery } from 'views/delivery-and-return/air-delivery';
import { AirDeliveryDetails } from 'views/delivery-and-return/air-delivery-details';
import { RouteWithPermissions } from 'containers/route-with-permissions';
import { BUSINNESS_FUNCTIONS } from 'constants/roles';

export const DeliveryAndReturnRouting = () => {
    return (
        <Switch>
            <RouteWithPermissions 
                exact={true} 
                path="/delivery-and-return/equipment" 
                component={DeliveryAndReturnEquipmentList} 
                functions={[ BUSINNESS_FUNCTIONS.OCEAN_DELIVERY_EQUIPMENT_LIST ]} 
            />
            <RouteWithPermissions 
                exact={true} 
                path="/delivery-and-return/location" 
                component={DeliveryAndReturnLocationList} 
                functions={[ BUSINNESS_FUNCTIONS.OCEAN_DELIVERY_LOCATION_LIST ]} 
            />
            <RouteWithPermissions 
                exact={true} 
                path="/delivery-and-return/air" 
                component={AirDelivery} 
                functions={[ BUSINNESS_FUNCTIONS.AIR_DELIVERY_EQUIPMENT_LIST ]} 
            />
            <RouteWithPermissions 
                exact={true} 
                path="/delivery-and-return/equipment/:id" 
                component={DeliveryAndReturnEquipmentDetails} 
                functions={[ BUSINNESS_FUNCTIONS.OCEAN_DELIVERY_EQUIPMENT_DETAILS ]} 
            />
            <RouteWithPermissions 
                exact={true} 
                path="/delivery-and-return/air/:id" 
                component={AirDeliveryDetails} 
                functions={[ BUSINNESS_FUNCTIONS.AIR_DELIVERY_EQUIPMENT_DETAILS ]} 
            />
        </Switch>
    );
};
