import { IOption } from 'components/select';
import { DPReasonCodesGroups, CTReasonCodes } from 'constants/reasonCodes';

export const constOptions: IOption[] = [
    {optionText: 'Ocean - LateShipment', value: CTReasonCodes.Ocean_LateShipment},
    {optionText: 'Air - LateShipment', value: CTReasonCodes.Air_LateShipment},
];

export const constOptionsDP: IOption[] = [
    {optionText: 'Ocean - LateShipment', value: DPReasonCodesGroups.Ocean_LateShipment},
    {optionText: 'Air - LateShipment', value: DPReasonCodesGroups.Air_LateShipment},
    {optionText: 'Ocean - FailedDelivery', value: DPReasonCodesGroups.Ocean_FailedDelivery},
    {optionText: 'Air - FailedDelivery', value: DPReasonCodesGroups.Air_FailedDelivery},
];