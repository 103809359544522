import React from 'react';
import { StatusRow } from 'components/status-row';
import { EquipmentTable } from './EquipmentTable';

interface IEquipmentRow {
    data: any[];
}

export const EquipmentRow = (props: IEquipmentRow) => (
    <StatusRow title="Equipment">
        <EquipmentTable data={props.data} />
    </StatusRow>
);
