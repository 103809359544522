import React from 'react';
import { OneStatus } from 'components/status-row/OneStatus';
import { StatusRow } from 'components/status-row';
import { QuarterGrid } from 'components/grid/quarter-grid';
import { formatValueIfNullOrUndefined } from 'utilities/util';

interface ILCLRequirementsRow {
    hblNumbers: string[];
    bookingReference: string;
    deconsolidationLocation: string;
    grossWeight: number;
    palletsCount: number;
    totalPackages: number;
    volume: number;
}

export const LCLRequirementsRow: React.SFC<ILCLRequirementsRow> = (props: ILCLRequirementsRow) => (
    <StatusRow title="LCL Requirements">
        <QuarterGrid>
            <OneStatus label="HBL number">
                {formatValueIfNullOrUndefined(props.hblNumbers && props.hblNumbers[0])}
            </OneStatus>
            <OneStatus label="Booking reference field">
                {formatValueIfNullOrUndefined(props.bookingReference)}
            </OneStatus>
            <OneStatus label=" Deconsolidation location">
                {formatValueIfNullOrUndefined(props.deconsolidationLocation)}
            </OneStatus>
            <OneStatus label="Pallet count">
                {formatValueIfNullOrUndefined(props.palletsCount)}
            </OneStatus>
        </QuarterGrid>
        <QuarterGrid>
            <OneStatus label="Packages">
                {formatValueIfNullOrUndefined(props.totalPackages)}
            </OneStatus>
            <OneStatus label="Weight">
                {formatValueIfNullOrUndefined(props.grossWeight)}
            </OneStatus>
            <OneStatus label="Volume">
                {formatValueIfNullOrUndefined(props.volume)}
            </OneStatus>
        </QuarterGrid>
    </StatusRow>
);
