import React, { Component } from 'react';
import { ModalScreen } from 'components/modal-screen';
import { ModalType } from 'components/modal-screen/ModalType';
import { Accordion } from 'components/accordion';
import { UpdateHBLCustomsClearanceForm, FORM_NAME } from 'modules/customs/ocean/components/UpdateHBLCustomsClearanceForm';
import { LoadingAnimation } from 'components/loading';
import { connect } from 'react-redux';
import { IStore } from 'reducers/index';
import { submit } from 'redux-form';
import { IHolds, IHawbDetails } from 'reducers/customs/models';
import { Holds } from 'modules/customs/air/containers/Holds';
import { ThunkDispatch } from 'redux-thunk';
import { Action } from 'redux';
import { RouteComponentProps, withRouter } from 'react-router';
import { updateHBLDetails, resetUpdateHBLDetailsError, fetchHawbDetailsOnList } from 'actions/customs';
import { Dialog } from 'components/dialog';

interface IMapStateToProps {
    hawbDetails: IHawbDetails;
    isUpdateHBLDetailsLoading: boolean;
    updateHBLDetailsError: string;
}

interface IMapDispatchToProps {
    updateHblDetails: (body: any) => void;
    fetchHawbDetails: (id: string, voyageId: string) => void;
    submit: (formName: string) => void;
    resetUpdateHBLDetailsError: () => void;
}

interface IUpdateHBLCustomsClearanceModal {
    visible: boolean;
    hblId: number[];
    voyageId: number[];
    data: any;
    isDetails?: boolean;
    closeModal: () => void;
}

interface IUpdateHawbModalState {
    holds: IHolds[];
}

interface IRouteProps {
    id: string;
    voyageId: string;
}

type TUpdateHawbModal = IUpdateHBLCustomsClearanceModal & IMapStateToProps & IMapDispatchToProps & RouteComponentProps<IRouteProps>

class _UpdateHawbModal extends Component<TUpdateHawbModal, IUpdateHawbModalState> {
    public constructor(props: TUpdateHawbModal) {
        super(props);

        this.state = {
            holds: this.props.isDetails ? this.props.hawbDetails.holds : this.props.data[0].holds,
        };
    }

    public render() {
        return(
            <ModalScreen
                title={`Update clearance details: ${this.props.hawbDetails.FlightNumber}`}
                modalType={ModalType.xl()}
                visible={this.props.visible}
                closeModal={this.props.closeModal}
                secondaryButtonFunc={this.props.closeModal}
                primaryButtonTitle="Save"
                primaryButtonType="submit"
                primaryButtonFunc={() => this.props.submit(FORM_NAME)}
                secondaryButtonTitle="Cancel"
            >
                <div>
                    { this.props.isUpdateHBLDetailsLoading && <LoadingAnimation /> }
                    <Dialog
                        message={this.props.updateHBLDetailsError}
                        dialogType={500}
                        isVisible={!!this.props.updateHBLDetailsError}
                        closeDialog={this.props.resetUpdateHBLDetailsError}
                    />
                    <Accordion text="Clearance">
                        <UpdateHBLCustomsClearanceForm
                            onSubmit={this.saveHBLDetails}
                            initialValues={this.props.isDetails ? this.getInitialValuesForClearance() : this.getInitialDataForClearanceWhenMultiple()}
                            isAir={true}
                        />
                    </Accordion>
                    {this.props.hblId.length <= 1 && <Accordion text="Holds">
                        <Holds
                            holds={this.state.holds}
                            addHolds={this.addHolds}
                            editHolds={this.editHold}
                            removeHolds={this.removeHolds}
                            dataForDropdown={this.getDataForEquipNumberDropdown()}
                        />
                    </Accordion>}
                </div>
            </ModalScreen>
        );
    }

    private saveHBLDetails = async (fields: any) => {
        const { id, voyageId } = this.props.match.params;
        const isDetailsPage = !!(id && voyageId);

        const delayIncrement = 500;
        let delay = 0;

        await Promise.all(this.props.hblId.map(async (_: any, index: number) => {
            const holds = this.props.hblId.length === 1 ? this.state.holds : this.props.data[index].holds;
            const properties = this.props;

            setTimeout((prop) => {
                prop.updateHblDetails({
                    hblId: prop.hblId[index],
                    CL2_Type: fields.clearanceType2,
                    CL2_Broker: fields.Broker_2,
                    CL2_CustomsClearedDate: fields.CustomsClearedDate,
                    CL2_Comments: fields.Comments,
                    CL2_Status: fields.Status_2,
                    holds,
                    VoyageId: prop.voyageId[index]
                })
            }, delay, properties);

            delay += delayIncrement;
        }));

        await this.props.closeModal();
        if (isDetailsPage) {
            await this.props.fetchHawbDetails(id, voyageId);
        }
    }

    private getInitialValuesForClearance = () => {
        const { hawbDetails } = this.props;
        return {
            clearanceType2: hawbDetails.cL2_Type,
            Broker_2: hawbDetails.cL2_Broker ? hawbDetails.cL2_Broker.brokerId : null,
            CustomsClearedDate: hawbDetails.cL2_CustomsClearedDate,
            Comments: hawbDetails.cL2_Comments,
            Status_2: hawbDetails.cL2_Status,
            VoyageId: hawbDetails.voyageId
        }
    };

    private addHolds = (data: any) => {
        const updatedData: IHolds = data;
        const isNew = this.state.holds.every((obj: IHolds) => obj.holdId !== updatedData.holdId);
        if (isNew) {
            this.setState((state: IUpdateHawbModalState) => ({
                holds: [
                    ...state.holds,
                    updatedData
                ]
            }));
        }
    }

    private editHold = (data: any) => {
        const updatedData: IHolds = data;

        const index = this.state.holds.findIndex((obj: IHolds) => obj.holdId === updatedData.holdId);
        const items = [...this.state.holds];
        items[index] = updatedData;

        this.setState({
            holds: items
        });
    }

    private removeHolds = (id: any) => {
        const filteredData = this.state.holds.filter((obj: IHolds) => obj.holdId !== id);
        this.setState({ holds: filteredData });
    }

    private getDataForEquipNumberDropdown = () => {
        if (Array.isArray(this.props.data)) {
            return this.props.data.map((hbl) => {
                return hbl.equipments.map((equip: any) => ({
                    value: equip.HAWBNumber,
                    optionText: equip.HAWBNumber
                }))
            });
        } else {
            return this.props.data.equipments
                ? this.props.data.equipments.map((equip: any) => ({
                    value: equip.HAWBNumber,
                    optionText: equip.HAWBNumber
                }))
                : [];
        }
    };

    private getInitialDataForClearanceWhenMultiple = () => ({
        clearanceType2: this.props.data[0].cL2_Type,
        Broker_2: this.props.data[0].cL2_Broker ? this.props.data[0].cL2_Broker.brokerId : null,
        CustomsClearedDate: this.props.data[0].cL2_CustomsClearedDate,
        Comments: this.props.data[0].cL2_Comments,
        Status_2: this.props.data[0].cL2_Status,
        VoyageId: this.props.data[0].voyageId,
        EIDR: this.props.data[0].EIDR,
        UCR: this.props.data[0].UCR,
        SFD: this.props.data[0].SFD
    });
}

const mapStateToProps = (state: IStore): IMapStateToProps => {
    const { hawbDetails, isUpdateHBLDetailsLoading, updateHBLDetailsError } = state.customs;
    return {
        hawbDetails,
        isUpdateHBLDetailsLoading,
        updateHBLDetailsError,
    };
};

const mapDispatchToProps = (dispatch: ThunkDispatch<any, any, Action>): IMapDispatchToProps => ({
    fetchHawbDetails: (id: string, voyageId: string) => dispatch(fetchHawbDetailsOnList(id, voyageId)),
    updateHblDetails: (body: any) => dispatch(updateHBLDetails(body)),
    submit: (formName: string) => dispatch(submit(formName)),
    resetUpdateHBLDetailsError: () => dispatch(resetUpdateHBLDetailsError()),
});

export const UpdateHawbModal =
    connect<IMapStateToProps, IMapDispatchToProps, IUpdateHBLCustomsClearanceModal, IStore>(mapStateToProps, mapDispatchToProps)
(withRouter<RouteComponentProps<IRouteProps>>(_UpdateHawbModal));
