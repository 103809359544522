import { BUSINNESS_FUNCTIONS } from 'constants/roles';

export interface IRoles {
    id: number;
    name?: string;
    Name?: string;
    description: string;
    apiName?: string;
    transportMode?: string;
}

export const isAdminUser = (userRoles: BUSINNESS_FUNCTIONS[]): boolean => userRoles.includes(BUSINNESS_FUNCTIONS.ADMINISTRATOR);

export const hasRequiredRole = (requiredRoles: BUSINNESS_FUNCTIONS[], userRoles: IRoles[]): boolean => {
    return requiredRoles.reduce((prev: boolean, role: BUSINNESS_FUNCTIONS) => {
        return userRoles && userRoles.length !== 0 ? prev || !!userRoles.find((userRole: IRoles) => userRole.name === role || userRole.Name === role) : false;
    }, false)
};