import React from 'react';
import { formatValueIfNullOrUndefined } from 'utilities/util';
import { PropertiesRibbonWrapper } from 'components/properties-ribbon';

interface IShipmentDetailsTopBarOwnProps {
    documentNumber: string;
    cargoType: string;
    prepaidCollectIndicator: string;
    reconsolidationPoint: string;
}

export const ShipmentDetailsTopBar: React.FunctionComponent<IShipmentDetailsTopBarOwnProps> = (props) => {
    const propertiesRibbonData = {
        labels: [
            {label: 'Document number', value: formatValueIfNullOrUndefined(props.documentNumber)},
            {label: 'Cargo type', value: formatValueIfNullOrUndefined(props.cargoType)},
            {label: 'Prepaid collect indicator', value: formatValueIfNullOrUndefined(props.prepaidCollectIndicator)},
            {label: 'Reconsolidation point', value: formatValueIfNullOrUndefined(props.reconsolidationPoint)}
        ],
        actions: []
    }

    return <PropertiesRibbonWrapper content={propertiesRibbonData} />;
};
