import React from 'react';
import { QuarterGrid } from 'components/grid/quarter-grid';
import { OneStatus } from 'components/status-row/OneStatus';
import { StatusRow } from 'components/status-row';
import { formatValueIfNullOrUndefined } from 'utilities/util';

interface ICustomerReferencesRow {
    beCode?: string;
    consigneeName?: string;
    clientReference?: string;
    outOfHoursContact?: string;
    referencesShift?: string;
    hblNumbers?: string[];
}

export const CustomerReferencesRow: React.SFC<ICustomerReferencesRow> = (props: ICustomerReferencesRow) => (
    <StatusRow title="Customer references">
        <QuarterGrid>
            <OneStatus label="Consignee name">
                {formatValueIfNullOrUndefined(props.consigneeName)}
            </OneStatus>
            <OneStatus label="BE code">
                {formatValueIfNullOrUndefined(props.beCode)}
            </OneStatus>
            <OneStatus label="Client reference">
                {formatValueIfNullOrUndefined(props.clientReference)}
            </OneStatus>
            <OneStatus label="Out of hours contant">
                {formatValueIfNullOrUndefined(props.outOfHoursContact)}
            </OneStatus>
        </QuarterGrid>
        <QuarterGrid>
            <OneStatus label="Shift">
                {formatValueIfNullOrUndefined(props.referencesShift)}
            </OneStatus>
            <OneStatus label="HBL/FCR">
                {formatValueIfNullOrUndefined(props.hblNumbers && props.hblNumbers[0])}
            </OneStatus>
        </QuarterGrid>
    </StatusRow>
);
