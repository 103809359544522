import { callApiPut } from './../utilities/apiCaller';
import { applyFilters, generateErrors } from 'utilities/util';
import { IStore } from 'reducers/index';
import { ThunkDispatch } from 'redux-thunk';
import { Action } from 'redux';
import { TYPES } from 'action-types/fact';
import { TYPES as FILTER_TYPES} from 'action-types/filters';
import { callApiPost } from 'utilities/apiCaller';
import API from 'constants/api';
import { IASNMessage } from 'reducers/fact/models';

export const fetchFactData = (pageNumber?: number, pageSize?: number) => {
    return async (dispatch: ThunkDispatch<any, any, Action>, getState: () => IStore) => {
        dispatch({ type: TYPES.FETCH_FACT_DATA.PENDING });
        try {
            const res = await callApiPost(API.Fact.GetData, applyFilters(getState, {
                isAirInFilters: false,
                isAirInBody: false
            }, {
                Page: pageNumber,
                PageNumber: pageNumber,
                PageSize: pageSize
            }), {headers: {TransportMode: 'Ocean'}});
            dispatch({ type: FILTER_TYPES.SET_TOTAL_COUNT, totalCount: res.data.totalCount });
            dispatch({ type: TYPES.FETCH_FACT_DATA.FULFILLED, documents: res.data });
        } catch (e) {
            const errors = generateErrors(e);
            dispatch({ type: TYPES.FETCH_FACT_DATA.REJECTED, errors });
        }
    }
};

export const fetchASNMessages = (documentId: number) => {
    return async (dispatch: ThunkDispatch<any, any, Action>) => {
        dispatch({ type: TYPES.FETCH_ASN_MESSAGES.PENDING });
        try {
            const res = await callApiPost(API.Fact.GetASNMessages, { documentId }, {headers: {TransportMode: 'Ocean'}});
            dispatch({ type: TYPES.FETCH_ASN_MESSAGES.FULFILLED, ASNMessages: res.data as IASNMessage[] });
        } catch (e) {
            const errors = generateErrors(e);
            dispatch({ type: TYPES.FETCH_ASN_MESSAGES.REJECTED, errors });
        }
    }
};

export const fetchDocumentDetails = (documentId: number) => {
    return async (dispatch: ThunkDispatch<any, any, Action>) => {
        dispatch({ type: TYPES.FETCH_DOCUMENT_DETAILS.PENDING });
        try {
            const res = await callApiPost(API.Fact.GetDocumentDetails, { documentId }, {headers: {TransportMode: 'Ocean'}});
            dispatch({ type: TYPES.FETCH_DOCUMENT_DETAILS.FULFILLED, documentDetails: res.data });
        } catch (e) {
            const errors = generateErrors(e);
            dispatch({ type: TYPES.FETCH_DOCUMENT_DETAILS.REJECTED, errors });
        }
    }
};

export const fetchEquipmentDetails = (equipmentId: number) => {
    return async (dispatch: ThunkDispatch<any, any, Action>) => {
        dispatch({ type: TYPES.FETCH_EQUIPMENT_DETAILS.PENDING });
        try {
            const res = await callApiPost(API.Fact.GetEquipmentDetails, { equipmentId }, {headers: {TransportMode: 'Ocean'}});
            dispatch({ type: TYPES.FETCH_EQUIPMENT_DETAILS.FULFILLED, equipmentDetails: res.data });
        } catch (e) {
            const errors = generateErrors(e);
            dispatch({ type: TYPES.FETCH_EQUIPMENT_DETAILS.REJECTED, errors });
        }
    }
};

export const fetchEquipmentTransportShippmentDetails = (equipmentTransportShipmentId: number) => {
    return async (dispatch: ThunkDispatch<any, any, Action>) => {
        dispatch({ type: TYPES.FETCH_EQUIPMENT_TRANSPORT_SHIPPMENT_DETAILS.PENDING });
        try {
            const res = await callApiPost(API.Fact.GetEquipmentDeliveryShippmentDetails, { equipmentTransportShipmentId }, {headers: {TransportMode: 'Ocean'}});
            dispatch({ type: TYPES.FETCH_EQUIPMENT_TRANSPORT_SHIPPMENT_DETAILS.FULFILLED, etsDetails: res.data });
        } catch (e) {
            const errors = generateErrors(e);
            dispatch({ type: TYPES.FETCH_EQUIPMENT_TRANSPORT_SHIPPMENT_DETAILS.REJECTED, errors });
        }
    }
};

export const fetchCblDetails = (cblId: number) => {
    return async (dispatch: ThunkDispatch<any, any, Action>) => {
        dispatch({ type: TYPES.FETCH_CBL_DETAILS.PENDING });
        try {
            const res = await callApiPost(API.Fact.GetCblDetails, { cblId }, {headers: {TransportMode: 'Ocean'}});
            dispatch({ type: TYPES.FETCH_CBL_DETAILS.FULFILLED, cblDetails: res.data });
        } catch (e) {
            const errors = generateErrors(e);
            dispatch({ type: TYPES.FETCH_CBL_DETAILS.REJECTED, errors });
        }
    }
};

export const fetchEquipmentsList = (pageNumber?: number, pageSize?: number) => {
    return async (dispatch: ThunkDispatch<any, any, Action>, getState: () => IStore) => {
        dispatch({ type: TYPES.FETCH_EQUIPMENTS_LIST.PENDING });
        try {
            const res = await callApiPost(API.Fact.GetEquipmentsList, applyFilters(getState, {
                isAirInFilters: false,
                isAirInBody: false
            }, {
                Page: pageNumber,
                PageNumber: pageNumber,
                PageSize: pageSize
            }), {headers: {TransportMode: 'Ocean'}});
            dispatch({ type: TYPES.FETCH_EQUIPMENTS_LIST.FULFILLED, equipmentList: res.data });
        } catch (e) {
            const errors = generateErrors(e);
            dispatch({ type: TYPES.FETCH_EQUIPMENTS_LIST.REJECTED, errors });
        }
    }
};

export const exportFactFile = (body: {id: number, documentType: string}) => {
    return async (dispatch: ThunkDispatch<any, any, Action>) => {
        dispatch({ type: TYPES.GET_FILE.PENDING });
        try {
            const res = await callApiPost(API.Fact.GetFile, {...body}, {
                responseType: 'blob',
                headers: 'Ocean'
            });
            dispatch({ type: TYPES.GET_FILE.FULFILLED, res });
            return res.data;
        } catch (e) {
            const errors = generateErrors(e);
            dispatch({ type: TYPES.GET_FILE.REJECTED, errors });
            throw e;
        }
    }
};

export const fetchEssoList = (pageNumber?: number, pageSize?: number) => {
    return async (dispatch: ThunkDispatch<any, any, Action>, getState: () => IStore) => {
        dispatch({ type: TYPES.FETCH_ESSO_LIST.PENDING });
        try {
            const res = await callApiPost(API.Fact.GetESSOList, applyFilters(getState, {
                isAirInFilters: false,
                isAirInBody: false
            }, {
                Page: pageNumber,
                PageNumber: pageNumber,
                PageSize: pageSize
            }), {headers: {TransportMode: 'Ocean'}});
            dispatch({ type: TYPES.FETCH_ESSO_LIST.FULFILLED, essoList: res.data });
        } catch (e) {
            const errors = generateErrors(e);
            dispatch({ type: TYPES.FETCH_ESSO_LIST.REJECTED, errors });
        }
    }
};

export const fetchIssoList = (pageNumber?: number, pageSize?: number) => {
    return async (dispatch: ThunkDispatch<any, any, Action>, getState: () => IStore) => {
        dispatch({ type: TYPES.FETCH_ISSO_LIST.PENDING });
        try {
            const res = await callApiPost(API.Fact.GetISSOList, applyFilters(getState, {}, {
                Page: pageNumber,
                PageNumber: pageNumber,
                PageSize: pageSize
            }), {headers: {TransportMode: 'Ocean'}});
            dispatch({ type: TYPES.FETCH_ISSO_LIST.FULFILLED, issoList: res.data });
        } catch (e) {
            const errors = generateErrors(e);
            dispatch({ type: TYPES.FETCH_ISSO_LIST.REJECTED, errors });
        }
    }
};

export const fetchCblList = (pageNumber?: number, pageSize?: number) => {
    return async (dispatch: ThunkDispatch<any, any, Action>, getState: () => IStore) => {
        dispatch({ type: TYPES.FETCH_CBLS_LIST });
        try {
            const res = await callApiPost(API.Fact.GetCblsList, applyFilters(getState, {}, {
                Page: pageNumber,
                PageNumber: pageNumber,
                PageSize: pageSize
            }), {headers: {TransportMode: 'Ocean'}});
            dispatch({ type: TYPES.FETCH_CBLS_LIST.FULFILLED, cblList: res.data });
        } catch (e) {
            const errors = generateErrors(e);
            dispatch({ type: TYPES.FETCH_CBLS_LIST.REJECTED, errors });
        }
    };
};

export const fetchShipmentDetails = (shipmentId: number) => {
    return async (dispatch: ThunkDispatch<any, any, Action>) => {
        dispatch({ type: TYPES.FETCH_SHIPMENT_DETAILS.PENDING });
        try {
            const res = await callApiPost(API.Fact.FetchShipmentDetails, { shipmentId }, {headers: {TransportMode: 'Ocean'}});
            dispatch({ type: TYPES.FETCH_SHIPMENT_DETAILS.FULFILLED, shipmentDetails: res.data });
        } catch (e) {
            const errors = generateErrors(e);
            dispatch({ type: TYPES.FETCH_SHIPMENT_DETAILS.REJECTED, errors });
        }
    }
};

export const createEsso = (documentId: number) => {
    return async (dispatch: ThunkDispatch<any, any, Action>) => {
        dispatch({ type: TYPES.CREATE_ESSO.PENDING });
        try {
            const res = await callApiPost(API.Fact.EssoResend, { documentId }, {headers: {TransportMode: 'Ocean'}});
            dispatch({ type: TYPES.CREATE_ESSO.FULFILLED, shipmentDetails: res.data });
        } catch (e) {
            const errors = generateErrors(e);
            dispatch({ type: TYPES.CREATE_ESSO.REJECTED, errors });
        }
    }
};

export const createIsso = (equipmentTransportShipmentId: number) => {
    return async (dispatch: ThunkDispatch<any, any, Action>) => {
        dispatch({ type: TYPES.CREATE_ISSO.PENDING });
        try {
            const res = await callApiPost(API.Fact.IssoResend, { equipmentTransportShipmentId }, {headers: {TransportMode: 'Ocean'}});
            dispatch({ type: TYPES.CREATE_ISSO.FULFILLED, shipmentDetails: res.data });
        } catch (e) {
            const errors = generateErrors(e);
            dispatch({ type: TYPES.CREATE_ISSO.REJECTED, errors });
        }
    }
};

export const generateDqIssuesReport = (data: any) => {
    return async (dispatch: ThunkDispatch<any, any, Action>) => {
        dispatch({type: TYPES.GENERATE_DQ_ISSUES_REPORT.PENDING});
        try {
            const res = await callApiPut(API.Fact.GenerateDQReport, data, {
                responseType: 'blob',
                headers: {
                    TransportMode: 'Ocean'
                } 
            }) 
            dispatch({type: TYPES.GENERATE_DQ_ISSUES_REPORT.FULFILLED, res});
            return res.data;
        } catch (e) {
            const errors = generateErrors(e);
            dispatch({ type: TYPES.GENERATE_DQ_ISSUES_REPORT.REJECTED, errors });
            throw e;
        }
    }
};