import React from 'react';
import { ModalType } from 'components/modal-screen/ModalType';
import { ModalScreen } from 'components/modal-screen';
import { Icon, IconsType } from 'components/icons/Icon';

interface IShipmentClosedModal {
    visible: boolean;
    onClose: () => void;
    onAccept: () => Promise<void>;
}

export class ShipmentClosedModal extends React.Component<IShipmentClosedModal> {
    public render() {
        return (
            <ModalScreen
                title="Are you sure"
                modalType={ModalType.s()}
                primaryButtonTitle="Yes"
                primaryButtonFunc={this.onAccept}
                primaryButtonId="submit-no-date"
                secondaryButtonTitle="Cancel"
                secondaryButtonFunc={this.props.onClose}
                visible={this.props.visible}
                closeModal={this.props.onClose}
                id="no-date-modal"
            >
                <Icon iconType={IconsType.warning} /> Shipment has been already closed, are you sure you want to make the update?
            </ModalScreen>
        )
    }

    private onAccept = () => {
        this.props.onClose();
        this.props.onAccept();
    }
}