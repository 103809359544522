import * as React from 'react';
import styled from 'styled-components';
import { COLORS } from 'styles/colors';

export const SErrorMessage = styled.span`
    color: ${COLORS.RED};
    margin: 5px 0;
`;

export interface IInput {
    label: string;
    value?: string;
    disabled?: boolean;
    input?: React.InputHTMLAttributes<HTMLInputElement>;
    meta?: any;
    type?: string
}

export const Input: React.FunctionComponent<IInput> = (props) => {
    if(props.type === 'number') {
        return (
            <div className="form-group">
                <label>{props.label}</label>
                <input
                    className="form-control"
                    disabled={props.disabled}
                    type={props.type}
                    min="0"
                    {...props.input}
                />
                {props.meta && props.meta.touched && props.meta.error && <SErrorMessage>{props.meta.error}</SErrorMessage>}
            </div>
        );
    }
    return (
        <div className="form-group">
            <label>{props.label}</label>
            <input
                className="form-control"
                disabled={props.disabled}
                type={props.type}
                {...props.input}
            />
            {props.meta && props.meta.touched && props.meta.error && <SErrorMessage>{props.meta.error}</SErrorMessage>}
        </div>
    );
};
