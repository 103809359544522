import React, { Component } from 'react';
import { EditDAndDDetailsPopup } from 'modules/modals/components/EditDAndDDetailsPopup';
import { formatDate, formatValueIfNullOrUndefined, getArrayOfButtons } from 'utilities/util';
import { IEquipmentDetails } from 'reducers/planning/models';
import { BUSINNESS_FUNCTIONS } from 'constants/roles';
// tslint:disable-next-line: no-submodule-imports
import { IWithPermissions, withPermissions } from 'src/HOC/withPermissions';
import { PropertiesRibbonWrapper } from 'components/properties-ribbon';

interface IDemurrageAndDetentionContainerDetailsTopBar extends IWithPermissions {
    equipment: IEquipmentDetails;
    refetchFunction: () => void;
}

interface IDemurrageAndDetentionContainerDetailsTopBarState {
    isEditDetailsModalOpen: boolean
}

class _DemurrageAndDetentionContainerDetailsTopBar extends Component<IDemurrageAndDetentionContainerDetailsTopBar, IDemurrageAndDetentionContainerDetailsTopBarState> {
    public state: IDemurrageAndDetentionContainerDetailsTopBarState = {
        isEditDetailsModalOpen: false
    };

    public render() {
        const actions = [
            {label: 'Update D&D details', action: this.toggleEditDetailsModal},
        ];
        const propertiesRibbonData = {
            labels: [
                {label: 'Demurrage expiry', value: formatDate(this.props.equipment.demmurageFreeTimeExpiry)},
                {label: 'Planned date / time', value: formatDate(this.props.equipment.deliveryPlannedDate)},
                {label: 'Delivery actual', value: formatDate(this.props.equipment.deliveryActualDate)},
                {label: 'Detention expiry', value: formatDate(this.props.equipment.detentionFreeTimeExpiry)},
                {label: 'Current location', value: formatValueIfNullOrUndefined(this.props.equipment.deliveryCurrentLocation)},
                {label: 'Delivery status', value: formatValueIfNullOrUndefined(this.props.equipment.deliveryStatus)},
                {label: 'GOH', value: formatValueIfNullOrUndefined(this.props.equipment.isGOH ? 'Y' : 'N')},
            ],
            actions: getArrayOfButtons(this.props.permissions, actions)
        };

        return (
            <React.Fragment>
                <PropertiesRibbonWrapper content={propertiesRibbonData} />
                {this.state.isEditDetailsModalOpen && <EditDAndDDetailsPopup
                    visible={this.state.isEditDetailsModalOpen}
                    closeModal={this.toggleEditDetailsModal}
                    id={this.props.equipment.id}
                    refetchFunction={this.props.refetchFunction}
                />}
            </React.Fragment>
        );
    };

    private toggleEditDetailsModal = () => this.setState((state) => ({
        isEditDetailsModalOpen: !state.isEditDetailsModalOpen
    }));
}

const ROLES: BUSINNESS_FUNCTIONS[] = [BUSINNESS_FUNCTIONS.OCEAN_DD_EQUIPMENT_UPDATE_DELIVERY_DETAILS];

export const DemurrageAndDetentionContainerDetailsTopBar = withPermissions<IDemurrageAndDetentionContainerDetailsTopBar>(_DemurrageAndDetentionContainerDetailsTopBar, ROLES) as any;