import React from 'react';
import { Button, ButtonType } from 'components/button/index';
import { ToggleArrow } from 'components/toggle-arrow';

interface IFilterFooter {
    isExpanded: boolean;
    onToggleClick(): void;
    onApplyClick(): void;
    onClearClick(): void;
}

export const FilterFooter: React.SFC<IFilterFooter> = (props: IFilterFooter) => {
    const onToggle = props.onToggleClick as () => void;
    return (
        <fieldset>
            <div className="filter-button-area">
                <Button
                    buttonType={ButtonType.Blue}
                    onClick={props.onApplyClick}
                    id="apply-filters-button"
                >
                    Apply
                </Button>
                <Button
                    buttonType={ButtonType.Transparent}
                    onClick={props.onClearClick}
                    id="clear-filters"
                    type="reset"
                >
                    Clear
                </Button>
                <Button
                    buttonType={ButtonType.Transparent}
                    onClick={onToggle}
                    style={{float: 'right'}}
                >
                    <ToggleArrow
                        isExpanded={props.isExpanded}
                    />
                </Button>
            </div>
        </fieldset>
    )
}