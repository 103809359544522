import React from 'react';
import { formatDate } from 'utilities/util';
import { StatusRow } from 'components/status-row';
import { QuarterGrid } from 'components/grid/quarter-grid';
import { OneStatus } from 'components/status-row/OneStatus';

interface IDeliveryRowProps {
    originalDeliveryDate: string;
    deliveryPlannedDate: string;
    milestonesGateInDCDate: string;
    milestonesUnloadDCDate: string;
    milestonesGateOutDCDate: string;
}

export const DeliveryRow = (props: IDeliveryRowProps) => (
    <StatusRow title="Delivery">
        <QuarterGrid>
            <OneStatus label="Original Planned Date">
                {formatDate(props.originalDeliveryDate)}
            </OneStatus>
            <OneStatus label="Planned Date">
                {formatDate(props.deliveryPlannedDate)}
            </OneStatus>
            <OneStatus label="Check in DC">
                {formatDate(props.milestonesGateInDCDate)}
            </OneStatus>
            <OneStatus label="Unloaded DC">
                {formatDate(props.milestonesUnloadDCDate)}
            </OneStatus>
        </QuarterGrid>
        <QuarterGrid>
            <OneStatus label="Check out DC">
                {formatDate(props.milestonesGateOutDCDate)}
            </OneStatus>
        </QuarterGrid>
    </StatusRow>
)
