import React from 'react';
import { ColumnFactory } from 'components/data-table/ColumnFactory';
import { DataTable } from 'components/data-table/DamcoTable';
import { IFactEquipmentTransportShipment, IIsso } from 'reducers/fact/models';
import { StatusRow } from 'components/status-row';
import { cloneDataWithDisplayNames } from 'utilities/tables';

interface IEquipmentTransportShipmentTableProps {
    data: IFactEquipmentTransportShipment[];
}

export const EquipmentTransportShipmentTable: React.FunctionComponent<IEquipmentTransportShipmentTableProps> = (props) => {
    const data = cloneDataWithDisplayNames(props.data).map(ets => ({
        ...ets,
        equipmentTransportShipmentDocumentNumberDisplayName: [ets.equipmentTransportShipmentDocumentNumber, `/exception-management/fact/ets/${ets.id}`],
        children: ets.issos.map((isso: IIsso) => ({
            ...isso,
            financialChangeIndicatorDisplayName: isso.financialChangeIndicator ? 'Y' : 'N',
            isSentDisplayName: isso.isSent ? 'Y' : 'N',
        }))
    }));

    return(
        <StatusRow title="Equipment transport shipments">
            <DataTable
                data={data}
                columns={[
                    ColumnFactory.linkedColumn('equipmentTransportShipmentDocumentNumberDisplayName', 'Equipment transport shipment document number'),
                    ColumnFactory.standardColumn('equipmentNumberDisplayName', 'Equipment number'),
                    ColumnFactory.standardColumn('equipmentSealNumberDisplayName', 'Equipment seal number'),
                    ColumnFactory.standardColumn('shipmentNumberDisplayName', 'Shipment number'),
                    ColumnFactory.standardColumn('volumeDisplayName', 'Volume'),
                    ColumnFactory.standardColumn('createDateDisplayName', 'Create date'),
                    ColumnFactory.standardColumn('lastUpdateDateDisplayName', 'Last update date'),
                    ColumnFactory.standardColumn('isCancelledDisplayName', 'Is cancelled'),
                    ColumnFactory.standardColumn('anyIssoSentDisplayName', 'Any isso sent'),
                ]}
                subColumns={[
                    ColumnFactory.standardColumn('issoNumberDisplayName', 'ISSO number'),
                    ColumnFactory.standardColumn('messageTypeDisplayName', 'Message type'),
                    ColumnFactory.standardColumn('isSentDisplayName', 'Is sent'),
                    ColumnFactory.standardColumn('generationDateDisplayName', 'Generation date'),
                    ColumnFactory.standardColumn('changedFinancialFieldsDisplayName', 'Changed financial fields'),
                    ColumnFactory.standardColumn('financialChangeIndicatorDisplayName', 'Financial change indicator'),
                    ColumnFactory.standardColumn('brainResponseStatusCodeDisplayName', 'Status')
                ]}
                expandable={true}
                withCheckboxes={false}
                reference={null as any}
                tableName="FactDetailsEquipmentTransportShipmentTable"
                showPagination={true}
            />
        </StatusRow>
    );
};

