import React, { useEffect } from 'react';
import { PageHeader } from 'components/page-header';
import { Page } from 'components/page-container';
import { Log } from './components/Log';
import { connect } from 'react-redux';
import { ThunkDispatch } from 'redux-thunk';
import { Action } from 'redux';
import { getChangelog } from 'actions/changelog';
import { IStore } from 'reducers/index';
import { IChangeLog } from 'reducers/changelog/models';

interface IMapStateToProps {
    changelog: IChangeLog[];
}
interface IMapDispatchToProps {
    getChangelog: () => Promise<void>;
}

interface IChangeLogProps extends IMapStateToProps, IMapDispatchToProps {}

const _ChangeLog: React.StatelessComponent<IChangeLogProps> = (props) => {
    useEffect(() => {
        props.getChangelog();
    }, []);

    const logs = props.changelog.map((log, i) => {
        return(
            <Log 
                data={log} 
                key={i}
                parentNode={i}
            />
        )
    }).reverse();

    return(
        <React.Fragment>
            <PageHeader>Change log</PageHeader>
            <Page>
                {logs}
            </Page>
        </React.Fragment>
    );
};

const mapStateToProps = (state: IStore): IMapStateToProps => ({
    changelog: state.changelog.changelog
});

const mapDispatchToProps = (dispatch: ThunkDispatch<any, any, Action>): IMapDispatchToProps => ({
    getChangelog: () => dispatch(getChangelog())
});

export const ChangeLog = connect(mapStateToProps, mapDispatchToProps)(_ChangeLog);