import React from 'react';
import { ColumnFactory } from 'components/data-table/ColumnFactory';
import { IDelivery } from 'reducers/planning/models';
import { DataTable } from 'components/data-table/DamcoTable';
import { cloneDataWithDisplayNames } from 'utilities/tables';
import { Transport_Mode, Transport_Type } from 'constants/transport';

interface IDeliveryTableProps {
    data: IDelivery[];
}

export const DeliveryTable = (props: IDeliveryTableProps) => {
    const data = cloneDataWithDisplayNames(props.data ? props.data : []).map((obj: any) => ({
        ...obj,
        transportModeDisplayName: Transport_Mode[obj.transportMode],
        transportTypeDisplayName: Transport_Type[obj.transportType],
    }));

    return (
        <DataTable
            withCheckboxes={false}
            expandable={false}
            data={data}
            columns={[
                ColumnFactory.standardColumn('transportModeDisplayName', 'Transport mode'),
                ColumnFactory.standardColumn('transportTypeDisplayName', 'Transport type'),
                ColumnFactory.standardColumn('haulierDisplayName', 'Haulier'),
                ColumnFactory.standardColumn('isTransportOrderSentDisplayName', 'Sent'),
                ColumnFactory.standardColumn('pickupLocationDisplayName', 'Pickup'),
                ColumnFactory.standardColumn('estimatedPickupDateDisplayName', 'Planned Date/Time'),
                ColumnFactory.standardColumn('pickupDateDisplayName', 'Date/Time'),
                ColumnFactory.standardColumn('deliveryDropOffLocationDisplayName', 'Drop-off'),
                ColumnFactory.standardColumn('estimatedDeliveryDropOffDateDisplayName', 'Planned Date/Time'),
                ColumnFactory.standardColumn('deliveryDropOffDateDisplayName', 'Date/Time'),
                ColumnFactory.standardColumn('deliveryReferenceDisplayName', 'Delivery Reference'),
            ]}
            reference={null as any}
            tableName="EquipmentDetailsDeliveryTable"
        />
    )
}
