import React from 'react';

interface IIconProps {
    iconType: IIconType;
}

export const Icon: React.FunctionComponent<IIconProps> = (props) => {
    return (
        <span style={{color: props.iconType.color, transition: 'all .3s ease'}}>
            <i className={props.iconType.css} style={{marginRight: '0.25rem'}}/>
        </span>
    );
};

enum IconsColor{
    Green = '#76bc68',
    Red = '#D52B1E',
    Blue = '#398FD1',
    Orange = '#FF7900'
}

export interface IIconType {
    color: string;
    css: string;
}

export interface IIconsType {
    positive: IIconType,
    negative: IIconType,
    waiting: IIconType,
    warning: IIconType,
}

export const IconsType: IIconsType = {
    positive: {
        color: IconsColor.Green,
        css:  'fa fa-check-circle'
    },
    negative: {
        color: IconsColor.Red,
        css:  'fa fa-times-circle failed'
    },
    warning: {
        color: IconsColor.Orange,
        css:  'fa fa-exclamation-triangle'
    },
    waiting: {
        color: IconsColor.Blue,
        css:  'fa fa-clock-o'
    },
}