import { TYPES } from 'action-types/customs';
import {
    IContainer,
    ICustomBl,
    IHBLFCRDetails,
    IHawbDetails,
    IEquipments
} from 'reducers/customs/models';
import { cloneDataWithDisplayNames } from 'utilities/tables';

interface IAction {
    readonly type: string;
    readonly error?: string;
    readonly bls: ICustomBl;
    readonly hblFcrDetails: IHBLFCRDetails;
    readonly hawbDetails: IHawbDetails;
    readonly container: IContainer;
    readonly equipments: IEquipments;
}

export interface ICustomsState {
    readonly isFetchBlsLoading: boolean;
    readonly fetchBlsError: string;
    readonly isFetchHBLFCRDetailsLoading: boolean;
    readonly fetchHBLFCRDetailsError: string;
    readonly isUpdateHBLDetailsLoading: boolean;
    readonly updateHBLDetailsError: string;
    readonly isFetchContainerDetails: boolean;
    readonly fetchContainerError: string;
    readonly isFetchHawbDetailsLoading: boolean;
    readonly fetchHawbDetailsError: string;
    readonly isError: boolean;
    readonly bls: ICustomBl;
    readonly hblFcrDetails: IHBLFCRDetails;
    readonly hawbDetails: IHawbDetails;
    readonly container: IContainer;
    readonly airClearance: ICustomBl;
    readonly isfetchHblFcrDetailsOnListLoading: boolean;
    readonly fetchHblFcrDetailsOnListError: string;
    readonly isFetchHawbDetailsOnListLoading: boolean;
    readonly fetchHawbDetailsOnListError: string;
    readonly equipments: IEquipments;
    readonly isFetchEquipmentsLoading: boolean;
}

const defaultState: ICustomsState = {
    isFetchHawbDetailsLoading: false,
    fetchHawbDetailsError: '',
    isFetchBlsLoading: false,
    fetchBlsError: '',
    isFetchHBLFCRDetailsLoading: false,
    fetchHBLFCRDetailsError: '',
    isUpdateHBLDetailsLoading: false,
    updateHBLDetailsError: '',
    isFetchContainerDetails: false,
    fetchContainerError: '',
    isError: false,
    isfetchHblFcrDetailsOnListLoading: false,
    fetchHblFcrDetailsOnListError: '',
    isFetchHawbDetailsOnListLoading: false,
    fetchHawbDetailsOnListError: '',
    hblFcrDetails: {
        ata: null,
        atd: null,
        consigneeBECode: null,
        consigneeName: null,
        equipments: [],
        bubbleData: [],
        etd: null,
        hblId: 0,
        hblNumber: '',
        newETA: null,
        pod: '',
        pol: '',
        sadBroker: null,
        sadComments: null,
        sadCustomsClearedDate: null,
        sfdAssignedUser: null,
        sfdBroker: null,
        sfdConsignee: null,
        sfdEntryReference: null,
        sfdJobNotes: null,
        sfdProduct: null,
        sfdReference: null,
        sfdStatus: null,
        sfdStatusDate: null,
        sfdcpc: null,
        cL1_Type: '',
        cL2_Type: '',
        cL1_AssignedUser: '',
        cL1_Broker: null,
        cL1_CPC: '',
        cL1_Consignee: '',
        cL1_EntryReference: null,
        cL1_Comments: '',
        cL1_Product: '',
        cL1_Reference: '',
        cL1_Status: 0,
        cL1_StatusDate: '',
        cL2_Broker: null,
        cL2_Comments: null,
        cL2_CustomsClearedDate: null,
        cL2_Status: null,
        vesselName: '',
        voyageId: null,
        voyageReference: '',
        voyageStatus: null,
        holds: [],
        lastUserUpdated: null,
        updateDate: null,
        customsStatus: 0
    },
    hawbDetails: {
        hblNumber: '',
        ata: null,
        atd: null,
        consigneeBECode: null,
        consigneeName: null,
        equipments: [],
        bubbleData: [],
        etd: null,
        hblId: 0,
        FlightNumber: '',
        newETA: null,
        pod: '',
        pol: '',
        sadBroker: null,
        sadComments: null,
        sadCustomsClearedDate: null,
        sfdAssignedUser: null,
        sfdBroker: null,
        sfdConsignee: null,
        sfdEntryReference: null,
        sfdJobNotes: null,
        sfdProduct: null,
        sfdReference: null,
        sfdStatus: null,
        sfdStatusDate: null,
        sfdcpc: null,
        cL1_Type: '',
        cL2_Type: '',
        cL1_AssignedUser: '',
        cL1_Broker: null,
        cL1_CPC: '',
        cL1_Consignee: '',
        cL1_EntryReference: null,
        cL1_Comments: '',
        cL1_Product: '',
        cL1_Reference: '',
        cL1_Status: 0,
        cL1_StatusDate: '',
        cL2_Broker: null,
        cL2_Comments: null,
        cL2_CustomsClearedDate: null,
        cL2_Status: null,
        vesselName: '',
        voyageId: null,
        voyageReference: '',
        voyageStatus: null,
        holds: [],
        lastUserUpdated: null,
        updateDate: null,
        customsStatus: 0
    },
    bls: {
        totalCount: 0,
        page: 0,
        pageSize: 0,
        result: [],
    },
    airClearance: {
        totalCount: 0,
        page: 0,
        pageSize: 0,
        result: [],
    },
    container: {
        voyageId: 0,
        vesselName: '',
        voyageReference: '',
        pol: '',
        pod: '',
        currentETA: '',
        ata: '',
        landedDate: '',
        status: '',
        customsStatus: '',
        voyageStatus: 0,
        numberOfHbls: 0,
        equipment: {
            hotbox: false,
            service: '',
            shipmentType: '',
            sizeType: '',
            sealNumber: '',
            totalPackages: 0,
            packageType: '',
            weight: 0,
            volume: 0,
            customsStatus: 0,
            cL1_Type: '',
            cL1_Broker: null,
            cL1_StatusDate: '',
            cL1_Status: 0,
            cL1_Comments: '',
            equipmentNumber: '',
            eidr:'',
            sfd:'',
            ucr:''
        },
        po: [],
        hbl: [],
        lastUser: {
            updateDate: '',
            user: ''
        },
        bubbleData: []
    },
    equipments: {
        result: [],
        pageNumber: 1,
        pageSize: 0,
        totalCount: 0
    },
    isFetchEquipmentsLoading: false,
};

export const reducer = (state: ICustomsState = defaultState, action: IAction): ICustomsState => {
    switch (action.type) {
        case TYPES.FETCH_BLS.PENDING:
            return {
                ...state,
                isFetchBlsLoading: true,
                fetchBlsError: '',
            };
        case TYPES.FETCH_BLS.FULFILLED:
            return {
                ...state,
                bls: {
                    totalCount: action.bls.totalCount || defaultState.bls.totalCount,
                    page: action.bls.page || defaultState.bls.page,
                    pageSize: action.bls.pageSize || defaultState.bls.pageSize,
                    result: cloneDataWithDisplayNames(action.bls.result.map((obj: any) => {
                        return {
                            ...obj,
                            children: cloneDataWithDisplayNames(obj.hblEquipmentData.map((row: any) => {
                                return {
                                    ...row,
                                    voyageId: obj.voyageId,
                                    _uniqId: `${row.equipmentId}_${row.hblId}`
                                };
                            }))
                        };
                    })) || defaultState.bls.result
                },
                isFetchBlsLoading: false,
                fetchBlsError: ''
            }
        case TYPES.FETCH_BLS.REJECTED:
            return {
                ...state,
                isFetchBlsLoading: false,
                fetchBlsError: action.error || ''
            };
        case TYPES.FETCH_BLS.RESET_ERROR:
            return {
                ...state,
                fetchBlsError: '',
            }
        case TYPES.FETCH_HBLFCR_DETAILS.PENDING:
            return {
                ...state,
                isFetchHBLFCRDetailsLoading: true,
                fetchHBLFCRDetailsError: ''
            };
        case TYPES.FETCH_HBLFCR_DETAILS.FULFILLED:
            return {
                ...state,
                hblFcrDetails: action.hblFcrDetails,
                isFetchHBLFCRDetailsLoading: false,
                fetchHBLFCRDetailsError: ''
            };
        case TYPES.FETCH_HBLFCR_DETAILS.REJECTED:
            return {
                ...state,
                isFetchHBLFCRDetailsLoading: false,
                fetchHBLFCRDetailsError: action.error || ''
            };
        case TYPES.FETCH_HBLFCR_DETAILS.RESET_ERROR:
            return {
                ...state,
                fetchHBLFCRDetailsError: '',
            }
        case TYPES.FETCH_HBLFCR_DETAILS_ON_LIST.PENDING:
            return {
                ...state,
                isfetchHblFcrDetailsOnListLoading: true,
            };
        case TYPES.FETCH_HBLFCR_DETAILS_ON_LIST.FULFILLED:
            return {
                ...state,
                hblFcrDetails: action.hblFcrDetails,
                isfetchHblFcrDetailsOnListLoading: false,
            };
        case TYPES.FETCH_HBLFCR_DETAILS_ON_LIST.REJECTED:
            return {
                ...state,
                isfetchHblFcrDetailsOnListLoading: false,
                fetchHblFcrDetailsOnListError: action.error || ''
            };
        case TYPES.FETCH_HBLFCR_DETAILS_ON_LIST.RESET_ERROR:
            return {
                ...state,
                fetchHblFcrDetailsOnListError: '',
            }
        case TYPES.UPDATE_HBL_DETAILS.PENDING:
            return {
                ...state,
                isUpdateHBLDetailsLoading: true,
                updateHBLDetailsError: ''
            }
        case TYPES.UPDATE_HBL_DETAILS.FULFILLED:
            return {
                ...state,
                isUpdateHBLDetailsLoading: false,
                updateHBLDetailsError: ''
            };
        case TYPES.UPDATE_HBL_DETAILS.REJECTED:
            return {
                ...state,
                isUpdateHBLDetailsLoading: false,
                updateHBLDetailsError: action.error || '',
            };
        case TYPES.UPDATE_HBL_DETAILS.RESET_ERROR:
            return {
                ...state,
                updateHBLDetailsError: '',
            }
        case TYPES.FETCH_CONTAINER_DETAILS.PENDING:
            return {
                ...state,
                isFetchContainerDetails: true,
                fetchContainerError: ''
            };
        case TYPES.FETCH_CONTAINER_DETAILS.FULFILLED:
            return {
                ...state,
                container: action.container || defaultState.container,
                isFetchContainerDetails: false,
                fetchContainerError: ''
            };
        case TYPES.FETCH_CONTAINER_DETAILS.REJECTED:
            return {
                ...state,
                isFetchContainerDetails: false,
                fetchContainerError: action.error || '',
            };
        case TYPES.FETCH_CONTAINER_DETAILS.RESET_ERROR:
            return {
                ...state,
                fetchContainerError: '',
            }
        case TYPES.FETCH_AIR_CLEARANCE.PENDING:
            return {
                ...state,
                isFetchBlsLoading: true,
                fetchBlsError: '',
            };
        case TYPES.FETCH_AIR_CLEARANCE.FULFILLED:
            return {
                ...state,
                airClearance: {
                    totalCount: action.bls.totalCount || defaultState.bls.totalCount,
                    page: action.bls.page || defaultState.bls.page,
                    pageSize: action.bls.pageSize || defaultState.bls.pageSize,
                    result: cloneDataWithDisplayNames(action.bls.result.map((obj: any) => {
                        return {
                            ...obj,
                            children: cloneDataWithDisplayNames(obj.hblEquipmentData.map((row: any) => {
                                return {
                                    ...row,
                                    voyageId: obj.voyageId,
                                    _uniqId: `${row.equipmentId}_${row.hblId}`
                                };
                            }))
                        };
                    }), 'DD-MM-YYYY HH:mm') || defaultState.bls.result
                },
                isFetchBlsLoading: false,
                fetchBlsError: ''
            };
        case TYPES.FETCH_AIR_CLEARANCE.REJECTED:
            return {
                ...state,
                isFetchBlsLoading: false,
                fetchBlsError: '',
            };
        case TYPES.FETCH_AIR_CLEARANCE.RESET_ERROR:
            return {
                ...state,
                fetchBlsError: '',
            }
        case TYPES.FETCH_CUSTOMS_HAWB_DETAILS.PENDING:
            return {
                ...state,
                isFetchHawbDetailsLoading: true,
                fetchHawbDetailsError: '',
            };
        case TYPES.FETCH_CUSTOMS_HAWB_DETAILS.FULFILLED:
            return {
                ...state,
                hawbDetails: action.hawbDetails,
                isFetchHawbDetailsLoading: false,
                fetchHawbDetailsError: '',
            };
        case TYPES.FETCH_CUSTOMS_HAWB_DETAILS.REJECTED:
            return {
                ...state,
                isFetchHawbDetailsLoading: false,
                fetchHawbDetailsError: action.error || '',
            };
        case TYPES.FETCH_CUSTOMS_HAWB_DETAILS.RESET_ERROR:
            return {
                ...state,
                fetchHawbDetailsError: '',
            }
        case TYPES.FETCH_HAWB_DETAILS_ON_LIST.PENDING:
            return {
                ...state,
                isFetchHawbDetailsOnListLoading: true,
                fetchHawbDetailsOnListError: '',
            };
        case TYPES.FETCH_HAWB_DETAILS_ON_LIST.FULFILLED:
            return {
                ...state,
                isFetchHawbDetailsOnListLoading: false,
                fetchHawbDetailsOnListError: '',
                hawbDetails: action.hawbDetails
            };
        case TYPES.FETCH_HAWB_DETAILS_ON_LIST.REJECTED:
            return {
                ...state,
                isFetchHawbDetailsOnListLoading: false,
                fetchHawbDetailsOnListError: action.error || '',
            };
        case TYPES.FETCH_HAWB_DETAILS_ON_LIST.RESET_ERROR:
            return {
                ...state,
                fetchHawbDetailsOnListError: '',
            }
        case TYPES.FETCH_EQUIPMENTS.PENDING:
            return {
                ...state,
                isFetchEquipmentsLoading: true,
            };
        case TYPES.FETCH_EQUIPMENTS.FULFILLED:
            return {
                ...state,
                isFetchEquipmentsLoading: false,
                equipments: {
                    result: cloneDataWithDisplayNames(action.equipments.result) || defaultState.equipments.result,
                    pageNumber: action.equipments.pageNumber || defaultState.equipments.pageNumber,
                    pageSize: action.equipments.pageSize || defaultState.equipments.pageSize,
                    totalCount: action.equipments.totalCount || defaultState.equipments.totalCount
                }
            };
        case TYPES.FETCH_EQUIPMENTS.REJECTED:
            return {
                ...state,
                isFetchEquipmentsLoading: false,
            };
        default:
            return state;
    }
};
