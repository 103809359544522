import React from 'react';
import {ColumnFactory} from 'components/data-table/ColumnFactory';
import {Column} from 'react-table';
import {formatValueIfNullOrUndefined} from 'utilities/util';

export const ValueBasedOnIdColumn = (accessor: string, name: string, values: any[], selector = 'id', displayValueSelector = 'optionText'): Column => {
    const cell = (rowInfo: { value: string }) => {
        const foundValue = values.find((oneValue) => oneValue[selector] === rowInfo.value);
        if(!foundValue) {
            return(
                <div>
                    -
                </div>
            )
        }
        return(
            <div>
                {formatValueIfNullOrUndefined(foundValue[displayValueSelector])}
            </div>
        );
    };
    return ColumnFactory.getColumnOptions(accessor, name, cell);
};