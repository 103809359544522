import React from 'react';
import { StatusRow } from 'components/status-row';
import { OneStatus } from 'components/status-row/OneStatus';
import { QuarterGrid } from 'components/grid/quarter-grid';
import { formatValueIfNullOrUndefined } from 'utilities/util';

interface IShipmentRowProps {
    shipmentTypeAtOrigin: string;
    shipmentTypeAtDestination: string;
}

export const ShipmentRow: React.StatelessComponent<IShipmentRowProps> = (props) => {
    return (
        <StatusRow title="Shipment">
            <QuarterGrid>
                <OneStatus label="Shipment type at origin">
                    {formatValueIfNullOrUndefined(props.shipmentTypeAtOrigin)}
                </OneStatus>
                <OneStatus label="Shipment type at destination">
                    {formatValueIfNullOrUndefined(props.shipmentTypeAtDestination)}
                </OneStatus>
            </QuarterGrid>
        </StatusRow>
    );
};
