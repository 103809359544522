import React from 'react';
import { StatusRow } from 'components/status-row';
import { ColumnFactory } from 'components/data-table/ColumnFactory';
import { DataTable } from 'components/data-table/DamcoTable';
import { cloneDataWithDisplayNames } from 'utilities/tables';

interface IHBLDetailsTable {
    data: any[];
}

export const HBLDetailsTable = (props: IHBLDetailsTable) => {
    const data: any = cloneDataWithDisplayNames(props.data).map((obj: any) => ({
        ...obj,
        children: obj.pOs.map((pos: any) => ({
            ...pos,
            ...pos.sku[0]
        }))
    }));

    return (
        <StatusRow title="Equipment">
            <DataTable
                expandable={true}
                showPagination={true}
                reference={null as any}
                withCheckboxes={false}
                data={data}
                columns={[
                    ColumnFactory.standardColumn('equipmentNumberDisplayName', 'Equipment number'),
                    ColumnFactory.standardColumn('serviceDisplayName', 'Service'),
                    ColumnFactory.standardColumn('shipmentTypeDisplayName', 'Shipment Type'),
                    ColumnFactory.standardColumn('sizeTypeDisplayName', 'Size/type'),
                    ColumnFactory.standardColumn('hotBoxDisplayName', 'Hotbox'),
                    ColumnFactory.standardColumn('sealNumberDisplayName', 'Seal number'),
                    ColumnFactory.standardColumn('totalPackagesDisplayName', 'Total packages'),
                    ColumnFactory.standardColumn('shipmentTypeDisplayName', 'Package type'),
                    ColumnFactory.standardColumn('grossWeightDisplayName', 'Weight'),
                    ColumnFactory.standardColumn('volumeDisplayName', 'Volume'),
                ]}
                subColumns={[
                    ColumnFactory.standardColumn('poNumberDisplayName', 'PO Number'),
                    ColumnFactory.standardColumn('skuNumberDisplayName', 'SKU'),
                    ColumnFactory.standardColumn('goodsDescriptionDisplayName', 'Description'),
                    ColumnFactory.standardColumn('packagesDisplayName', 'Packages'),
                    ColumnFactory.standardColumn('pieceTypeDisplayName', 'Piece type'),
                    ColumnFactory.standardColumn('termsDisplayName', 'Terms')
                ]}
                tableName="HBLReleaseEquipmentTable"
            />
        </StatusRow>
    )
};
