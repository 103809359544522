import React from 'react';

interface IStandardHeader {
    name: string;
}

export const StandardHeader = (props: IStandardHeader) => {
    return (
        <React.Fragment>
            <span>{props.name}</span>
            <i className="fa fa-sort" aria-hidden="true"/>
        </React.Fragment>
    );
};
