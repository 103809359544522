import React from 'react';
import { Switch, Route, Redirect } from 'react-router';
import { Settings } from 'views/exception-management/settings';
import { EventLogs} from 'views/exception-management/event-logs';
import { FactRouting } from './fact/FactRouting';
import { CustomerManagement } from 'views/exception-management/customer-management';

export const ExceptionManagementRouting = () => {
    return(
        <Switch>
            <Route
                path="/exception-management/fact"
                component={FactRouting}
            />
            <Route
                path="/exception-management/event-logs"
                component={EventLogs}
            />
            <Route
                path="/exception-management/settings"
                component={Settings}
            />
            <Route
                path="/exception-management/customer-management"
                component={CustomerManagement}
            />
            <Redirect to="/exception-management/fact" />
        </Switch>
    );
};