import React from 'react';
import { ColumnFactory } from 'components/data-table/ColumnFactory';
import { LoadingAnimation } from 'components/loading';
import { IGlobalSettings } from 'reducers/settings/models';
import { DataTable } from 'components/data-table/DamcoTable';
import { EditGlobalSettingsModal } from 'modules/settings/shared/EditGlobalSettingsModal';
import { ErrorModal } from 'modules/modals/components/ErrorModal';
import { ThunkDispatch } from 'redux-thunk';
import { Action } from 'redux';
import { IUpdateDDContractBody } from 'reducers/demurrage-and-detention/models';
import { updateNewDDContract, removeDDContract, getDDContracts } from 'actions/demurrageAndDetention';
import { connect } from 'react-redux';

interface IMapDispatchToProps {
    updateDDContract: (body: IUpdateDDContractBody) => Promise<void>;
    removeDDContract: (id: number) => Promise<void>;
    getDDContracts: () => Promise<void>;
}

interface IConfigurationDataOwnProps {
    isLoading: boolean;
    data: IGlobalSettings[];
    updateSettings: (configuration: IGlobalSettings) => void;
    refreshSettings: () => void;
}

interface IConfigurationData extends IConfigurationDataOwnProps, IMapDispatchToProps {}

interface IConfigurationTableState {
    isEditConfigurationModalOpened: boolean;
    editRowKey: string | null;
    editedItem: any;
    isErrorModalOpen: boolean;
}

export class _GlobalsTable extends React.Component<IConfigurationData, IConfigurationTableState> {
    public dataTable = React.createRef<any>();

    public state: IConfigurationTableState = {
        isEditConfigurationModalOpened: false,
        editRowKey: null,
        editedItem: null,
        isErrorModalOpen: false
    };

    public render() {
        return (
            <React.Fragment>
                {this.props.isLoading && <LoadingAnimation />}
                <DataTable
                    data={this.props.data}
                    expandable={false}
                    withCheckboxes={true}
                    selectVariant="single"
                    columns={[
                        ColumnFactory.standardColumn('fieldName', 'Key'),
                        ColumnFactory.standardColumn('value', 'Value')
                    ]}
                    actions={{
                        parentContextualActions: [
                            { isMoreBtn: false, label: 'Edit', action: this.toggleEditModal },
                        ]
                    }}
                    reference={this.dataTable}
                    tableName="SettingsGlobalsTable"
                    showPagination={true}
                />
                {this.state.isEditConfigurationModalOpened && <EditGlobalSettingsModal
                    title="Edit settings"
                    visible={this.state.isEditConfigurationModalOpened}
                    editedItem={this.state.editedItem}
                    closeModal={this.toggleEditModal}
                    saveFunction={this.updateSettings}
                    initialValues={this.getRowToEdit()}
                />}
                {this.state.isErrorModalOpen && <ErrorModal 
                    visible={this.state.isErrorModalOpen}
                    closeModal={this.toggleErrorModal}
                    primaryButtonFunc={this.toggleErrorModal}
                />}
            </React.Fragment>
        );
    }

    private toggleEditModal = (key?: string) => {
        const selectedItems = this.dataTable.current ? this.dataTable.current.getSelectedItems() : [];

        if (selectedItems[0]) {
            const rowToEdit = selectedItems[0];

            this.setState((state: IConfigurationTableState) => ({
                isEditConfigurationModalOpened: !state.isEditConfigurationModalOpened,
                editRowKey: key || null,
                editedItem: rowToEdit ? rowToEdit : null
            }))
        }
    };

    private toggleErrorModal = () => this.setState((state: IConfigurationTableState) => ({
        isErrorModalOpen: !state.isErrorModalOpen
    }));

    private getRowToEdit = () => {
        const selectedItems = this.dataTable.current ? this.dataTable.current.getSelectedItems() : [];

        return {
            Key: selectedItems[0].key,
            OptionName: selectedItems[0].fieldName,
            Value: selectedItems[0].value
        };
    };

    private updateSettings = async (configuration: IGlobalSettings) => {
        await this.props.updateSettings(configuration);
        await this.toggleEditModal();
        await this.props.refreshSettings();
    };
}

const mapDispatchToProps = (dispatch: ThunkDispatch<any, any, Action>): IMapDispatchToProps => ({
    updateDDContract: (body: IUpdateDDContractBody) => dispatch(updateNewDDContract(body)),
    removeDDContract: (id: number) => dispatch(removeDDContract(id)),
    getDDContracts: () => dispatch(getDDContracts())
});

export const GlobalsTable = connect(undefined, mapDispatchToProps)(_GlobalsTable);