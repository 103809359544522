import { TYPES } from 'action-types/customer-management';
import { ICustomerManagementAction} from './models';

export interface ICustomerManagementState {
    readonly isLoading: boolean;
    readonly requestSucceeded: boolean;
}

const defaultState: ICustomerManagementState = {
    isLoading: false,
    requestSucceeded: false
};

export interface IEventsAction {
    readonly type: string;
    readonly data: ICustomerManagementAction;
    readonly error: string;
}

export const reducer = (state: ICustomerManagementState = defaultState, action: IEventsAction): ICustomerManagementState => {
    switch (action.type) {
        case TYPES.ADD_CUSTOMER.PENDING:
            return {
                ...state,
                isLoading: true
            };
        case TYPES.ADD_CUSTOMER.FULFILLED:
            return {
                ...state,
                isLoading: false,
                requestSucceeded: true
            };
        case TYPES.ADD_CUSTOMER.REJECTED:
            return {
                ...state,
                isLoading: false,
                requestSucceeded: false
            };
        default:
            return state;
    }
};
