import React, { Component } from 'react';
import { ModalScreen } from 'components/modal-screen';
import { ModalType } from 'components/modal-screen/ModalType';
import { reduxForm, InjectedFormProps, reset } from 'redux-form';
import { DoubleGrid } from 'components/grid/double-grid';
import { FormInput } from 'components/input/FormInput';
import { FormDateInput } from 'components/date-input/FormDateInput';
import { Dispatch } from 'redux';
import { FormSelect } from 'components/select/FormSelect';
import { SingleGrid } from 'components/grid/single-grid';
import { FormTextArea } from 'components/textarea/FormTextArea';
import { IOption } from 'components/select/Select';

interface IAddNewFlightModalOwnProps {
    title: string;
    visible: boolean;
    dataForDropdown: IOption[][];
    addHold: (fields: IAddNewFlightModalFields) => void;
    closeModal: () => void;
}

export interface IAddNewFlightModalFields {
    HoldType: number;
    EquipmentNumber: string;
    StartDate: string;
    EndDate: string;
    Comments: string;
}

interface IAddNewFlightModalProps extends IAddNewFlightModalOwnProps, InjectedFormProps<IAddNewFlightModalFields, IAddNewFlightModalOwnProps> {}

class _AddNewFlightModal extends Component<IAddNewFlightModalProps> {
    public render() {
        return (
            <form onSubmit={this.props.handleSubmit(this.props.addHold)}>
                <ModalScreen
                    title={this.props.title}
                    modalType={ModalType.s()}
                    primaryButtonTitle="Save"
                    primaryButtonType="submit"
                    primaryButtonIsDisabled={this.props.dataForDropdown.length === 0 ? true : false}
                    secondaryButtonTitle="Cancel"
                    secondaryButtonFunc={this.props.closeModal}
                    visible={this.props.visible}
                    closeModal={this.props.closeModal}
                >
                    <DoubleGrid>
                        <FormInput
                            label="Hold type"
                            name="HoldType"
                        />
                        <FormSelect
                            label="Equipment number"
                            name="EquipmentNumber"
                            options={this.mapOptionsForDropdown()}
                        />
                    </DoubleGrid>
                    <DoubleGrid>
                        <FormDateInput
                            label="Start date"
                            name="StartDate"
                            withTime={true}
                        />
                        <FormDateInput
                            label="End date"
                            name="EndDate"
                            withTime={true}
                        />
                    </DoubleGrid>
                    <SingleGrid>
                        <FormTextArea
                            label="Comments"
                            name="Comments"
                        />
                    </SingleGrid>
                </ModalScreen>
            </form>
        );
    }

    private mapOptionsForDropdown = () => {
        const options: IOption[] = [];
        this.props.dataForDropdown.map((arr: any) => {
            return options.push(...arr);
        });

        return options;
    };
};

const FORM_NAME = 'ADD_NEW_FLIGHTD_TO_HBL';
const onSubmitSuccess = (_: any, dispatch: Dispatch) => dispatch(reset(FORM_NAME));

export const AddNewFlightModal = reduxForm<IAddNewFlightModalFields, IAddNewFlightModalOwnProps>({
    form: FORM_NAME,
    onSubmitSuccess,
})(_AddNewFlightModal);
