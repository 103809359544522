import React from 'react';
import { ColumnFactory } from 'components/data-table/ColumnFactory';
import { LoadingAnimation } from 'components/loading';
import { IReasonCode } from 'reducers/settings/models';
import { DataTable } from 'components/data-table/DamcoTable';
import { CreateNewReasonCodeModal } from 'modules/settings/shared/CreateNewReasonCodeModal';
import { connect } from 'react-redux';
import { ThunkDispatch } from 'redux-thunk';
import { Action } from 'redux';
import {
    resetCreateContainerTrackingReasonCodeError,
    resetUpdateContainerTrackingReasonCodeError,
    fetchDemurrageAndDetentionReasonCodes,
    updateDemurrageAndDetentionReasonCodes,
    deleteDemurrageAndDetentionReasonCodes,
    createDemurrageAndDetentionReasonCodes
} from 'actions/settings';
import { IStore } from 'reducers/index';
import { ConfirmModal } from 'components/confirm-modal';
import { EditReasonCodeModal } from 'modules/settings/shared/EditReasonCodeModal';
import { cloneDataWithDisplayNames } from 'utilities/tables';

interface IMapStateToProps {
    isLoading: boolean;
    reasonCodes: IReasonCode[];
    createDndReasonCodeError: string;
    updateReasonCodeError: string;
}

interface IMapDispatchToProps {
    fetchDemurrageAndDetentionReasonCodes: () => Promise<void>;
    createDemurrageAndDetentionReasonCodes: (body: any) => Promise<void>;
    deleteDemurrageAndDetentionReasonCodes: (id: number) => Promise<void>;
    resetCreateContainerTrackingReasonCodeError: () => void;
    updateDemurrageAndDetentionReasonCodes: (body: any) => Promise<void>;
    resetUpdateReasonCodeError: () => void;
}

interface IReasonCodesTable extends IMapStateToProps, IMapDispatchToProps {
    isLoading: boolean;
    data: IReasonCode[];
}

interface IReasonCodesTableState {
    isEditConfigurationModalOpened: boolean;
    editRowKey: string | null;
    editedItem: any;
    createNewReasonCodeModalIsOpen: boolean;
    isConfirmModalOpen: boolean;
}

export class _ReasonCodesTable extends React.Component<IReasonCodesTable, IReasonCodesTableState> {
    public dataTable = React.createRef<any>();

    public state: IReasonCodesTableState = {
        isEditConfigurationModalOpened: false,
        editRowKey: null,
        editedItem: null,
        createNewReasonCodeModalIsOpen: false,
        isConfirmModalOpen: false
    };

    public componentDidMount() {
        this.props.fetchDemurrageAndDetentionReasonCodes();
    }

    public render() {
        const data = cloneDataWithDisplayNames(this.props.data)
        return (
            <React.Fragment>
                {this.props.isLoading && <LoadingAnimation />}
                <DataTable
                    data={data}
                    withCheckboxes={true}
                    expandable={false}
                    selectVariant="single"
                    columns={[
                        ColumnFactory.standardColumn('reasonCodeDisplayName', 'Reason code'),
                        ColumnFactory.standardColumn('groupDisplayName', 'Group'),
                    ]}
                    actions={{
                        primaryActionIsMoreBtn: false,
                        primaryActionLabel: 'Add new',
                        primaryAction: this.toggleCreateReasonCodeModal,
                        parentContextualActions: [
                            { isMoreBtn: false, label: 'Edit', action: this.toggleEditModal },
                            { isMoreBtn: false, label: 'Delete', action: this.toggleConfirmModal },
                        ]
                    }}
                    reference={this.dataTable}
                    tableName="SettingsReasonCodeCTTable"
                    showPagination={true}
                />
                {this.state.createNewReasonCodeModalIsOpen && <CreateNewReasonCodeModal
                    title="Create reason code"
                    visible={this.state.createNewReasonCodeModalIsOpen}
                    errorMessage={this.props.createDndReasonCodeError}
                    resetErrorMessage={this.props.resetCreateContainerTrackingReasonCodeError}
                    closeModal={this.toggleCreateReasonCodeModal}
                    saveFunction={this.createNewReasonCode}
                    reasonCodes={this.props.reasonCodes}
                    hasCommentMode={false}
                />}
                {this.state.isEditConfigurationModalOpened && <EditReasonCodeModal
                    title="Edit reason code"
                    visible={this.state.isEditConfigurationModalOpened}
                    closeModal={this.toggleEditModal}
                    saveFunction={this.updateSettings}
                    initialValues={this.getRowToEdit()}
                    reasonCodes={this.props.reasonCodes}
                    hasCommentMode={false}
                    error={this.props.updateReasonCodeError}
                    resetError={this.props.resetUpdateReasonCodeError}
                />}
                {this.state.isConfirmModalOpen && <ConfirmModal
                    visible={this.state.isConfirmModalOpen}
                    title="Delete reason code confirmation"
                    closeModal={this.toggleConfirmModal}
                    onConfirm={this.removeSettings}
                >
                <p>Are you sure?</p>
                </ConfirmModal>}
            </React.Fragment>
        );
    }

    private createNewReasonCode = async (configuration: any) => {
        try {
            const newReasonCode = {
                code: configuration.ReasonCode,
                group: configuration.Group,
                commentMode: configuration.commentMode,
            };
            await this.props.createDemurrageAndDetentionReasonCodes(newReasonCode);
            await this.toggleCreateReasonCodeModal();
            await this.props.fetchDemurrageAndDetentionReasonCodes();
        } catch (error) {
            console.error(error);
        }
    };

    private toggleEditModal = (key?: string) => {
        const selectedItems = this.dataTable.current ? this.dataTable.current.getSelectedItems() : [];
        if (selectedItems[0]) {
            const rowToEdit = selectedItems[0];

            this.setState((state: IReasonCodesTableState) => ({
                isEditConfigurationModalOpened: !state.isEditConfigurationModalOpened,
                editRowKey: key || null,
                editedItem: rowToEdit ? rowToEdit : null
            }))
        }
    };

    private updateSettings = async (configuration: any) => {
        await this.props.updateDemurrageAndDetentionReasonCodes({
            id: this.getId(),
            code: configuration.ReasonCode,
            group: configuration.Group,
            commentMode: configuration.commentMode,
        });
        await this.toggleEditModal();
        await this.props.fetchDemurrageAndDetentionReasonCodes();
    };

    private removeSettings = async () => {
        await this.props.deleteDemurrageAndDetentionReasonCodes(this.getId());
        await this.toggleConfirmModal();
        await this.dataTable.current.hideContextRibbon();
        await this.props.fetchDemurrageAndDetentionReasonCodes();
    };

    private toggleCreateReasonCodeModal = () => this.setState((state) => ({
        createNewReasonCodeModalIsOpen: !state.createNewReasonCodeModalIsOpen
    }));

    private toggleConfirmModal = () => this.setState((state) => ({
        isConfirmModalOpen: !state.isConfirmModalOpen
    }));

    private getId = () => {
        const selectedItems = this.dataTable.current ? this.dataTable.current.getSelectedItems() : [];
        return selectedItems[0].id;
    };

    private getRowToEdit = () => {
        const selectedItems = this.dataTable.current ? this.dataTable.current.getSelectedItems() : [];
        return {
            ReasonCode: selectedItems[0].reasonCode,
            Group: selectedItems[0].group,
            commentMode: selectedItems[0].commentMode,
        };
    };
}

const mapStateToProps = (state: IStore): IMapStateToProps => ({
    isLoading: state.settings.fetchReasonCodeIsLoading,
    reasonCodes: state.settings.dndReasonCodes,
    createDndReasonCodeError: state.settings.createDndReasonCodesError,
    updateReasonCodeError: state.settings.updateDndReasonCodesError,
});

const mapDispatchToProps = (dispatch: ThunkDispatch<any, any, Action>): IMapDispatchToProps => ({
    fetchDemurrageAndDetentionReasonCodes: () => dispatch(fetchDemurrageAndDetentionReasonCodes()),
    createDemurrageAndDetentionReasonCodes: (body: any) => dispatch(createDemurrageAndDetentionReasonCodes(body)),
    deleteDemurrageAndDetentionReasonCodes: (id: number) => dispatch(deleteDemurrageAndDetentionReasonCodes(id)),
    resetCreateContainerTrackingReasonCodeError: () => dispatch(resetCreateContainerTrackingReasonCodeError()),
    updateDemurrageAndDetentionReasonCodes: (body: any) => dispatch(updateDemurrageAndDetentionReasonCodes(body)),
    resetUpdateReasonCodeError: () => dispatch(resetUpdateContainerTrackingReasonCodeError()),
});

export const ReasonCodesTable = connect(mapStateToProps, mapDispatchToProps)(_ReasonCodesTable);