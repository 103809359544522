import React, { Component } from 'react';
import { LoadingAnimation } from 'components/loading';
import { ColumnFactory } from 'components/data-table/ColumnFactory';
import { downloadFile } from 'utilities/download';
import { ThunkDispatch } from 'redux-thunk';
import { Action } from 'redux';
import { exportCustomsToExcel } from 'actions/export';
import { connect } from 'react-redux';
import { DataTable } from 'components/data-table/DamcoTable';
import { UpdateHBLCustomsModal } from './UpdateHBLCustomsModal';
import { NotifyCustomerModal } from 'modules/modals/components/NotifyCustomerModalForCustoms';
import { uniqWith, isEqual, xorWith } from 'lodash';
import { fetchHBLFCRDetailsOnList } from 'actions/customs';
import {
  mapStatusToStringForCustoms,
  mapStatusToStringForCT,
} from 'constants/statuses';
// tslint:disable-next-line: no-submodule-imports
import { withPermissions, IWithPermissions } from 'src/HOC/withPermissions';
import { BUSINNESS_FUNCTIONS } from 'constants/roles';
import { getArrayOfButtons } from 'utilities/util';
import { IStore } from 'reducers/index';
import { Dialog } from 'components/dialog';

export interface ICustomsTable {
  selected: boolean;
}

interface IMapDispatchToProps {
  exportCustomsToExcel: (isAir: boolean) => Promise<any>;
  fetchHBLFCRDetails: (id: string, voyageId: string) => Promise<any>;
}

interface ICustomsTableOwnProps {
  data: any;
  isLoading: boolean;
  pageCount: number;
  fetchBls: any;
  refreshFunction: () => Promise<void>;
}

interface IOceanClearanceTableProps
  extends IWithPermissions,
    ICustomsTableOwnProps,
    IMapDispatchToProps {}

interface IOceanClearanceTableState {
  isEditMultipleContainersModalOpened: boolean;
  isNotifyCustomerModalOpened: boolean;
  details: any[];
  isErrorDialogOpened: boolean;
  errorMessage: string;
}

class _OceanClearance extends Component<
  IOceanClearanceTableProps,
  IOceanClearanceTableState
> {
  public dataTable = React.createRef<any>();

  public state = {
    isEditMultipleContainersModalOpened: false,
    isNotifyCustomerModalOpened: false,
    details: [],
    isErrorDialogOpened: false,
    errorMessage: 'Some error occurred',
  };

  public componentDidUpdate(prevProps: IOceanClearanceTableProps) {
    const isArrayEqual = (x: any[], y: any[]) =>
      xorWith(x, y, isEqual).length === 0;

    if (!isArrayEqual(this.props.data, prevProps.data)) {
      this.setState({ isErrorDialogOpened: false });
    }
  }

  public render() {
    const data = this.props.data.map((obj: any, index: number) => ({
      ...obj,
      id: index,
      statusDisplayName: mapStatusToStringForCT(obj.status),
      children: obj.hblEquipmentData.map((child: any, localIndex: number) => ({
        ...child,
        id: index.toFixed(1) + localIndex / 10,
        hblNumberDisplayName: [
          child.hblNumber,
          `/customs/ocean-clearance/${child.hblId}/${obj.voyageId}`,
        ],
        customsStatusDisplayName: mapStatusToStringForCustoms(
          child.customsStatus
        ),
        parentNode: 0,
        voyageId: obj.voyageId,
      })),
    }));

    return (
      <React.Fragment>
        {this.props.isLoading && <LoadingAnimation />}
        {this.state.isErrorDialogOpened && (
          <Dialog
            isVisible={this.state.isErrorDialogOpened}
            message={this.state.errorMessage}
            closeDialog={this.toggleErrorDialog}
          />
        )}
        <DataTable
          data={data}
          columns={[
            ColumnFactory.standardColumn(
              'vesselNameDisplayName',
              'Vessel name'
            ),
            ColumnFactory.standardColumn(
              'voyageReferenceDisplayName',
              'Voyage'
            ),
            ColumnFactory.standardColumn('polDisplayName', 'POL'),
            ColumnFactory.standardColumn('podDisplayName', 'POD'),
            ColumnFactory.standardColumn('newETADisplayName', 'New ETA'),
            ColumnFactory.standardColumn('ataDisplayName', 'ATA'),
            ColumnFactory.standardColumn(
              'daysTillETADisplayName',
              'Days till ETA'
            ),
            ColumnFactory.standardColumn('statusDisplayName', 'Status'),
          ]}
          subColumns={[
            ColumnFactory.linkedColumn(
              'hblNumberDisplayName',
              'HBL / FCR number'
            ),
            ColumnFactory.standardColumn('serviceDisplayName', 'Service'),
            ColumnFactory.standardColumn(
              'totalPackagesDisplayName',
              'Total packages'
            ),
            ColumnFactory.standardColumn(
              'packagesTypeDisplayName',
              'Piece type'
            ),
            ColumnFactory.standardColumn('weightDisplayName', 'Weight'),
            ColumnFactory.standardColumn('volumeDisplayName', 'Volume'),
            ColumnFactory.standardColumn('brokerDisplayName.name', 'Broker'),
            ColumnFactory.standardColumn(
              'customsStatusDisplayName',
              'Customs status'
            ),
          ]}
          expandable={true}
          withCheckboxes={true}
          selectVariant="multiple"
          actions={{
            primaryActionIsMoreBtn: false,
            primaryActionLabel: 'Export to excel',
            primaryAction: this.exportToExcel,
            childContextualActions: getArrayOfButtons(this.props.permissions, [
              {
                isMoreBtn: false,
                label: 'Update clearance details',
                action: this.checkIfCanUpdateClearanceDetails,
              },
              {
                isMoreBtn: false,
                label: 'Notify customer',
                action: this.toggleNotifyCustomerModal,
              },
            ]),
          }}
          reference={this.dataTable}
          tableName="CustomsOceanClearanceList"
          manualPagination={true}
          fetchData={this.props.fetchBls}
          pageCount={this.props.pageCount}
          showColumnOptions={true}
        />
        {this.state.isEditMultipleContainersModalOpened && (
          <UpdateHBLCustomsModal
            visible={this.state.isEditMultipleContainersModalOpened}
            closeModal={this.toggleEditMultipleContainersModal}
            hblId={this.getIds().hblId}
            voyageId={this.getIds().voyageId}
            data={this.state.details}
            refreshFunction={this.props.refreshFunction}
          />
        )}
        {this.state.isNotifyCustomerModalOpened && (
          <NotifyCustomerModal
            visible={this.state.isNotifyCustomerModalOpened}
            closeModal={this.toggleNotifyCustomerModal}
            blId={this.getIds().blId}
            isAir={false}
            refreshFunction={this.props.refreshFunction}
          />
        )}
      </React.Fragment>
    );
  }

  private exportToExcel = async () => {
    const res = await this.props.exportCustomsToExcel(false);
    await downloadFile(res, 'application/zip', 'Data.zip');
  };

  private toggleEditMultipleContainersModal = async () => {
    const selectedItems = this.dataTable.current
      ? this.dataTable.current.getSelectedItems()
      : [];

    if (!this.state.isEditMultipleContainersModalOpened) {
      const checkSelectedItems = uniqWith(
        selectedItems.map((hbl: any) => ({
          cL1_Broker: hbl.cL1_Broker,
          cL1_Comments: hbl.cL1_Comments,
          cL1_Status: hbl.cL1_Status,
          cL1_StatusDate: hbl.cL1_StatusDate,
          cL1_Type: hbl.cL1_Type,
          cL2_Broker: hbl.cL2_Broker,
          cL2_Comments: hbl.cL2_Comments,
          cL2_CustomsClearedDate: hbl.cL2_CustomsClearedDate,
          cL2_Status: hbl.cL2_Status,
          cL2_Type: hbl.cL2_Type,
        })),
        isEqual
      );

      if (checkSelectedItems.length === 1) {
        const fetchDetails = await Promise.all(
          this.getIds().hblId.map((_: any, index: number) => {
            return this.props.fetchHBLFCRDetails(
              this.getIds().hblId[index],
              this.getIds().voyageId[index]
            );
          })
        );

        this.setState({
          isEditMultipleContainersModalOpened: true,
          details: fetchDetails,
        });
      }
    } else {
      this.setState({
        isEditMultipleContainersModalOpened: false,
      });
    }
  };

  private toggleNotifyCustomerModal = () =>
    this.setState((state) => ({
      isNotifyCustomerModalOpened: !state.isNotifyCustomerModalOpened,
    }));

  private getIds = () => {
    const selectedItems = this.dataTable.current
      ? this.dataTable.current.getSelectedItems()
      : [];
    const getValues = (property: string) =>
      selectedItems.map((item: any) => {
        return item[property];
      });

    return {
      hblId: getValues('hblId'),
      blId: getValues('blId'),
      voyageId: getValues('voyageId'),
    };
  };

  private selectedItemsAsUniq = () => {
    const selectedItems = this.dataTable.current
      ? this.dataTable.current.getSelectedItems()
      : [];
    return uniqWith(
      selectedItems.map((hbl: any) => {
        return {
          cL1_Broker: hbl.cL1_Broker,
          cL1_Comments: hbl.cL1_Comments,
          cL1_Status: hbl.cL1_Status,
          cL1_StatusDate: hbl.cL1_StatusDate,
          cL1_Type: hbl.cL1_Type,
          cL2_Broker: hbl.cL2_Broker,
          cL2_Comments: hbl.cL2_Comments,
          cL2_CustomsClearedDate: hbl.cL2_CustomsClearedDate,
          cL2_Status: hbl.cL2_Status,
          cL2_Type: hbl.cL2_Type,
        };
      }),
      isEqual
    );
  };

  private toggleErrorDialog = () =>
    this.setState((state) => ({
      isErrorDialogOpened: !state.isErrorDialogOpened,
    }));

  private checkIfCanUpdateClearanceDetails = () => {
    if (this.selectedItemsAsUniq().length === 1) {
      this.setState({ isErrorDialogOpened: false });
      this.toggleEditMultipleContainersModal();
    } else {
      this.setState({
        isErrorDialogOpened: true,
        errorMessage: 'Selected HBLs are not equal',
      });
    }
  };
}

const ROLES: BUSINNESS_FUNCTIONS[] = [
  BUSINNESS_FUNCTIONS.OCEAN_CUSTOMS_UPDATE_HBL_CUSTOMS_DETAILS,
  BUSINNESS_FUNCTIONS.OCEAN_CUSTOMS_NOTIFY_CUSTOMER,
];

const mapDispatchToProps = (
  dispatch: ThunkDispatch<any, any, Action>
): IMapDispatchToProps => ({
  exportCustomsToExcel: (isAir: boolean) =>
    dispatch(exportCustomsToExcel(isAir)),
  fetchHBLFCRDetails: (id: string, voyageId: string) =>
    dispatch(fetchHBLFCRDetailsOnList(id, voyageId)),
});

const __OceanClearanceTable = connect<
  undefined,
  IMapDispatchToProps,
  ICustomsTableOwnProps,
  IStore
>(
  undefined,
  mapDispatchToProps
)(_OceanClearance);
export const OceanClearanceTable = withPermissions(
  __OceanClearanceTable,
  ROLES
) as any;
