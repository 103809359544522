import styled from 'styled-components';
import {Link} from 'react-router-dom';

export const DropdowContainer: any = styled.div`
    position: absolute;
    z-index: 999;
    top: calc(100% - 2px);
    right: 0;
    border: 1px solid #bfc0c2;
    border-top: 0;
    min-width: 300px;
    background: #FFF;
`;

export const DropdownUserArea: any = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    padding: 15px 20px;
    border-bottom: 1px solid #bfc0c2;
`;

export const UserImage: any = styled.div`
    width: 32%;

    i {
        font-size: 64px;
    }
`;

export const UserDesc: any = styled.div`
    width: 68%;
`;

export const Text: any = styled.p`
    margin: 0;
`;

export const ButtonsArea: any = styled.div`
    display: flex;
    justify-content: space-between;
    background-color: #e7e9ea;
    position: relative;
    bottom: 0;
    padding: 1rem;
    width: 100%;
    height: 4rem;
    border-top: 0.0625rem solid #bfc0c2;
`;

export const Button: any = styled(Link)`
    width: 33.333%;
    background: rgba(134, 143, 152, 0.2);
    border: 0;
    padding: 15px 10px;
    transition: .2s ease-in-out;
    white-space: nowrap;
    font-size: 14px;
    display: flex;
    justify-content: center;
    color: rgba(134, 143, 152, 0.8);

    &:hover {
        background: rgba(134, 143, 152, 0.4);
        color: white;
        transition: .2s ease-in-out;
        text-decoration: none;
    }

    &:focus {
        outline: none;
    }
`;