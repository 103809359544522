import React from 'react';
import { BUSINNESS_FUNCTIONS } from 'constants/roles';
import { IStore } from 'reducers/index';
import { connect } from 'react-redux';
import { hasRequiredRole, IRoles } from 'utilities/roles';

interface IMapStateToProps {
    roles: IRoles[];
}

export interface IWithPermissions {
    permissions: boolean[];
}

export const withPermissions = <P extends any>(WrappedComponent: React.ComponentType<P & IWithPermissions>, roles: BUSINNESS_FUNCTIONS[]): React.ComponentType<P & IWithPermissions> => {
    class _withPermissions extends React.Component<P & IMapStateToProps> {
        public render() {
            return (
                <WrappedComponent {...this.props} permissions={this.hasPermission()} />
            );
        }

        private hasPermission = () => roles.map((func: BUSINNESS_FUNCTIONS) => {
            if(hasRequiredRole([func], this.props.roles)) {
                return true;
            }
            return false;
        });
    }

    const mapStateToProps = (state: IStore): IMapStateToProps => ({
        roles: state.user.roles,
    });

    return connect<IMapStateToProps, {}, P & IWithPermissions>(mapStateToProps)(_withPermissions);
};