import React, { Component } from 'react';
import { connect } from 'react-redux';
import { ReasonCodesTable } from 'modules/settings/delivery-planning/containers/ReasonCodesTable';
import { ThunkDispatch } from 'redux-thunk';
import { Action } from 'redux';
import { fetchDeliveryPlanningReasonCodes, updateDeliveryPlanningReasonCode } from 'actions/settings';
import { IStore } from 'reducers/index';
import { IReasonCode } from 'reducers/settings/models';

interface IMapStateToProps {
    isLoading: boolean;
    reasonCodes: IReasonCode[];
    createDeliveryPlanningReasonCodeError: string;
}

interface IMapDispatchToProps {
    fetchDeliveryPlanningReasonCodes: (group?: string) => Promise<void>;
    updateDeliveryPlanningReasonCode: (id: number, body: any) => Promise<void>;
}

interface IReasonCodes extends IMapStateToProps, IMapDispatchToProps {};

interface IReasonCodesState {
    createNewReasonCodeModalIsOpen: boolean;
}

class _ReasonCodes extends Component<IReasonCodes, IReasonCodesState> {
    public state: IReasonCodesState = {
        createNewReasonCodeModalIsOpen: false
    };

    public componentDidMount() {
        this.props.fetchDeliveryPlanningReasonCodes();
    }

    public render() {
        return(
            <ReasonCodesTable
                isLoading={this.props.isLoading}
                data={this.props.reasonCodes}
                refreshSettings={this.props.fetchDeliveryPlanningReasonCodes}
                editFunction={this.props.updateDeliveryPlanningReasonCode}
                createDeliveryPlanningReasonCodeError={this.props.createDeliveryPlanningReasonCodeError}
            />
        );
    }
}

const mapStateToProps = (state: IStore): IMapStateToProps => ({
    isLoading: state.settings.fetchReasonCodeIsLoading,
    reasonCodes: state.settings.deliveryPlanningReasonCodes,
    createDeliveryPlanningReasonCodeError: state.settings.createDeliveryPlanningReasonCodeError,
});

const mapDispatchToProps = (dispatch: ThunkDispatch<any, any, Action>): IMapDispatchToProps => ({
    fetchDeliveryPlanningReasonCodes: (group?: string) => dispatch(fetchDeliveryPlanningReasonCodes(group)),
    updateDeliveryPlanningReasonCode: (id: number, body: any) => dispatch(updateDeliveryPlanningReasonCode(id, body)),
});

export const ReasonCodes = connect(mapStateToProps, mapDispatchToProps)(_ReasonCodes);