import React from 'react';
import { connect } from 'react-redux';
import { InjectedFormProps, reduxForm, reset, formValueSelector } from 'redux-form';
import { ModalType } from 'components/modal-screen/ModalType';
import { ModalScreen } from 'components/modal-screen';
import { TripleGrid } from 'components/grid/triple-grid';
import { DoubleGrid } from 'components/grid/double-grid';
import { FormSelect } from 'components/select/FormSelect';
import { FormInput } from 'components/input/FormInput';
import { Input } from 'components/input';
import { FormDateInput } from 'components/date-input/FormDateInput';
import { Action, compose } from 'redux';
import { addEquipmentDeliveries, editEquipmentDeliveries, getEquipmentDeliveries } from 'actions/planning';
import { ThunkDispatch } from 'redux-thunk';
import { LoadingAnimation } from 'components/loading';
import { IStore } from 'reducers/index';
import { TRANSPORT_MODE_OPTIONS, TRANSPORT_TYPE_OPTIONS } from 'constants/transport';
import { FormTextArea } from 'components/textarea/FormTextArea';
import { addNewComment, IAddComment } from 'actions/timeline';
import { DeliveryPlanningTypes } from 'constants/comment';
import { fetchDeliveryPlanningHaulierSettings } from 'actions/settings';
import { ISettingsModel } from 'reducers/settings/models';
import { IOption } from 'components/select/Select';
import { Dialog } from 'components/dialog';
import { getHaulierText } from 'utilities/util';
import { Capability } from 'components/timeline';

interface IAddTransportationRowModal {
    visible: boolean;
    closeModal: () => void;
    ids: number[];
    initialValues?: any;
    refetchFunction?: any;
    isAir?: boolean;
    fromPlanning: boolean;
}

interface IReduxProps extends IMapStateToProps, IDispatch {
}

interface IMapStateToProps {
    isLoading: boolean;
    deliveryPlanningHaulierSettings: ISettingsModel[];
    transportMode: string;
    transportType: string
    fetchDeliveryPlanningHaulierSettingsPending: boolean;
}

interface IDispatch {
    addEquipmentDeliveries: (id: number, fields: any, isAir: boolean) => Promise<void>;
    editEquipmentDeliveries: (equipmentId: number, deliveryId: number, fields: any, isAir: boolean) => Promise<void>;
    getEquipmentDeliveries: (id: number, isAir: boolean) => Promise<void>;
    addNewComment: (comment: IAddComment, isAir: boolean) => Promise<void>;
    resetForm: () => void;
    fetchDeliveryPlanningHaulierSettings: () => void;
}

interface IState {
    errorMessage: string | null;
}

type Props = InjectedFormProps<{}, IAddTransportationRowModal> & IAddTransportationRowModal & IReduxProps;

class _AddTransportationRowModal extends React.Component<Props, IState> {
    public static FORM_NAME = 'ADD_TRANSPORTATION_ROW_MODAL';

    public state: IState = {
        errorMessage: null,
    }

    public componentDidMount() {
        this.props.fetchDeliveryPlanningHaulierSettings();
    }

    public componentDidUpdate(prevProps: Props) {
        if(prevProps.visible && !this.props.visible) {
            this.props.resetForm();
        }

        if (this.props.transportMode !== prevProps.transportMode) {
            const options = TRANSPORT_TYPE_OPTIONS[parseInt(this.props.transportMode, 10)];
            this.props.change('transportType', options ? options[0].value : undefined)
        }
    }

    public render() {
        const isEdit = this.props.initialValues && !!Object.keys(this.props.initialValues).length;
        const onSubmit =  this.getSubmitFunction(isEdit);
        const isTOSent = this.props.initialValues && 'isTransportOrderSent' in this.props.initialValues;

        return (
            <form>
                {(this.props.isLoading || this.props.fetchDeliveryPlanningHaulierSettingsPending) && <LoadingAnimation />}
                <ModalScreen
                    title={this.props.fromPlanning ? 'Update estimates' : 'Update actuals'}
                    visible={this.props.visible}
                    modalType={ModalType.xl()}
                    closeModal={this.props.closeModal}
                    primaryButtonTitle="Done"
                    primaryButtonFunc={this.props.handleSubmit(onSubmit)}
                    primaryButtonId="submit-add-transportation-details-button"
                    primaryButtonIsDisabled={!this.props.valid || this.props.transportMode === undefined || this.props.transportMode === 'Select value'}
                    secondaryButtonTitle="Cancel"
                    secondaryButtonFunc={this.props.closeModal}
                    id="add-edit-transportation-details-modal"
                >
                    <Dialog
                        message={this.state.errorMessage ? this.state.errorMessage : ''}
                        dialogType={500}
                        isVisible={!!this.state.errorMessage}
                        closeDialog={() => this.setState({ errorMessage: null })}
                    />

                    <TripleGrid>
                        <FormSelect
                            name="transportMode"
                            label="Transport mode"
                            options={this.getTransportMode()}
                        />
                        <FormSelect
                            name="transportType"
                            label="Transport type"
                            options={TRANSPORT_TYPE_OPTIONS[parseInt(this.props.transportMode, 10)] || []}
                        />
                        <FormSelect
                            label="Haulier"
                            name="haulier"
                            options={this.getHauliers()}
                        />
                    </TripleGrid>
                    <TripleGrid>
                        <FormInput
                            label="Pickup location"
                            name="pickupLocationCode"
                            id="pickup-location"
                        />
                        <FormDateInput
                            label="Pickup date"
                            id="pickup-date"
                            name="estimatedPickupDate"
                            withTime={this.props.isAir ? true : false}
                        />
                    </TripleGrid>
                    <TripleGrid>
                        <FormInput
                            label="Drop-off location"
                            name="dropOffLocationCode"
                        />
                        <FormDateInput
                            label="Drop-off date"
                            id="drop-off-date"
                            name="estimatedDropOffDate"
                            withTime={this.props.isAir ? true : false}
                        />
                    </TripleGrid>
                    {isTOSent && <TripleGrid>
                        <Input
                            disabled={true}
                            input={{ value: this.props.initialValues.isTransportOrderSent ? 'Y' : 'N' }}
                            label="TO sent"
                        />
                    </TripleGrid>}
                    <TripleGrid>
                        <FormInput
                            label="Delivery reference"
                            name="deliveryReference"
                        />
                    </TripleGrid>
                    <DoubleGrid>
                        <FormTextArea
                            label="Comment"
                            name="comment"
                        />
                    </DoubleGrid>
                </ModalScreen>
            </form>
        );
    }

    private getTransportMode = (): IOption[] => {
        return [
            { value: null, optionText: 'Select value' },
            ...TRANSPORT_MODE_OPTIONS
        ];
    };

    private getHauliers = (): IOption[] => {
        const {deliveryPlanningHaulierSettings: hauliers} = this.props;
        const options: IOption[] = [{ optionText: 'Select value...', value: '' }];

        hauliers.forEach((haulier) => {
            options.push({
                value: haulier.id,
                optionText: getHaulierText(haulier)
            });
        });

        return options;
    }

    private getSubmitFunction = (isEdit: boolean) => {
        if(isEdit) {
            return this.onUpdatePlaningSubmit;
        }
        return this.onAddSubmit;
    };

    private onAddSubmit = async (fields: any) => {
        try {
            await Promise.all(this.props.ids.map(async (id: number) => {
                await this.props.addEquipmentDeliveries(id, {
                    deliveries: [{
                        deliveryReference: fields.deliveryReference,
                        dropOffLocationCode: fields.dropOffLocationCode,
                        estimatedDropOffDate: fields.estimatedDropOffDate,
                        estimatedPickupDate: fields.estimatedPickupDate,
                        haulier: fields.haulier,
                        pickupLocationCode: fields.pickupLocationCode,
                        transportMode: fields.transportMode,
                        transportType: fields.transportType,
                        deliveryId: id
                    }],
                }, !!this.props.isAir);
                if(fields.comment) {
                    await this.props.addNewComment({
                        objectType: DeliveryPlanningTypes.Equipment,
                        content: fields.comment,
                        objectId: id,
                    }, !!this.props.isAir);
                }
            }));
            this.props.getEquipmentDeliveries(this.props.ids[0], !!this.props.isAir);
            this.props.resetForm();
            this.props.closeModal();
        } catch (errorMessage) {
            this.setState({ errorMessage });
        }
    };

    private onUpdatePlaningSubmit = async (fields: any) => {
        try {
            await Promise.all(this.props.ids.map(async (id: number) => {
                await this.props.editEquipmentDeliveries(id, this.props.initialValues.id, { delivery: {
                    deliveryReference: fields.deliveryReference,
                    dropOffLocationCode: fields.dropOffLocationCode,
                    estimatedDropOffDate: fields.estimatedDropOffDate,
                    estimatedPickupDate: fields.estimatedPickupDate,
                    haulier: fields.haulier,
                    pickupLocationCode: fields.pickupLocationCode,
                    transportMode: fields.transportMode,
                    transportType: fields.transportType,
                    deliveryId: id                    
                }}, !!this.props.isAir);
            }));

            if(this.props.refetchFunction) {
                this.props.resetForm();
                this.props.closeModal();
                this.props.refetchFunction();
            } else {
                this.props.getEquipmentDeliveries(this.props.ids[0], !!this.props.isAir);
                this.props.resetForm();
                this.props.closeModal();
            }
        } catch (errorMessage) {
            this.setState({ errorMessage })
        }
    };
}

const mapStateToProps = (state: IStore): IMapStateToProps => {
    const {
        isAddEquipmentDeliveriesPending,
        isEditEquipmentDeliveriesPending
    } = state.planning;
    const {
        addCommentLoading,
    } = state.timeline;
    const {
        deliveryPlanningHaulierSettings,
        fetchDeliveryPlanningHaulierSettingsPending,
    } = state.settings;
    const selector = formValueSelector(_AddTransportationRowModal.FORM_NAME);

    return {
        deliveryPlanningHaulierSettings,
        isLoading: isAddEquipmentDeliveriesPending || addCommentLoading || isEditEquipmentDeliveriesPending,
        fetchDeliveryPlanningHaulierSettingsPending,
        transportMode: selector(state, 'transportMode'),
        transportType: selector(state, 'transportType'),
    }
};

const mapDispatchToProps = (dispatch: ThunkDispatch<any, any, Action>): IDispatch => ({
    addEquipmentDeliveries: (id: number, fields: any, isAir: boolean) => dispatch(addEquipmentDeliveries(id, fields, isAir)),
    editEquipmentDeliveries: (equipmentId: number, deliveryId: number, fields: any, isAir: boolean) => dispatch(editEquipmentDeliveries(equipmentId, deliveryId, fields, isAir)),
    getEquipmentDeliveries: (id: number, isAir: boolean) => dispatch(getEquipmentDeliveries(id, isAir)),
    addNewComment: (comment: IAddComment, isAir: boolean) => dispatch(addNewComment(comment, isAir, Capability.DeliveryPlanning)),
    resetForm: () => dispatch(reset(_AddTransportationRowModal.FORM_NAME)),
    fetchDeliveryPlanningHaulierSettings: () => dispatch(fetchDeliveryPlanningHaulierSettings(true)),
});

export const DeliveryAddTransportationRowModal = compose(
    reduxForm<{}, IAddTransportationRowModal>({
        form: _AddTransportationRowModal.FORM_NAME,
        enableReinitialize: true,
    }),
    connect(mapStateToProps, mapDispatchToProps),
)(_AddTransportationRowModal);
