import React from 'react';
import { QuarterGrid } from 'components/grid/quarter-grid';
import { OneStatus } from 'components/status-row/OneStatus';
import { StatusRow } from 'components/status-row';

interface IEmptyReturnDocumentsRow {
    documentsPARNRequired?: boolean;
    documentsPARNNumber?: string;
    documentsCMRRequired?: boolean;
    documentsCMRNumber?: string;
    destinationPort?: string;
}

export const EmptyReturnDocumentsRow: React.SFC<IEmptyReturnDocumentsRow> = (props: IEmptyReturnDocumentsRow) => (
    <StatusRow title="Documents">
        <QuarterGrid>
            <OneStatus label="PARN Received">
                {props.documentsPARNRequired ? 'Y' : 'N'}
            </OneStatus>
            <OneStatus label="CMR Required">
                {props.documentsCMRRequired ? 'Y' : 'N'}
            </OneStatus>
        </QuarterGrid>
    </StatusRow>
);
