import React, { FunctionComponent } from 'react';
import { OneStatus } from 'components/status-row/OneStatus';
import { StatusRow } from 'components/status-row';
import { QuarterGrid } from 'components/grid/quarter-grid';
import { formatDate, formatValueIfNullOrUndefined } from 'utilities/util';
import { DataTable } from 'components/data-table/DamcoTable';
import { ColumnFactory } from 'components/data-table/ColumnFactory';

interface IReleaseRow {
    cbLs?: any[];
    blReleaseStatus?: string;
    emptyReturnLocation?: string;
    pin?: string;
    pinExpiryDate?: string;
    releasePickupLocation?: string;
}

export const ReleaseRow: FunctionComponent<IReleaseRow | any> = (props) => (
    <StatusRow title="Release">
        <DataTable
            data={props.cbLs}
            columns={[
                ColumnFactory.standardColumn('carrierName', 'Carrier'),
                ColumnFactory.standardColumn('number', 'CBL number'),
            ]}
            withCheckboxes={false}
            tableName="ReleaseTable"
            reference={null as any}
        />
        <QuarterGrid>
            <OneStatus label="Release status">
                {formatValueIfNullOrUndefined(props.blReleaseStatus)}
            </OneStatus>
            <OneStatus label="PIN">
                {formatValueIfNullOrUndefined(props.pin)}
            </OneStatus>
            <OneStatus label="Expiry date">
                {formatDate(props.pinExpiryDate)}
            </OneStatus>
            <OneStatus label="Pickup location">
                {formatValueIfNullOrUndefined(props.releasePickupLocation)}
            </OneStatus>
        </QuarterGrid>
        <QuarterGrid>
            <OneStatus label="Empty return">
                {formatValueIfNullOrUndefined(props.emptyReturnLocation)}
            </OneStatus>
        </QuarterGrid>
    </StatusRow>
);
