import React from 'react';
import { ModalType } from 'components/modal-screen/ModalType';
import { ModalScreen } from 'components/modal-screen';

interface IAreYouSureModal {
    visible: boolean;
    onClose: () => void;
    onAccept: () => Promise<void>;
}

export class AreYouSureModal extends React.Component<IAreYouSureModal> {
    public render() {
        return (
            <ModalScreen
                title="Are you sure"
                modalType={ModalType.s()}
                primaryButtonTitle="Yes"
                primaryButtonFunc={this.onAccept}
                primaryButtonId="submit-are-you-sure"
                secondaryButtonTitle="Cancel"
                secondaryButtonFunc={this.props.onClose}
                visible={this.props.visible}
                closeModal={this.props.onClose}
                id="are-you-sure-modal"
            >
                Are you sure? This operation is irreversible.
            </ModalScreen>
        )
    }

    private onAccept = () => {
        this.props.onClose();
        this.props.onAccept();
    }
}
