import React from 'react';

interface IHorizontalTab {
    link: {
        linkText: string;
    };
    index: number;
    onClick: () => void;
    active?: boolean;
}

export const HorizontalTab: React.SFC<IHorizontalTab> = (props: IHorizontalTab) => (
    <React.Fragment>
        <input id={`tab-${props.index}`} name="grp" checked={props.active} readOnly={true} type="radio" onClick={props.onClick} />
        <label htmlFor={`tab-${props.index}`}>{props.link.linkText}</label>
    </React.Fragment>
);
