import { IOption } from 'components/select';

export enum CT_STATUSES {
    NEW = 10,
    PENDING_DEPARTURE = 20,
    CHECK_DEPARTURE = 30,
    DEPARTED = 40,
    DELAYED = 50,
    DIVERTED = 60,
    CHECK_ARRIVAL = 70,
    ARRIVED = 80,
    SUBMIT_REASON_CODE = 90
}

export enum CR_STATUSES {
    NOT_DUE = 10,
    REQUEST_RELEASE = 20,
    MISSING_ORIGINAL_BL = 30,
    REQUEST_SENT = 40,
    RELEASE_INCOMPLETE = 50,
    RELEASE_COMPLETE = 60
}

export enum CUSTOMS_STATUSES {
    UPDATE_ENTRY_TYPE = 10,
    UPDATE_CUSTOMS_BROKER = 20,
    PENDING = 25,
    ON_HOLD = 70,
    CUSTOMS_COMPLETED = 110
}

export enum CUSTOMS_RELEASE_STATUSES {
    UPDATE_ENTRY_TYPE = 10,
    UPDATE_CUSTOMS_BROKER = 20,
    PENDING = 25,
    ON_HOLD = 70,
    PRE_CLEARANCE_COMPLETED = 110
}

export enum DP_STATUSES {
    NOT_DUE = 10,
    SEND_DE_RIGGING_REQUEST = 20,
    AVAILABLE_FOR_TRANSPORT = 30,
    UPDATE_REQUESTED_DELIVERY_DATE = 40,
    PRE_ALERT_SENT = 50,
    SUBMIT_TRANSPORT_ORDER = 60,
    SEND_NEW_TRANSPORT_ORDER = 70,
    PLANNING_COMPLETED= 80,
    DELIVERY_FAILED = 100,
    GATE_OUT_OF_PORT = 110,
    ARRIVAL_INTO_INLAND_DEPOT = 120,
    MOVED_TO_STORAGE = 130,
    VEHICLE_CHECKED_INTO_DC = 140,
    ACTUAL_DELIVERY = 150,
    VEHICLE_UNLOADED_DC = 160,
    VEHICLE_CHECKOUT_OF_DC = 170,
    DE_RIGGED = 180,
    EMPTY_RETURN_TO_PORT = 200,
    EMPTY_RETURN_INLAND_DEPOT = 210,
    NON_CONFORMANCE_RECEIVED = 220,
    RESENT = 230,
}

export enum DERIG_STATUSES_ENUM {
    NOT_DUE = 1,
    DUE = 2,
    SENT = 3,
}

export enum CLEARANCE1_STATUSES {
    PRE_CLEARANCE_COMPLETED = 1,
    ON_HOLD = 2,
    IN_PROGRESS = 3
}

export enum CLEARANCE2_STATUSES {
    CUSTOMS_COMPLETED = 1,
    ON_HOLD = 2,
    IN_PROGRESS = 3
}

export const DERIG_STATUSES = {
    [DERIG_STATUSES_ENUM.NOT_DUE]: 'Not due',
    [DERIG_STATUSES_ENUM.DUE]: 'Due',
    [DERIG_STATUSES_ENUM.SENT]: 'Sent',
};

export const DERIG_STATUSES_OPTIONS: IOption[] = [{
    value: DERIG_STATUSES_ENUM.NOT_DUE,
    optionText: 'Not due',
}, {
    value: DERIG_STATUSES_ENUM.DUE,
    optionText: 'Due',
}, {
    value: DERIG_STATUSES_ENUM.SENT,
    optionText: 'Sent',
}];

export const CT_STATUS: any = {
    [CT_STATUSES.NEW]: { text: 'New', value: CT_STATUSES.NEW },
    [CT_STATUSES.PENDING_DEPARTURE]: { text: 'Pending Departure', value: CT_STATUSES.PENDING_DEPARTURE },
    [CT_STATUSES.CHECK_DEPARTURE]: { text: 'Check Departure', value: CT_STATUSES.CHECK_DEPARTURE },
    [CT_STATUSES.DEPARTED]: { text: 'Departed', value: CT_STATUSES.DEPARTED },
    [CT_STATUSES.DELAYED]: { text: 'Delayed', value: CT_STATUSES.DELAYED },
    [CT_STATUSES.DIVERTED]: { text: 'Diverted', value: CT_STATUSES.DIVERTED },
    [CT_STATUSES.CHECK_ARRIVAL]: { text: 'Check Arrival', value: CT_STATUSES.CHECK_ARRIVAL },
    [CT_STATUSES.ARRIVED]: { text: 'Arrived', value: CT_STATUSES.ARRIVED },
    [CT_STATUSES.SUBMIT_REASON_CODE]: { text: 'Submit reason code', value: CT_STATUSES.SUBMIT_REASON_CODE }
};

export const CR_STATUS = {
    [CR_STATUSES.NOT_DUE]: { text: 'Not due', value: CR_STATUSES.NOT_DUE },
    [CR_STATUSES.REQUEST_RELEASE]: { text: 'Request release', value: CR_STATUSES.REQUEST_RELEASE },
    [CR_STATUSES.MISSING_ORIGINAL_BL]: { text: 'Missing original B/L', value: CR_STATUSES.MISSING_ORIGINAL_BL },
    [CR_STATUSES.REQUEST_SENT]: { text: 'Request sent', value: CR_STATUSES.REQUEST_SENT},
    [CR_STATUSES.RELEASE_INCOMPLETE]: { text: 'Release incomplete', value: CR_STATUSES.RELEASE_INCOMPLETE },
    [CR_STATUSES.RELEASE_COMPLETE]: { text: 'Release complete', value: CR_STATUSES.RELEASE_COMPLETE }
}

export const CUSTOMS_STATUS = {
    [CUSTOMS_STATUSES.UPDATE_ENTRY_TYPE]: { text: 'Update entry type', value: CUSTOMS_STATUSES.UPDATE_ENTRY_TYPE },
    [CUSTOMS_STATUSES.UPDATE_CUSTOMS_BROKER]: { text: 'Update customs broker', value: CUSTOMS_STATUSES.UPDATE_CUSTOMS_BROKER },
    [CUSTOMS_STATUSES.PENDING]: { text: 'Pending', value: CUSTOMS_STATUSES.PENDING },
    [CUSTOMS_STATUSES.ON_HOLD]: { text: 'On hold', value: CUSTOMS_STATUSES.ON_HOLD },
    [CUSTOMS_STATUSES.CUSTOMS_COMPLETED]: { text: 'Customs completed', value: CUSTOMS_STATUSES.CUSTOMS_COMPLETED },
}

export const CUSTOMS_RELEASE_STATUS = {
    [CUSTOMS_RELEASE_STATUSES.UPDATE_ENTRY_TYPE]: { text: 'Update entry type', value: CUSTOMS_RELEASE_STATUSES.UPDATE_ENTRY_TYPE },
    [CUSTOMS_RELEASE_STATUSES.UPDATE_CUSTOMS_BROKER]: { text: 'Update customs broker', value: CUSTOMS_RELEASE_STATUSES.UPDATE_CUSTOMS_BROKER },
    [CUSTOMS_RELEASE_STATUSES.PENDING]: { text: 'Pending', value: CUSTOMS_RELEASE_STATUSES.PENDING },
    [CUSTOMS_RELEASE_STATUSES.ON_HOLD]: { text: 'On hold', value: CUSTOMS_RELEASE_STATUSES.ON_HOLD },
    [CUSTOMS_RELEASE_STATUSES.PRE_CLEARANCE_COMPLETED]: { text: 'Pre-clearance completed', value: CUSTOMS_RELEASE_STATUSES.PRE_CLEARANCE_COMPLETED },
}

export const PLANNING_STATUS_OPTIONS = {
    [DP_STATUSES.NOT_DUE]: { text: 'Not due', value: DP_STATUSES.NOT_DUE },
    [DP_STATUSES.SEND_DE_RIGGING_REQUEST]: { text: 'Send De-Rigging request', value: DP_STATUSES.SEND_DE_RIGGING_REQUEST },
    [DP_STATUSES.AVAILABLE_FOR_TRANSPORT]: { text: 'Available for transport', value: DP_STATUSES.AVAILABLE_FOR_TRANSPORT },
    [DP_STATUSES.UPDATE_REQUESTED_DELIVERY_DATE]: { text: 'Update Requested Delivery Date', value: DP_STATUSES.UPDATE_REQUESTED_DELIVERY_DATE },
    [DP_STATUSES.PRE_ALERT_SENT]: { text: 'Pre-Alert Sent', value: DP_STATUSES.PRE_ALERT_SENT },
    [DP_STATUSES.SUBMIT_TRANSPORT_ORDER]: { text: 'Submit Transport Order', value: DP_STATUSES.SUBMIT_TRANSPORT_ORDER },
    [DP_STATUSES.PLANNING_COMPLETED]: { text: 'Planning completed', value: DP_STATUSES.PLANNING_COMPLETED },
    [DP_STATUSES.SEND_NEW_TRANSPORT_ORDER]: { text: 'Send New Transport Order', value: DP_STATUSES.SEND_NEW_TRANSPORT_ORDER },
    [DP_STATUSES.DELIVERY_FAILED]: { text: 'Delivery failed', value: DP_STATUSES.DELIVERY_FAILED },
};

export const DELIVERY_STATUS = {
    [DP_STATUSES.PLANNING_COMPLETED]: { text: 'Planning completed', value: DP_STATUSES.PLANNING_COMPLETED },
    [DP_STATUSES.SEND_NEW_TRANSPORT_ORDER]: { text: 'Send New Transport Order', value: DP_STATUSES.SEND_NEW_TRANSPORT_ORDER },
    [DP_STATUSES.DELIVERY_FAILED]: { text: 'Delivery failed', value: DP_STATUSES.DELIVERY_FAILED },
    [DP_STATUSES.GATE_OUT_OF_PORT]: { text: 'Gate out of port', value: DP_STATUSES.GATE_OUT_OF_PORT },
    [DP_STATUSES.ARRIVAL_INTO_INLAND_DEPOT]: { text: 'Arrival into inland depot', value: DP_STATUSES.ARRIVAL_INTO_INLAND_DEPOT },
    [DP_STATUSES.MOVED_TO_STORAGE]: { text: 'Moved to storage', value: DP_STATUSES.MOVED_TO_STORAGE },
    [DP_STATUSES.VEHICLE_CHECKED_INTO_DC]: { text: 'Vehicle checked into dc', value: DP_STATUSES.VEHICLE_CHECKED_INTO_DC },
    [DP_STATUSES.ACTUAL_DELIVERY]: { text: 'Actual delivery', value: DP_STATUSES.ACTUAL_DELIVERY },
    [DP_STATUSES.VEHICLE_UNLOADED_DC]: { text: 'Vehicle unloaded dc', value: DP_STATUSES.VEHICLE_UNLOADED_DC },
    [DP_STATUSES.VEHICLE_CHECKOUT_OF_DC]: { text: 'Vehicle checkout out of dc', value: DP_STATUSES.VEHICLE_CHECKOUT_OF_DC },
    [DP_STATUSES.DE_RIGGED]: { text: 'De-rigged', value: DP_STATUSES.DE_RIGGED },
    [DP_STATUSES.EMPTY_RETURN_TO_PORT]: { text: 'Empty return to port', value: DP_STATUSES.EMPTY_RETURN_TO_PORT },
    [DP_STATUSES.EMPTY_RETURN_INLAND_DEPOT]: { text: 'Empty return inland depot', value: DP_STATUSES.EMPTY_RETURN_INLAND_DEPOT },
    [DP_STATUSES.NON_CONFORMANCE_RECEIVED]: { text: 'Non conformance received', value: DP_STATUSES.NON_CONFORMANCE_RECEIVED },
    [DP_STATUSES.RESENT]: { text: 'Resent', value: DP_STATUSES.RESENT },
};

export const DP_STATUS = {
    ...PLANNING_STATUS_OPTIONS,
    ...DELIVERY_STATUS,
};

export const PLANNING_STATUS_PLANNING = {
    [DP_STATUSES.NOT_DUE]: 'Not due',
    [DP_STATUSES.SEND_DE_RIGGING_REQUEST]: 'Send De-Rigging request',
    [DP_STATUSES.AVAILABLE_FOR_TRANSPORT]: 'Available for transport',
    [DP_STATUSES.UPDATE_REQUESTED_DELIVERY_DATE]: 'Update Requested Delivery Date',
    [DP_STATUSES.PRE_ALERT_SENT]: 'Pre-Alert Sent',
    [DP_STATUSES.SUBMIT_TRANSPORT_ORDER]: 'Submit Transport Order',
    [DP_STATUSES.PLANNING_COMPLETED]: 'Planning completed',
    [DP_STATUSES.SEND_NEW_TRANSPORT_ORDER]: 'Send New Transport Order'
};

export const RETURN_STATUS = {
    [DP_STATUSES.DELIVERY_FAILED]: 'Delivery failed',
    [DP_STATUSES.GATE_OUT_OF_PORT]: 'Gate out of port',
    [DP_STATUSES.ARRIVAL_INTO_INLAND_DEPOT]: 'Arrival into inland depot',
    [DP_STATUSES.MOVED_TO_STORAGE]: 'Moved to storage',
    [DP_STATUSES.VEHICLE_CHECKED_INTO_DC]: 'Vehicle checked into dc',
    [DP_STATUSES.ACTUAL_DELIVERY]: 'Actual delivery',
    [DP_STATUSES.VEHICLE_UNLOADED_DC]: 'Vehicle unloaded dc',
    [DP_STATUSES.VEHICLE_CHECKOUT_OF_DC]: 'Vehicle checkout out of dc',
    [DP_STATUSES.DE_RIGGED]: 'De-rigged',
    [DP_STATUSES.EMPTY_RETURN_TO_PORT]: 'Empty return to port',
    [DP_STATUSES.EMPTY_RETURN_INLAND_DEPOT]: 'Empty return inland depot',
};

export const CLEARANCE1_STATUS = {
    [CLEARANCE1_STATUSES.PRE_CLEARANCE_COMPLETED]: { text: 'Pre clearance completed', value: CLEARANCE1_STATUSES.PRE_CLEARANCE_COMPLETED },
    [CLEARANCE1_STATUSES.ON_HOLD]: { text: 'On hold', value: CLEARANCE1_STATUSES.ON_HOLD},
    [CLEARANCE1_STATUSES.IN_PROGRESS]: { text: 'In progress', value: CLEARANCE1_STATUSES.IN_PROGRESS}
};
export const CLEARANCE2_STATUS = {
    [CLEARANCE2_STATUSES.CUSTOMS_COMPLETED]: { text: 'Customs completed', value: CLEARANCE2_STATUSES.CUSTOMS_COMPLETED },
    [CLEARANCE2_STATUSES.ON_HOLD]: { text: 'On hold', value: CLEARANCE2_STATUSES.ON_HOLD},
    [CLEARANCE2_STATUSES.IN_PROGRESS]: { text: 'In progress', value: CLEARANCE2_STATUSES.IN_PROGRESS}
};

export const PLANNING_CAPABILITY_STATUSES = {
    ...PLANNING_STATUS_PLANNING,
    ...RETURN_STATUS,
};

export const billReleaseFilterStatuses = [
    { optionText: 'Not due', value: CR_STATUSES.NOT_DUE },
    { optionText: 'Request release', value: CR_STATUSES.REQUEST_RELEASE },
    { optionText: 'Missing original B/L', value: CR_STATUSES.MISSING_ORIGINAL_BL },
    { optionText: 'Request sent', value: CR_STATUSES.REQUEST_SENT},
    { optionText: 'Release incomplete', value: CR_STATUSES.RELEASE_INCOMPLETE },
    { optionText: 'Release complete', value: CR_STATUSES.RELEASE_COMPLETE }
];

export const mapStatusToStringForCT = (value: string | number) => CT_STATUS[value] === undefined ? '-' : CT_STATUS[value].text;
export const mapStatusToStringForCR = (value: string | number) => CR_STATUS[value] === undefined ? '-' : CR_STATUS[value].text;
export const mapStatusToStringForCustoms = (value: string | number) => CUSTOMS_STATUS[value] === undefined ? '-' : CUSTOMS_STATUS[value].text;
export const mapStatusToStringForCustomsRelease = (value: string | number) => CUSTOMS_RELEASE_STATUS[value] === undefined ? '-' : CUSTOMS_RELEASE_STATUS[value].text;
export const mapStatusToStringForDP = (value: string | number) => DP_STATUS[value] === undefined ? '-' : DP_STATUS[value].text;
export const mapStatusToStringForDelivery = (value: string | number) => DELIVERY_STATUS[value] === undefined ? '-' : DELIVERY_STATUS[value].text;
export const mapStatusToStringForClearance1 = (value: string | number) => CLEARANCE1_STATUS[value] === undefined ? '-' : CLEARANCE1_STATUS[value].text;
export const mapStatusToStringForClearance2 = (value: string | number) => CLEARANCE2_STATUS[value] === undefined ? '-' : CLEARANCE2_STATUS[value].text;
