import React, { FunctionComponent, useEffect } from 'react';
import { Page } from 'components/page-container';
import { PageHeader } from 'components/page-header';
import { CarrierBLDetailsTopBar } from './components/CarrierBLDetailsTopBar';
import { CarrierBLDetailsTable } from './components/CarrierBLDetailsTable';
import { PageWithTimeline } from 'components/page-with-timeline';
import { ThunkDispatch } from 'redux-thunk';
import { IStore } from 'reducers/index';
import { RouteComponentProps } from 'react-router-dom';
import { Action } from 'redux';
import { fetchBLDetails } from 'actions/vessels';
import { connect } from 'react-redux';
import { IBLDetails, IBubble } from 'reducers/vessels/models';
import { ProgressIndicator } from 'components/progress-indicator';
import { ContainerTrackingTypes } from 'constants/comment';
import { UserDetails } from 'components/user-details';
import { Capability } from 'components/timeline';
import { CarrierBlVesselsTable } from './components/CarrierBLDetailsVesselTable';
import { GenericError } from 'components/error-dialog/GenericError';

interface IParams {
    blId: string;
}

interface IDispatch {
    fetchBLDetails: (blId: number) => Promise<void>;
}

interface IMapStateToProps {
    blDetails: IBLDetails;
    bubbles: IBubble[];
}

interface IBLDetailsProps extends RouteComponentProps<IParams>, IDispatch, IMapStateToProps {
}

const _OceanBLDetails: FunctionComponent<IBLDetailsProps> = (props) => {
    const blId = parseInt(props.match.params.blId, 10);

    useEffect(() => {
        if (!Number.isNaN(blId)) {
            props.fetchBLDetails(blId);
        }
    }, []);

    if (Number.isNaN(blId)) {
        return <GenericError />;
    }

    return (
        <React.Fragment>
            <PageHeader backButton={true}>Carrier B/L : {props.blDetails.blNumber}</PageHeader>
            <ProgressIndicator data={props.bubbles} />
            <CarrierBLDetailsTopBar
                blId={blId}
                refreshFunctionForNotify={props.fetchBLDetails}
                {...props.blDetails.vessels[0]}
            />
            <Page>
                <PageWithTimeline
                    objectId={blId}
                    objectType={ContainerTrackingTypes.Cbl}
                    isAir={false}
                    capability={Capability.ContainerTracking}
                    additionalSources={{
                        objectTypes: props.blDetails.vessels.map(_ => ContainerTrackingTypes.Voyage),
                        ids: props.blDetails.vessels.map(vessel => vessel.voyageId)
                    }}
                >
                    <CarrierBlVesselsTable data={props.blDetails.vessels} />
                    <CarrierBLDetailsTable data={...props.blDetails.equipments}/>
                    <UserDetails
                        user={props.blDetails.lastUserUpdated}
                        date={props.blDetails.updateDate}
                    />
                </PageWithTimeline>
            </Page>
        </React.Fragment>
    );
}

const mapStateToProps = (state: IStore): IMapStateToProps => ({
    blDetails: state.vessels.blDetails,
    bubbles: state.vessels.blDetails.bubbleData
});

const mapDispatchToProps = (dispatch: ThunkDispatch<any, any, Action>): IDispatch => ({
    fetchBLDetails: (blId: number) => dispatch(fetchBLDetails(blId)),
});

export const OceanBLDetails = connect<IMapStateToProps, IDispatch, {}, IStore>(mapStateToProps, mapDispatchToProps)(_OceanBLDetails);
