import * as React from 'react';

export interface IOption {
    value: any;
    optionText: string;
}

export interface ISelect {
    label?: string;
    value?: any;
    options: IOption[];
    name?: string;
    disabled?: boolean;
    onChange?(value: React.ChangeEvent<HTMLSelectElement>): void;
}

export class Select extends React.Component<ISelect> {
    public render() {
        const options = this.createOptions();
        return(
            <>
                { this.props.label && <label className="filter-label">{this.props.label}</label>}
                <select
                    name={this.props.name}
                    value={this.props.value}
                    id="filter-dropdown"
                    className="form-control"
                    onChange={this.props.onChange}
                    disabled={!!this.props.disabled}
                >
                    {options}
                </select>
            </>
        );
    };

    private createOptions = () => this.props.options.map((option: IOption, index: number) => (
        <option
            key={index}
            value={option.value}
        >
            {option.optionText}
        </option>
    ));
}
