export const TYPES = {
    SET_SORT: 'SET_SORT',
    RESET_SORT: 'RESET_SORT',
    SET_FIRST_PAGE: 'SET_FIRST_PAGE',
    SET_LAST_PAGE: 'SET_LAST_PAGE',
    SET_NEXT_PAGE: 'SET_NEXT_PAGE',
    SET_PREVIOUS_PAGE: 'SET_PREVIOUS_PAGE',
    SET_TOTAL_COUNT: 'SET_TOTAL_COUNT',
    SET_EMPTY_FILTERS: 'SET_EMPTY_FILTERS',
    SET_FILTER: 'SET_FILTER',
    APPLY_FILTERS: 'APPLY_FILTERS',
    CLEAR_FILTERS: 'CLEAR_FILTERS',
    SET_EMPTY_DATE: 'SET_EMPTY_DATE'
};
