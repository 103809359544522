import React from 'react';
import { StatusRow } from 'components/status-row';
import { OneStatus } from 'components/status-row/OneStatus';
import { QuarterGrid } from 'components/grid/quarter-grid';
import { formatValueIfNullOrUndefined } from 'utilities/util';

interface ISubstitutionsRowProps {
    substitutionType: string;
    substitutionSize: string;
}

export const SubstitutionsRow: React.StatelessComponent<ISubstitutionsRowProps> = (props) => {
    return (
        <StatusRow title="Substitutions">
            <QuarterGrid>
                <OneStatus label="Substitution type">
                    {formatValueIfNullOrUndefined(props.substitutionType)}
                </OneStatus>
                <OneStatus label="Substitution size">
                    {formatValueIfNullOrUndefined(props.substitutionSize)}
                </OneStatus>
            </QuarterGrid>
        </StatusRow>
    );
};
