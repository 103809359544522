import React from 'react';
import { PageHeader } from 'components/page-header';
import { HandleError } from 'components/handle-error';
import { Filters } from 'modules/filters/containers/Filters';
import { Page } from 'components/page-container';
import { HAWBTable } from 'modules/planning/components/HAWBTable';
import { FiltersInput } from 'modules/filters/components/FiltersInput';
import { FiltersDateInput } from 'modules/filters/components/FiltersDateInput';
import { FiltersSelect } from 'modules/filters/components/FiltersSelect';
import { statusesValues } from 'utilities/util';
import { connect } from 'react-redux';
import { ThunkDispatch } from 'redux-thunk';
import { Action } from 'redux';
import { getHAWBList } from 'actions/planning';
import { IStore } from 'reducers/index';
import { IHAWBListData } from 'reducers/planning/models';
import { LoadingAnimation } from 'components/loading';
import { PLANNING_STATUS_OPTIONS } from 'constants/statuses';
import { setSort, resetSort } from 'actions/filters';
import { ISortModel } from 'reducers/filters/models';
import { setSortForTable } from 'utilities/tables';

interface IMapStateToProps {
    hawb: IHAWBListData;
    isLoading: boolean;
    isGetHAWBListError: boolean;
    isExportLoading: boolean;
    exportPlanningError: string;
    clientDeliveryEmpty: boolean;
    newEtaEmpty: boolean;
    ataEmpty: boolean;
}

interface IDispatch {
    getHAWBList: (pageNumber: number, pageSize: number) => Promise<void>;
    setSort: (sort: ISortModel) => void;
    resetSort: () => void;
}

interface IHAWBPlanning extends IMapStateToProps, IDispatch {}

interface IHAWBPlanningState {
    page: number;
    pageSize: number;
}

class _HAWBPlanning extends React.Component<IHAWBPlanning, IHAWBPlanningState> {
    public state: IHAWBPlanningState = {
        page: 1,
        pageSize: 10
    };

    public render() {
        const filters = [
            <FiltersInput
                key="HAWBNumber"
                name="HAWBNumber"
                label="HAWB Number"
            />,
            <FiltersDateInput
                type="date"
                key="clientDelivery_from"
                name="clientDelivery_from"
                label="Client delivery (from)"
                withTime={true}
                relatedField="clientDelivery"
                readOnly={this.props.clientDeliveryEmpty}
            />,
            <FiltersDateInput
                type="date"
                key="clientDelivery_to"
                name="clientDelivery_to"
                label="Client delivery (to)"
                isEndOfDay={true}
                withTime={true}
                relatedField="clientDelivery"
                readOnly={this.props.clientDeliveryEmpty}
            />,
            <FiltersDateInput
                type="date"
                key="newEta_from"
                name="newEta_from"
                label="New ETA (from)"
                withTime={true}
                relatedField="newEta"
                readOnly={this.props.newEtaEmpty}
            />,
            <FiltersDateInput
                type="date"
                key="newEta_to"
                name="newEta_to"
                label="New ETA (to)"
                isEndOfDay={true}
                withTime={true}
                relatedField="newEta"
                readOnly={this.props.newEtaEmpty}
            />,
            <FiltersDateInput
                type="date"
                key="ata_from"
                name="ata_from"
                label="ATA (from)"
                withTime={true}
                relatedField="ata"
                readOnly={this.props.ataEmpty}
            />,
            <FiltersDateInput
                type="date"
                key="ata_to"
                name="ata_to"
                label="ATA (to)"
                isEndOfDay={true}
                withTime={true}
                relatedField="ata"
                readOnly={this.props.ataEmpty}
            />,
            <FiltersSelect
                type="number"
                key="status"
                name="deliveryStatus"
                label="Status"
                options={statusesValues(PLANNING_STATUS_OPTIONS)}
            />,
            <FiltersInput
                key="pickUpLocation"
                name="pickUpLocation"
                label="Pick up location"
            />,
            <FiltersInput
                key="location"
                name="location"
                label="Delivery location"
            />,
            <FiltersInput
                key="POD"
                name="podName"
                label="POD"
            />,
            <FiltersInput
                key="PONumber"
                name="PONumber"
                label="PO Number"
            />,
            <FiltersInput
                key="lastUserUpdated"
                name="lastUserUpdated"
                label="Last updated by"
            />,
            <FiltersInput
                key="mawbNumber"
                name="mawbNumber"
                label="MAWB number"
            />,
        ];
        return (
            <React.Fragment>
                <PageHeader>HAWB planning</PageHeader>
                <Page>
                    {this.props.isLoading ||this.props.isExportLoading && <LoadingAnimation />}
                    <HandleError
                        exportPlanningError={this.props.exportPlanningError}
                    />
                    <HandleError
                        isGetHAWBListError={this.props.isGetHAWBListError}
                    />
                    <Filters
                        tableNameKey="planning-hawb"
                        error={this.props.isGetHAWBListError}
                        filters={filters}
                        fetchFunction={() => this.props.getHAWBList(this.state.page, this.state.pageSize)}
                    />
                    <HAWBTable
                        data={this.props.hawb.data}
                        pageCount={Math.ceil(this.props.hawb.totalCount / this.props.hawb.pageSize)}
                        fetchEquipments={this.fetchEquipments}
                    />
                </Page>
            </React.Fragment>
        );
    }

    public componentWillUnmount() {
        this.props.resetSort();
    }

    private fetchEquipments = (tableData: any) => {
        this.setState({
            page: tableData.page + 1,
            pageSize: tableData.pageSize
        }, () => {
            setSortForTable(tableData.sorted, this.props.setSort);
            this.props.getHAWBList(this.state.page, this.state.pageSize);
        });
    };
}

const mapStateToProps = (state: IStore): IMapStateToProps => ({
    hawb: state.planning.hawb,
    isLoading: state.planning.isGetHAWBListPending,
    isGetHAWBListError: !!state.planning.getHAWBListError,
    isExportLoading: state.exportReducer.isExportEquipmentPlanningLoading,
    exportPlanningError: state.exportReducer.exportPlanningError,
    clientDeliveryEmpty: state.filters.filters.clientdelivery_empty,
    newEtaEmpty: state.filters.filters.neweta_empty,
    ataEmpty: state.filters.filters.ata_empty
});

const mapDispatchToProps = (dispatch: ThunkDispatch<any, any, Action>): IDispatch => ({
    getHAWBList: (pageNumber: number, pageSize: number) => dispatch(getHAWBList(pageNumber, pageSize)),
    setSort: (sort: ISortModel) => dispatch(setSort(sort)),
    resetSort: () => dispatch(resetSort()),
});

export const HAWBPlanning = connect<IMapStateToProps, IDispatch, {}, IStore>(mapStateToProps, mapDispatchToProps)(_HAWBPlanning);
