import { TYPES } from 'action-types/demurrageAndDetention';
import { IDemurrageListData, IDetentionListData } from 'reducers/demurrage-and-detention/models';

interface IAction {
    readonly type: string;
    readonly contracts: any;
}

export interface IDemurrageAndDetentionContracts {
    totalCount: number;
    page: number;
    pageSize: number;
    result: any[];
}

interface IAction {
    readonly type: string;
    readonly error?: string;
    readonly demurrage: IDemurrageListData;
    readonly detention: IDetentionListData;
}

export interface IDemurrageAndDetentionState {
    isGetDemurragePending: boolean;
    getDemurragePendingError: string;
    isGetDetentionPending: boolean;
    getDetentionPendingError: string;
    isGetContractsPending: boolean;
    getContractsPendingError: string;
    isAddContractPending: boolean;
    addContractPendingError: string;
    isEditContractPending: boolean;
    editContractPendingError: string;
    isDeleteContractPending: boolean;
    deleteContractPendingError: string;
    isEditDAndDPending: boolean;
    editDAndDPendingError: string;
    demurrage: IDemurrageListData;
    detention: IDetentionListData;
    contracts: IDemurrageAndDetentionContracts;
}

const defaultState: IDemurrageAndDetentionState = {
    isGetDemurragePending: false,
    getDemurragePendingError: '',
    isGetDetentionPending: false,
    getDetentionPendingError: '',
    isGetContractsPending: false,
    getContractsPendingError: '',
    isAddContractPending: false,
    addContractPendingError: '',
    isEditContractPending: false,
    editContractPendingError: '',
    isDeleteContractPending: false,
    deleteContractPendingError: '',
    isEditDAndDPending: false,
    editDAndDPendingError: '',
    demurrage: {
        result: [],
        pageNumber: 1,
        pageSize: 20,
        totalCount: 0,
    },
    detention: {
        result: [],
        pageNumber: 1,
        pageSize: 20,
        totalCount: 0,
    },
    contracts: {
        totalCount: 0,
        page: 1,
        pageSize: 1,
        result: [],
    }
};

export const reducer = (state: IDemurrageAndDetentionState = defaultState, action: IAction): IDemurrageAndDetentionState => {
    switch (action.type) {
        case TYPES.GET_CONTRACTS.PENDING:
            return {
                ...state,
                isGetContractsPending: true,
            };
        case TYPES.GET_CONTRACTS.FULFILLED:
            return {
                ...state,
                isGetContractsPending: false,
                contracts: action.contracts
            };
        case TYPES.GET_CONTRACTS.REJECTED:
            return {
                ...state,
                isGetContractsPending: false,
                getContractsPendingError: action.error || '',
            };
        case TYPES.GET_CONTRACTS.RESET_ERROR:
            return {
                ...state,
                getContractsPendingError: '',
            }
        case TYPES.ADD_CONTRACT.PENDING:
            return {
                ...state,
                isAddContractPending: true
            };
        case TYPES.ADD_CONTRACT.FULFILLED:
            return {
                ...state,
                isAddContractPending: false
            };
        case TYPES.ADD_CONTRACT.REJECTED:
            return {
                ...state,
                isAddContractPending: false,
                addContractPendingError: action.error || '',
            };
        case TYPES.ADD_CONTRACT.RESET_ERROR:
            return {
                ...state,
                addContractPendingError: '',
            }
        case TYPES.EDIT_CONTRACT.PENDING:
            return {
                ...state,
                isEditContractPending: true
            };
        case TYPES.EDIT_CONTRACT.FULFILLED:
            return {
                ...state,
                isEditContractPending: false
            };
        case TYPES.EDIT_CONTRACT.REJECTED:
            return {
                ...state,
                isEditContractPending: false,
                editContractPendingError: action.error || '',
            };
        case TYPES.EDIT_CONTRACT.RESET_ERROR:
            return {
                ...state,
                editContractPendingError: '',
            }
        case TYPES.DELETE_CONTRACT.PENDING:
            return {
                ...state,
                isDeleteContractPending: true
            };
        case TYPES.DELETE_CONTRACT.FULFILLED:
            return {
                ...state,
                isDeleteContractPending: false
            };
        case TYPES.DELETE_CONTRACT.REJECTED:
            return {
                ...state,
                isDeleteContractPending: false,
                deleteContractPendingError: action.error || '',
            };
        case TYPES.DELETE_CONTRACT.RESET_ERROR:
            return {
                ...state,
                deleteContractPendingError: '',
            }
        case TYPES.GET_DEMURRAGE_EQUIPMENTS.PENDING:
            return {
                ...state,
                isGetDemurragePending: true,
            };
        case TYPES.GET_DEMURRAGE_EQUIPMENTS.FULFILLED:
            return {
                ...state,
                isGetDemurragePending: false,
                demurrage: {
                    result: action.demurrage.result || defaultState.demurrage.result,
                    totalCount: action.demurrage.totalCount || defaultState.demurrage.totalCount,
                    pageNumber: action.demurrage.pageNumber || defaultState.demurrage.pageNumber,
                    pageSize: action.demurrage.pageSize || defaultState.demurrage.pageSize
                }
            };
        case TYPES.GET_DEMURRAGE_EQUIPMENTS.REJECTED:
            return {
                ...state,
                isGetDemurragePending: false,
                getDemurragePendingError: action.error || '',
            };
        case TYPES.GET_DEMURRAGE_EQUIPMENTS.RESET_ERROR:
            return {
                ...state,
                getDemurragePendingError: '',
            };
        case TYPES.GET_DETENTION_EQUIPMENTS.PENDING:
            return {
                ...state,
                isGetDetentionPending: true,
            };
        case TYPES.GET_DETENTION_EQUIPMENTS.FULFILLED:
            return {
                ...state,
                isGetDetentionPending: false,
                detention: {
                    result: action.detention.result || defaultState.detention.result,
                    totalCount: action.detention.totalCount || defaultState.detention.totalCount,
                    pageNumber: action.detention.pageNumber || defaultState.detention.pageNumber,
                    pageSize: action.detention.pageSize || defaultState.detention.pageSize
                }
            };
        case TYPES.GET_DETENTION_EQUIPMENTS.REJECTED:
            return {
                ...state,
                isGetDetentionPending: false,
                getDetentionPendingError: action.error || '',
            };
        case TYPES.GET_DETENTION_EQUIPMENTS.RESET_ERROR:
            return {
                ...state,
                getDetentionPendingError: '',
            };
        case TYPES.EDIT_DEMURRAGE_AND_DETENTION_DETAILS.PENDING:
            return {
                ...state,
                isEditDAndDPending: true,
                editDAndDPendingError: '',
            };
        case TYPES.EDIT_DEMURRAGE_AND_DETENTION_DETAILS.FULFILLED:
            return {
                ...state,
                isEditDAndDPending: false,
            };
        case TYPES.EDIT_DEMURRAGE_AND_DETENTION_DETAILS.REJECTED:
            return {
                ...state,
                isEditDAndDPending: false,
                editDAndDPendingError: action.error || '',
            };
        case TYPES.EDIT_DEMURRAGE_AND_DETENTION_DETAILS.RESET_ERROR:
            return {
                ...state,
                editDAndDPendingError: '',
            };
        default:
            return state;
    }
};
