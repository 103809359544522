import React from 'react';
import { OneStatus } from 'components/status-row/OneStatus';
import { StatusRow } from 'components/status-row';
import { QuarterGrid } from 'components/grid/quarter-grid';
import { IEquipment } from 'reducers/customs/models';
import {formatValueIfNullOrUndefined} from 'utilities/util';

export const EquipmentDetailsTable: React.SFC<IEquipment> = (props: IEquipment) => (
    <StatusRow title="Equipment details">
        <QuarterGrid>
            <OneStatus label="Hotbox">
                {props.hotbox ? 'Y' : 'N'}
            </OneStatus>
            <OneStatus label="Service">
                {formatValueIfNullOrUndefined(props.service)}
            </OneStatus>
            <OneStatus label="Shipment type">
                {formatValueIfNullOrUndefined(props.shipmentType)}
            </OneStatus>
            <OneStatus label="Size / type">
                {formatValueIfNullOrUndefined(props.sizeType)}
            </OneStatus>
        </QuarterGrid>
        <QuarterGrid>
            <OneStatus label="Seal number">
                {formatValueIfNullOrUndefined(props.sealNumber)}
            </OneStatus>
            <OneStatus label="Total packages">
                {formatValueIfNullOrUndefined(props.totalPackages)}
            </OneStatus>
            <OneStatus label="Package type">
                {formatValueIfNullOrUndefined(props.packageType)}
            </OneStatus>
            <OneStatus label="Weight">
                {formatValueIfNullOrUndefined(props.weight)}
            </OneStatus>
        </QuarterGrid>
        <QuarterGrid>
            <OneStatus label="Volume">
                {formatValueIfNullOrUndefined(props.volume)}
            </OneStatus>
        </QuarterGrid>
    </StatusRow>
);
