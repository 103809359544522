import React from 'react';
import { ColumnFactory } from 'components/data-table/ColumnFactory';
import { DataTable } from 'components/data-table/DamcoTable';
import { ICblShipment } from 'reducers/fact/models';
import { StatusRow } from 'components/status-row';
import { cloneDataWithDisplayNames } from 'utilities/tables';

interface IShipmentsTableProps {
    shipments: ICblShipment[];
}

export const ShipmentsTable: React.FunctionComponent<IShipmentsTableProps> = (props) => {
    const mappedBookingAgents = props.shipments.map(shipment => ({
        ...shipment,
        bookingNumbers: shipment.bookingNumbers.map(num => ({
            val: num
        }))
    }));
    const data = cloneDataWithDisplayNames(mappedBookingAgents);
    
    return(
        <StatusRow title="Shipments">
            <DataTable
                data={data}
                columns={[
                    ColumnFactory.standardColumn('documentNumberDisplayName', 'Document number'),
                    ColumnFactory.standardColumn('bookingAgentDisplayName', 'Booking agent'),
                    ColumnFactory.standardColumn('cargoTypeDisplayName', 'Cargo type'),
                    ColumnFactory.standardColumn('consigneeNameDisplayName', 'Consignee name'),
                    ColumnFactory.standardColumn('cosigneeFACTCodeDisplayName', 'Consignee FACT code'),
                    ColumnFactory.standardColumn('consigneeCountryCodeDisplayName', 'Consignee country code'),
                    ColumnFactory.standardColumn('consigneeBusinessEntityCodeDisplayName', 'Consignee business entity code'),
                    ColumnFactory.standardColumn('placeOfDeliveryCodeDisplayName', 'Place of delivery code'),
                    ColumnFactory.standardColumn('placeOfDeliveryNameDisplayName', 'Place of delivery name'),
                    ColumnFactory.standardColumn('placeOfConsolidationCodeDisplayName', 'Place of consolidation code'),
                    ColumnFactory.standardColumn('placeOfConsolidationNameDisplayName', 'Place of consolidation name'),
                    ColumnFactory.standardColumn('placeOfDeconsolidationCodeDisplayName', 'Place of deconsolidation code'),
                    ColumnFactory.standardColumn('placeOfDeconsolidationNameDisplayName', 'Place of deconsolidation name'),
                    ColumnFactory.standardColumn('prepaidCollectIndicatorDisplayName', 'Prepaid collect indicator'),
                    ColumnFactory.standardColumn('reconsolidationPointDisplayName', 'Reconsolidation point'),
                ]}
                withCheckboxes={false}
                reference={null as any}
                tableName="CblDetailsShipmentsTable"
                showPagination={true}
                expandable={false}
            />
        </StatusRow>
    );
};

