import React from 'react';
import { InjectedFormProps, reduxForm, submit } from 'redux-form';
import { ModalScreen } from 'components/modal-screen';
import { ModalType } from 'components/modal-screen/ModalType';
import { Accordion } from 'components/accordion';
import { TripleGrid } from 'components/grid/triple-grid';
import { SingleGrid } from 'components/grid/single-grid';
import { Action, compose } from 'redux';
import { connect } from 'react-redux';
import { FormDateInput } from 'components/date-input/FormDateInput';
import { IStore } from 'reducers/index';
import { FormInput } from 'components/input/FormInput';
import { DeliveryTable } from 'views/planning/deliver-planning-container-details/components/DeliveryTable';
import { ThunkDispatch } from 'redux-thunk';
import { editDeliveryAndEmptyReturnEquipment, resetEditDeliveryAndEmptyReturnEquipmentError, getDPReasonCodes } from 'actions/planning';
import { IEquipmentDetails } from 'reducers/planning/models';
import { BooleanFormSelect } from 'components/select/BooleanFormSelect';
import { FormTextArea } from 'components/textarea/FormTextArea';
import { addNewComment, IAddComment } from 'actions/timeline';
import { LoadingAnimation } from 'components/loading';
import { HandleError } from 'components/handle-error';
import { DeliveryPlanningReasonCodesSelect } from 'components/select/DeliveryPlanningReasonCodesSelect';
import { DeliveryPlanningTypes } from 'constants/comment';
import styled from 'styled-components';
import { Button, ButtonType } from 'components/button';
import { Dialog } from 'components/dialog';
import { DELIVERY_STATUS, DP_STATUSES } from 'constants/statuses';
import { ShipmentClosedModal } from 'modules/modals/components/ShipmentClosedModal';
import { Countable } from 'components/countable';
import { count } from 'utilities/util';
import { updateButtonOnAccordionsChange } from 'utilities/accordion';
import { Capability } from 'components/timeline';
import { DPReasonCodesGroups } from 'constants/reasonCodes';

const SButton = styled(Button)`
    margin: 10px 0;
`;

const SButtonWrapper = styled.div`
    width: 100%;
    display: flex;
    justify-content: flex-end;
`;

interface IEditEmptyReturnDetailsPopup {
    isAir?: boolean;
    visible: boolean;
    status: number;
    initialValues: IEquipmentDetails;
    closeModal: () => void;
    refetchFunction: () => void;
}

interface IMapStateToProps {
    isLoading: boolean;
    isGetDPReasonCodesError: boolean;
    reasonCodes: Array<{ id: number; reasonCode: string }>
    updateEmptyReturnDetailsError: string;
}

interface IDispatch {
    editDeliveryAndEmptyReturnEquipment: (id: number, fields: any) => Promise<void>;
    addNewComment: (body: IAddComment, isAir: boolean) => Promise<void>;
    submit: () => void;
    resetEditDeliveryAndEmptyReturnEquipmentError: () => void;
    getDPReasonCodes: (group?: string) => Promise<void>;
}

type TEditDetailsProps = IEditEmptyReturnDetailsPopup & InjectedFormProps<{}, IEditEmptyReturnDetailsPopup> & IDispatch & IMapStateToProps;

const FORM_NAME = 'UPDATE_EMPTY_RETURN_DETAILS';

class _EditEmptyReturnDetailsPopup extends React.Component<TEditDetailsProps, any> {
    public state: any = {
        confirmVisible: false,
        fields: {},
    };

    public componentDidMount() {
        this.props.getDPReasonCodes(this.props.isAir ? DPReasonCodesGroups.Air_FailedDelivery : DPReasonCodesGroups.Ocean_FailedDelivery);

        this.setState({
            accordion_1: true,
            accordion_2: true,
            accordion_3: true,
        });
    }

    public render() {
        return (
            <React.Fragment>
                {this.props.isLoading && <LoadingAnimation />}
                <form>
                    <ModalScreen
                        title="Update final delivery details"
                        modalType={ModalType.l()}
                        primaryButtonTitle="Save"
                        primaryButtonType="submit"
                        primaryButtonFunc={this.props.handleSubmit(this.submit)}
                        primaryButtonId="submit-empty-return-modal"
                        secondaryButtonTitle="Cancel"
                        secondaryButtonFunc={this.props.closeModal}
                        visible={this.props.visible}
                        closeModal={this.props.closeModal}
                        id="edit-empty-return-details"
                    >
                        <HandleError
                            isGetDPReasonCodesError={this.props.isGetDPReasonCodesError}
                        />
                        {this.shouldDisplayConfirm() && <ShipmentClosedModal
                            visible={this.state.confirmVisible}
                            onClose={() => this.setState({ confirmVisible: false })}
                            onAccept={() => this.makeRequest(this.state.fields)}
                        />}
                        <Dialog
                            message={this.props.updateEmptyReturnDetailsError}
                            dialogType={500}
                            isVisible={!!this.props.updateEmptyReturnDetailsError}
                            closeDialog={this.props.resetEditDeliveryAndEmptyReturnEquipmentError}
                        />
                        <SingleGrid>
                            <DeliveryTable
                                id={this.props.initialValues.id}
                                withEdit={true}
                                isEmptyReturn={true}
                            />
                        </SingleGrid>
                        <SButtonWrapper>
                            <SButton buttonType={ButtonType.Transparent} onClick={this.toggleSections} id="expand-sections">
                                {updateButtonOnAccordionsChange(this.state)}
                            </SButton>
                        </SButtonWrapper>
                            <Countable getNumberOfChildrens={this.getNumberOfSections} >
                                <Accordion text="Delivery" collapse={this.state.accordion_1} onClick={() => this.setCollapseStatus(1)}>
                                    <TripleGrid>
                                        <FormDateInput
                                            label="Original planned date"
                                            id="planned-date"
                                            name="originalDeliveryDate"
                                            readOnly={true}
                                            withTime={this.props.isAir ? true : false}
                                        />
                                    </TripleGrid>
                                    <TripleGrid>
                                        <FormDateInput
                                            label="Check in DC"
                                            id="checkInDc"
                                            name="milestonesGateInDCDate"
                                            withTime={this.props.isAir ? true : false}
                                        />
                                        <FormDateInput
                                            label="Equipment unload DC"
                                            id="equipmentUnloadDc"
                                            name="milestonesUnloadDCDate"
                                            withTime={this.props.isAir ? true : false}
                                        />
                                        <FormDateInput
                                            label="Check out DC"
                                            id="checkOutDc"
                                            name="milestonesGateOutDCDate"
                                            withTime={this.props.isAir ? true : false}
                                        />
                                    </TripleGrid>
                                </Accordion>
                                <Accordion text="Delivery failure" collapse={this.state.accordion_2} onClick={() => this.setCollapseStatus(2)}>
                                    <TripleGrid>
                                        <FormDateInput
                                            label="Planned date"
                                            name="deliveryPlannedDate"
                                            id="deliveryPlannedDate"
                                            readOnly={true}
                                            withTime={this.props.isAir ? true : false}
                                        />
                                    </TripleGrid>
                                    <TripleGrid>
                                        <FormDateInput
                                            label="Failure date"
                                            name="deliveryFailureDate"
                                            id="delivery-failure-date"
                                            withTime={this.props.isAir ? true : false}
                                            readOnly={this.props.status >= 140 ? true : false}
                                        />
                                    </TripleGrid>
                                    <TripleGrid>
                                        <DeliveryPlanningReasonCodesSelect
                                            name="deliveryFailureReasonCodeId"
                                            label="Reason code"
                                            isEmptyReturn={true}
                                            disabled={this.props.status >= 140 ? true : false}
                                            reasonCodeGroup={this.props.isAir ? 'Air_LateShipment' : 'Ocean_LateShipment'}
                                        />
                                        <FormInput
                                            name="deliveryFailureResponsible"
                                            label="Responsible"
                                            disabled={this.props.status >= 140 ? true : false}
                                        />
                                    </TripleGrid>
                                </Accordion>
                                <Accordion text="Non conformance" collapse={this.state.accordion_3} onClick={() => this.setCollapseStatus(3)}>
                                    <TripleGrid>
                                        <BooleanFormSelect
                                            label="Non conformance"
                                            name="nonConformance"
                                        />
                                        <FormInput
                                            label="Non conformance type"
                                            name="nonConformanceType"
                                        />
                                        <FormInput
                                            label="Non conformance reference"
                                            name="nonConformanceReference"
                                        />
                                    </TripleGrid>
                                    <TripleGrid>
                                        <FormInput
                                            label="Quantity"
                                            name="nonConformanceQuantity"
                                        />
                                        <FormInput
                                            label="POC"
                                            name="nonConformancePoc"
                                        />
                                        <FormInput
                                            label="Non conformance category"
                                            name="nonConformanceCategory"
                                        />
                                    </TripleGrid>
                                    <TripleGrid>
                                        <FormInput
                                            label="Comment"
                                            name="nonConformanceComment"
                                        />
                                    </TripleGrid>
                                </Accordion>
                            </Countable>
                            <SingleGrid>
                                <FormTextArea
                                    label="Comments"
                                    name="comment"
                                    id="comment"
                                />
                            </SingleGrid>
                    </ModalScreen>
                    </form>
            </React.Fragment>
        );
    }

    private toggleSections = () => {
        const collapseStatus: boolean[] = [];

        for (const property in this.state) {
            if(property.includes('accordion_')) {
                collapseStatus.push(this.state[property]);
            }
        }

        const countTrue = count(collapseStatus, true);
        const countFalse = count(collapseStatus, false);

        if(countTrue > countFalse && countTrue === collapseStatus.length) {
            this.setState({
                accordion_1: false,
                accordion_2: false,
                accordion_3: false,
                collapseSections: false
            });
        } else if(countTrue < countFalse && countFalse === collapseStatus.length) {
            this.setState({
                accordion_1: true,
                accordion_2: true,
                accordion_3: true,
                collapseSections: true
            });
        } else if(countTrue > countFalse && countTrue !== collapseStatus.length) {
            this.setState({
                accordion_1: true,
                accordion_2: true,
                accordion_3: true,
                collapseSections: true
            });
        } else if(countTrue < countFalse && countFalse !== collapseStatus.length) {
            this.setState({
                accordion_1: false,
                accordion_2: false,
                accordion_3: false,
                collapseSections: false
            });
        } else if (countTrue === countFalse) {
            this.setState({
                accordion_1: true,
                accordion_2: true,
                accordion_3: true,
                collapseSections: true
            });
        }
    };

    private getNumberOfSections = (data: boolean[]) => data.forEach((val: boolean, i: number) => {
        this.setState({
            [`accordion_${i+1}`]: val
        });
    });

    private setCollapseStatus = (sectionNumber: number) => this.setState((state: any) => ({
        [`accordion_${sectionNumber}`]: !state[`accordion_${sectionNumber}`]
    }));

    private shouldDisplayConfirm = () => {
        const { deliveryStatus } = this.props.initialValues;
        return deliveryStatus && (
            deliveryStatus.toLowerCase() === DELIVERY_STATUS[DP_STATUSES.EMPTY_RETURN_TO_PORT].text.toLowerCase() ||
            deliveryStatus.toLowerCase() === DELIVERY_STATUS[DP_STATUSES.EMPTY_RETURN_INLAND_DEPOT].text.toLowerCase()
        );
    }

    private submit = async (fields: any) => {
        if (this.shouldDisplayConfirm()) {
            this.setState({ fields, confirmVisible: true });
        } else {
            return this.makeRequest(fields)
        }
    };

    private makeRequest = async (fields: any) => {
        await this.props.editDeliveryAndEmptyReturnEquipment(this.props.initialValues.id, {
            ...fields,
            equipmentId: fields.id,
        });
        if(fields.comment) {
            await this.props.addNewComment({
                objectId: this.props.initialValues.id,
                content: fields.comment,
                objectType: DeliveryPlanningTypes.Equipment,
            }, !!this.props.isAir)
        }
        this.props.refetchFunction();
        this.props.closeModal();
    }
}

const mapStateToProps = (state: IStore): IMapStateToProps => {
    const {
        addCommentLoading,
    } = state.timeline;
    const {
        isGetDPReasonCodesPending,
        getDPReasonCodesError,
        reasonCodes,
        isUpdateEmptyReturnDetailsPending,
        updateEmptyReturnDetailsError,
    } = state.planning;
    return {
        isGetDPReasonCodesError: !!getDPReasonCodesError,
        isLoading: addCommentLoading || isGetDPReasonCodesPending || isUpdateEmptyReturnDetailsPending,
        reasonCodes,
        updateEmptyReturnDetailsError,
    }
};

const mapDispatchToProps = (dispatch: ThunkDispatch<any, any, Action>): IDispatch => ({
    editDeliveryAndEmptyReturnEquipment: (id: number, fields: any) => dispatch(editDeliveryAndEmptyReturnEquipment(id, fields)),
    addNewComment: (body: IAddComment, isAir: boolean) => dispatch(addNewComment(body, isAir, Capability.DeliveryPlanning)),
    submit: () => dispatch(submit(FORM_NAME)),
    resetEditDeliveryAndEmptyReturnEquipmentError: () => dispatch(resetEditDeliveryAndEmptyReturnEquipmentError()),
    getDPReasonCodes: (group?: string) => dispatch(getDPReasonCodes(group)),
});

export const EditEmptyReturnDetailsPopup = compose(
    reduxForm<{}, IEditEmptyReturnDetailsPopup>({
        form: FORM_NAME,
        enableReinitialize: true,
    }),
    connect<IMapStateToProps, IDispatch, IEditEmptyReturnDetailsPopup, IStore>(mapStateToProps, mapDispatchToProps),
)(_EditEmptyReturnDetailsPopup);
