import React, {Component} from 'react';
import { MobileNavigation } from 'modules/navbar/containers/MobileNavigation';
import { NavBar } from 'modules/navbar/components/NavBar/NavBar';
import { connect } from 'react-redux';
import { IStore } from 'reducers/index';
import { ROUTES, IRoute, IRouteLink } from 'modules/navbar/routes';
import { IRoles } from 'utilities/roles';
import { BUSINNESS_FUNCTIONS } from 'constants/roles';
import { withRouter, RouteComponentProps } from 'react-router';
import { GlobalLoading } from 'components/global-loading';

class _PageWrapper extends Component<IMapStateToProps & RouteComponentProps<{}>> {
    public render() {
        const routes = this.filterRoutes();

        return(
            <div className="lsg-content">
                <header className="ds-header app-logged-in">
                    <NavBar routes={routes}/>
                </header>
                <GlobalLoading />
                {this.props.children}
                <MobileNavigation routes={routes} />
            </div>
        )
    }

    private filterRoutes = (): IRoute[] => {
        return ROUTES.reduce((routes: IRoute[], route: IRoute) => {
            if(route.links) {
                const links = route.links.filter((link: IRouteLink) => this.filterRoute(link));
                if(links.length) {
                    route.links = links;
                    routes.push(route);
                }
                return routes;
            }
            if(this.filterRoute(route)) {
                routes.push(route);
            }
            return routes;
        }, []);
    };

    private filterRoute = (route: IRoute | IRouteLink) => {
        return this.props.userRoles.find((role: IRoles) =>
            route.businessFunctions !== undefined && route.businessFunctions.includes(role.name as BUSINNESS_FUNCTIONS)
        );
    }
}

interface IMapStateToProps {
    userRoles: IRoles[];
}

const mapStateToProps = (state: IStore): IMapStateToProps => ({
    userRoles: state.user.roles,
});

export default withRouter(connect<IMapStateToProps, {}, {}, IStore>(mapStateToProps)(_PageWrapper));
