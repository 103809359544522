import React from 'react';
import { Switch } from 'react-router-dom';
import { Hbl } from 'views/bill-release/hbl';
import { Cbl } from 'views/bill-release/cbl';
import { CarrierBLDetails } from 'views/bill-release/carrier-bl-details';
import { HBLDetails } from 'views/bill-release/hbl-details';
import { RouteWithPermissions } from 'containers/route-with-permissions';
import { BUSINNESS_FUNCTIONS } from 'constants/roles';
import { Hawb } from './hawb';
import { HAWBDetails } from './hawb-details';

export const BillReleaseRouting = () => {
    return(
        <Switch>
            <RouteWithPermissions
                path="/bill-release/cbl/:blId"
                component={CarrierBLDetails}
                functions={[ BUSINNESS_FUNCTIONS.OCEAN_CBL_RELEASE_DETAILS]}
            />
            <RouteWithPermissions
                path="/bill-release/hbl/:id"
                component={HBLDetails}
                functions={[ BUSINNESS_FUNCTIONS.OCEAN_HBL_RELEASE_DETAILS]}
            />
            <RouteWithPermissions
                path="/bill-release/hawb/:id"
                component={HAWBDetails}
                functions={[ BUSINNESS_FUNCTIONS.AIR_HAWB_RELEASE_DETAILS]}
            />
            <RouteWithPermissions
                exact={true}
                path="/bill-release/hbl"
                component={Hbl}
                functions={[ BUSINNESS_FUNCTIONS.OCEAN_HBL_RELEASE_LIST]}
            />
            <RouteWithPermissions
                exact={true}
                path="/bill-release/cbl"
                component={Cbl}
                functions={[ BUSINNESS_FUNCTIONS.OCEAN_CBL_RELEASE_LIST]}
            />
            <RouteWithPermissions
                exact={true}
                path="/bill-release/hawb"
                component={Hawb}
                functions={[ BUSINNESS_FUNCTIONS.AIR_HAWB_RELEASE_LIST]}
            />
        </Switch>
    )
}