import { IStore } from './../reducers/index';
import { ThunkDispatch } from 'redux-thunk';
import API from 'constants/api';
import { callApiPost, callApiGet, callApiPut } from 'utilities/apiCaller';
import { TYPES } from 'action-types/bill-release'
import { Action } from 'redux';
import { applyFilters, generateError } from 'utilities/util';
import { Dispatch } from 'redux';

export interface IUpdateReleaseDetailsData {
    CarrierReleases: Array<{
        EquipmentId: number;
        PinExpiryDate: string;
        PickupLocationCode: string;
        EmptyLocationCode: string;
        Pin: string;
        UnloadDate: string;
        IsCarrierReleased?: boolean;
        blId?: number;
    }>;
    UpdatedBy: string;
}

export interface IRequestCarrierReleaseData {
    blIds: number[];
}

export interface INotifyCustomerWithRelease {
	voyageId: number;
	blIds: number[];
}

export const updateReleaseDetails = (data: IUpdateReleaseDetailsData) => {
    return async (dispatch: ThunkDispatch<any, any, Action>) => {
        dispatch({ type: TYPES.UPDATE_RELEASE_DETAILS.PENDING });
        try {
            await callApiPut(API.BillRelease.ReleaseUpdate, data, {headers: {TransportMode: 'Ocean'}});
            dispatch({ type: TYPES.UPDATE_RELEASE_DETAILS.FULFILLED });
        } catch (e) {
            const error = generateError(e);
            dispatch({ type: TYPES.UPDATE_RELEASE_DETAILS.REJECTED, error });
            throw e;
        }
    }
};

export const resetUpdateReleaseDetailsError = () => (dispatch: Dispatch) => {
    dispatch({ type: TYPES.UPDATE_RELEASE_DETAILS.RESET_ERROR });
}

export const requestCarrierRelease = (data: IRequestCarrierReleaseData) => {
    return async (dispatch: ThunkDispatch<any, any, Action>) => {
        dispatch({ type: TYPES.REQUEST_RELEASE.PENDING });
        try {
            await callApiPost(API.BillRelease.RequestCarrierRelease, data, {headers: {TransportMode: 'Ocean'}});
            dispatch({ type: TYPES.REQUEST_RELEASE.FULFILLED });
        } catch (e) {
            const error = generateError(e);
            dispatch({ type: TYPES.REQUEST_RELEASE.REJECTED, error });
            throw e;
        }
    }
};

export const resetRequestCarrierReleaseError = () => (dispatch: Dispatch) => {
    dispatch({ type: TYPES.REQUEST_RELEASE.RESET_ERROR });
}

export const fetchCblList = (pageNumber?: number, pageSize?: number) => {
    return async (dispatch: ThunkDispatch<any, any, Action>, getState: () => IStore) => {
        dispatch({ type: TYPES.FETCH_CBL_LIST.PENDING });
        try {
            const res = await callApiPost(API.BillRelease.CblList, applyFilters(getState, { isAirInBody: false, isAirInFilters: false }, {
                Page: pageNumber,
                PageNumber: pageNumber,
                PageSize: pageSize
            }), {headers: {TransportMode: 'Ocean'}});
            dispatch({ type: TYPES.FETCH_CBL_LIST.FULFILLED, cbl: res.data });
        } catch (e) {
            const error = generateError(e);
            dispatch({ type: TYPES.FETCH_CBL_LIST.REJECTED, error })
            throw e;
        }
    }
};

export const resetFetchCblListError = () => (dispatch: Dispatch) => {
    dispatch({ type: TYPES.FETCH_CBL_LIST.RESET_ERROR });
}

export const fetchHawbList = (pageNumber?: number, pageSize?: number) => {
    return async (dispatch: ThunkDispatch<any, any, Action>, getState: () => IStore) => {
        dispatch({ type: TYPES.FETCH_HAWB_LIST.PENDING });
        try {
            const res = await callApiPost(API.BillRelease.HblList, applyFilters(getState, { isAirInFilters: true, isAirInBody: true }, {
                Page: pageNumber,
                PageNumber: pageNumber,
                PageSize: pageSize
            }), {headers: {TransportMode: 'Air'}});
            dispatch({ type: TYPES.FETCH_HAWB_LIST.FULFILLED, hawb: res.data });
        } catch (e) {
            const error = generateError(e);
            dispatch({ type: TYPES.FETCH_HAWB_LIST.REJECTED, error })
            throw e;
        }
    }
};

export const resetFetchHawbListError = () => (dispatch: Dispatch) => {
    dispatch({ type: TYPES.FETCH_HAWB_LIST.RESET_ERROR });
}

export const fetchHblList = (pageNumber?: number, pageSize?: number) => {
    return async (dispatch: ThunkDispatch<any, any, Action>, getState: () => IStore) => {
        dispatch({ type: TYPES.FETCH_HBL_LIST.PENDING });
        try {
            const res = await callApiPost(API.BillRelease.HblList, applyFilters(getState, { isAirInBody: false, isAirInFilters: false }, {
                Page: pageNumber,
                PageNumber: pageNumber,
                PageSize: pageSize
            }), {headers: {TransportMode: 'Ocean'}});
            dispatch({ type: TYPES.FETCH_HBL_LIST.FULFILLED, hbl: res.data });
        } catch (e) {
            const error = generateError(e);
            dispatch({ type: TYPES.FETCH_HBL_LIST.REJECTED, error });
            throw e;
        }
    }
};

export const resetFetchHblListError = () => (dispatch: Dispatch) => {
    dispatch({ type: TYPES.FETCH_HBL_LIST.RESET_ERROR });
}

export const fetchCblDetails = (id: number) => {
    return async (dispatch: ThunkDispatch<any, any, Action>) => {
        dispatch({ type: TYPES.FETCH_CBL_DETAILS.PENDING });
        try {
            const res = await callApiGet(API.BillRelease.CblDetails(id), undefined, {headers: {TransportMode: 'Ocean'}});
            dispatch({ type: TYPES.FETCH_CBL_DETAILS.FULFILLED, cblDetails: res.data });
        } catch (e) {
            const error = generateError(e);
            dispatch({ type: TYPES.FETCH_CBL_DETAILS.REJECTED, error });
            throw e;
        }
    }
};

export const resetFetchCblDetailsError = () => (dispatch: Dispatch) => {
    dispatch({ type: TYPES.FETCH_CBL_DETAILS.RESET_ERROR });
}

export const fetchHblDetails = (id: number) => {
    return async (dispatch: ThunkDispatch<any, any, Action>) => {
        dispatch({ type: TYPES.FETCH_HBL_DETAILS.PENDING });
        try {
            const res = await callApiGet(API.BillRelease.HblDetails(id), undefined, {headers: {TransportMode: 'Ocean'}});
            dispatch({ type: TYPES.FETCH_HBL_DETAILS.FULFILLED, hblDetails: res.data });
        } catch (e) {
            const error = generateError(e);
            dispatch({ type: TYPES.FETCH_HBL_DETAILS.REJECTED, error });
            throw e;
        }
    }
};

export const resetFetchHblDetailsError = () => (dispatch: Dispatch) => {
    dispatch({ type: TYPES.FETCH_HBL_DETAILS.RESET_ERROR });
}

export const fetchHawbDetails = (id: number) => {
    return async (dispatch: ThunkDispatch<any, any, Action>) => {
        dispatch({ type: TYPES.FETCH_HAWB_DETAILS.PENDING });
        try {
            const res = await callApiGet(API.BillRelease.HblDetails(id), undefined, {headers: {TransportMode: 'Air'}});
            dispatch({ type: TYPES.FETCH_HAWB_DETAILS.FULFILLED, hblDetails: res.data });
        } catch (e) {
            const error = generateError(e);
            dispatch({ type: TYPES.FETCH_HAWB_DETAILS.REJECTED, error });
            throw e;
        }
    }
};

export const resetFetchHawbDetailsError = () => (dispatch: Dispatch) => {
    dispatch({ type: TYPES.FETCH_HAWB_DETAILS.RESET_ERROR });
}

export const editCblDetails = (body: any) => {
    return async (dispatch: ThunkDispatch<any, any, Action>) => {
        dispatch({ type: TYPES.EDIT_HBL_DETAILS.PENDING });
        try {
            await callApiPost(API.BillRelease.EditCblDetails, body, {headers: {TransportMode: 'Ocean'}});
            dispatch({ type: TYPES.EDIT_HBL_DETAILS.FULFILLED });
        } catch (e) {
            const error = generateError(e);
            dispatch({ type: TYPES.EDIT_HBL_DETAILS.REJECTED, error });
            throw e;
        }
    }
}

export const resetEditCblDetailsError = () => (dispatch: Dispatch) => {
    dispatch({ type: TYPES.EDIT_HBL_DETAILS.RESET_ERROR });
}

export const editHblDetails = (body: any) => {
    return async (dispatch: ThunkDispatch<any, any, Action>) => {
        dispatch({ type: TYPES.EDIT_HBL_DETAILS.PENDING });
        try {
            await callApiPost(API.BillRelease.EditHblDetails, body, {headers: {TransportMode: 'Ocean'}});
            dispatch({ type: TYPES.EDIT_HBL_DETAILS.FULFILLED });
        } catch (e) {
            const error = generateError(e);
            dispatch({ type: TYPES.EDIT_HBL_DETAILS.REJECTED, error });
            throw e;
        }
    }
}
export const resetEditHblDetailsError = () => (dispatch: Dispatch) => {
    dispatch({ type: TYPES.EDIT_HBL_DETAILS.RESET_ERROR });
}

export const editHawbDetails = (body: any) => {
    return async (dispatch: ThunkDispatch<any, any, Action>) => {
        dispatch({ type: TYPES.EDIT_HAWB_DETAILS.PENDING });
        try {
            await callApiPost(API.BillRelease.EditHblDetails, body, {headers: {TransportMode: 'Air'}});
            dispatch({ type: TYPES.EDIT_HAWB_DETAILS.FULFILLED });
        } catch (e) {
            const error = generateError(e);
            dispatch({ type: TYPES.EDIT_HAWB_DETAILS.REJECTED, error });
            throw e;
        }
    }
}
export const resetEditHawbDetailsError = () => (dispatch: Dispatch) => {
    dispatch({ type: TYPES.EDIT_HAWB_DETAILS.RESET_ERROR });
}

export const notifyCustomerWithCarrierContainerRelease = (data: INotifyCustomerWithRelease) => {
    return async (dispatch: ThunkDispatch<any, any, Action>) => {
        dispatch({ type: TYPES.REQUEST_RELEASE.PENDING });
        try {
            await callApiPost(API.BillRelease.NotifyCustomerWithCarrierContainerRelease, data, {headers: {TransportMode: 'Ocean'}});
            dispatch({ type: TYPES.REQUEST_RELEASE.FULFILLED });
        } catch (e) {
            const error = generateError(e);
            dispatch({ type: TYPES.REQUEST_RELEASE.REJECTED, error });
            throw e;
        }
    }
};
