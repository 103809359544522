import { createAsyncActionConstants } from 'utilities/util';

export const TYPES = {
    ADD_COMMENT: createAsyncActionConstants('ADD_COMMENT'),
    FETCH_COMMENTS: createAsyncActionConstants('FETCH_COMMENTS'),
    FETCH_ADDITIONAL_COMMENTS: createAsyncActionConstants('FETCH_ADDITIONAL_COMMENTS'),
    LOAD_MORE_COMMENTS: createAsyncActionConstants('LOAD_MORE_COMMENTS'),
    RESET_ERROR: 'RESET_ERROR',
    SET_FILTERS: 'SET_FILTERS',
}
