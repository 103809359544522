import React from 'react';
import { StatusRow } from 'components/status-row';
import { OneStatus } from 'components/status-row/OneStatus';
import { QuarterGrid } from 'components/grid/quarter-grid';
import { formatValueIfNullOrUndefined } from 'utilities/util';

interface IConsigneeRowProps {
    consigneeBusinessEntityCode: string;
    consigneeCountryCode: string;
    consigneeFACTCode: string;
    consigneeName: string;
}

export const ConsigneeRow: React.FunctionComponent<IConsigneeRowProps> = (props) => {
    return (
        <StatusRow title="Consignee">
            <QuarterGrid>
                <OneStatus label="Business entity code">
                    {formatValueIfNullOrUndefined(props.consigneeBusinessEntityCode)}
                </OneStatus>
                <OneStatus label="Country code">
                    {formatValueIfNullOrUndefined(props.consigneeCountryCode)}
                </OneStatus>
                <OneStatus label="FACT code">
                    {formatValueIfNullOrUndefined(props.consigneeFACTCode)}
                </OneStatus>
                <OneStatus label="Name">
                    {formatValueIfNullOrUndefined(props.consigneeName)}
                </OneStatus>
            </QuarterGrid>
        </StatusRow>
    );
};
