import React from 'react';

export interface IWithTableCheckboxesProps extends IWithTableCheckboxesDataProps {
    toggleCheckbox: (row: any) => void;
    clearCheckedCheckboxes: () => void;
    toggleAllCheckboxes: () => void;
    isAllCheckboxesChecked: () => boolean
    getSelectedData: () => any[];
    checked: any[];
    isLoading: boolean;
}

interface IWithTableCheckboxesState {
    checked: any[];
}

interface IWithTableCheckboxesDataProps {
    data: any[];
}

export const withTableCheckboxes = <P extends {}>(WrappedComponent: React.ComponentType<P & IWithTableCheckboxesProps>, idField: string, multipleSelection: boolean = false): React.ComponentType<P> => {
    return class extends React.Component<P & IWithTableCheckboxesProps, IWithTableCheckboxesState> {
        public state: IWithTableCheckboxesState = {
            checked: [],
        };

        public componentDidUpdate(prevProps: P & IWithTableCheckboxesProps) {
            if(this.props.isLoading && !prevProps.isLoading) {
                this.setState({ checked: [] });
            }

            if (this.props.data !== prevProps.data && this.state.checked.length > 0) {
                this.setState((prevState: IWithTableCheckboxesState) => ({
                    checked: prevState.checked.map(row => this.props.data.find(r => row[idField] === r[idField]) || row)
                }));
            }
        }

        public toggleCheckbox = (row: any): void => {
            if(!multipleSelection) {
                this.toggleCheckboxWithSingleSelection(row);
            } else {
                this.toggleCheckboxWithMultipleSelection(row);
            }
        };

        public toggleAllCheckboxes = (): void => {
            if(this.isAllCheckboxesSelected()) {
                this.clearAllCheckboxes();
            } else {
                this.selectAllCheckboxes();
            }
        };

        public isAllCheckboxesSelected = (): boolean => this.state.checked.length === this.props.data.length;

        public render() {
            return(
                <WrappedComponent
                    {...this.props}
                    checked={this.state.checked}
                    toggleCheckbox={this.toggleCheckbox}
                    toggleAllCheckboxes={this.toggleAllCheckboxes}
                    clearCheckedCheckboxes={this.clearAllCheckboxes}
                    isAllCheckboxesChecked={this.isAllCheckboxesSelected}
                    getSelectedData={this.getSelectedData}
                    data={this.getDataWithSelectedProp(this.props.data)}
                />
            );
        }

        private toggleCheckboxWithMultipleSelection = (row: any): void => {
            if(this.rowIsChecked(row)) {
                this.removeRowFromState(row);
            } else {
                this.addRowToState(row)
            }
        };

        private toggleCheckboxWithSingleSelection = (row: any): void => {
            if(this.rowIsChecked(row)) {
                this.setState({ checked: [] });
            } else {
                this.setState({ checked: [row] });
            }
        };

        private getDataWithSelectedProp = (data: any[]) => {
            return data.map((row: any) => {
                row.selected = this.rowIsChecked(row);
                return {
                    ...row,
                    selected: this.rowIsChecked(row),
                };
            });
        };

        private getSelectedData = () => {
            return this.state.checked;
        }

        private rowIsChecked = (row: any): boolean => !!this.state.checked.find((checkedRow: any) => {
            return checkedRow[idField] === row[idField];
        });

        private clearAllCheckboxes = (): void => this.setState({ checked: [] });

        private selectAllCheckboxes = (): void => this.setState({ checked: this.props.data });

        private removeRowFromState = (row: any) => this.setState((state) => ({
            checked: state.checked.filter((rowToCheck: any) => rowToCheck[idField] !== row[idField])
        }));

        private addRowToState = (row: any) => this.setState((state) => ({
            checked: state.checked.concat(row),
        }));
    };
};
