import React from 'react';
import { withRouter, RouteComponentProps } from 'react-router-dom';

const _BackButton = (props: RouteComponentProps<{}>) => (
    <li>
        <a
            onClick={() => props.history.goBack()}
            href="javascript:void(0)"
            className="button-large button-transparent back-btn"
        >
            <i className="fa fa-angle-left" aria-hidden="true" />
        </a>
    </li>
)

export const BackButton = withRouter(_BackButton);
