import React from 'react';
import { ColumnFactory } from 'components/data-table/ColumnFactory';
import { IVesselBL } from 'reducers/vessels/models';
import { DataTable } from 'components/data-table/DamcoTable';
import { cloneDataWithDisplayNames } from 'utilities/tables';

interface IVesselDetailsTable {
    bLs: IVesselBL[];
    loadingPort: string;
    destinationPort: string;
}

export const CBLDetailsTable: React.SFC<IVesselDetailsTable> = (props: IVesselDetailsTable) => {
    const data = cloneDataWithDisplayNames(props.bLs).map((bl: any) => ({
            ...bl,
            numberDisplayName: [bl.number, `/tracking/mbl-tracking/${bl.masterBillOfLadingId}`],
            svcDisplayName: bl.svc === null ? '-' : bl.svc,
            polDisplayName: props.loadingPort,
            podDisplayName: props.destinationPort
    }));

    return(
        <DataTable
            data={data}
            columns={[
                ColumnFactory.linkedColumn('numberDisplayName', 'CB/L'),
                ColumnFactory.standardColumn('voyageDisplayName', 'Voyage'),
                ColumnFactory.standardColumn('carrierDisplayName', 'Carrier'),
                ColumnFactory.standardColumn('svcDisplayName', 'SVC'),
                ColumnFactory.standardColumn('polDisplayName', 'POL'),
                ColumnFactory.standardColumn('podDisplayName', 'POD'),
            ]}
            withCheckboxes={false}
            reference={null as any}
            tableName="VesselTrackingDetailsCBLDetails"
            showPagination={true}
        />
    );
};

