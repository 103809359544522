import axios, { AxiosPromise, AxiosRequestConfig } from 'axios';
import APIConstants from 'constants/api';
import { API } from 'action-types/api';
import store from '../store';
import { generateErrors } from './util';
import { IApiErrorAction, IApiResetErrorAction } from 'reducers/api';

const apiRequest = async (url: string, config: AxiosRequestConfig) => {
    store.dispatch({ type: API.REQUEST_START });
    store.dispatch<IApiResetErrorAction>({ type: API.RESET_ERROR, endpoint: url });

    try {
        const res = await axios.request({
            ...config,
            url,
        });
        store.dispatch({ type: API.REQUEST_END });
        return res;
    } catch (e) {
        store.dispatch({ type: API.REQUEST_END });
        store.dispatch<IApiErrorAction>({ type: API.ADD_ERROR, endpoint: url, errors: generateErrors(e) });
        throw e;
    }
};

export const callApiGet = (url: string, query?: object, config: AxiosRequestConfig = {}) => {
    return apiRequest(url, {
        ...config,
        method: 'GET',
        params: query ? { ...query } : undefined,
    });
};

export const callApiPost = (url: string, data: any[] | object = {}, config: AxiosRequestConfig = {}): AxiosPromise => {
    return apiRequest(url, {
        ...config,
        method: 'POST',
        headers: {
            ...config.headers,
            ...APIConstants.Headers,
        },
        data,
    });
};

export const callApiPut = (url: string, data: object, config: AxiosRequestConfig = {}) => {
    return apiRequest(url, {
        ...config,
        method: 'PUT',
        data,
    });
};

export const callApiPatch = (url: string, data: object, config: AxiosRequestConfig = {}) => {
    return apiRequest(url, {
        ...config,
        method: 'PATCH',
        data,
    });
};

export const callApiDelete = (url: string, config: AxiosRequestConfig = {}) => {
    return apiRequest(url, {
        ...config,
        method: 'DELETE',
    });
};
