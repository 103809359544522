import React from 'react';
import { IReasonCode } from 'reducers/settings/models';
import { connect } from 'react-redux';
import { IStore } from 'reducers/index';
import { ReasonCodesTable } from 'modules/settings/demurrage-and-detention/containers/ReasonCodesTables';

interface IMapStateToProps {
    isLoading: boolean;
    reasonCodes: IReasonCode[];
    createContainerTrackingReasonCodeError: string;
}

const _ReasonCodes: React.StatelessComponent<IMapStateToProps> = (props: IMapStateToProps) => (
    <ReasonCodesTable
        data={props.reasonCodes}
    />
);

const mapStateToProps = (state: IStore): IMapStateToProps => ({
    isLoading: state.settings.fetchReasonCodeIsLoading,
    reasonCodes: state.settings.dndReasonCodes,
    createContainerTrackingReasonCodeError: state.settings.createContainerTrackingReasonCodeError,
});

export const ReasonCodes = connect(mapStateToProps)(_ReasonCodes);