import { TYPES } from 'action-types/changelog';
import { IChangeLog } from './models';

interface IAction {
    readonly type: string;
    readonly changelog: IChangeLog[];
}

export interface IChangeLogState {
    readonly changelog: IChangeLog[];
    readonly changelogIsLoading: boolean;
}

const defaultState: IChangeLogState = {
    changelog: [],
    changelogIsLoading: false
};

export const reducer = (state: IChangeLogState = defaultState, action: IAction): IChangeLogState => {
    switch (action.type) {
        case TYPES.FETCH_CHANGELOG.PENDING:
            return {
                ...state,
                changelogIsLoading: true
            };
        case TYPES.FETCH_CHANGELOG.FULFILLED:
            return {
                ...state,
                changelog: action.changelog || defaultState.changelog,
                changelogIsLoading: false
            };
        case TYPES.FETCH_CHANGELOG.REJECTED:
            return {
                ...state,
                changelogIsLoading: false
            };
        case TYPES.FETCH_CHANGELOG.RESET_ERROR:
            return {
                ...state,
            }
        default:
            return state;
    }
};
