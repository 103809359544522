export const EVENT_TYPES = [
    'ActualArrival',
    'ActualDeparture',
    'ArrivalTranshipmentPortOrigin',
    'DepartureTranshipmentPortOrigin',
    'ArrivalTranshipmentPortEU',
    'DepartureTranshipmentPortEU',
    'ETA',
    'ATA',
    'ETD',
    'ATD',
    'VesselLink',
    'LateShipment',
    'PARNReceived',
    'BookedForDelivery',
    'DeRigged',
    'MovedToDarkStorage',
    'EmptyEquipmentDispatched',
    'EmptyReturn',
    'EmptyReturnInlandDepot',
    'LoadedVessel',
    'FailedDelivery',
    'ArrivalAtInlandDepot',
    'MovedOffQuay',
    'HotBox',
    'ActualDelivery',
    'AvailableForBooking',
    'CheckIn',
    'CheckOut',
    'Unloaded',
    'ContainerGateIn',
    'ContainerGateOut',
    'ExportDocumentsReceivedAtOrigin',
    'Demurrage',
    'Detention',
    'NonConformance',
    'CarrierEquipmentRelease',
    'CustomCleared',
    'USCustomsCleared',
    'ESCustomsCleared',
    'FrontierDeclaration',
    'CustomsClearanceUpdate',
    'GenericEvent',
    'DeliveryUpdate',
    'CarrierRelease',
];

export const NON_COMMENT_EVENT_TYPES = ['VesselLink', 'GenericEvent', 'DeliveryUpdate', 'CarrierRelease'];

export const COMMENT_EVENT_TYPES = EVENT_TYPES.filter(type => NON_COMMENT_EVENT_TYPES.indexOf(type) === -1);
