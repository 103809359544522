import React, { FunctionComponent, useRef, useState, useEffect } from 'react';
import { LoadingAnimation } from 'components/loading';
import { ColumnFactory } from 'components/data-table/ColumnFactory';
import { IEquipmentListItem, IEquipmentDetails } from 'reducers/planning/models';
import { DataTable } from 'components/data-table/DamcoTable';
import { downloadFile } from 'utilities/download';
import { connect } from 'react-redux';
import { ThunkDispatch } from 'redux-thunk';
import { Action } from 'redux';
import { exportEquipmentPlanningToExcel, exportTransportDetailsToExcel } from 'actions/export';
import { EditDeliveryDetailsModal } from 'modules/modals/components/EditDevlieryDetailsModal';
import { getEquipmentsList, getEquipmentDetails } from 'actions/planning';
import { EditTransportationDetailsModal } from 'views/planning/deliver-planning-container-details/components/EditTransportationDetailsModal';
import { IStore } from 'reducers/index';
import { SendTransportOrderToHaulierModal } from 'modules/modals/components/SendTransportOrderToHaulierModal';
import { Transport_Order_Type, Transport_Mode, Transport_Type } from 'constants/transport';
import { mapStatusToStringForDP, DERIG_STATUSES } from 'constants/statuses';
import { uniqWith, isEqual } from 'lodash';
// tslint:disable-next-line: no-submodule-imports
import { IWithPermissions, withPermissions } from 'src/HOC/withPermissions';
import { BUSINNESS_FUNCTIONS } from 'constants/roles';
import { getArrayOfButtons, sendFileAsBase64 } from 'utilities/util';
import { Dialog } from 'components/dialog';
import { ModalImport } from 'components/modal-import/ModalImport';
import { importFinalDeliveryDetails, importTransportDetails } from 'actions/import';
import { IImportErrors } from 'reducers/import/models';

interface IMapStateToProps {
    equipmentDetails: IEquipmentDetails;
    errors: IImportErrors[];
}

interface IMapDispatchToProps {
    exportEquipmentPlanningToExcel: (isAir: boolean, downloadForReImport?: boolean, ids?: number[]) => Promise<void>;
    exportTransportDetailsToExcel: (isAir: boolean, onlyTemplate:boolean, ids?: number[]) => Promise<void>;
    getEquipmentsList: () => Promise<void>;
    getEquipmentDetails: (id: number) => Promise<void>;
    importFinalDeliveryDetails: (isAir: boolean, file: File) => Promise<void>;
    importTransportDetails: (isAir: boolean, file: File) => Promise<void>;
}

interface IPlanningTableOwnProps {
    data: IEquipmentListItem[];
    isLoading: boolean;
    pageCount: number;
    fetchEquipments: any;
}

interface IPlanningTableProps extends IWithPermissions, IPlanningTableOwnProps, IMapStateToProps, IMapDispatchToProps {}

interface IPlanningTableState {
    isEditDeliveryDetailsModalOpened?: boolean;
    isEditTransportDetailsOpened?: boolean;
    isSendTransportOrderToHaulierOpened?: boolean;
    isErrorDialogOpened?: boolean;
    importModalIsOpened?: boolean;
}

const _PlanningTable: FunctionComponent<IPlanningTableProps> = (props) => {
    const [modals, setModals] = useState<IPlanningTableState>({
        isEditDeliveryDetailsModalOpened: false,
        isEditTransportDetailsOpened: false,
        isSendTransportOrderToHaulierOpened: false,
        isErrorDialogOpened: false,
        importModalIsOpened: false
    });
    const [errorMessage, setErrorMessage] = useState<string>('Some error occured');
    const [transportOrderType, setTransportOrderType] = useState<Transport_Order_Type>(Transport_Order_Type.Trucking);
    const [transportOrderName, setTransportOrderName] = useState<string>('');

    const dataTable = useRef<any>();

    useEffect(() => setModals({isErrorDialogOpened: false}), [props.data]);

    const exportToExcel = async (): Promise<void> => {
        const res = await props.exportEquipmentPlanningToExcel(false);
        await downloadFile(res);
    };

    const toggleEditDeliveryDetailsModal = (): void => setModals({isEditDeliveryDetailsModalOpened: !modals.isEditDeliveryDetailsModalOpened});

    const toggleEditTransportModal = async (): Promise<void> => {
        const selectedItems = dataTable.current ? dataTable.current.getSelectedItems() : [];

        if(!modals.isEditTransportDetailsOpened && selectedItems.length === 1) {
            await props.getEquipmentDetails(selectedItems[0].id);
        }
        setModals({isEditTransportDetailsOpened: !modals.isEditTransportDetailsOpened});
    };

    // tslint:disable-next-line: no-shadowed-variable
    const toggleSendTransportOrderToHaulierModal = (transportOrderType: Transport_Order_Type = Transport_Order_Type.Trucking): void => {
        setTransportOrderType(transportOrderType);
        setModals({isSendTransportOrderToHaulierOpened: !modals.isSendTransportOrderToHaulierOpened});

        const transportName = () => {
            if(Object.entries(Transport_Order_Type)[transportOrderType]) {
                return Object.entries(Transport_Order_Type)[transportOrderType][1];
            }
            return '';
        };
        setTransportOrderName(transportName() as string);
    };

    const getIds = (): number[] => {
        const selectedItems = dataTable.current ? dataTable.current.getSelectedItems() : [];

        return selectedItems.map((item: IEquipmentListItem) => item.id);
    };

    const getEquipmentsNumbers = (): object[] => {
        const selectedItems = dataTable.current ? dataTable.current.getSelectedItems() : [];

        return selectedItems.map((selectedItem: any) => ({
            equipmentNumber: selectedItem.equipmentNumber,
            deliveryLocation: selectedItem.deliveryLocation
        }));
    };

    const getInitialValues = (): object => ({
        documentsPARNRequired: props.equipmentDetails.documentsPARNRequired,
        documentsCRMRequired: props.equipmentDetails.documentsCMRRequired,
        isDangerousGoods: props.equipmentDetails.isDangerousGoods,
        dangerousGoodsUNNO: props.equipmentDetails.dangerousGoodsUNNO,
        dangerousGoodsClass: props.equipmentDetails.dangerousGoodsClass,
        dangerousGoodsDescription: props.equipmentDetails.dangerousGoodsDescription,
        isDangerousGoodsADR: props.equipmentDetails.dangerousGoodsADRRequired,
        comments: props.equipmentDetails.nonConformanceComment,
        hbLs: props.equipmentDetails.hbLs,
    });

    const disableIfDeliveriesNotEqual = () => {
        const selectedItems = dataTable.current ? dataTable.current.getSelectedItems() : [];

        return uniqWith(selectedItems.map((selectedItem: any) => {
            return {
                deliveryComments: selectedItem.deliveryComments,
                deliveryReference: selectedItem.deliveryReference,
                dropOffDate: selectedItem.dropOffDate,
                dropOffLocation: selectedItem.dropOffLocation,
                estimatedDropOffDate: selectedItem.estimatedDropOffDate,
                estimatedPickupDate: selectedItem.estimatedPickupDate,
                haulier: selectedItem.haulier,
                isTransportOrderSent: selectedItem.isTransportOrderSent,
                pickupDate: selectedItem.pickupDate,
                pickupLocation: selectedItem.pickupLocation,
                transportMode: selectedItem.transportMode,
                transportType: selectedItem.transportType
            };
        }), isEqual);
    };

    const toggleErrorDialog = (): void => setModals({isErrorDialogOpened: !modals.isErrorDialogOpened});

    const checkIfCanEditTransportationDetails = (): void => {
        if(disableIfDeliveriesNotEqual().length === 1) {
            setModals({ isErrorDialogOpened: false });
            toggleEditTransportModal();
        } else {
            setModals({isErrorDialogOpened: true});
            setErrorMessage('Deliveries are not equal');
        }
    };

    const exportToExcelForSelectedItems = async (downloadForReImport: boolean): Promise<void> => {
        const selectedItems = dataTable.current ? dataTable.current.getSelectedItems() : [];
        const ids = selectedItems.map((selectedItem: any) => selectedItem.id);
        const res = await props.exportEquipmentPlanningToExcel(false, downloadForReImport, ids);
        downloadFile(res);
    };

    const exportToExcelTransportDetails = async (): Promise<void> => {
        const selectedItems = dataTable.current ? dataTable.current.getSelectedItems() : [];
        const ids = selectedItems.map((selectedItem: any) => selectedItem.id);
        const res = await props.exportTransportDetailsToExcel(false, false, ids);
        downloadFile(res);
    };

    const downloadFinalDeliveryDetailsTemplate = async (): Promise<void> => {
        // [0] needed for download excel file as a template
        const res = await props.exportEquipmentPlanningToExcel(false, true, [0]);
        downloadFile(res);
    };

    const downloadTransportDetailsTemplate = async (): Promise<void> => {
        const res = await props.exportTransportDetailsToExcel(false, true);
        downloadFile(res);
    };

    const toggleImportModal = () => setModals({importModalIsOpened: !modals.importModalIsOpened});

    const uploadFinalDeliveryDetails = async (ref: any): Promise<void> => {
        sendFileAsBase64(ref, async (val: File) => {
            await props.importFinalDeliveryDetails(false, val);
            await ref.current.uploadComplete();
        });
    };

    const uploadTransportDetails = async (ref: any): Promise<void> => {
        sendFileAsBase64(ref, async (val: File) => {
            await props.importTransportDetails(false, val);
            await ref.current.uploadComplete();
        });
    };

    const data = props.data.map((obj: any) => ({
        ...obj,
        equipmentNumber: [obj.equipmentNumber, `/planning/${obj.id}`],
        deliveryStatusDisplayName: mapStatusToStringForDP(obj.deliveryStatus),
        deRigStatusDisplayName: DERIG_STATUSES[obj.deRigStatus] || '-',
        children: obj.deliveries.map((child: any) => ({
            ...child,
            transportModeDisplayName: Transport_Mode[child.transportMode],
            transportTypeDisplayName: Transport_Type[child.transportType],
        }))
    }));

    return(
        <React.Fragment>
            {props.isLoading && <LoadingAnimation />}
            {modals.isErrorDialogOpened && <Dialog 
                isVisible={modals.isErrorDialogOpened}
                message={errorMessage} 
                closeDialog={toggleErrorDialog}
            />}
            <DataTable
                data={data}
                withCheckboxes={true}
                expandable={true}
                selectVariant="multiple"
                columns={[
                    ColumnFactory.linkedColumn('equipmentNumber', 'Equipment number'),
                    ColumnFactory.standardColumn('equipmentSizeTypeDisplayName', 'Size/Type'),
                    ColumnFactory.standardColumn('isHotBoxDisplayName', 'Hotbox'),
                    ColumnFactory.standardColumn('isDangerousGoodsDisplayName', 'DG'),
                    ColumnFactory.standardColumn('newETADisplayName', 'New ETA'),
                    ColumnFactory.standardColumn('ataDisplayName', 'ATA'),
                    ColumnFactory.standardColumn('demurrageDisplayName', 'Demurrage'),
                    ColumnFactory.standardColumn('availableForBookingDisplayName', 'Available For Booking'),
                    ColumnFactory.standardColumn('deliveryLocationDisplayName', 'Delivery location'),
                    ColumnFactory.standardColumn('clientDeliveryDisplayName', 'Delivery date'),
                    ColumnFactory.standardColumn('isGOHDisplayName', 'GOH'),
                    ColumnFactory.standardColumn('deRigStatusDisplayName', 'Derig status'),
                    ColumnFactory.standardColumn('deliveryStatusDisplayName', 'Status'),
                ]}
                subColumns={[
                    ColumnFactory.standardColumn('transportModeDisplayName', 'Transport Mode'),
                    ColumnFactory.standardColumn('transportTypeDisplayName', 'Transport Type'),
                    ColumnFactory.standardColumn('haulierDisplayName', 'Haulier'),
                    ColumnFactory.standardColumn('isTransportOrderSentDisplayName', 'TO Sent'),
                    ColumnFactory.standardColumn('pickupLocationDisplayName', 'Pickup location'),
                    ColumnFactory.standardColumn('estimatedPickupDateDisplayName', 'Pickup date'),
                    ColumnFactory.standardColumn('dropOffLocationDisplayName', 'Drop-off location'),
                    ColumnFactory.standardColumn('estimatedDropOffDateDisplayName', 'Drop-off date'),
                    ColumnFactory.standardColumn('deliveryReferenceDisplayName', 'Delivery reference'),
                ]}
                actions={{
                    primaryActionIsMoreBtn: false,
                    primaryActionLabel: 'Export to excel',
                    primaryAction: exportToExcel,
                    secondaryActions: [
                        {isMoreBtn: false, label: 'Import from excel', action: toggleImportModal},
                    ],
                    parentContextualActions: getArrayOfButtons(
                        props.permissions,
                        [
                            { isMoreBtn: false, label: 'Update final delivery details', action: toggleEditDeliveryDetailsModal },
                            { isMoreBtn: false, label: 'Update transport details', action: checkIfCanEditTransportationDetails },
                            { isMoreBtn: true, moreBtnActions: [
                                {label: 'Send Trucking Transport Order', action: () => toggleSendTransportOrderToHaulierModal(Transport_Order_Type.Trucking)},
                                {label: 'Send De-Rig Transport Order', action: () => toggleSendTransportOrderToHaulierModal(Transport_Order_Type.DeRig)},
                                {label: 'Send Dark Storage Transport Order', action: () => toggleSendTransportOrderToHaulierModal(Transport_Order_Type.DarkStorage)},
                                {label: 'Send Rail Transport Order', action: () => toggleSendTransportOrderToHaulierModal(Transport_Order_Type.Rail)},
                                {label: 'Send Barge Transport Order', action: () => toggleSendTransportOrderToHaulierModal(Transport_Order_Type.Barge)},
                                {label: 'Export selected', action: () => exportToExcelForSelectedItems(false)},
                                {label: 'Export for update final delivery details', action: () => exportToExcelForSelectedItems(true)},
                                {label: 'Export for update transport details', action: () => exportToExcelTransportDetails()},
                            ]},
                        ])
                }}
                reference={dataTable}
                tableName="PlanningTable"
                manualPagination={true}
                fetchData={props.fetchEquipments}
                pageCount={props.pageCount}
                showColumnOptions={true}
            />
            {modals.isEditDeliveryDetailsModalOpened && <EditDeliveryDetailsModal
                visible={modals.isEditDeliveryDetailsModalOpened}
                closeModal={toggleEditDeliveryDetailsModal}
                ids={getIds()}
                refetchFunction={props.getEquipmentsList}
                isAir={false}
                isFinal={true}
                equipments={getEquipmentsNumbers()}
            />}
            {modals.isEditTransportDetailsOpened && <EditTransportationDetailsModal
                visible={modals.isEditTransportDetailsOpened}
                closeModal={toggleEditTransportModal}
                ids={getIds()}
                initialValues={getInitialValues()}
            />}
            {modals.isSendTransportOrderToHaulierOpened && <SendTransportOrderToHaulierModal
                visible={modals.isSendTransportOrderToHaulierOpened}
                closeModal={toggleSendTransportOrderToHaulierModal}
                transportConfig={transportOrderType}
                transportOrderName={transportOrderName}
                id={getIds()}
                isAir={false}
            />}
            {modals.importModalIsOpened && <ModalImport
                uploadFinalDeliveryDetails={uploadFinalDeliveryDetails}
                uploadTransportDetails={uploadTransportDetails}
                cancelMethod={() => null}
                downloadFinalDeliveryDetails={downloadFinalDeliveryDetailsTemplate}
                downloadTransportDetails={downloadTransportDetailsTemplate}
                closeModal={toggleImportModal}
                fileFormats=".xlsx"
                downloadTitle="Download template"
                visible={modals.importModalIsOpened || false}
                errors={props.errors}
            />}
        </React.Fragment>
    );
}

const ROLES: BUSINNESS_FUNCTIONS[] = [
    BUSINNESS_FUNCTIONS.OCEAN_PLANNING_EDIT_DELIVERY_DETAILS,
    BUSINNESS_FUNCTIONS.OCEAN_PLANNING_EDIT_TRANSPORTATION_DETAILS,
    BUSINNESS_FUNCTIONS.OCEAN_PLANNING_SEND_TRANSPORT_ORDER
];

const mapStateToProps = (state: IStore): IMapStateToProps => ({
    equipmentDetails: state.planning.equipmentDetails,
    errors: state.importReducer.importPlanningOceanErrors,
});

const mapDispatchToProps = (dispatch: ThunkDispatch<any, any, Action>): IMapDispatchToProps => ({
    exportEquipmentPlanningToExcel: (isAir: boolean, downloadForReImport: boolean = false, ids?: number[]) => dispatch(exportEquipmentPlanningToExcel(isAir, downloadForReImport, ids)),
    exportTransportDetailsToExcel: (isAir: boolean, onlyTemplate: boolean, ids?: number[]) => dispatch(exportTransportDetailsToExcel(isAir, onlyTemplate, ids)),
    getEquipmentsList: () => dispatch(getEquipmentsList()),
    getEquipmentDetails: (id: number) => dispatch(getEquipmentDetails(id)),
    importFinalDeliveryDetails: (isAir: boolean, file: File) => dispatch(importFinalDeliveryDetails(isAir, file)),
    importTransportDetails: (isAir: boolean, file: File) => dispatch(importTransportDetails(isAir, file)),
});

const __PlanningTable = connect<IMapStateToProps, IMapDispatchToProps, IPlanningTableOwnProps, IStore>(mapStateToProps, mapDispatchToProps)(_PlanningTable);
export const PlanningTable = withPermissions(__PlanningTable, ROLES) as any;
