import React, { Component } from 'react';
import { connect } from 'react-redux';
import { FiltersInput } from 'modules/filters/components/FiltersInput';
import { Page } from 'components/page-container';
import { Filters } from 'modules/filters/containers/Filters';
import { PageHeader } from 'components/page-header';
import { ThunkDispatch } from 'redux-thunk';
import { Action } from 'redux';
import { fetchEquipmentsList } from 'actions/fact';
import { IStore } from 'reducers/index';
import { IEquipments } from 'reducers/fact/models';
import { ILink } from 'components/sub-navigation/models/Link';
import { SubNavigation } from 'components/sub-navigation';
import { EquipmentsTable } from 'modules/fact/components/EquipmentsTable';
import { ISortModel } from 'reducers/filters/models';
import { setSortForTable } from 'utilities/tables';
import { setSort, resetSort } from 'actions/filters';

const links: ILink[] = [
    {
        to: '/exception-management/fact/documents',
        linkText: 'Documents',
    },
    {
        to: '/exception-management/fact/equipments',
        linkText: 'Equipments',
    }, 
    {
        to: '/exception-management/fact/cbls',
        linkText: 'Cbls',
    }, 
    {
        to: '/exception-management/fact/isso',
        linkText: 'ISSO',
    }, 
    {
        to: '/exception-management/fact/esso',
        linkText: 'ESSO',
    }
];

interface IMapDispatchToProps {
    fetchEquipmentsList: (pageNumber: number, pageSize: number) => Promise<void>;
    setSort: (sort: ISortModel) => void;
    resetSort: () => void;
}

interface IMapStateToProps {
    equipmentsList: IEquipments;
    equipmentsListPendingError: string | string[];
    isEquipmentListLoading: boolean;
}

interface IEquipmentsListState {
    page: number;
    pageSize: number;
}

interface IEquipmentsListProps extends IMapStateToProps, IMapDispatchToProps {}

class _EquipmentsList extends Component<IEquipmentsListProps, IEquipmentsListState> {
    public state = {
        page: 1,
        pageSize: 10,
    };

    public render() {
        const filters = [
            <FiltersInput
                key="equipmentNumber"
                name="equipmentNumber"
                label="Equipment number"
            />,
            <FiltersInput
                key="sealNumber"
                name="sealNumber"
                label="Seal number"
            />,
        ];
    
        return(
            <React.Fragment>
                <PageHeader>Equipments</PageHeader>
                <Page>
                    <Filters
                        tableNameKey="fact"
                        fetchFunction={() => this.props.fetchEquipmentsList(this.state.page, this.state.pageSize)}
                        filters={filters}
                    />
                    <SubNavigation links={links} />
                    <EquipmentsTable
                        data={this.props.equipmentsList.result}
                        isLoading={this.props.isEquipmentListLoading}
                        fetchEquipments={this.fetchEquipments}
                        pageCount={Math.ceil(this.props.equipmentsList.totalCount / this.props.equipmentsList.pageSize)}
                    />
                </Page>
            </React.Fragment>
        );
    }

    private fetchEquipments = (tableData: any) => {
        this.setState({
            page: tableData.page + 1,
            pageSize: tableData.pageSize
        }, () => {
            setSortForTable(tableData.sorted, this.props.setSort);
            this.props.fetchEquipmentsList(this.state.page, this.state.pageSize);
        });
    };
}

const mapStateToProps = (state: IStore): IMapStateToProps => ({
    equipmentsList: state.fact.equipmentList,
    equipmentsListPendingError: state.fact.equipmentListPendingError,
    isEquipmentListLoading: state.fact.isEquipmentListLoading
});

const mapDispatchToProps = (dispatch: ThunkDispatch<any, any, Action>): IMapDispatchToProps => ({
    fetchEquipmentsList: (pageNumber: number, pageSize: number) => dispatch(fetchEquipmentsList(pageNumber, pageSize)),
    setSort: (sort: ISortModel) => dispatch(setSort(sort)),
    resetSort: () => dispatch(resetSort())
});

export const EquipmentsList = connect<IMapStateToProps, IMapDispatchToProps, {}, IStore>(mapStateToProps, mapDispatchToProps)(_EquipmentsList);
