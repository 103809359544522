import { count } from 'utilities/util';

export const updateButtonOnAccordionsChange = (state: object) => {
    const collapseStatus: boolean[] = [];

    for (const property in state) {
        if(property.includes('accordion_')) {
            collapseStatus.push(state[property]);
        }
    }

    const countTrue = count(collapseStatus, true);
    const countFalse = count(collapseStatus, false);

    if(countTrue > countFalse && countTrue === collapseStatus.length) {
        return 'Expand all';
    } else if(countTrue < countFalse && countFalse === collapseStatus.length) {
        return 'Collapse all';
    } else if(countTrue > countFalse && countTrue !== collapseStatus.length) {
        return 'Collapse all';
    } else if(countTrue < countFalse && countFalse !== collapseStatus.length) {
        return 'Expand all';
    } else if(countTrue === countFalse ) {
        return 'Collapse all';
    } else {
        return 'Collapse all';
    }
};