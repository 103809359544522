import React, { FunctionComponent, useRef } from 'react';
import { ColumnFactory } from 'components/data-table/ColumnFactory';
import { DataTable } from 'components/data-table/DamcoTable';
import { IEventLog } from 'reducers/event-logs/models';

interface EventLogsTableProps {
    data: IEventLog[];
    pageCount: number;
    fetchEvents: any;
    openDialog(eventLog: IEventLog): void;
}

export const EventLogsTable: FunctionComponent<EventLogsTableProps> = (props) => {
    const dataTable = useRef<any>();

    const openEventLogsDetailsModal = () => {
        const selectedItems = dataTable.current ? dataTable.current.getSelectedItems() : [];

        if(selectedItems[0]) {
            props.openDialog(selectedItems[0]);
        }
    };

    const data = props.data.map((obj: any, index: number) => ({
        ...obj,
        id: index
    }));

    return (
        <DataTable
            data={data}
            withCheckboxes={true}
            selectVariant="single"
            expandable={false}
            columns={[
                ColumnFactory.standardColumn('eventLogDateDisplayName', 'Date'),
                ColumnFactory.standardColumn('runId', 'Run ID'),
                ColumnFactory.standardColumn('statusDisplayName', 'Status'),
                ColumnFactory.standardColumn('systemDisplayName', 'System'),
                ColumnFactory.standardColumn('messageDisplayName', 'Message'),
                ColumnFactory.standardColumn('typeDisplayName', 'Type'),
                ColumnFactory.standardColumn('capabilityDisplayName', 'Capability'),
                ColumnFactory.standardColumn('cblNumberDisplayName', 'CBL Number'),
                ColumnFactory.standardColumn('vesselNumberDisplayName', 'Vessel Number'),
                ColumnFactory.standardColumn('voyageNumberDisplayName', 'Voyage Number'),
                ColumnFactory.standardColumn('equipmentNumberDisplayName', 'Equipment Number'),
                ColumnFactory.standardColumn('poNumberDisplayName', 'PO Number'),
                ColumnFactory.standardColumn('correlationIdDisplayName', 'Correlation ID'),
                ColumnFactory.standardColumn('byUserDisplayName', 'Triggered by'),
            ]}
            actions={{
                parentContextualActions: [
                    { isMoreBtn: false, label: 'Event logs details', action: openEventLogsDetailsModal }
                ],
            }}
            reference={dataTable}
            tableName="EventLogsList"
            showColumnOptions={true}
            manualPagination={true}
            fetchData={props.fetchEvents}
            pageCount={props.pageCount}
        />
    );
}
