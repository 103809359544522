import React, { FunctionComponent } from 'react';
import { DoubleGrid } from 'components/grid/double-grid';
import { FormInput } from 'components/input/FormInput';
import { ModalScreen } from 'components/modal-screen';
import { ModalType } from 'components/modal-screen/ModalType';
import { InjectedFormProps, reduxForm, reset } from 'redux-form';
import { required } from 'utilities/validate';
import { ErrorDialog } from 'components/error-dialog';
import API from 'constants/api';
import { Dispatch } from 'redux';

interface IGOHModalOwnProps {
    title: string;
    closeModal: () => void;
    saveFunction: (data: any) => void;
}

interface IGOHModal extends IGOHModalOwnProps, InjectedFormProps<{}, IGOHModalOwnProps> {}

const FORM_NAME = 'ADD_EDIT_GOH_MODAL';

const _GOHModal: FunctionComponent<IGOHModal> = (props) => (
    <form onSubmit={props.handleSubmit(props.saveFunction)}>
        <ModalScreen
            title={props.title}
            visible={true}
            modalType={ModalType.s()}
            closeModal={props.closeModal}
            primaryButtonTitle="Save"
            primaryButtonFunc={() => null}
            primaryButtonType="submit"
            secondaryButtonTitle="Cancel"
            secondaryButtonFunc={props.closeModal}
        >
            <ErrorDialog endpoint={API.Settings.UpdateFactOfficePrefixes} />
            <DoubleGrid>
                <FormInput label="GOH Flag" name="gohFlag" validate={[required]} />
                <FormInput label="Type" name="type" validate={[required]} />
            </DoubleGrid>
            <DoubleGrid>
                <FormInput label="Subtype" name="subtype" validate={[required]} />
                <FormInput label="Result container type" name="resultContainerType" validate={[required]} />
            </DoubleGrid>
        </ModalScreen>
    </form>
);

const afterSubmit = (_: any, dispatch: Dispatch) => dispatch(reset(FORM_NAME));

export const GOHModal = reduxForm<any, IGOHModalOwnProps>({
    form: FORM_NAME,
    onSubmitSuccess: afterSubmit,
    enableReinitialize: true,
})(_GOHModal);
