import React from 'react';
import { ColumnFactory } from 'components/data-table/ColumnFactory';
import { IPO } from 'reducers/planning/models';
import { DataTable } from 'components/data-table/DamcoTable';
import { cloneDataWithDisplayNames } from 'utilities/tables';

interface IDeliveryBottomTable {
    data: IPO[];
}

export const DeliveryBottomTable: React.SFC<IDeliveryBottomTable> = (props: IDeliveryBottomTable) => {
    const data = cloneDataWithDisplayNames(props.data ? props.data : []).map((obj: any) => ({
        ...obj,
        children: obj.skUs
    }));
    
    return(
        <DataTable
            data={data}
            expandable={true}
            withCheckboxes={false}
            columns={[
                ColumnFactory.standardColumn('poNumberDisplayName', 'PO number')
            ]}
            subColumns={[
                ColumnFactory.standardColumn('skuNumberDisplayName', 'SKU'),
                ColumnFactory.standardColumn('skuGoodsDescriptionDisplayName', 'Description'),
                ColumnFactory.standardColumn('skuPackagesDisplayName', 'Packages'),
                ColumnFactory.standardColumn('skuPieceTypeDisplayName', 'Piece type'),
                ColumnFactory.standardColumn('skuTermsDisplayName', 'Terms'),
            ]}
            reference={null as any}
            tableName="DeliveryPlanningDetailsPOTable"
            showPagination={true}
        />
    );
};
