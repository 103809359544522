import React, { FunctionComponent, useEffect } from 'react';
import { Page } from 'components/page-container';
import { PageHeader } from 'components/page-header';
import { MawbDetailsTable } from './components/MawbDetailsTable';
import { PageWithTimeline } from 'components/page-with-timeline';
import { ThunkDispatch } from 'redux-thunk';
import { IStore } from 'reducers/index';
import { RouteComponentProps } from 'react-router-dom';
import { Action } from 'redux';
import { fetchMAWBDetails } from 'actions/vessels';
import { connect } from 'react-redux';
import { IMAWBDetails, IBubble } from 'reducers/vessels/models';
import { MawbDetailsTopBar } from './components/MawbDetailsTopBar';
import { LoadingAnimation } from 'components/loading';
import { ContainerTrackingTypes } from 'constants/comment';
import { UserDetails } from 'components/user-details';
import { ProgressIndicator } from 'components/progress-indicator';
import { Capability } from 'components/timeline';
import { MawbVesselsTable } from './components/MawbDetailsVesselTable';
import { GenericError } from 'components/error-dialog/GenericError';

interface IParams {
    blId: string;
}

interface IDispatch {
    fetchMAWBDetails: (blId: number) => void;
}

interface IMapStateToProps {
    mawbDetails: IMAWBDetails;
    isLoading: boolean;
    bubbles: IBubble[];
}

interface IBLDetailsProps extends RouteComponentProps<IParams>, IDispatch, IMapStateToProps {}

const _MawbFlightDetails: FunctionComponent<IBLDetailsProps> = (props) => {
    const blId = parseInt(props.match.params.blId, 10);

    useEffect(() => {
        if (!Number.isNaN(blId)) {
            props.fetchMAWBDetails(blId);
        }
    }, []);

    if (Number.isNaN(blId)) {
        return <GenericError />;
    }

    return (
        <React.Fragment>
            {props.isLoading && <LoadingAnimation />}
            <PageHeader backButton={true}>MAWB: {props.mawbDetails.MAWB}</PageHeader>
            <ProgressIndicator data={props.bubbles} />
            <MawbDetailsTopBar
                {...props.mawbDetails.vessels[0]}
                blId={blId}
                threePl={props.mawbDetails['3PL']}
                fourPLCarrierName={props.mawbDetails.fourPLCarrierName}
                fourPLReference={props.mawbDetails.fourPLReference}
            />
            <Page>
                <PageWithTimeline
                    objectId={blId}
                    objectType={ContainerTrackingTypes.Cbl}
                    isAir={true}
                    capability={Capability.ContainerTracking}
                    additionalSources={{
                        objectTypes: props.mawbDetails.vessels.map(_ => ContainerTrackingTypes.Voyage),
                        ids: props.mawbDetails.vessels.map(mawb => mawb.voyageId)
                    }}
                >
                    <MawbVesselsTable data={props.mawbDetails.vessels} threePl={props.mawbDetails['3PL']} />
                    <MawbDetailsTable data={...props.mawbDetails.equipments}/>
                    <UserDetails
                        user={props.mawbDetails.lastUserUpdated}
                        date={props.mawbDetails.updateDate}
                    />
                </PageWithTimeline>
            </Page>
        </React.Fragment>
    );
}

const mapStateToProps = (state: IStore): IMapStateToProps => ({
    mawbDetails: state.vessels.mawbDetails,
    isLoading: state.vessels.isLoading,
    bubbles: state.vessels.mawbDetails.bubbleData,
});

const mapDispatchToProps = (dispatch: ThunkDispatch<any, any, Action>): IDispatch => ({
    fetchMAWBDetails: (blId: number) => dispatch(fetchMAWBDetails(blId)),
});

export const MawbFlight = connect<IMapStateToProps, IDispatch, {}, IStore>(mapStateToProps, mapDispatchToProps)(_MawbFlightDetails);
