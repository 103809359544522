import React from 'react';
import { DataTable as DamcoTable } from 'damco-components';
import { IActions } from './models/actions';

interface IDataTableProps {
    data: any;
    columns: any;
    subColumns?: any;
    withCheckboxes: boolean;
    selectVariant?: 'single' | 'multiple';
    tableName: string;
    actions?: IActions;
    getTdProps?: any;
    reference: any;
    withFooter?: boolean;
    showPagination?: boolean;
    expandable?: boolean;
    manualPagination?: boolean;
    pageCount?: number;
    fetchData?: any;
    id?: string;
    showColumnOptions?: boolean;
    pageSize?: number;
}

export const DataTable: React.StatelessComponent<IDataTableProps> = (props) => {
    return(
        <DamcoTable
            id={props.id}
            data={props.data}
            columns={props.columns}
            subColumns={props.subColumns}
            isSelectable={props.withCheckboxes}
            selectedOption={props.selectVariant}
            isSortable={true}
            isResizable={true}
            isEditable={false}
            hasFooter={props.withFooter}
            isExpandable={props.expandable}
            tableName={props.tableName}
            actions={props.actions}
            height="auto"
            getTdProps={props.getTdProps}
            ref={props.reference}
            showPagination={props.showPagination ? true : props.showPagination}
            manualPagination={props.manualPagination}
            pageCount={props.pageCount}
            pageSize={props.pageSize}
            fetchData={props.fetchData}
            showColumnOptions={!!props.showColumnOptions}
        />
    );
}