import styled from 'styled-components';

export const UserAreaContainer: any = styled.div`
    height: 63px;
    display: flex;
    align-items: center;
`;

export const Settings: any = styled.button`
    border: 0;
    background: transparent;
    margin-right: 5px;

    &:focus {
        outline: none;
    }

    i {
        font-size: 30px;
        height: 30px;
        width: 30px;
        color: lightgray;
        transition: .2s ease-in-out;

        &:hover {
            color: black;
            transition: .2s ease-in-out;
        }
    }
`;

export const Button: any = styled.button`
    height: 100%;
    border: 0;
    padding: 0 10px;
    background: transparent;
    border-left: 1px solid transparent;
    border-right: 1px solid transparent;

    &:focus {
        outline: none;
    }

    i {
        font-size: 28px;
        height: 28px;
        width: 28px;
        color: #5E6167;
        transition: .2s ease-in-out;

        &:hover {
            color: black;
            transition: .2s ease-in-out;
        }
    }
`;