import React, { Component } from 'react';
import { PageHeader } from 'components/page-header';
import { Page } from 'components/page-container';
import { Filters } from 'modules/filters/containers/Filters';
import { FiltersInput } from 'modules/filters/components/FiltersInput';
import { FiltersDateInput } from 'modules/filters/components/FiltersDateInput';
import { FiltersSelect } from 'modules/filters/components/FiltersSelect';
import { connect } from 'react-redux';
import { fetchMawbList } from 'actions/vessels';
import { ThunkDispatch } from 'redux-thunk';
import { Action } from 'redux';
import { IStore } from 'reducers/index';
import { statusesValues } from 'utilities/util';
import { setSortForTable } from 'utilities/tables';
import { MawbTable } from 'modules/tracking/mawb/components/MawbTable';
import { CT_STATUS } from 'constants/statuses';
import { LoadingAnimation } from 'components/loading';
import { HandleError } from 'components/handle-error';
import { setSort, resetSort } from 'actions/filters';
import { ISortModel } from 'reducers/filters/models';

interface IMapToStateProps {
    isLoading: boolean;
    mawbList: {
        pageNumber: number;
        totalCount: number;
        pageSize: number;
        data: any[];
    };
    isExportLoading: boolean;
    containerTrackingError: string;
    etdEmpty: boolean;
    atdEmpty: boolean;
    newEtaEmpty: boolean;
    ataEmpty: boolean;
}

interface IDispatchProps {
    fetchMawbList: (pageNumber: number, pageSize: number) => Promise<void>;
    setSort: (sort: ISortModel) => void;
    resetSort: () => void;
}

interface IMawbList extends IMapToStateProps, IDispatchProps {}

interface IMawbListState {
    page: number;
    pageSize: number;
}

export class _MawbList extends Component<IMawbList, IMawbListState> {
    public state = {
        page: 1,
        pageSize: 10
    };

    public render() {
        const filters = [
            <FiltersInput
                key="cblNumber"
                name="cblNumber"
                label="MAWB Number"
            />,
            <FiltersInput
                key="pol"
                name="polName"
                label="POL"
            />,
            <FiltersInput
                key="pod"
                name="podName"
                label="POD"
            />,
            <FiltersDateInput
                key="etd"
                name="etd_from"
                label="ETD (from)"
                type="date"
                withTime={true}
                relatedField="etd"
                readOnly={this.props.etdEmpty}
            />,
            <FiltersDateInput
                key="etd"
                name="etd_to"
                label="ETD (to)"
                type="date"
                withTime={true}
                relatedField="etd"
                readOnly={this.props.etdEmpty}
            />,
            <FiltersDateInput
                key="atd"
                name="atd_from"
                label="ATD (from)"
                type="date"
                withTime={true}
                relatedField="atd"
                readOnly={this.props.atdEmpty}
            />,
            <FiltersDateInput
                key="atd"
                name="atd_to"
                label="ATD (to)"
                type="date"
                withTime={true}
                relatedField="atd"
                readOnly={this.props.atdEmpty}
            />,
            <FiltersDateInput
                key="neweta_from"
                name="neweta_from"
                label="New ETA (from)"
                type="date"
                withTime={true}
                relatedField="newEta"
                readOnly={this.props.newEtaEmpty}
            />,
            <FiltersDateInput
                key="neweta_to"
                name="neweta_to"
                label="New ETA (to)"
                type="date"
                withTime={true}
                relatedField="newEta"
                readOnly={this.props.newEtaEmpty}
            />,
            <FiltersDateInput
                key="ata"
                name="ata_from"
                label="ATA (from)"
                type="date"
                withTime={true}
                relatedField="ata"
                readOnly={this.props.ataEmpty}
            />,
            <FiltersDateInput
                key="ata"
                name="ata_to"
                label="ATA (to)"
                type="date"
                withTime={true}
                relatedField="ata"
                readOnly={this.props.ataEmpty}
            />,
            <FiltersSelect
                key="status"
                name="status"
                label="Status"
                type="number"
                options={statusesValues(CT_STATUS)}
            />,
            <FiltersInput
                key="PONumber"
                name="PONumber"
                label="PO Number"
            />,
            <FiltersInput
                key="lastUserUpdated"
                name="lastUserUpdated"
                label="Last updated by"
            />,
            <FiltersInput
                key="fourPLCarrierName"
                name="fourPLCarrierName"
                label="Actual carrier"
            />,
            <FiltersInput
                key="fourPLReference"
                name="fourPLReference"
                label="Reference"
            />,
        ];
        return (
            <>
                <PageHeader>MAWB Tracking</PageHeader>
                <Page>
                    {this.props.isExportLoading && <LoadingAnimation />}
                    <HandleError
                        containerTrackingError={this.props.containerTrackingError}
                    />
                    <Filters
                        tableNameKey="tracking-mawb"
                        filters={filters}
                        fetchFunction={() => this.props.fetchMawbList(this.state.page, this.state.pageSize)}
                        withoutPagination={true}
                    />
                    <MawbTable
                        data={this.props.mawbList.data}
                        isLoading={this.props.isLoading}
                        pageCount={Math.ceil(this.props.mawbList.totalCount / this.props.mawbList.pageSize)}
                        fetchMawbList={this.fetchMawbList}
                    />
                </Page>
            </>
        );
    }

    public componentWillUnmount() {
        this.props.resetSort();
    }

    private fetchMawbList = (tableData: any) => {
        this.setState({
            page: tableData.page + 1,
            pageSize: tableData.pageSize
        }, () => {
            setSortForTable(tableData.sorted, this.props.setSort);
            this.props.fetchMawbList(this.state.page, this.state.pageSize);
        });
    };
}

const mapStateToProps = (state: IStore): IMapToStateProps => ({
    mawbList: state.vessels.mawbList,
    isLoading: state.vessels.isLoading,
    isExportLoading: state.exportReducer.isExportContainerTrackingLoading,
    containerTrackingError: state.exportReducer.exportContainerTrackingError,
    etdEmpty: state.filters.filters.etd_empty,
    atdEmpty: state.filters.filters.atd_empty,
    newEtaEmpty: state.filters.filters.neweta_empty,
    ataEmpty: state.filters.filters.ata_empty,
});

const mapDispatchToProps = (dispatch: ThunkDispatch<any, any, Action>): IDispatchProps => ({
    fetchMawbList: (pageNumber: number, pageSize: number) => dispatch(fetchMawbList(pageNumber, pageSize)),
    setSort: (sort: ISortModel) => dispatch(setSort(sort)),
    resetSort: () => dispatch(resetSort()),
});

export const MawbList = connect<IMapToStateProps, IDispatchProps, {}, IStore>(mapStateToProps, mapDispatchToProps)(_MawbList);
