import React from 'react';
import { StatusRow } from 'components/status-row';
import { OneStatus } from 'components/status-row/OneStatus';
import { QuarterGrid } from 'components/grid/quarter-grid';
import { formatValueIfNullOrUndefined } from 'utilities/util';

interface ILastUpdateRowProps {
    lastUpdatedBy: string;
    lastUpdatedByDocumentId: number;
}

export const LastUpdateRow: React.FunctionComponent<ILastUpdateRowProps> = (props) => {
    return (
        <StatusRow title="Last update">
            <QuarterGrid>
                <OneStatus label="Last updated by">
                    {formatValueIfNullOrUndefined(props.lastUpdatedBy)}
                </OneStatus>
                <OneStatus label="Last updated by document ID">
                    {formatValueIfNullOrUndefined(props.lastUpdatedByDocumentId)}
                </OneStatus>
            </QuarterGrid>
        </StatusRow>
    );
};
