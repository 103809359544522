import React, { FunctionComponent } from 'react';
import { FormSelect } from 'components/select/FormSelect';
import { connect } from 'react-redux';
import { IStore } from 'reducers/index';
import { LoadingAnimation } from 'components/loading';
import { IOption } from 'components/select/Select';
import { IReasonCode } from 'reducers/settings/models';

interface IDeliveryPlanningReasonCodesSelect {
    name: string;
    label: string;
    isEmptyReturn?: boolean;
    disabled?: boolean;
    reasonCodeGroup: string;
}

interface IMapStateToProps {
    reasonCodes: IReasonCode[];
    isLoading: boolean;
}

interface Props extends IDeliveryPlanningReasonCodesSelect, IMapStateToProps {};

const _DeliveryPlanningReasonCodesSelect: FunctionComponent<Props> = (props) => {
    const options = props.reasonCodes.map(code => ({
        value: code.id,
        optionText: code.reasonCode
    }));

    return (
        <React.Fragment>
            {props.isLoading && <LoadingAnimation/>}
            <FormSelect
                options={[{
                    value: '',
                    optionText: 'Select value...',
                } as IOption].concat(options)}
                disabled={!!props.disabled}
                {...props}
            />
        </React.Fragment>
    );
}

const mapStateToProps = (state: IStore): IMapStateToProps => ({
    reasonCodes: state.planning.reasonCodes,
    isLoading: state.planning.isGetDPReasonCodesPending,
});

export const DeliveryPlanningReasonCodesSelect = connect(mapStateToProps)(_DeliveryPlanningReasonCodesSelect);
