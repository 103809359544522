import React from 'react';
import styled from 'styled-components';
import { PropertiesRibbon } from 'damco-components';
import { IContent } from './models';

const SPropertiesRibbonWrapper = styled.div`
    max-width: 100%;

    .section-header {
        margin: 15px 0;
    }
`;

interface IPropertiesRibbonWrapperProps {
    content: IContent;
}

export const PropertiesRibbonWrapper: React.StatelessComponent<IPropertiesRibbonWrapperProps> = (props: IPropertiesRibbonWrapperProps) => (
    <SPropertiesRibbonWrapper>
        <PropertiesRibbon content={props.content} />
    </SPropertiesRibbonWrapper>
);