import React from 'react';
import { RouteComponentProps, withRouter } from 'react-router';

const _GenericError = (props: RouteComponentProps<{}>) => (
    <div className="message failed">
        <i aria-hidden="true" className="fa fa-times-circle fa-3x" />

        <span>
            Something went wrong.&nbsp;
            <a href="#" onClick={props.history.goBack}>
                Go back
            </a>
        </span>
    </div>
);

export const GenericError = withRouter(_GenericError);
