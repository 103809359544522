import React from 'react';
import { PageHeader } from 'components/page-header';
import { Page } from 'components/page-container';
import { ILink } from 'components/sub-navigation/models/Link';
import { Redirect, Switch } from 'react-router';
import { DeliveryPlanning } from 'views/exception-management/settings/delivery-planning';
import { Customs } from 'views/exception-management/settings/customs';
import { ContainerTracking } from 'views/exception-management/settings/container-tracking';
import { DemurrageAndDetention } from 'views/exception-management/settings/demurrage-and-detention';
import { RouteWithPermissions } from 'containers/route-with-permissions';
import { BUSINNESS_FUNCTIONS } from 'constants/roles';
import { SubNavigationSettings } from 'components/sub-navigation-settings';
import { Fact } from './fact';

interface IDispatch {
    toggleAirMenu: () => void;
}

interface ISettingsState {
    isAirMenu: boolean;
}

export class Settings extends React.Component<IDispatch, ISettingsState> {
    public state = {
        isAirMenu: false,
    };

    private subLinks: ILink[] = [
        {
            to: '/exception-management/settings/container-tracking',
            linkText: 'Container tracking'
        },
        {
            to: '/exception-management/settings/customs',
            linkText: 'Customs'
        },
        {
            to: '/exception-management/settings/delivery-planning',
            linkText: 'Delivery planning'
        },  
        {
            to: '/exception-management/settings/demurrage-and-detention',
            linkText: 'Demurrage & Detention'
        },
        {
            to: '/exception-management/settings/fact',
            linkText: 'FACT'
        }, 
    ];

    public render() {
        return (
            <Page>
                <PageHeader>
                    Settings
                </PageHeader>
                <SubNavigationSettings links={this.subLinks} />
                <Switch>
                    <RouteWithPermissions 
                        path="/exception-management/settings/delivery-planning" 
                        component={DeliveryPlanning} 
                        functions={[ BUSINNESS_FUNCTIONS.CONFIGURATION_DELIVERY_PLANNING ]} 
                    />
                    <RouteWithPermissions 
                        path="/exception-management/settings/customs" 
                        component={Customs} 
                        functions={[ BUSINNESS_FUNCTIONS.CONFIGURATION_CUSTOMS ]} 
                    />
                    <RouteWithPermissions 
                        path="/exception-management/settings/container-tracking" 
                        component={ContainerTracking} 
                        functions={[ BUSINNESS_FUNCTIONS.CONFIGURATION_CONTAINER_TRACKING ]} 
                    />
                    <RouteWithPermissions 
                        path="/exception-management/settings/demurrage-and-detention" 
                        component={DemurrageAndDetention} 
                        functions={[ BUSINNESS_FUNCTIONS.CONFIGURATION_DELIVERY_PLANNING ]} 
                    />
                    <RouteWithPermissions 
                        path="/exception-management/settings/fact" 
                        component={Fact} 
                        functions={[ BUSINNESS_FUNCTIONS.CONFIGURATION_DELIVERY_PLANNING ]} 
                    />
                    <Redirect to="/exception-management/settings/container-tracking" />
                </Switch>
            </Page>
        );
    }
}
