import React from 'react';
import { SendTransportOrderToHaulierModal } from 'modules/modals/components/SendTransportOrderToHaulierModal';
import { EditTransportationDetailsModal, IEditDeliveryDetailsForm } from 'views/planning/deliver-planning-container-details/components/EditTransportationDetailsModal';
import { EditDeliveryDetailsModal } from 'modules/modals/components/EditDevlieryDetailsModal';
import { formatDate, formatValueIfNullOrUndefined, getArrayWithMoreOptions } from 'utilities/util';
import { IEquipmentDetails } from 'reducers/planning/models';
import { Transport_Order_Type } from 'constants/transport';
import { ThunkDispatch } from 'redux-thunk';
import { Action } from 'redux';
import { getEquipmentDetails } from 'actions/planning';
import { connect } from 'react-redux';
import { BUSINNESS_FUNCTIONS } from 'constants/roles';
import { IStore } from 'reducers/index';
import { PropertiesRibbonWrapper } from 'components/properties-ribbon';
// tslint:disable-next-line: no-submodule-imports
import { withPermissions, IWithPermissions } from 'src/HOC/withPermissions';
import { mapStatusToStringForClearance1, mapStatusToStringForClearance2 } from 'constants/statuses';

interface IMapStateToProps {
    equipmentDetails: IEquipmentDetails;
}

interface IDispatch {
    getEquipmentDetails: (id: number) => Promise<void>;
}

interface ITopBarProps extends IWithPermissions, IMapStateToProps, IDispatch {
    equipment: IEquipmentDetails;
    id: number;
}

interface ITopBarState {
    isEditDeliveryDetailsModalOpened: boolean;
    isEditTransportationDetailsModalOpened: boolean;
    isSendTransportOrderToHaulierOpened: boolean;
    isEditMultipleContainersModalOpen: boolean;
    transportOrderType: Transport_Order_Type;
    transportOrderName: string;
}

class _TopBar extends React.Component<ITopBarProps, ITopBarState> {
    public state: ITopBarState = {
        isEditDeliveryDetailsModalOpened: false,
        isEditTransportationDetailsModalOpened: false,
        isSendTransportOrderToHaulierOpened: false,
        isEditMultipleContainersModalOpen: false,
        transportOrderType: Transport_Order_Type.Trucking,
        transportOrderName: ''
    };

    public render() {
        const actions = [
            {label: 'Update final delivery details', action: this.toggleEditDeliveryDetailsModal},
            {label: 'Update Transport details', action: this.toggleEditTransportationDetailsModal},
            {label: 'Send Trucking Transport Order', action: () => this.toggleSendTransportOrderToHaulierModal(Transport_Order_Type.Trucking)},
            {label: 'Send De-Rig Transport Order', action: () => this.toggleSendTransportOrderToHaulierModal(Transport_Order_Type.DeRig)},
            {label: 'Send Dark Storage Transport Order', action: () => this.toggleSendTransportOrderToHaulierModal(Transport_Order_Type.DarkStorage)},
            {label: 'Send Rail Transport Order', action: () => this.toggleSendTransportOrderToHaulierModal(Transport_Order_Type.Rail)},
            {label: 'Send Barge Transport Order', action: () => this.toggleSendTransportOrderToHaulierModal(Transport_Order_Type.Barge)},
        ];
        const propertiesRibbonData = {
            labels: [
                {label: 'Customs clearance status', value: formatValueIfNullOrUndefined(mapStatusToStringForClearance2(this.props.equipment.cL2_Status))},
                {label: 'Carrier release status', value: formatValueIfNullOrUndefined(mapStatusToStringForClearance1(this.props.equipment.cL1_Status))},
                {label: 'Equipment landed', value: formatDate(this.props.equipment.equipmentLandedDate)},
                {label: 'Demurrage expiry', value: formatDate(this.props.equipment.demmurageFreeTimeExpiry)},
                {label: 'Planned date / time', value: formatDate(this.props.equipment.deliveryPlannedDate)},
                {label: 'Delivery site', value: formatValueIfNullOrUndefined(this.props.equipment.deliveryLocation)},
                {label: 'Status', value: formatValueIfNullOrUndefined(this.props.equipment.deliveryStatus)},
                {label: 'GOH', value: formatValueIfNullOrUndefined(this.props.equipment.isGOH ? 'Y' : 'N')},
                {label: 'HotBox', value: formatValueIfNullOrUndefined(this.props.equipment.isHotBox ? 'Y' : 'N')}
            ],
            actions: getArrayWithMoreOptions(this.props.permissions, actions)
        };

        return (
            <React.Fragment>
                <PropertiesRibbonWrapper content={propertiesRibbonData} />
                {this.props.equipment.id && <EditDeliveryDetailsModal
                    visible={this.state.isEditDeliveryDetailsModalOpened}
                    closeModal={this.toggleEditDeliveryDetailsModal}
                    ids={[this.props.equipment.id]}
                    refetchFunction={() => this.props.getEquipmentDetails(this.props.equipment.id)}
                    isAir={false}
                    isFinal={true}
                />}
                {this.state.isEditTransportationDetailsModalOpened && <EditTransportationDetailsModal
                    visible={this.state.isEditTransportationDetailsModalOpened}
                    closeModal={this.toggleEditTransportationDetailsModal}
                    ids={[this.props.id]}
                    initialValues={this.getInitialValues()}
                    isDetails={true}
                />}
                {this.state.isSendTransportOrderToHaulierOpened && <SendTransportOrderToHaulierModal
                    visible={this.state.isSendTransportOrderToHaulierOpened}
                    closeModal={this.toggleSendTransportOrderToHaulierModal}
                    transportConfig={this.state.transportOrderType}
                    id={[this.props.equipment.id]}
                    isAir={false}
                    transportOrderName={this.state.transportOrderName}
                />}
            </React.Fragment>
        );
    }

    private getInitialValues = (): IEditDeliveryDetailsForm => ({
        documentsPARNRequired: this.props.equipmentDetails.documentsPARNRequired,
        documentsCRMRequired: this.props.equipmentDetails.documentsCMRRequired,
        isDangerousGoods: this.props.equipmentDetails.isDangerousGoods,
        dangerousGoodsUNNO: this.props.equipmentDetails.dangerousGoodsUNNO,
        dangerousGoodsClass: this.props.equipmentDetails.dangerousGoodsClass,
        dangerousGoodsDescription: this.props.equipmentDetails.dangerousGoodsDescription,
        isDangerousGoodsADR: this.props.equipmentDetails.dangerousGoodsADRRequired,
        comments: this.props.equipmentDetails.nonConformanceComment,
        hbLs: this.props.equipmentDetails.hbLs,
    });

    private toggleEditDeliveryDetailsModal = () => this.setState((state) => this.setState({
        isEditDeliveryDetailsModalOpened: !state.isEditDeliveryDetailsModalOpened,
    }));

    private toggleEditTransportationDetailsModal = () => this.setState((state) => this.setState({
        isEditTransportationDetailsModalOpened: !state.isEditTransportationDetailsModalOpened,
    }));

    private toggleSendTransportOrderToHaulierModal = (transportOrderType: Transport_Order_Type = Transport_Order_Type.Trucking) => {
        const transportName = () => {
            if(Object.entries(Transport_Order_Type)[transportOrderType]) {
                return Object.entries(Transport_Order_Type)[transportOrderType][1];
            }
            return '';
        };

        this.setState((prevState) => ({
            isSendTransportOrderToHaulierOpened: !prevState.isSendTransportOrderToHaulierOpened,
            transportOrderType,
            transportOrderName: transportName() as string
        }));
    }
}
const mapStateToProps = (state: IStore): IMapStateToProps => ({
    equipmentDetails: state.planning.equipmentDetails,
});

const mapDispatchToProps = (dispatch: ThunkDispatch<any, any, Action>): IDispatch => ({
    getEquipmentDetails: (id: number) => dispatch(getEquipmentDetails(id)),
});

const ROLES: BUSINNESS_FUNCTIONS[] = [
    BUSINNESS_FUNCTIONS.OCEAN_PLANNING_EDIT_CONTAINER_DETAILS_AND_DELIVERY_LEG,
    BUSINNESS_FUNCTIONS.OCEAN_PLANNING_SEND_TRANSPORT_ORDER,
    BUSINNESS_FUNCTIONS.OCEAN_PLANNING_EDIT_DELIVERY_DETAILS
];

const __TopBar = withPermissions<ITopBarProps>(_TopBar, ROLES);

export const TopBar = connect(mapStateToProps, mapDispatchToProps)(__TopBar) as any;
