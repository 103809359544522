import React from 'react';
import { DoubleGrid } from 'components/grid/double-grid';
import { FormInput } from 'components/input/FormInput';
import { ModalScreen } from 'components/modal-screen';
import { ModalType } from 'components/modal-screen/ModalType';
import { IOfficePrefix } from 'reducers/fact/models';
import { InjectedFormProps, reduxForm, reset } from 'redux-form';
import { required } from 'utilities/validate';
import { ErrorDialog } from 'components/error-dialog';
import API from 'constants/api';
import { Dispatch } from 'redux';

interface IPrefixModalOwnProps {
    title: string;
    closeModal: () => void;
    saveFunction: (prefix: IOfficePrefix) => void;
}

interface IPrefixModal extends IPrefixModalOwnProps, InjectedFormProps<{}, IPrefixModalOwnProps> {}

const FORM_NAME = 'EDIT_OFFICE_PREFIX_MODAL';

const _PrefixModal = (props: IPrefixModal) => {
    return (
        <form onSubmit={props.handleSubmit(props.saveFunction)}>
            <ModalScreen
                title={props.title}
                visible={true}
                modalType={ModalType.s()}
                closeModal={props.closeModal}
                primaryButtonTitle="Save"
                primaryButtonFunc={() => null}
                primaryButtonType="submit"
                secondaryButtonTitle="Cancel"
                secondaryButtonFunc={props.closeModal}
            >
                <ErrorDialog endpoint={API.Settings.UpdateFactOfficePrefixes} />
                <DoubleGrid>
                    <FormInput label="Prefix" name="prefix" validate={[required]} />
                    <FormInput label="Description" name="description" validate={[required]} />
                </DoubleGrid>
                <DoubleGrid>
                    <FormInput label="Business entity code" name="businessEntityCode" validate={[required]} />
                    <FormInput label="Function code" name="functionCode" validate={[required]} />
                </DoubleGrid>
                <DoubleGrid>
                    <FormInput
                        label="Country code of document issuer"
                        name="countryCodeOfDocumentIssuer"
                        validate={[required]}
                    />
                    <FormInput label="Place of receipt site" name="placeOfReceiptSite" validate={[required]} />
                </DoubleGrid>
            </ModalScreen>
        </form>
    );
};

const afterSubmit = (_: any, dispatch: Dispatch) => dispatch(reset(FORM_NAME));

export const PrefixModal = reduxForm<IOfficePrefix, IPrefixModalOwnProps>({
    form: FORM_NAME,
    onSubmitSuccess: afterSubmit,
    enableReinitialize: true,
})(_PrefixModal);
