import { createAsyncActionConstants } from 'utilities/util';

export const TYPES = {
    DELETE_CONTAINER_TRACKING_CARRIER_SETTINGS: createAsyncActionConstants('DELETE_CONTAINER_TRACKING_CARRIER_SETTINGS'),
    FETCH_CONTAINER_TRACKING_CARRIER_SETTINGS: createAsyncActionConstants('FETCH_CONTAINER_TRACKING_CARRIER_SETTINGS'),
    ADD_CONTAINER_TRACKING_CARRIER_SETTINGS: createAsyncActionConstants('ADD_CONTAINER_TRACKING_CARRIER_SETTINGS'),
    UPDATE_CONTAINER_TRACKING_CARRIER_SETTINGS: createAsyncActionConstants('UPDATE_CONTAINER_TRACKING_CARRIER_SETTINGS'),
    FETCH_CONTAINER_TRACKING_GLOBALS_SETTINGS: createAsyncActionConstants('FETCH_CONTAINER_TRACKING_GLOBALS_SETTINGS'),
    FETCH_DELIVERY_PLANNING_GLOBALS_SETTINGS: createAsyncActionConstants('FETCH_DELIVERY_PLANNING_GLOBALS_SETTINGS'),
    FETCH_CUSTOMS_GLOBALS_SETTINGS: createAsyncActionConstants('FETCH_CUSTOMS_GLOBALS_SETTINGS'),
    FETCH_DELIVERY_PLANNING_DERIG_SETTINGS: createAsyncActionConstants('FETCH_DELIVERY_PLANNING_DERIG_SETTINGS'),
    CREATE_DELIVERY_PLANNING_DERIG_SETTINGS: createAsyncActionConstants('CREATE_DELIVERY_PLANNING_DERIG_SETTINGS'),
    UPDATE_DELIVERY_PLANNING_DERIG_SETTINGS: createAsyncActionConstants('UPDATE_DELIVERY_PLANNING_DERIG_SETTINGS'),
    DELETE_DELIVERY_PLANNING_DERIG_SETTINGS: createAsyncActionConstants('DELETE_DELIVERY_PLANNING_DERIG_SETTINGS'),
    FETCH_DELIVERY_PLANNING_HAULIER_SETTINGS: createAsyncActionConstants('FETCH_DELIVERY_PLANNING_HAULIER_SETTINGS'),
    CREATE_DELIVERY_PLANNING_HAULIERS_SETTINGS: createAsyncActionConstants('CREATE_DELIVERY_PLANNING_HAULIERS_SETTINGS'),
    UPDATE_DELIVERY_PLANNING_HAULIER_SETTINGS: createAsyncActionConstants('UPDATE_DELIVERY_PLANNING_HAULIER_SETTINGS'),
    DELETE_DELIVERY_PLANNING_HAULIERS_SETTINGS: createAsyncActionConstants('DELETE_DELIVERY_PLANNING_HAULIERS_SETTINGS'),
    UPDATE_CUSTOMS_GLOBAL_SETTINGS: createAsyncActionConstants('UPDATE_CUSTOMS_GLOBAL_SETTINGS'),
    UPDATE_CONTAINER_TRACKING_GLOBAL_SETTINGS: createAsyncActionConstants('UPDATE_CONTAINER_TRACKING_GLOBAL_SETTINGS'),
    FETCH_DELIVERY_PLANNING_REASON_CODES: createAsyncActionConstants('FETCH_DELIVERY_PLANNING_REASON_CODES'),
    UPDATE_DELIVERY_PLANNING_REASON_CODES: createAsyncActionConstants('UPDATE_DELIVERY_PLANNING_REASON_CODES'),
    DELETE_DELIVERY_PLANNING_REASON_CODES: createAsyncActionConstants('DELETE_DELIVERY_PLANNING_REASON_CODES'),
    CREATE_DELIVERY_PLANNING_REASON_CODES: createAsyncActionConstants('CREATE_DELIVERY_PLANNING_REASON_CODES'),
    FETCH_CONTAINER_TRACKING_REASON_CODES: createAsyncActionConstants('FETCH_CONTAINER_TRACKING_REASON_CODES'),
    UPDATE_CONTAINER_TRACKING_REASON_CODES: createAsyncActionConstants('UPDATE_CONTAINER_TRACKING_REASON_CODES'),
    DELETE_CONTAINER_TRACKING_REASON_CODES: createAsyncActionConstants('DELETE_CONTAINER_TRACKING_REASON_CODES'),
    CREATE_CONTAINER_TRACKING_REASON_CODES: createAsyncActionConstants('CREATE_CONTAINER_TRACKING_REASON_CODES'),
    FETCH_CUSTOMS_REASON_CODES: createAsyncActionConstants('FETCH_CUSTOMS_REASON_CODES'),
    UPDATE_CUSTOMS_REASON_CODES: createAsyncActionConstants('UPDATE_CUSTOMS_REASON_CODES'),
    DELETE_CUSTOMS_REASON_CODES: createAsyncActionConstants('DELETE_CUSTOMS_REASON_CODES'),
    CREATE_CUSTOMS_REASON_CODES: createAsyncActionConstants('CREATE_CUSTOMS_REASON_CODES'),
    CREATE_DELIVERY_PLANNING_CUSTOMER: createAsyncActionConstants('CREATE_DELIVERY_PLANNING_CUSTOMER'),
    GET_DELIVERY_PLANNING_CUSTOMERS: createAsyncActionConstants('GET_DELIVERY_PLANNING_CUSTOMERS'),
    EDIT_DELIVERY_PLANNING_CUSTOMER: createAsyncActionConstants('EDIT_DELIVERY_PLANNING_CUSTOMER'),
    DELETE_DELIVERY_PLANNING_CUSTOMER: createAsyncActionConstants('DELETE_DELIVERY_PLANNING_CUSTOMER'),
    FETCH_CUSTOMS_BROKER_SETTINGS: createAsyncActionConstants('FETCH_CUSTOMS_BROKER_SETTINGS'),
    CREATE_CUSTOMS_BROKERS_SETTINGS: createAsyncActionConstants('CREATE_CUSTOMS_BROKERS_SETTINGS'),
    UPDATE_CUSTOMS_BROKER_SETTINGS: createAsyncActionConstants('UPDATE_CUSTOMS_BROKER_SETTINGS'),
    DELETE_CUSTOMS_BROKERS_SETTINGS: createAsyncActionConstants('DELETE_CUSTOMS_BROKERS_SETTINGS'),
    FETCH_FACT_OFFICE_PREFIXES: 'FETCH_FACT_OFFICE_PREFIXES',
    FETCH_DEMURRAGE_AND_DETENTION_REASON_CODES: createAsyncActionConstants('FETCH_DEMURRAGE_AND_DETENTION_REASON_CODES'),
    UPDATE_DEMURRAGE_AND_DETENTION_REASON_CODES: createAsyncActionConstants('UPDATE_DEMURRAGE_AND_DETENTION_REASON_CODES'),
    DELETE_DEMURRAGE_AND_DETENTION_REASON_CODES: createAsyncActionConstants('DELETE_DEMURRAGE_AND_DETENTION_REASON_CODES'),
    CREATE_DEMURRAGE_AND_DETENTION_REASON_CODES: createAsyncActionConstants('CREATE_DEMURRAGE_AND_DETENTION_REASON_CODES'),
    FETCH_GOH_TRANSLATIONS: createAsyncActionConstants('FETCH_GOH_TRANSLATIONS'),
    ADD_GOH_TRANSLATION: createAsyncActionConstants('ADD_GOH_TRANSLATION'),
    UPDATE_GOH_TRANSLATIONS: createAsyncActionConstants('UPDATE_GOH_TRANSLATION'),
    DELETE_GOH_TRANSLATION: createAsyncActionConstants('DELETE_GOH_TRANSLATION'),
};
