import React from 'react';
import { Select, IOption } from 'components/select/Select';

interface IFiltersBooleanSelect {
    label: string;
    value?: any; // injected
    name: string;
    type?: string;
    options: IOption[];
    onChange?(event: any): void; // injected
}

export const FiltersSelect = (props: IFiltersBooleanSelect) => {
    const options = [{ optionText: 'Select value...', value: '' }].concat(props.options);
    return(
        <div className="col-25">
            <Select
                label={props.label}
                value={props.value}
                onChange={props.onChange}
                options={options}
            />
        </div>
    )
};
