import React, { Component } from 'react';
import { ModalScreen } from 'components/modal-screen';
import { ModalType } from 'components/modal-screen/ModalType';
import { DoubleGrid } from 'components/grid/double-grid';
import { FormInput } from 'components/input/FormInput';
import { FormDateInput } from 'components/date-input/FormDateInput';
import { InjectedFormProps, reduxForm } from 'redux-form';
import { compose, Action } from 'redux';
import { connect } from 'react-redux';
import { SingleGrid } from 'components/grid/single-grid';
import { FormTextArea } from 'components/textarea/FormTextArea';
import { ThunkDispatch } from 'redux-thunk';
import { updateReleaseDetails, IUpdateReleaseDetailsData, resetUpdateReleaseDetailsError, fetchCblDetails } from 'actions/bill-release';
import { IStore } from 'reducers/index';
import { Dialog } from 'components/dialog';
import { DataTable } from 'components/data-table/DataTable';
import { ColumnFactory } from 'components/data-table/ColumnFactory';
import { withChildrenCheckboxes, IWithChildrenCheckboxesProps } from '../../../../HOC/withChildrenCheckboxes';
import { LoadingAnimation } from 'components/loading';
import { Button, ButtonType } from 'components/button';
import { CellInfo } from 'react-table';
import { formatValueIfNullOrUndefined, formatDate } from 'utilities/util';

interface IMapDispatchToProps {
    updateReleaseDetails: (data: IUpdateReleaseDetailsData) => void;
    resetUpdateReleaseDetailsError: () => void;
    fetchDetails: (id: number) => Promise<void>;
}

interface IMapStateToProps {
    user: string;
    updateReleaseDetailsError: string;
    isLoading: boolean;
}

interface IEditReleaseDetailsPopup {
    visible: boolean;
    data: any[];
    closeModal: () => void;
    editingItemsCount: number;
    isDetails?: boolean;
    id?: number
    cblId: number;
}

const cellIfCarrierNotReleased = (cell: CellInfo) => {
    if (cell.original.isCarrierReleased) {
        return 'N/A';
    }

    return formatValueIfNullOrUndefined(cell.value);
};

const dateCellIfCarrierNotReleased = (cell: CellInfo) => {
    if (cell.original.isCarrierReleased) {
        return 'N/A';
    }

    return formatDate(cell.value);
};

class _EditReleaseDetailsPopup extends Component<IEditReleaseDetailsPopup & IWithChildrenCheckboxesProps & IMapStateToProps & IMapDispatchToProps & InjectedFormProps & IWithChildrenCheckboxesProps> {
    public componentDidUpdate(prevProps: _EditReleaseDetailsPopup['props']) {
        if (this.props.visible && !prevProps.visible) {
            this.props.resetUpdateReleaseDetailsError();
            this.props.reset();
            this.props.clearChildrenCheckboxes();
        }

        if (this.props.checkedChildren !== prevProps.checkedChildren) {
            if (this.props.checkedChildren.length) {
                const selectedItem = this.props.checkedChildren[0];

                this.props.change('PIN', selectedItem.pin || '');
                this.props.change('ExpiryDate', selectedItem.pinExpiryDate || '');
                this.props.change('Pickup', selectedItem.pickupLocationCode || '');
                this.props.change('EmptyReturn', selectedItem.emptyLocationCode || '');
                this.props.change('UnloadDate', selectedItem.unloadDate || '');
            } else {
                this.props.reset();
            }
        }
    }

    public componentDidMount() {
        this.props.resetUpdateReleaseDetailsError();
    }

    public render() {
        return (
            <form onSubmit={this.props.handleSubmit(this.submitHandler)}>
                <ModalScreen
                    title={this.getTitle()}
                    modalType={ModalType.s()}
                    primaryButtonTitle="Save"
                    primaryButtonType="submit"
                    primaryButtonIsDisabled={!this.props.checkedChildren.length}
                    secondaryButtonTitle="Cancel"
                    secondaryButtonFunc={this.props.closeModal}
                    buttons={(
                        <Button
                            className="button-large"
                            buttonType={ButtonType.Transparent}
                            onClick={this.skipRelease}
                            isDisabled={!this.props.checkedChildren.length}
                        >
                            Skip release
                        </Button>
                    )}
                    visible={this.props.visible}
                    closeModal={this.props.closeModal}
                >
                    {this.props.isLoading && <LoadingAnimation />}
                    <div className="page-container">
                        <Dialog
                            message={this.props.updateReleaseDetailsError}
                            dialogType={500}
                            isVisible={!!this.props.updateReleaseDetailsError}
                            closeDialog={this.props.resetUpdateReleaseDetailsError}
                        />
                        <DataTable
                            data={this.props.data}
                            columns={[
                                ColumnFactory.checkBoxColumn(this.props.onToggleChildrenCheckbox, () => null, false),
                                ColumnFactory.standardColumnOld('equipmentNumber', 'Equipment number'),
                                ColumnFactory.booleanColumn('originalBLSurrendered', 'Original HBL surrendered', 'Y', 'N'),
                                ColumnFactory.booleanColumn('dangerousGoods', 'Dangerous goods', 'Y', 'N'),
                                ColumnFactory.booleanColumn('hotbox', 'Hotbox', 'Y', 'N'),
                                ColumnFactory.columnWithCustomCell('pin', 'PIN', cellIfCarrierNotReleased),
                                ColumnFactory.columnWithCustomCell('pinExpiryDate', 'Expiry date', dateCellIfCarrierNotReleased),
                                ColumnFactory.columnWithCustomCell('expiresIn', 'Expires in', cellIfCarrierNotReleased),
                                ColumnFactory.columnWithCustomCell('pickupLocation', 'Pickup location', cellIfCarrierNotReleased),
                                ColumnFactory.columnWithCustomCell('emptyLocation', 'Empty return', cellIfCarrierNotReleased),
                                ColumnFactory.columnWithCustomCell('unloadDate', 'Landed date', dateCellIfCarrierNotReleased),
                            ]}
                        />

                        <DoubleGrid>
                            <FormInput
                                label="PIN"
                                name="PIN"
                            />
                            <FormDateInput
                                label="Expiry date"
                                name="ExpiryDate"
                            />
                        </DoubleGrid>
                        <DoubleGrid>
                            <FormInput
                                label="Pickup / terminal location"
                                name="Pickup"
                            />
                            <FormInput
                                label="Empty return"
                                name="EmptyReturn"
                            />
                        </DoubleGrid>
                        <DoubleGrid>
                            <FormDateInput
                                label="Landed Date"
                                name="UnloadDate"
                            />
                        </DoubleGrid>
                        <SingleGrid>
                            <FormTextArea
                                label="Comment"
                                name="Comment"
                            />
                        </SingleGrid>
                    </div>
                </ModalScreen>
            </form>
        );
    }

    private submitHandler = async (fields: any) => {
        try {
            await this.props.updateReleaseDetails({
                CarrierReleases: [{
                    EquipmentId: this.props.checkedChildren[0].equipmentId,
                    PinExpiryDate: fields.ExpiryDate,
                    EmptyLocationCode: fields.EmptyReturn,
                    PickupLocationCode: fields.Pickup,
                    Pin: fields.PIN,
                    UnloadDate: fields.UnloadDate,
                    blId: this.props.cblId,
                }],
                UpdatedBy: this.props.user
            });
            this.props.closeModal();

            if(this.props.isDetails && this.props.id) {
                this.props.fetchDetails(this.props.id);
            }
        } catch (error) {
            console.error(error);
        }
        return false;
    };

    private skipRelease = () => {
        this.props.handleSubmit(this.submitSkipRelease)();
    }

    private submitSkipRelease = async (fields: any) => {
        try {
            const carrierReleases = this.props.data.map(item => ({
                EquipmentId: item.equipmentId,
                PinExpiryDate: item.pinExpiryDate,
                EmptyLocationCode: item.emptyLocationCode,
                PickupLocationCode: item.pickupLocationCode,
                Pin: item.pin,
                UnloadDate: item.unloadDate,
                IsCarrierReleased: true,
                blId: this.props.cblId,
            }));

            if (this.props.checkedChildren.length > 0) {
                const checked = carrierReleases.find(item => item.EquipmentId === this.props.checkedChildren[0].equipmentId);
                if (checked) {
                    checked.PinExpiryDate = fields.ExpiryDate;
                    checked.EmptyLocationCode = fields.EmptyReturn;
                    checked.PickupLocationCode = fields.Pickup;
                    checked.Pin = fields.PIN;
                    checked.UnloadDate = fields.UnloadDate;
                }
            }

            await this.props.updateReleaseDetails({
                CarrierReleases: carrierReleases,
                UpdatedBy: this.props.user
            });

            this.props.closeModal();

            if(this.props.isDetails && this.props.id) {
                this.props.fetchDetails(this.props.id);
            }
        } catch (error) {
            console.error(error);
        }
        return false;
    }

    private getTitle() {
        const { editingItemsCount: count } = this.props;
        const base = 'Update release details';

        if (count < 1) {
            return base;
        }

        return `${base} - updating for ${count} CB/L${count > 1 ? 's' : ''}`;
    }
};

const mapDispatchToProps = (dispatch: ThunkDispatch<any, any, Action>): IMapDispatchToProps => ({
    updateReleaseDetails: (data: IUpdateReleaseDetailsData) => dispatch(updateReleaseDetails(data)),
    resetUpdateReleaseDetailsError: () => dispatch(resetUpdateReleaseDetailsError()),
    fetchDetails: (id: number) => dispatch(fetchCblDetails(id)),
});

const mapStateToProps = (state: IStore): IMapStateToProps => ({
    user: state.user.user.name,
    updateReleaseDetailsError: state.bill_release.updateReleaseDetailsError,
    isLoading: state.bill_release.isUpdateReleaseDetailsLoading,
});

const FORM_NAME = 'EDIT_RELEASE_DETAILS';

export const EditReleaseDetailsPopup = compose(
    reduxForm<{}, IEditReleaseDetailsPopup>({
        form: FORM_NAME,
        enableReinitialize: true,
        keepDirtyOnReinitialize: false,
    }),
    connect<IMapStateToProps, IMapDispatchToProps, IEditReleaseDetailsPopup>(mapStateToProps, mapDispatchToProps),
)(withChildrenCheckboxes(_EditReleaseDetailsPopup, 'equipmentNumber', true));
