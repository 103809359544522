import React from 'react';
import { Accordion } from 'components/accordion';
import { QuarterGrid } from 'components/grid/quarter-grid';
import { OneStatus } from 'components/status-row/OneStatus';

interface IFlightTableProps {
    flight: string;
    pol: string;
    pod: string;
}

export const FlightTable: React.StatelessComponent<IFlightTableProps> = (props: IFlightTableProps) => {
    return(
        <Accordion text="Flight">
            <QuarterGrid>
                <OneStatus 
                    label="Flight number"
                    children={props.flight}
                />
                <OneStatus 
                    label="POD"
                    children={props.pod}
                />
                <OneStatus 
                    label="POL"
                    children={props.pol}
                />
            </QuarterGrid>
        </Accordion>
    );
}