import React, { useState } from 'react';
import { formatDate, formatValueIfNullOrUndefined } from 'utilities/util';
import { PropertiesRibbonWrapper } from 'components/properties-ribbon';
import { connect } from 'react-redux';
import { ThunkDispatch } from 'redux-thunk';
import { Action } from 'redux';
import { exportFactFile, createEsso } from 'actions/fact';
import { IFactAsns } from 'reducers/fact/models';
import { ASNMessagesModal } from 'modules/fact/components/ASNMessagesModal';

interface IFactDetailsTopBarOwnProps {
    documentId: string | number;
    documentNumber: string;
    type: string;
    isCancelled: boolean;
    createDate: string;
    lastUpdateDate: string;
    approverUserCompanyWideCode: string;
    changeUserCompanyWideCode: string;
    prepaidCollectIndicator: string;
    goodsReceiptDate: string;
    estimatedDeliveryTime: string;
    id: number;
    asns: IFactAsns[];
}

interface IMapDispatchToProps {
    exportFactFile: (body: any) => Promise<void>;
    createEsso: (documentId: number) => Promise<void>;
}

interface IFactDetailsTopBarProps extends IFactDetailsTopBarOwnProps, IMapDispatchToProps {}

const _FactDetailsTopBar: React.FunctionComponent<IFactDetailsTopBarProps> = (props) => {
    const [isModalOpened, toggleModal] = useState<boolean>(false);

    const isCanceledAsString = props.isCancelled ? 'Y' : 'N';
    const toggleASNModal = () => toggleModal(!isModalOpened);
    const createAction = () => props.createEsso(props.id);

    const propertiesRibbonData = {
        labels: [
            {label: 'Document number', value: formatValueIfNullOrUndefined(props.documentNumber)},
            {label: 'Type', value: formatValueIfNullOrUndefined(props.type)},
            {label: 'Is cancelled', value: isCanceledAsString},
            {label: 'Create date', value: formatDate(props.createDate, 'DD-MM-YYYY, h:mm')},
            {label: 'Last update date', value: formatDate(props.lastUpdateDate, 'DD-MM-YYYY, h:mm')},
            {label: 'Approver user company wide code', value: formatValueIfNullOrUndefined(props.approverUserCompanyWideCode)},
            {label: 'Change user company wide code', value: formatValueIfNullOrUndefined(props.changeUserCompanyWideCode)},
            {label: 'Prepaid collect indicator', value: formatValueIfNullOrUndefined(props.prepaidCollectIndicator)},
            {label: 'Goods receipt date', value: formatDate(props.goodsReceiptDate, 'DD-MM-YYYY, h:mm')},
            {label: 'Estimated delivery time', value: formatDate(props.estimatedDeliveryTime, 'DD-MM-YYYY, h:mm')}
        ],
        actions: [
            {label: 'Get', action: () => props.exportFactFile({id: props.id, documentType: 'ASN'})},
            {label: 'ASN messages', action: toggleASNModal },
            {label: 'Create', action: createAction }
        ]
    }

    return(
        <React.Fragment>
            <PropertiesRibbonWrapper content={propertiesRibbonData} />
            <ASNMessagesModal 
                data={props.asns}
                visible={isModalOpened}
                closeModal={toggleASNModal}
            />
        </React.Fragment>
    )
};

const mapDispatchToProps = (dispatch: ThunkDispatch<any, any, Action>): IMapDispatchToProps => ({
    exportFactFile: (body: any) => dispatch(exportFactFile(body)),
    createEsso: (documentId: number) => dispatch(createEsso(documentId))
});

export const FactDetailsTopBar = connect<undefined, IMapDispatchToProps, IFactDetailsTopBarOwnProps>(undefined, mapDispatchToProps)(_FactDetailsTopBar);
