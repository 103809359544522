import React, { Component } from 'react';
import { connect } from 'react-redux';
import { FiltersInput } from 'modules/filters/components/FiltersInput';
import { Page } from 'components/page-container';
import { Filters } from 'modules/filters/containers/Filters';
import { PageHeader } from 'components/page-header';
import { ThunkDispatch } from 'redux-thunk';
import { Action } from 'redux';
import { fetchFactData } from 'actions/fact';
import { IStore } from 'reducers/index';
import { IDocuments } from 'reducers/fact/models';
import { FiltersSelect } from 'modules/filters/components/FiltersSelect';
import { types } from './fact';
import { FactTable } from 'modules/fact/components/FactTable';
import { ILink } from 'components/sub-navigation/models/Link';
import { SubNavigation } from 'components/sub-navigation';
import { ISortModel } from 'reducers/filters/models';
import { setSortForTable } from 'utilities/tables';
import { setSort, resetSort } from 'actions/filters';

const links: ILink[] = [
    {
        to: '/exception-management/fact/documents',
        linkText: 'Documents',
    },
    {
        to: '/exception-management/fact/equipments',
        linkText: 'Equipments',
    }, 
    {
        to: '/exception-management/fact/cbls',
        linkText: 'Cbls',
    }, 
    {
        to: '/exception-management/fact/isso',
        linkText: 'ISSO',
    }, 
    {
        to: '/exception-management/fact/esso',
        linkText: 'ESSO',
    }
];

interface IMapDispatchToProps {
    fetchFactData: (pageNumber: number, pageSize: number) => Promise<void>;
    setSort: (sort: ISortModel) => void;
    resetSort: () => void;
}

interface IMapStateToProps {
    isFetchFactDataLoading: boolean;
    documents: IDocuments;
}

interface IFactProps extends IMapStateToProps, IMapDispatchToProps {}

interface IFactState {
    page: number;
    pageSize: number;
}

class _Fact extends Component<IFactProps, IFactState>{
    public state = {
        page: 1,
        pageSize: 10
    };

    public render() {
        const filters = [
            <FiltersInput
                key="originalDocumentNumber"
                name="originalDocumentNumber"
                label="Original document number"
            />,
            <FiltersInput
                key="documentNumber"
                name="documentNumber"
                label="Document number"
            />,
            <FiltersSelect
                key="type"
                name="type"
                label="Type"
                options={types}
            />
        ];
    
        return(
            <React.Fragment>
                <PageHeader>FACT</PageHeader>
                <Page>
                    <Filters
                        tableNameKey="fact"
                        fetchFunction={() => this.props.fetchFactData(this.state.page, this.state.pageSize)}
                        filters={filters}
                        withoutPagination={true}
                    />
                    <SubNavigation links={links} />
                    <FactTable
                        data={this.props.documents.result}
                        isLoading={this.props.isFetchFactDataLoading}
                        fetchDocuments={this.fetchDocuments}
                        pageCount={Math.ceil(this.props.documents.totalCount / this.props.documents.pageSize)}
                    /> 
                </Page>
            </React.Fragment>
        );
    }

    private fetchDocuments = (tableData: any) => {
        this.setState({
            page: tableData.page + 1,
            pageSize: tableData.pageSize
        }, () => {
            setSortForTable(tableData.sorted, this.props.setSort);
            this.props.fetchFactData(this.state.page, this.state.pageSize);
        });
    };
}

const mapStateToProps = (state: IStore): IMapStateToProps => ({
    isFetchFactDataLoading: state.fact.isFetchFactDataLoading,
    documents: state.fact.documents
});

const mapDispatchToProps = (dispatch: ThunkDispatch<any, any, Action>): IMapDispatchToProps => ({
    fetchFactData: (pageNumber: number, pageSize: number) => dispatch(fetchFactData(pageNumber, pageSize)),
    setSort: (sort: ISortModel) => dispatch(setSort(sort)),
    resetSort: () => dispatch(resetSort())
});

export const Fact = connect<IMapStateToProps, IMapDispatchToProps, {}, IStore>(mapStateToProps, mapDispatchToProps)(_Fact);
