import React from 'react';
import { MobileNavigationLink } from '../components/Mobile/MobileNavigationLink';
import { MobileNavigationDropDownItem } from '../components/Mobile/MobileNavigationDropDownItem';
import { IRoute } from '../routes';

interface IMobileNavigationState {
    showNav: boolean;
    expandedRoutes: object;
}

interface IMobileNavigationOwnProps {
    routes: IRoute[];
}

export class MobileNavigation extends React.Component<IMobileNavigationOwnProps, IMobileNavigationState> {
    public state = {
        showNav: false,
        expandedRoutes: this.props.routes.reduce((prev: object, _: any, index: number) => ({
            ...prev,
            [index]: true,
        }), {}),
    };

    public render() {
        const routes = this.props.routes.map((oneRoute: IRoute, index: number) => {
            if (!oneRoute.links) {
                return (
                    <MobileNavigationLink
                        {...oneRoute}
                        key={index}
                        index={index}
                    />
                );
            }
            return (
                <MobileNavigationDropDownItem
                    {...oneRoute}
                    isExpanded={this.state.expandedRoutes[index]}
                    index={index}
                    key={index}
                    toggleSubNavigation={this.toggleSubNavigation}
                />
            )
        });
        return (
            <nav className="footer-nav mobile-display-gray">
                <ul>
                    <li
                        className={this.state.showNav ? 'has-sub sub-active close-nav-btn' : 'has-sub news'}
                        onClick={this.toggleMobileNav}
                    >
                        <ul className="submenu submenu-small">
                            <li className="nav-column-row">
                                {routes}
                            </li>
                        </ul>
                    </li>
                </ul>
            </nav>
        );
    }

    private toggleMobileNav = () => this.setState((previousState) => ({
        showNav: !previousState.showNav
    }));

    private toggleSubNavigation = (index: number) => {
        this.setState((previousState: any) => ({
            expandedRoutes: {
                ...previousState.expandedRoutes,
                [index]: !previousState.expandedRoutes[index],
            }
        }))
    }

}
