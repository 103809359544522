import React, { Component } from 'react';
import { PageHeader } from 'components/page-header';
import { HBLTable } from 'modules/bill-release/hbl/components/HBLTable';
import { Page } from 'components/page-container';
import { Filters } from 'modules/filters/containers/Filters';
import { FiltersInput } from 'modules/filters/components/FiltersInput';
import { FiltersDateInput } from 'modules/filters/components/FiltersDateInput';
import { connect } from 'react-redux';
import { IStore } from 'reducers/index';
import { ThunkDispatch } from 'redux-thunk';
import { Action } from 'redux';
import { fetchHblList } from 'actions/bill-release';
import { LoadingAnimation } from 'components/loading';
import { HandleError } from 'components/handle-error';
import { ISortModel } from 'reducers/filters/models';
import { setSort, resetSort } from 'actions/filters';
import { setSortForTable } from 'utilities/tables';

interface IMapStateToProps {
    hblList: any;
    isLoading: boolean;
    isExportLoading: boolean;
    hblError: string;
    ataEmpty: boolean;
    newEtaEmpty: boolean;
}

interface IMapDispatchToProps {
    fetchHblList: (pageNumber: number, pageSize: number) => Promise<void>;
    setSort: (sort: ISortModel) => void;
    resetSort: () => void;
}

interface IHbl extends IMapStateToProps, IMapDispatchToProps {}

interface IHblState {
    page: number;
    pageSize: number;
}

class _Hbl extends Component<IHbl, IHblState> {
    public state = {
        page: 1,
        pageSize: 10
    };

    public render() {
        const filters = [
            <FiltersInput
                key="hblNumber"
                name="hblNumber"
                label="HBL number"
            />,
            <FiltersInput
                key="vesselName"
                name="vesselName"
                label="Vessel name"
            />,
            <FiltersInput
                key="voyageNumber"
                name="voyageNumber"
                label="Voyage Number"
            />,
            <FiltersInput
                key="pod"
                name="podName"
                label="POD"
            />,
            <FiltersInput
                type="number"
                key="daysTillETA_from"
                name="daysTillETA_from"
                label="Days till ETA (from)"
            />,
            <FiltersInput
                type="number"
                key="daysTillETA_to"
                name="daysTillETA_to"
                label="Days till ETA (to)"
            />,
            <FiltersDateInput
                key="newETA_from"
                name="newETA_from"
                label="New ETA (from)"
                type="date"
                relatedField="newEta"
                readOnly={this.props.newEtaEmpty}
            />,
            <FiltersDateInput
                key="newETA_to"
                name="newETA_to"
                label="New ETA (to)"
                type="date"
                isEndOfDay={true}
                relatedField="newEta"
                readOnly={this.props.newEtaEmpty}
            />,
            <FiltersDateInput
                key="ata_from"
                name="ata_from"
                label="ATA (from)"
                type="date"
                relatedField="ata"
                readOnly={this.props.ataEmpty}
            />,
            <FiltersDateInput
                key="ata_to"
                name="ata_to"
                label="ATA (to)"
                type="date"
                isEndOfDay={true}
                relatedField="ata"
                readOnly={this.props.ataEmpty}
            />,
            <FiltersInput
                key="PONumber"
                name="PONumber"
                label="PO Number"
            />,
            <FiltersInput
                key="lastUserUpdated"
                name="lastUserUpdated"
                label="Last updated by"
            />,
        ];

        return (
            <React.Fragment>
                <PageHeader>HBL release</PageHeader>
                <Page>
                    {this.props.isExportLoading && <LoadingAnimation />}
                    <HandleError
                        hblError={this.props.hblError}
                    />
                    <Filters
                        tableNameKey="ct-hbl"
                        fetchFunction={() => this.props.fetchHblList(this.state.page, this.state.pageSize)}
                        filters={filters}
                        withoutPagination={true}
                    />
                    <HBLTable
                        data={this.props.hblList.data}
                        isLoading={this.props.isLoading}
                        pageCount={Math.ceil(this.props.hblList.totalCount / this.props.hblList.pageSize)}
                        fetchHbls={this.fetchHbls}
                    />
                </Page>
            </React.Fragment>
        );
    };

    public componentWillUnmount() {
        this.props.resetSort();
    }

    private fetchHbls = (tableData: any) => {
        this.setState({
            page: tableData.page + 1,
            pageSize: tableData.pageSize
        }, () => {
            setSortForTable(tableData.sorted, this.props.setSort);
            this.props.fetchHblList(this.state.page, this.state.pageSize);
        });
        
    };
}

const mapStateToProps = (state: IStore): IMapStateToProps => ({
    hblList: state.bill_release.hbl,
    isLoading: state.bill_release.isFetchHblListLoading,
    isExportLoading: state.exportReducer.isExportHblReleaseLoading,
    hblError: state.exportReducer.exportHblError,
    ataEmpty: state.filters.filters.ata_empty,
    newEtaEmpty: state.filters.filters.neweta_empty
});

const mapDispatchToProps = (dispatch: ThunkDispatch<any, any, Action>): IMapDispatchToProps => ({
    fetchHblList: (pageNumber: number, pageSize: number) => dispatch(fetchHblList(pageNumber, pageSize)),
    setSort: (sort: ISortModel) => dispatch(setSort(sort)),
    resetSort: () => dispatch(resetSort()),
});

export const Hbl = connect(mapStateToProps, mapDispatchToProps)(_Hbl);
