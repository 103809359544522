import React from 'react';
import { connect } from 'react-redux';
import { ColumnFactory } from 'components/data-table/ColumnFactory';
import { StatusRow } from 'components/status-row';
import { ThunkDispatch } from 'redux-thunk';
import { Action } from 'redux';
import { getEquipmentDeliveries, getEquipmentDetails } from 'actions/planning';
import { IStore } from 'reducers/index';
import { IWithTableCheckboxesProps, withTableCheckboxes } from 'containers/data-table/withCheckboxes';
import { AddTransportationRowModal } from 'modules/modals/components/AddTransportationRowModal';
import { ISettingsModel } from 'reducers/settings/models';
import { fetchDeliveryPlanningHaulierSettings } from 'actions/settings';
import { DataTable } from 'components/data-table/DamcoTable';
import { cloneDataWithDisplayNames } from 'utilities/tables';
import { Transport_Mode, Transport_Type } from 'constants/transport';
import { hauliersToEnum } from 'utilities/util';

interface IDeliveryTableOwnProps {
    id: number;
    withEdit?: boolean;
    isEmptyReturn?: boolean;
}

interface IDeliveryTable extends IDeliveryTableOwnProps, IMapStateToProps, IDispatch, IWithTableCheckboxesProps {
}

interface IMapStateToProps {
    data: any[];
    deliveryPlanningHaulierSettings: ISettingsModel[];
}

interface IDispatch {
    getEquipmentDeliveries: (id: number) => Promise<void>;
    getEquipmentDetails: (id: number) => Promise<void>;
    fetchDeliveryPlanningHaulierSettings: () => void;
}

interface IDeliveryTableState {
    isEditActualsModalOpened: boolean;
}

export class _DeliveryTable extends React.Component<IDeliveryTable, IDeliveryTableState> {
    public dataTable = React.createRef<any>();

    public state = {
        isEditActualsModalOpened: false,
    };

    public componentDidMount() {
        this.props.getEquipmentDeliveries(this.props.id);
        this.props.fetchDeliveryPlanningHaulierSettings();
    }

    public render() {
        const { dropOffDateName, pickupDateName } = this.getNames();
        const refetchFunction = this.props.isEmptyReturn && this.refetchEmptyReturns;
        const hauliers = hauliersToEnum(this.props.deliveryPlanningHaulierSettings);
        const data = cloneDataWithDisplayNames(this.props.data).map((obj: any) => ({
            ...obj,
            transportModeDisplayName: Transport_Mode[obj.transportMode],
            transportTypeDisplayName: Transport_Type[obj.transportType],
            haulierDisplayName: hauliers[obj.haulier]
        }));

        return (
            <React.Fragment>
                <StatusRow title="Equipment delivery">
                    <DataTable
                        withCheckboxes={true}
                        selectVariant="single"
                        expandable={false}
                        data={data}
                        columns={[
                            ColumnFactory.standardColumn('transportModeDisplayName', 'Transport mode'),
                            ColumnFactory.standardColumn('transportTypeDisplayName', 'Transport type'),
                            ColumnFactory.standardColumn('haulierDisplayName', 'Haulier'),
                            ColumnFactory.standardColumn('isTransportOrderSentDisplayName', 'Sent'),
                            ColumnFactory.standardColumn('pickupLocationDisplayName', 'Pickup'),
                            ColumnFactory.standardColumn(pickupDateName, 'Date/Time'),
                            ColumnFactory.standardColumn('dropOffLocationDisplayName', 'Drop-off'),
                            ColumnFactory.standardColumn(dropOffDateName, 'Date/Time'),
                            ColumnFactory.standardColumn('deliveryReferenceDisplayName', 'Reference'),
                        ]}
                        actions={{
                            parentContextualActions: [
                                {isMoreBtn: false, label: 'Update actuals', action: this.toggleEditActualsModal}
                            ]
                        }}
                        reference={this.dataTable}
                        tableName="DeliveryDetailsEquipmentTable"
                        showPagination={true}
                    />
                </StatusRow>
                {this.state.isEditActualsModalOpened && <AddTransportationRowModal
                    visible={this.state.isEditActualsModalOpened}
                    closeModal={this.toggleEditActualsModal}
                    id={this.props.id}
                    initialValues={this.getInitialValues()}
                    isEmptyReturn={this.props.isEmptyReturn}
                    refetchFunction={refetchFunction}
                    fromPlanning={true}
                />}
            </React.Fragment>
        );
    }

    private toggleEditActualsModal = () => this.setState(state => ({
        isEditActualsModalOpened: !state.isEditActualsModalOpened,
    }));

    private getNames = () => {
        return this.props.isEmptyReturn ?  {
            dropOffDateName: 'dropOffDateDisplayName',
            pickupDateName: 'pickupDateDisplayName',
        } : {
            dropOffDateName: 'estimatedDropOffDateDisplayName',
            pickupDateName: 'estimatedPickupDateDisplayName',
        };
    };

    private refetchEmptyReturns = () => {
        this.props.getEquipmentDeliveries(this.props.id);
        this.props.getEquipmentDetails(this.props.id);
    }

    private getInitialValues = () => {
        const selectedItem = this.dataTable.current ? this.dataTable.current.getSelectedItems() : [];

        if(selectedItem[0]) {
            return selectedItem[0];
        }
    };
}

const mapStateToProps = (state: IStore): IMapStateToProps => ({
    data: state.planning.equipmentDeliveries,
    deliveryPlanningHaulierSettings: state.settings.deliveryPlanningHaulierSettings,
});

const mapDispatchToProps = (dispatch: ThunkDispatch<any, any, Action>): IDispatch => ({
    getEquipmentDeliveries: (id: number) => dispatch(getEquipmentDeliveries(id, false)),
    getEquipmentDetails: (id: number) => dispatch(getEquipmentDetails(id)),
    fetchDeliveryPlanningHaulierSettings: () => dispatch(fetchDeliveryPlanningHaulierSettings(true)),
});

export const DeliveryTable = connect<IMapStateToProps, IDispatch, IDeliveryTableOwnProps, IStore>(mapStateToProps, mapDispatchToProps)(withTableCheckboxes<IDeliveryTableOwnProps>(_DeliveryTable, 'id'));
