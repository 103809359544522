export const COLORS = {
    LEAF_GREEN_100: '#97C550',
    LEAF_GREEN_80: '#acd173',
    LEAF_GREEN_60: '#c1dc96',
    LEAF_GREEN_40: '#d5e8b9',
    LEAF_GREEN_20: '#eaf3dc',
    LEAF_GREEN_10: '#f5f9ee',
    GRASS_GREEN_100: '#54AB42',
    GRASS_GREEN_80: '#76bc68',
    GRASS_GREEN_60: '#98cd8e',
    GRASS_GREEN_40: '#bbddb3',
    GRASS_GREEN_20: '#ddeed9',
    GRASS_GREEN_10: '#eef7ec',
    LIGHT_GREY_100: '#868F98',
    LIGHT_GREY_80: '#9ea5ad',
    LIGHT_GREY_60: '#b6bcc1',
    LIGHT_GREY_40: '#cfd2d6',
    LIGHT_GREY_20: '#e7e9ea',
    LIGHT_GREY_10: '#f3f4f5',
    SKY_BLUE_100: '#398FD1',
    WHITE: '#FFF',
    RED: '#D52B1E'
}