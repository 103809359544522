import { createAsyncActionConstants } from 'utilities/util';

export const TYPES = {
    GET_EQUIPMENTS_LIST: createAsyncActionConstants('GET_EQUIPMENTS_LIST'),
    GET_EQUIPMENT_DELIVERIES: createAsyncActionConstants('GET_EQUIPMENT_DELIVERIES'),
    ADD_EQUIPMENT_DELIVERIES: createAsyncActionConstants('ADD_EQUIPMENT_DELIVERIES'),
    EDIT_EQUIPMENT_DELIVERIES: createAsyncActionConstants('EDIT_EQUIPMENT_DELIVERIES'),
    DELETE_EQUIPMENT_DELIVERIES: createAsyncActionConstants('DELETE_EQUIPMENT_DELIVERIES'),
    GET_EQUIPMENT_DETAILS: createAsyncActionConstants('GET_EQUIPMENT_DETAILS'),
    EDIT_DELIVERY_DETAILS: createAsyncActionConstants('EDIT_DELIVERY_DETAILS'),
    SEND_TRANSPORT_ORDER_TO_HAULIER: createAsyncActionConstants('SEND_TRANSPORT_ORDER_TO_HAULIER'),
    UPDATE_EQUIPMENT_DETAILS: createAsyncActionConstants('UPDATE_EQUIPMENT_DETAILS'),
    GET_EMPTY_RETURN_EQUIPMENTS: createAsyncActionConstants('GET_EMPTY_RETURN_EQUIPMENTS'),
    GET_AIR_DELIVERY: createAsyncActionConstants('GET_AIR_DELIVERY'),
    UPDATE_EMPTY_RETURN_EQUIPMENT_DETAILS: createAsyncActionConstants('UPDATE_EMPTY_RETURN_EQUIPMENT_DETAILS'),
    UPDATE_EMPTY_RETURN_ACTUALS: createAsyncActionConstants('UPDATE_EMPTY_RETURN_ACTUALS'),
    RESET_SEND_TRANSPORT_ORDER_ERROR: 'RESET_SEND_TRANSPORT_ORDER_ERROR',
    GET_CONTAINERS_LOCATION: createAsyncActionConstants('GET_CONTAINERS_LOCATION'),
    GET_DP_REASON_CODES: createAsyncActionConstants('GET_DP_REASON_CODES'),
    GET_HAWB_LIST: createAsyncActionConstants('GET_HAWB_LIST'),
    GET_HAWB_DETAILS: createAsyncActionConstants('GET_HAWB_DETAILS'),
    SET_AVAILABILITY_FOR_BOOKING: createAsyncActionConstants('SET_AVAILABILITY_FOR_BOOKING'),
    GET_AIR_DELIVERY_DETAILS: createAsyncActionConstants('GET_AIR_DELIVERY_DETAILS'),
    FETCH_HBL_WITH_CUSTOMS: createAsyncActionConstants('FETCH_HBL_WITH_CUSTOMS'),
    GET_DELIVERY_DETAILS_FOR_EQUIPMENTS: createAsyncActionConstants('GET_DELIVERY_DETAILS_FOR_EQUIPMENTS'),
    GET_PO_PLANNING_LIST: createAsyncActionConstants('GET_PO_PLANNING_LIST'),
};
