import React from 'react';
import { OneStatus } from 'components/status-row/OneStatus';
import { StatusRow } from 'components/status-row';
import { QuarterGrid } from 'components/grid/quarter-grid';
import { formatDate } from 'utilities/util';
import { connect } from 'react-redux';
import { ThunkDispatch } from 'redux-thunk';
import { Action } from 'redux';
import { IStore } from 'reducers/index';
import { LoadingAnimation } from 'components/loading';
import { getDPReasonCodes } from 'actions/planning';

interface IDeliveryFailureRowProps {
    deliveryFailureDate?: string;
    deliveryFailureReasonCodeId?: string;
    deliveryFailureResponsible?: string;
    deliveryPlannedDate?: string;
}

interface IMapStateToProps {
    isLoading: boolean;
    reasonCodes: Array<{ id: number; reasonCode: string }>
}

interface IDispatch {
    getDPReasonCodes: () => Promise<void>;
}

type IDeliveryFailureRow = IDeliveryFailureRowProps & IDispatch & IMapStateToProps;

class _DeliveryFailureRow extends React.Component<IDeliveryFailureRow> {
    public componentDidMount() {
        this.props.getDPReasonCodes();
    }

    public render() {
        const reasonCode = this.props.reasonCodes.find((oneReasonCode) => String(oneReasonCode.id) === String(this.props.deliveryFailureReasonCodeId));
        return (
            <StatusRow title="Delivery failure">
                {this.props.isLoading && <LoadingAnimation />}
                <QuarterGrid>
                    <OneStatus label="Delivery planned">
                        {formatDate(this.props.deliveryPlannedDate)}
                    </OneStatus>
                    <OneStatus
                        label="Failure"
                        id="delivery-failure"
                    >
                        {formatDate(this.props.deliveryFailureDate)}
                    </OneStatus>
                    <OneStatus label="Reason code">
                        {reasonCode && reasonCode.reasonCode}
                    </OneStatus>
                    <OneStatus label="Responsible">
                        {this.props.deliveryFailureResponsible}
                    </OneStatus>
                </QuarterGrid>
            </StatusRow>
        );
    }
}

const mapStateToProps = (state: IStore): IMapStateToProps => ({
    isLoading: state.planning.isGetDPReasonCodesPending,
    reasonCodes: state.planning.reasonCodes,
});

const mapDispatchToProps = (dispatch: ThunkDispatch<any, any, Action>): IDispatch => ({
    getDPReasonCodes: () => dispatch(getDPReasonCodes()),
});

export const DeliveryFailureRow = connect(mapStateToProps, mapDispatchToProps)(_DeliveryFailureRow);
