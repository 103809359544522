import React from 'react';
import { ColumnFactory } from 'components/data-table/ColumnFactory';
import { Accordion } from 'components/accordion';
import {IHBLFCRDetails} from 'reducers/customs/models';
import { DataTable } from 'components/data-table/DamcoTable';
import { cloneDataWithDisplayNames } from 'utilities/tables';

export const EquipmentTable: React.StatelessComponent<IHBLFCRDetails> = (props: IHBLFCRDetails) => {
    const data = cloneDataWithDisplayNames(props.equipments).map((obj: any) => ({
        ...obj,
        equipmentNumberDisplayName: [obj.equipmentNumber, `/customs/customs-release/${obj.equipmentId}`],
        hotboxDisplayName: obj.isHotBox ? 'Y' : 'N',
        children: obj.pOs.map((pos: any) => ({
            ...pos,
            ...pos.skUs[0]
        }))
    }));

    return (
        <Accordion text="Equipment">
            <DataTable
                data={data}
                withCheckboxes={false}
                expandable={true}
                columns={[
                    ColumnFactory.linkedColumn('equipmentNumberDisplayName', 'Equipment number'),
                    ColumnFactory.standardColumn('serviceDisplayName', 'Service'),
                    ColumnFactory.standardColumn('equipmentTypeDisplayName', 'Size/type'),
                    ColumnFactory.standardColumn('hotboxDisplayName', 'Hotbox'),
                    ColumnFactory.standardColumn('sealNumberDisplayName', 'Seal number'),
                    ColumnFactory.standardColumn('totalPackagesDisplayName', 'Total packages'),
                    ColumnFactory.standardColumn('packagesTypeDisplayName', 'Package type'),
                    ColumnFactory.standardColumn('weightDisplayName', 'Weight'),
                    ColumnFactory.standardColumn('volumeDisplayName', 'Volume'),
                ]}
                subColumns={[
                    ColumnFactory.standardColumn('poNumberDisplayName', 'PO number'),
                    ColumnFactory.standardColumn('skuNumberDisplayName', 'SKU'),
                    ColumnFactory.standardColumn('goodsDescriptionDisplayName', 'Description'),
                    ColumnFactory.standardColumn('packagesPerCartonDisplayName', 'Packages'),
                    ColumnFactory.standardColumn('pieceTypeDisplayName', 'Piece type'),
                    ColumnFactory.standardColumn('termsDisplayName', 'Terms'),
                ]}
                reference={null as any}
                tableName="CustomsEquipmentTable"
            />
        </Accordion>
    );
}
