import React from 'react';
import { IHBLFCRDetails } from 'reducers/customs/models';
import { formatValueIfNullOrUndefined, formatDate, getArrayOfButtons } from 'utilities/util';
import { UpdateHBLCustomsModal } from 'modules/customs/ocean/components/UpdateHBLCustomsModal';
import { mapStatusToStringForCustoms } from 'constants/statuses';
import { BUSINNESS_FUNCTIONS } from 'constants/roles';
// tslint:disable-next-line: no-submodule-imports
import { IWithPermissions, withPermissions } from 'src/HOC/withPermissions';
import { PropertiesRibbonWrapper } from 'components/properties-ribbon';

interface IHBLFCRTopBarState {
    isUpdateCustomsModalOpened: boolean;
}

interface IHBLFCRTopBarProps extends IWithPermissions, IHBLFCRDetails {
    hblId: number;
    data: any;
}

class _HBLFCRTopBar extends React.Component<IHBLFCRTopBarProps, IHBLFCRTopBarState> {
    public state: IHBLFCRTopBarState = {
        isUpdateCustomsModalOpened: false,
    };

    public render() {
        const actions = [
            {label: 'Update customs clearance', action: this.toggleCustomsClearance},
        ];
        const propertiesRibbonData = {
            labels: [
                {label: 'Vessel name', value: formatValueIfNullOrUndefined(this.props.vesselName)},
                {label: 'New ETA', value: formatDate(this.props.newETA)},
                {label: 'ATA', value: formatDate(this.props.ata)},
                {label: 'Equipment Landed Date', value: formatDate(this.getLandedDate())},
                {label: 'Status', value: formatValueIfNullOrUndefined(mapStatusToStringForCustoms(this.props.customsStatus))},
            ],
            actions: getArrayOfButtons(this.props.permissions, actions)
        };

        return (
            <React.Fragment>
                <PropertiesRibbonWrapper content={propertiesRibbonData} />
                {this.state.isUpdateCustomsModalOpened && <UpdateHBLCustomsModal
                    visible={this.state.isUpdateCustomsModalOpened}
                    closeModal={this.toggleCustomsClearance}
                    hblId={[this.props.hblId]}
                    voyageId={[this.props.voyageId]}
                    data={this.props.data}
                    isDetails={true}
                />}
            </React.Fragment>
        );
    }

    private toggleCustomsClearance = () => this.setState((state) => ({
        isUpdateCustomsModalOpened: !state.isUpdateCustomsModalOpened,
    }));

    private getLandedDate = () => {
        const datesArray = this.props.equipments.map((equip: any) => equip.landedDate);

        if(datesArray.includes(null)) {
            return null
        } else {
            const sortedArray = datesArray.sort((a: string, b: string) => {
                if(new Date(a) < new Date(b)) {
                    return -1;
                }
                if(new Date(a) > new Date(b)) {
                    return 1;
                }
                return 0;
            });

            return sortedArray[sortedArray.length - 1];
        }
    };
}

const ROLES: BUSINNESS_FUNCTIONS[] = [BUSINNESS_FUNCTIONS.OCEAN_CUSTOMS_UPDATE_HBL_CUSTOMS_DETAILS];

export const HBLFCRTopBar = withPermissions<IHBLFCRTopBarProps>(_HBLFCRTopBar, ROLES) as any;