import React, { Component } from 'react';
import { LoadingAnimation } from 'components/loading';
import { ColumnFactory } from 'components/data-table/ColumnFactory';
import { DataTable } from 'components/data-table/DataTable';
import { connect } from 'react-redux';
import { IStore } from 'reducers/index';
import { IHolds } from 'reducers/customs/models';
import { Button, ButtonType } from 'components/button';
import styled from 'styled-components';
import { AddNewHoldModal } from 'modules/customs/ocean/components/AddNewHoldModal';
import { EditHoldModal } from 'modules/customs/ocean/components/EditHoldModal';
import { ConfirmModal } from 'components/confirm-modal';
import { HoldTypes } from 'constants/customs';
import { IOption } from 'components/select/Select';
import { cloneDataWithDisplayNames } from 'utilities/tables';

const SButton = styled(Button)`
    margin-top: 10px;
`;

interface IMapStateToProps {
    data: IHolds[] | any;
    isLoading: boolean;
}

interface IHoldsOwnProps {
    holds: IHolds[];
    dataForDropdown: IOption[][];
    removeHolds: (id: number) => void;
    editHolds: (body: IHolds) => void;
    addHolds: (body: IHolds) => void;
}

interface IHoldsProps extends IHoldsOwnProps, IMapStateToProps {}

interface IHoldsState {
    isHoldModalOpened: boolean;
    isEditHoldModalOpened: boolean;
    isConfirmDeleteModalOpened: boolean;
    holdId: number;
    holdToDelete: number;
}

class _Holds extends Component<IHoldsProps, IHoldsState> {
    public state: IHoldsState = {
        isHoldModalOpened: false,
        isEditHoldModalOpened: false,
        isConfirmDeleteModalOpened: false,
        holdToDelete: 0,
        holdId: 0
    };

    public render() {
        const data = cloneDataWithDisplayNames(this.props.holds).map((hold: any) => ({
            ...hold,
            holdTypeDisplayName: HoldTypes[hold.holdType]
        }));

        return(
            <>
                {this.props.isLoading && <LoadingAnimation />}
                <DataTable
                    data={data}
                    columns={[
                        ColumnFactory.standardColumnOld('holdTypeDisplayName', 'Hold type'),
                        ColumnFactory.standardColumnOld('equipmentNumberDisplayName', 'Equipment number'),
                        ColumnFactory.dateColumnOld('startDateDisplayName', 'State date'),
                        ColumnFactory.dateColumnOld('endDateDisplayName', 'End date'),
                        ColumnFactory.standardColumnOld('commentDisplayName', 'Comments'),
                        ColumnFactory.multiActionColumn('holdIdDisplayName', 'Actions', {
                            delete: {
                                func: (id: string) => this.showRemoveConfirmationDelete(Number(id)),
                                iconClass: 'fa-times'
                            },
                            edit: {
                                func: (id: string) => this.toggleEditHoldModal(Number(id)),
                                iconClass: 'fa-pencil'
                            }
                        })
                    ]}
                />
                <SButton
                    buttonType={ButtonType.Blue}
                    onClick={this.toggleHoldModal}
                    id="add-new-hold-button"
                >
                    Add new
                </SButton>
                <ConfirmModal
                    visible={this.state.isConfirmDeleteModalOpened}
                    title="Remove hold"
                    closeModal={this.hideConfirmDelete}
                    onConfirm={() => {
                        this.hideConfirmDelete();
                        this.props.removeHolds(this.state.holdToDelete)
                    }}
                >
                    Are you sure to remove the hold?
                </ConfirmModal>

                {this.state.isHoldModalOpened && <AddNewHoldModal
                    title="Add new hold"
                    visible={this.state.isHoldModalOpened}
                    closeModal={this.toggleHoldModal}
                    addHold={this.addHold}
                    dataForDropdown={this.props.dataForDropdown}
                />}

                {this.state.isEditHoldModalOpened && <EditHoldModal
                    title="Edit hold"
                    visible={this.state.isEditHoldModalOpened}
                    closeModal={() => this.toggleEditHoldModal(-1)}
                    editHold={this.editHold}
                    initialValues={this.getInitialValues(this.state.holdId)}
                    dataForDropdown={this.props.dataForDropdown}
                />}
            </>
        );
    }

    private hideConfirmDelete = () => {
        this.setState({
            isConfirmDeleteModalOpened: false,
        })
    };

    private showRemoveConfirmationDelete = (holdId: number) => {
        this.setState({
            isConfirmDeleteModalOpened: true,
            holdToDelete: holdId
        })
    };

    private addHold = (fields: any) => {
        const hold = {
            holdId: this.state.holdId,
            holdType: fields.HoldType,
            equipmentNumber: fields.EquipmentNumber,
            startDate: fields.StartDate,
            endDate: fields.EndDate,
            comment: fields.Comments
        };

        this.setState((state) => ({
            holdId: state.holdId + 1
        }));

        this.props.addHolds(hold);
        this.toggleHoldModal();
    }

    private editHold = (fields: any) => {
        const hold = {
            holdId: this.state.holdId,
            holdType: fields.HoldType,
            equipmentNumber: fields.EquipmentNumber,
            startDate: fields.StartDate,
            endDate: fields.EndDate,
            comment: fields.Comments
        };

        this.props.editHolds(hold);
        this.setState({ isEditHoldModalOpened: false });
    }

    private getInitialValues = (id: any) => {
        const hold = this.props.holds.find((obj: any) => obj.holdId === id);

        const initValues = hold ? {
            HoldType: hold.holdType,
            EquipmentNumber: hold.equipmentNumber,
            StartDate: hold.startDate,
            EndDate: hold.endDate,
            Comments: hold.comment
        } : {};
        return initValues;
    };

    private toggleHoldModal = () => this.setState((state) => ({
        isHoldModalOpened: !state.isHoldModalOpened
    }));

    private toggleEditHoldModal = (id: number) => this.setState((state) => ({
        isEditHoldModalOpened: !state.isEditHoldModalOpened,
        holdId: id
    }));
}

const mapStateToProps = (state: IStore): IMapStateToProps => ({
    data: state.customs.hblFcrDetails,
    isLoading: state.customs.isFetchHBLFCRDetailsLoading
});

export const Holds = connect<IMapStateToProps, {}, IHoldsOwnProps, IStore>(mapStateToProps)(_Holds);
