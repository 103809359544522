import React, { FunctionComponent, useEffect } from 'react';
import { Page } from 'components/page-container';
import { PageHeader } from 'components/page-header';
import { HBLDetailsTopBar } from 'views/bill-release/hbl-details/containers/HBLDetailsTopBar';
import { PageWithTimeline } from 'components/page-with-timeline';
import { HBLDetailsTable } from 'views/bill-release/hbl-details/components/HBLDetailsTable';
import { connect } from 'react-redux';
import { ThunkDispatch } from 'redux-thunk';
import { Action } from 'redux';
import { ContainerTrackingTypes } from 'constants/comment';
import { IStore } from 'reducers/index';
import { RouteComponentProps } from 'react-router-dom';
import { fetchHblDetails } from 'actions/bill-release';
import { IHblDetails } from 'reducers/bill-release/models';
import { LoadingAnimation } from 'components/loading';
import { Leg } from 'views/bill-release/hbl-details/components/Leg';
import { ProgressIndicator } from 'components/progress-indicator';
import { IBubble } from 'reducers/vessels/models';
import { UserDetails } from 'components/user-details';
import { Capability } from 'components/timeline';
import { GenericError } from 'components/error-dialog/GenericError';

interface IMapStateToProps {
    isHblDetailsLoading: boolean;
    hblDetails: IHblDetails;
    bubbles: IBubble[];
}

interface IMapDispatchToProps {
    fetchHblDetails: (id: number) => Promise<void>;
}

interface IHBLDetails extends IMapStateToProps, IMapDispatchToProps, RouteComponentProps<{ id: string }> {}

const _HBLDetails: FunctionComponent<IHBLDetails> = (props) => {
    const id = parseInt(props.match.params.id, 10);

    useEffect(() => {
        if (!Number.isNaN(id)) {
            props.fetchHblDetails(id);
        }
    }, []);

    const legsList = props.hblDetails.vessels.map((vessel: any, index: number) => {
        const modalTitle = props.hblDetails.vessels.length - 1 === index ? 'Final leg' : `Leg ${index + 1} `;

        return(
            <Leg
                key={index}
                modalTitle={modalTitle}
                vesselName={vessel.vesselName}
                voyage={vessel.voyageReference}
                pod={vessel.destinationPort}
                pol={vessel.loadingPort}
                atd={vessel.atd}
                ata={vessel.ata}
                currentETA={vessel.currentETA}
            />
        );
    }).reverse();

    if (Number.isNaN(id)) {
        return <GenericError />;
    }

    return (
        <>
            {props.isHblDetailsLoading && <LoadingAnimation /> }
            <PageHeader backButton={true}>HBL: {props.hblDetails.hblNumber}</PageHeader>
            <ProgressIndicator data={props.bubbles} />
            <HBLDetailsTopBar
                vessels={props.hblDetails.vessels}
                surrenderType={props.hblDetails.surrenderType}
                hblId={props.hblDetails.hblId}
                originalBLSurrenderDate={props.hblDetails.originalHBLSurrenderDate}
                originalBLSurrendered={props.hblDetails.originalHBLSurrendered}
            />
            <Page>
                <PageWithTimeline
                    objectId={id}
                    objectType={ContainerTrackingTypes.Voyage}
                    isAir={false}
                    capability={Capability.ContainerTracking}
                >
                    {legsList}
                    <HBLDetailsTable data={props.hblDetails.equipments}/>
                    <UserDetails
                        user={props.hblDetails.lastUserUpdated}
                        date={props.hblDetails.updateDate}
                    />
                </PageWithTimeline>
            </Page>
        </>
    );
}

const mapStateToProps = (state: IStore): IMapStateToProps => ({
    hblDetails: state.bill_release.hblDetails,
    isHblDetailsLoading: state.bill_release.isFetchHblDetailsLoading,
    bubbles: state.bill_release.hblDetails.bubbleData
});

const mapDispatchToProps = (dispatch: ThunkDispatch<any, any, Action>): IMapDispatchToProps => ({
    fetchHblDetails: (id: number) => dispatch(fetchHblDetails(id)),
});

export const HBLDetails = connect<IMapStateToProps, IMapDispatchToProps, {}, IStore>(mapStateToProps, mapDispatchToProps)(_HBLDetails);
