import React from 'react';
import { StatusRow } from 'components/status-row';
import { OneStatus } from 'components/status-row/OneStatus';
import { QuarterGrid } from 'components/grid/quarter-grid';
import { formatValueIfNullOrUndefined } from 'utilities/util';

interface IPlacesRowProps {
    originalPortOfLoadingName: string;
    originalPortOfLoadingCode: string;
    placeOfDeliveryName: string;
    placeOfDeliveryCode: string;
}

export const PlacesRow: React.StatelessComponent<IPlacesRowProps> = (props) => {
    return (
        <StatusRow title="Places">
            <QuarterGrid>
                <OneStatus label="Original port of loading name">
                    {formatValueIfNullOrUndefined(props.originalPortOfLoadingName)}
                </OneStatus>
                <OneStatus label="Original port of loading code">
                    {formatValueIfNullOrUndefined(props.originalPortOfLoadingCode)}
                </OneStatus>
                <OneStatus label="Place of delivery name">
                    {formatValueIfNullOrUndefined(props.placeOfDeliveryName)}
                </OneStatus>
                <OneStatus label="Place of delivery code">
                    {formatValueIfNullOrUndefined(props.placeOfDeliveryCode)}
                </OneStatus>
            </QuarterGrid>
        </StatusRow>
    );
};
