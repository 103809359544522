import React from 'react';
import { ColumnFactory } from 'components/data-table/ColumnFactory';
import { DataTable } from 'components/data-table/DamcoTable';
import { IFactCbl } from 'reducers/fact/models';
import { cloneDataWithDisplayNames } from 'utilities/tables';
import { StatusRow } from 'components/status-row';

interface ICblsTableProps {
    data: IFactCbl[];
}

export const CblsTable: React.StatelessComponent<ICblsTableProps> = (props) => {
    const data = cloneDataWithDisplayNames(props.data).map(cbl => ({
        ...cbl,
        numberDisplayName: [cbl.number, `/exception-management/fact/cbls/${cbl.id}`]
    }));

    return(
        <StatusRow title="Cbls">
            <DataTable
                data={data}
                columns={[
                    ColumnFactory.linkedColumn('numberDisplayName', 'Number'),
                    ColumnFactory.standardColumn('internalNumberDisplayName', 'Internal number'),
                    ColumnFactory.standardColumn('carrierBookingReferenceDisplayName', 'Carrier booking reference'),
                    ColumnFactory.standardColumn('transportContractNumberDisplayName', 'Transport contract number'),
                    ColumnFactory.standardColumn('carrierNameDisplayName', 'Carrier'),
                    ColumnFactory.standardColumn('shipperNameDisplayName', 'Shipper'),
                    ColumnFactory.standardColumn('originalPortOfLoadingNameDisplayName', 'Original port of loading'),
                    ColumnFactory.standardColumn('finalPortOfDischargeNameDisplayName', 'Final port of discharge'),
                    ColumnFactory.standardColumn('placeOfDeliveryNameDisplayName', 'Place of delivery'),
                ]}
                withCheckboxes={false}
                reference={null as any}
                tableName="FactDetailsCblsTable"
                showPagination={true}
            />
        </StatusRow>
    );
};

