import React from 'react';
import { ColumnFactory } from 'components/data-table/ColumnFactory';
import { LoadingAnimation } from 'components/loading';
import { IReasonCode } from 'reducers/settings/models';
import { connect } from 'react-redux';
import { DataTable } from 'components/data-table/DamcoTable';
import { CreateNewReasonCodeModal } from 'modules/settings/shared/CreateNewReasonCodeModal';
import { ThunkDispatch } from 'redux-thunk';
import { Action } from 'redux';
import { fetchCustomsReasonCodes, updateCustomsReasonCode, createCustomsReasonCode, resetCreateCustomsReasonCodeError, deleteCustomsReasonCode, resetUpdateReasonCodeError } from 'actions/settings';
import { IStore } from 'reducers/index';
import { ConfirmModal } from 'components/confirm-modal';
import { EditReasonCodeModal } from 'modules/settings/shared/EditReasonCodeModal';
import { cloneDataWithDisplayNames, prettifyString } from 'utilities/tables';
import { ReasonCommentMode } from 'constants/reasonCodes';

interface IMapStateToProps {
    isLoading: boolean;
    reasonCodes: IReasonCode[];
    createCustomsReasonCodeError: string;
    updateReasonCodeError: string;
}

interface IMapDispatchToProps {
    fetchCustomsReasonCodes: (group?: string) => Promise<void>;
    updateCustomsReasonCode: (body: any) => Promise<void>;
    createCustomsReasonCode: (body: any) => Promise<void>;
    resetCreateCustomsReasonCodeError: () => void;
    removeReasonCode: (id: number) => Promise<void>;
    resetUpdateReasonCodeError: () => void;
}

interface IReasonCodesTableOwnProps {
    isLoading: boolean;
    data: IReasonCode[];
}

interface IReasonCodesTable extends IReasonCodesTableOwnProps, IMapStateToProps, IMapDispatchToProps {}

interface IReasonCodesTableState {
    isEditConfigurationModalOpened: boolean;
    editRowKey: string | null;
    editedItem: any;
    createNewReasonCodeModalIsOpen: boolean;
    isConfirmModalOpen: boolean;
}

export class _ReasonCodesTable extends React.Component<IReasonCodesTable, IReasonCodesTableState> {
    public dataTable = React.createRef<any>();

    public state: IReasonCodesTableState = {
        isEditConfigurationModalOpened: false,
        editRowKey: null,
        editedItem: null,
        createNewReasonCodeModalIsOpen: false,
        isConfirmModalOpen: false,
    };

    public render() {
        const data = cloneDataWithDisplayNames(this.props.data).map(reasonCode => ({
            ...reasonCode,
            commentModeDisplayName: ReasonCommentMode[reasonCode.commentMode] || '-',
            groupDisplayName: prettifyString(reasonCode.group)
        }));
        
        return (
            <React.Fragment>
                {this.props.isLoading && <LoadingAnimation />}
                <DataTable
                    data={data}
                    expandable={false}
                    withCheckboxes={true}
                    selectVariant="single"
                    columns={[
                        ColumnFactory.standardColumn('reasonCodeDisplayName', 'Reason code'),
                        ColumnFactory.standardColumn('groupDisplayName', 'Group'),
                        ColumnFactory.standardColumn('commentModeDisplayName', 'Comments'),
                    ]}
                    actions={{
                        primaryActionIsMoreBtn: false,
                        primaryActionLabel: 'Add new',
                        primaryAction: this.toggleCreateReasonCodeModal,
                        parentContextualActions: [
                            { isMoreBtn: false, label: 'Edit', action: this.toggleEditModal },
                            { isMoreBtn: false, label: 'Delete', action: this.toggleConfirmModal },
                        ]
                    }}
                    reference={this.dataTable}
                    tableName="SettingsReasonCodesCustomsTable"
                    showPagination={true}
                />
                {this.state.createNewReasonCodeModalIsOpen && <CreateNewReasonCodeModal
                    title="Create reason code"
                    visible={this.state.createNewReasonCodeModalIsOpen}
                    errorMessage={this.props.createCustomsReasonCodeError}
                    resetErrorMessage={this.props.resetCreateCustomsReasonCodeError}
                    closeModal={this.toggleCreateReasonCodeModal}
                    saveFunction={this.createNewReasonCode}
                    reasonCodes={this.props.reasonCodes}
                    hasCommentMode={true}
                />}
                {this.state.isEditConfigurationModalOpened && <EditReasonCodeModal
                    title="Edit reason code"
                    visible={this.state.isEditConfigurationModalOpened}
                    closeModal={this.toggleEditModal}
                    saveFunction={this.updateSettings}
                    initialValues={this.getRowToEdit()}
                    reasonCodes={this.props.reasonCodes}
                    hasCommentMode={true}
                    error={this.props.updateReasonCodeError}
                    resetError={this.props.resetUpdateReasonCodeError}
                />}
                {this.state.isConfirmModalOpen && <ConfirmModal
                    visible={this.state.isConfirmModalOpen}
                    title="Delete reason code confirmation"
                    closeModal={this.toggleConfirmModal}
                    onConfirm={this.removeSettings}
                >
                    <p>Are you sure?</p>
                </ConfirmModal>}
            </React.Fragment>
        );
    }

    private toggleCreateReasonCodeModal = () => this.setState((state: IReasonCodesTableState) => ({
        createNewReasonCodeModalIsOpen: !state.createNewReasonCodeModalIsOpen
    }));

    private toggleConfirmModal = () => this.setState((state: IReasonCodesTableState) => ({
        isConfirmModalOpen: !state.isConfirmModalOpen
    }));

    private getId = () => {
        const selectedItems = this.dataTable.current ? this.dataTable.current.getSelectedItems() : [];
        return selectedItems[0].id;
    };

    private createNewReasonCode = async (configuration: any) => {
        try {
            const newReasonCode = {
                code: configuration.ReasonCode,
                group: configuration.Group,
                commentMode: configuration.commentMode,
            };
            await this.props.createCustomsReasonCode(newReasonCode);
            await this.toggleCreateReasonCodeModal();
            await this.props.fetchCustomsReasonCodes();
        } catch (error) {
            console.error(error);
        }
    };

    private toggleEditModal = (key?: string) => {
        const selectedItems = this.dataTable.current ? this.dataTable.current.getSelectedItems() : [];

        if (selectedItems[0]) {
            const rowToEdit = selectedItems[0];

            this.setState((state: IReasonCodesTableState) => ({
                isEditConfigurationModalOpened: !state.isEditConfigurationModalOpened,
                editRowKey: key || null,
                editedItem: rowToEdit ? rowToEdit : null
            }));
        }
    };

    private updateSettings = async (configuration: any) => {
        await this.props.updateCustomsReasonCode({
            ReasonCodeId: this.getId(),
            Code: configuration.ReasonCode,
            Group: configuration.Group,
            CommentMode: configuration.commentMode,
        });
        await this.toggleEditModal();
        await this.props.fetchCustomsReasonCodes();
    };

    private removeSettings = async () => {
        await this.props.removeReasonCode(this.getId());
        await this.toggleConfirmModal();
        await this.dataTable.current.hideContextRibbon();
        await this.props.fetchCustomsReasonCodes();
    };

    private getRowToEdit = () => {
        const selectedItems = this.dataTable.current ? this.dataTable.current.getSelectedItems() : [];

        return {
            ReasonCode: selectedItems[0].reasonCode,
            Group: selectedItems[0].group,
            commentMode: selectedItems[0].commentMode,
        };
    };
}

const mapStateToProps = (state: IStore): IMapStateToProps => ({
    isLoading: state.settings.fetchReasonCodeIsLoading,
    reasonCodes: state.settings.customsReasonCodes,
    createCustomsReasonCodeError: state.settings.createCustomsReasonCodeError,
    updateReasonCodeError: state.settings.updateCustomsReasonCodeError,
});

const mapDispatchToProps = (dispatch: ThunkDispatch<any, any, Action>): IMapDispatchToProps => ({
    fetchCustomsReasonCodes: (group?: string) => dispatch(fetchCustomsReasonCodes(group)),
    updateCustomsReasonCode: (body: any) => dispatch(updateCustomsReasonCode(body)),
    createCustomsReasonCode: (body: any) => dispatch(createCustomsReasonCode(body)),
    resetCreateCustomsReasonCodeError: () => dispatch(resetCreateCustomsReasonCodeError()),
    removeReasonCode: (id: number) => dispatch(deleteCustomsReasonCode(id)),
    resetUpdateReasonCodeError: () => dispatch(resetUpdateReasonCodeError())
});

export const ReasonCodesTable = connect(mapStateToProps, mapDispatchToProps)(_ReasonCodesTable);
