import React from 'react';
import { EditEmptyReturnDetailsPopup } from 'modules/delivery-and-return/ocean/components/EditEmptyReturnDetailsPopup';
import { EditTransportationDetailsModal } from 'views/delivery-and-return/equipment-details/components/EditTransportationDetailsModal';
import { IEquipmentDetails } from 'reducers/planning/models';
import { formatDate, formatValueIfNullOrUndefined, getArrayOfButtons } from 'utilities/util';
import { EditDAndDDetailsPopup } from 'modules/modals/components/EditDAndDDetailsPopup';
import { BUSINNESS_FUNCTIONS } from 'constants/roles';
// tslint:disable-next-line: no-submodule-imports
import { withPermissions, IWithPermissions } from 'src/HOC/withPermissions';
import { PropertiesRibbonWrapper } from 'components/properties-ribbon';


interface IDeliveryAndReturnContainerDetailsTopBar extends IWithPermissions {
    equipment: IEquipmentDetails;
    refecthFunction: () => void;
}

interface IDeliveryAndReturnContainerDetailsTopBarState {
    isEditTransportationDetailsModalOpened: boolean;
    isEditDetailsModalOpened: boolean;
    isEditDetailsDnDModalOpened: boolean;
}

class _DeliveryAndReturnContainerDetailsTopBar extends React.Component<IDeliveryAndReturnContainerDetailsTopBar, IDeliveryAndReturnContainerDetailsTopBarState> {
    public state: IDeliveryAndReturnContainerDetailsTopBarState = {
        isEditTransportationDetailsModalOpened: false,
        isEditDetailsModalOpened: false,
        isEditDetailsDnDModalOpened: false,
    };

    public toggleEditDetailsModal = () => this.setState((state) => ({
        isEditDetailsModalOpened: !state.isEditDetailsModalOpened
    }));

    public toggleEditDnDDetailsModal = () => this.setState((state) => ({
        isEditDetailsDnDModalOpened: !state.isEditDetailsDnDModalOpened
    }));

    public toggleEditTransportationDetailsModal = () => this.setState((state) => ({
        isEditTransportationDetailsModalOpened: !state.isEditTransportationDetailsModalOpened,
    }));

    public render() {
        const actions = [
            {label: 'Update final delivery details', action: this.toggleEditDetailsModal},
            {label: 'Update transport details', action: this.toggleEditTransportationDetailsModal},
            {label: 'Update D&D details', action: this.toggleEditDnDDetailsModal},
        ];
        const propertiesRibbonData = {
            labels: [
                {label: 'Planned date / time', value: formatDate(this.props.equipment.deliveryPlannedDate)},
                {label: 'Demurrage expiry', value: formatDate(this.props.equipment.demmurageFreeTimeExpiry)},
                {label: 'Detention expiry', value: formatDate(this.props.equipment.detentionFreeTimeExpiry)},
                {label: 'GOH', value: formatValueIfNullOrUndefined(this.props.equipment.isGOH ? 'Y' : 'N')},
                {label: 'Current location', value: formatValueIfNullOrUndefined(this.props.equipment.deliveryCurrentLocation)},
                {label: 'Delivery actual', value: formatDate(this.props.equipment.deliveryActualDate)}
            ],
            actions: getArrayOfButtons(this.props.permissions, actions)
        };

        return (
            <React.Fragment>
                <PropertiesRibbonWrapper content={propertiesRibbonData} />
                {this.state.isEditTransportationDetailsModalOpened && <EditTransportationDetailsModal
                    visible={this.state.isEditTransportationDetailsModalOpened}
                    closeModal={this.toggleEditTransportationDetailsModal}
                    isAir={false}
                    id={this.props.equipment.id}
                    refetchFunction={this.props.refecthFunction}
                />}
                {this.state.isEditDetailsModalOpened && <EditEmptyReturnDetailsPopup
                    visible={this.state.isEditDetailsModalOpened}
                    closeModal={this.toggleEditDetailsModal}
                    initialValues={this.props.equipment}
                    refetchFunction={this.props.refecthFunction}
                    status={this.props.equipment.deliveryStatusEnum}
                />}
                {this.state.isEditDetailsDnDModalOpened && <EditDAndDDetailsPopup
                    visible={this.state.isEditDetailsDnDModalOpened}
                    closeModal={this.toggleEditDnDDetailsModal}
                    id={this.props.equipment.id}
                    refetchFunction={this.props.refecthFunction}
                />}
            </React.Fragment>
        );
    }
}

const ROLES: BUSINNESS_FUNCTIONS[] = [
    BUSINNESS_FUNCTIONS.OCEAN_DELIVERY_EQUIPMENT_UPDATE_DELIVERY_DETAILS,
    BUSINNESS_FUNCTIONS.OCEAN_DELIVERY_EDIT_TRANSPORTATION_DETAILS,
    BUSINNESS_FUNCTIONS.OCEAN_DD_EQUIPMENT_UPDATE_DELIVERY_DETAILS
];

export const DeliveryAndReturnContainerDetailsTopBar = withPermissions<IDeliveryAndReturnContainerDetailsTopBar>(_DeliveryAndReturnContainerDetailsTopBar, ROLES) as any;