import { ThunkDispatch } from 'redux-thunk';
import { Action } from 'redux';
import { TYPES } from 'action-types/demurrageAndDetention';
import { callApiPost, callApiDelete, callApiPut } from 'utilities/apiCaller';
import API from 'constants/api';
import { IAddNewDDContractBody, IUpdateDDContractBody } from 'reducers/demurrage-and-detention/models';
import { applyFilters, applyPageDetailsWithoutFilters, generateError } from 'utilities/util';
import { IStore } from 'reducers/index';
import { Dispatch } from 'redux';

export interface IGetDDContractsQuery {
    Filters?: any,
    Page: number,
    PageSize: number;
}

export const getDemurrageEquipments = (pageNumber?: number, pageSize?: number) => {
    return async (dispatch: ThunkDispatch<any, any, Action>, getState: () => IStore): Promise<void> => {
        dispatch({ type: TYPES.GET_DEMURRAGE_EQUIPMENTS.PENDING });
        try {
            const res = await callApiPost(API.DemurrageAndDetention.GetDemurrageEquipments, applyFilters(getState, { isAirInBody: false, isAirInFilters: false }, {
                Page: pageNumber,
                PageNumber: pageNumber,
                PageSize: pageSize
            }), {headers: {TransportMode: 'Ocean'}});
            const demurrage = {
                ...res.data,
                data: res.data.result,
            };
            dispatch({ type: TYPES.GET_DEMURRAGE_EQUIPMENTS.FULFILLED, demurrage });
        } catch (e) {
            const error = generateError(e);
            dispatch({ type: TYPES.GET_DEMURRAGE_EQUIPMENTS.REJECTED, error });
            throw e;
        }
    };
};

export const resetGetDemurrageEquipmentsError = () => (dispatch: Dispatch) => {
    dispatch({ type: TYPES.GET_DEMURRAGE_EQUIPMENTS.RESET_ERROR })
}

export const getDetentionEquipments = (pageNumber?: number, pageSize?: number) => {
    return async (dispatch: ThunkDispatch<any, any, Action>, getState: () => IStore): Promise<void> => {
        dispatch({ type: TYPES.GET_DETENTION_EQUIPMENTS.PENDING });
        try {
            const res = await callApiPost(API.DemurrageAndDetention.GetDetentionEquipments, applyFilters(getState, { isAirInBody: false, isAirInFilters: false }, {
                Page: pageNumber,
                PageNumber: pageNumber,
                PageSize: pageSize
            }), {headers: {TransportMode: 'Ocean'}});
            const detention = {
                ...res.data,
                data: res.data.result,
            };
            dispatch({ type: TYPES.GET_DETENTION_EQUIPMENTS.FULFILLED, detention });
        } catch (e) {
            const error = generateError(e);
            dispatch({ type: TYPES.GET_DETENTION_EQUIPMENTS.REJECTED, error });
            throw e;
        }
    };
};

export const resetGetDetentionEquipmentsError = () => (dispatch: Dispatch) => {
    dispatch({ type: TYPES.GET_DEMURRAGE_EQUIPMENTS.RESET_ERROR })
}

export const getDDContracts = (pageNumber?: number, pageSize?: number) => {
    return async (dispatch: ThunkDispatch<any, any, Action>, getState: () => IStore) => {
        dispatch({ type: TYPES.GET_CONTRACTS.PENDING });
        try {
            const res = await callApiPost(API.DemurrageAndDetention.GetDDContracts, applyPageDetailsWithoutFilters(getState, { isAirInBody: false, isAirInFilters: false }, {
                Page: pageNumber,
                PageNumber: pageNumber,
                PageSize: pageSize
            }), {headers: {TransportMode: 'Ocean'}});
            dispatch({ type: TYPES.GET_CONTRACTS.FULFILLED, contracts: res.data });
        } catch (e) {
            const error = generateError(e);
            dispatch({ type: TYPES.GET_CONTRACTS.REJECTED, error });
            throw e;
        }
    }
}

export const resetGetDDContractsError = () => (dispatch: Dispatch) => {
    dispatch({type: TYPES.GET_CONTRACTS.RESET_ERROR });
}

export const addNewDDContract = (body: IAddNewDDContractBody) => {
    return async (dispatch: ThunkDispatch<any, any, Action>) => {
        dispatch({ type: TYPES.ADD_CONTRACT.PENDING });
        try {
            const res = await callApiPost(API.DemurrageAndDetention.AddDDContract, body, {headers: {TransportMode: 'Ocean'}});
            dispatch({ type: TYPES.ADD_CONTRACT.FULFILLED, newContract: res.data });
        } catch (e) {
            const error = generateError(e);
            dispatch({ type: TYPES.ADD_CONTRACT.REJECTED, error });
            throw e;
        }
    }
}

export const resetAddNewDDContractError = () => (dispatch: Dispatch) => {
    dispatch({type: TYPES.ADD_CONTRACT.RESET_ERROR });
}

export const updateNewDDContract = (body: IUpdateDDContractBody) => {
    return async (dispatch: ThunkDispatch<any, any, Action>) => {
        dispatch({ type: TYPES.EDIT_CONTRACT.PENDING });
        try {
            const res = await callApiPut(API.DemurrageAndDetention.UpdateDDContract, body, {headers: {TransportMode: 'Ocean'}});
            dispatch({ type: TYPES.EDIT_CONTRACT.FULFILLED, updatedContract: res.data });
        } catch (e) {
            const error = generateError(e);
            dispatch({ type: TYPES.EDIT_CONTRACT.REJECTED, error });
            throw e;
        }
    }
};

export const resetUpdateNewDDContractError = () => (dispatch: Dispatch) => {
    dispatch({type: TYPES.EDIT_CONTRACT.RESET_ERROR });
}

export const removeDDContract = (id: number) => {
    return async (dispatch: ThunkDispatch<any, any, Action>) => {
        dispatch({ type: TYPES.DELETE_CONTRACT.PENDING });
        try {
            const res = await callApiDelete(API.DemurrageAndDetention.DeleteContract(id), {headers: {TransportMode: 'Ocean'}});
            dispatch({ type: TYPES.DELETE_CONTRACT.FULFILLED, contracts: res.data });
        } catch (e) {
            const error = generateError(e);
            dispatch({ type: TYPES.DELETE_CONTRACT.REJECTED, error });
            throw e;
        }
    }
};

export const resetRemoveDDContractError = () => (dispatch: Dispatch) => {
    dispatch({type: TYPES.DELETE_CONTRACT.RESET_ERROR });
}


export const editDemurrageAndDetention = (fields: any) => {
    return async (dispatch: ThunkDispatch<any, any, Action>) => {
        dispatch({ type: TYPES.EDIT_DEMURRAGE_AND_DETENTION_DETAILS.PENDING });
        try {
            await callApiPost(API.DemurrageAndDetention.EditDnDContainerDetails, fields, {headers: {TransportMode: 'Ocean'}});
            dispatch({ type: TYPES.EDIT_DEMURRAGE_AND_DETENTION_DETAILS.FULFILLED });
        } catch (e) {
            const error = generateError(e);
            dispatch({ type: TYPES.EDIT_DEMURRAGE_AND_DETENTION_DETAILS.REJECTED, error });
            throw error;
        }
    }
};

export const resetEditDemurrageAndDetentionError = () => (dispatch: Dispatch) => {
    dispatch({type: TYPES.EDIT_DEMURRAGE_AND_DETENTION_DETAILS.RESET_ERROR });
}
