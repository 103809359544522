import React, { Component } from 'react';
import { ModalScreen } from 'components/modal-screen';
import { ModalType } from 'components/modal-screen/ModalType';
import { reduxForm, InjectedFormProps, reset } from 'redux-form';
import { DoubleGrid } from 'components/grid/double-grid';
import { FormDateInput } from 'components/date-input/FormDateInput';
import { Dispatch } from 'redux';
import { FormSelect } from 'components/select/FormSelect';
import { holdTypesOptions } from 'constants/customs';
import { SingleGrid } from 'components/grid/single-grid';
import { FormTextArea } from 'components/textarea/FormTextArea';
import { IOption } from 'components/select/Select';

interface IEditHoldModalProps {
    title: string;
    visible: boolean;
    dataForDropdown: IOption[][];
    isAir?: boolean;
    editHold: (fields: {}) => void;
    closeModal: () => void;
}

class _EditHoldModal extends Component<InjectedFormProps<{},IEditHoldModalProps> & IEditHoldModalProps> {
    public render() {
        return (
            <form onSubmit={this.props.handleSubmit(this.props.editHold)}>
                <ModalScreen
                    title={this.props.title}
                    modalType={ModalType.s()}
                    primaryButtonTitle="Save"
                    primaryButtonType="submit"
                    secondaryButtonTitle="Cancel"
                    secondaryButtonFunc={this.props.closeModal}
                    visible={this.props.visible}
                    closeModal={this.props.closeModal}
                >
                    <DoubleGrid>
                        <FormSelect
                            label="Hold type"
                            name="HoldType"
                            options={holdTypesOptions}
                        />
                        <FormSelect
                            label="Equipment number"
                            name="EquipmentNumber"
                            options={this.mapOptionsForDropdown()}
                        />
                    </DoubleGrid>
                    <DoubleGrid>
                        <FormDateInput
                            label="Start date"
                            name="StartDate"
                            withTime={this.props.isAir ? true : false}
                        />
                        <FormDateInput
                            label="End date"
                            name="EndDate"
                            withTime={this.props.isAir ? true : false}
                        />
                    </DoubleGrid>
                    <SingleGrid>
                        <FormTextArea
                            label="Comments"
                            name="Comments"
                        />
                    </SingleGrid>
                </ModalScreen>
            </form>
        );
    }

    private mapOptionsForDropdown = () => {
        const options: IOption[] = [];
        this.props.dataForDropdown.map(arr => options.push(...arr));

        return options;
    };
};

const FORM_NAME = 'EDIT_HOLD_TO_HBL';
const onSubmitSuccess = (_: any, dispatch: Dispatch) => dispatch(reset(FORM_NAME));

export const EditHoldModal = reduxForm<{}, IEditHoldModalProps>({
    form: FORM_NAME,
    onSubmitSuccess,
})(_EditHoldModal);
