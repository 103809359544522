import React, { useRef, useState } from 'react';
import { LoadingAnimation } from 'components/loading';
import { ColumnFactory } from 'components/data-table/ColumnFactory';
import { IPOPlanningData } from 'reducers/planning/models';
import { DataTable } from 'components/data-table/DamcoTable';
import { cloneDataWithDisplayNames } from 'utilities/tables';
import { mapStatusToStringForDP } from 'constants/statuses';
import { NotifyCustomerModal } from 'modules/modals/components/NotifyCustomerModalForDP';
import { getArrayOfButtons } from 'utilities/util';
// tslint:disable-next-line: no-submodule-imports
import { IWithPermissions, withPermissions } from 'src/HOC/withPermissions';
import { BUSINNESS_FUNCTIONS } from 'constants/roles';
import { CTReasonCodes } from 'constants/reasonCodes';

interface IPOTableOwnProps {
    data: IPOPlanningData[];
    isLoading: boolean;
    pageCount: number;
    fetchEquipments: any;
    refreshFunction: () => Promise<void>;
}

interface IPOTableProps extends IWithPermissions, IPOTableOwnProps {}

const _AirPOTable: React.FunctionComponent<IPOTableProps> = (props) => {
    const dataTable = useRef<any>();
    const [isNotifyCustomerModalOpened, toggleNotifyCustomer] = useState<boolean>(false);
    const data = cloneDataWithDisplayNames(props.data).map(po => ({
        ...po,
        id: po.poId,
        HAWBNumberDisplayName: [po.HAWBNumber, `/planning/hawb/${po.equipmnetId}`],
        deliveryStatusDisplayName: mapStatusToStringForDP(po.deliveryStatus)
    }));

    const toggleNotifyCustomerModal = () => toggleNotifyCustomer(!isNotifyCustomerModalOpened);

    const poIds = dataTable.current ? dataTable.current.getSelectedItems().map((item: any) => item.poId) : [];

    return(
        <React.Fragment>
            {props.isLoading && <LoadingAnimation />}
            <DataTable
                data={data}
                withCheckboxes={true}
                selectVariant="multiple"
                expandable={false}
                columns={[
                    ColumnFactory.standardColumn('poNumberDisplayName', 'PO number'),
                    ColumnFactory.standardColumn('packagesDisplayName', 'Packages'),
                    ColumnFactory.standardColumn('weightDisplayName', 'Weight'),
                    ColumnFactory.linkedColumn('HAWBNumberDisplayName', 'HAWB number'),
                    ColumnFactory.standardColumn('podDisplayName', 'POD'),
                    ColumnFactory.standardColumn('newETADisplayName', 'New ETA'),
                    ColumnFactory.standardColumn('ataDisplayName', 'ATA'),
                    ColumnFactory.standardColumn('availableForBookingDisplayName', 'Available For Booking'),
                    ColumnFactory.standardColumn('deliveryLocationDisplayName', 'Delivery location'),
                    ColumnFactory.standardColumn('originalDeliveryDateDisplayName', 'Original planned date'),
                    ColumnFactory.standardColumn('deliveryActualDateDisplayName', 'Delivery date'),
                    ColumnFactory.standardColumn('deliveryStatusDisplayName', 'Status'),
                    ColumnFactory.standardColumn('reasonCodeDisplayName', 'Reason code'),
                ]}
                actions={{
                    parentContextualActions: getArrayOfButtons(
                        props.permissions,
                        [
                            { isMoreBtn: false, label: 'Notify customer', action: toggleNotifyCustomerModal }
                        ])
                }}
                reference={dataTable}
                tableName="AirPOTable"
                manualPagination={true}
                fetchData={props.fetchEquipments}
                pageCount={props.pageCount}
                showColumnOptions={true}
            />
            {isNotifyCustomerModalOpened && <NotifyCustomerModal
                visible={isNotifyCustomerModalOpened}
                closeModal={toggleNotifyCustomerModal}
                refreshFunction={props.refreshFunction}
                blId={poIds}
                isAir={true}
                group={CTReasonCodes.Air_LateShipment}
            />}
        </React.Fragment>
    );
}

const ROLES: BUSINNESS_FUNCTIONS[] = [
    BUSINNESS_FUNCTIONS.AIR_PO_PLANNING_NOTIFY_CUSTOMER
];

export const AirPOTable = withPermissions(_AirPOTable, ROLES) as any;