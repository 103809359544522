import React, { FunctionComponent, useState, useEffect } from 'react';
import { ModalScreen } from 'components/modal-screen';
import { ModalType } from 'components/modal-screen/ModalType';
import { OneStatus } from 'components/status-row/OneStatus';
import { reduxForm, InjectedFormProps } from 'redux-form';
import { DoubleGrid } from 'components/grid/double-grid';
import { FormDateInput } from 'components/date-input/FormDateInput';
import { ThunkDispatch } from 'redux-thunk';
import { Action, compose } from 'redux';
import { connect } from 'react-redux';
import { editVesselDetails, fetchVesselDetails, fetchVessels, resetEditVesselDetailsError } from 'actions/vessels';
import { LoadingAnimation } from 'components/loading';
import { IStore } from 'reducers/index';
import { IAddComment, addNewComment } from 'actions/timeline';
import { FormInput } from 'components/input/FormInput';
import { SingleGrid } from 'components/grid/single-grid';
import { NoDateModal } from 'modules/modals/components/NoDateModal';
import { Dialog } from 'components/dialog';
import { Capability } from 'components/timeline';
import { required } from 'utilities/validate';
import { ErrorDialog } from 'components/error-dialog';
import API from 'constants/api';

interface IDispatch {
    editVesselDetails: (fields: IFormProps) => Promise<void>;
    fetchVesselDetails: (voyageId: number) => Promise<void>;
    fetchVessels: () => Promise<void>;
    addNewComment: (body: IAddComment) => void;
    resetEditVesselDetailsError: () => void;
}
interface IMapStateToProps {
    isLoading: boolean;
    editVesselPendingError: string;
}

interface IVesselDetailsPopupOwnProps {
    visible: boolean;
    isDetails?: boolean;
    closeModal: () => void;
}

interface IFormProps {
    VesselName: string;
    VoyageReference: string;
    ATD: string;
    ATA: string;
    ETD: string;
    ETA: string;
    POLCode: string;
    PODCode: string;
}

interface IVesselDetailsPopup extends IVesselDetailsPopupOwnProps, InjectedFormProps<IFormProps, IVesselDetailsPopupOwnProps> , IMapStateToProps, IDispatch {}

const FORM_NAME: string = 'VESSEL_DETAILS_POPUP';

const _VesselDetailsPopup: FunctionComponent<IVesselDetailsPopup> = (props) => {
    const [comment, setComment] = useState<string>('');
    const [isConfirmationPopupOpen, setConfirmationPopupOpen] = useState<boolean>(false);
    const [fieldsToSend, setFieldsToSend] = useState<any>(null);

    useEffect(() => {
        props.resetEditVesselDetailsError();
    }, []);

    const submit = (fields: any) => {
        const initialValues  = props.initialValues;
        setFieldsToSend(fields);

        if (
            (initialValues.ATA && !fields.ATA) ||
            (initialValues.ATD && !fields.ATD) ||
            (initialValues.ETA && !fields.ETA) ||
            (initialValues.ETD && !fields.ETD)
        ) {
            setConfirmationPopupOpen(true);
        } else {
            makeRequest(fields);
        }
    };

    const makeRequest = async (fields: any) => {
        await props.editVesselDetails(fields);
        if(comment.length > 0) {
            props.addNewComment({
                objectId: fields.VoyageId,
                objectType: 'voyage',
                content: comment
            });
        }
        props.closeModal();
        if(props.isDetails) {
            props.fetchVesselDetails(fields.VoyageId);
        } else {
            props.fetchVessels();
        }
    }

    return (
        <>
            {props.isLoading && <LoadingAnimation />}
            <form onSubmit={props.handleSubmit(submit)}>
                <ModalScreen
                    title="Update vessel details"
                    modalType={ModalType.s()}
                    primaryButtonTitle="Save"
                    primaryButtonType="submit"
                    secondaryButtonTitle="Cancel"
                    secondaryButtonFunc={props.closeModal}
                    visible={props.visible}
                    closeModal={props.closeModal}
                >
                    <ErrorDialog endpoint={API.ContainerTracking.EditVessel} />
                    <section className="page-container">
                        <Dialog
                            message={props.editVesselPendingError}
                            isVisible={!!props.editVesselPendingError}
                            closeDialog={props.resetEditVesselDetailsError}
                            dialogType={500}
                        />
                        <DoubleGrid>
                            <FormInput
                                label="Vessel name"
                                name="VesselName"
                                disabled={true}
                            />
                            <FormInput
                                label="Voyage"
                                name="VoyageReference"
                                disabled={true}
                            />
                        </DoubleGrid>
                        <DoubleGrid>
                            <OneStatus label="ETD">
                                <FormDateInput
                                    name="ETD"
                                    validate={[required]}
                                />
                            </OneStatus>
                            <OneStatus label="ETA">
                                <FormDateInput
                                    name="ETA"
                                    validate={[required]}
                                />
                            </OneStatus>
                        </DoubleGrid>
                        <DoubleGrid>
                            <OneStatus label="ATD">
                                <FormDateInput
                                    name="ATD"
                                />
                            </OneStatus>
                            <OneStatus label="ATA">
                                <FormDateInput
                                    name="ATA"
                                />
                            </OneStatus>
                        </DoubleGrid>
                        <DoubleGrid>
                            <FormInput
                                label="POL"
                                name="POLCode"
                                disabled={true}
                            />
                            <FormInput
                                label="POD"
                                name="PODCode"
                                disabled={true}
                            />
                        </DoubleGrid>
                        <SingleGrid>
                            <OneStatus label="Comment">
                                <textarea
                                    className="form-control"
                                    onChange={(e: any) => setComment(e.target.value)}
                                />
                            </OneStatus>
                        </SingleGrid>
                    </section>
                </ModalScreen>
            </form>
            <NoDateModal
                visible={isConfirmationPopupOpen}
                onAccept={() => makeRequest(fieldsToSend)}
                onClose={() => setConfirmationPopupOpen(false)}
            />
        </>
    );
}

const mapStateToProps = (state: IStore): IMapStateToProps => ({
    isLoading: state.vessels.isEditVesselLoading,
    editVesselPendingError: state.vessels.editVesselPendingError,
});

const mapDispatchToProps = (dispatch: ThunkDispatch<any, any, Action>): IDispatch => ({
    editVesselDetails: (data: IFormProps) => dispatch(editVesselDetails(data, false, undefined, undefined)),
    fetchVesselDetails: (voyageId: number) => dispatch(fetchVesselDetails(voyageId, false)),
    fetchVessels: () => dispatch(fetchVessels(1,10)),
    addNewComment: (body: IAddComment) => dispatch(addNewComment(body, false, Capability.ContainerTracking)),
    resetEditVesselDetailsError: () => dispatch(resetEditVesselDetailsError()),
});

export const VesselDetailsPopup = compose(
    reduxForm<IFormProps, IVesselDetailsPopupOwnProps>({
        form: FORM_NAME,
    }),
    connect<IMapStateToProps, IDispatch, IVesselDetailsPopupOwnProps, IStore>(mapStateToProps, mapDispatchToProps),
)(_VesselDetailsPopup);
