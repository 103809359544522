import React from 'react';

interface IArrowProps extends React.HTMLAttributes<HTMLLIElement> {
    isUp: boolean;
}

export const Arrow = ({ isUp, ...elementProps }: IArrowProps) => {
    const arrow = isUp ? 'fa fa-chevron-up' : 'fa fa-chevron-down';
    return <i className={arrow} aria-hidden="true" {...elementProps} />;
};
