import React from 'react';
import { ColumnFactory } from 'components/data-table/ColumnFactory';
import { LoadingAnimation } from 'components/loading';
import { ISettingsModel, ICreateSettingsModel, ICreateBroker, IUpdateBroker } from 'reducers/settings/models';
import { ConfigurationModal } from './ConfigurationModal';
import { DataTable } from 'components/data-table/DamcoTable';
import { connect } from 'react-redux';
import { ThunkDispatch } from 'redux-thunk';
import { Action } from 'redux';
import { IStore } from 'reducers/index';
import { fetchCustomsBrokerSettings, createCustomsBrokerConfiguration, deleteCustomsBrokersConfiguration, updateCustomsBrokerConfiguration } from 'actions/settings';
import { ErrorModal } from 'modules/modals/components/ErrorModal';

interface IDispatch {
    fetchCustomsBrokerSettings: () => void;
    createCustomsBrokerConfiguration: (configuration: ICreateBroker) => Promise<void>;
    updateCustomsBrokerConfiguration: (configuration: IUpdateBroker) => Promise<void>;
    deleteCustomsBrokersConfiguration: (id: string) => Promise<void>;
}

interface IMapStateToProps {
    customsBrokerSettings: ISettingsModel[];
    fetchCustomsBrokerSettingsPending: boolean;
    updateCustomsBrokerSettingsPending: boolean;
    deleteCustomsBrokerSettingsPending: boolean;
    createCustomsBrokerSettingsPending: boolean;
}

interface IConfigurationData extends IMapStateToProps, IDispatch {
    isLoading: boolean;
    updateConfigurationPending: boolean;
    data: ISettingsModel[];
    deleteConfiguration: (id: string) => void;
    updateConfiguration: (configuration: ISettingsModel) => void;
}

interface IConfigurationTableState {
    isEditConfigurationModalOpened: boolean;
    editRowId: null | string;
    isCreateBrokerConfigurationModalOpened: boolean;
    isErrorModalOpen: boolean;
}

class _ConfigurationTable extends React.Component<IConfigurationData, IConfigurationTableState> {
    public dataTable = React.createRef<any>();

    public state: IConfigurationTableState = {
        isEditConfigurationModalOpened: false,
        editRowId: null,
        isCreateBrokerConfigurationModalOpened: false,
        isErrorModalOpen: false
    };

    public render() {
        return (
            <React.Fragment>
                {this.props.isLoading && <LoadingAnimation />}
                <DataTable
                    data={this.props.data}
                    expandable={false}
                    withCheckboxes={true}
                    selectVariant="single"
                    columns={[
                        ColumnFactory.standardColumn('name', 'Name'),
                        ColumnFactory.standardColumn('email', 'Email'),
                        ColumnFactory.standardColumn('subject', 'Subject'),
                        ColumnFactory.standardColumn('body', 'Body'),
                        ColumnFactory.standardColumn('country', 'Country'),
                        ColumnFactory.standardColumn('beCode', 'BeCode'),
                    ]}
                    actions={{
                        primaryActionIsMoreBtn: false,
                        primaryActionLabel: 'Add new',
                        primaryAction: this.toggleCreateBrokerConfigurationModal,
                        parentContextualActions: [
                            { isMoreBtn: false, label: 'Edit', action: this.toggleEditModal },
                            { isMoreBtn: false, label: 'Delete', action: this.deleteConfiguration },
                        ]
                    }}
                    reference={this.dataTable}
                    tableName="SettingsBrokersCustomsTable"
                    showPagination={true}
                />
                {this.state.isCreateBrokerConfigurationModalOpened && <ConfigurationModal
                    title="Create broker configuration"
                    visible={this.state.isCreateBrokerConfigurationModalOpened}
                    closeModal={this.toggleCreateBrokerConfigurationModal}
                    saveFunction={this.createBrokerConfiguration}
                    isLoading={this.props.createCustomsBrokerSettingsPending}
                />}
                {this.state.isEditConfigurationModalOpened && <ConfigurationModal
                    title="Edit derig configuration"
                    visible={this.state.isEditConfigurationModalOpened}
                    closeModal={this.toggleEditModal}
                    saveFunction={this.updateConfiguration}
                    initialValues={this.getRowToEdit()}
                    isLoading={this.props.updateConfigurationPending}
                />}
                {this.state.isErrorModalOpen && <ErrorModal
                    visible={this.state.isErrorModalOpen}
                    closeModal={this.toggleErrorModal}
                    primaryButtonFunc={this.toggleErrorModal}
                />}
            </React.Fragment>
        );
    }

    private toggleCreateBrokerConfigurationModal = () => this.setState((state: IConfigurationTableState) => ({
        isCreateBrokerConfigurationModalOpened: !state.isCreateBrokerConfigurationModalOpened,
    }));

    private toggleEditModal = (id?: string) => this.setState((state: IConfigurationTableState) => ({
        isEditConfigurationModalOpened: !state.isEditConfigurationModalOpened
    }));

    private toggleErrorModal = () => this.setState((state: IConfigurationTableState) => ({
        isErrorModalOpen: !state.isErrorModalOpen
    }));

    private createBrokerConfiguration = async (configuration: ICreateSettingsModel) => {
        await this.props.createCustomsBrokerConfiguration({Broker: configuration});
        await this.toggleCreateBrokerConfigurationModal();
        await this.props.fetchCustomsBrokerSettings();
    };

    private deleteConfiguration = async () => {
        await this.props.deleteConfiguration(this.getId());
        await this.dataTable.current.hideContextRibbon();
    };

    private updateConfiguration = async (configuration: ISettingsModel) => {
        await this.props.updateConfiguration(configuration);
        await this.toggleEditModal();
    };

    private getRowToEdit = () => {
        const selectedItems = this.dataTable.current ? this.dataTable.current.getSelectedItems() : [];

        return {
            Name: selectedItems[0].name,
            Email: selectedItems[0].email,
            Subject: selectedItems[0].subject,
            Country: selectedItems[0].country,
            BeCode: selectedItems[0].beCode,
            Body: selectedItems[0].body,
            Id: selectedItems[0].id,
        }
    };

    private getId = () => {
        const selectedItems = this.dataTable.current ? this.dataTable.current.getSelectedItems() : [];
        return selectedItems[0].id;
    };
}

const mapStateToProps = (state: IStore): IMapStateToProps => ({
    customsBrokerSettings: state.settings.customsBrokerSettings,
    fetchCustomsBrokerSettingsPending: state.settings.fetchCustomsBrokerSettingsPending,
    updateCustomsBrokerSettingsPending: state.settings.updateCustomsBrokerSettingsPending,
    deleteCustomsBrokerSettingsPending: state.settings.deleteCustomsBrokerSettingsPending,
    createCustomsBrokerSettingsPending: state.settings.createCustomsBrokerSettingsPending,
});

const mapDispatchToProps = (dispatch: ThunkDispatch<any, any, Action>): IDispatch => ({
    fetchCustomsBrokerSettings: () => dispatch(fetchCustomsBrokerSettings(true)),
    createCustomsBrokerConfiguration: (configuration: ICreateBroker) => dispatch(createCustomsBrokerConfiguration(configuration)),
    deleteCustomsBrokersConfiguration: (id: string) => dispatch(deleteCustomsBrokersConfiguration(id)),
    updateCustomsBrokerConfiguration: (configuration: IUpdateBroker) => dispatch(updateCustomsBrokerConfiguration(configuration)),
});

export const ConfigurationTable = connect(mapStateToProps, mapDispatchToProps)(_ConfigurationTable);