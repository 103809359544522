import React, { FunctionComponent, useState } from 'react';
import styled from 'styled-components';

const SDiv = styled.div`
    margin-bottom: 15px;
`;

const dialogType = (type?: number) => {
    switch (type) {
        case 200:
            return {
                type: 'success',
                icon: 'fa fa-check-circle fa-3x'
            };
        case 404:
        case 400:
        case 500:
            return {
                type: 'failed',
                icon: 'fa fa-times-circle fa-3x'
            };
        default:
            return {
                type: 'info',
                icon: 'fa fa-info-circle fa-3x'
            }
    }
}

interface IDialogProps {
    isVisible: boolean;
    dialogType?: number ;
    message: string;
    closeDialog?(): void;
}

export const Dialog: FunctionComponent<IDialogProps> = (props) => {
    const [isVisible, setVisibility] = useState<boolean>(props.isVisible);

    const dialog = dialogType(props.dialogType);
    const renderDialog = () => {
        if(isVisible) {
            return(
                <SDiv className={`message ${dialog.type}`}>
                    <i aria-hidden="true" className={dialog.icon} />
                        <span id="message_success">{props.message}</span>
                    <i id="close_failed" className="fas fa-times" onClick={props.closeDialog ? props.closeDialog : () => setVisibility(false)} />
                </SDiv>
            )
        }
        return <div />
    };
    return renderDialog();
};

