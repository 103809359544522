import { ThunkDispatch } from 'redux-thunk';
import { Action } from 'redux';
import { TYPES } from 'action-types/planning';
import { TYPES as FILTER_TYPES } from 'action-types/filters';
import { callApiDelete, callApiGet, callApiPost, callApiPut } from 'utilities/apiCaller';
import API from 'constants/api';
import { IEquipmentListData, IHAWBListData } from 'reducers/planning/models';
import { applyFilters, formatString, generateError } from 'utilities/util';
import { Transport_Order_Type } from 'constants/transport';
import { IStore } from 'reducers/index';
import { Dispatch } from 'redux';

export const getEquipmentsList = (pageNumber?: number, pageSize?: number) => {
    return async (dispatch: ThunkDispatch<any, any, Action>, getState: () => IStore): Promise<void> => {
        dispatch({
            type: TYPES.GET_EQUIPMENTS_LIST.PENDING,
        });
        try {
            const res = await callApiPost(API.DeliveryPlanning.GetEquipmentsList, applyFilters(getState, { isAirInBody: false, isAirInFilters: false}, {
                Page: pageNumber,
                PageNumber: pageNumber,
                PageSize: pageSize
            }), {headers: {TransportMode: 'Ocean'}});
            const equipments: IEquipmentListData = {
                data: res.data.result,
                pageNumber: res.data.number,
                pageSize: res.data.pageSize,
                totalCount: res.data.totalCount,
            };
            dispatch({
                type: TYPES.GET_EQUIPMENTS_LIST.FULFILLED,
                equipments,
            });
            dispatch({
                type: FILTER_TYPES.SET_TOTAL_COUNT,
                totalCount: res.data.totalCount,
            });
        } catch (e) {
            const error = generateError(e);

            dispatch({
                type: TYPES.GET_EQUIPMENTS_LIST.REJECTED, error,

            });
        }
    }
};


export const getEquipmentDetails = (id: number) => {
    return async (dispatch: ThunkDispatch<any, any, Action>): Promise<void> => {
        dispatch({
            type: TYPES.GET_EQUIPMENT_DETAILS.PENDING
        });
        const url = formatString(API.DeliveryPlanning.GetEquipmentDetails, id);
        try {
            const res = await callApiGet(url, undefined, {headers: {TransportMode: 'Ocean'}});
            dispatch({
                type: TYPES.GET_EQUIPMENT_DETAILS.FULFILLED,
                equipmentDetails: res.data,
            });
        } catch (e) {
            const error = generateError(e);

            dispatch({
                type: TYPES.GET_EQUIPMENT_DETAILS.REJECTED, error,
            });
        }
    }
};

export const getHAWBDetails = (id: number) => {
    return async (dispatch: ThunkDispatch<any, any, Action>): Promise<void> => {
        dispatch({
            type: TYPES.GET_HAWB_DETAILS.PENDING
        });
        const url = formatString(API.DeliveryPlanning.GetEquipmentDetails, id);
        try {
            const res = await callApiGet(url, undefined, {headers: {TransportMode: 'Air'}});
            dispatch({
                type: TYPES.GET_HAWB_DETAILS.FULFILLED,
                hawbDetails: res.data,
            });
        } catch (e) {
            const error = generateError(e);

            dispatch({
                type: TYPES.GET_HAWB_DETAILS.REJECTED, error,
            });
        }
    }
};

export const getEquipmentDeliveries = (id: number, isAir: boolean) => {
    return async (dispatch: ThunkDispatch<any, any, Action>): Promise<void> => {
        dispatch({
            type: TYPES.GET_EQUIPMENT_DELIVERIES.PENDING,
        });
        try {
            const url = formatString(API.DeliveryPlanning.GetEquipmentDeliveries, id);
            const res = await callApiGet(url, undefined, {headers: {TransportMode: isAir ? 'Air' : 'Ocean'}});
            dispatch({
                type: TYPES.GET_EQUIPMENT_DELIVERIES.FULFILLED,
                equipmentDeliveries: res.data,
            });
        } catch (e) {
            const error = generateError(e);

            dispatch({
                type: TYPES.GET_EQUIPMENT_DELIVERIES.REJECTED, error,
            });
        }
    }
};

export const addEquipmentDeliveries = (id: number, fields: any, isAir: boolean) => {
    return async (dispatch: ThunkDispatch<any, any, Action>): Promise<void> => {
        dispatch({
            type: TYPES.ADD_EQUIPMENT_DELIVERIES.PENDING,
        });
        try {
            const url = formatString(API.DeliveryPlanning.AddEquipmentDeliveries, id);
            await callApiPost(url, fields, {headers: {TransportMode: isAir ? 'Air' : 'Ocean'}});
            dispatch({
                type: TYPES.ADD_EQUIPMENT_DELIVERIES.FULFILLED,
            });
        } catch (e) {
            const error = generateError(e);

            dispatch({
                type: TYPES.ADD_EQUIPMENT_DELIVERIES.REJECTED, error,
            });
            throw error;
        }
    }
};

export const editEquipmentDeliveries = (equipmentId: number, deliveryId: number, fields: any, isAir: boolean) => {
    return async (dispatch: ThunkDispatch<any, any, Action>): Promise<void> => {
        dispatch({
            type: TYPES.EDIT_EQUIPMENT_DELIVERIES.PENDING,
        });
        try {
            const url = formatString(API.DeliveryPlanning.EditEquipmentDeliveries, equipmentId, deliveryId);
            await callApiPut(url, fields, {headers: {TransportMode: isAir ? 'Air' : 'Ocean'}});
            dispatch({
                type: TYPES.EDIT_EQUIPMENT_DELIVERIES.FULFILLED,
            });
        } catch (e) {
            const error = generateError(e);

            dispatch({
                type: TYPES.EDIT_EQUIPMENT_DELIVERIES.REJECTED, error,
            });
            throw error;
        }
    }
};

export const deleteEquipmentDeliveries = (containerId: number, ids: { deliveriesIds: number[]}, isAir: boolean) => {
    return async (dispatch: ThunkDispatch<any, any, Action>): Promise<void> => {
        dispatch({
            type: TYPES.DELETE_EQUIPMENT_DELIVERIES.PENDING,
        });
        try {
            const url = formatString(API.DeliveryPlanning.DeleteEquipmentDeliveries, containerId);
            await callApiDelete(url, {
                data: ids,
                headers: {TransportMode: isAir ? 'Air' : 'Ocean'}
            });
            dispatch({
                type: TYPES.DELETE_EQUIPMENT_DELIVERIES.FULFILLED,
            });
        } catch (e) {
            const error = generateError(e);

            dispatch({
                type: TYPES.DELETE_EQUIPMENT_DELIVERIES.REJECTED, error,
            });
        }
    }
};

export const editDeliveryDetails = (fields: any, isAir: boolean) => {
    return async (dispatch: ThunkDispatch<any, any, Action>): Promise<void> => {
        dispatch({
            type: TYPES.EDIT_DELIVERY_DETAILS.PENDING,
        });
        try {
            const url = formatString(API.DeliveryPlanning.EditDeliveryDetails);
            await callApiPut(url, fields, {headers: {TransportMode: isAir ? 'Air' : 'Ocean'}});
            dispatch({
                type: TYPES.EDIT_DELIVERY_DETAILS.FULFILLED,
            });
        } catch (e) {
            const error = generateError(e);

            dispatch({
                type: TYPES.EDIT_DELIVERY_DETAILS.REJECTED, error,
            });
            throw e;
        }
    }
};

export const resetEditDeliveryDetailsError = () => {
    return (dispatch: Dispatch): void => {
        dispatch({
            type: TYPES.EDIT_DELIVERY_DETAILS.RESET_ERROR,
        });
    }
};

export interface ISendTransportOrderToHaulier {
    haulierId: string;
    transportConfig: Transport_Order_Type;
    equipmentIds: number[];
}

export const sendTransportOrderToHaulier = (data: ISendTransportOrderToHaulier, isAir: boolean) => {
    return async (dispatch: ThunkDispatch<any, any, Action>): Promise<void> => {
        dispatch({
            type: TYPES.SEND_TRANSPORT_ORDER_TO_HAULIER.PENDING,
        });
        try {
            await callApiPost(API.DeliveryPlanning.SendTransportOrderToHaulier, data, {headers: {TransportMode: isAir ? 'Air' : 'Ocean'}});
            dispatch({
                type: TYPES.SEND_TRANSPORT_ORDER_TO_HAULIER.FULFILLED,
            });
        } catch (e) {
            const error = generateError(e);
            dispatch({
                type: TYPES.SEND_TRANSPORT_ORDER_TO_HAULIER.REJECTED, error,
            });
            throw e;
        }
    }
};

export const updateEquipmentDetails = (fields: object, isAir: boolean) => {
    return async (dispatch: ThunkDispatch<any, any, Action>): Promise<void> => {
        dispatch({
            type: TYPES.UPDATE_EQUIPMENT_DETAILS.PENDING,
        });
        try {
            await callApiPost(API.DeliveryPlanning.UpdateEquipmentDetails, fields, {headers: {TransportMode: isAir ? 'Air' : 'Ocean'}});
            dispatch({
                type: TYPES.UPDATE_EQUIPMENT_DETAILS.FULFILLED,
            });
        } catch (e) {
            const error = generateError(e);

            dispatch({
                type: TYPES.UPDATE_EQUIPMENT_DETAILS.REJECTED, error,
            });
            throw e;
        }
    }
};

export const resetUpdateEquipmentDetailsError = () => {
    return (dispatch: Dispatch): void => {
        dispatch({
            type: TYPES.UPDATE_EQUIPMENT_DETAILS.RESET_ERROR,
        });
    }
};

export const getEmptyReturnEquipments = (pageNumber?: number, pageSize?: number) => {
    return async (dispatch: ThunkDispatch<any, any, Action>, getState: () => IStore): Promise<void> => {
        dispatch({
            type: TYPES.GET_EMPTY_RETURN_EQUIPMENTS.PENDING,
        });
        try {
            const res = await callApiPost(API.DeliveryPlanning.GetEmptyReturnEquipments, applyFilters(getState, { isAirInBody: false, isAirInFilters: false }, {
                Page: pageNumber,
                PageNumber: pageNumber,
                PageSize: pageSize
            }), {headers: {TransportMode: 'Ocean'}});
            const emptyReturnEquipments = {
                ...res.data,
                data: res.data.result,
            };
            dispatch({
                type: TYPES.GET_EMPTY_RETURN_EQUIPMENTS.FULFILLED,
                emptyReturnEquipments,
            });
            dispatch({
                type: FILTER_TYPES.SET_TOTAL_COUNT,
                totalCount: res.data.totalCount,
            });
        } catch (e) {
            const error = generateError(e);

            dispatch({
                type: TYPES.GET_EMPTY_RETURN_EQUIPMENTS.REJECTED, error,
            });
        }
    }
};

export const getAirDelivery = (pageNumber?: number, pageSize?: number) => {
    return async (dispatch: ThunkDispatch<any, any, Action>, getState: () => IStore): Promise<void> => {
        dispatch({
            type: TYPES.GET_AIR_DELIVERY.PENDING,
        });
        try {
            const res = await callApiPost(API.DeliveryPlanning.GetEmptyReturnEquipments, applyFilters(getState, { isAirInBody: true }, {
                Page: pageNumber,
                PageNumber: pageNumber,
                PageSize: pageSize
            }), {headers: {TransportMode: 'Air'}});
            const airDelivery = {
                ...res.data,
                data: res.data.result,
            };
            dispatch({
                type: TYPES.GET_AIR_DELIVERY.FULFILLED,
                airDelivery,
            });
            dispatch({
                type: FILTER_TYPES.SET_TOTAL_COUNT,
                totalCount: res.data.totalCount,
            });
        } catch (e) {
            const error = generateError(e);

            dispatch({
                type: TYPES.GET_AIR_DELIVERY.REJECTED, error,
            });
        }
    }
};

export const editDeliveryAndEmptyReturnEquipment = (id: number, fields: any) => {
    return async (dispatch: ThunkDispatch<any, any, Action>): Promise<void> => {
        dispatch({
            type: TYPES.UPDATE_EMPTY_RETURN_EQUIPMENT_DETAILS.PENDING,
        });
        try {
            await callApiPost(API.DeliveryPlanning.UpdateEmptyReturnEquipmentDetails, fields, {headers: {TransportMode: 'Ocean'}});
            dispatch({
                type: TYPES.UPDATE_EMPTY_RETURN_EQUIPMENT_DETAILS.FULFILLED,
            });
        } catch (e) {
            const error = generateError(e);

            dispatch({
                type: TYPES.UPDATE_EMPTY_RETURN_EQUIPMENT_DETAILS.REJECTED, error,
            });
            throw e;
        }
    }
};

export const resetEditDeliveryAndEmptyReturnEquipmentError = () => (dispatch: Dispatch) => {
    dispatch({ type: TYPES.UPDATE_EMPTY_RETURN_EQUIPMENT_DETAILS.RESET_ERROR });
}

const formatBooleans = (fields: object) => Object.keys(fields).reduce((prev: object, key: string) => {
    const field = fields[key];
    if(field === 'true') {
        prev[key] = true;
        return prev;
    }
    if(field === 'false') {
        prev[key] = false;
        return prev;
    }
    prev[key] = field;
    return prev;
}, {});

export const updateEmptyReturnActuals = (fields: any, isAir: boolean) => {
    return async (dispatch: ThunkDispatch<any, any, Action>): Promise<void> => {
        dispatch({
            type: TYPES.UPDATE_EMPTY_RETURN_ACTUALS.PENDING,
        });
        try {
            await callApiPost(API.DeliveryPlanning.UpdateEmptyReturnActuals, formatBooleans(fields), {headers: {TransportMode: isAir ? 'Air' : 'Ocean'}});
            dispatch({
                type: TYPES.UPDATE_EMPTY_RETURN_ACTUALS.FULFILLED,
            });
        } catch (e) {
            const error = generateError(e);

            dispatch({
                type: TYPES.UPDATE_EMPTY_RETURN_ACTUALS.REJECTED, error,
            });
            throw error;
        }
    }
};

export const resetSendTransportOrderErrors = () => ({
    type: TYPES.RESET_SEND_TRANSPORT_ORDER_ERROR,
});

export const getContainersLocation = (pageNumber?: number, pageSize?: number) => {
    return async (dispatch: ThunkDispatch<any, any, Action>, getState: () => IStore): Promise<void> => {
        dispatch({
            type: TYPES.GET_CONTAINERS_LOCATION.PENDING,
        });
        try {
            const res = await callApiPost(API.DeliveryPlanning.GetContainersLocation, applyFilters(getState, { isAirInBody: false, isAirInFilters: false }, {
                Page: pageNumber,
                PageNumber: pageNumber,
                PageSize: pageSize
            }), {headers: {TransportMode: 'Ocean'}});
            const locations = {
                ...res.data,
                data: res.data.result
            };
            dispatch({
                type: TYPES.GET_CONTAINERS_LOCATION.FULFILLED,
                locations,
            });
            dispatch({
                type: FILTER_TYPES.SET_TOTAL_COUNT,
                totalCount: res.data.totalCount,
            });
        } catch (e) {
            const error = generateError(e);

            dispatch({
                type: TYPES.GET_CONTAINERS_LOCATION.REJECTED, error,
            });
        }
    }
};

export const getDPReasonCodes = (group?: string) => {
    return async (dispatch: ThunkDispatch<any, any, Action>): Promise<void> => {
        dispatch({
            type: TYPES.GET_DP_REASON_CODES.PENDING,
        });
        try {
            const params = group ? { group } : {};
            const res = await callApiGet(API.DeliveryPlanning.GetReasonCodes, params, {headers: {TransportMode: 'Ocean'}});
            dispatch({
                type: TYPES.GET_DP_REASON_CODES.FULFILLED,
                reasonCodes: res.data,
            });
        } catch (e) {
            const error = generateError(e);

            dispatch({
                type: TYPES.GET_DP_REASON_CODES.REJECTED, error,
            });
        }
    };
};

export const getHAWBList = (pageNumber?: number, pageSize?: number) => {
    return async (dispatch: ThunkDispatch<any, any, Action>, getState: () => IStore): Promise<void> => {
        dispatch({
            type: TYPES.GET_HAWB_LIST.PENDING,
        });
        try {
            const res = await callApiPost(API.DeliveryPlanning.GetEquipmentsList, applyFilters(getState, { isAirInFilters: true, isAirInBody: true }, {
                Page: pageNumber,
                PageNumber: pageNumber,
                PageSize: pageSize
            }), {headers: {TransportMode: 'Air'}});
            const hawb: IHAWBListData = {
                data: res.data.result,
                pageNumber: res.data.number,
                pageSize: res.data.pageSize,
                totalCount: res.data.totalCount,
            };
            dispatch({
                type: TYPES.GET_HAWB_LIST.FULFILLED,
                hawb,
            });
            dispatch({
                type: FILTER_TYPES.SET_TOTAL_COUNT,
                totalCount: res.data.totalCount,
            });
        } catch (e) {
            const error = generateError(e);

            dispatch({
                type: TYPES.GET_HAWB_LIST.REJECTED, error,

            });
        }
    }
};

export const setAvaialibilityForBooking = (body: any, isAir: boolean) => {
    return async (dispatch: ThunkDispatch<any, any, Action>): Promise<void> => {
        dispatch({ type: TYPES.SET_AVAILABILITY_FOR_BOOKING.PENDING });
        try {
            const res = await callApiPost(API.DeliveryPlanning.SetAvailibilityForBooking, body, {headers: {TransportMode: isAir ? 'Air' : 'Ocean'}});
            dispatch({
                type: TYPES.SET_AVAILABILITY_FOR_BOOKING.FULFILLED,
                ...res.data
            });
        } catch (e) {
            const error = generateError(e);

            dispatch({
                type: TYPES.SET_AVAILABILITY_FOR_BOOKING.REJECTED, error,
            });
            throw e;
        }
    }
};

export const resetSetAvaialabilityForBookingError = () => (dispatch: Dispatch) => {
    dispatch({ type: TYPES.SET_AVAILABILITY_FOR_BOOKING.RESET_ERROR })
};

export const getAirDeliveryDetails = (id: number) => {
    return async (dispatch: ThunkDispatch<any, any, Action>): Promise<void> => {
        dispatch({
            type: TYPES.GET_AIR_DELIVERY_DETAILS.PENDING
        });
        const url = formatString(API.DeliveryPlanning.GetEquipmentDetails, id);
        try {
            const res = await callApiGet(url, undefined, {headers: {TransportMode: 'Air'}});
            dispatch({
                type: TYPES.GET_AIR_DELIVERY_DETAILS.FULFILLED,
                airDeliveryDetails: res.data,
            });
        } catch (e) {
            const error = generateError(e);

            dispatch({
                type: TYPES.GET_AIR_DELIVERY_DETAILS.REJECTED, error,
            });
        }
    }
};

export const getHblWithCustoms = (body: any) => {
    return async (dispatch: ThunkDispatch<any, any, Action>): Promise<void> => {
        dispatch({
            type: TYPES.FETCH_HBL_WITH_CUSTOMS.PENDING
        });
        try {
            const res = await callApiPost(API.DeliveryPlanning.GetHblWithCustoms, body, {headers: {TransportMode: 'Ocean'}});
            dispatch({
                type: TYPES.FETCH_HBL_WITH_CUSTOMS.FULFILLED,
                hblWithCustoms: res.data,
            });
        } catch (e) {
            const error = generateError(e);

            dispatch({
                type: TYPES.FETCH_HBL_WITH_CUSTOMS.REJECTED, error,
            });
        }
    }
};

export const getDeliveryDetailsForEquipments = (EquipmentIdList: number[], isAir: boolean) => {
    return async (dispatch: ThunkDispatch<any, any, Action>): Promise<void> => {
        dispatch({
            type: TYPES.GET_DELIVERY_DETAILS_FOR_EQUIPMENTS.PENDING
        });
        try {
            const res = await callApiPost(API.DeliveryPlanning.GetDeliveryDetailsForEquipments, { EquipmentIdList }, {headers: {TransportMode: isAir ? 'Air' : 'Ocean'}});
            dispatch({
                type: TYPES.GET_DELIVERY_DETAILS_FOR_EQUIPMENTS.FULFILLED,
                equipmentsDeliveryDetails: res.data,
            });
        } catch (e) {
            const error = generateError(e);

            dispatch({
                type: TYPES.GET_DELIVERY_DETAILS_FOR_EQUIPMENTS.REJECTED, error,
            });
        }
    }
};

export const getPoPlanning = (pageNumber?: number, pageSize?: number) => {
    return async (dispatch: ThunkDispatch<any, any, Action>, getState: () => IStore): Promise<void> => {
        dispatch({ type: TYPES.GET_PO_PLANNING_LIST.PENDING });
        try {
            const res = await callApiPost(API.DeliveryPlanning.GetPos, applyFilters(getState, { isAirInBody: false, isAirInFilters: false }, {
                Page: pageNumber,
                PageNumber: pageNumber,
                PageSize: pageSize
            }), {headers: {TransportMode: 'Ocean'}});
            dispatch({
                type: TYPES.GET_PO_PLANNING_LIST.FULFILLED,
                poPlanningList: res.data,
            });
            dispatch({
                type: FILTER_TYPES.SET_TOTAL_COUNT,
                totalCount: res.data.totalCount,
            });
        } catch (e) {
            const error = generateError(e);
            dispatch({ type: TYPES.GET_PO_PLANNING_LIST.REJECTED, error });
        }
    }
};

export const getPoPlanningAir = (pageNumber?: number, pageSize?: number) => {
    return async (dispatch: ThunkDispatch<any, any, Action>, getState: () => IStore): Promise<void> => {
        dispatch({ type: TYPES.GET_PO_PLANNING_LIST.PENDING });
        try {
            const res = await callApiPost(API.DeliveryPlanning.GetPos, applyFilters(getState, { isAirInBody: true, isAirInFilters: true }, {
                Page: pageNumber,
                PageNumber: pageNumber,
                PageSize: pageSize
            }), {headers: {TransportMode: 'Air'}});
            dispatch({
                type: TYPES.GET_PO_PLANNING_LIST.FULFILLED,
                poPlanningList: res.data,
            });
            dispatch({
                type: FILTER_TYPES.SET_TOTAL_COUNT,
                totalCount: res.data.totalCount,
            });
        } catch (e) {
            const error = generateError(e);
            dispatch({ type: TYPES.GET_PO_PLANNING_LIST.REJECTED, error });
        }
    }
};