import React, { ReactNode } from 'react';
import { connect } from 'react-redux';
import { Dispatch } from 'redux';
import ReactTable, { Column, RowInfo } from 'react-table';
import { ISortModel, SORT_ORDER } from 'reducers/filters/models';
import { resetSort, setSort } from 'actions/filters';
import { IStore } from 'reducers/index';
// import { DataTable as DamcoTable } from 'damco-components';

interface IDataTable extends IDispatch, IMapStateToProps {
    data: any[];
    columns: Column[];
    SubComponent?: (row: RowInfo) => ReactNode;
    onExpandedChange?: (expanded: object, index: number, event: any) => void;
    expanded?: object;
    TrComponent?: any;
    TbodyComponent?: any;
    withBackendSort?: boolean;
    getTdProps?: any;
    id?: string;
}

interface IMapStateToProps {
    order: SORT_ORDER;
}

interface IDispatch {
    setSort: (sort: ISortModel) => void;
    resetSort: () => void;
}

class _DataTable extends React.Component<IDataTable> {
    public static defaultProps = {
        withBackendSort: false,
    };

    public render() {
        const pageSize = this.props.data.length > 20 ? this.props.data.length : 20;
        const data =
            !this.props.withBackendSort && this.props.order === SORT_ORDER.DESCENDING
                ? this.props.data.reverse()
                : this.props.data

        return (
            <div className="data-table-container fixed-header" id={this.props.id}>
                <ReactTable
                    {...this.props as any}
                    data={data}
                    pageSize={pageSize}
                    minRows={1}
                    showPagination={false}
                    sortable={true}
                    manual={this.props.withBackendSort}
                    resizable={true}
                    TrComponent={this.props.TrComponent}
                    TrGroupComponent={this.props.TbodyComponent}
                    onSortedChange={this.props.withBackendSort ? this.setSort : undefined}
                    getTdProps={this.props.getTdProps}
                />
            </div>
        );
    }

    private setSort = (data: Array<{ desc: boolean, id: string}>, ...rest: any[]) => {
        const order = data[0].desc ? SORT_ORDER.DESCENDING : SORT_ORDER.ASCENDING;
        const sort = {
            column: data[0].id,
            order,
            desc: data[0].desc,
        };
        this.props.setSort(sort);
    }
}

const mapStateToProps = (state: IStore): IMapStateToProps => ({
    order: state.filters.order,
});

const mapDispatchToProps = (dispatch: Dispatch): IDispatch => ({
    setSort: (sort: ISortModel) => dispatch(setSort(sort)),
    resetSort: () => dispatch(resetSort()),
});

export const DataTable = connect(mapStateToProps, mapDispatchToProps)(_DataTable);
