import React from 'react';
import { ColumnFactory } from 'components/data-table/ColumnFactory';
import { DataTable } from 'components/data-table/DamcoTable';
import { IFactEquipment } from 'reducers/fact/models';
import { StatusRow } from 'components/status-row';
import { cloneDataWithDisplayNames } from 'utilities/tables';

interface IEquipmentsTableProps {
    equipments: IFactEquipment[];
}

export const EquipmentsTable: React.StatelessComponent<IEquipmentsTableProps> = (props) => {
    const data = cloneDataWithDisplayNames(props.equipments, 'DD-MM-YYYY, h:mm').map(equipment => ({
        ...equipment,
        equipmentNumberDisplayName: [equipment.equipmentNumber, `/exception-management/fact/equipments/${equipment.equipmentId}`]
    }));

    return(
        <StatusRow title="Equipments">
            <DataTable
                data={data}
                columns={[
                    ColumnFactory.linkedColumn('equipmentNumberDisplayName', 'Equipment number'),
                    ColumnFactory.standardColumn('sealNumberDisplayName', 'Seal number'),
                    ColumnFactory.standardColumn('typeDisplayName', 'Type'),
                    ColumnFactory.standardColumn('sizeDisplayName', 'Size'),
                    ColumnFactory.standardColumn('subSizeDisplayName', 'Sub size'),
                    ColumnFactory.standardColumn('equipmentTotalVolumeDisplayName', 'Equipment total volume'),
                    ColumnFactory.standardColumn('documentOnEquipmentVolumeDisplayName', 'Document on equipment volume'),
                    ColumnFactory.standardColumn('etOnEssoVolumeFactorDisplayName', 'Et on esso volume factor'),
                    ColumnFactory.standardColumn('essoOnEtVolumeFactorDisplayName', 'Esso on et volume factor'),
                    ColumnFactory.standardColumn('createDateDisplayName', 'Create date'),
                    ColumnFactory.standardColumn('updateDateDisplayName', 'Update date'),
                ]}
                withCheckboxes={false}
                reference={null as any}
                tableName="FactDetailsEquipmentTable"
                showPagination={true}
            />
        </StatusRow>
    );
};

