import React from 'react';
import { StatusRow } from 'components/status-row';
import { OneStatus } from 'components/status-row/OneStatus';
import { QuarterGrid } from 'components/grid/quarter-grid';
import { formatValueIfNullOrUndefined } from 'utilities/util';

interface IIssuerRowProps {
    invoiceeCountryCode: string;
    invoiceeBusinessEntityCode: string;
    invoiceeName: string;
    invoiceeFactCode: string;
    invoiceeSCVCode: string;
}

export const InvoiceeRow: React.StatelessComponent<IIssuerRowProps> = (props) => {
    return (
        <StatusRow title="Invoicee">
            <QuarterGrid>
                <OneStatus label="Invoicee country code">
                    {formatValueIfNullOrUndefined(props.invoiceeCountryCode)}
                </OneStatus>
                <OneStatus label="Invoicee business entity code">
                    {formatValueIfNullOrUndefined(props.invoiceeBusinessEntityCode)}
                </OneStatus>
                <OneStatus label="Invoicee name">
                    {formatValueIfNullOrUndefined(props.invoiceeName)}
                </OneStatus>
                <OneStatus label="Invoicee fact code">
                    {formatValueIfNullOrUndefined(props.invoiceeFactCode)}
                </OneStatus>
            </QuarterGrid>
            <QuarterGrid>
                <OneStatus label="Invoicee SCV code">
                    {formatValueIfNullOrUndefined(props.invoiceeSCVCode)}
                </OneStatus>
            </QuarterGrid>
        </StatusRow>
    );
};
