import React from 'react';
import { NavLink as RouterNavLink } from 'react-router-dom';

interface INavLink {
    to: string;
    text: string;
}

export const NavLink = (props: INavLink) => (
    <li>
        <RouterNavLink to={props.to}>
            {props.text}
        </RouterNavLink>
    </li>
);