import React from 'react';
import { PageHeader } from 'components/page-header';
import { SubNavigation } from 'components/sub-navigation';
import { DemurrageTable } from 'views/demurrage-and-detention/demurrage/components/DemurrageTable';
import { Page } from 'components/page-container';
import { ILink } from 'components/sub-navigation/models/Link';
import { connect } from 'react-redux';
import { ThunkDispatch } from 'redux-thunk';
import { Action } from 'redux';
import { getDemurrageEquipments } from 'actions/demurrageAndDetention';
import { IStore } from 'reducers/index';
import { IDemurrageListData } from 'reducers/demurrage-and-detention/models';
import { LoadingAnimation } from 'components/loading';
import { HandleError } from 'components/handle-error';
import { ISortModel } from 'reducers/filters/models';
import { setSort, resetSort } from 'actions/filters';
import { setSortForTable } from 'utilities/tables';
import { Filters } from 'modules/filters/containers/Filters';
import { FiltersInput } from 'modules/filters/components/FiltersInput';
import { FiltersDateInput } from 'modules/filters/components/FiltersDateInput';

const links: ILink[] = [{
    to: '/demurrage-and-detention/demurrage',
    linkText: 'Demurrage',
}, {
    to: '/demurrage-and-detention/detention',
    linkText: 'Detention',
}];

interface IMapStateToProps {
    demurrage: IDemurrageListData;
    isLoading: boolean;
    exportDemurrageError: string;
    isExportLoading: boolean;
    ataEmpty: boolean;
    equipmentLandDateEmpty: boolean;
    gateOutPortDateEmpty: boolean;
    firstChargeableDayDemurrageEmpty: boolean;
}

interface IDispatch {
    getDemurrageEquipments: (pageNumber: number, pageSize: number) => Promise<void>;
    setSort: (sort: ISortModel) => void;
    resetSort: () => void;
}

interface IDemurrage extends IMapStateToProps, IDispatch {}

interface IDemurrageState {
    page: number;
    pageSize: number;
}

class _Demurrage extends React.Component<IDemurrage, IDemurrageState> {
    public state = {
        page: 1,
        pageSize: 10
    };

    public render() {
        const filters = [
            <FiltersInput
                key="EquipmentNumber"
                name="EquipmentNumber"
                label="Equipment Number"
                id="equipmentNumber"
            />,
            <FiltersDateInput
                type="date"
                key="ata_from"
                name="ata_from"
                label="ATA (from)"
                relatedField="ata"
                readOnly={this.props.ataEmpty}
            />,
            <FiltersDateInput
                type="date"
                key="ata_to"
                name="ata_to"
                label="ATA (to)"
                isEndOfDay={true}
                relatedField="ata"
                readOnly={this.props.ataEmpty}
            />,
            <FiltersDateInput
                type="date"
                key="EquipmentLandDate_from"
                name="EquipmentLandDate_from"
                label="Container landed date (from)"
                relatedField="equipmentLandDate"
                readOnly={this.props.equipmentLandDateEmpty}
            />,
            <FiltersDateInput
                type="date"
                key="EquipmentLandDate_to"
                name="EquipmentLandDate_to"
                label="Container landed date (to)"
                isEndOfDay={true}
                relatedField="equipmentLandDate"
                readOnly={this.props.equipmentLandDateEmpty}
            />,
            <FiltersDateInput
                type="date"
                key="GateOutPortDate_from"
                name="GateOutPortDate_from"
                label="Gate out date (from)"
                relatedField="gateOutPortDate"
                readOnly={this.props.gateOutPortDateEmpty}
            />,
            <FiltersDateInput
                type="date"
                key="GateOutPortDate_to"
                name="GateOutPortDate_to"
                label="Gate out date (to)"
                isEndOfDay={true}
                relatedField="gateOutPortDate"
                readOnly={this.props.gateOutPortDateEmpty}
            />,
            <FiltersInput
                key="VesselName"
                name="VesselName"
                label="Vessel name"
            />,
            <FiltersInput
                key="CarrierName"
                name="CarrierName"
                label="Carrier"
            />,
            <FiltersInput
                key="DemurrageContractId"
                name="DemurrageContractId"
                label="Contract #"
            />,
            <FiltersDateInput
                type="date"
                key="FirstChargeableDayDemurrage_from"
                name="FirstChargeableDayDemurrage_from"
                label="First chargeable day (from)"
                relatedField="firstChargeableDayDemurrage"
                readOnly={this.props.firstChargeableDayDemurrageEmpty}
            />,
            <FiltersDateInput
                type="date"
                key="FirstChargeableDayDemurrage_to"
                name="FirstChargeableDayDemurrage_to"
                label="First chargeable day (to)"
                isEndOfDay={true}
                relatedField="firstChargeableDayDemurrage"
                readOnly={this.props.firstChargeableDayDemurrageEmpty}
            />,
            <FiltersInput
                key="PONumber"
                name="PONumber"
                label="PO Number"
            />,
            <FiltersInput
                key="lastUserUpdated"
                name="lastUserUpdated"
                label="Last updated by"
            />,
        ];

        return (
            <Page>
                <PageHeader>Demurrage & Detention</PageHeader>
                {(this.props.isLoading || this.props.isExportLoading) && <LoadingAnimation />}
                <HandleError
                    exportDetentionError={this.props.exportDemurrageError}
                />
                <Filters
                    tableNameKey="delivery-demurrage"
                    filters={filters}
                    fetchFunction={() => this.props.getDemurrageEquipments(this.state.page, this.state.pageSize)}
                    withoutPagination={true}
                />
                <SubNavigation links={links} />
                <DemurrageTable
                    data={this.props.demurrage.result}
                    refetchFunction={() => this.props.getDemurrageEquipments(1, this.state.pageSize)}
                    pageCount={Math.ceil(this.props.demurrage.totalCount / this.props.demurrage.pageSize)}
                    fetchEquipments={this.fetchEquipments}
                />
            </Page>
        );
    }

    public componentWillUnmount() {
        this.props.resetSort();
    }

    private fetchEquipments = (tableData: any) => {
        this.setState({
            page: tableData.page + 1,
            pageSize: tableData.pageSize
        }, () => {
            setSortForTable(tableData.sorted, this.props.setSort);
            this.props.getDemurrageEquipments(this.state.page, this.state.pageSize);
        });
    };
}

const mapStateToProps = (state: IStore): IMapStateToProps => ({
    demurrage: state.demurrageAndDetention.demurrage,
    isLoading: state.demurrageAndDetention.isGetDemurragePending,
    exportDemurrageError: state.exportReducer.exportDemurrageError,
    isExportLoading: state.exportReducer.isExportDemurrageLoading,
    ataEmpty: state.filters.filters.ata_empty,
    equipmentLandDateEmpty: state.filters.filters.equipmentlanddate_empty,
    gateOutPortDateEmpty: state.filters.filters.gateoutportdate_empty,
    firstChargeableDayDemurrageEmpty: state.filters.filters.firstchargeabledaydemurrage_empty
});

const mapDispatchToProps = (dispatch: ThunkDispatch<any, any, Action>): IDispatch => ({
    getDemurrageEquipments: (pageNumber: number, pageSize: number) => dispatch(getDemurrageEquipments(pageNumber, pageSize)),
    setSort: (sort: ISortModel) => dispatch(setSort(sort)),
    resetSort: () => dispatch(resetSort()),
});

export const Demurrage = connect<IMapStateToProps, IDispatch, {}, IStore>(mapStateToProps, mapDispatchToProps)(_Demurrage);
