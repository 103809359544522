import React from 'react';
import { Switch } from 'react-router-dom';
import { OceanClearance } from 'views/customs/ocean/ocean-clearance/OceanClearance';
import { CustomsRelease } from 'views/customs/ocean/customs-release/CustomsRelease';
import { CustomsTrackingContainerDetails } from 'views/customs/ocean/customs-tracking-container-details';
import { HBLFCRDetails } from 'views/customs/ocean/hbl-fcr-details';
import { AirClearance } from 'views/customs/air/air-clearance/AirClearance';
import { HawbDetails } from 'views/customs/air/hawb-details';
import { RouteWithPermissions } from 'containers/route-with-permissions';
import { BUSINNESS_FUNCTIONS } from 'constants/roles';
import { HAWBRelease } from './air/hawb-release/HawbRelease';

export const CustomsRouting = () => {
    return(
        <Switch>
            <RouteWithPermissions
                path="/customs/air-clearance/:id/:voyageId"
                component={HawbDetails}
                functions={[ BUSINNESS_FUNCTIONS.AIR_CUSTOMS_HBL_DETAILS]}
            />
            <RouteWithPermissions
                path="/customs/ocean-clearance/:id/:voyageId"
                component={HBLFCRDetails}
                functions={[ BUSINNESS_FUNCTIONS.OCEAN_CUSTOMS_HBL_DETAILS ]}
            />
            <RouteWithPermissions
                path="/customs/customs-release/:containerId"
                component={CustomsTrackingContainerDetails}
                functions={[ BUSINNESS_FUNCTIONS.OCEAN_CUSTOMS_CONTAINER_DETAILS]}
            />
            <RouteWithPermissions
                exact={true}
                path="/customs/ocean-clearance"
                component={OceanClearance}
                functions={[ BUSINNESS_FUNCTIONS.OCEAN_CUSTOMS_LIST ]}
            />
            <RouteWithPermissions
                exact={true}
                path="/customs/customs-release"
                component={CustomsRelease}
                functions={[ BUSINNESS_FUNCTIONS.OCEAN_CUSTOMS_LIST ]}
            />
            <RouteWithPermissions
                exact={true}
                path="/customs/air-clearance"
                component={AirClearance}
                functions={[ BUSINNESS_FUNCTIONS.AIR_CUSTOMS_LIST ]}
            />
            <RouteWithPermissions
                exact={true}
                path="/customs/hawb-release"
                component={HAWBRelease}
                functions={[ BUSINNESS_FUNCTIONS.OCEAN_CUSTOMS_LIST ]}
            />
        </Switch>
    )
}
